import { MAX_DATE_SELECT_DATE } from "@coherehealth/common";
import { Patient, AuthorizationResponse } from "@coherehealth/core-platform-api";

import { Grid } from "@material-ui/core";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import {
  ContinuationConfiguration,
  FormConfiguration,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { checkOnetimeRequestCoverage, getPatientHealthPlanName } from "util/patientUtils";
import AdmissionAndExpectedDischargeDateSelect from "common/AdmissionAndExpectedDischargeDateSelect";
import OutPatientDateRangeSelect from "./OutPatientDateRangeSelect";
import PatientDateSelectionMessage from "./PatientDateSelectionMessage";
import { isValid } from "date-fns";
import { useCalculateInitializedEndDate } from "../common";
import { calculateStartEndDate } from "util/authorization";
import { useCalculateDateSelectionMessageDisplay } from "components/AuthBuilder/FillFormsGeneralAuthSubmission/utils";
import { fieldIsValid } from "common/FormConfigUtils";

interface Props {
  formConfiguration: FormConfiguration | ContinuationConfiguration;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  onUserEdit?: Dispatch<ServiceRequestFormContent>;
  patient: Patient | null;
  attemptedSubmit: boolean;
  showEndDate: boolean;
  facilityBasedFeatureEnabled?: boolean;
  hasValidStartDate?: boolean;
  startDateErrorMessage?: string;
  authorization?: AuthorizationResponse;
  isContinuation?: boolean;
  clearEndDate?: boolean | undefined;
  setClearEndDate?: (clearEndDate: boolean) => void;
  dateRangeDisabled?: boolean;
  stayDaysDisabled?: boolean;
}

const MIN_START_DATE = new Date(2020, 0, 1);

export default function DateRangeSelect({
  formConfiguration,
  formContent,
  setFormContent,
  onUserEdit,
  patient,
  attemptedSubmit,
  facilityBasedFeatureEnabled,
  hasValidStartDate,
  startDateErrorMessage,
  authorization,
  isContinuation,
  clearEndDate,
  setClearEndDate,
  dateRangeDisabled,
  stayDaysDisabled,
}: Props) {
  const isDraftAuth = ["DRAFT", undefined].includes(formContent.authStatus);
  const { showExceededDurationLimitMessageErrorState, showBelowDurationLimitMessageErrorState } =
    useCalculateDateSelectionMessageDisplay(
      formContent,
      patient?.coverages || [],
      patient,
      formConfiguration,
      clearEndDate
    );

  const startDateCoverage = useMemo(() => {
    if (patient?.coverages) {
      if (formContent?.admissionDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.admissionDate);
      } else if (formContent?.startDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.startDate);
      }
    }
    return undefined;
  }, [patient?.coverages, formContent?.startDate, formContent?.admissionDate]);

  const endDateCoverage = useMemo(() => {
    if (patient?.coverages) {
      if (formContent?.dischargeDate) {
        return checkOnetimeRequestCoverage(
          patient?.coverages,
          formContent?.dischargeDate,
          formConfiguration.blockUserIfLessThanRecommendedEndDate?.fieldSpec === "REQUIRED"
        );
      } else if (formContent?.endDate) {
        return checkOnetimeRequestCoverage(
          patient?.coverages,
          formContent?.endDate,
          formConfiguration.blockUserIfLessThanRecommendedEndDate?.fieldSpec === "REQUIRED"
        );
      }
    }
    return undefined;
  }, [
    patient?.coverages,
    formContent?.dischargeDate,
    formContent?.endDate,
    formConfiguration.blockUserIfLessThanRecommendedEndDate?.fieldSpec,
  ]);

  const expectedDischargeExceedsAdmissionDate =
    formContent.expectedDischargeDate && formContent.admissionDate
      ? formContent.expectedDischargeDate >= formContent.admissionDate
      : false;
  const admissionDateExceedsMinAdmissionDate = formContent.admissionDate
    ? formContent.admissionDate >= MIN_START_DATE
    : false;
  const admissionDischargeDate = fieldIsValid(
    formConfiguration.admissionDischargeDate,
    expectedDischargeExceedsAdmissionDate && admissionDateExceedsMinAdmissionDate
  );

  const calculateInitializedEndDate = useCalculateInitializedEndDate(getPatientHealthPlanName(patient || undefined));
  const endDateCalculation = useCallback(
    (startDate: Date) => {
      if (isValid(startDate) && isDraftAuth) {
        const updatedEndDate = calculateInitializedEndDate(
          startDate,
          formContent.clinicalServices || [],
          patient?.coverages || []
        );
        if (updatedEndDate && updatedEndDate !== formContent.endDate) {
          return updatedEndDate;
        }
      }
      return formContent.endDate;
    },
    [calculateInitializedEndDate, formContent.clinicalServices, formContent.endDate, isDraftAuth, patient?.coverages]
  );
  const expectedMaxStartDate = isContinuation
    ? calculateStartEndDate(authorization, 1)?.endDate ?? MAX_DATE_SELECT_DATE
    : MAX_DATE_SELECT_DATE;

  const expectedAdmissionDateValue = !(formContent?.admissionDate || formContent?.expectedAdmissionDate);

  const expectedAdmissionDateValueForPlannedAdmission =
    formContent?.admissionDate || formContent?.expectedAdmissionDate;

  const updatePatientStatusForPlannedService = useCallback(() => {
    setFormContent((prevFormContent) => ({
      ...prevFormContent,
      admissionDate: undefined,
      expectedAdmissionDate: expectedAdmissionDateValueForPlannedAdmission,
    }));
  }, [expectedAdmissionDateValueForPlannedAdmission, setFormContent]);

  useEffect(() => {
    if (facilityBasedFeatureEnabled === false) {
      updatePatientStatusForPlannedService();
    }
  }, [facilityBasedFeatureEnabled, updatePatientStatusForPlannedService]);

  const [isStartDateInRange, setIsStartDateInRange] = useState(true);
  const [isEndDateInRange, setIsEndDateInRange] = useState(true);

  const startDate = formContent?.startDate;
  const defaultDuration = formContent?.clinicalServices?.[0]?.defaultDuration;
  const minDuration =
    formConfiguration.blockUserIfLessThanRecommendedEndDate?.fieldSpec === "REQUIRED" &&
    startDate &&
    defaultDuration &&
    new Date(new Date(startDate).setDate(new Date(startDate).getDate() + defaultDuration));

  return (
    <>
      {!formContent.isInpatient && (
        <>
          <OutPatientDateRangeSelect
            formContent={formContent}
            minDuration={minDuration}
            setFormContent={setFormContent}
            startDateCoverage={startDateCoverage}
            endDateCoverage={endDateCoverage}
            hasValidStartDate={hasValidStartDate || false}
            startDateErrorMessage={startDateErrorMessage}
            isContinuation={isContinuation || false}
            expectedMaxStartDate={expectedMaxStartDate}
            attemptedSubmit={attemptedSubmit}
            showExceededDurationLimitMessageErrorState={
              showExceededDurationLimitMessageErrorState || showBelowDurationLimitMessageErrorState
            }
            clearEndDate={clearEndDate}
            setClearEndDate={setClearEndDate}
            endDateCalculation={endDateCalculation}
            isStartDateInRange={isStartDateInRange}
            setIsStartDateInRange={setIsStartDateInRange}
            isEndDateInRange={isEndDateInRange}
            setIsEndDateInRange={setIsEndDateInRange}
            disabled={dateRangeDisabled}
          />
          <Grid item xs={12} md={12}>
            <PatientDateSelectionMessage
              formContent={formContent}
              coverages={patient?.coverages || []}
              patient={patient}
              formFieldConfigurations={formConfiguration}
            />
          </Grid>
        </>
      )}
      {!facilityBasedFeatureEnabled && formContent.isInpatient && (
        <Grid item xs={12}>
          <AdmissionAndExpectedDischargeDateSelect
            minDuration={minDuration}
            minAdmissionDate={MIN_START_DATE}
            admissionDate={formContent?.admissionDate}
            expectedAdmissionDate={formContent?.expectedAdmissionDate}
            expectedDischargeDate={formContent?.expectedDischargeDate}
            error={
              showExceededDurationLimitMessageErrorState ||
              showBelowDurationLimitMessageErrorState ||
              (attemptedSubmit && !admissionDischargeDate) ||
              (attemptedSubmit && expectedAdmissionDateValue) ||
              !startDateCoverage?.inRange
            }
            onUserEdit={onUserEdit}
            patient={patient}
            serviceType={formContent.serviceType}
            setFormContent={setFormContent}
            startDateCoverage={startDateCoverage}
            endDateCoverage={endDateCoverage}
            isDraftAuth={isDraftAuth}
            formContent={formContent}
            attemptedSubmit={attemptedSubmit}
            setClearEndDate={setClearEndDate}
            isStartDateInRange={isStartDateInRange}
            setIsStartDateInRange={setIsStartDateInRange}
            isEndDateInRange={isEndDateInRange}
            setIsEndDateInRange={setIsEndDateInRange}
            showExceededDurationLimitMessageErrorState={
              showExceededDurationLimitMessageErrorState || showBelowDurationLimitMessageErrorState
            }
            formConfiguration={formConfiguration}
            disabled={stayDaysDisabled}
          />
        </Grid>
      )}
    </>
  );
}
