import { ComponentProps, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  TextField,
  Switch,
  Body2,
  Body1,
  colorsLight,
  formatDateTimeStrToISO,
  formatDateStr,
  DateSelect,
  Body3,
} from "@coherehealth/common";
import { useTheme } from "@material-ui/core";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { isValid, isAfter, isBefore, isFuture, parseISO } from "date-fns";
import { RequestorChannel, RequestorType } from "@coherehealth/core-platform-api";

type Props = {
  channel: RequestorChannel | RequestorType;
  timestamp: string | undefined;
  setTimestamp: Dispatch<SetStateAction<string | undefined>>;
  setHasValidReceivedDate: Dispatch<SetStateAction<boolean>>;
  attemptedSave?: boolean;
  hasValidReceivedDate: boolean;
  responsiveMdWidth?: boolean;
  dataTestId?: string;
  hideLabel?: boolean;
  style?: {
    dateTimeOuterGridStyle?: React.CSSProperties;
    dateSelectGridStyle?: React.CSSProperties;
    dateHourGridStyle?: React.CSSProperties;
    dateMinuteGridStyle?: React.CSSProperties;
  };
};

interface dateTime {
  currDate: Date | undefined;
  currHours: number | undefined;
  currMinutes: number | undefined;
  currAM: boolean;
}

const generateTimeComponents = (timestamp: string): dateTime => {
  const currDate = timestamp ? parseISO(timestamp) : undefined;
  let currHours = currDate ? currDate.getHours() : undefined;
  const currAM = currHours !== undefined ? currHours < 12 : false;
  if (currHours !== undefined) {
    currHours = currHours % 12;
    currHours = currHours ? currHours : 12;
  }
  const currMinutes = currDate ? currDate.getMinutes() : undefined;
  return { currDate: currDate, currHours: currHours, currMinutes: currMinutes, currAM: currAM };
};

export function removeSecondsFromTimestamp(timestamp: string): string {
  const { currDate, currHours, currMinutes, currAM } = generateTimeComponents(timestamp || "");
  return formatDateTimeStrToISO(formatDateStr(currDate), Number(currHours), Number(currMinutes), currAM);
}
const minDate = new Date(2020, 0, 1);
let maxDate = new Date();

export const DateTimePicker = ({
  channel,
  timestamp,
  setTimestamp,
  attemptedSave,
  setHasValidReceivedDate,
  hasValidReceivedDate,
  dataTestId,
  hideLabel,
  style,
}: Props) => {
  const theme = useTheme();
  const currDateTime = useMemo(() => generateTimeComponents(timestamp || ""), [timestamp]);
  const currMinutesAsString = useMemo(
    () =>
      currDateTime.currMinutes || currDateTime.currMinutes === 0
        ? currDateTime.currMinutes < 10
          ? "0" + currDateTime.currMinutes
          : currDateTime.currMinutes.toString()
        : "",
    [currDateTime.currMinutes]
  );
  const [date, setDate] = useState<Date | undefined>(currDateTime?.currDate);
  const [hours, setHours] = useState<string>(currDateTime.currHours?.toString() || "");
  const [minutes, setMinutes] = useState<string>(currMinutesAsString);
  const [am, setAm] = useState<boolean>(currDateTime.currAM);
  const isFutureDate = timestamp ? isFuture(new Date(timestamp)) : false;

  useEffect(() => {
    maxDate = new Date();
    const hasValidReceivedDate = Boolean(
      date &&
        isValid(date) &&
        !isBefore(date, minDate) &&
        !isAfter(date, maxDate) &&
        timestamp &&
        hours &&
        minutes &&
        !isFutureDate
    );
    setHasValidReceivedDate(hasValidReceivedDate);
  }, [date, hours, isFutureDate, minutes, timestamp, setHasValidReceivedDate]);

  useEffect(() => {
    //initializes timestamp into individual date entry items
    const newDate = currDateTime.currDate;
    const newHours = currDateTime.currHours?.toString() || "";
    const newMinutes = currMinutesAsString;
    const newAm = currDateTime.currAM;

    setDate(newDate);
    setHours(newHours);
    setMinutes(newMinutes);
    setAm(newAm);
  }, [currDateTime, currMinutesAsString]);

  const updateTimestampFromInput = (dateObj: {
    newDate?: Date;
    newHours?: string;
    newMinutes?: string;
    newAm?: boolean;
  }) => {
    const { newDate, newHours, newMinutes, newAm } = dateObj;
    const innerDate = newDate ?? date;
    const innerHours = newHours ?? hours;
    const innerMinutes = newMinutes ?? minutes;
    const innerAm = newAm !== undefined ? newAm : am;
    if (innerDate && innerHours && innerMinutes) {
      const updatedTimestamp = formatDateTimeStrToISO(
        formatDateStr(innerDate),
        Number(innerHours),
        Number(innerMinutes),
        innerAm
      );

      //only setting this if it's been updated
      if (updatedTimestamp !== timestamp) {
        setTimestamp(updatedTimestamp);
      }
    }
  };

  const isAor = channel === "MEMBER_REPRESENTATIVE";

  const getLabelText = () => {
    if (isAor) {
      return "Appointment of Representative details";
    } else {
      return `When was this ${
        channel === "EMAIL" || channel === "MAIL" || channel === "PHONE" ? "contact" : "fax"
      } received`;
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        {!hideLabel && <Body2 data-public>{getLabelText()}</Body2>}
        {channel === "FAX" && (
          <Body3 style={{ paddingTop: theme.spacing(1) }} data-public>
            The time of fax receipt is auto-populated below when available. This should only be updated if inaccurate or
            the fax has been forwarded between departments
          </Body3>
        )}
      </div>
      <div data-testid={dataTestId ?? ""} style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "5px" }}>
        <div style={{ marginTop: theme.spacing(2), flexGrow: 1, ...style?.dateSelectGridStyle }}>
          <DateSelect
            error={(attemptedSave && !hasValidReceivedDate) || isFutureDate}
            helperText={
              attemptedSave && !date
                ? "Please fill in the received date of contact"
                : isFutureDate
                ? "Future dates and times are not allowed. Please enter a valid date and time"
                : ""
            }
            label="Date"
            minDate={minDate}
            maxDate={maxDate}
            value={date || null}
            onDateChange={(date) => {
              setDate(date);
              updateTimestampFromInput({ newDate: date });
            }}
            TextFieldProps={{
              fullWidth: true,
            }}
            disablePortal={true}
            data-testid={`${dataTestId}-date-field`}
          />
        </div>
        <div style={{ display: "flex", gap: "5px", marginTop: theme.spacing(2) }}>
          <div style={{ maxWidth: "134px", ...style?.dateHourGridStyle }}>
            <AutoShrinkLabelTextField
              fullWidth
              type="text"
              label="Hour"
              value={hours}
              onChange={(e: { currentTarget: { value: string } }) => {
                let strVal = e.currentTarget.value;
                const numVal = Math.trunc(Number(strVal));
                if (!isNaN(numVal) && numVal >= 1 && numVal <= 12) {
                  strVal = numVal.toString();
                } else {
                  strVal = "";
                }
                setHours(strVal);
                updateTimestampFromInput({ newHours: strVal });
              }}
              data-testid={`${dataTestId}-hour-field`}
            />
          </div>
          <div style={{ maxWidth: "134px", ...style?.dateMinuteGridStyle }}>
            <AutoShrinkLabelTextField
              fullWidth
              type="text"
              label="Mins"
              value={minutes}
              onChange={(e: { currentTarget: { value: string } }) => {
                let strVal = e.currentTarget.value;
                const numVal = Math.trunc(Number(strVal));
                if (!isNaN(numVal) && numVal <= 59) {
                  if (strVal === "00") {
                    strVal = strVal.toString();
                  } else {
                    strVal = numVal.toString();
                  }
                } else {
                  strVal = "";
                }
                setMinutes(strVal);
                updateTimestampFromInput({ newMinutes: strVal });
              }}
              data-testid={`${dataTestId}-min-field`}
            />
          </div>
          <div style={{ paddingTop: 18, paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>
            <MValueBody data-public>PM</MValueBody>
          </div>
          <div style={{ paddingTop: theme.spacing(1) }}>
            <Switch
              checked={am}
              onChange={(isAm) => {
                setAm(isAm);
                updateTimestampFromInput({ newAm: isAm });
              }}
              label="AM"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const AutoShrinkLabelTextField = (props: ComponentProps<typeof TextField>) => (
  <TextField InputLabelProps={props.value ? { shrink: true } : {}} data-public {...props} />
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const MValueBody = styled(Body1)(({ theme }) => ({
  color: colorsLight.font.main,
}));
