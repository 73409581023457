import { AuthorizationResponse } from "@coherehealth/core-platform-api";
import { Divider, Grid, IconButton, makeStyles } from "@material-ui/core";
import { useCallback, useContext, useEffect, useState } from "react";
import { getLatestDeterminedServiceRequest, getNextServiceRequest, getPriorServiceRequest } from "util/authorization";
import { ServiceRequestStatusDisplay } from "components/ServiceRequestStatusDisplay/StatusBanner/ServiceRequestStatusDisplay";
import CloseIcon from "@material-ui/icons/Close";
import { H4, useFeature } from "@coherehealth/common";
import { RequestDetailsContent } from "components/ServiceRequest/ReadonlyDetail/RequestDetails";
import { ClinicalServicesWithProcedures } from "components/PatientSummary/ServiceRequestSummaryCard/ClinicalServicesWithProcedures";
import { ExpandedServiceRequestContent } from "components/ServiceRequest/ExpandedServiceRequestCard/ExpandedServiceRequestContent";
import { formatServiceName } from "util/serviceRequest";
import { TrackUserActivityProps, useTrackUserInteraction } from "util/userActivityTracker";
import { User, UserContext } from "UserContext";
import { FaxAuthorizationContext } from "util/context/FaxAuthorizationContext";

interface IProps {
  authorization: AuthorizationResponse;
  setFlyoutOpen: ((auth: AuthorizationResponse | undefined) => void) | undefined;
}

const useStyles = makeStyles((theme) => ({
  "@keyframes slideIn": {
    "0%": {
      transform: "translateX(100%)",
    },
    "100%": {
      transform: "translateX(0%)",
    },
  },
  "@keyframes slideOut": {
    "0%": {
      transform: "translateX(0%)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
  slideIn: {
    animation: "$slideIn 0.5s",
  },
  slideOut: {
    animation: "$slideOut 0.5s",
  },
  container: {
    display: "flex",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: "calc(100% - 616px)",
    height: "100vh",
    zIndex: 99,
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  expandedRowClickable: {
    borderRadius: 0,
    boxShadow: "none !important",
    cursor: "pointer",
    "&:active": {
      backgroundColor: "#F5F5F5",
    },
  },
  expandedRow: {
    borderRadius: 0,
    boxShadow: "none !important",
    cursor: "",
  },
  onSidebar: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0, 3),
    boxShadow:
      "0px 8px 16px 0px rgba(0, 0, 0, 0.2), 0px 6px 7.5px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
  },
  closeButtonContainer: {
    marginLeft: "auto",
  },
  closeButton: {
    height: "24px",
    width: "24px",
  },
  content: {
    width: "616px",
  },
  card: {
    backgroundColor: "#FFFFFF",
    border: theme.palette.divider,
    borderRadius: "8px",
    marginBottom: theme.spacing(1),
  },
  cardBody: {
    padding: theme.spacing(0, 3),
  },
  scrollWrapper: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: 1,
  },
  divider: {
    height: 2,
  },
  expandedSrContent: {
    padding: theme.spacing(3, 3),
    border: "none",
    boxShadow: "none",
  },
  titleMargin: {
    marginBottom: theme.spacing(2),
  },
  header: {
    width: "100%",
    display: "flex",
    padding: theme.spacing(3, 0),
    alignItems: "center",
  },
  flyoutDivider: {
    width: "100%",
    height: "2px",
    margin: theme.spacing(2, 0),
    backgroundColor: "#E5E5E5",
  },
  expandedDateHeader: {
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
  },
}));

const actionConfigurations = {
  printActionConfiguration: {
    disabled: true,
    hidden: true,
    noRender: true,
  },
  startContinuationActionConfiguration: {
    disabled: true,
    hidden: true,
  },
  draftContinueActionConfiguration: {
    disabled: true,
    hidden: true,
  },
  draftDeleteActionConfiguration: {
    disabled: true,
    hidden: true,
  },
  editStatusActionConfiguration: {
    disabled: false,
    hidden: true,
  },
};

export default function AuthDetailsFlyout({ authorization, setFlyoutOpen }: IProps) {
  const classes = useStyles();
  const latestDeterminedSr = getLatestDeterminedServiceRequest(authorization);
  const trackUserActivityInteraction = useTrackUserInteraction();
  const { getUser } = useContext(UserContext);

  const [contentContainerClassNames, setContentContainerClassNames] = useState(`${classes.content} ${classes.slideIn}`);
  const showPerformingProviderPracticeFieldFF = useFeature("showPerformingProviderPracticeField");

  const closeFlyout = useCallback(async () => {
    setContentContainerClassNames(`${classes.content} ${classes.slideOut}`);

    setTimeout(() => {
      setFlyoutOpen?.(undefined);
    }, 500);
  }, [classes, setFlyoutOpen]);

  useEffect(() => {
    getUser()?.then((currentUser: User) => {
      const userActivityInteractionPayload: TrackUserActivityProps = {
        event: "VIEW_AUTH_DETAILS",
        stage: "NON_COHERE_TEMPLATE_FAX_FLOW",
        interactionAccept: true,
        activityContext: {
          cohereAuthId: authorization.id,
          userId: currentUser?.sub,
        },
      };
      trackUserActivityInteraction(userActivityInteractionPayload);
    });
  }, [authorization.id, getUser, trackUserActivityInteraction]);

  const { shouldShowAdditionalCareParticipants } = useContext(FaxAuthorizationContext);

  if (!latestDeterminedSr) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <div className={contentContainerClassNames}>
        <div className={classes.onSidebar}>
          <IconButton
            className={classes.closeButtonContainer}
            onClick={closeFlyout}
            data-testid="auth-details-flyout-close-btn"
          >
            <CloseIcon className={classes.closeButton} />
          </IconButton>
          <div className={`${classes.card} ${classes.scrollWrapper}`}>
            <div className={classes.cardBody}>
              <div className={classes.header}>
                <H4>{formatServiceName(latestDeterminedSr)}</H4>
              </div>
              {latestDeterminedSr && (
                <ServiceRequestStatusDisplay
                  onSubmission={undefined}
                  serviceRequest={latestDeterminedSr}
                  actionConfiguration={actionConfigurations}
                />
              )}
              <div className={classes.flyoutDivider} />
              <H4 className={classes.titleMargin}>Details</H4>
              <RequestDetailsContent
                serviceRequest={latestDeterminedSr}
                authorization={authorization}
                oonReviewConfig={undefined}
                isFacilityBasedAuth={latestDeterminedSr.isFacilityBased}
                ruleActions={[]}
                isFaxEdit={false}
                usePatientDatesAuthModal={true}
                simplifiedServiceFrequency={true}
                showPerformingProviderPracticeFieldFF={showPerformingProviderPracticeFieldFF}
                hideFacilityState={true}
                shouldIncludeAdditionalCareParticipants={shouldShowAdditionalCareParticipants(latestDeterminedSr)}
              />
              <ClinicalServicesWithProcedures serviceRequest={latestDeterminedSr} authorization={authorization} />
            </div>
            {authorization.serviceRequestsOnAuth &&
              authorization.serviceRequestsOnAuth.length > 1 &&
              authorization.serviceRequestsOnAuth
                .filter((sr) => sr.authStatus !== "DRAFT")
                .map((sr) => (
                  <Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                    </Grid>

                    <div className={classes.expandedSrContent}>
                      <ExpandedServiceRequestContent
                        key={sr.id}
                        sr={sr}
                        isFacilityBased={latestDeterminedSr.isFacilityBased}
                        limitVoidsAfterApproval={true}
                        clinicalReviewView={false}
                        includePatientStayDatesOnPlannedAdmission={true}
                        classes={classes}
                        priorSr={getPriorServiceRequest(sr, authorization)}
                        nextSr={getNextServiceRequest(sr, authorization)}
                      />
                    </div>
                  </Grid>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}
