import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CardTextField, H6, InlineButton, Pill, Tooltip } from "@coherehealth/common";
import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import ContactInfoModal, { ContactInfoModalProps } from "components/ContactInfoModal";
import { Facility, Provider } from "@coherehealth/core-platform-api";

export interface ReferralRequestSelectionCardProps {
  referralTitle: string;
  status: string;
  authNumber?: string;
  trackingNumber: string;
  primaryDiagnosisCode: string;
  secondaryDiagnosisCodes?: string;
  datesValid: string;
  referringProvider: Provider | null | undefined;
  specialistPractice: Facility | null | undefined;
  specialist: Provider | null | undefined;
  specialty: string;
  healthPlanName: string;
  onSelect: () => void;
  onUnSelect: () => void;
  selected: boolean | undefined;
  canAttach: boolean;
  selectedReferralRequestId?: string | null | undefined;
  selectedServiceRequestIds?: string[] | null | undefined;
  referralId: string;
}

const referralRequestSelectionCardStyles = makeStyles((theme) => ({
  referralRequestSelectionCard: {
    border: `1px solid ${theme.palette.divider}`,
    minHeight: 225,
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    gap: theme.spacing(1),
  },
  cardHeaderLine: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  cardHeaderItem: {
    display: "inline-block",
    marginRight: theme.spacing(1),
  },
  pillsRow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  pill: {
    marginRight: theme.spacing(1),
  },
  viewButtonContainer: {
    display: "flex",
    gap: "12px",
  },
  viewInfoLink: {
    minWidth: "fit-content",
    padding: 0,
  },
  actionButton: {
    flex: 1,
    justifyContent: "start",
    padding: "16px 16px 16px 0px",
  },
  modalStyles: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "600px",
      minWidth: "600px",
    },
  },
  textWrap: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  removeButton: {
    color: theme.palette.error.main,
    "&:hover,&:focus": {
      color: theme.palette.error.main,
    },
    "&:active": {
      color: theme.palette.error.main,
    },
  },
}));
interface SpecialtyFieldComponentProps {
  specialty: string;
  className?: string;
}

const SpecialtyFieldComponent: React.FC<SpecialtyFieldComponentProps> = ({ specialty, className }) => {
  return (
    <Tooltip interactive title={specialty} disableHoverListener={specialty.length <= 25}>
      <Typography variant="body1" className={className}>
        {specialty}
      </Typography>
    </Tooltip>
  );
};

interface SpecialistModalComponentProps {
  name: string;
  classes: ReturnType<typeof referralRequestSelectionCardStyles>;
  onClick: () => void;
}

const SpecialistModalComponent: React.FC<SpecialistModalComponentProps> = ({ name, classes, onClick }) => {
  if (!name || name.trim() === "") {
    return <>{"--"}</>;
  }
  return (
    <div className={classes.viewButtonContainer}>
      <Tooltip interactive title={name ?? ""} disableHoverListener={name ? name.length <= 30 : true}>
        <Typography variant="body1" className={classes.textWrap}>
          {name ?? ""}
        </Typography>
      </Tooltip>
      <InlineButton className={classes.viewInfoLink} onClick={onClick}>
        View info
      </InlineButton>
    </div>
  );
};

interface StatusLabelProps {
  status: string;
  classes: ReturnType<typeof referralRequestSelectionCardStyles>;
  healthPlanName: string;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status, classes, healthPlanName }) => {
  switch (status) {
    case "APPROVED":
      return <Pill className={classes.pill} label={"Covered"} variant={"success"} />;
    case "DENIED":
      return <Pill className={classes.pill} label={"Non-covered"} variant={"error"} />;
    case "PENDING":
      return <Pill className={classes.pill} label={`Pending review by ${healthPlanName}`} variant={"warning"} />;
    case "DRAFT":
      return <Pill className={classes.pill} label={"Draft"} variant={"info"} />;

    default:
      return <Pill className={classes.pill} label={"Unknown"} variant={"primary"} />;
  }
};

export function ReferralRequestSelectionCard(props: ReferralRequestSelectionCardProps): JSX.Element {
  const {
    referralId,
    selectedReferralRequestId,
    specialty,
    specialist,
    specialistPractice,
    referringProvider,
    healthPlanName,
    referralTitle,
    selected,
    selectedServiceRequestIds,
    status,
    primaryDiagnosisCode,
    secondaryDiagnosisCodes,
    authNumber,
    trackingNumber,
    onSelect,
    onUnSelect,
  } = props;
  const attachmentCTADisabled =
    selectedServiceRequestIds?.length === 0
      ? selectedReferralRequestId == null || selectedReferralRequestId === ""
        ? false
        : referralId !== selectedReferralRequestId
      : true;
  const classes = referralRequestSelectionCardStyles();

  const [modalState, setModalState] = useState<ContactInfoModalProps>({
    open: false,
    handleClose: () => {
      setModalState((prev) => ({ ...prev, open: false }));
    },
    type: "FACILITY",
  });

  return (
    <Card className={classes.referralRequestSelectionCard} key={`ref_request_selection_card_${referralId}`}>
      <Grid container>
        <ContactInfoModal
          open={modalState.open}
          handleClose={modalState.handleClose}
          type={modalState.type}
          name={modalState.name}
          phones={modalState.phones}
          fax={modalState.fax}
          email={modalState.email}
          npi={modalState.npi}
          tinList={modalState.tinList}
          addresses={modalState.addresses}
          npiLabel={modalState.npiLabel}
        />

        <div className={classes.cardHeaderLine}>
          <H6 className={classes.cardHeaderItem}>{referralTitle}</H6>
          <div className={classes.pillsRow}>
            <div>
              {!!authNumber && (
                <Pill className={classes.cardHeaderItem} label={`Auth #${authNumber}`} variant="info" data-public />
              )}
              {!!trackingNumber && (
                <Pill
                  className={classes.cardHeaderItem}
                  label={`Tracking #${trackingNumber}`}
                  variant="info"
                  data-public
                />
              )}
              {!!status && <StatusLabel status={status} classes={classes} healthPlanName={healthPlanName} />}
            </div>
          </div>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <CardTextField label="Primary diagnosis code" content={primaryDiagnosisCode} />
          </Grid>
          <Grid item xs={5}>
            <CardTextField label="Secondary diagnosis" content={secondaryDiagnosisCodes ?? "--"} />
          </Grid>
          <Grid item xs={5}>
            <CardTextField label="Dates valid" content={props.datesValid} />
          </Grid>
          <Grid item xs={7}>
            <CardTextField
              label="Referral Provider"
              content={
                <SpecialistModalComponent
                  classes={classes}
                  name={referringProvider?.name ?? ""}
                  onClick={() =>
                    setModalState({
                      handleClose: () => setModalState((prev) => ({ ...prev, open: false })),
                      open: true,
                      name: referringProvider?.name || "None",
                      phones: referringProvider?.selectedLocation?.phoneNumbers,
                      type: "REFERRING_PROVIDER",
                      tinList: [
                        healthPlanName === "BCBS South Carolina"
                          ? "--"
                          : referringProvider?.selectedLocation?.tin || "None",
                      ],
                      npi: referringProvider?.npi,
                      addresses: [referringProvider?.selectedLocation?.address],
                      npiLabel: referringProvider?.npi || "N/A",
                    })
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={5}>
            <CardTextField
              label="Specialty"
              content={<SpecialtyFieldComponent specialty={specialty} className={classes.textWrap} />}
            />
          </Grid>
          <Grid item xs={7}>
            <CardTextField
              label="Specialist practice"
              content={
                <SpecialistModalComponent
                  classes={classes}
                  name={specialistPractice?.name ?? ""}
                  onClick={() =>
                    setModalState({
                      handleClose: () => setModalState((prev) => ({ ...prev, open: false })),
                      open: true,
                      name: specialistPractice?.name || "None",
                      phones: specialistPractice?.selectedLocation?.phoneNumbers,
                      type: "SPECIALIST_PRACTICE",
                      tinList: [
                        healthPlanName === "BCBS South Carolina"
                          ? "--"
                          : specialistPractice?.selectedLocation?.tin || "None",
                      ],
                      npi: specialistPractice?.npi,
                      addresses: [specialistPractice?.selectedLocation?.address],
                      npiLabel: specialistPractice?.npi || "N/A",
                    })
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CardTextField
              label="Specialist"
              content={
                <SpecialistModalComponent
                  classes={classes}
                  name={specialist?.name ?? ""}
                  onClick={() =>
                    setModalState({
                      handleClose: () => setModalState((prev) => ({ ...prev, open: false })),
                      open: true,
                      name: specialist?.name || "None",
                      phones: specialist?.selectedLocation?.phoneNumbers,
                      type: "SPECIALIST",
                      tinList: [
                        healthPlanName === "BCBS South Carolina" ? "--" : specialist?.selectedLocation?.tin || "None",
                      ],
                      npi: specialist?.npi,
                      addresses: [specialist?.selectedLocation?.address],
                      npiLabel: specialist?.npi || "N/A",
                    })
                  }
                />
              }
            />
          </Grid>
        </Grid>
        {!selected && (
          <InlineButton
            disabled={attachmentCTADisabled}
            startIcon={<AddCircleIcon />}
            className={classes.actionButton}
            onClick={onSelect}
            data-public
          >
            Attach to request
          </InlineButton>
        )}
        {selected && (
          <InlineButton
            startIcon={<RemoveCircleOutlineIcon />}
            className={classes.actionButton}
            classes={{ textPrimary: classes.removeButton }}
            onClick={onUnSelect}
            data-public
          >
            Remove from request
          </InlineButton>
        )}
      </Grid>
    </Card>
  );
}
