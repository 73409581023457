import React, { Dispatch, SetStateAction } from "react";
import { Body1, Card, colorsLight, H4, PrimaryButton } from "@coherehealth/common";
import { Organization } from "@coherehealth/core-platform-api";
import BuildingIcon from "../images/BuildingIcon.svg";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { OnboardingSteps } from "./shared";

const useStyles = makeStyles((theme) => ({
  logo: {
    padding: "40px",
    width: "30%",
    alignSelf: "center",
  },
  joinButton: {
    alignSelf: "right",
    width: theme.spacing(18),
  },
}));

interface Props {
  organization: Organization;
  setCurrentStep: Dispatch<SetStateAction<OnboardingSteps>>;
  setSelectedProviderOrgId: Dispatch<SetStateAction<string>>;
  setSelectedProviderOrgName: Dispatch<SetStateAction<string>>;
  setSelectedProviderOrg: Dispatch<SetStateAction<Organization>>;
}

export default function OrganizationResultCard({
  organization,
  setCurrentStep,
  setSelectedProviderOrgId,
  setSelectedProviderOrgName,
  setSelectedProviderOrg,
}: Props) {
  const classes = useStyles();

  const displayedTinList =
    organization?.tinList && organization.tinList.length > 0
      ? organization.tinList
          .filter((tin) => !!tin) // Don't display tins that are null (we've encountered some. see COH-3320)
          .map((tin) => {
            return tin.length === 9 ? [tin.slice(0, 2), "-", tin.slice(2, tin.length)].join("") : tin;
          })
          .join(", ")
      : "xxxxxxxxx";

  return (
    <>
      <Card>
        <CardContents>
          <CardContents>
            <img src={BuildingIcon} alt={""} />
            <TextContainer>
              <H4 data-testid="org-onboarding-org-name">{organization.name} </H4>
              <LightBody1 display={"inline"}>{displayedTinList}</LightBody1>
            </TextContainer>
          </CardContents>
          <PrimaryButton
            className={classes.joinButton}
            onClick={() => {
              setSelectedProviderOrgId(organization.id || "");
              setSelectedProviderOrgName(organization.name || "");
              setSelectedProviderOrg(organization);
              setCurrentStep("EXISTING_ORG_USER_INFO");
            }}
            data-tracking-id="join-existing-org-start"
          >
            Join
          </PrimaryButton>
        </CardContents>
      </Card>
    </>
  );
}
// eslint-disable-next-line cohere-react/no-mui-styled-import
const CardContents = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
// eslint-disable-next-line cohere-react/no-mui-styled-import
const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  paddingLeft: theme.spacing(2),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const LightBody1 = styled(Body1)(({ theme }) => ({
  color: colorsLight.font.light,
  marginTop: theme.spacing(1),
}));
