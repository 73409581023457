import React, { ComponentProps, SetStateAction } from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, Theme } from "@material-ui/core";
import {
  Body1,
  H2,
  MultiSelectDropdown,
  PrimaryButton,
  SecondaryButton,
  SingleSelectDropdown,
} from "@coherehealth/common";
import {
  NOTIFICATION_PURPOSE_OPTIONS,
  NOTIFICATION_RECIPIENTS_OPTIONS,
} from "components/PatientSummary/AuthorizationPatientSummary/AuthorizationPatientSummaryUtils";

interface Props {
  title: string;
  subTitle?: string;
  theme: Theme;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
  selectedRecipients: string[];
  setSelectedRecipients: React.Dispatch<SetStateAction<string[]>>;
  selectedNotificationPurpose: string;
  setSelectedNotificationPurpose: React.Dispatch<SetStateAction<string>>;
}

const SendNewAuthNotificationModal = ({
  title,
  subTitle,
  theme,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  selectedRecipients,
  setSelectedRecipients,
  selectedNotificationPurpose,
  setSelectedNotificationPurpose,
}: Props) => {
  const handleSelectNotificationRecipients = (recipients: string[]) => {
    setSelectedRecipients(recipients);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: theme.spacing(2), width: theme.spacing(76) },
      }}
      data-testid="new-auth-notification-modal"
    >
      <MuiDialogTitle>
        <MuiIconButton
          style={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </MuiIconButton>
      </MuiDialogTitle>
      <MuiDialogContent
        style={{
          padding: `${theme.spacing(3)}px ${theme.spacing(7)}px`,
          overflow: "visible",
        }}
      >
        <Grid container spacing={2} component="form" onSubmit={() => true}>
          <Row data-public>
            {title && <H2>{title}</H2>}
            {subTitle && (
              <Body1
                style={{
                  color: theme.palette.text.secondary,
                  padding: theme.spacing(1, 0),
                }}
              >
                {subTitle}
              </Body1>
            )}
          </Row>
          <Row>
            <SingleSelectDropdown
              label="Purpose"
              value={selectedNotificationPurpose}
              options={NOTIFICATION_PURPOSE_OPTIONS}
              onChange={(value: string) => setSelectedNotificationPurpose(value)}
              disabled={true}
              data-testid="notification-modal-purpose-dropdown"
            />
          </Row>
          <Row>
            <MultiSelectDropdown
              label="Recipients"
              options={NOTIFICATION_RECIPIENTS_OPTIONS}
              selectedValues={selectedRecipients}
              onChange={handleSelectNotificationRecipients}
              data-testid="notification-modal-recipients-dropdown"
            />
          </Row>
          <Row>
            <PrimaryButton
              loading={isLoading}
              disabled={isLoading || selectedRecipients.length === 0 || !selectedNotificationPurpose}
              type="submit"
              onClick={onSubmit}
              fullWidth
              style={{
                marginTop: theme.spacing(2),
                alignSelf: "center",
                marginBottom: theme.spacing(2),
              }}
              data-testid="notification-modal-primary-button"
            >
              Create notifications
            </PrimaryButton>
            <SecondaryButton
              type="button"
              onClick={onClose}
              fullWidth
              style={{
                alignSelf: "center",
                marginBottom: theme.spacing(4),
              }}
              data-testid="notification-modal-secondary-button"
            >
              Cancel
            </SecondaryButton>
          </Row>
        </Grid>
      </MuiDialogContent>
    </Dialog>
  );
};

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

export default SendNewAuthNotificationModal;
