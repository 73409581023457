import { CardTextField } from "@coherehealth/common";
import { AdditionalCareParticipant } from "@coherehealth/core-platform-api";
import { Grid } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { ContactInfoModalProps } from "components/ContactInfoModal";
import { useCallback } from "react";
import { PracticeModalComponent } from "./AuthorizationCardCommonSection";
import { getAdditionalCareParticipantName } from "util/serviceRequest";

export function CareParticipantModal({
  careParticipant,
  classes,
  setModalState,
}: {
  careParticipant: AdditionalCareParticipant;
  classes: ClassNameMap;
  setModalState: React.Dispatch<React.SetStateAction<ContactInfoModalProps>>;
}): JSX.Element {
  const careParticipantClickHandler = (
    careParticipant: AdditionalCareParticipant,
    setModalState: React.Dispatch<React.SetStateAction<ContactInfoModalProps>>
  ) => {
    setModalState({
      handleClose: () => setModalState((prev: ContactInfoModalProps) => ({ ...prev, open: false })),
      open: true,
      name: careParticipant?.name || "None",
      phones: careParticipant?.phoneNumbers || careParticipant?.selectedLocation?.phoneNumbers,
      type: careParticipant.careParticipantType || "ORDERING_PROVIDER",
      tinList:
        careParticipant?.tinList ||
        (careParticipant?.selectedLocation?.tin ? [careParticipant.selectedLocation.tin] : []),
      npi: careParticipant?.npi,
      addresses:
        careParticipant?.addresses ||
        (careParticipant?.selectedLocation?.address ? [careParticipant.selectedLocation.address] : []),
      npiLabel: careParticipant?.npi || "N/A",
    });
  };

  const careParticipantOnClickHandler = useCallback(
    () => careParticipantClickHandler(careParticipant, setModalState),
    [careParticipant, setModalState]
  );

  return (
    <Grid item xs={12} data-public data-testid="placeOfService">
      <CardTextField
        label=""
        content={
          <PracticeModalComponent
            classes={classes}
            label={getAdditionalCareParticipantName(careParticipant.careParticipantType)}
            name={
              careParticipant
                ? `${careParticipant?.name ? careParticipant.name : `None`} / ${
                    `NPI - ` + (careParticipant?.npi || "N/A")
                  }`
                : "--"
            }
            onClick={careParticipantOnClickHandler}
          />
        }
      />
    </Grid>
  );
}
