import {
  ClinicalAssessment,
  ClinicalAssessmentResponse,
  RuleCondition,
  UnitedStates,
} from "@coherehealth/core-platform-api";

export interface GenericCode {
  code?: string;
  system?: string;
}

/**
 * Converts a set of codes from a ClinicalAssessment.question.clinicalQuestion or
 * ClinicalAssessment.question.clinicalQuestion.responseOption or
 * ClinicalAssessment.answer to a string in an unambiguous way. This is used
 * either to generate a ID for an id/label pair or for comparison of one of the
 * aforementioned types
 */
export const encodeCodesAsString = (observationCodes: GenericCode[] | undefined) =>
  observationCodes?.map(encodeCode).join("##") || "";

/**
 * Inverse of encodeCodesAsString
 */
export const decodeCodes = (encoded: string): { code: string; system: string }[] => {
  let returnStatement = encoded.split("##").map(decodeCode);
  return returnStatement;
};

const encodeCode = ({ code, system }: GenericCode) => `${system}::${code}`;
const decodeCode = (encoded: string) => {
  const [system, code] = encoded.split("::");
  return { system, code };
};

export const singleAnswerIsValid = (ipt: string | undefined) => {
  return !ipt || ipt === "" || !isNaN(Number(ipt));
};

interface ClinicalAssessmentResponseWithOriginalIndex extends ClinicalAssessmentResponseWithSubQuestions {
  originalIndex: number;
}

export type SubQuestion = ClinicalAssessmentResponse & {
  answerToRenderOn?: string;
  questionToRenderOn?: string;
};

export type ClinicalAssessmentResponseWithSubQuestions = ClinicalAssessmentResponse & {
  subQuestions?: SubQuestion[];
};

export interface ClinicalQuestionsConditionDataModel {
  clinicalQuestionResponses: Record<string, string>;
}

export function filterClinicalQuestionsByDisplayCondition(
  clinicalQuestions?: ClinicalAssessmentResponse[]
): ClinicalAssessmentResponseWithOriginalIndex[] {
  if (!clinicalQuestions) {
    return [];
  }

  const dataModel = createConditionDataModel(clinicalQuestions);

  return clinicalQuestions
    .map((question, originalIndex) => ({ ...question, originalIndex }))
    .filter((question) => {
      if (!question.clinicalQuestion?.displayCondition) {
        return true;
      } else {
        return evaluateDisplayCondition(question.clinicalQuestion?.displayCondition, dataModel);
      }
    });
}

export function assessmentWithOnlyServiceRequestCurrentFacilityStateValue(
  clinicalAssessment: ClinicalAssessment,
  currentFacilityState: UnitedStates
): ClinicalAssessment {
  let newClinicalAssessment = { ...clinicalAssessment };
  if (!clinicalAssessment.questions) {
    return newClinicalAssessment;
  }
  newClinicalAssessment.questions = filterByFacilityState(clinicalAssessment.questions, currentFacilityState);
  return newClinicalAssessment;
}

export function filterByFacilityState(
  clinicalQuestions?: ClinicalAssessmentResponse[],
  currentFacilityState?: UnitedStates
): ClinicalAssessmentResponseWithOriginalIndex[] {
  if (!clinicalQuestions) {
    return [];
  }

  return clinicalQuestions
    .map((question, originalIndex) => ({ ...question, originalIndex }))
    .filter((question) => {
      if (question.clinicalQuestion?.filterGroups && question.clinicalQuestion.filterGroups.length > 0) {
        let stateFilterGroup = question.clinicalQuestion?.filterGroups?.find(
          (group) => (group.filterStates || []).length > 0
        );
        if (stateFilterGroup && currentFacilityState) {
          return stateFilterGroup.filterStates?.includes(currentFacilityState);
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
}

export function determineCorrectFacilityState(
  clinicalQuestions?: ClinicalAssessmentResponse[],
  currentFacilityState?: UnitedStates
): ClinicalAssessmentResponseWithOriginalIndex[] {
  if (!clinicalQuestions) {
    return [];
  }

  const dataModel = createConditionDataModel(clinicalQuestions);

  const facilityStateQuestionsThatWereAnswers = clinicalQuestions
    .map((question, originalIndex) => ({ ...question, originalIndex }))
    .filter((question) => {
      if (question.clinicalQuestion?.filterGroups && question.clinicalQuestion.filterGroups.length > 0) {
        return (
          question.clinicalQuestion?.filterGroups?.find((group) => (group.filterStates || []).length > 0) !==
            undefined && (question.answers || []).length > 0
        );
      } else {
        return false;
      }
    });
  if (facilityStateQuestionsThatWereAnswers.length > 0) {
    return clinicalQuestions
      .map((question, originalIndex) => ({ ...question, originalIndex }))
      .filter((question) => {
        const hasFacilityState =
          question.clinicalQuestion?.filterGroups?.find((group) => (group.filterStates || []).length > 0) !== undefined;
        if (hasFacilityState) {
          return (question.answers || [])?.length > 0;
        } else if (!question.clinicalQuestion?.displayCondition) {
          return true;
        } else {
          return evaluateDisplayCondition(question.clinicalQuestion?.displayCondition, dataModel);
        }
      });
  } else {
    return filterClinicalQuestionsByDisplayCondition(filterByFacilityState(clinicalQuestions, currentFacilityState));
  }
}

/**
 * Creates a data model used to evaluate displayConditions on clinicalQuestions
 * @param questionsForClinicalAssessment List of questions from the assessment
 */
export function createConditionDataModel(
  questionsForClinicalAssessment: ClinicalAssessmentResponse[]
): ClinicalQuestionsConditionDataModel {
  const singleSelectQuestions = questionsForClinicalAssessment.filter((question) => {
    return question.clinicalQuestion?.type === "SINGLE_SELECT";
  });

  let responsesRecord: Record<string, string> = {};

  singleSelectQuestions.forEach((questionResponse) => {
    if (questionResponse.clinicalQuestion?.displayCondition) {
      const isQuestionShown =
        evaluateDisplayCondition(questionResponse.clinicalQuestion?.displayCondition, {
          clinicalQuestionResponses: responsesRecord,
        }) || !!questionResponse.answers;
      if (!isQuestionShown) {
        return;
      }
    }

    let ovId = questionResponse.clinicalQuestion ? questionResponse.clinicalQuestion.originalVersionId : "";
    let selectedAnswer = questionResponse.answers?.[0]; //singleselect always has 1 answer
    let selectedResponseOption = questionResponse.clinicalQuestion?.responseOptions?.find((responseOption) => {
      return responseOption.observationCodes?.every((obvCode) => {
        return !!selectedAnswer?.codes?.find((code) => {
          return code.code === obvCode.code && code.system === obvCode.system;
        });
      });
    });
    if (ovId && selectedResponseOption?.responseOptionText) {
      responsesRecord[ovId] = selectedResponseOption.responseOptionText;
    }
  });

  return {
    clinicalQuestionResponses: responsesRecord,
  };
}

/**
 * Evaluate displayCondition on from a clinicalQuestion using the provided data model
 * @param displayCondition Condition to be evaluated
 * @param dataModel From createConditionDataModel() above
 */
export function evaluateDisplayCondition(
  displayCondition: RuleCondition,
  dataModel: ClinicalQuestionsConditionDataModel
) {
  // todo evaluate condition more generically, look at Condition::evaluate function in the backend
  // todo remove all type assertions (i.e. `_ as _`)

  /*
   Right now this code assumes that the displayCondition object will always match this shape
      const matchingDisplayCondition: RuleCondition = {
        block: {
          logicalOperator: "ANY",
          conditions: [
            {
              statement: {
                operandA: {
                  value: "clinicalQuestionResponses.631b426097b1dfbb1be9814a",
                },
                operandB: {
                  value: ["Polidocanol microfoam (PEM) (e.g., Varithena)"],
                },
                operator: "contains_any",
              },
            },
          ],
        },
      };

    i.e. an ANY block w/ x number of conditions. In the initial conditional question build-out this should
    always be the case, but in reality conditions could be different
   */

  // todo handle nested blocks/statements/blocks that don't use "ANY" operator
  const matchingCondition = displayCondition.block?.conditions?.find((condition) => {
    if (condition.statement?.operator === "contains_any") {
      const operandAValue = getIndexedPropertyFromDataModel(condition.statement.operandA.value as string, dataModel);
      if (Array.isArray(condition.statement.operandB.value)) {
        return (condition.statement.operandB.value as string[]).includes(operandAValue);
      } else {
        // todo handle non-array operandB
        return false;
      }
    } else {
      // todo handle other operator types besides contains_any
      return false;
    }
  });

  return !!matchingCondition;
}

/**
 * Finds property with dot notation in the given data model
 * i.e.
 * getIndexedPropertyFromDataModel("property.nestedProperty", { property: { nestedProperty: 1 }}) === 1
 * @param propertyName The property to find, can use dot notation for nested objects
 * @param dataModel The data model to search in
 */
export function getIndexedPropertyFromDataModel(propertyName: string, dataModel: any): any {
  // todo write some more tests, I'm not sure that this is 100% right
  return propertyName.split(".").reduce((prev, curr) => {
    if (typeof prev === "object") {
      if (!!prev[curr]) {
        return prev[curr];
      }
      return curr;
    } else {
      return prev;
    }
  }, dataModel);
}

export const filterOutTempCohereCodes = (codes: GenericCode[] | undefined) => {
  return codes?.filter((code) => code.system !== "TEMP-COHERE");
};

/**
 * Given a clinical question and answers,
 * figure out which answers match the given question by their codes.
 * This is _almost_ the same as selectedOptions, except it preserves the order of the incoming
 * answers, which is very important when viewing
 */
export const selectAnswersForQuestion = ({ clinicalQuestion, answers }: ClinicalAssessmentResponse, type?: string) => {
  if (type === "AGADIA") {
    return (answers || []).filter((answer) =>
      clinicalQuestion?.responseOptions?.some((cq) => cq.optionId === answer.optionId)
    );
  } else {
    return (answers || []).filter((answer) =>
      clinicalQuestion?.responseOptions?.some(
        (cq) =>
          encodeCodesAsString(filterOutTempCohereCodes(cq.observationCodes)) ===
          encodeCodesAsString(filterOutTempCohereCodes(answer.codes))
      )
    );
  }
};
