import { ServiceRequestResponse } from "@coherehealth/core-platform-api";

export enum NotificationRecipientsEnum {
  PCP = "PCP",
  ORDERING_PROVIDER = "ORDERING_PROVIDER",
  PATIENT = "PATIENT",
  PERFORMING_PROVIDER = "PERFORMING_PROVIDER",
  PERFORMING_FACILITY = "PERFORMING_FACILITY",
  REQUESTOR = "REQUESTOR",
  MEMBER_REPRESENTATIVE = "MEMBER_REPRESENTATIVE",
  REFERRING_PROVIDER = "REFERRING_PROVIDER",
  PERFORMING_SPECIALIST = "PERFORMING_SPECIALIST",
}

export const NOTIFICATION_RECIPIENTS_OPTIONS = [
  { id: "PCP", label: "PCP" },
  { id: "ORDERING_PROVIDER", label: "Ordering Provider" },
  { id: "PATIENT", label: "Patient" },
  { id: "PERFORMING_PROVIDER", label: "Performing Provider" },
  { id: "PERFORMING_FACILITY", label: "Performing Facility" },
  { id: "REQUESTOR", label: "Requestor" },
  { id: "MEMBER_REPRESENTATIVE", label: "Member Representative" },
  { id: "REFERRING_PROVIDER", label: "Referring Provider" },
  { id: "PERFORMING_SPECIALIST", label: "Performing Specialist" },
];

export enum NotificationPurposeEnum {
  FINAL_DETERMINATION = "FINAL_DETERMINATION",
  VAT = "VAT",
  TAT_EXTENSION = "TAT_EXTENSION",
  ADHOC = "ADHOC",
}

export const NOTIFICATION_PURPOSE_OPTIONS = [
  { id: "FINAL_DETERMINATION", label: "Final Determination" },
  { id: "VAT", label: "VAT" },
  { id: "TAT_EXTENSION", label: "TAT Extension" },
  { id: "ADHOC", label: "ADHOC" },
];

export const isFinalDeterminationStatus = (serviceRequest: ServiceRequestResponse | null): boolean => {
  const finalDeterminationStatuses = ["APPROVED", "PARTIALLY_APPROVED", "DENIED", "DISMISSED", "WITHDRAWN"];
  const authStatus: string = serviceRequest?.authStatus || "";

  return finalDeterminationStatuses.includes(authStatus || "");
};
