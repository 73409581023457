import { RenderSearchProps } from "@react-pdf-viewer/search";
import { AttachmentInfo } from "./AttachmentViewerSidePanel";
import { MutableRefObject, useMemo, useRef, useState } from "react";

interface Props {
  handleAttachmentClick?: (index: number) => void;
  attachmentsInfo?: AttachmentInfo[];
  validAttachments: string[];
  loadingRemainingAttachments?: boolean;
}

interface IAttachmentsMultiSearchStateHolder {
  searchText: string;
  isSearchTextChanged: boolean;
  showSearchResults: boolean;
}

export interface IMultiSearchSearchJumpArgs {
  activeAttachmentId: string;
  autoFocusInput: boolean;
}

interface IAttachmentsMultiSearchUtilityHolder {
  doSearch: (args: IMultiSearchSearchJumpArgs) => void;
  doJumpToNextMatch: (args: IMultiSearchSearchJumpArgs) => void;
  doJumpToPreviousMatch: (args: IMultiSearchSearchJumpArgs) => void;
  doChangeSearchValue: (text: string) => void;
  doClearSearchValue: () => void;
  registerAttachmentSearchProps: (attachmentId: string, props: RenderSearchProps) => void;
  registerAttachmentSearchInputRef: (
    attachmentId: string,
    inputRef: MutableRefObject<HTMLInputElement | undefined>
  ) => void;
  setSidePanelScrollOffset: (scrollOffset: number) => void;
  getSidePanelScrollOffset: () => number;
  handleAttachmentFirstRender: (attachmentId: string) => void;
  handleAttachmentRemoval: (attachmentId: string) => void;
}

interface IAttachmentsMultiSearchStatsHolder {
  totalMatchesNumber: number;
  currentMatchNumber: number;
  shouldRenderTransientAttachments: boolean;
  isSearching: boolean;
}

export interface IAttachmentsMultiSearchHolder {
  state: IAttachmentsMultiSearchStateHolder;
  utilities: IAttachmentsMultiSearchUtilityHolder;
  stats: IAttachmentsMultiSearchStatsHolder;
}

type ISearchMatchesByAttachmentIds = { attachmentId: string; sidePanelIndex: number; matches: number }[];
type ICurrentSearchMatch = { attachmentId: string; sidePanelIndex: number; currentMatch: number } | undefined;

function useAttachmentsMultiSearch({
  handleAttachmentClick,
  attachmentsInfo,
  validAttachments,
  loadingRemainingAttachments,
}: Props): IAttachmentsMultiSearchHolder {
  const [attachmentsMultiSearchStateHolder, setAttachmentsMultiSearchStateHolder] =
    useState<IAttachmentsMultiSearchStateHolder>({
      isSearchTextChanged: false,
      searchText: "",
      showSearchResults: false,
    });

  const [currentSearchMatch, setCurrentSearchMatch] = useState<ICurrentSearchMatch>(undefined);
  const [searchMatchesByAttachmentIds, setSearchMatchesByAttachmentIds] = useState<ISearchMatchesByAttachmentIds>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [shouldRenderTransientAttachments, setShouldRenderTransientAttachments] = useState<boolean>(false);

  const searchPropsByAttachmentIds = useRef<{ [key: string]: RenderSearchProps }>({});
  const textInputRefByAttachmentIds = useRef<{ [key: string]: MutableRefObject<HTMLInputElement | undefined> }>({});
  const sidePanelScrollOffset = useRef<number>(0);

  const didChangeAttachmentsFromSearchControls = useRef<boolean>(false);
  const didChangeAttachmentsFromSearchControlsWithAutoFocus = useRef<boolean>(false);
  const renderedTransientAttachments = useRef<Set<string>>(new Set());

  const sortedSearchMatchesByAttachmentIds = useMemo(
    () => searchMatchesByAttachmentIds.sort((a, b) => a.sidePanelIndex - b.sidePanelIndex),
    [searchMatchesByAttachmentIds]
  );

  const totalMatchesNumber = useMemo(
    () => searchMatchesByAttachmentIds.reduce((prev, curr) => prev + curr.matches, 0),
    [searchMatchesByAttachmentIds]
  );

  const currentMatchNumber = useMemo(() => {
    if (!currentSearchMatch) {
      return 0;
    }
    let acc = currentSearchMatch.currentMatch ?? 1;
    for (const ele of sortedSearchMatchesByAttachmentIds) {
      if (ele.sidePanelIndex < currentSearchMatch.sidePanelIndex) {
        acc += ele.matches;
      }
    }

    return acc;
  }, [sortedSearchMatchesByAttachmentIds, currentSearchMatch]);

  // Public functions
  async function doSearch({ activeAttachmentId, autoFocusInput }: IMultiSearchSearchJumpArgs) {
    if (loadingRemainingAttachments) {
      return;
    }
    setIsSearching(true);

    const hasMoreThanOneAttachment = validAttachments.length > 1;

    if (hasMoreThanOneAttachment) {
      await __prepareTransientAttachments();

      await __syncAttachmentsKeywords();
    }

    const searchPromises = Object.keys(searchPropsByAttachmentIds.current).map((key) => {
      return searchPropsByAttachmentIds.current[key].search().then((matches) => {
        return { key, matches };
      });
    });

    const responses = await Promise.all(searchPromises);

    if (hasMoreThanOneAttachment) {
      await __removeTransientAttachments();
    }

    const searchMatchesByAttachmentIds: ISearchMatchesByAttachmentIds = responses.map((response) => {
      const sidePanelIndex = attachmentsInfo?.map((att) => att.attachmentId).indexOf(response.key) ?? 0;
      return {
        attachmentId: response.key,
        sidePanelIndex,
        matches: response.matches.length,
      };
    });

    const activeAttachmentMatches = searchMatchesByAttachmentIds.find((ele) => ele.attachmentId === activeAttachmentId);
    if (!activeAttachmentMatches) {
      // Impossible Case
      return;
    }

    setSearchMatchesByAttachmentIds(searchMatchesByAttachmentIds);
    setAttachmentsMultiSearchStateHolder((prev) => ({
      ...prev,
      showSearchResults: true,
      isSearchTextChanged: false,
    }));

    if (activeAttachmentMatches.matches > 0) {
      // Current active attachment contains matches
      setCurrentSearchMatch({
        attachmentId: activeAttachmentId,
        sidePanelIndex: activeAttachmentMatches.sidePanelIndex,
        currentMatch: 1,
      });
    } else {
      const eligibleSearchMatches = searchMatchesByAttachmentIds
        .sort((a, b) => a.sidePanelIndex - b.sidePanelIndex)
        .filter((ele) => ele.matches > 0);
      if (eligibleSearchMatches.length === 0) {
        // No Matches across all attachments
        setCurrentSearchMatch(undefined);
      } else {
        // Next Match is in another attachment
        setCurrentSearchMatch({
          attachmentId: eligibleSearchMatches[0].attachmentId,
          sidePanelIndex: eligibleSearchMatches[0].sidePanelIndex,
          currentMatch: 1,
        });

        __doHandleAttachmentChange(
          eligibleSearchMatches[0].sidePanelIndex,
          eligibleSearchMatches[0].attachmentId,
          activeAttachmentId,
          autoFocusInput
        );
      }
    }

    setIsSearching(false);
  }

  function doJumpToNextMatch({ activeAttachmentId, autoFocusInput }: IMultiSearchSearchJumpArgs) {
    if (totalMatchesNumber === 0 || !currentSearchMatch) {
      return;
    }
    const { attachmentId, sidePanelIndex, currentMatch } = currentSearchMatch;
    const totalMatchesForAttachmentId =
      searchMatchesByAttachmentIds.find((ele) => ele.attachmentId === attachmentId)?.matches ?? 0;

    let computedAttachmentId = attachmentId;
    const computedNextMatch = totalMatchesForAttachmentId > currentMatch ? currentMatch + 1 : 1;

    const manuallyChangedAttachment = attachmentId !== activeAttachmentId;

    let computedSidePanelIndex = sidePanelIndex;

    if (computedNextMatch === 1 || manuallyChangedAttachment) {
      if (computedNextMatch === 1) {
        const eligibleSearchMatchesByAttachmentIds = sortedSearchMatchesByAttachmentIds.filter(
          (ele) => ele.matches > 0
        );

        if (eligibleSearchMatchesByAttachmentIds.length === 1) {
          // This means matches were found on only one attachment
          computedAttachmentId = eligibleSearchMatchesByAttachmentIds[0].attachmentId;
          computedSidePanelIndex = eligibleSearchMatchesByAttachmentIds[0].sidePanelIndex;
        } else {
          const currentMatchSidePanelIndex = eligibleSearchMatchesByAttachmentIds
            .map((ele) => ele.attachmentId)
            .indexOf(attachmentId);
          const indexOfNextMatch = (currentMatchSidePanelIndex + 1) % eligibleSearchMatchesByAttachmentIds.length;
          computedAttachmentId = eligibleSearchMatchesByAttachmentIds[indexOfNextMatch].attachmentId;
          computedSidePanelIndex = eligibleSearchMatchesByAttachmentIds[indexOfNextMatch].sidePanelIndex;
        }
      }

      __doHandleAttachmentChange(computedSidePanelIndex, computedAttachmentId, activeAttachmentId, autoFocusInput);
    }

    setCurrentSearchMatch({
      attachmentId: computedAttachmentId,
      sidePanelIndex: computedSidePanelIndex,
      currentMatch: computedNextMatch,
    });
    searchPropsByAttachmentIds.current[computedAttachmentId].jumpToMatch(computedNextMatch);
  }

  function doJumpToPreviousMatch({ activeAttachmentId, autoFocusInput }: IMultiSearchSearchJumpArgs) {
    if (totalMatchesNumber === 0 || !currentSearchMatch) {
      return;
    }
    const { attachmentId, sidePanelIndex, currentMatch } = currentSearchMatch;

    let computedAttachmentId = attachmentId;
    let computedPrevMatch = currentMatch - 1;

    const manuallyChangedAttachment = attachmentId !== activeAttachmentId;

    let computedSidePanelIndex = sidePanelIndex;

    if (computedPrevMatch === 0 || manuallyChangedAttachment) {
      if (computedPrevMatch === 0) {
        const eligibleSearchMatchesByAttachmentIds = sortedSearchMatchesByAttachmentIds.filter(
          (ele) => ele.matches > 0
        );

        if (eligibleSearchMatchesByAttachmentIds.length === 1) {
          // This means matches were found on only one attachment
          computedAttachmentId = eligibleSearchMatchesByAttachmentIds[0].attachmentId;
          computedSidePanelIndex = eligibleSearchMatchesByAttachmentIds[0].sidePanelIndex;
          computedPrevMatch = eligibleSearchMatchesByAttachmentIds[0].matches;
        } else {
          const currentMatchSidePanelIndex = eligibleSearchMatchesByAttachmentIds
            .map((ele) => ele.attachmentId)
            .indexOf(attachmentId);
          const indexOfPrevMatch =
            currentMatchSidePanelIndex - 1 >= 0
              ? currentMatchSidePanelIndex - 1
              : eligibleSearchMatchesByAttachmentIds.length - 1;
          computedAttachmentId = eligibleSearchMatchesByAttachmentIds[indexOfPrevMatch].attachmentId;
          computedSidePanelIndex = eligibleSearchMatchesByAttachmentIds[indexOfPrevMatch].sidePanelIndex;
          computedPrevMatch = eligibleSearchMatchesByAttachmentIds[indexOfPrevMatch].matches;
        }
      }

      __doHandleAttachmentChange(computedSidePanelIndex, computedAttachmentId, activeAttachmentId, autoFocusInput);
    }

    setCurrentSearchMatch({
      attachmentId: computedAttachmentId,
      sidePanelIndex: computedSidePanelIndex,
      currentMatch: computedPrevMatch,
    });
    searchPropsByAttachmentIds.current[computedAttachmentId].jumpToMatch(computedPrevMatch);
  }

  function doChangeSearchValue(text: string) {
    Object.keys(searchPropsByAttachmentIds.current).forEach((key) => {
      searchPropsByAttachmentIds.current[key].setKeyword(text);
    });
    setAttachmentsMultiSearchStateHolder((prev) => ({
      ...prev,
      isSearchTextChanged: true,
      searchText: text,
    }));
  }

  function doClearSearchValue() {
    Object.keys(searchPropsByAttachmentIds.current).forEach((key) => {
      searchPropsByAttachmentIds.current[key].clearKeyword();
    });
    setAttachmentsMultiSearchStateHolder((prev) => ({
      ...prev,
      isSearchTextChanged: false,
      showSearchResults: false,
      searchText: "",
    }));
    setCurrentSearchMatch(undefined);
    setSearchMatchesByAttachmentIds([]);
  }

  function registerAttachmentSearchProps(attachmentId: string, props: RenderSearchProps) {
    searchPropsByAttachmentIds.current[attachmentId] = props;
  }

  function registerAttachmentSearchInputRef(
    attachmentId: string,
    inputRef: MutableRefObject<HTMLInputElement | undefined>
  ) {
    textInputRefByAttachmentIds.current[attachmentId] = inputRef;
  }

  function setSidePanelScrollOffset(scrollOffset: number) {
    sidePanelScrollOffset.current = scrollOffset;
  }

  function getSidePanelScrollOffset(): number {
    return sidePanelScrollOffset.current;
  }

  // When an attachment is mounted on the DOM:
  // - If it was due to a search/jumping through matches, then we need to re-search for the matches and jump to the correct match
  // - If it was due to a click on the side panel, then we just clear the search state and text field.
  async function handleAttachmentFirstRender(activeAttachmentId: string) {
    if (shouldRenderTransientAttachments) {
      renderedTransientAttachments.current.add(activeAttachmentId);
      didChangeAttachmentsFromSearchControls.current = false;
      return;
    }
    if (!currentSearchMatch) {
      didChangeAttachmentsFromSearchControls.current = false;
      return;
    }

    if (!didChangeAttachmentsFromSearchControls.current && attachmentsMultiSearchStateHolder.searchText) {
      doClearSearchValue();
    }

    const { attachmentId, currentMatch } = currentSearchMatch;
    const isActiveAttachment = activeAttachmentId === attachmentId;

    if (!isActiveAttachment) {
      return;
    }

    if (didChangeAttachmentsFromSearchControls.current) {
      didChangeAttachmentsFromSearchControls.current = false;

      await __syncAttachmentsKeywords();

      await searchPropsByAttachmentIds.current[attachmentId].search();

      await new Promise((resolve) => setTimeout(resolve, 1));

      searchPropsByAttachmentIds.current[attachmentId].jumpToMatch(currentMatch);
      if (didChangeAttachmentsFromSearchControlsWithAutoFocus.current) {
        __tryToRetrapFocus(attachmentId);
        didChangeAttachmentsFromSearchControlsWithAutoFocus.current = false;
      }
    }
  }

  function handleAttachmentRemoval(activeAttachmentId: string) {
    renderedTransientAttachments.current.delete(activeAttachmentId);
  }
  // End of Public functions

  // Private functions
  function __tryToRetrapFocus(attachmentId: string) {
    const nextTextInputRef = textInputRefByAttachmentIds.current[attachmentId];

    nextTextInputRef.current?.focus();
  }

  function __doHandleAttachmentChange(
    computedSidePanelIndex: number,
    computedAttachmentId: string,
    activeAttachmentId: string,
    autoFocusInput: boolean
  ) {
    if (computedAttachmentId !== activeAttachmentId) {
      didChangeAttachmentsFromSearchControls.current = true;
      didChangeAttachmentsFromSearchControlsWithAutoFocus.current = autoFocusInput;
      handleAttachmentClick?.(computedSidePanelIndex);
    }
  }

  function __prepareTransientAttachments() {
    setShouldRenderTransientAttachments(true);
    return new Promise((resolve, _) => {
      const interval = setInterval(() => {
        // Valid attachments contains the current attachment as well, therefore we need to subtract 1
        const hasRenderedAllTransientAttachments =
          validAttachments.length - 1 === renderedTransientAttachments.current.size;
        if (hasRenderedAllTransientAttachments) {
          clearInterval(interval);
          resolve(1);
        }
      }, 100);
    });
  }

  function __removeTransientAttachments() {
    setShouldRenderTransientAttachments(false);
    return new Promise((resolve, _) => {
      setTimeout(() => {
        resolve(1);
      }, 100);
    });
  }

  // This function syncs keywords for latest loaded attachments.
  // Syncing keywords cannot to be done synchronously, and the .setKeyword(...) doesn't return a Promise,
  // therefore as a workaround we need to call setKeyword(...) and let it rerender, and only then we'll be sure that keywords
  // are up-to-date across all attachments.
  function __syncAttachmentsKeywords() {
    return new Promise((resolve, _) => {
      Object.keys(searchPropsByAttachmentIds.current).forEach((key) => {
        searchPropsByAttachmentIds.current[key].setKeyword(attachmentsMultiSearchStateHolder.searchText);
      });
      setTimeout(() => {
        resolve(1);
      }, 1);
    });
  }
  // End of Private functions

  return {
    state: attachmentsMultiSearchStateHolder,
    utilities: {
      doSearch,
      doJumpToNextMatch,
      doJumpToPreviousMatch,
      doChangeSearchValue,
      doClearSearchValue,
      registerAttachmentSearchProps,
      registerAttachmentSearchInputRef,
      setSidePanelScrollOffset,
      getSidePanelScrollOffset,
      handleAttachmentFirstRender,
      handleAttachmentRemoval,
    },
    stats: {
      currentMatchNumber,
      totalMatchesNumber,
      shouldRenderTransientAttachments,
      isSearching,
    },
  };
}

export default useAttachmentsMultiSearch;
