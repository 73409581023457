import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import PoweredByCohereLogo from "../components/images/Powered-by-Cohere.svg";
import { useGetLogoV2 } from "@coherehealth/core-platform-api";
import { useEffect, useState } from "react";
import { useHealthPlanDisplayNameContext } from "../util/context/HealthPlanDisplayNameContext";
import { useFeature } from "@coherehealth/common";
import CohereLogo from "../components/images/CohereLogo.svg";
import { usePatientContext } from "../util/context/PatientContext";

interface Props {
  isDesktopScreen?: boolean;
  isVerySmallScreen?: boolean;
}

const useStyles = makeStyles({
  desktopContainer: {
    display: "flex",
    alignItems: "center",
  },

  desktopLogo: {
    paddingRight: "24px",
  },

  desktopDivider: {
    height: "24px",
  },

  poweredByLogo: {
    paddingLeft: "24px",
  },

  smallScreenContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },

  smallScreenLogo: {
    width: "100px",
    display: "flex",
    justifyContent: "center",
  },

  smallScreenPoweredByLogo: {
    width: "100px",
    display: "flex",
    justifyContent: "center",
    paddingTop: "12px",
  },
});

function CoBrandedLogo({ isDesktopScreen, isVerySmallScreen }: Props) {
  const classes = useStyles();

  const { healthPlanDisplayName } = useHealthPlanDisplayNameContext();
  const { patientData } = usePatientContext();

  const [ssoCode, setSSOCode] = useState("");

  useEffect(() => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);
    const ssoCode = urlParams.get("ssoCode");
    setSSOCode(ssoCode || "");
  }, []);

  //only send ssoCode parameter in logo requests if url param exists, if user is on dashboard page, and if the current healthPlanDisplayName matches the value corresponding to the ssoCode
  const getSsoCodeParamValue = () => {
    return ssoCode && window?.location?.pathname === "/dashboard" ? ssoCode : "";
  };

  const {
    data: getLogoImageResponse,
    refetch,
    loading,
  } = useGetLogoV2({
    queryParams: {
      healthPlanDisplayName: healthPlanDisplayName,
      patientId: patientData?.id,
      context: "default",
      ssoCode: getSsoCodeParamValue(),
    },
    lazy: true,
  });

  const coBrandingPayerLogosFF = useFeature("cobrandingPayerLogos");

  // When healthPlanName changes, refetch useGetLogo

  useEffect(() => {
    let isMounted = true;
    if (coBrandingPayerLogosFF && healthPlanDisplayName && isMounted) {
      refetch();
    }

    return () => {
      isMounted = false;
    };
  }, [healthPlanDisplayName, refetch, coBrandingPayerLogosFF]);

  // Update this line when PayCon endpoints return a single image instead of an array

  const coBrandedLogoSrc = coBrandingPayerLogosFF && healthPlanDisplayName ? getLogoImageResponse?.url : "";

  if (loading) {
    // Don't render anything while loading
    return null;
  }

  // If FF is on, healthPlanName is specified, and there is a valid image src path returned from useGetLogo(), display co-branded logo

  // Otherwise, show Cohere logo

  return (
    <>
      {coBrandingPayerLogosFF && healthPlanDisplayName && coBrandedLogoSrc ? (
        <>
          {isDesktopScreen && (
            <div className={classes.desktopContainer}>
              <img src={coBrandedLogoSrc} alt="Branded Logo" className={classes.desktopLogo} />

              <Divider orientation="vertical" className={classes.desktopDivider} />

              <img src={PoweredByCohereLogo} alt="Powered by Cohere Logo" className={classes.poweredByLogo} />
            </div>
          )}

          {isVerySmallScreen && !isDesktopScreen && (
            <div className={classes.smallScreenContainer}>
              <div className={classes.smallScreenLogo}>
                <img src={coBrandedLogoSrc} alt="Branded Logo" />
              </div>

              <div className={classes.smallScreenPoweredByLogo}>
                <img src={PoweredByCohereLogo} alt="Powered by Cohere Logo" />
              </div>
            </div>
          )}
        </>
      ) : (
        <img src={CohereLogo} alt="Cohere Logo" />
      )}
    </>
  );
}

export default CoBrandedLogo;
