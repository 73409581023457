import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Body1 } from "@coherehealth/common";
import NoPatientResults from "../images/NoPatientResults.svg";

export default function EmptyResultsPatientLookup() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <img alt="" src={NoPatientResults} />
        <Body1 className={classes.noResults}>
          No patients match your search criteria. Please check your information and try again.
        </Body1>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& div": {
      marginTop: theme.spacing(1),
    },
    marginBottom: theme.spacing(1.5),
  },
  noResults: {
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
  },
  row: {
    display: "flex",
  },
}));
