import { InlineButton } from "@coherehealth/common";
import { ReviewType } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { ModalManagerProps, ReviewCorrectionPayload } from "./hooks/useReviewCorrection";

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledInlineButton = styled(InlineButton)(({ theme }) => ({
  paddingRight: theme.spacing(2),
}));

type AddReviewCorrectionButtonProps = {
  completedReviews: ReviewType[];
  setModalManager: React.Dispatch<React.SetStateAction<ModalManagerProps>>;
  setReviewCorrectionPayload: React.Dispatch<React.SetStateAction<ReviewCorrectionPayload>>;
};

export const AddReviewCorrectionButton = ({
  completedReviews,
  setModalManager,
  setReviewCorrectionPayload,
}: AddReviewCorrectionButtonProps) => {
  const handleAddReviewCorrectionButtonClick = () => {
    if (completedReviews?.length > 1) {
      setModalManager((prev) => ({ ...prev, openReviewSelectionModal: true }));
    } else {
      const [firstCompletedReview] = completedReviews;
      setReviewCorrectionPayload((prev) => ({ ...prev, reviewId: firstCompletedReview.id }));
      setModalManager((prev) => ({ ...prev, openReviewCorrectionModal: true }));
    }
  };
  return (
    <StyledInlineButton startIcon={<AddCircleIcon />} onClick={handleAddReviewCorrectionButtonClick}>
      Add review correction
    </StyledInlineButton>
  );
};
