import {
  Patient,
  AuthStatus,
  AuthorizationResponse,
  ServiceRequestResponse,
  ProcedureCode,
  ClinicalService,
  useGetPlaceOfServices,
  AdditionalInsurance,
  ServiceRequestEditConfig,
  NetworkType,
} from "@coherehealth/core-platform-api";
import {
  ContinuationConfiguration,
  ContinuationFieldName,
  FormConfiguration,
  convertContinuationFieldNameToFacilityBasedGeneralAuthSubmissionForm,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import {
  AdditionalCareParticipantFormContent,
  ContinuationFormContent,
} from "common/SharedServiceRequestFormComponents";
import { Dispatch, SetStateAction, useCallback, useEffect, useState, useRef, useMemo, useContext } from "react";
import ServicesAndProceduresCard from "../FillFormsGeneralAuthSubmission/ServicesAndProceduresCard";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core";
import { CohereCard } from "@coherehealth/design-system";
import {
  MAX_DATE_SELECT_DATE,
  useFeature,
  compareISODatesWithoutTime,
  formatDateToISODate,
  removeTimeFromDate,
} from "@coherehealth/common";
import {
  NonCohereCodes,
  convertStringToAuthBuilderWorkflowStep,
  getSortedClinicalServices,
  useDoPalCheck,
} from "../common";
import { checkOnetimeRequestCoverage, getPatientHealthPlanName, getPatientRiskBearingEntity } from "util/patientUtils";
import { useServiceRequestFormRedirectDispatch } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { calculateStartEndDate, getSortedServiceRequests } from "util/authorization";
import * as datefns from "date-fns";
import { error as logError } from "logger";
import { useCheckServiceRequestProcedureCodes } from "../FillForms/ServiceRequestForm";
import { useSnackbar } from "notistack";
import ProvidersCard from "../FillFormsGeneralAuthSubmission/ProvidersCard";
import ServiceRequestFormRedirectModal from "../../ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import DiagnosisCard from "../FillFormsGeneralAuthSubmission/DiagnosisCard";
import PatientStayCard from "../FillFormsGeneralAuthSubmission/PatientStayCard";
import useGetFacilityBasedRequestConfigurationByPayer, {
  useGetCareParticipantsConfigByHealthPlan,
  useGetOtherInsuranceConfigurationByPayer,
} from "hooks/useGetFeatureConfigurations";
import listReplace from "util/listReplace";
import { formatDateStr, useConfiguration } from "@coherehealth/common";
import AdditionalInsuranceSection from "../GatherRequirements/AdditionalInsuranceSection";
import {
  isAnyCareParticipantOONExceptionRequired,
  updateCareParticipants,
  validateEachFieldOfCareParticipant,
  validateOONExceptionCommentFieldForCareParticipants,
  validateOONExceptionReasonFieldForCareParticipants,
  validatePatientStayDates,
} from "util/serviceRequest";
import { validatePatientStayRange } from "util/authorization";
import { useAuthorized } from "authorization";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { shouldUseCohereCard } from "util/workflowUtils";
import {
  validatePerformingProviderAddress,
  validatePerformingProviderNPI,
  validatePerformingProviderTin,
} from "../validationUtil";
import { isExceptionCommentRequired } from "util/providerUtils";
import { useCalculateDateSelectionMessageDisplay } from "components/AuthBuilder/FillFormsGeneralAuthSubmission/utils";
import { ScrubTinsContext } from "../../ScrubTinsContext";
import { User } from "UserContext";
import { isBackOfficeUser } from "util/user";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { fieldIsValid } from "common/FormConfigUtils";
import { timePattern } from "util/dateUtils";
import { isEmpty } from "lodash";
import { EntityTypeFieldName } from "../../ServiceRequest/ServiceRequestForm/components/NetworkCheckStatusDisplay";

interface Props {
  formContent: ContinuationFormContent;
  setFormContent: Dispatch<SetStateAction<ContinuationFormContent>>;
  formConfiguration: ContinuationConfiguration;
  patient?: Patient;
  attemptedSubmit: boolean;
  setServiceRequestFormsCanBeSubmitted: Dispatch<SetStateAction<boolean[]>>;
  hideDiagnoses?: boolean;
  isCohereFaxForm?: boolean;
  authStatus: AuthStatus;
  userFaxExtension?: string;
  onUserEdit?: Dispatch<ContinuationFormContent>;
  workflowId?: string;
  authorization?: AuthorizationResponse | null;
  onPatientSummary?: boolean;
  serviceRequest?: Partial<ServiceRequestResponse>;
  requestType?: string;
  setAuthorization?: Dispatch<SetStateAction<AuthorizationResponse | null>>;
  setAllowDischargeModalToOpen?: Dispatch<SetStateAction<boolean>>;
  setIsFormContentOnContinuationUpdated?: Dispatch<SetStateAction<boolean>>;
  isFormContentOnContinuationUpdated?: boolean;
  hideExpeditedRequestCheckbox?: boolean;
  allowedPxCodePerPayer?: number;
  curreUser?: User | undefined;
  formEditConfiguration?: ServiceRequestEditConfig | undefined;
  setFacilityOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setPerformingProviderPracticeOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setProviderOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setOrderingProviderOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
}

const MIN_START_DATE = new Date(2020, 0, 1);
export default function GeneralAuthServiceRequestContinuationForm({
  formContent,
  setFormContent,
  formConfiguration,
  patient,
  attemptedSubmit,
  setServiceRequestFormsCanBeSubmitted,
  authStatus: currAuthStatus,
  onUserEdit,
  workflowId,
  authorization,
  isCohereFaxForm,
  onPatientSummary = false,
  serviceRequest,
  requestType,
  setAllowDischargeModalToOpen,
  setIsFormContentOnContinuationUpdated,
  isFormContentOnContinuationUpdated,
  hideExpeditedRequestCheckbox,
  allowedPxCodePerPayer,
  curreUser,
  formEditConfiguration,
  setFacilityOonCheckLoading,
  setPerformingProviderPracticeOonCheckLoading,
  setProviderOonCheckLoading,
  setOrderingProviderOonCheckLoading,
}: Props) {
  const { spacing } = useTheme();
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const initialRender = useRef(true);
  // Checking if the Diagnosis and Provider card is on continuation forms
  const isDiagnosisAndProviderCardOnContinuation =
    // Checking if the workflow step is "FILL_FORMS_CONTINUATION" or "CLINICAL_ASSESSMENT_CONTINUATION"
    serviceRequest?.workflowStep === "FILL_FORMS_CONTINUATION" ||
    serviceRequest?.workflowStep === "CLINICAL_ASSESSMENT_CONTINUATION";

  const { healthPlanName, encounterType, delegatedVendor } = serviceRequest ?? {};
  const patientHealthPlanName = getPatientHealthPlanName(patient || undefined, formContent.startDate) || "";
  const patientRiskBearingEntity = getPatientRiskBearingEntity(patient || undefined, formContent.startDate) || "";
  const { facilityBasedFeatureEnabled, includePatientStayDatesOnPlannedAdmission } =
    useGetFacilityBasedRequestConfigurationByPayer({
      healthPlanName,
      encounterType,
      skipRequestTimingCheck: true,
      delegatedVendorName: delegatedVendor,
    });

  const { formFieldConfigurations, outOfNetworkCheckConfiguration, isLoading, pxCodeAttributeConfiguration } =
    useServiceRequestConfigSpec({
      ...formContent,
      patientId: patient?.id || "",
      healthPlanName: patientHealthPlanName,
    });

  const { scrubTinsEnabled: hideTinField } = useContext(ScrubTinsContext);

  const [performingProviderExceptionRequest, setPerformingProviderExceptionRequest] = useState(false);

  const [performingProviderPracticeExceptionRequest, setPerformingProviderPracticeExceptionRequest] = useState(false);

  const [facilityExceptionRequest, setFacilityExceptionRequest] = useState(false);

  const [orderingProviderExceptionRequest, setOrderingProviderExceptionRequest] = useState(false);
  const [careParticipantExceptionRequest, setCareParticipantExceptionRequest] = useState(false);

  const setExceptionRequest = useCallback(() => {
    if (formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionReason) {
      setPerformingProviderExceptionRequest(
        !!formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionReason || false
      );
    }

    if (formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason) {
      setPerformingProviderPracticeExceptionRequest(
        !!formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason || false
      );
    }

    if (formContent?.facilitySelectedAddress?.outOfNetworkExceptionReason) {
      setFacilityExceptionRequest(!!formContent?.facilitySelectedAddress?.outOfNetworkExceptionReason || false);
    }

    if (formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason) {
      setOrderingProviderExceptionRequest(
        !!formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason || false
      );
    }

    if (formContent?.additionalCareParticipants) {
      formContent?.additionalCareParticipants?.forEach((careParticipant: AdditionalCareParticipantFormContent) => {
        if (careParticipant?.selectedLocation?.outOfNetworkExceptionReason) {
          setCareParticipantExceptionRequest(!!careParticipant?.selectedLocation?.outOfNetworkExceptionReason || false);
        }
      });
    }
  }, [
    setPerformingProviderExceptionRequest,
    setFacilityExceptionRequest,
    setPerformingProviderPracticeExceptionRequest,
    setOrderingProviderExceptionRequest,
    setCareParticipantExceptionRequest,
    formContent,
  ]);

  useEffect(() => {
    setExceptionRequest();
  }, [
    formContent.performingProviderSelectedAddress,
    formContent.facilitySelectedAddress,
    formContent.additionalCareParticipants,
    setExceptionRequest,
  ]);

  const tinConfiguration = useConfiguration("tinConfiguration", patientHealthPlanName) ?? {};
  const userTinValidation = tinConfiguration?.userTinValidation;
  const authCategoryClinicalService = formContent?.clinicalServices?.find((clinicalService) =>
    Boolean(clinicalService.authCategory)
  );
  const initialClinicalService = authCategoryClinicalService
    ? authCategoryClinicalService
    : formContent?.clinicalServices && formContent?.clinicalServices.length > 0
    ? getSortedClinicalServices(formContent.clinicalServices)[0]
    : undefined;

  const [clinicalService] = useState<ClinicalService | undefined>(initialClinicalService);
  const [nonPalProcedureCodes, setNonPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const [nonCohereProcedureCodes, setNonCohereProcedureCodes] = useState<NonCohereCodes[]>([]);
  const [palPxCodes, setPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const [sameProviders, setSameProviders] = useState(false);
  const [, setSemanticProcedureCodes] = useState<ProcedureCode[]>(authorization?.semanticProcedureCodes || []);
  const [showServicesAndProceduresCard, setShowServicesAndProceduresCard] = useState(false);
  const [isAdditionalUnitsRequested, setIsAdditionalUnitsRequested] = useState(false);
  const doesNotExceedMaxSecondaryDiagnoses = !!formContent?.secondaryDiagnosisCodes;
  const shouldShowEndDate =
    serviceRequest?.requestType === "CONTINUATION" ? true : Number(formContent.units) === 1 ? false : true;

  const servicesEditabilityEnabled = useFeature("servicesEditability") && onPatientSummary;
  const skipManualNpiTin = useFeature("skipManualNpiTin");
  const canSkipManualNpiTin = useAuthorized("CAN_SKIP_MANUAL_NPI_TIN");
  const isGeisingerPatient = patientHealthPlanName === "Geisinger";
  const canSkipValidationForNpiTin = skipManualNpiTin && isGeisingerPatient && canSkipManualNpiTin;
  // Logic and other helper functions related to validation
  const startDateExceedsMinStartDate = formContent.startDate >= MIN_START_DATE;
  const hasStartDate = Boolean(formContent.startDate);
  let hasValidStartDate = false;
  let startDateErrorMessage = "";
  const finalDeterminationStates = ["APPROVED", "DENIED", "PARTIALLY_APPROVED", "WITHDRAWN", "VOIDED"];
  try {
    const expectedMinStartDate =
      serviceRequest?.requestType === "CONTINUATION"
        ? calculateStartEndDate(authorization, 1)?.startDate ?? MIN_START_DATE
        : MIN_START_DATE;
    const expectedMaxStartDate =
      serviceRequest?.requestType === "CONTINUATION"
        ? calculateStartEndDate(authorization, 1)?.endDate ?? MAX_DATE_SELECT_DATE
        : MAX_DATE_SELECT_DATE;
    const isValidStartDate = datefns.isValid(formContent.startDate)
      ? !!(expectedMinStartDate && expectedMaxStartDate)
      : false;
    const compareStartDates = () => {
      const resultStartDate = isValidStartDate
        ? compareISODatesWithoutTime(formContent.startDate, expectedMinStartDate)
        : undefined;
      const resultEndDate = isValidStartDate
        ? compareISODatesWithoutTime(formContent.startDate, expectedMaxStartDate)
        : undefined;
      return resultStartDate !== undefined && resultEndDate !== undefined
        ? resultStartDate <= 0 && resultEndDate >= 0
        : false;
    };
    hasValidStartDate = compareStartDates();
    // sets an error message for an invalid start date, specifying a range between expectedMinStartDate and expectedMaxStartDate on patientSummary editview
    startDateErrorMessage = `Date must be between ${formatDateStr(expectedMinStartDate)} and ${formatDateStr(
      expectedMaxStartDate
    )}`;
  } catch (error) {
    logError(new Error("Invalid date format"));
  }

  //track initial networkType across providers and facilities so changes from in-network to out-of-network can be conditionally blocked based off serviceRequestEditConfiguration.disableOutOfNetworkChanges value
  const prevOrderingProviderNetworkType = useRef<NetworkType | undefined>(
    formContent?.orderingProviderSelectedAddress?.networkType
  );
  const prevPerformingProviderNetworkType = useRef<NetworkType | undefined>(
    formContent?.performingProviderSelectedAddress?.networkType
  );
  const prevPerformingProviderPracticeNetworkType = useRef<NetworkType | undefined>(
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.networkType
  );
  const prevFacilityNetworkType = useRef<NetworkType | undefined>(formContent?.facilitySelectedAddress?.networkType);

  //for each provider/facility field, return true if all of the following are true:
  //authStatus is not DRAFT (has been submitted)
  //serviceRequestEditConfiguration.disableOutOfNetworkChanges is set to true
  //request's submitted networkType of provider/facility is in-network
  //user has selected provider/facility that is NOT in-network
  const shouldBlockOonChanges = formEditConfiguration?.disableOutOfNetworkChanges && currAuthStatus !== "DRAFT";
  const isBlockingOonChange = (field: EntityTypeFieldName): boolean => {
    if (!shouldBlockOonChanges) {
      return false;
    }
    switch (field) {
      case "orderingProvider":
        return Boolean(
          prevOrderingProviderNetworkType?.current &&
            prevOrderingProviderNetworkType?.current === "INN" &&
            formContent?.orderingProviderSelectedAddress?.networkType !== "INN"
        );
      case "performingProvider":
        return Boolean(
          prevPerformingProviderNetworkType?.current &&
            prevPerformingProviderNetworkType?.current === "INN" &&
            formContent?.performingProviderSelectedAddress?.networkType !== "INN"
        );
      case "performingProviderPractice":
        return Boolean(
          prevPerformingProviderPracticeNetworkType?.current &&
            prevPerformingProviderPracticeNetworkType?.current === "INN" &&
            formContent?.selectedPerformingProviderPractice?.selectedLocation?.networkType !== "INN"
        );
      case "facility":
        return Boolean(
          prevFacilityNetworkType?.current &&
            prevFacilityNetworkType?.current === "INN" &&
            formContent?.facilitySelectedAddress?.networkType !== "INN"
        );
      default:
        return false;
    }
  };

  const startDateCoverage = useMemo(
    () =>
      patient?.coverages && formContent?.admissionDate
        ? checkOnetimeRequestCoverage(patient.coverages, formContent?.admissionDate || "")
        : undefined,
    [patient?.coverages, formContent?.admissionDate]
  );

  const endDateCoverage = useMemo(
    () =>
      patient?.coverages && formContent?.endDate
        ? checkOnetimeRequestCoverage(
            patient.coverages,
            formContent?.endDate || "",
            formConfiguration.blockUserIfLessThanRecommendedEndDate?.fieldSpec === "REQUIRED"
          )
        : undefined,
    [patient?.coverages, formContent?.endDate, formConfiguration.blockUserIfLessThanRecommendedEndDate?.fieldSpec]
  );

  const hasValidEndDate = (formContent.endDate ?? MAX_DATE_SELECT_DATE) <= MAX_DATE_SELECT_DATE;
  const hasPlaceOfService = Boolean(formContent.placeOfService);
  const endDateExceedsStartDate = formContent.endDate ? formContent.endDate >= formContent.startDate : true;
  const minNumUnits = 1;
  const hasAdmissionDate =
    Boolean(formContent?.admissionDate) &&
    !datefns.isAfter(new Date(formContent?.admissionDate ?? ""), new Date()) &&
    startDateCoverage?.inRange;
  const isAdmissionTimeValid = timePattern.test(formContent?.admissionTime || "");
  const isDischargeTimeValid = timePattern.test(formContent?.dischargeTime || "");

  const hasAdmissionTime = Boolean(formContent?.admissionTime) && isAdmissionTimeValid;
  const hasDischargeTime = Boolean(formContent?.dischargeTime) && isDischargeTimeValid;
  const hasDischargedTo = Boolean(formContent?.dischargedTo);

  const { otherInsuranceEnabled, otherInsurance } = useGetOtherInsuranceConfigurationByPayer({
    healthPlanName: patientHealthPlanName ?? "",
  });

  const useExpeditedTatUpdateTimestamp = useFeature("useExpeditedTatUpdateTimestamp");
  const isUserBackOffice = isBackOfficeUser(curreUser);
  const { careParticipantEnabled, careParticipantConfig } = useGetCareParticipantsConfigByHealthPlan({
    healthPlanName: patientHealthPlanName ?? "",
  });
  const shouldUpdateAdditionalCareParticipants = currAuthStatus !== "DRAFT";

  const hasDischargeDate =
    formContent?.dischargeDate !== undefined &&
    datefns.isValid(formContent?.dischargeDate) &&
    !datefns.isBefore(new Date(formContent?.dischargeDate ?? ""), new Date(formContent?.admissionDate ?? "")) &&
    !datefns.isAfter(new Date(formContent?.dischargeDate ?? ""), new Date());

  const hasExpectedAdmissionDate =
    Boolean(formContent?.expectedAdmissionDate) &&
    datefns.isValid(formContent?.expectedAdmissionDate) &&
    datefns.isAfter(formContent?.expectedAdmissionDate ?? new Date(), datefns.subDays(new Date(), 1));

  const hasEndDateIfApplicable = Boolean(formContent.endDate);
  const hasUnits = Boolean(formContent?.units);
  const somePxsHaveUnits = Boolean(formContent?.procedureCodes?.some((px) => !!px.units));
  const allPxsHaveUnits = Boolean(formContent?.procedureCodes?.every((px) => !!px.units));
  const validUnits =
    parseInt(formContent.units) > Math.max(0, minNumUnits - 1) && Number.isInteger(Number(formContent.units));
  const hasExpeditedReasonIfExpedited = !formContent.isExpedited || !!formContent.expeditedReason;
  const expectedDischargeExceedsAdmissionDate =
    formContent.expectedDischargeDate && formContent.admissionDate
      ? formContent.expectedDischargeDate >= formContent.admissionDate
      : false;
  const admissionDateExceedsMinAdmissionDate = formContent.admissionDate
    ? formContent.admissionDate >= MIN_START_DATE
    : false;

  const hasValidPxUnits = formContent?.procedureCodes?.some(
    (procedureCode) =>
      procedureCode.units &&
      procedureCode.units > 0 &&
      (procedureCode.units <= (procedureCode.maxUnits ?? 1) * Number(formContent.units) ||
        formContent?.clinicalService?.isUnitsOnPx)
  );
  let hasAllowedProcedureCodePerPayer;
  if (allowedPxCodePerPayer && allowedPxCodePerPayer > 0) {
    hasAllowedProcedureCodePerPayer = Boolean(
      allowedPxCodePerPayer && formContent?.procedureCodes.length <= allowedPxCodePerPayer
    );
  }
  const hasValidProcedureCodeWithUnits = formContent && formContent.procedureCodes.length > 0 && hasValidPxUnits;
  const procedureCodesValid =
    formContent.procedureCodes.length > 0 &&
    (onPatientSummary ? allPxsHaveUnits : somePxsHaveUnits) &&
    hasAllowedProcedureCodePerPayer;
  const canSubmitNonPalProcedureCodes = !!serviceRequest?.userSelectedNonPalCode || servicesEditabilityEnabled;
  const noNonPalProcedureCodesIfOutpatient =
    formContent.procedureCodes.filter((px) => nonPalProcedureCodes?.some(({ code }) => code === px.code)).length ===
      0 || formContent.isInpatient;
  const hasPrimaryDiagnosis = Boolean(formContent.primaryDiagnosisCode);
  const hasSecondaryDiagnoses = !!formContent?.secondaryDiagnosisCodes
    ? formContent?.secondaryDiagnosisCodes?.length > 0
    : true;
  const hasOrderingProvider = Boolean(formContent.orderingProvider);
  const hasPerformingProvider = Boolean(formContent.performingProvider);
  const hasFacility = Boolean(formContent.facility);
  const hasFacilityNpi =
    formContent?.facility?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.facility?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent.facility?.npi);
  const hasPerformingProviderNpi =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.performingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent.performingProvider?.npi);
  const hasOrderingProviderNpi =
    formContent?.orderingProvider?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.orderingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent.orderingProvider?.npi);
  const hasFacilityTin =
    formContent?.facility?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.facilitySelectedTin
      ? canSkipValidationForNpiTin
      : Boolean(formContent.facilitySelectedTin);
  const performingProviderTinLength = formContent.performingProvider?.tinList?.length;
  const hasPerformingProviderTin =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.performingProviderSelectedTin
      ? canSkipValidationForNpiTin
      : Boolean(formContent.performingProviderSelectedTin);
  const hasFacilityAddress =
    formContent?.facility?.locations === undefined ? true : Boolean(formContent.facilitySelectedAddress);
  const hasPerformingProviderAddress =
    formContent?.performingProvider?.locations === undefined
      ? true
      : Boolean(formContent.performingProviderSelectedAddress);
  const hasOrderingProviderTin =
    formContent?.orderingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.orderingProviderSelectedTin
      ? canSkipValidationForNpiTin
      : Boolean(formContent.orderingProviderSelectedTin);
  const hasOrderingProviderAddress =
    formContent?.orderingProvider?.locations === undefined
      ? true
      : Boolean(formContent.orderingProviderSelectedAddress);
  const hasPerformingProviderPractice = Boolean(formContent?.selectedPerformingProviderPractice);
  const hasPerformingProviderPracticeTIN =
    formContent?.selectedPerformingProviderPractice?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedPerformingProviderPractice?.selectedLocation?.tin
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.selectedPerformingProviderPractice?.selectedLocation?.tin);

  const hasPerformingProviderPracticeAddress = !isEmpty(
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.address
  );
  const hasPerformingProviderPracticeNpi =
    formContent?.selectedPerformingProviderPractice?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedPerformingProviderPractice?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.selectedPerformingProviderPractice?.npi);

  const hasMinStayDays = formContent.patientStayDateRanges?.length
    ? formContent.patientStayDateRanges?.length > 0 && formContent.patientStayDateRanges[0].rangeEndDate !== null
    : serviceRequest?.requestType === "CONTINUATION" || !!serviceRequest?.patientStayDates?.length;
  const validatePatientStayDateRangesEndDate = validatePatientStayRange(
    formContent.patientStayDateRanges ?? [],
    authorization
  );
  const hasValidStayDateRange = validatePatientStayDateRangesEndDate.filter((error) => error.isRowInValid)?.length;
  const isPatientStayDatesValid = hasMinStayDays && !hasValidStayDateRange;
  const hasAuthCategory = Boolean(formContent.authCategory);
  const hasAuthSubcategory = Boolean(formContent.authSubcategory);
  const hasSubCategories = formContent.authCategory?.subcategories?.length
    ? formContent.authCategory?.subcategories?.length > 0
    : false;
  const checkAuthSubCategory = hasSubCategories ? hasAuthSubcategory : true;
  const hasProviderOONExceptionReason = Boolean(formContent?.performingProviderSelectedAddress?.isOutOfNetwork);
  const hasProviderPracticeOONExceptionReason = Boolean(
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.isOutOfNetwork
  );
  const hasFacilityOONExceptionReason = Boolean(formContent?.facilitySelectedAddress?.isOutOfNetwork);
  const hasOrderingProviderOONExceptionReason = Boolean(formContent?.orderingProviderSelectedAddress?.isOutOfNetwork);
  const requiresFacilityOONExceptionComment =
    formContent.selectedFacility?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason === "Other (comment is required)";

  const requiresPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresPerformingProviderPracticeOONExceptionComment =
    formContent.selectedPerformingProviderPractice?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresOrderingProviderOONExceptionComment = isExceptionCommentRequired(formContent);

  const hasFacilityOONExceptionComment = formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderPracticeOONExceptionComment =
    formContent.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionComment;

  const hasOrderingProviderOONExceptionComment =
    formContent.selectedOrderingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const hasExpectedAdmissionDateForServiceBasedSR = facilityBasedFeatureEnabled
    ? Boolean(formContent?.expectedAdmissionDate) && datefns.isValid(formContent?.expectedAdmissionDate)
    : Boolean(formContent?.startDate) && datefns.isValid(formContent?.startDate);

  const expectedAdmissionDateValueForPlannedAdmission =
    formContent?.admissionDate || formContent?.expectedAdmissionDate;

  const dischargeDate = new Date(formContent?.expectedDischargeDate ?? "");
  const admissionDate = new Date(expectedAdmissionDateValueForPlannedAdmission ?? "");

  const hasExpectedDischargeDate =
    (formContent?.expectedDischargeDate !== undefined &&
      datefns.isValid(formContent?.expectedDischargeDate) &&
      !datefns.isBefore(dischargeDate, admissionDate)) ||
    datefns.isSameDay(dischargeDate, admissionDate);

  const validateCareParticipantField =
    formContent?.additionalCareParticipants &&
    formContent?.additionalCareParticipants?.length > 0 &&
    !validateEachFieldOfCareParticipant(formContent.additionalCareParticipants);

  const hasOrderingProviderExceptionRequestOnContinuation = formContent?.orderingProviderSelectedAddress?.isOutOfNetwork
    ? orderingProviderExceptionRequest
    : false;

  const { showExceededDurationLimitMessageErrorState, showBelowDurationLimitMessageErrorState } =
    useCalculateDateSelectionMessageDisplay(
      formContent,
      patient?.coverages || [],
      patient ? patient : null,
      formConfiguration
    );

  const isValidExpeditedTime = timePattern.test(formContent?.expeditedTime || "");

  const removeTimeFromTatStartTimestamp =
    formContent?.tatStartTimestamp && removeTimeFromDate(formContent?.tatStartTimestamp);

  const filteredProcedureCodes = formContent?.procedureCodes?.filter(
    (procedureCode) =>
      procedureCode.attributeType && (!procedureCode.selectedDetails || !procedureCode?.selectedDetails?.length)
  );

  // Default to "Cohere" if delegatedVendorName is null or an empty string
  const srDelegatedVendorName = serviceRequest?.delegatedVendor || "Cohere";

  // Determine the delegated vendor name, defaulting to "Cohere" if it's an empty string
  const configuredDelegatedVendorName =
    pxCodeAttributeConfiguration?.delegatedVendorName == null ||
    pxCodeAttributeConfiguration?.delegatedVendorName === ""
      ? "Cohere"
      : pxCodeAttributeConfiguration?.delegatedVendorName;

  // Check if the Px Code Attributed Field should be shown based on configuration and vendor name
  const showPxBasedAttributedField =
    formFieldConfigurations?.selectedDetails?.fieldSpec !== "OMIT" &&
    pxCodeAttributeConfiguration?.showPxCodeAttributeField &&
    configuredDelegatedVendorName === srDelegatedVendorName;

  // Check if there are required procedures with selected details
  const hasProceduresWithSelectedDetail =
    formFieldConfigurations?.selectedDetails?.fieldSpec === "REQUIRED" &&
    pxCodeAttributeConfiguration?.showPxCodeAttributeField
      ? filteredProcedureCodes.length > 0
      : false;

  const isValidExpeditedDate =
    (formContent?.expeditedDate &&
      datefns.isAfter(new Date(formContent?.expeditedDate), new Date(removeTimeFromTatStartTimestamp ?? "")) &&
      datefns.isBefore(new Date(formContent?.expeditedDate), new Date())) ||
    datefns.isEqual(new Date(formContent?.expeditedDate || ""), new Date()) ||
    datefns.isEqual(new Date(formContent?.expeditedDate || ""), new Date(removeTimeFromTatStartTimestamp ?? ""));

  const hasValidExpediteRequestedTime = Boolean(
    formContent?.isExpedited && isValidExpeditedDate && isValidExpeditedTime
  );
  const VALIDATION_MAP: Record<ContinuationFieldName, boolean> = {
    selectedDetails: fieldIsValid({ fieldSpec: "REQUIRED" }, !hasProceduresWithSelectedDetail),
    procedureCodes: fieldIsValid(
      formContent.isInpatient && facilityBasedFeatureEnabled ? { fieldSpec: "OMIT" } : formConfiguration.procedureCodes,
      procedureCodesValid,
      noNonPalProcedureCodesIfOutpatient || canSubmitNonPalProcedureCodes
    ),
    procedureCodeWithUnits: fieldIsValid(
      formContent.isInpatient && facilityBasedFeatureEnabled
        ? { fieldSpec: "OMIT" }
        : formConfiguration.procedureCodeWithUnits,
      hasValidProcedureCodeWithUnits
    ),
    startEndDate: fieldIsValid(
      formContent.isInpatient ? { fieldSpec: "OMIT" } : formConfiguration.startEndDate,
      hasStartDate && (hasEndDateIfApplicable || expectedDischargeExceedsAdmissionDate),
      startDateExceedsMinStartDate &&
        endDateExceedsStartDate &&
        hasValidStartDate &&
        hasValidEndDate &&
        endDateCoverage?.inRange
    ),
    units: fieldIsValid(
      facilityBasedFeatureEnabled && formContent?.isInpatient ? { fieldSpec: "OMIT" } : formConfiguration.units,
      hasUnits,
      validUnits
    ),
    urgency: useExpeditedTatUpdateTimestamp || fieldIsValid(formConfiguration.urgency, hasExpeditedReasonIfExpedited),
    admissionDischargeDate: fieldIsValid(
      formConfiguration.admissionDischargeDate,
      expectedDischargeExceedsAdmissionDate && admissionDateExceedsMinAdmissionDate
    ),
    orderingProvider: onPatientSummary ? fieldIsValid(formConfiguration.orderingProvider, hasOrderingProvider) : true,
    performingProvider: onPatientSummary
      ? fieldIsValid(formConfiguration.performingProvider, hasPerformingProvider)
      : true,
    facility: onPatientSummary ? fieldIsValid(formConfiguration.facility, hasFacility) : true,
    facilityAddress: onPatientSummary ? fieldIsValid(formConfiguration.facilityAddress, hasFacilityAddress) : true,
    facilityNPI: onPatientSummary ? fieldIsValid(formConfiguration.facilityNPI, hasFacilityNpi) : true,
    facilityTIN: onPatientSummary
      ? hideTinField ||
        (!userTinValidation && formContent.facility?.source === "CMS" && !formContent.facility?.manuallyCreated) ||
        fieldIsValid(formConfiguration.facilityTIN, hasFacilityTin)
      : true,
    performingProviderTIN: onPatientSummary
      ? validatePerformingProviderTin(
          formConfiguration,
          formContent,
          performingProviderTinLength,
          hasPerformingProviderTin
        )
      : true,
    performingProviderNPI: validatePerformingProviderNPI(formConfiguration, formContent, hasPerformingProviderNpi),
    performingProviderAddress: onPatientSummary
      ? validatePerformingProviderAddress(
          formConfiguration,
          formContent,
          patientHealthPlanName,
          hasPerformingProviderAddress
        )
      : true,
    orderingProviderAddress: onPatientSummary
      ? fieldIsValid(formConfiguration.orderingProviderAddress, hasOrderingProviderAddress)
      : true,
    orderingProviderTIN: onPatientSummary
      ? hideTinField ||
        (!userTinValidation &&
          formContent.orderingProvider?.source === "CMS" &&
          !formContent?.orderingProvider?.manuallyCreated) ||
        fieldIsValid(formConfiguration.orderingProviderTIN, hasOrderingProviderTin)
      : true,
    orderingProviderNPI: onPatientSummary
      ? fieldIsValid(formConfiguration.orderingProviderNPI, hasOrderingProviderNpi)
      : true,
    performingProviderPractice: fieldIsValid(
      formConfiguration?.performingProviderPractice,
      hasPerformingProviderPractice
    ),
    performingProviderPracticeSelectedTIN:
      hideTinField ||
      (!userTinValidation &&
        formContent.selectedPerformingProviderPractice?.source === "CMS" &&
        !formContent?.selectedPerformingProviderPractice?.manuallyCreated) ||
      fieldIsValid(formConfiguration.performingProviderPracticeSelectedTIN, hasPerformingProviderPracticeTIN),
    performingProviderPracticeSelectedAddress: fieldIsValid(
      formConfiguration?.performingProviderPracticeSelectedAddress,
      hasPerformingProviderPracticeAddress
    ),
    performingProviderPracticeSelectedNPI: fieldIsValid(
      formConfiguration?.performingProviderPracticeSelectedNPI,
      hasPerformingProviderPracticeNpi
    ),
    primaryDiagnosis:
      onPatientSummary && formContent?.isInpatient
        ? fieldIsValid(formConfiguration.primaryDiagnosis, hasPrimaryDiagnosis)
        : true,
    secondaryDiagnoses: onPatientSummary
      ? fieldIsValid(formConfiguration.secondaryDiagnoses, hasSecondaryDiagnoses, doesNotExceedMaxSecondaryDiagnoses)
      : true,
    placeOfService: fieldIsValid(formConfiguration.placeOfService, hasPlaceOfService),
    patientStayDateRanges: validatePatientStayDates(
      facilityBasedFeatureEnabled,
      formContent?.isInpatient,
      isPatientStayDatesValid,
      includePatientStayDatesOnPlannedAdmission,
      formContent?.patientStatus
    ),
    authCategory:
      facilityBasedFeatureEnabled && formContent?.isInpatient
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAuthCategory)
        : fieldIsValid(formConfiguration.authCategory, hasAuthCategory),
    authSubCategory:
      facilityBasedFeatureEnabled && formContent?.isInpatient && hasSubCategories
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, checkAuthSubCategory)
        : fieldIsValid(formConfiguration.authSubCategory, hasAuthSubcategory),
    admissionDate:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus !== "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAdmissionDate)
        : fieldIsValid(formConfiguration.admissionDate, hasAdmissionDate),
    admissionTime:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus !== "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAdmissionTime)
        : fieldIsValid(formConfiguration.admissionDate, hasAdmissionTime),
    dischargeDate:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargeDate)
        : fieldIsValid(formConfiguration.dischargeDate, hasDischargeDate),
    dischargeTime:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargeTime)
        : fieldIsValid(formConfiguration.dischargeTime, hasDischargeTime),
    dischargedTo:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargedTo)
        : fieldIsValid(formConfiguration.dischargedTo, hasDischargedTo),
    expectedAdmissionDate: facilityBasedFeatureEnabled
      ? formContent?.isInpatient && formContent?.patientStatus === "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedAdmissionDate)
        : fieldIsValid(formConfiguration.expectedAdmissionDate, hasExpectedAdmissionDate)
      : formContent?.isInpatient
      ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedAdmissionDateForServiceBasedSR)
      : fieldIsValid(formConfiguration.expectedAdmissionDate, hasExpectedAdmissionDateForServiceBasedSR),
    expectedDischargeDate:
      !facilityBasedFeatureEnabled && formContent?.isInpatient
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedDischargeDate)
        : fieldIsValid(formConfiguration.expectedDischargeDate, hasExpectedDischargeDate),
    facilityOutOfNetworkExceptionReason:
      (facilityExceptionRequest && !formContent?.facilityOONExceptionRequired) ||
      (formContent?.facilityOONExceptionRequired && hasFacilityOONExceptionReason)
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, !!formContent?.facilitySelectedAddress?.outOfNetworkExceptionReason)
        : fieldIsValid(formConfiguration?.facilityOutOfNetworkExceptionReason),

    performingProviderOutOfNetworkExceptionReason:
      (performingProviderExceptionRequest && !formContent?.performingProviderOONExceptionRequired) ||
      (formContent?.performingProviderOONExceptionRequired && hasProviderOONExceptionReason)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formConfiguration?.performingProviderOutOfNetworkExceptionReason),

    performingProviderPracticeOutOfNetworkExceptionReason:
      (performingProviderPracticeExceptionRequest &&
        !formContent?.selectedPerformingProviderPracticeOONExceptionRequired) ||
      (formContent?.selectedPerformingProviderPracticeOONExceptionRequired && hasProviderPracticeOONExceptionReason)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formConfiguration?.performingProviderPracticeOutOfNetworkExceptionComment),

    orderingProviderOutOfNetworkExceptionReason:
      (hasOrderingProviderExceptionRequestOnContinuation && !formContent?.orderingProviderOONExceptionRequired) ||
      (formContent?.orderingProviderOONExceptionRequired && hasOrderingProviderOONExceptionReason)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formConfiguration?.orderingProviderOutOfNetworkExceptionReason),

    facilityOutOfNetworkExceptionComment:
      (requiresFacilityOONExceptionComment && !!hasFacilityOONExceptionComment) || !requiresFacilityOONExceptionComment,
    performingProviderOutOfNetworkExceptionComment:
      (requiresPerformingProviderOONExceptionComment && !!hasPerformingProviderOONExceptionComment) ||
      !requiresPerformingProviderOONExceptionComment,
    performingProviderPracticeOutOfNetworkExceptionComment:
      (requiresPerformingProviderPracticeOONExceptionComment && !!hasPerformingProviderPracticeOONExceptionComment) ||
      !requiresPerformingProviderPracticeOONExceptionComment,
    orderingProviderOutOfNetworkExceptionComment:
      (requiresOrderingProviderOONExceptionComment && !!hasOrderingProviderOONExceptionComment) ||
      !requiresOrderingProviderOONExceptionComment,
    careParticipantOutOfNetworkExceptionReason: formContent.additionalCareParticipants
      ? (careParticipantExceptionRequest &&
          !isAnyCareParticipantOONExceptionRequired(formContent.additionalCareParticipants)) ||
        validateOONExceptionReasonFieldForCareParticipants(formContent.additionalCareParticipants, false)
      : true,
    careParticipantOutOfNetworkExceptionComment: formContent.additionalCareParticipants
      ? validateOONExceptionCommentFieldForCareParticipants(formContent.additionalCareParticipants)
      : true,

    expeditedTatUpdateTimestamp:
      !isUserBackOffice && useExpeditedTatUpdateTimestamp && formContent?.isExpedited
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasValidExpediteRequestedTime)
        : true,
    encounterType: true,
    userDeclaredOONException: true,
    additionalCareParticipants: !validateCareParticipantField,
    nonPalCheckbox: true,
    blockUserIfExceedsRecommendedEndDate: fieldIsValid(
      formConfiguration.blockUserIfExceedsRecommendedEndDate,
      !showExceededDurationLimitMessageErrorState
    ),
    blockUserIfLessThanRecommendedEndDate: fieldIsValid(
      formConfiguration.blockUserIfLessThanRecommendedEndDate,
      !showBelowDurationLimitMessageErrorState
    ),
    admissionSource: true,
    patientStatus: true,
    orderingProviderOutOfNetworkStatusDisplay: fieldIsValid(
      formConfiguration?.orderingProviderOutOfNetworkStatusDisplay,
      undefined,
      !isBlockingOonChange("orderingProvider")
    ),
    performingProviderOutOfNetworkStatusDisplay: fieldIsValid(
      formConfiguration?.performingProviderOutOfNetworkStatusDisplay,
      undefined,
      !isBlockingOonChange("performingProvider")
    ),
    performingProviderPracticeOutOfNetworkStatusDisplay: fieldIsValid(
      formConfiguration?.performingProviderPracticeOutOfNetworkStatusDisplay,
      undefined,
      !isBlockingOonChange("performingProviderPractice")
    ),
    facilityOutOfNetworkStatusDisplay: fieldIsValid(
      formConfiguration?.facilityOutOfNetworkStatusDisplay,
      undefined,
      !isBlockingOonChange("facility")
    ),
  };

  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) => setServiceRequestFormsCanBeSubmitted((prev) => listReplace(prev, 0, canBeSubmitted)),
    [setServiceRequestFormsCanBeSubmitted]
  );

  const { dispatchRedirectRuleCheck, redirectModalProps, dispatchUrgencyRuleCheck } =
    useServiceRequestFormRedirectDispatch({
      workflowId,
      formContent,
      clinicalService,
      healthPlanName: patientHealthPlanName,
      patient,
      authStage: convertStringToAuthBuilderWorkflowStep("FILL_FORMS_CONTINUATION"),
    });

  const validateFields = () => {
    const formConfig = formConfiguration;
    if (formConfig) {
      const formConfigKeys = Object.keys(formConfig) as ContinuationFieldName[];
      for (const key of formConfigKeys) {
        if (VALIDATION_MAP[key] !== undefined && !VALIDATION_MAP[key]) {
          return false;
        }
      }
      return true;
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };

  const isValid = validateFields();

  const handleFormValidation = useCallback(
    (isFormValid: boolean) => {
      setCanBeSubmitted(isFormValid);
    },
    [setCanBeSubmitted]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [isValid, handleFormValidation]);

  useEffect(() => {
    if (authorization) {
      setSemanticProcedureCodes(authorization.semanticProcedureCodes || []);
    }
  }, [authorization, setSemanticProcedureCodes]);

  useEffect(() => {
    if (formContent.isInpatient && formConfiguration.admissionDischargeDate.fieldSpec !== "OMIT") {
      setFormContent((oldContents) => ({
        ...oldContents,
        admissionDate: oldContents.admissionDate || oldContents.startDate,
      }));
    }
    if (!formContent.isInpatient && formConfiguration.admissionDischargeDate.fieldSpec === "OMIT") {
      setFormContent((oldContents) => ({
        ...oldContents,
        admissionDate: undefined,
      }));
    }
  }, [formContent.isInpatient, formConfiguration.admissionDischargeDate.fieldSpec, setFormContent]);

  const setEditModal = useCallback(() => {
    const showAllow = isAdditionalUnitsRequested
      ? false
      : (formContent?.isInpatient &&
          (formContent?.procedureCodes?.length ? !hasValidProcedureCodeWithUnits : true) &&
          formContent?.patientStatus === "DISCHARGED" &&
          isValid &&
          !hasMinStayDays) ||
        (formContent?.patientStatus === "NOT_YET_ADMITTED" && !isFormContentOnContinuationUpdated);
    setAllowDischargeModalToOpen?.(showAllow);
  }, [
    formContent?.isInpatient,
    formContent?.patientStatus,
    formContent?.procedureCodes?.length,
    hasMinStayDays,
    hasValidProcedureCodeWithUnits,
    isFormContentOnContinuationUpdated,
    isValid,
    isAdditionalUnitsRequested,
    setAllowDischargeModalToOpen,
  ]);
  useEffect(() => {
    if (facilityBasedFeatureEnabled) {
      setEditModal();
    }
  }, [facilityBasedFeatureEnabled, setEditModal]);

  useEffect(() => {
    if (facilityBasedFeatureEnabled && authorization) {
      const sortedSRs = getSortedServiceRequests(authorization);
      const latestApprovedSR = sortedSRs[0];
      let didExpectedAdditionalDateUnitChanged = true;
      if (formatDateToISODate(formContent?.expectedAdmissionDate) === latestApprovedSR.expectedAdmissionDate) {
        didExpectedAdditionalDateUnitChanged = true;
      }
      if (formContent?.units === "0" && formContent?.patientStayDateRanges?.length === 0) {
        didExpectedAdditionalDateUnitChanged = false;
      }
      setIsFormContentOnContinuationUpdated?.(didExpectedAdditionalDateUnitChanged);
    }
  }, [
    authorization,
    facilityBasedFeatureEnabled,
    formContent?.expectedAdmissionDate,
    formContent?.patientStayDateRanges?.length,
    formContent?.units,
    setIsFormContentOnContinuationUpdated,
  ]);

  useEffect(() => {
    if (authorization?.placeOfService && !isDiagnosisAndProviderCardOnContinuation) {
      setFormContent((prev) => {
        return { ...prev, placeOfService: authorization?.placeOfService };
      });
    }
  }, [authorization?.placeOfService, isDiagnosisAndProviderCardOnContinuation, setFormContent]);

  useEffect(() => {
    if (authorization && formContent.procedureCodes && formContent.procedureCodes.length > 0 && initialRender.current) {
      setShowServicesAndProceduresCard(true);
      if (initialRender.current) {
        initialRender.current = false;
      }
    }
  }, [authorization, formContent.procedureCodes]);

  //TODO: maybe we don't need this??
  useCheckServiceRequestProcedureCodes({
    procedureCodes: formContent.procedureCodes,
    palPxCodes,
    isInpatient: formContent.isInpatient,
    clinicalService,
    dispatchRedirectRuleCheck,
    placeOfServiceId: authorization?.placeOfService?.id,
    startDate: formContent.startDate,
    patient,
    workflowId,
  });

  /**
   * This sets the service request form content, but it also runs the onUserEdit callback.
   *
   * This is useful if we want to do something after the user explicitly edits the service request form content, not
   * just whenever the serviceRequestForm content changes (like from a PAR check effect or whatever)
   * @param newFormContent
   */
  const setFormContentOnUserEdit: Dispatch<SetStateAction<ContinuationFormContent>> = useCallback(
    (setStateAction) => {
      // In order to get the next state value to pass to onUserEdit, we actually need to call setFormContent
      // because we might in fact have a function, not a realized value
      setFormContent((prev) => {
        const newFormContent: ContinuationFormContent =
          typeof setStateAction === "function" ? setStateAction(prev) : setStateAction;
        onUserEdit?.(newFormContent);
        return newFormContent;
      });
    },
    [onUserEdit, setFormContent]
  );
  const {
    data: placeOfServiceData,
    loading: placeOfServiceLoading,
    error: getPlaceOfServiceError,
  } = useGetPlaceOfServices({
    queryParams: {
      healthPlanName: patientHealthPlanName || undefined,
      encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
      clinicalServiceId: clinicalService?.id,
    },
  });

  const updateFormContentsWithAdditionalCareParticipants = useCallback(
    (careParticipantConfig) => {
      setFormContentOnUserEdit((prev) => ({
        ...prev,
        additionalCareParticipants: updateCareParticipants(careParticipantConfig, prev),
      }));
    },
    [setFormContentOnUserEdit]
  );

  useEffect(() => {
    if (careParticipantEnabled && careParticipantConfig && shouldUpdateAdditionalCareParticipants && !isLoading) {
      updateFormContentsWithAdditionalCareParticipants(careParticipantConfig);
    }
  }, [
    careParticipantConfig,
    careParticipantEnabled,
    updateFormContentsWithAdditionalCareParticipants,
    isLoading,
    shouldUpdateAdditionalCareParticipants,
  ]);

  formConfiguration = formFieldConfigurations;

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (getPlaceOfServiceError) {
      enqueueSnackbar(`Error getting places of service ${getPlaceOfServiceError.message}`, { variant: "error" });
    }
  }, [getPlaceOfServiceError, enqueueSnackbar]);

  /*
   * TODO this hook is "new" and should probably replace the one above...
   */
  const { doPalCheck: doPalOrCrdCheck } = useDoPalCheck({
    canCheckPAL: !!formContent.procedureCodes?.length || (formContent?.isInpatient && facilityBasedFeatureEnabled),
    patientData: patient || null,
    patientId: patient?.id,
    serviceRequestFormContents: [formContent],
    setCrdLogId: (newCrdLogId: string) => {
      setFormContent((prev) => ({ ...prev, newCrdLogId: newCrdLogId }));
      return Promise.resolve(undefined);
    },
    setNonCohereCodes: setNonCohereProcedureCodes,
    setNonPalProcedureCodes: setNonPalProcedureCodes,
    setPalProcedureCodes: setPalProcedureCodes,
    setPriorAuthRequirements: () => {
      /* Not needed for continuation */
    },
  });

  const callCrdCheckWithState = (payload: { desiredProcedureCodes?: ProcedureCode[] }) => {
    return doPalOrCrdCheck({
      primaryDiagnosis: formContent.primaryDiagnosisCode || undefined,
      secondaryDiagnoses: formContent.secondaryDiagnosisCodes,
      startDate: formContent.startDate,
      encounterType: encounterType,
      desiredProcedureCodes: formContent.procedureCodes,
      showPxCheckbox: false,
      noPxServiceRequired: false,
      authCategory: formContent.authCategory,
      authSubcategory: formContent.authSubcategory,
      userSelectedOutOfNetworkException: formContent.userSelectedOONException,
      userSelectedNonPalCode: formContent.userSelectedNonPalCode,
      additionalInsurance: formContent.additionalInsurance,
      ...payload,
    });
  };

  const ServicesAndProceduresCardContainer = () => {
    return (
      <>
        <ServicesAndProceduresCard
          formConfiguration={formConfiguration}
          formContent={formContent}
          setFormContent={setFormContent}
          patient={patient || null}
          attemptedSubmit={attemptedSubmit}
          authStatus={currAuthStatus}
          palProcedureCodes={formContent.procedureCodes}
          setPalProcedureCodes={setPalProcedureCodes}
          nonPalProcedureCodes={nonPalProcedureCodes}
          setNonPalProcedureCodes={setNonPalProcedureCodes}
          nonCohereProcedureCodes={nonCohereProcedureCodes}
          setNonCohereProcedureCodes={setNonCohereProcedureCodes}
          isContinuation={serviceRequest?.requestType === "CONTINUATION"}
          authorization={authorization || undefined}
          requestType={requestType}
          dispatchRedirectRuleCheck={dispatchRedirectRuleCheck}
          dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
          showEndDate={shouldShowEndDate}
          onPatientSummary={onPatientSummary}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
          hasValidStartDate={hasValidStartDate}
          startDateErrorMessage={startDateErrorMessage}
          hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
          serviceRequest={serviceRequest}
          setIsAdditionalUnitsRequested={setIsAdditionalUnitsRequested}
          allowedPxCodePerPayer={allowedPxCodePerPayer}
          doPalOrCrdCheck={callCrdCheckWithState}
          showPxBasedAttributedField={showPxBasedAttributedField}
          showPxAttributeValidation={formConfiguration?.selectedDetails?.fieldSpec === "REQUIRED"}
        />
        {otherInsuranceEnabled &&
          !finalDeterminationStates.includes(serviceRequest?.authStatus || "") &&
          (serviceRequest?.requestType === "INITIAL" || serviceRequest?.requestType === "CONTINUATION") && (
            <>
              <div style={{ marginTop: spacing(3) }}>
                <AdditionalInsuranceSection
                  otherInsurance={otherInsurance}
                  healthPlanName={patientHealthPlanName}
                  defaultAdditionalInsurance={
                    serviceRequest?.additionalInsurance || {
                      additionalInsurance: "NONE",
                      additionalInsuranceText: "None",
                    }
                  }
                  onChange={(additionalInsurance: AdditionalInsurance | undefined) => {
                    setFormContent({ ...formContent, additionalInsurance: additionalInsurance });
                  }}
                />
              </div>
            </>
          )}
      </>
    );
  };

  return (
    <Grid>
      {onPatientSummary && formConfiguration !== undefined && (
        <>
          <Grid item xs={12} style={{ marginTop: spacing(3) }}>
            <DiagnosisCard
              authStatus={currAuthStatus}
              error={attemptedSubmit && !VALIDATION_MAP["primaryDiagnosis"]}
              formContent={formContent}
              isCohereFaxForm={isCohereFaxForm}
              setFormContent={setFormContent}
              workflowId={workflowId}
              formConfiguration={formConfiguration as FormConfiguration}
              isEdit={true}
              healthPlanName={patientHealthPlanName}
              delegatedVendorName={
                serviceRequest?.delegatedVendor ||
                (currAuthStatus === "DRAFT" ? patientRiskBearingEntity : undefined) ||
                ""
              }
              isContinuation={serviceRequest?.requestType === "CONTINUATION"}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: spacing(3) }}>
            <ProvidersCard
              attemptedSubmit={attemptedSubmit}
              VALIDATION_MAP={convertContinuationFieldNameToFacilityBasedGeneralAuthSubmissionForm(VALIDATION_MAP)}
              formContent={formContent}
              setFormContent={setFormContent}
              sameProviders={sameProviders}
              dispatchRedirectRuleCheck={dispatchRedirectRuleCheck}
              setSameProviders={setSameProviders}
              performingIsOptional={formConfiguration.performingProvider.fieldSpec === "OPTIONAL"}
              authStatus={currAuthStatus}
              facilityIsOptional={formConfiguration.facility.fieldSpec === "OPTIONAL"}
              isInpatient={formContent?.isInpatient}
              placeOfService={formContent?.placeOfService || null}
              setPlaceOfService={(pos) => {
                setFormContentOnUserEdit({ ...formContent, placeOfService: pos });
              }}
              placeOfServiceLoading={placeOfServiceLoading}
              availablePlacesOfService={placeOfServiceData || undefined}
              formFieldConfigurations={formConfiguration as FormConfiguration}
              onPatientSummary={onPatientSummary}
              isContinuation={serviceRequest?.requestType === "CONTINUATION"}
              patient={patient}
              isFacilityOutOfNetworkCommentRequired={requiresFacilityOONExceptionComment}
              isPerformingProviderOutOfNetworkCommentRequired={requiresPerformingProviderOONExceptionComment}
              isOrderingProviderOutOfNetworkCommentRequired={requiresOrderingProviderOONExceptionComment}
              authStage={convertStringToAuthBuilderWorkflowStep("FILL_FORMS_CONTINUATION")}
              performingProviderExceptionRequest={performingProviderExceptionRequest}
              setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
              facilityExceptionRequest={facilityExceptionRequest}
              setFacilityExceptionRequest={setFacilityExceptionRequest}
              orderingProviderExceptionRequest={orderingProviderExceptionRequest}
              setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
              careParticipantExceptionRequest={careParticipantExceptionRequest}
              setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
              outOfNetworkCheckConfiguration={outOfNetworkCheckConfiguration}
              setPerformingProviderPracticeExceptionRequest={setPerformingProviderPracticeExceptionRequest}
              performingProviderPracticeExceptionRequest={performingProviderPracticeExceptionRequest}
              delegatedVendorName={
                serviceRequest?.delegatedVendor ||
                (currAuthStatus === "DRAFT" ? patientRiskBearingEntity : undefined) ||
                ""
              }
              shouldBlockOonChanges={shouldBlockOonChanges}
              isBlockingOonChange={isBlockingOonChange}
              setFacilityOonCheckLoading={setFacilityOonCheckLoading}
              setPerformingProviderPracticeOonCheckLoading={setPerformingProviderPracticeOonCheckLoading}
              setProviderOonCheckLoading={setProviderOonCheckLoading}
              setOrderingProviderOonCheckLoading={setOrderingProviderOonCheckLoading}
            />
          </Grid>
        </>
      )}
      {facilityBasedFeatureEnabled && formContent.isInpatient && (
        <>
          {shouldUseCohereCard() ? (
            <Grid item xs={12} style={{ marginTop: spacing(3) }}>
              <CohereCard
                noBorder={isFaxAuthBuilderFlow}
                noBoxShadow={isFaxAuthBuilderFlow}
                style={{ padding: isFaxAuthBuilderFlow ? spacing(0, 0, 3, 0) : spacing(3) }}
              >
                <PatientStayCard
                  formContent={formContent}
                  setFormContent={setFormContent}
                  healthPlanName={patientHealthPlanName}
                  delegatedVendorName={
                    serviceRequest?.delegatedVendor ||
                    (currAuthStatus === "DRAFT" ? patientRiskBearingEntity : undefined) ||
                    ""
                  }
                  isContinuation={serviceRequest?.requestType === "CONTINUATION"}
                  authorization={authorization ?? undefined}
                  serviceRequest={serviceRequest}
                  attemptedSubmit={attemptedSubmit}
                  timePattern={timePattern}
                  startDateCoverage={startDateCoverage}
                  onPatientSummary={onPatientSummary}
                  formConfiguration={formConfiguration}
                  authStatus={currAuthStatus}
                  patient={patient || null}
                  dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
                  hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
                  disablePatientStatusChange={false}
                  shouldNotWaitForAuth={false}
                  encounterType={serviceRequest?.encounterType}
                />
              </CohereCard>
            </Grid>
          ) : (
            <Grid item xs={12} style={{ marginTop: spacing(3) }}>
              <PatientStayCard
                formContent={formContent}
                setFormContent={setFormContent}
                healthPlanName={patientHealthPlanName}
                delegatedVendorName={
                  serviceRequest?.delegatedVendor ||
                  (currAuthStatus === "DRAFT" ? patientRiskBearingEntity : null) ||
                  ""
                }
                isContinuation={serviceRequest?.requestType === "CONTINUATION"}
                authorization={authorization ?? undefined}
                serviceRequest={serviceRequest}
                attemptedSubmit={attemptedSubmit}
                timePattern={timePattern}
                startDateCoverage={startDateCoverage}
                onPatientSummary={onPatientSummary}
                formConfiguration={formConfiguration}
                authStatus={currAuthStatus}
                patient={patient || null}
                dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
                hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
                disablePatientStatusChange={false}
                shouldNotWaitForAuth={false}
                encounterType={serviceRequest?.encounterType}
              />
            </Grid>
          )}
        </>
      )}
      {showServicesAndProceduresCard && (
        <>
          {shouldUseCohereCard() ? (
            <CohereCard
              style={{
                marginTop: isFaxAuthBuilderFlow ? spacing(0) : spacing(3),
                padding: isFaxAuthBuilderFlow ? "24px 0px" : "24px",
              }}
              noBorder={isFaxAuthBuilderFlow}
              noBoxShadow={isFaxAuthBuilderFlow}
            >
              <Grid item xs={12}>
                {ServicesAndProceduresCardContainer()}
              </Grid>
            </CohereCard>
          ) : (
            <Grid item xs={12} style={{ marginTop: spacing(5) }}>
              {ServicesAndProceduresCardContainer()}
            </Grid>
          )}
        </>
      )}
      {!showServicesAndProceduresCard && serviceRequest?.healthPlanName === "Geisinger" && (
        <>
          {otherInsuranceEnabled &&
            !finalDeterminationStates.includes(serviceRequest?.authStatus || "") &&
            (serviceRequest?.requestType === "INITIAL" || serviceRequest?.requestType === "CONTINUATION") && (
              <Grid item xs={12} style={{ marginTop: spacing(3) }}>
                <AdditionalInsuranceSection
                  otherInsurance={otherInsurance}
                  healthPlanName={patientHealthPlanName}
                  defaultAdditionalInsurance={
                    serviceRequest?.additionalInsurance || {
                      additionalInsurance: "NONE",
                      additionalInsuranceText: "None",
                    }
                  }
                  onChange={(additionalInsurance: AdditionalInsurance | undefined) => {
                    setFormContent({ ...formContent, additionalInsurance: additionalInsurance });
                  }}
                />
              </Grid>
            )}
        </>
      )}

      <ServiceRequestFormRedirectModal
        {...redirectModalProps}
        formContent={formContent}
        setFormContent={setFormContent}
      />
    </Grid>
  );
}
