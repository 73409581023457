import React, { useCallback, useEffect, useState, Dispatch, SetStateAction, useContext } from "react";
import {
  Body2,
  SecondaryButton,
  Tooltip,
  InformativeModal,
  Subtitle2,
  InformativeModalProps,
  useFeature,
  Body1,
  H4,
  Sanitized,
  HEALTH_HELP_V2_NAME,
  formatDateToISODate,
  useGeneralAuthSubmissionWorflowOn,
  formatDateStr,
  TertiaryButton,
} from "@coherehealth/common";
import {
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  useDeleteServiceRequest,
  useTestUpdateReplaceOrAmendServiceRequest,
  useUpdateReplaceOrAmendServiceRequest,
  useWithdrawAndReplaceServiceRequest,
  AuthorizationResponse,
  ServiceRequestCommandPayload,
  useAddServiceRequestOnAuthorization,
  useGetAuthValidityWindow,
  useUpdateAuthorizationById,
  PatientStayDate,
  ServiceRequestUpdatePayload,
} from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import { Divider, useTheme } from "@material-ui/core";
import PencilIcon from "@material-ui/icons/Create";
import MuiErrorIcon from "@material-ui/icons/Error";
import { error as logError } from "logger";
import { useSnackbar } from "notistack";
import { generatePath, useLocation } from "react-router";
import { useNavigate, To } from "react-router-dom";
import routes from "routes";
import {
  canBeEdited,
  canBeDeleted,
  canBeWithdrawn,
  useIsEditDisabledForReview,
  payloadFromSRFormContent,
  formContentFromResponse,
  continuationWorkflowPath,
  startNewAuthWorkflowPath,
  useServiceRequestWithdrawn,
  addFlexibleCodeIntakeFieldsToProcedureCodes,
  formContentToResponse,
} from "util/serviceRequest";
import ServiceRequestForm from "../../ServiceRequest/ServiceRequestForm/ServiceRequestFormSubmissionWrapper";
import ServiceRequestFormReadOnly from "./ServiceRequestFormReadOnly";
import { SectionHeaderContainer } from "./ServiceRequestSummaryDetails";
import WithdrawModal from "./WithdrawModal";
import ApprovedSrEditRedirectionModal, {
  Props as ApprovedSrEditRedirectModalProps,
} from "../../ServiceRequest/ServiceRequestForm/components/ApprovedSrEditRedirectionModal";
import {
  activityContextFromServiceRequest,
  activitySnapshotFromServiceRequestFormContent,
  activitySnapshotFromServiceRequest,
  useTrackUserInteraction,
  TrackUserActivityProps,
  useTrackUserImpression,
} from "util/userActivityTracker";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import ServiceRequestWithdraw from "../../images/ServiceRequestWithdraw.svg";
import { useAuthorized } from "../../../authorization";
import WithdrawAndReplaceServiceRequests from "./WithdrawAndReplaceServiceRequests";
import StartContinuationModal from "components/StartContinuationModal";
import StartNewServiceRequestModal from "components/StartNewServiceRequestModal";
import { getLatestDeterminedServiceRequest, isAuthorizationValidForContinuations } from "util/authorization";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { deAggregateStayDates } from "components/ServiceRequest/PatientStay/PatientStays";
import { useServiceRequestFormSectionStyles } from "../patientSummaryStyles";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";
import { useCalculateInitializedEndDate } from "components/AuthBuilder/common";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { getParametersFaxIntake } from "util/queryParams";
import useOpsEditDisabled from "hooks/useOpsEditDisabled";
import { haveProcedureCodesChanged } from "./hooks/useProcedureCodeChangeBanner";
import { PatientStayTimeline } from "components/ServiceRequest/PatientStay/PatientStayTimeline/PatientStayTimeline";

export type SRFormViewState = "EDIT" | "READ_ONLY";

type ApprovedSrEditState = Omit<ApprovedSrEditRedirectModalProps, "handleClose" | "loading">;
const defaultApprovedSrModalState: ApprovedSrEditState = {
  open: false,
  variant: "AMEND",
  onContinue: () => {},
};

export type InformativeModalConfigState =
  | "CHANNEL_WARN"
  | "WITHDRAW_AND_REPLACE"
  | "WITHDRAW_AND_REPLACE_AUTH_DECISION_GROUP"
  | "ISSUE_SAVING";
type InformativeModalConfigProps = Omit<InformativeModalProps, "open">;
type InformativeModalConfig = Record<InformativeModalConfigState, InformativeModalConfigProps>;

interface Props {
  authorization?: AuthorizationResponse;
  refreshAuthorization?: () => Promise<void>;
  refreshAuthorizationList?: () => void;
  serviceRequest: ServiceRequestResponse;
  serviceRequests?: ServiceRequestResponse[];
  viewState: SRFormViewState;
  setViewState: (newState: SRFormViewState) => void;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  simulateRuleRun?: () => void;
  submitEditsOnDraftSrsToIntake?: boolean;
  hideEditWithdrawButtons?: boolean;
  clinicalReviewView?: boolean;
  serviceRequestRefetch?: () => Promise<ServiceRequestResponse | null>;
  populateDefaults?: boolean;
  incrementNudgeOpKey?: () => void;
  incrementReviewKey?: () => void;
  setUpdatedServiceRequestPayload?: Dispatch<SetStateAction<ServiceRequestUpdatePayload | undefined>>;
  setIsEditView?: Dispatch<SetStateAction<boolean>>;
  setServiceRequest?: Dispatch<SetStateAction<ServiceRequestResponse | undefined>>;
  isFaxEdit?: boolean;
}

export default function ServiceRequestFormSection({
  authorization,
  refreshAuthorization,
  refreshAuthorizationList,
  serviceRequest,
  serviceRequests,
  viewState,
  setViewState,
  simulateRuleRun,
  submitEditsOnDraftSrsToIntake = true,
  hideEditWithdrawButtons = false,
  clinicalReviewView,
  serviceRequestRefetch,
  populateDefaults,
  incrementNudgeOpKey,
  incrementReviewKey,
  setUpdatedServiceRequestPayload,
  setIsEditView,
  setServiceRequest,
  isFaxEdit = false,
}: Props) {
  const srFormSectionStyles = useServiceRequestFormSectionStyles();
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const isIntegrateAuthDecisionGroups = useFeature("integrateAuthDecisionGroups");
  const generalAuthSubmission = useGeneralAuthSubmissionWorflowOn(serviceRequest.healthPlanName || "");
  const authorizedToEditRequest = useAuthorized("EDIT_SERVICE_REQUEST");
  const { healthPlanName, encounterType, requestTiming, delegatedVendor } = serviceRequest;
  const { faxAttachment, caseId, queueId } = useContext(FaxAttachmentContext);
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    requestTiming,
    skipRequestTimingCheck: true,
    delegatedVendorName: delegatedVendor,
  });
  // REST Hooks
  const { enqueueSnackbar } = useSnackbar();
  const [requestor, setRequestor] = useState(serviceRequest.requestor);
  const {
    mutate: testUpdateReplaceOrAmend,
    error: testUpdateReplaceOrAmendError,
    loading: testUpdateReplaceOrAmendLoading,
  } = useTestUpdateReplaceOrAmendServiceRequest({});
  const {
    mutate: updateReplaceOrAmend,
    error: updateReplaceOrAmendError,
    loading: updateReplaceOrAmendLoading,
  } = useUpdateReplaceOrAmendServiceRequest({});
  const {
    mutate: withdrawAndReplace,
    error: withdrawAndReplaceError,
    loading: withdrawAndReplaceLoading,
  } = useWithdrawAndReplaceServiceRequest({});

  const { mutate: patchAuthorization } = useUpdateAuthorizationById({ id: "" });

  const canEditChannel = useAuthorized("EDIT_CHANNEL");
  useEffect(() => {
    if (testUpdateReplaceOrAmendError) {
      enqueueSnackbar(`Error updating service request: ${testUpdateReplaceOrAmendError.message}`, { variant: "error" });
    }
    if (updateReplaceOrAmendError) {
      enqueueSnackbar(`Error updating service request: ${updateReplaceOrAmendError.message}`, { variant: "error" });
    }
    if (withdrawAndReplaceError) {
      enqueueSnackbar(`Error withdrawing and replacing service request`, { variant: "error" });
    }
  }, [enqueueSnackbar, testUpdateReplaceOrAmendError, updateReplaceOrAmendError, withdrawAndReplaceError]);

  const { mutate: deleteServiceRequest, loading: deleting, error: deleteError } = useDeleteServiceRequest({});
  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(`Error removing service request: ${deleteError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, deleteError]);

  const [approvedSrEditModalState, setApprovedSrEditModalState] =
    useState<ApprovedSrEditState>(defaultApprovedSrModalState);
  const clearApprovedSrEditModalState = useCallback(() => {
    setApprovedSrEditModalState(defaultApprovedSrModalState);
  }, [setApprovedSrEditModalState]);

  const [showInformativeModal, setShowInformativeModal] = useState<boolean>(false);
  const [informativeModalConfigState, setInformativeModalConfigState] =
    useState<InformativeModalConfigState>("CHANNEL_WARN");
  const setAndOpenInformativeModal = useCallback(
    (informativeModalConfigState) => {
      setInformativeModalConfigState(informativeModalConfigState);
      setShowInformativeModal(true);
    },
    [setInformativeModalConfigState, setShowInformativeModal]
  );

  const postEditActions = useCallback(() => {
    incrementNudgeOpKey?.();
    incrementReviewKey?.();
  }, [incrementNudgeOpKey, incrementReviewKey]);

  const editAndRefreshList = useCallback(() => {
    postEditActions();
    refreshAuthorizationList?.();
  }, [postEditActions, refreshAuthorizationList]);

  const postSaveActions = useCallback(() => {
    setViewState("READ_ONLY");
    setIsEditView?.(false);
    postEditActions();
    simulateRuleRun?.();
    if (!isFaxEdit) {
      refreshAuthorization?.();
    }
  }, [setViewState, postEditActions, simulateRuleRun, refreshAuthorization, isFaxEdit, setIsEditView]);

  const navigate = useNavigate();

  const trackUserActivityInteraction = useTrackUserInteraction();
  const trackUserActivityImpression = useTrackUserImpression();
  const {
    withdrawModalOpen,
    setWithdrawModalOpen,
    withdrawRequestor,
    setWithdrawRequestor,
    withdrawnReason,
    setWithdrawnReason,
    onWithdraw,
    withdrawAuthDecisionGroup,
    loadingWithdrawAuthDecisionGroup,
    patch,
    submitting,
    submitError,
  } = useServiceRequestWithdrawn(serviceRequest, setViewState, editAndRefreshList, setAndOpenInformativeModal);
  const [openContinuationModal, setOpenContinuationModal] = useState(false);
  const [loadingContinuationFlow, setLoadingContinuationFlow] = useState(false);
  const [openStartNewRequestModal, setOpenNewRequestModal] = useState(false);
  const [continuationSr, setContinuationSr] = useState<ServiceRequestCreatePayload>();

  //Start a continuation
  const { mutate: createNewServiceRequestOnAuth, error: createContinuationError } = useAddServiceRequestOnAuthorization(
    {
      id: serviceRequest?.authorization?.id || "",
      requestOptions: { headers: { Accept: "application/json" } },
    }
  );

  useEffect(() => {
    if (createContinuationError) {
      enqueueSnackbar(
        typeof createContinuationError.data === "object"
          ? createContinuationError.data?.message
          : createContinuationError.message,
        { variant: "error" }
      );
    }
  }, [createContinuationError, enqueueSnackbar]);

  const { data, refetch } = useGetAuthValidityWindow({ lazy: true });

  function navigateToContinuationWorkflowStep(serviceRequestId: string, patientId: string, authorizationId: string) {
    const { pathname, search } = continuationWorkflowPath(patientId, serviceRequestId);
    const toNavigate: To = { pathname };

    if (isFaxEdit) {
      const faxSearchParams = getParametersFaxIntake(search, faxAttachment?.id, caseId, queueId);
      toNavigate.search = `?${faxSearchParams}`;
    } else {
      toNavigate.search = search;
    }
    navigate(toNavigate, {
      state: {
        isContinuationWorkflow: true,
        authorizationId,
      },
    });
  }
  const calculateInitializedEndDate = useCalculateInitializedEndDate(healthPlanName);
  const onContinuationClick = async () => {
    // creates a new empty draft SR on the authorization and directs the user to the fill forms continuation page for the newly created draft
    let payload: ServiceRequestCommandPayload = {
      authStatus: "DRAFT",
      urgency: { isExpedited: false },
      units: 0,
      semanticProcedureCodes: serviceRequest?.procedureCodes,
      requestType: "CONTINUATION",
      workflowStep: "FILL_FORMS_CONTINUATION",
      encounterType: serviceRequest?.encounterType,
      continuationGeneratedFromServiceRequest: {
        id: serviceRequest.id,
      },
    };

    setLoadingContinuationFlow(true);
    const today = new Date();
    let endDate;
    const initializedEndDate = calculateInitializedEndDate(
      today,
      serviceRequest.clinicalServices || [],
      serviceRequest.patient?.coverages || []
    );
    if (initializedEndDate) {
      endDate = initializedEndDate;
    }
    if (serviceRequest?.patient?.id && serviceRequest?.startDate) {
      await refetch({ queryParams: { patient: serviceRequest.patient.id, startDate: serviceRequest.startDate } });
    }
    const lastValidDate = data?.validityWindowEndDate ? new Date(data.validityWindowEndDate) : endDate;
    payload.endDate = formatDateToISODate(lastValidDate);

    if (continuationSr?.units !== serviceRequest?.units) {
      payload.units = continuationSr?.units;
    }
    payload.semanticProcedureCodes = generalAuthSubmission
      ? addFlexibleCodeIntakeFieldsToProcedureCodes(
          continuationSr?.semanticProcedureCodes || [],
          serviceRequest.clinicalService
        )
      : continuationSr?.semanticProcedureCodes;
    payload.encounterType = continuationSr?.encounterType || authorization?.encounterType;

    payload.patientStatus = continuationSr?.patientStatus;
    if (continuationSr?.patientStatus === "CURRENTLY_ADMITTED" || continuationSr?.patientStatus === "DISCHARGED") {
      //need to add the currently admitted fields
      payload.admissionDate = continuationSr.admissionDate;
      payload.admissionTime = continuationSr.admissionTime;
      payload.admissionSource = continuationSr.admissionSource;
    }

    if (continuationSr?.patientStatus === "DISCHARGED") {
      //need to add the discharged fields
      payload.dischargeDate = continuationSr.dischargeDate;
      payload.dischargeTime = continuationSr.dischargeTime;
    }

    if (!!continuationSr?.patientStayDates && continuationSr?.patientStayDates.length > 0) {
      if (!serviceRequest.patientStayDates || serviceRequest.patientStayDates.length === 0) {
        //there are no previous dates, so just request everything
        payload.patientStayDates = [...continuationSr.patientStayDates];
      } else {
        // filter only the stay dates that are not part of the service request
        const filteredDates = continuationSr?.patientStayDates?.filter((continuationDate: PatientStayDate) => {
          //check that serviceRequest.patientStayDates does not contain this date
          const containsDate = serviceRequest.patientStayDates?.find((serviceRequestDate: PatientStayDate) => {
            return formatDateStr(continuationDate.date) === formatDateStr(serviceRequestDate.date);
          });

          return !containsDate;
        });

        payload.patientStayDates = [...filteredDates];
      }
    }

    const addDraftSRResponse = await createNewServiceRequestOnAuth(payload);
    navigateToContinuationWorkflowStep(
      addDraftSRResponse?.id,
      addDraftSRResponse?.patient?.id || "",
      addDraftSRResponse?.authorization?.id || ""
    );
  };

  const location = useLocation();
  const isOnReviewPage = location.pathname.includes("/review");

  const handleSaveOperation = async (sr: ServiceRequestCreatePayload, formContent: ServiceRequestFormContent) => {
    if (serviceRequest.authStatus === "APPROVED" || serviceRequest.authStatus === "PARTIALLY_APPROVED") {
      setContinuationSr(sr);
      const { verb: updateReplaceOrAmendVerb } = await testUpdateReplaceOrAmend({
        ...sr,
        authorization: authorization ? { id: authorization?.id } : undefined,
        id: serviceRequest.id,
      });
      const userActivityInteractionPayload: TrackUserActivityProps = {
        event: "REPLACE_DECISIONED_AUTH",
        stage: "DECISIONED_AUTH",
        activityContext: activityContextFromServiceRequest(serviceRequest),
        beforeSnapshot: activitySnapshotFromServiceRequest(serviceRequest),
        afterSnapshot: activitySnapshotFromServiceRequestFormContent(formContent),
      };

      if (updateReplaceOrAmendVerb === "REPLACE") {
        trackUserActivityImpression({ ...userActivityInteractionPayload, event: "REPLACE_DECISIONED_AUTH" });
        setOpenNewRequestModal(true);
      } else if (updateReplaceOrAmendVerb === "CONTINUATION") {
        if (canCreateContinuation) {
          setOpenContinuationModal(true);
        } else {
          //direct to start new auth request
          setOpenNewRequestModal(true);
        }
      } else if (updateReplaceOrAmendVerb === "UPDATE") {
        if (isFaxEdit && !!caseId) {
          setUpdatePayloadToState(sr, formContent);
        } else {
          const updateAmendReplacePayload = {
            ...sr,
            authorization: authorization ? { id: authorization?.id } : undefined,
            id: serviceRequest.id,
          };

          // this is in ref to COH-4912 - npi being empty assumes the minimum missing info for this field to be null
          if (
            serviceRequest.formConfiguration &&
            serviceRequest.formConfiguration["performingProvider"].fieldSpec === "OPTIONAL" &&
            sr.selectedPerformingProvider?.npi === ""
          ) {
            updateAmendReplacePayload.selectedPerformingProvider = null;
          }
          await updateReplaceOrAmend({ ...updateAmendReplacePayload });
        }

        trackUserActivityInteraction({ ...userActivityInteractionPayload, event: "UPDATE_DECISIONED_AUTH" });
        postSaveActions();
      } else {
        logError("No verb returned from testUpdateReplaceOrAmend");
        enqueueSnackbar("Error updating service request");
      }
    } else {
      const updatePayload: ServiceRequestUpdatePayload = {
        ...sr,
        authorization: authorization ? { id: authorization?.id } : undefined,
        authCategory: formContent?.authCategory ? formContent.authCategory.enumName : sr.authCategory,
        patientStayDates: (facilityBasedFeatureEnabled && formContent?.patientStayDateRanges
          ? deAggregateStayDates(formContent.patientStayDateRanges)
          : sr.patientStayDates
        )?.map((date) => {
          return { ...date, reviewStatus: date.reviewStatus || "PENDING" };
        }),
        patientStatus:
          facilityBasedFeatureEnabled && formContent?.patientStatus ? formContent.patientStatus : sr.patientStatus,
        // Only add the requestor to the payload if defined
        ...(requestor ? { requestor } : {}),
        authStatus: formContent.authStatus,
      };
      if (isFaxEdit && !!caseId) {
        setUpdatedServiceRequestPayload?.(updatePayload);

        const updatedServiceRequestResponse = formContentToResponse(
          formContent,
          simplifiedServiceFrequency,
          serviceRequest
        );
        setServiceRequest?.(updatedServiceRequestResponse);
      } else {
        await patch(updatePayload);
        if (facilityBasedFeatureEnabled && Boolean(sr?.dischargedTo) && authorization?.id) {
          await patchAuthorization({ dischargedTo: sr.dischargedTo }, { pathParams: { id: authorization.id } });
        }
      }

      postSaveActions();
    }

    if (!isFaxEdit) {
      serviceRequestRefetch?.();
    }

    if (haveProcedureCodesChanged(serviceRequest.semanticProcedureCodes, sr.semanticProcedureCodes) && isOnReviewPage) {
      navigate(`/${serviceRequest.id}/review${location.search}`);
      window.location.reload();
    }
  };

  const onDelete = canBeDeleted(serviceRequest)
    ? async () => {
        await deleteServiceRequest(serviceRequest.id);
        setViewState("READ_ONLY");
        setIsEditView?.(false);
        editAndRefreshList?.();
      }
    : undefined;

  const onWithdrawAndReplace = async (sr: ServiceRequestResponse) => {
    const formContent = formContentFromResponse(sr);
    const payload = payloadFromSRFormContent(formContent, simplifiedServiceFrequency);
    withdrawAndReplace({ ...payload, id: serviceRequest?.id }).then((response) => {
      navigate(generatePath(routes.WITHDRAW_AND_REPLACE, { serviceRequestId: response.id }));
    });
  };

  const onWithdrawAndReplaceAuthDecison = async (sr: ServiceRequestResponse) => {
    withdrawAuthDecisionGroup({ withdrawnReason: "VOID_AND_REPLACE" }).then((response) => {
      navigate(generatePath(routes.AUTH_BUILDER, { patientId: sr?.patient?.id || "" }));
    });
  };

  const theme = useTheme();
  const latestSr = getLatestDeterminedServiceRequest(authorization);
  const onlyAllowView = (latestSr && !canBeEdited(latestSr)) || !authorizedToEditRequest || hideEditWithdrawButtons;
  const isEditDisabledForReview = useIsEditDisabledForReview(latestSr || serviceRequest);

  const srEditsPayerConfigurable = useFeature("srEditsPayerConfigurable");
  const showPatientStayTimeline = useFeature("showPatientStayTimeline");

  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    healthPlanName: latestSr?.healthPlanName || serviceRequest?.healthPlanName,
    delegatedVendorName: latestSr?.delegatedVendor || serviceRequest?.delegatedVendor,
    authStatus: serviceRequest?.authStatus,
  });
  const srEditConfigEditDisabled = srEditsPayerConfigurable && srEditConfig?.disableFormEdits;
  const editCheck =
    srEditConfigEditDisabled || isEditDisabledForReview || latestSr?.type === "Pharmacy" || serviceRequest.isRestricted;
  const editDisabled = useOpsEditDisabled(serviceRequest) || editCheck;

  const canViewFacilityState = useAuthorized("VIEW_CLINICAL_ASSESSMENT_TEST_PAGE");
  const isClearerDraftWorkflow = useFeature("clearerDraftWorkflow");
  const canCreateContinuation =
    latestSr &&
    serviceRequest.delegatedVendor !== HEALTH_HELP_V2_NAME &&
    isAuthorizationValidForContinuations(serviceRequest, latestSr);

  const handleEditButtonClick = () => {
    if (latestSr && latestSr.authStatus === "DRAFT" && latestSr.requestor?.channel === "FAX" && canEditChannel) {
      setAndOpenInformativeModal("CHANNEL_WARN");
    } else if (
      (latestSr?.authDecisionGroup?.id && isIntegrateAuthDecisionGroups) ||
      latestSr?.authDecisionGroup?.authNumber
    ) {
      setAndOpenInformativeModal("WITHDRAW_AND_REPLACE_AUTH_DECISION_GROUP");
    } else {
      setViewState("EDIT");
      setIsEditView?.(true);
    }
  };

  const providerCallModalConfig: InformativeModalConfigProps = {
    headerText: "Are you editing this draft request due to a provider call?",
    subHeaderElement: (
      <Subtitle2>
        If you are not editing this draft request due to a provider call, please create a new service request.
      </Subtitle2>
    ),
    primaryButtonText: "Yes, continue",
    tertiaryButtonText: "Go back",
    primaryButtonAction: () => {
      setRequestor((prev) => ({ ...prev, channel: "PHONE" }));
      setViewState("EDIT");
      setIsEditView?.(true);
      setShowInformativeModal(false);
    },
    tertiaryButtonAction: () => {
      setShowInformativeModal(false);
    },
    onClose: () => {
      setShowInformativeModal(false);
    },
  };

  const withdrawAndReplaceModalConfig: InformativeModalConfigProps = {
    icon: <img alt="" src={ServiceRequestWithdraw} width={140} height={140} />,
    headerText: "We will submit this change as a new service request.",
    subHeaderElement: (
      <Body1>
        This authorization is processed by HealthHelp. Your new authorization number will replace you original request
        which will be withdrawn.
      </Body1>
    ),
    primaryButtonText: "Void and replace",
    primaryButtonAction: () => onWithdrawAndReplace(serviceRequest),
    primaryButtonLoading: withdrawAndReplaceLoading,
    tertiaryButtonText: "Go back",
    tertiaryButtonAction: () => {
      setShowInformativeModal(false);
    },
    onClose: () => {
      setShowInformativeModal(false);
    },
  };

  const withdrawAndReplaceModaWithAuthDecisionlConfig: InformativeModalConfigProps = {
    customContentStyle: srFormSectionStyles.splitAuthContent,
    customButtonStyle: srFormSectionStyles.withdrawPrimaryButton,
    dialogWidth: "xl",
    headerText: "We are unable to support edits to your request at this time",
    subHeaderElement: (
      <>
        <Subtitle2>
          If you need to make a change, please void and start a new request. Other requests in this auth group will be
          voided too.
        </Subtitle2>
        <div className={srFormSectionStyles.withdrawReplacePadding}>
          {serviceRequest?.authDecisionGroup?.id && (
            <WithdrawAndReplaceServiceRequests authDecisionGroupId={serviceRequest?.authDecisionGroup?.id} />
          )}
        </div>
      </>
    ),
    primaryButtonText: "Void and start new request",
    showDivider: false,
    primaryButtonAction: () => onWithdrawAndReplaceAuthDecison(serviceRequest),
    primaryButtonLoading: loadingWithdrawAuthDecisionGroup,
    tertiaryButtonText: "Go back",
    tertiaryButtonAction: () => {
      setShowInformativeModal(false);
    },
    onClose: () => {
      setShowInformativeModal(false);
    },
  };

  const { palette } = useTheme();
  const issueSavingRequestModalConfig: InformativeModalConfigProps = {
    icon: <MuiErrorIcon style={{ height: "48px", width: "48px", color: palette.warning.dark }} />,
    headerText: "There is an issue with saving your request",
    subHeaderElement: (
      <Sanitized __html={typeof submitError?.data === "object" ? submitError?.data?.message : submitError?.message} />
    ),
    primaryButtonText: "Go back",
    primaryButtonAction: () => {
      setShowInformativeModal(false);
    },
    onClose: () => {
      setShowInformativeModal(false);
    },
  };

  const modalConfigs: InformativeModalConfig = {
    CHANNEL_WARN: providerCallModalConfig,
    WITHDRAW_AND_REPLACE: withdrawAndReplaceModalConfig,
    WITHDRAW_AND_REPLACE_AUTH_DECISION_GROUP: withdrawAndReplaceModaWithAuthDecisionlConfig,
    ISSUE_SAVING: issueSavingRequestModalConfig,
  };

  const editTooltipTitle = (): string => {
    return srEditConfig?.disableFormEditsMessage || "";
  };

  return (
    <div>
      <SectionHeaderContainer style={{ paddingBottom: 16, display: "flex", flexDirection: "column" }}>
        {showPatientStayTimeline && authorization?.encounterType === "INPATIENT" && (
          <div>
            <H4 data-public>Patient stay</H4>
            <PatientStayTimeline
              authorization={authorization as AuthorizationResponse}
              serviceRequest={serviceRequest}
            />
            <Divider
              style={{
                margin: "20px 0",
              }}
            />
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
          <H4 data-public>Details</H4>
          {!onlyAllowView && (
            <>
              {viewState === "READ_ONLY" ? (
                <Grid container item xs={8} spacing={4} justifyContent="flex-end" style={{ margin: 0 }}>
                  {(!isClearerDraftWorkflow ||
                    (latestSr ? latestSr.authStatus !== "DRAFT" : serviceRequest.authStatus !== "DRAFT")) && (
                    <Grid item style={{ padding: theme.spacing(0, 1) }}>
                      <Tooltip title={editDisabled ? editTooltipTitle() : ""} placement="top" arrow>
                        <div>
                          <TertiaryButton
                            disabled={editDisabled}
                            startIcon={<PencilIcon />}
                            onClick={handleEditButtonClick}
                            style={{ padding: 0 }}
                            className={srFormSectionStyles.editBtn}
                          >
                            <Body2>Edit</Body2>
                          </TertiaryButton>
                        </div>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <SecondaryButton
                  onClick={() => {
                    setViewState("READ_ONLY");
                    setIsEditView?.(false);
                  }}
                  warning
                >
                  Cancel
                </SecondaryButton>
              )}
            </>
          )}
        </div>
      </SectionHeaderContainer>
      {viewState === "READ_ONLY" ? (
        <ServiceRequestFormReadOnly
          authorization={authorization}
          refreshAuthorization={refreshAuthorization}
          serviceRequest={serviceRequest}
          serviceRequests={serviceRequests}
          hideFacilityState={!clinicalReviewView && !canViewFacilityState}
          clinicalReviewView={clinicalReviewView}
          isFacilityBasedAuth={facilityBasedFeatureEnabled}
          isFaxEdit={isFaxEdit}
        />
      ) : (
        <ServiceRequestForm
          serviceRequest={serviceRequest}
          submitButtonText="Save"
          submitting={submitting || deleting || testUpdateReplaceOrAmendLoading || updateReplaceOrAmendLoading}
          submitDraftToIntake={submitEditsOnDraftSrsToIntake}
          onSave={handleSaveOperation}
          onDelete={onDelete}
          onWithdraw={onWithdraw}
          withdrawnReason={withdrawnReason}
          setWithdrawnReason={setWithdrawnReason}
          withdrawRequestor={withdrawRequestor}
          setWithdrawRequestor={setWithdrawRequestor}
          patient={serviceRequest.patient}
          populateDefaults={populateDefaults}
          isAuthorizationSr={!!latestSr?.authorization}
          onPatientSummary={true}
          refreshAuthorization={refreshAuthorization}
          initialAuthorization={authorization}
          formEditConfiguration={srEditConfig}
        />
      )}
      {canBeWithdrawn(serviceRequest) && (
        <WithdrawModal
          open={withdrawModalOpen}
          onClose={() => {
            setWithdrawModalOpen(false);
            setWithdrawnReason("");
          }}
          onWithdraw={onWithdraw}
          withdrawnReason={withdrawnReason}
          setWithdrawnReason={setWithdrawnReason}
          withdrawRequestor={withdrawRequestor}
          setWithdrawRequestor={setWithdrawRequestor}
          submitting={submitting}
          serviceRequestId={serviceRequest.id}
          serviceRequest={latestSr}
          authDecisionGroupId={
            (serviceRequest?.authDecisionGroup?.id && isIntegrateAuthDecisionGroups) ||
            serviceRequest?.authDecisionGroup?.authNumber
              ? serviceRequest.authDecisionGroup?.id
              : undefined
          }
          withdrawnReasonOptionsList={serviceRequest.reviewOutcomeWithdrawOptions}
        />
      )}
      <ApprovedSrEditRedirectionModal
        {...approvedSrEditModalState}
        handleClose={clearApprovedSrEditModalState}
        loading={updateReplaceOrAmendLoading}
      />
      <InformativeModal {...modalConfigs[informativeModalConfigState]} open={showInformativeModal} />
      <StartContinuationModal
        open={openContinuationModal}
        onClose={() => {
          setOpenContinuationModal(false);
        }}
        primaryButtonActions={onContinuationClick}
        primaryButtonLoading={loadingContinuationFlow}
        tertiaryButtonAction={() => {
          setOpenContinuationModal(false);
        }}
      />
      <StartNewServiceRequestModal
        open={openStartNewRequestModal}
        onClose={() => {
          setOpenNewRequestModal(false);
        }}
        primaryButtonActions={() => {
          const { pathname } = startNewAuthWorkflowPath(serviceRequest?.patient?.id || "");

          if (isFaxEdit) {
            const faxSearchParams = getParametersFaxIntake(undefined, faxAttachment?.id, caseId, queueId);
            navigate({
              pathname,
              search: `${faxSearchParams}&faxStep=SIDE_BY_SIDE_VIEW`,
            });
          } else {
            navigate({
              pathname,
            });
          }
        }}
        tertiaryButtonAction={() => {
          setOpenNewRequestModal(false);
        }}
      />
    </div>
  );

  function setUpdatePayloadToState(sr: ServiceRequestCreatePayload, formContent: ServiceRequestFormContent) {
    const serviceRequestPatchPayload: ServiceRequestUpdatePayload = {
      ...sr,
      authorization: authorization ? { id: authorization?.id } : undefined,
      authCategory: formContent?.authCategory ? formContent.authCategory.enumName : sr.authCategory,
      patientStayDates: (facilityBasedFeatureEnabled && formContent?.patientStayDateRanges
        ? deAggregateStayDates(formContent.patientStayDateRanges)
        : sr.patientStayDates
      )?.map((date) => {
        return { ...date, reviewStatus: date.reviewStatus ?? "PENDING" };
      }),
      patientStatus:
        facilityBasedFeatureEnabled && formContent?.patientStatus ? formContent.patientStatus : sr.patientStatus,
      // Only add the requestor to the payload if defined
      ...(requestor ? { requestor } : {}),
    };

    // this is in ref to COH-4912 - npi being empty assumes the minimum missing info for this field to be null
    if (
      serviceRequest.formConfiguration &&
      serviceRequest.formConfiguration["performingProvider"].fieldSpec === "OPTIONAL" &&
      sr.selectedPerformingProvider?.npi === ""
    ) {
      serviceRequestPatchPayload.selectedPerformingProvider = null;
    }
    serviceRequestPatchPayload.dischargedTo = sr.dischargedTo;
    setUpdatedServiceRequestPayload?.(serviceRequestPatchPayload);

    const updatedServiceRequestResponse = formContentToResponse(
      formContent,
      simplifiedServiceFrequency,
      serviceRequest
    );
    setServiceRequest?.(updatedServiceRequestResponse);
  }
}
