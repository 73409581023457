import { Dispatch, useCallback, useContext } from "react";

import { queueMgmtBaseUrl, SelectedPatientInfo, useFeature } from "@coherehealth/common";
import {
  Patient,
  ReferralRequestResponse,
  ServiceRequestResponse,
  useDeleteReferralRequestAttachment,
  useDeleteServiceRequestAttachment,
} from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { CommonFaxSidebarViewProps, backToMenuView, getServiceRequestsFaxAttachedTo } from "../common";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import CommonFaxFooter from "../CommonFaxFooter";
import { AttachedServiceRequestCard } from "./AttachedServiceRequestCard";
import { AttachedRequestWithAuthorization } from "./AttachedRequestWithAuthorization";
import { Drawer } from "@material-ui/core";
import AuthDetailsFlyout from "./AuthDetailsFlyout";
import { FaxAuthorizationContext } from "util/context/FaxAuthorizationContext";

interface Props extends CommonFaxSidebarViewProps {
  selectedPatient?: Patient;
  setSelectedPatient: Dispatch<Patient | undefined>;
  attachedToServiceRequests: ServiceRequestResponse[];
  setAttachedToServiceRequests: React.Dispatch<React.SetStateAction<ServiceRequestResponse[]>>;
  setAttachedToReferralRequests: React.Dispatch<React.SetStateAction<ReferralRequestResponse[]>>;
}

const useStyles = makeStyles((theme) => ({
  serviceRequestResultsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflowY: "hidden",
    paddingBottom: 72,
  },
  fullWidthDivider: {
    margin: theme.spacing(4, -3, 3),
  },
  fullWidthBottomDivider: {
    margin: theme.spacing(2, 0, 0),
  },
  scrollableResultList: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: "1",
    padding: theme.spacing(2, 1, 3),
  },
  resultListHeader: {
    paddingBottom: theme.spacing(1),
  },
  restartButtonHeader: {
    marginTop: theme.spacing(6),
  },
  restartButton: {
    margin: theme.spacing(2, 0),
  },
  buttonContainer: {
    display: "flex",
    padding: theme.spacing(3, 3),
    margin: theme.spacing(0, -3),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

export default function AttachedServiceRequests({
  selectedPatient,
  setSelectedPatient,
  fileData,
  setSidebarView,
  refreshFileData,
  attachedToServiceRequests,
  setAttachedToServiceRequests,
  setAttachedToReferralRequests,
}: Props) {
  const classes = useStyles();
  const attachedToData = getServiceRequestsFaxAttachedTo(fileData);

  const faxAuthCardRedesign = useFeature("faxAuthCardRedesign");

  const { caseId } = useContext(FaxAttachmentContext);
  const { authContext, handleAuthDetailsSelected, clearAuthContextAttachmentsAndRequests, selectedReferralRequestId } =
    useContext(FaxAuthorizationContext);
  const { flyoutOpen, selectedAuth } = authContext;

  const { mutate: updateServiceCase, loading: serviceCaseLoading } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  function onFinish() {
    if (attachedToServiceRequests.length > 0) {
      updateServiceCase({
        outcome: "FAX_ATTACHED",
        dateCompleted: new Date().toISOString(),
        description: "Fax attached",
        serviceRequests: attachedToServiceRequests.map((attachedToServiceRequest) => {
          return {
            serviceRequestId: attachedToServiceRequest.id,
            cohereId: attachedToServiceRequest.cohereId,
            dateCreated: new Date(attachedToServiceRequest.dateCreated).toISOString(),
            status: attachedToServiceRequest.authStatus,
          };
        }),
      });
    }
  }

  const disabledFinish =
    attachedToServiceRequests.some((sr) => sr.authStatus === "DRAFT") || attachedToServiceRequests.length === 0;

  const afterRemoveAttachmentHandler = useCallback(() => {
    refreshFileData?.();

    if (attachedToServiceRequests.length === 1) {
      setAttachedToServiceRequests([]);
      setAttachedToReferralRequests([]);

      clearAuthContextAttachmentsAndRequests();

      setSidebarView(backToMenuView);
    }
  }, [
    attachedToServiceRequests.length,
    clearAuthContextAttachmentsAndRequests,
    refreshFileData,
    setAttachedToReferralRequests,
    setAttachedToServiceRequests,
    setSidebarView,
  ]);

  const { mutate: removeRequestAttachment, loading: removingServiceAttachment } = useDeleteServiceRequestAttachment({
    id: "",
  });

  const { mutate: removeReferralAttachment, loading: removingReferralAttachment } = useDeleteReferralRequestAttachment({
    id: "",
  });

  const clearAttachments = useCallback(() => {
    setAttachedToServiceRequests([]);
    setAttachedToReferralRequests([]);

    clearAuthContextAttachmentsAndRequests();

    setSelectedPatient(undefined);
    setSidebarView(backToMenuView);
  }, [
    clearAuthContextAttachmentsAndRequests,
    setAttachedToReferralRequests,
    setAttachedToServiceRequests,
    setSelectedPatient,
    setSidebarView,
  ]);

  const handleCancel = useCallback(async () => {
    if (selectedReferralRequestId) {
      // Making sure every call to API has been made
      const rrAttachmentId = attachedToData.find((rrData) => rrData.id === selectedReferralRequestId)?.linkedAttachment;

      if (rrAttachmentId) {
        await removeReferralAttachment(rrAttachmentId, { pathParams: { id: selectedReferralRequestId } }).catch(
          (_error) => {
            enqueueSnackbar("Failed to remove attachments", {
              variant: "error",
            });
          }
        );
      }
    } else {
      // Making sure every call to API has been made
      for (const serviceRequest of attachedToServiceRequests) {
        const srAttachmentId = attachedToData.find((srData) => srData.id === serviceRequest.id)?.linkedAttachment;

        if (srAttachmentId) {
          await removeRequestAttachment(srAttachmentId, { pathParams: { id: serviceRequest.id } }).catch((_error) => {
            enqueueSnackbar("Failed to remove attachments", {
              variant: "error",
            });
          });
        }
      }
    }

    refreshFileData?.();
    clearAttachments();
  }, [
    attachedToData,
    attachedToServiceRequests,
    clearAttachments,
    enqueueSnackbar,
    refreshFileData,
    removeReferralAttachment,
    removeRequestAttachment,
    selectedReferralRequestId,
  ]);

  return (
    <>
      <div className={classes.serviceRequestResultsContainer}>
        <Divider className={classes.fullWidthDivider} />
        {selectedPatient && (
          <>
            <SelectedPatientInfo patient={selectedPatient} />
            <Divider className={classes.fullWidthBottomDivider} />
          </>
        )}
        <div className={classes.scrollableResultList}>
          <Grid container spacing={2}>
            {attachedToServiceRequests.map((attachedToServiceRequest, idx) => {
              const srId = attachedToServiceRequest.id;
              const srAttachmentId = attachedToData.find(
                (srData) => srData.id === attachedToServiceRequest.id
              )?.linkedAttachment;

              if (!srId || !srAttachmentId) {
                return null;
              }

              if (faxAuthCardRedesign) {
                return (
                  <Grid item xs={12} key={srId}>
                    <AttachedRequestWithAuthorization
                      serviceRequest={attachedToServiceRequest}
                      serviceRequestAttachmentId={srAttachmentId}
                      afterRemoveAttachment={afterRemoveAttachmentHandler}
                      setPatient={idx === 0 ? setSelectedPatient : undefined}
                    />
                  </Grid>
                );
              }

              return (
                <Grid item xs={12} key={srId}>
                  <AttachedServiceRequestCard
                    serviceRequest={attachedToServiceRequest}
                    serviceRequestAttachmentId={srAttachmentId}
                    afterRemoveAttachment={afterRemoveAttachmentHandler}
                    selectedPatient={selectedPatient}
                    setPatient={idx === 0 ? setSelectedPatient : undefined}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
        <CommonFaxFooter
          primaryButtonLoading={serviceCaseLoading}
          onPrimaryButtonClick={onFinish}
          disabledFinish={disabledFinish}
          setSidebarView={setSidebarView}
          showBackButton={true}
          onCancelButtonClick={handleCancel}
          cancelButtonLoading={removingServiceAttachment || removingReferralAttachment}
        />
      </div>
      {selectedAuth && (
        <>
          <Drawer
            open={flyoutOpen}
            onClose={() => handleAuthDetailsSelected?.(undefined)}
            anchor={"right"}
            hideBackdrop={true}
            variant="persistent"
          >
            <AuthDetailsFlyout
              key={selectedAuth.id}
              authorization={selectedAuth}
              setFlyoutOpen={handleAuthDetailsSelected}
            />
          </Drawer>
        </>
      )}
    </>
  );
}
