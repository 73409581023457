import type { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";

import { Box } from "@material-ui/core";
import { makeStyles } from "tss-react/mui";

import useFetchPatientStayDates from "../hooks/useFetchPatientStayDates";

import PatientStayTimelineHeader from "./PatientStayTimelineHeader";
import { PatientStayTimelineItem } from "./PatientStayTimelineItem";
import { usePatientStayTimelineItems } from "../hooks/usePatientStayTimelineItems";

export interface PatientStayTimelineProps {
  authorization: AuthorizationResponse;
  serviceRequest?: ServiceRequestResponse;
}

export function PatientStayTimeline({ authorization, serviceRequest }: PatientStayTimelineProps) {
  const { classes } = useStyles();
  const { groupedStayDatesForTimeline } = useFetchPatientStayDates(authorization?.id);
  const timelineItems = usePatientStayTimelineItems(authorization, groupedStayDatesForTimeline);

  return (
    <Box display="flex" flexDirection="column" padding="0">
      <PatientStayTimelineHeader authorization={authorization} serviceRequest={serviceRequest} />
      <Box className={classes.timelineContainer}>
        <Box className={classes.timelineScroll}>
          {timelineItems.map((item, index) => (
            <PatientStayTimelineItem key={index} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles()({
  timelineContainer: {
    position: "relative",
    width: "100%",
    overflowX: "visible",
    overflowY: "hidden",
  },
  timelineScroll: {
    display: "flex",
    position: "relative",
    padding: "24px 0px 0px 0px",
    overflowX: "auto",
    overflowY: "hidden",
    scrollBehavior: "smooth",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
});
