import React, { Dispatch } from "react";
import InformativeModal from "./InformativeModal";
import ClipboardErrorIcon from "../images/ClipboardErrorIcon";
interface Props {
  open: boolean;
  setOpen: Dispatch<boolean>;
  unassign: () => void;
  from: string;
  loading?: boolean;
  showOnHoldWarning?: boolean;
}

export default function UnassignModal({ open, setOpen, unassign, from, loading, showOnHoldWarning }: Props) {
  return (
    <InformativeModal
      headerText={"Are you sure you want to unassign this case from " + from + "?"}
      subHeaderText={showOnHoldWarning ? "Proceeding will remove the hold" : ""}
      icon={<ClipboardErrorIcon />}
      primaryButtonText="Proceed to unassign"
      primaryButtonAction={() => {
        unassign();
      }}
      primaryButtonLoading={loading}
      primaryButtonDisabled={loading}
      tertiaryButtonText={"Go back"}
      tertiaryButtonAction={() => setOpen(false)}
      showDivider
      open={open}
      onClose={() => setOpen(false)}
    />
  );
}
