import type { TimelineItem } from "../hooks/usePatientStayTimelineItems";

import { Box } from "@material-ui/core";
import { makeStyles } from "tss-react/mui";

import { TimelineContent } from "./PatientStayTimelineItemContent";

export interface StayGroup {
  record: {
    days: string;
    status: string;
    dates: string;
    hideStatus?: boolean;
  };
}

interface TimelineItemProps {
  item: TimelineItem;
}

export const PatientStayTimelineItem = ({ item }: TimelineItemProps) => {
  const { classes } = useStyles();

  return (
    <Box
      className={classes.timelineItem}
      style={{
        minWidth: item.type === "line" ? (item.dischargedText ? "360px" : "180px") : "auto",
        width: item.style.width,
        overflow: item.style.overflow,
      }}
    >
      <div
        className={item.type === "line" ? classes.lineSegment : classes.dot}
        style={{ backgroundColor: item.style.backgroundColor, borderColor: item.style.borderColor }}
      />
      <TimelineContent item={item} />
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  timelineItem: {
    position: "relative",
  },
  dot: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    border: "2px solid gray",
    position: "absolute",
    top: "2px",
    left: "0px",
    transform: "translateY(-50%)",
    zIndex: 4,
  },
  lineSegment: {
    width: "100%",
    height: "4px",
    zIndex: 3,
    transform: "translateX(4px)",
  },
}));
