import { useState, ReactNode, useContext, useCallback } from "react";
import { authorizeBaseUrl, InlineButton, PrimaryButton, SecondaryButton } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SubdirectoryArrowRight } from "@material-ui/icons";
import PencilIcon from "@material-ui/icons/Create";
import { FaxAuthorizationContext } from "util/context/FaxAuthorizationContext";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";

export const cardButtonsStyles = makeStyles((theme) => ({
  actionButton: {
    paddingLeft: 0,
    paddingRight: "16px",
  },
  viewRequestButton: {
    minWidth: "max-content",
  },
  disabledAttachButton: {
    paddingLeft: theme.spacing(0, 2),
  },
  removeButton: {
    color: theme.palette.error.main,
    "&:hover,&:focus": {
      color: theme.palette.error.main,
    },
    "&:active": {
      color: theme.palette.error.main,
    },
  },
  hover: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
      "background-blend-mode": "multiply",
    },
  },
  continuationButton: {
    paddingLeft: theme.spacing(1),
    minWidth: "max-content",
  },
  editRequestButton: {
    paddingLeft: theme.spacing(1),
    minWidth: "max-content",
  },
  showRemoveButton: {
    color: `${theme.palette.error.main}`,
  },
}));

interface MainActionButtonProps {
  serviceCaseId: string | undefined;
  id: string | undefined;
  showRemoveButton: boolean | undefined;
  disableAttachmentCta?: boolean;
  onRemove: ((serviceRequestId: string) => void) | undefined;
  attachingFile: boolean | undefined;
  latestServiceRequest: ServiceRequestResponse | null;
}

export const MainActionButton = ({
  serviceCaseId,
  id,
  showRemoveButton,
  onRemove,
  attachingFile,
  latestServiceRequest,
  disableAttachmentCta,
}: MainActionButtonProps) => {
  const classes = cardButtonsStyles();
  const { handleServiceRequestSelection, isCurrentlyServiceRequestSelected, isSRAttached } =
    useContext(FaxAuthorizationContext);
  const handleClick = useCallback(() => {
    if (latestServiceRequest) {
      handleServiceRequestSelection?.(latestServiceRequest);
    }
  }, [latestServiceRequest, handleServiceRequestSelection]);
  if (serviceCaseId) {
    return (
      <SecondaryButton
        className={classes.viewRequestButton}
        disabled={disableAttachmentCta}
        onClick={() =>
          window.location.assign(
            `${authorizeBaseUrl()}/view_only_review?caseId=${serviceCaseId || ""}&serviceRequestId=${id}`
          )
        }
        data-public
      >
        View request
      </SecondaryButton>
    );
  }

  if (showRemoveButton && id) {
    return (
      <InlineButton
        startIcon={<DeleteIcon />}
        disabled={disableAttachmentCta}
        className={`${classes.actionButton} ${classes.showRemoveButton}`}
        onClick={() => onRemove?.(id)}
        data-public
      >
        Remove
      </InlineButton>
    );
  }

  if (latestServiceRequest && isSRAttached?.(latestServiceRequest.id)) {
    return (
      <PrimaryButton className={classes.disabledAttachButton} disabled data-public>
        Attached
      </PrimaryButton>
    );
  }

  if (latestServiceRequest && isCurrentlyServiceRequestSelected?.(latestServiceRequest.id) && id) {
    return (
      <InlineButton
        startIcon={<RemoveCircleOutlineIcon />}
        disabled={disableAttachmentCta}
        className={classes.actionButton}
        classes={{ textPrimary: classes.removeButton }}
        onClick={handleClick}
        loading={attachingFile}
        data-public
      >
        Remove from request
      </InlineButton>
    );
  }

  if (id) {
    return (
      <InlineButton
        startIcon={<AddCircleIcon />}
        className={classes.actionButton}
        disabled={disableAttachmentCta}
        onClick={handleClick}
        data-public
      >
        Attach to auth
      </InlineButton>
    );
  }

  return <></>;
};

export function getAuthorizationCardActionButtons(
  showCreateContinuation?: boolean,
  onContinuationClick?: () => void,
  loadingContinuation?: boolean,
  showEditRequest?: boolean,
  onEditClick?: () => void
): ReactNode {
  return (
    <>
      {showCreateContinuation && (
        <StartContinuationButton onClick={onContinuationClick} loading={loadingContinuation} />
      )}
      {showEditRequest && <EditRequestButton onClick={onEditClick} />}
    </>
  );
}

interface Props {
  onClick?: () => void;
  loading?: boolean;
}
export const StartContinuationButton = ({ onClick, loading }: Props) => {
  const classes = cardButtonsStyles();
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    setIsClicked(true);
    onClick?.();
  };

  return (
    <InlineButton
      data-tracking-id="create-continuation"
      data-testid="create-continuation-button"
      startIcon={loading ? <CircularProgress size={20} /> : <SubdirectoryArrowRight />}
      onClick={handleClick}
      disabled={isClicked || loading}
      className={classes.continuationButton}
      data-public
    >
      Start continuation
    </InlineButton>
  );
};

export const EditRequestButton = ({ onClick, loading }: Props) => {
  const classes = cardButtonsStyles();
  return (
    <InlineButton
      data-tracking-id="edit-request"
      data-testid="edit-request-button"
      startIcon={loading ? <CircularProgress size={20} /> : <PencilIcon />}
      onClick={onClick}
      disabled={loading}
      className={classes.editRequestButton}
      data-public
    >
      Edit auth
    </InlineButton>
  );
};
