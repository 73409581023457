import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { HighlightStateContext, RadioGroup, RichTextEditor, useConfiguration, useFeature } from "@coherehealth/common";
import {
  NurseReview,
  CopyPasteInfo,
  ServiceRequestResponse,
  OutreachAttempt,
  NudgeInfo,
  AuthorizationResponse,
  NurseReviewCoverageLevelDetails,
} from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core/styles";
import { ReviewControlBoxOuterContainer, ReviewControlBoxInnerContainer } from "../MDReview/MDReviewEdit";
import { useMediaQuery } from "@material-ui/core";
import { NurseReviewUpdate, useReviewsDispatch } from "components/ClinicalReview/reviewUtils/useReviews";
import {
  renderAutomatedReviewNote,
  RIGHT_HAND_PANEL_SIZE,
  usePasteTracker,
} from "components/ClinicalReview/reviewUtils/utils";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { PatientStaysErrorState } from "components/ServiceRequest/PatientStay/usePatientStayDateOnCRR";
import PatientStays from "components/ServiceRequest/PatientStay/PatientStays";
import DischargedToDetails, { DischargeDetailsType } from "../DischargedToDetails";
import { OutreachFormFields } from "../useOutreachAttempt";
import ReadmissionSelection from "components/ServiceRequest/ReviewSection/Readmission/ReadmissionSelection";
import { isUnplannedInpatientRequest, patientNotYetAdmittedForInpatient } from "util/serviceRequest";
import { getDateForDischargeBasedOnPreviousRanges } from "util/authorization";
import { PatientStayManger } from "components/ClinicalReview/Review/hooks/usePatientStayCardControl";
import { ReviewControlPanel } from "../ReviewControlPanel/ReviewControlPanel";
import { ReviewOutreachForm } from "components/ClinicalReview/reviewUtils/ReviewOutreachForm";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { useLocation } from "react-router";
import { CREATE_CPP_REVIEW_PARAM } from "util/queryParams";
import NurseReviewMultiCoverageSubmissionOutcomes from "./NurseReviewMultiCoverageSubmissionOutcomes";
import { ExtendedRnReviewOutcome, ReviewOutcomeOption } from "../util/QueueManagementReviewUtil";

interface Props {
  serviceRequest: ServiceRequestResponse;
  nurseReview: NurseReview;
  setNurseReview: NurseReviewUpdate;
  openSubmissionModal: () => void;
  onDiscard: () => void;
  discarding: boolean;
  willAutoApprove?: boolean;
  copiedAttachmentText?: CopyPasteInfo;
  setPastedAttachmentTexts?: Dispatch<React.SetStateAction<CopyPasteInfo[] | undefined>>;
  outreachAttempt?: OutreachAttempt;
  setOutreachAttempt?: Dispatch<SetStateAction<OutreachAttempt>>;
  isSubmissionDisabled: boolean;
  patientStayDateRanges?: PatientStayDateRange[];
  setPatientStayDateRanges?: Dispatch<SetStateAction<PatientStayDateRange[]>>;
  patientStaysErrorStates?: PatientStaysErrorState[];
  dischargeDetails?: DischargeDetailsType;
  setDischargeDetails?: Dispatch<SetStateAction<DischargeDetailsType>>;
  isDischarged?: boolean;
  setIsDischarged?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  facilityBasedFeatureEnabled?: boolean;
  outreachFormConfig?: OutreachFormFields;
  outreachFormErrors?: OutreachFormFields;
  setOutreachFormErrors?: React.Dispatch<React.SetStateAction<OutreachFormFields>>;
  validateOutreachForm?: () => boolean;
  hasOutreachAttempt?: boolean;
  setHasOutreachAttempt?: React.Dispatch<React.SetStateAction<boolean>>;
  updating: boolean;
  saveDraft?: () => void;
  isReadmission: boolean;
  setIsReadmission: Dispatch<SetStateAction<boolean>>;
  anchorAuthNumber: string;
  setAnchorAuthNumber: Dispatch<SetStateAction<string>>;
  hasErrorWithAnchorAuth: boolean;
  setHasErrorWithAnchorAuth: Dispatch<SetStateAction<boolean>>;
  isReadmissionFeatureEnabled: boolean;
  authorization?: AuthorizationResponse;
  isStayDatesValid?: () => boolean;
  isDischargeDetailsValid: boolean;
  patientStayManager?: PatientStayManger;
  validateOONReview?: () => boolean;
  shouldSubmitEmbededOonReview?: boolean;
  discardAndExitDisabled: boolean;
}

export default function NurseReviewEdit({
  serviceRequest,
  nurseReview,
  setNurseReview,
  openSubmissionModal,
  onDiscard,
  discarding,
  willAutoApprove,
  copiedAttachmentText,
  setPastedAttachmentTexts,
  outreachAttempt,
  setOutreachAttempt,
  isSubmissionDisabled,
  patientStayDateRanges,
  setPatientStayDateRanges,
  patientStaysErrorStates,
  dischargeDetails,
  setDischargeDetails,
  isDischarged,
  setIsDischarged,
  facilityBasedFeatureEnabled,
  outreachFormConfig,
  outreachFormErrors,
  setOutreachFormErrors,
  validateOutreachForm,
  hasOutreachAttempt,
  setHasOutreachAttempt,
  updating,
  saveDraft,
  isReadmission,
  setIsReadmission,
  anchorAuthNumber,
  setAnchorAuthNumber,
  hasErrorWithAnchorAuth,
  setHasErrorWithAnchorAuth,
  isReadmissionFeatureEnabled,
  authorization,
  isStayDatesValid,
  isDischargeDetailsValid,
  patientStayManager,
  validateOONReview,
  shouldSubmitEmbededOonReview,
  discardAndExitDisabled,
}: Props) {
  const theme = useTheme();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const isCppCase = search.has(CREATE_CPP_REVIEW_PARAM);
  const caseId = search.get("caseId") || "";
  const isUnplannedInpatient = isUnplannedInpatientRequest(serviceRequest);
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const multiCoverageReviews = useFeature("multiCoverageReviews");
  const automatedReviewNoteEnabled = useFeature("automatedReviewNote");

  const { isMultiCoverageReviewRequired, allowedReviewOutcomes } = useContext(ClinicalReviewContext);

  const multiCoverageContent = multiCoverageReviews && isMultiCoverageReviewRequired;
  const setValue = (fieldName: keyof NurseReview) => (val: string) => {
    setNurseReview({ [fieldName]: val });
  };
  const reviewsDispatch = useReviewsDispatch();

  const setNurseReviewNudges = (selectedNudges: NudgeInfo[]) => {
    setNurseReview({
      nurseReviewNudges: selectedNudges,
    });
  };
  const setNurseReviewNudgeValue = (fieldName: keyof NudgeInfo, idx: number, opt: string | boolean) => {
    reviewsDispatch?.({
      type: "SET_NURSE_NUDGE",
      reviewId: nurseReview.id,
      payload: { fieldName, idx, opt },
    });
  };

  const [attemptedSubmitted, setAttemptedSubmitted] = useState(false);
  const [displayCaseOutcomes, setDisplayCaseOutcomes] = useState<ExtendedRnReviewOutcome[]>(
    nurseReview.coverageLevelDetails?.map((detail) => detail.reviewOutcome || undefined) || []
  );

  const isMissingSubMenuSelections = useNurseReviewMissingSubMenuSelection(
    allowedReviewOutcomes,
    nurseReview.coverageLevelDetails
  );
  const hasSubMenuError = attemptedSubmitted && isMissingSubMenuSelections;
  const hasValidMultiCoverageResponses = multiCoverageContent
    ? nurseReview.coverageLevelDetails?.every((detail) => Boolean(detail.reviewOutcome)) && !hasSubMenuError
    : true;
  const meetsAutoApprovalCriteria = nurseReview.criteriaDecision === "AUTO_APPROVED";
  const checkHasValidDecisionReasoning = () => {
    if (meetsAutoApprovalCriteria) {
      return true;
    }
    const isNonEmptyDecisionReasoning = Boolean(stripHTMl(nurseReview.decisionReasoning || ""));
    return isNonEmptyDecisionReasoning;
  };
  const hasValidDecisionReasoning = checkHasValidDecisionReasoning();

  const validateFields = hasValidDecisionReasoning && hasValidMultiCoverageResponses;

  useEffect(() => {
    if (willAutoApprove) {
      setNurseReview({ criteriaDecision: "AUTO_APPROVED" });
    }
  }, [setNurseReview, willAutoApprove]);

  usePasteTracker(copiedAttachmentText, setPastedAttachmentTexts);
  const isPatientNotYetAdmitted = patientNotYetAdmittedForInpatient(serviceRequest);
  const isPatientAdmitted = !isPatientNotYetAdmitted;

  const [stayDatesValid, setStayDatesValid] = useState<boolean | undefined>(false);
  useEffect(() => {
    if (!stayDatesValid || setPatientStayDateRanges) {
      const validate = isStayDatesValid?.();
      setStayDatesValid(validate);
    }
  }, [isStayDatesValid, setPatientStayDateRanges, stayDatesValid]);

  const hasErrorsWithPatientStayDates = !(stayDatesValid && isDischargeDetailsValid);

  const isInpatientRequest = serviceRequest.encounterType === "INPATIENT";
  const { formContent, renderPatientStayCard, reviewStayDateEditsFlag, validatePatientStayCard } =
    patientStayManager || {};

  const loadingDiscardOrSave = discarding || updating;
  const disableFinishButton = () => {
    if (isSubmissionDisabled || loadingDiscardOrSave || (attemptedSubmitted && hasErrorsWithPatientStayDates)) {
      return true;
    }
    if (attemptedSubmitted && validatePatientStayCard && !validatePatientStayCard()) {
      return true;
    }
    return false;
  };

  const isPreService = serviceRequest.requestTiming === "PRE_SERVICE";
  const { setRightColumnTab } = useContext(HighlightStateContext);

  const onFinishReview = () => {
    setAttemptedSubmitted(true);
    const isValidOutreach = !hasOutreachAttempt || (hasOutreachAttempt && validateOutreachForm?.());
    const isPatientStayCardValid = validatePatientStayCard ? validatePatientStayCard() : true;
    const isValidReadmission = (isReadmission && anchorAuthNumber !== "") || !isReadmission;
    //preservice doens't have patient stay dates on sr
    let isDatesValid = true;
    if (facilityBasedFeatureEnabled && !isPreService) {
      isDatesValid = !!isStayDatesValid?.();
    }
    let isOonReviewValid = !shouldSubmitEmbededOonReview || validateOONReview?.();

    if (!isOonReviewValid) {
      setRightColumnTab?.("OON_REVIEW");
      return;
    }

    if (
      isPatientStayCardValid &&
      validateFields &&
      !hasErrorsWithPatientStayDates &&
      isValidOutreach &&
      isValidReadmission &&
      hasErrorWithAnchorAuth === false &&
      isDatesValid
    ) {
      openSubmissionModal();
    }
  };

  const facilityBasedConfig = useConfiguration(
    "facilityBasedRequestConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );

  const decisionReasoningLabel = useRef(
    nurseReview?.decisionReasoning
      ? "Review note • Pre-filled using text from attachments and previous reviews"
      : `Reviewer notes`
  );

  const automatedNoteOutput = useMemo(
    () =>
      automatedReviewNoteEnabled
        ? renderAutomatedReviewNote(nurseReview.automatedReviewNote || [], serviceRequest.attachments)
        : "",
    [automatedReviewNoteEnabled, nurseReview.automatedReviewNote, serviceRequest.attachments]
  );

  const shouldInitializeAutomatedReviewNote = !Boolean(nurseReview.decisionReasoning) && Boolean(automatedNoteOutput);

  useEffect(() => {
    if (shouldInitializeAutomatedReviewNote) {
      setNurseReview({ decisionReasoning: automatedNoteOutput });
    }
  }, [shouldInitializeAutomatedReviewNote, automatedNoteOutput, setNurseReview]);

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "48px" }}
        component="form"
        data-testid="nurse-review-edit-container"
      >
        <>
          <Grid item xs={12}>
            <RichTextEditor
              readonly={false}
              testid="clinical-review-text-editor-decision-reasoning"
              label={decisionReasoningLabel.current}
              error={Boolean(attemptedSubmitted && !hasValidDecisionReasoning)}
              helperText={Boolean(attemptedSubmitted && !hasValidDecisionReasoning) ? "Required" : ""}
              htmlValue={nurseReview.decisionReasoning || ""}
              setHtmlValue={setValue("decisionReasoning")}
            />
          </Grid>
        </>
        {facilityBasedFeatureEnabled && isInpatientRequest && reviewStayDateEditsFlag && (
          <Grid item xs={12} style={{ marginBottom: theme.spacing(2), padding: theme.spacing(1) }}>
            {renderPatientStayCard}
          </Grid>
        )}
        {patientStayDateRanges && setPatientStayDateRanges && facilityBasedFeatureEnabled && isInpatientRequest && (
          <Grid item xs={12} style={{ marginBottom: theme.spacing(2), padding: theme.spacing(0) }}>
            <PatientStays
              version={"RequestedStay"}
              minimumFromDate={formContent?.startDate}
              admissionDate={formContent?.admissionDate || null}
              healthPlanName={serviceRequest.healthPlanName || ""}
              patientStayDateRanges={patientStayDateRanges}
              setPatientStayDateRanges={setPatientStayDateRanges}
              patientStaysErrorStates={patientStaysErrorStates}
              isDischarged={isDischarged}
              attemptedSubmit={attemptedSubmitted}
              authorization={authorization}
              patientStatus={formContent?.patientStatus}
              isReview={true}
              includePatientStayDatesOnPlannedAdmission={facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission}
            />
          </Grid>
        )}
        {facilityBasedFeatureEnabled && isPatientAdmitted && !reviewStayDateEditsFlag && (
          <>
            <Grid item xs={12}>
              <RadioGroup
                row
                style={{ padding: theme.spacing(2) }}
                nowrap
                label="Has the patient been discharged?"
                options={[
                  { id: "false", label: "No" },
                  { id: "true", label: "Yes" },
                ]}
                value={`${isDischarged}`}
                onChange={(opt) => {
                  setIsDischarged?.(opt === "true");

                  // Auto-populate discharge date
                  const defaultDischargeDate = getDateForDischargeBasedOnPreviousRanges({
                    decisionedStayDates: authorization?.patientStayDates,
                    currentStayDateRanges: patientStayDateRanges,
                  });
                  if (defaultDischargeDate) {
                    setDischargeDetails?.((prev) => ({
                      ...prev,
                      actualDischargeDate: defaultDischargeDate,
                    }));
                  }
                }}
              />
            </Grid>

            {isDischarged && dischargeDetails && setDischargeDetails && (
              <DischargedToDetails
                dischargeDetails={dischargeDetails}
                setDischargeDetails={setDischargeDetails}
                attemptedSubmit={attemptedSubmitted}
              />
            )}
          </>
        )}

        <ReviewOutreachForm
          showOutreachToggle
          serviceRequest={serviceRequest}
          hasOutreachAttempt={hasOutreachAttempt}
          setHasOutreachAttempt={setHasOutreachAttempt}
          outreachAttempt={outreachAttempt}
          setOutreachAttempt={setOutreachAttempt}
          outreachFormConfig={outreachFormConfig}
          outreachFormErrors={outreachFormErrors}
          setOutreachFormErrors={setOutreachFormErrors}
          setNurseReviewNudges={setNurseReviewNudges}
          setNurseReviewNudgeValue={setNurseReviewNudgeValue}
          setReview={setNurseReview}
        />

        {isReadmissionFeatureEnabled && serviceRequest.encounterType === "INPATIENT" && facilityBasedFeatureEnabled && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <ReadmissionSelection
              isReadmission={isReadmission}
              setIsReadmission={setIsReadmission}
              anchorAuthNumber={anchorAuthNumber}
              onAnchorAuthNumberChange={setAnchorAuthNumber}
              hasErrorWithAnchorAuth={hasErrorWithAnchorAuth}
              setHasErrorWithAnchorAuth={setHasErrorWithAnchorAuth}
              attemptedSubmitted={attemptedSubmitted}
            />
          </>
        )}
        {multiCoverageContent && (
          <NurseReviewMultiCoverageSubmissionOutcomes
            serviceRequest={serviceRequest}
            nurseReview={nurseReview}
            allowedReviewOutcomes={allowedReviewOutcomes}
            setNurseReview={setNurseReview}
            caseId={caseId}
            isCppCase={isCppCase}
            isUnplannedInpatient={isUnplannedInpatient}
            displayCaseOutcomes={displayCaseOutcomes}
            setDisplayCaseOutcomes={setDisplayCaseOutcomes}
            attemptedSubmit={attemptedSubmitted}
          />
        )}
      </Grid>
      <ReviewControlBoxOuterContainer matchesRight={matchesRight}>
        <ReviewControlBoxInnerContainer>
          <ReviewControlPanel
            reviewType={nurseReview.reviewType}
            discarding={discarding}
            discardAndExitDisabled={loadingDiscardOrSave || discardAndExitDisabled}
            handleDiscardAndExit={onDiscard}
            handleSaveAndExit={saveDraft}
            saveAndExitLoading={updating}
            saveAndExitDisabled={loadingDiscardOrSave}
            handleFinishReview={onFinishReview}
            finishButtonDisabled={disableFinishButton()}
          />
        </ReviewControlBoxInnerContainer>
      </ReviewControlBoxOuterContainer>
    </>
  );
}

export const NudgeTypes: Record<string, string> = {
  UNITS: "Units",
  FREQUENCY: "Frequency",
  SITE_OF_SERVICE: "Site of Service",
  DURATION: "Duration",
  SERVICES_ON_REQUEST: "Services on Request",
  TIMING: "Sequence / Timing",
  ADD_POST_ACUTE: "Adding Post Acute Services",
  ADD_PREHAB: "Adding Prehab Services",
};

export const NudgeTypeOptions = [
  { id: "UNITS", label: "Units" } as const,
  { id: "FREQUENCY", label: "Frequency" } as const,
  { id: "SITE_OF_SERVICE", label: "Site of Service" } as const,
  { id: "DURATION", label: "Duration" } as const,
  { id: "SERVICES_ON_REQUEST", label: "Services on Request" } as const,
  { id: "TIMING", label: "Sequence / Timing" } as const,
  { id: "ADD_POST_ACUTE", label: "Adding Post Acute Services" } as const,
  { id: "ADD_PREHAB", label: "Adding Prehab Services" } as const,
];
type NudgeType = typeof NudgeTypeOptions extends readonly { readonly id: infer T; readonly label: string }[]
  ? T
  : never;
export interface NudgeTypeOption {
  id: NudgeType;
  label: string;
}

export function nudgeTypeChangeQuestionText(nudgeType: string | undefined): string {
  if (nudgeType && ["TIMING", "ADD_POST_ACUTE", "ADD_PREHAB"].includes(nudgeType)) {
    return "Did the nudge result in a new auth request?";
  }
  return "Did the nudge result in a changed auth request?";
}

export function useNurseReviewMissingSubMenuSelection(
  allowedReviewOutcomes: ReviewOutcomeOption[] | undefined,
  coverageLevelDetails?: NurseReviewCoverageLevelDetails[] | undefined
) {
  const outcomesWithSubMenus = allowedReviewOutcomes
    ?.filter((outcome) => Boolean(outcome.submenu))
    ?.map((outcome) => outcome.id);
  const detailHasSubMenuErrors = coverageLevelDetails?.find(
    (detail) => outcomesWithSubMenus?.includes(detail.reviewOutcome) && !detail.serviceStatusCode
  );
  return Boolean(detailHasSubMenuErrors);
}

function stripHTMl(htmlString: string) {
  const elemStr = document.createElement("DIV");
  elemStr.innerHTML = htmlString;
  return elemStr.textContent || elemStr.innerText;
}
