import { useFeature } from "@coherehealth/common";
import {
  DenialsInfo,
  Guideline,
  MdReview,
  NurseReview,
  PeerToPeerReview,
  ServiceRequestResponse,
  useGetDenialsTemplatesForReview,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

interface UseDenialsTemplatesParams {
  started: boolean;
  review: NurseReview | MdReview | PeerToPeerReview | null;
  doctorOrNurseReview: NurseReview | MdReview | PeerToPeerReview | null;
  serviceRequest: ServiceRequestResponse | null;
  setStarted: (started: boolean) => void;
  setSelectTemplate: (select: boolean) => void;
  setNewDenialsTemplate: (denials: DenialsInfo | null | undefined) => void;
}

const getAuthStatusFromReviewOutcome = (reviewOutcome: string) => {
  if (reviewOutcome === "RECOMMENDED_DENIAL" || reviewOutcome === "ADMINISTRATIVE_DENIAL") {
    return "RECOMMENDED_DENIAL";
  } else if (reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL" || reviewOutcome === "ADMINISTRATIVE_PARTIAL_APPROVAL") {
    return "RECOMMENDED_PARTIAL_APPROVAL";
  } else {
    return "";
  }
};

export const useDenialsTemplates = ({
  started,
  review,
  doctorOrNurseReview,
  serviceRequest,
  setStarted,
  setSelectTemplate,
  setNewDenialsTemplate,
}: UseDenialsTemplatesParams) => {
  const { enqueueSnackbar } = useSnackbar();

  const showDenialTemplatesRelatedToHealthPlanFeatureFlag = useFeature("showDenialTemplatesRelatedToHealthPlan");
  const enableDenialTemplateGuidelineLobCheck = useFeature("enableDenialTemplateGuidelineLobCheck");

  const {
    data: denialsTemplates,
    loading: denialsLoading,
    refetch: denialsRefetch,
    error: denialsError,
  } = useGetDenialsTemplatesForReview({
    lazy: true,
  });

  useEffect(() => {
    if (denialsError) {
      enqueueSnackbar(`Error Fetching Denial Templates: ${denialsError.message}`, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [denialsError, enqueueSnackbar]);

  useEffect(() => {
    if (
      !started &&
      (review?.reviewOutcome === "RECOMMENDED_DENIAL" ||
        review?.reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL" ||
        review?.reviewOutcome === "ADMINISTRATIVE_DENIAL" ||
        review?.reviewOutcome === "ADMINISTRATIVE_PARTIAL_APPROVAL")
    ) {
      const filteredGuidelines = review?.usedGuidelines?.map(
        (guideline: Guideline) => guideline.originalVersionId || ""
      );
      const shouldIncludeLobAndStateParams =
        showDenialTemplatesRelatedToHealthPlanFeatureFlag &&
        (enableDenialTemplateGuidelineLobCheck || !(filteredGuidelines && filteredGuidelines?.length > 0));
      // further efforts should be made to standardize healthPlanName in Mongo
      // and or use the healthPlan Enum as a source of truth
      denialsRefetch({
        queryParams: {
          authStatus: getAuthStatusFromReviewOutcome(review?.reviewOutcome),
          usedGuidelineIds: filteredGuidelines || [],
          healthPlan: serviceRequest?.healthPlanName?.toUpperCase()?.replace(/\s+/g, "_"),
          lineOfBusiness: shouldIncludeLobAndStateParams ? serviceRequest?.lobType : "",
          state: shouldIncludeLobAndStateParams ? serviceRequest?.patient?.address?.state : "",
        },
      }).then(async (templates) => {
        if (!doctorOrNurseReview?.denialsTemplate) {
          if (templates?.length === 1) {
            setNewDenialsTemplate({
              denialsTemplateId: templates[0]?.id || "",
              formattedDenialsLanguage: templates[0]?.denialLanguage || "",
              templateTitle: templates[0]?.templateTitle || "",
            });
          } else if (!templates || templates.length === 0) {
            setStarted(true);
            setSelectTemplate(false);
          }
        }
      });
    }
  }, [
    started,
    doctorOrNurseReview?.denialsTemplate,
    review?.reviewOutcome,
    review?.usedGuidelines,
    serviceRequest?.healthPlanName,
    serviceRequest?.lobType,
    serviceRequest?.patient?.address?.state,
    enableDenialTemplateGuidelineLobCheck,
    showDenialTemplatesRelatedToHealthPlanFeatureFlag,
    denialsRefetch,
    setNewDenialsTemplate,
    setSelectTemplate,
    setStarted,
  ]);

  return {
    denialsTemplates,
    denialsLoading,
  };
};
