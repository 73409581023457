import { InformativeModal } from "@coherehealth/common";
import { Typography } from "@material-ui/core";
import { Dispatch } from "react";
interface Props {
  open: boolean;
  setOpen: Dispatch<boolean>;
  reTrigger: () => void;
  loading?: boolean;
}

export default function RetriggerModal({ open, setOpen, reTrigger, loading }: Props) {
  return (
    <InformativeModal
      headerText="Re-trigger delivery"
      subHeaderText={
        <Typography>
          Are you sure you'd like to re-trigger this notification for delivery? This will stage the notification to
          re-send to its recipient via the assigned medium.
        </Typography>
      }
      primaryButtonText="Send"
      primaryButtonAction={reTrigger}
      primaryButtonLoading={loading}
      primaryButtonDisabled={loading}
      tertiaryButtonText="Cancel"
      tertiaryButtonAction={() => setOpen(false)}
      showDivider={false}
      open={open}
      onClose={() => setOpen(false)}
    />
  );
}
