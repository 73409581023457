import React, { Dispatch, SetStateAction, MutableRefObject } from "react";
import { Body1 } from "@coherehealth/common";
import { PdfViewer, PdfViewerRenderError, NotificationViewerConfig } from "./Viewers";
import ImageViewer from "./Viewers/NotificationImageViewer";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CenteredTextContainer } from "./NotificationViewerShell";
import { NotificationViewerItem } from "./util/notificationUtil";
import { determineFileType } from "./util/fileManagementUtil";
import { SupportedFileType } from "components/DocumentViewer/DocumentViewer";

/*
 * Based on the file type, this component selects between various viewers:
 * PDF, JPG, loading spinner, or error state.
 */
interface NotificationViewerSelectorProps {
  notification: NotificationViewerItem;
  notifications: NotificationViewerItem[];
  setNotifications: React.Dispatch<React.SetStateAction<NotificationViewerItem[]>>;
  handleNotificationClick: (index: number, landingPage?: number) => void;
  notificationIndexOpen: number;
  currentPage: MutableRefObject<number>;
  zoomLevel: MutableRefObject<number>;
  currentRotate: MutableRefObject<number>;
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  downloadError?: Error;
  viewerConfig?: NotificationViewerConfig;
  paperlessOptedIn?: boolean;
}

export default function NotificationViewerSelector({
  notification,
  notifications,
  setNotifications,
  handleNotificationClick,
  notificationIndexOpen,
  currentPage,
  zoomLevel,
  currentRotate,
  openSidePanel,
  setOpenSidePanel,
  downloadError,
  viewerConfig,
  paperlessOptedIn,
}: NotificationViewerSelectorProps) {
  const fileType = determineFileType(notification);

  let documentDisplay;
  if (!notification.fileUrl) {
    // File is still downloading
    if (!downloadError) {
      documentDisplay = (
        <CenteredTextContainer>
          <CircularProgress size={144} />
          {viewerConfig?.closeDialogFunction && (
            <div style={{ position: "absolute", top: "8px", right: "8px" }}>
              <IconButton onClick={viewerConfig.closeDialogFunction} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </CenteredTextContainer>
      );
    } else {
      // Error state: Either failed to download, or there is no file available
      documentDisplay = (
        <PdfViewerRenderError
          error={downloadError}
          notifications={notifications}
          setNotifications={setNotifications}
          handleNotificationClick={handleNotificationClick}
          notificationIndexOpen={notificationIndexOpen}
          openSidePanel={openSidePanel}
          setOpenSidePanel={setOpenSidePanel}
          viewerConfig={viewerConfig}
        />
      );
    }
  } else if (fileType === SupportedFileType.PDF) {
    documentDisplay = (
      <PdfViewer
        fileName={notification.fileName || ""}
        pdfUrl={notification.fileUrl}
        notifications={notifications}
        setNotifications={setNotifications}
        handleNotificationClick={handleNotificationClick}
        notificationIndexOpen={notificationIndexOpen}
        currentPage={currentPage}
        zoomLevel={zoomLevel}
        currentRotate={currentRotate}
        openSidePanel={openSidePanel}
        setOpenSidePanel={setOpenSidePanel}
        viewerConfig={viewerConfig}
        paperlessOptedIn={paperlessOptedIn}
      />
    );
  } else if (fileType === SupportedFileType.JPG) {
    documentDisplay = (
      <ImageViewer
        url={notification.fileUrl}
        name={notification.fileName}
        notifications={notifications}
        handleNotificationClick={handleNotificationClick}
        openSidePanel={openSidePanel}
        setOpenSidePanel={setOpenSidePanel}
        viewerConfig={viewerConfig}
      />
    );
  } else {
    <CenteredTextContainer>
      <Body1>Viewing this file type is not supported</Body1>
      {viewerConfig?.closeDialogFunction && (
        <div style={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={viewerConfig.closeDialogFunction} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </CenteredTextContainer>;
  }

  return <>{documentDisplay}</>;
}
