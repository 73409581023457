import { Body1, Caption } from "@coherehealth/common";

export type PatientInfoReadOnlyTextFieldT = {
  className?: string;
  displayText?: string;
  label?: string;
};
export const PatientInfoReadOnlyTextField = ({ className, displayText, label }: PatientInfoReadOnlyTextFieldT) => {
  return (
    <div className={className}>
      <Caption>{label}</Caption>
      <Body1>{!displayText ? "--" : displayText}</Body1>
    </div>
  );
};
