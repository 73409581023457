import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useFeature } from "@coherehealth/common";
import { useNavigate, useLocation } from "react-router-dom";
import { stringify as stringifyQueryString, parse as parseQueryString } from "qs";
import { useGetUserById, useValidateUniqueEmail } from "@coherehealth/core-platform-api";
import { isBackOfficeWithoutOrganization } from "util/user";
import useLogout from "hooks/useLogout";
import { warn as logWarning } from "logger";
import { getEulaStatus } from "util/eula";
import { User, UserContext } from "UserContext";
import RedirectToAuthBuilderComponent from "RedirectToAuthBuilderComponent";
import { SSORedirectModal } from "./SSORedirectModal";

const SSORedirectComponent: FunctionComponent<
  React.PropsWithChildren<{ getAccessToken: () => Promise<string | undefined>; initialOktaUserData?: User }>
> = ({ getAccessToken, initialOktaUserData, children }) => {
  const { getUser } = useContext(UserContext);
  const logout = useLogout();
  const availityOnboardingFeatureOn = useFeature("availityOnboarding");
  const ssoUserOnboarding = useFeature("ssoUserOnboarding");
  const location = useLocation();
  const navigate = useNavigate();
  const [oktaUserData, setOktaUserData] = useState<User | undefined>(initialOktaUserData);
  const [cohereId, setCohereId] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();
  useEffect(() => {
    getAccessToken().then((newToken) => {
      setAccessToken((prev) => newToken);
    });
  }, [getAccessToken]);
  const authorizationHeaders = { headers: { Authorization: `Bearer ${accessToken}` } };

  const { data: userData, refetch } = useGetUserById({
    requestOptions: authorizationHeaders,
    lazy: true,
    id: "",
  });

  const {
    data: validation,
    error: validationError,
    refetch: validationRefetch,
  } = useValidateUniqueEmail({
    requestOptions: authorizationHeaders,
    lazy: true,
  });

  useEffect(() => {
    if (availityOnboardingFeatureOn && accessToken) {
      getUser()?.then((oktaUser) => {
        refetch({ pathParams: { id: oktaUser.sub } });
        setOktaUserData(oktaUser);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availityOnboardingFeatureOn, getUser, refetch, accessToken]);

  useEffect(() => {
    if (userData?.isSsoUser && userData.ssoType === "NAVINET") {
      if (!validation && !validationError) {
        validationRefetch();
      } else if (validation?.unique) {
        //"SSO account email is not unique, please authenticate with Cohere credentials"
        logout();
      } else if (validationError?.status === 404) {
        //"Could not validate SSO account email, see server logs"
        logout();
      }
    }
  }, [userData, validationRefetch, validation, validationError, logout]);

  useEffect(() => {
    localStorage.removeItem("skipRedirectBecauseUserFinishedOnboarding");
  }, []);

  const [redirectModalOpen, setRedirectModalOpen] = useState(false);
  const [hasRedirected, setHasRedirected] = useState(false);
  useEffect(() => {
    const queryParams = location.search;
    // Backoffice users without an organization should be redirected to the onboarding page when query param "fromIdp" is present OR user is an SSO user
    const shouldRedirect =
      location.pathname !== "/authenticated_organization_onboarding" && // Must include this to prevent infinite looping redirects
      userData &&
      !userData.pendingOrganizationId &&
      !userData.organization &&
      oktaUserData &&
      isBackOfficeWithoutOrganization(oktaUserData) &&
      (queryParams.includes("fromIdp") || userData.isSsoUser) &&
      !queryParams.includes("cohereId") &&
      localStorage.getItem("skipRedirectBecauseUserFinishedOnboarding") !== "true";
    // Backoffice users without an organization should be redirected to the onboarding page when query param "fromIdp" is present
    const shouldRedirectOld =
      userData &&
      !userData.pendingOrganizationId &&
      oktaUserData &&
      isBackOfficeWithoutOrganization(oktaUserData) &&
      queryParams.includes("fromIdp") &&
      !queryParams.includes("cohereId");
    if (ssoUserOnboarding) {
      if (shouldRedirect) {
        const { fromIdp, ...otherParams } = parseQueryString(queryParams.replace(/^[?]/, ""));
        const returnTo = `${location.pathname}?${stringifyQueryString(otherParams)}`;
        const newQueryParams = stringifyQueryString({ returnTo });
        // if user hasn't accepted EULA, redirect to onboarding immediately (once they are onboarded they can accept the EULA)
        // if user has accepted EULA, block screen with modal that prompts them to onboard (once they are onboarded they can continue to use the app)
        if (getEulaStatus(oktaUserData) !== "COMPLETE") {
          navigate(`/authenticated_organization_onboarding?${newQueryParams}`);
        } else if (getEulaStatus(oktaUserData) === "COMPLETE") {
          setRedirectModalOpen(true);
        }
      } else if (userData && oktaUserData && queryParams.includes("fromIdp") && queryParams.includes("cohereId")) {
        const { fromIdp, ...otherParams } = parseQueryString(queryParams.replace(/^[?]/, ""));
        const cohereId = otherParams["cohereId"];
        if (cohereId !== undefined) {
          setCohereId(cohereId.toString());
        }
      }
    } else {
      // One time only, log if user would have been redirected given new redirect logic
      if (shouldRedirect && !hasRedirected) {
        setHasRedirected(true);
        console.log(
          `User would have been redirected to org onboarding from '${location.pathname}' page, but ssoUserOnboarding feature is off`
        );
        logWarning(
          `User would have been redirected to org onboarding from '${location.pathname}' page, but ssoUserOnboarding feature is off`
        );
      }
      if (shouldRedirectOld) {
        const { fromIdp, ...otherParams } = parseQueryString(queryParams.replace(/^[?]/, ""));
        const returnTo = `${location.pathname}?${stringifyQueryString(otherParams)}`;
        const newQueryParams = stringifyQueryString({ returnTo });
        navigate(`/authenticated_organization_onboarding?${newQueryParams}`);
      } else if (userData && oktaUserData && queryParams.includes("fromIdp") && queryParams.includes("cohereId")) {
        const { fromIdp, ...otherParams } = parseQueryString(queryParams.replace(/^[?]/, ""));
        const cohereId = otherParams["cohereId"];
        if (cohereId !== undefined) {
          setCohereId(cohereId.toString());
        }
      }
    }
  }, [userData, oktaUserData, location.search, navigate, location.pathname, ssoUserOnboarding, hasRedirected]);

  return cohereId !== undefined ? (
    <RedirectToAuthBuilderComponent cohereId={cohereId}>{children}</RedirectToAuthBuilderComponent>
  ) : (
    <>
      {children}
      {Boolean(oktaUserData) && Boolean(userData) && (
        <SSORedirectModal
          open={redirectModalOpen}
          setOpen={setRedirectModalOpen}
          initialUserEmail={oktaUserData?.email || ""}
        />
      )}
    </>
  );
};

export default SSORedirectComponent;
