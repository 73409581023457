import { Dispatch, useCallback, useContext, useEffect, useState } from "react";

import { Patient, ServiceRequestResponse, useDeleteServiceRequestAttachment } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import RemoveAttachmentModal from "./RemoveAttachmentModal";
import { AuthorizationCard } from "./AuthorizationCard";
import { useGetAuthorizationByIdWithFallback } from "@coherehealth/common";
import { FaxAuthorizationContext } from "util/context/FaxAuthorizationContext";

interface AttachedRequestWithAuthorizationProps {
  serviceRequest: ServiceRequestResponse;
  serviceRequestAttachmentId: string;
  afterRemoveAttachment: () => void;
  setPatient?: Dispatch<Patient | undefined>;
}

/**
 * Given an id of a service request that the fax is attached to, renders an authorization card w/ a remove attachment action
 */
export function AttachedRequestWithAuthorization({
  serviceRequest,
  serviceRequestAttachmentId,
  afterRemoveAttachment,
  setPatient,
}: AttachedRequestWithAuthorizationProps) {
  const {
    mutate: removeAttachment,
    loading: removingAttachment,
    error: removeAttachmentError,
  } = useDeleteServiceRequestAttachment({ id: serviceRequest.id });

  const { error: getAuthorizationError, data: authorization } = useGetAuthorizationByIdWithFallback({
    id: serviceRequest?.authorization?.id || "",
    queryParams: {
      view: "expanded",
    },
  });

  const { handleServiceRequestSelection, handleNewlyAttachedServiceRequest, handleInitialAttachedServiceRequests } =
    useContext(FaxAuthorizationContext);

  const { enqueueSnackbar } = useSnackbar();
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const onConfirmRemove = useCallback(async () => {
    await removeAttachment(serviceRequestAttachmentId);

    handleServiceRequestSelection?.(serviceRequest);
    handleNewlyAttachedServiceRequest?.(serviceRequest.id);
    handleInitialAttachedServiceRequests?.(serviceRequest.id);

    setRemoveModalOpen(false);
    afterRemoveAttachment();
  }, [
    afterRemoveAttachment,
    handleInitialAttachedServiceRequests,
    handleNewlyAttachedServiceRequest,
    handleServiceRequestSelection,
    removeAttachment,
    serviceRequest,
    serviceRequestAttachmentId,
  ]);

  useEffect(() => {
    if (setPatient && serviceRequest?.patient) {
      setPatient(serviceRequest.patient);
    }
  }, [setPatient, serviceRequest]);

  useEffect(() => {
    if (removeAttachmentError) {
      enqueueSnackbar(`Failed to remove service request attachment: ${removeAttachmentError.message}`, {
        variant: "error",
      });
    }
    if (getAuthorizationError) {
      enqueueSnackbar(`Failed to load authorization: ${getAuthorizationError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, getAuthorizationError, removeAttachmentError]);

  if (!authorization) {
    return <></>;
  }

  return (
    <>
      <AuthorizationCard
        authorization={authorization}
        latestServiceRequest={serviceRequest ?? null}
        isFacilityBased={serviceRequest?.isFacilityBased}
        disableAttachmentCta={false}
        onRemove={() => setRemoveModalOpen(true)}
        showRemoveButton
      />
      <RemoveAttachmentModal
        open={removeModalOpen}
        handleClose={() => setRemoveModalOpen(false)}
        removing={removingAttachment}
        onRemove={onConfirmRemove}
      />
    </>
  );
}
