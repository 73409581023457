import { useCallback, useState } from "react";
import {
  ContentType,
  GetDenialsTemplatesForReviewQueryParams,
  ServiceRequestResponse,
  useGetDenialsTemplatesForReview,
  DenialsTemplate,
  AdhocNotificationType,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { isRecommendedDenial, isPostLetterStatus, isTatExtended } from "util/serviceRequest";
import { CategorizedDropdownOption } from "@coherehealth/common";

interface FetchContentBlockOpts {
  correctionLettersEnabled: boolean;
  qioAppealTemplatesOnly: boolean;
}

export function useFetchLetterTemplates(serviceRequest: ServiceRequestResponse | null, opts?: FetchContentBlockOpts) {
  const { enqueueSnackbar } = useSnackbar();
  const isDeniedOrPartial = isRecommendedDenial(serviceRequest?.authStatus);
  const [determinationTemplates, setDeterminationTemplates] = useState<DenialsTemplate[] | null>([]);
  const [nonDeterminationTemplates, setNonDeterminationTemplates] = useState<DenialsTemplate[] | null>([]);
  const [correctionTemplates, setCorrectionTemplates] = useState<DenialsTemplate[] | null>([]);
  const [qioAppealTemplates, setQioAppealTemplates] = useState<DenialsTemplate[] | null>([]);

  const mapTemplateDataForDisplay = (templates: DenialsTemplate[] | null, type: string): CategorizedDropdownOption[] =>
    templates?.map((template: any) => ({
      id: template?.id,
      label: template?.templateTitle,
      type,
    })) || [];

  const shouldFetchNonTatCorrectionBlocks =
    opts?.correctionLettersEnabled && isPostLetterStatus(serviceRequest?.authStatus);
  const shouldFetchTatCorrectionBlocks = opts?.correctionLettersEnabled && isTatExtended(serviceRequest);

  const shouldFetchCorrectionBlocks = shouldFetchNonTatCorrectionBlocks || shouldFetchTatCorrectionBlocks;

  const { refetch: refetchDeterminationBlocks, loading: isDeterminationBlocksLoading } =
    useGetDenialsTemplatesForReview({
      lazy: true,
      queryParams: determinationTemplatesQueryParams(serviceRequest),
    });

  const { refetch: refetchNonDeterminationBlocks, loading: isNonDeterminationBlocksLoading } =
    useGetDenialsTemplatesForReview({
      lazy: true,
      queryParams: nonDeterminationTemplatesQueryParams(serviceRequest),
    });

  const { refetch: refetchQioAppealTemplates, loading: isQioAppealTemplatesLoading } = useGetDenialsTemplatesForReview({
    lazy: true,
    queryParams: qioAppealTemplatesQueryParams(serviceRequest),
  });

  const { refetch: fetchCorrectionBlocks, loading: isCorrectionBlocksLoading } = useGetDenialsTemplatesForReview({
    lazy: true,
    queryParams: correctionTemplatesQueryParams(serviceRequest, {
      tatExtensionEnabled: shouldFetchTatCorrectionBlocks,
      isPostLetter: shouldFetchNonTatCorrectionBlocks,
    }),
  });

  const isLoading =
    isDeterminationBlocksLoading ||
    isNonDeterminationBlocksLoading ||
    isCorrectionBlocksLoading ||
    isQioAppealTemplatesLoading;

  const fetchTemplates = useCallback(async () => {
    try {
      if (opts?.qioAppealTemplatesOnly) {
        refetchQioAppealTemplates().then(setQioAppealTemplates);
      } else {
        const [nonDeterminationResponse, determinationResponse, correctionResponse] = await Promise.all([
          await refetchNonDeterminationBlocks(),
          isDeniedOrPartial ? await refetchDeterminationBlocks() : [],
          shouldFetchCorrectionBlocks ? await fetchCorrectionBlocks() : [],
        ]);
        setDeterminationTemplates(determinationResponse);
        setNonDeterminationTemplates(nonDeterminationResponse);
        setCorrectionTemplates(correctionResponse);
      }
    } catch (error) {
      enqueueSnackbar("Failed to fetch templates, please try again", { variant: "error" });
    }
  }, [
    opts?.qioAppealTemplatesOnly,
    refetchQioAppealTemplates,
    refetchNonDeterminationBlocks,
    isDeniedOrPartial,
    refetchDeterminationBlocks,
    shouldFetchCorrectionBlocks,
    fetchCorrectionBlocks,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    // Don't fetch templates unless we already loaded the service request
    if (serviceRequest?.authStatus) {
      fetchTemplates();
    }
  }, [serviceRequest?.authStatus, fetchTemplates]);

  return {
    isLoading,
    determinationTemplates,
    determinationDisplayTemplates: mapTemplateDataForDisplay(determinationTemplates, "Final Determination Letter(s)"),
    nonDeterminationTemplates,
    nonDeterminationDisplayTemplates: mapTemplateDataForDisplay(
      nonDeterminationTemplates,
      "Non-Determination Letter(s)"
    ),
    correctionTemplates,
    // Correction Letter(s) is no longer a category because
    // correction language can now be added to / removed from any template.
    correctionDisplayTemplates: mapTemplateDataForDisplay(correctionTemplates, "Other Letter(s)"),
    qioAppealTemplates,
    qioAppealDisplayTemplates: mapTemplateDataForDisplay(qioAppealTemplates, "QIO Appeal Letter(s)"),
  };
}
// further efforts should be made to standardize healthPlanName in Mongo
// and or use the healthPlan Enum as a source of truth
export function nonDeterminationTemplatesQueryParams(
  serviceRequest: ServiceRequestResponse | null | undefined
): GetDenialsTemplatesForReviewQueryParams | undefined {
  return serviceRequest
    ? {
        authStatus: "",
        usedGuidelineIds: [],
        healthPlan: serviceRequest?.healthPlanName?.toUpperCase()?.replace(/\s+/g, "_"),
        lineOfBusiness: serviceRequest?.lobType,
        state: serviceRequest?.patient?.address?.state,
        templateType: "NON_DETERMINATION",
        encounterTypes: serviceRequest.encounterType ? [serviceRequest.encounterType] : [],
      }
    : undefined;
}
export function determinationTemplatesQueryParams(
  serviceRequest: ServiceRequestResponse | null | undefined
): GetDenialsTemplatesForReviewQueryParams | undefined {
  return serviceRequest
    ? {
        authStatus: serviceRequest?.authStatus || "",
        usedGuidelineIds: [],
        healthPlan: serviceRequest?.healthPlanName?.toUpperCase()?.replace(/\s+/g, "_"),
        lineOfBusiness: serviceRequest?.lobType,
        state: serviceRequest?.patient?.address?.state,
        templateType: "DETERMINATION",
        encounterTypes: serviceRequest.encounterType ? [serviceRequest.encounterType] : [],
      }
    : undefined;
}

interface CorrectionQueryParamOpts {
  tatExtensionEnabled?: boolean;
  isPostLetter?: boolean;
}

export function correctionTemplatesQueryParams(
  serviceRequest: ServiceRequestResponse | null | undefined,
  opts: CorrectionQueryParamOpts
): GetDenialsTemplatesForReviewQueryParams | undefined {
  if (!serviceRequest) {
    return undefined;
  } else {
    const excludeContentTypes: ContentType[] = opts.tatExtensionEnabled ? [] : ["TAT_EXTENSION"];
    const filterContentTypes: ContentType[] = opts.isPostLetter ? [] : ["TAT_EXTENSION"];
    return {
      usedGuidelineIds: [],
      healthPlan: serviceRequest?.healthPlanName?.toUpperCase()?.replace(/\s+/g, "_"),
      lineOfBusiness: serviceRequest?.lobType,
      state: serviceRequest?.patient?.address?.state,
      templateType: "CORRECTION",
      encounterTypes: serviceRequest.encounterType ? [serviceRequest.encounterType] : [],
      excludeContentTypes,
      filterContentTypes,
    };
  }
}

function qioAppealTemplatesQueryParams(
  serviceRequest: ServiceRequestResponse | null
): GetDenialsTemplatesForReviewQueryParams | undefined {
  const adhocNotificationTypes: AdhocNotificationType[] = ["QIO_APPEAL_DENC", "QIO_APPEAL_DND", "NOMNC"];

  if (!serviceRequest) {
    return undefined;
  } else {
    return {
      usedGuidelineIds: [],
      healthPlan: serviceRequest?.healthPlanName?.toUpperCase()?.replace(/\s+/g, "_"),
      lineOfBusiness: serviceRequest?.lobType,
      state: serviceRequest?.patient?.address?.state,
      templateType: "NON_DETERMINATION",
      authStatus: serviceRequest?.authStatus,
      adhocNotificationTypes: adhocNotificationTypes,
    };
  }
}
