import { Dispatch, SetStateAction, useState } from "react";

import { TextField } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";

import { ServiceRequestSearchRequestBody } from "@coherehealth/core-platform-api";

interface Props {
  queryParams: ServiceRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ServiceRequestSearchRequestBody>>;
}

const useRequestSearchBarStyles = makeStyles((theme) => ({
  searchServiceRequests: {
    marginBottom: theme.spacing(3),
  },
  searchAdornment: {
    marginLeft: -9,
  },
  clearAdornment: {
    marginRight: -9,
  },
}));

export default function ServiceRequestSearchBar({ queryParams, setQueryParams }: Props) {
  const [searchText, setSearchText] = useState(queryParams.query ? queryParams.query : "");
  const classes = useRequestSearchBarStyles();
  const onClearSearch = () => {
    setSearchText("");
    searchServiceRequests("");
  };

  const searchServiceRequests = (searchText: string) => {
    const offsetOrNull = { offset: 0 };
    setQueryParams({ ...queryParams, query: `${searchText}`, ...offsetOrNull });
  };

  return (
    <TextField
      className={classes.searchServiceRequests}
      placeholder="Search (Patient name, Member ID, Tracking ID, Auth ID)"
      data-testid="search-bar"
      value={searchText}
      onChange={(e) => {
        if (searchText !== e.target.value) {
          setSearchText(e.target.value);
          searchServiceRequests(e.target.value);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          searchServiceRequests(searchText);
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment className={classes.searchAdornment} position="start">
            <IconButton
              aria-label="search"
              onClick={() => {
                searchServiceRequests(searchText);
              }}
            >
              <Search />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment className={classes.clearAdornment} position="end">
            {searchText && (
              <IconButton aria-label="clear" onClick={onClearSearch}>
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
