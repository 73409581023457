import { useGetLatestReviewLeadingToDenial } from "@coherehealth/core-platform-api";
import { useState } from "react";

interface UseLatestReviewCheckProps {
  serviceRequestId: string;
  currentReviewId?: string;
  onIncorrectReview: () => void;
}

export const useLatestReviewCheck = ({
  serviceRequestId,
  currentReviewId,
  onIncorrectReview,
}: UseLatestReviewCheckProps) => {
  const [isCheckingLatestReview, setIsCheckingLatestReview] = useState(false);
  const { refetch: getLatestReviewLeadingToDenial } = useGetLatestReviewLeadingToDenial({
    id: serviceRequestId,
    lazy: true,
  });

  const checkLatestReview = async () => {
    setIsCheckingLatestReview(true);
    const latestReviewLeadingToDenial = await getLatestReviewLeadingToDenial();
    setIsCheckingLatestReview(false);

    if (!latestReviewLeadingToDenial) {
      return false;
    }

    const isCorrectReview = latestReviewLeadingToDenial.id === currentReviewId;
    if (!isCorrectReview) {
      onIncorrectReview();
      return false;
    }

    return true;
  };

  return { checkLatestReview, isCheckingLatestReview };
};
