import { Coverage, Patient } from "@coherehealth/core-platform-api";

interface GetPatientHealthDisplayNameProps {
  latestCoverage?: Coverage;
  patient: Patient;
  showCoverageEndDate?: boolean;
}

export function getPatientHealthDisplayName({
  patient,
  latestCoverage,
  showCoverageEndDate,
}: GetPatientHealthDisplayNameProps) {
  let healthPlanName = patient.coverage?.healthPlanName;
  let healthPlanDisplayName = patient.coverage?.healthPlanDisplayName
    ? patient.coverage?.healthPlanDisplayName
    : patient.coverage?.healthPlanName;
  let rbe = patient.coverage?.riskBearingEntity;

  if (showCoverageEndDate && latestCoverage) {
    healthPlanName = latestCoverage.healthPlanDisplayName
      ? latestCoverage.healthPlanDisplayName
      : latestCoverage.healthPlanName;
    rbe = latestCoverage.riskBearingEntity;
  }

  healthPlanDisplayName =
    !!rbe && !!healthPlanName && rbe !== healthPlanName ? `${healthPlanName} - ${rbe}` : healthPlanDisplayName;

  return healthPlanDisplayName;
}
