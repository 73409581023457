import React, { useState, useEffect, SetStateAction, useContext } from "react";
import { ContinuationFormContent } from "common/SharedServiceRequestFormComponents";
import {
  Checkbox,
  colorsLight,
  DateSelect,
  TimeTextField,
  useFeature,
  Tooltip,
  getHoursAndMinutesAndSecondsStrFromDate,
} from "@coherehealth/common";
import { addTimeTextIn24HourFormatToDate } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
import { Divider, makeStyles, useTheme } from "@material-ui/core";
import { useAuthorized } from "authorization";
import { User } from "UserContext";
import { Patient, ServiceRequestEditConfig } from "@coherehealth/core-platform-api";
import ExpeditedStatusModal from "../../ServiceRequest/ServiceRequestForm/components/ExpeditedStatusModal";
import { isBackOfficeUser } from "../../../util/user";
import { timePattern } from "util/dateUtils";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";

interface ExpeditedUpgradeDatePickerProps {
  formContent: ContinuationFormContent;
  setFormContent: (value: SetStateAction<ContinuationFormContent>) => void;
  currentUser: User | undefined;
  formEditConfiguration?: ServiceRequestEditConfig | undefined;
  patient?: Patient | undefined;
  healthPlan?: string;
  serviceRequestId?: string;
  clinicalServiceId?: string;
  attemptedSubmit?: boolean;
}

const ExpeditedUpgradeDatePicker = ({
  formContent,
  setFormContent,
  currentUser,
  formEditConfiguration,
  patient,
  healthPlan,
  serviceRequestId,
  clinicalServiceId,
  attemptedSubmit,
}: ExpeditedUpgradeDatePickerProps) => {
  const classes = useStyles();
  const canBackOfficeEditExpedited = formEditConfiguration?.enableBackOfficeExpediteEdits ?? false;
  const allowBackOfficeToUpdateExpeditedStatus = useFeature("allowBackOfficeToUpdateExpeditedStatus");
  const useExpeditedTatUpdateTimestamp = useFeature("useExpeditedTatUpdateTimestamp");
  const hasUpgradeToExpeditedPermission = useAuthorized("UPGRADE_REQUESTS_TO_EXPEDITED");
  const [isExpedited, setIsExpedited] = useState<boolean>(!!formContent.isExpedited);
  const [isExpeditedModalOpen, setIsExpeditedModalOpen] = useState<boolean>(false);
  const { faxAttachment: faxData } = useContext(FaxAttachmentContext);
  const isUserBackOffice = isBackOfficeUser(currentUser);
  const canUpgradeToExpeditedUsingModal =
    allowBackOfficeToUpdateExpeditedStatus && isUserBackOffice && canBackOfficeEditExpedited;
  const diableUpgradeToExpeditedUsingDates = !useExpeditedTatUpdateTimestamp || !hasUpgradeToExpeditedPermission;
  const disableExpedite = diableUpgradeToExpeditedUsingDates && !canUpgradeToExpeditedUsingModal;
  const today = new Date();
  const setExpeditedStatusFromModal = (isExpedited: boolean, reason: string) => {
    //update local state
    setIsExpedited(isExpedited);
    setIsExpeditedModalOpen(false);
    //update form with expedited status, isExpeditedByModal (used to set expeditedTatUpdateTimestamp at point of save) and reason (if expedited is set to true)
    setFormContent((prevFormContent) => ({
      ...prevFormContent,
      isExpedited: isExpedited,
      isExpeditedByModal: isExpedited,
      expeditedReason: isExpedited ? reason : "",
      expeditedTatUpdateTimestamp: undefined,
    }));
  };

  const isValidExpeditedTime = timePattern.test(formContent?.expeditedTime || "");
  useEffect(() => {
    if (!formContent?.expeditedDate || !formContent?.expeditedTime) {
      return;
    }
    setFormContent((oldFormContent) => {
      const expeditedTatUpdateTimestamp = addTimeTextIn24HourFormatToDate(
        formContent?.expeditedDate!,
        formContent?.expeditedTime!
      );

      return {
        ...oldFormContent,
        expeditedTatUpdateTimestamp,
      };
    });
  }, [setFormContent, formContent?.expeditedDate, formContent?.expeditedTime]);

  /*
  This useEffect serves to initialize an expedited date and time based on a fax timestamp, if:
  -they do not exist in the form content (haven't been filled out or edited by a user)
  -we have fax data (we're in a fax-related flow)
  -the user has marked the request as expedited
   */
  useEffect(() => {
    if (isExpedited && !formContent?.expeditedTime && !formContent?.expeditedDate) {
      if (faxData) {
        const faxDateCreatedDate = new Date(faxData.dateCreated);
        setFormContent((oldFormContent) => {
          return {
            ...oldFormContent,
            expeditedDate: faxDateCreatedDate,
            expeditedTime: getHoursAndMinutesAndSecondsStrFromDate(faxDateCreatedDate),
          };
        });
      } else {
        const currentDate = new Date();
        setFormContent((oldFormContent) => {
          return {
            ...oldFormContent,
            expeditedDate: currentDate,
            expeditedTime: getHoursAndMinutesAndSecondsStrFromDate(currentDate),
          };
        });
      }
    }
  }, [faxData, formContent?.expeditedDate, formContent?.expeditedTime, isExpedited, setFormContent]);

  const { spacing } = useTheme();

  const ExpediteCheckbox = () => {
    return (
      <Checkbox
        className={classes.expeditedUpgradeCheckbox}
        checked={formContent.isExpedited}
        onChange={(checked) => {
          setIsExpedited(checked);
          setIsExpeditedModalOpen(checked && canUpgradeToExpeditedUsingModal);
          // Clear expeditedTatUpdateTimestamp so it can subsequently be recomputed:
          // 1. Immediately before save, if updated by the modal, OR
          // 2. By a useEffect, if the user inputs date and time via the picker components.
          setFormContent((prevFormContent) => ({
            ...prevFormContent,
            isExpedited: checked,
            expeditedTatUpdateTimestamp: undefined,
            ...(!checked && {
              expeditedDate: undefined,
              expeditedTime: undefined,
            }),
          }));
        }}
        label="Expedite"
        disabled={disableExpedite}
      />
    );
  };

  return (
    <Grid className={classes.expeditedSection}>
      <Grid item>
        <Divider style={{ marginTop: spacing(4) }} />
      </Grid>
      <Grid container item className={classes.expeditedFieldsContainer}>
        <Grid item className={classes.expeditedCheckboxGrid}>
          {disableExpedite ? (
            <Tooltip title="You cannot change the expedite status">{ExpediteCheckbox()}</Tooltip>
          ) : (
            <ExpediteCheckbox />
          )}
        </Grid>
        {isExpedited && hasUpgradeToExpeditedPermission && !isUserBackOffice && (
          <Grid container item spacing={3} className={classes.expeditedDateTimeGrid}>
            <Grid item>
              <DateSelect
                label="Expedite requested on"
                minDate={formContent.tatStartTimestamp}
                error={attemptedSubmit && !formContent?.expeditedDate}
                helperText={attemptedSubmit && !formContent?.expeditedDate ? "Required" : ""}
                maxDate={today}
                value={formContent?.expeditedDate || null}
                onDateChange={(newExpeditedTatUpdateTimeDate) => {
                  setFormContent((oldFormContent) => {
                    return {
                      ...oldFormContent,
                      expeditedDate: newExpeditedTatUpdateTimeDate,
                    };
                  });
                }}
                attemptedSubmit={attemptedSubmit}
                inputProps={{
                  style: {
                    width: "170px",
                  },
                }}
              />
            </Grid>
            <Grid item>
              <TimeTextField
                label="Expedite requested at"
                timeFormat="24"
                placeholder="hh:mm:ss"
                timePattern={["h", "m", "s"]}
                error={(attemptedSubmit && !isValidExpeditedTime) || (attemptedSubmit && !formContent?.expeditedTime)}
                helperText={
                  attemptedSubmit && !formContent?.expeditedTime
                    ? "Required"
                    : !isValidExpeditedTime && attemptedSubmit
                    ? "Invalid time"
                    : ""
                }
                value={formContent?.expeditedTime}
                onChangeValue={(newExpeditedTatUpdateHourAndMin) => {
                  setFormContent((oldFormContent) => {
                    return {
                      ...oldFormContent,
                      expeditedTime: newExpeditedTatUpdateHourAndMin,
                    };
                  });
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <ExpeditedStatusModal
        open={isExpeditedModalOpen}
        handleClose={() => {
          setIsExpeditedModalOpen(false);
        }}
        setIsExpedited={setExpeditedStatusFromModal}
        patientId={patient?.id || ""}
        serviceRequestId={serviceRequestId}
        clinicalServiceId={clinicalServiceId}
        patientHealthPlanName={healthPlan}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  expeditedSection: {
    marginBottom: theme.spacing(3),
    width: "100%",
  },
  expeditedFieldsContainer: {
    marginTop: theme.spacing(1),
    lineHeight: "60px",
  },
  expeditedCheckboxGrid: {
    //defined by responsive ui design
    flex: "0 0 115px",
    minWidth: "115px",
  },
  expeditedUpgradeCheckbox: {
    color: colorsLight.font.secondary,
    marginTop: theme.spacing(1),
  },
  expeditedDateTimeGrid: {
    //defined by responsive ui design
    flex: "1 1 87%",
  },
}));

export default ExpeditedUpgradeDatePicker;
