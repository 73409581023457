import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled, useTheme } from "@material-ui/core";
import {
  IntegrationStatus,
  KafkaIntegrationStatus,
  KafkaMessageDocumentResponse,
  TATNotification,
  ServiceRequestResponse,
  useRetryKafkaIntegration,
  useMarkTatNotificationIntegrationStatus,
} from "@coherehealth/core-platform-api";
import {
  H6,
  InformativeModal,
  InformativeModalProps,
  PrimaryButton,
  SingleSelectDropdown,
  useConfiguration,
} from "@coherehealth/common";
import { useMarkIntegrationStatus } from "@coherehealth/core-platform-api";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MuiErrorIcon from "@material-ui/icons/Error";
import { useSnackbar } from "notistack";
import {
  IntegrationStatus as IntObsIntegrationStatus,
  useRetryKafkaBasedIntegration as useObsRetryKafkaBasedIntegration,
} from "@coherehealth/int-obs-api";
import config from "api/config";

const EditIntegrationStatus: FunctionComponent<{
  serviceRequest: ServiceRequestResponse;
  onEdit?: () => void;
  mailVendorKafkaLogs?: KafkaMessageDocumentResponse[];
  tatNotification?: TATNotification | null;
  setTatNotification?: Dispatch<SetStateAction<TATNotification | undefined>>;
}> = ({ serviceRequest, onEdit, mailVendorKafkaLogs, tatNotification, setTatNotification }) => {
  const [integrationType, setIntegrationType] = useState<IntegrationType>();
  const [integrationStatus, setIntegrationStatus] = useState<string>("");
  const [showHelperTextForIntegrationType, setShowHelperTextForIntegrationType] = useState<boolean>(false);
  const salesforceIntegrationWhiteList = ["Humana"];
  const shouldNotIntegratedWithSalesforce = !salesforceIntegrationWhiteList.includes(
    serviceRequest.healthPlanName ?? ""
  );
  const theme = useTheme();
  const helperTextForIntegrationType =
    "Authorizations for this health plan are not usually integrated to Salesforce. If you reset the Salesforce integration, this auth will be added to Salesforce";

  const {
    mutate: submitRetry,
    loading: submittingRetry,
    error: submitRetryError,
  } = useMarkIntegrationStatus({
    id: serviceRequest.id,
  });

  const { mutate: obsRetryKafkaBasedIntegration } = useObsRetryKafkaBasedIntegration({
    base: `${config.INT_OBS_SERVICE_API_URL}`,
    objectId: "",
  });

  const {
    mutate: retryKafkaIntegration,
    error: retryKafkaIntegrationError,
    loading: retryKafkaIntegrationLoading,
  } = useRetryKafkaIntegration({});

  const {
    mutate: updateTatIntegration,
    loading: updatingTatIntegration,
    error: tatIntegrationUpdatError,
  } = useMarkTatNotificationIntegrationStatus({
    id: tatNotification?.id || "",
  });

  const corePlatformIntegrationHealthPlanNamesConfiguration = useConfiguration(
    "corePlatformIntegrationConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );

  const { enqueueSnackbar } = useSnackbar();

  const onSubmitRetry =
    integrationType && integrationStatus
      ? async (serviceRequest: ServiceRequestResponse) => {
          if (integrationType === "tat_matrix") {
            const tatIntegration = await updateTatIntegration({
              integrationType: "matrix",
              integrationStatus: integrationStatus,
            });
            setTatNotification?.(tatIntegration);
          } else if (
            integrationType === "attachments" &&
            !corePlatformIntegrationHealthPlanNamesConfiguration?.enabled
          ) {
            serviceRequest.attachments?.forEach(async (attachment) => {
              await obsRetryKafkaBasedIntegration(
                {
                  integrationType: "ATTACHMENTS",
                  integrationStatus: "READY",
                },
                { pathParams: { objectId: attachment.id } }
              );
            });
          } else if (integrationType === "payor" && !serviceRequest?.integrationStatus) {
            await obsRetryKafkaBasedIntegration(
              {
                integrationStatus: "READY",
                integrationType: "AUTHORIZATION_OUTBOUND",
              },
              { pathParams: { objectId: serviceRequest?.id } }
            );
          } else {
            await submitRetry({
              integrationType: integrationType,
              integrationStatus: integrationStatus,
            });
          }
          onEdit?.();
        }
      : undefined;

  const onRetryKafkaMessage = async () => {
    if (integrationType === "mail-vendor-consumer") {
      if (mailVendorKafkaLogs && mailVendorKafkaLogs?.length > 0) {
        const mostRecentLog = mailVendorKafkaLogs[0];
        const response = await retryKafkaIntegration({
          retryType: "singleMessage",
          index: mostRecentLog.index,
          documentId: mostRecentLog.id,
        });
        if (response.statusCode === 200) {
          setKafkaRetryMessageModalStage("success");
        } else {
          setKafkaRetryMessageModalStage("failure");
        }
      } else {
        enqueueSnackbar("Cannot find Kafka message to retry", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (retryKafkaIntegrationError) {
      setKafkaRetryMessageModalStage("failure");
      enqueueSnackbar("Failed to retry integration of single message, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [retryKafkaIntegrationError, enqueueSnackbar]);

  useEffect(() => {
    if (submitRetryError || tatIntegrationUpdatError) {
      enqueueSnackbar("Error updating the integration status, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, submitRetryError, tatIntegrationUpdatError]);

  const integrationInfrastructure = IntegrationInfrastructureMapping[integrationType || "payor"];

  /* 
    Remove the old mail vendor option if the SR has Kafka mail vendor logs, so that the
    user does not accidentally retry the wrong integration.
  */
  const hasMailVendorKafkaLogs = mailVendorKafkaLogs && mailVendorKafkaLogs.length > 0;
  let integrationTypeOptionsList = Object.entries(IntegrationTypeOptions).map(([id, label]) => ({ id, label }));
  if (hasMailVendorKafkaLogs) {
    integrationTypeOptionsList = integrationTypeOptionsList.filter((type) => type.id !== "matrix");
  } else {
    integrationTypeOptionsList = integrationTypeOptionsList.filter((type) => type.id !== "mail-vendor-consumer");
  }
  if (!tatNotification) {
    integrationTypeOptionsList = integrationTypeOptionsList.filter((type) => type.id !== "tat_matrix");
  }

  const [kafkaRetryMessageModalOpen, setKafkaRetryMessageModalOpen] = useState(false);
  const [kafkaRetryMessageModalStage, setKafkaRetryMessageModalStage] =
    useState<KafkaRetryMessageInformativeModalStage>();

  const openKafkaRetryMessageModal = () => {
    setKafkaRetryMessageModalStage("confirmation");
    setKafkaRetryMessageModalOpen(true);
  };

  const closeKafkaRetryMessageModal = () => {
    setKafkaRetryMessageModalOpen(false);
  };

  const standardSuccessKafkaMessageRetryModalProps: Omit<InformativeModalProps, "open" | "onClose"> = {
    headerText: "The attempt to retry has been initiated.",
    subHeaderText: "Please give the integration a minute to fully process and refresh the page to see results.",
    additionalInfoText:
      "Note that this does not mean that the retry is complete. The retry process through Kafka is asynchronous, meaning that we only receive confirmation that the process started, and not the results of the retry.",

    icon: <SuccessIcon />,
    primaryButtonText: undefined,
    primaryButtonAction: undefined,
    primaryButtonLoading: undefined,
    tertiaryButtonText: "Close",
    tertiaryButtonAction: closeKafkaRetryMessageModal,
  };

  const standardFailureKafkaMessageRetryModalProps: Omit<InformativeModalProps, "open" | "onClose"> = {
    headerText: "The attempt to retry has failed.",
    subHeaderText: `Error code: ${retryKafkaIntegrationError?.status || "N/A"} - Error reason: ${
      retryKafkaIntegrationError?.message || "N/A"
    }`,
    additionalInfoText: undefined,
    icon: <ErrorIcon />,
    primaryButtonText: "Retry again",
    primaryButtonAction: onRetryKafkaMessage,
    primaryButtonLoading: retryKafkaIntegrationLoading,
    tertiaryButtonText: "Close",
    tertiaryButtonAction: closeKafkaRetryMessageModal,
  };

  const KafkaRetryMessageInformativeModalConfig: Record<KafkaIntegrations, KafkaRetryMessageInformativeModalProps> = {
    "mail-vendor-consumer": {
      confirmation: {
        headerText: "Are you sure you want to retry this integration?",
        subHeaderText:
          "Doing so will attempt to send a final determination letter to the appropriate recipients via Matrix.",
        additionalInfoText: undefined,
        icon: <WarningIcon />,
        primaryButtonText: "Retry",
        primaryButtonAction: onRetryKafkaMessage,
        primaryButtonLoading: retryKafkaIntegrationLoading,
        tertiaryButtonText: "Go back",
        tertiaryButtonAction: closeKafkaRetryMessageModal,
      },
      success: standardSuccessKafkaMessageRetryModalProps,
      failure: standardFailureKafkaMessageRetryModalProps,
    },
  };

  const getOptions = () => {
    if (integrationType === "payor" && !serviceRequest?.integrationStatus) {
      return Object.entries(IntObsIntegrationStatuses).map(([id, label]) => ({ id, label }));
    } else {
      return Object.entries(IntegrationStatuses).map(([id, label]) => ({ id, label }));
    }
  };

  return (
    <>
      <>
        <StyledH6 data-public>
          {integrationInfrastructure === "Kafka" ? "Retry Kafka Message" : "Set the Integration Status"}
        </StyledH6>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SingleSelectDropdown
              label="Integration Type"
              value={integrationType || ""}
              onChange={(type) => {
                setShowHelperTextForIntegrationType(shouldNotIntegratedWithSalesforce && type === "salesforce");
                assertIsIntegrationType(type);
                setIntegrationType(type);
              }}
              options={integrationTypeOptionsList}
              optionIdsWithWarning={shouldNotIntegratedWithSalesforce ? ["salesforce"] : []}
              warningTextForOptions={helperTextForIntegrationType}
              helperText={
                showHelperTextForIntegrationType && (
                  <div style={{ color: theme.palette.error.main }}>{helperTextForIntegrationType}</div>
                )
              }
              dataPublic={true}
            />
          </Grid>
          {integrationInfrastructure === "Kafka" &&
          Object.keys(KafkaRetryMessageInformativeModalConfig).includes(integrationType || "payor") ? (
            <Grid item xs={4}>
              <PrimaryButton onClick={openKafkaRetryMessageModal} loading={retryKafkaIntegrationLoading}>
                Retry
              </PrimaryButton>
              <InformativeModal
                open={kafkaRetryMessageModalOpen}
                onClose={closeKafkaRetryMessageModal}
                headerText={
                  KafkaRetryMessageInformativeModalConfig[integrationType as KafkaIntegrations][
                    kafkaRetryMessageModalStage || "confirmation"
                  ]?.headerText || ""
                }
                icon={
                  KafkaRetryMessageInformativeModalConfig[integrationType as KafkaIntegrations][
                    kafkaRetryMessageModalStage || "confirmation"
                  ]?.icon
                }
                subHeaderText={
                  KafkaRetryMessageInformativeModalConfig[integrationType as KafkaIntegrations][
                    kafkaRetryMessageModalStage || "confirmation"
                  ]?.subHeaderText
                }
                additionalInfoText={
                  KafkaRetryMessageInformativeModalConfig[integrationType as KafkaIntegrations][
                    kafkaRetryMessageModalStage || "confirmation"
                  ]?.additionalInfoText
                }
                primaryButtonText={
                  KafkaRetryMessageInformativeModalConfig[integrationType as KafkaIntegrations][
                    kafkaRetryMessageModalStage || "confirmation"
                  ]?.primaryButtonText
                }
                primaryButtonAction={
                  KafkaRetryMessageInformativeModalConfig[integrationType as KafkaIntegrations][
                    kafkaRetryMessageModalStage || "confirmation"
                  ]?.primaryButtonAction
                }
                primaryButtonLoading={
                  KafkaRetryMessageInformativeModalConfig[integrationType as KafkaIntegrations][
                    kafkaRetryMessageModalStage || "confirmation"
                  ]?.primaryButtonLoading
                }
                tertiaryButtonText={
                  KafkaRetryMessageInformativeModalConfig[integrationType as KafkaIntegrations][
                    kafkaRetryMessageModalStage || "confirmation"
                  ]?.tertiaryButtonText
                }
                tertiaryButtonAction={
                  KafkaRetryMessageInformativeModalConfig[integrationType as KafkaIntegrations][
                    kafkaRetryMessageModalStage || "confirmation"
                  ]?.tertiaryButtonAction
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={4}>
                <SingleSelectDropdown
                  label="Set status to"
                  value={integrationStatus}
                  onChange={setIntegrationStatus}
                  options={getOptions()}
                  dataPublic={true}
                />
              </Grid>
              <Grid item xs={4}>
                <PrimaryButton
                  onClick={() => onSubmitRetry?.(serviceRequest)}
                  loading={submittingRetry || updatingTatIntegration}
                >
                  submit
                </PrimaryButton>
              </Grid>
            </>
          )}
        </Grid>
      </>
    </>
  );
};

export default EditIntegrationStatus;

type KafkaIntegrations = "mail-vendor-consumer";

export type IntegrationType =
  | keyof NonNullable<ServiceRequestResponse["integration"]>
  | "payor"
  | "attachments"
  | "tat_matrix"
  | KafkaIntegrations;

export const IntegrationTypeOptions: Record<IntegrationType, string> = {
  payor: "Payor",
  matrix: "Mail vendor",
  "mail-vendor-consumer": "Mail Vendor - Kafka",
  welltok: "VAT",
  carewebqi: "CareWebQI",
  salesforce: "Salesforce",
  fax_final_determination: "Final determination fax",
  tat_matrix: "TAT extension mail",
  attachments: "Attachments",
};

type IntegrationInfrastructure = "Core-Platform" | "Kafka";

const IntegrationInfrastructureMapping: Record<IntegrationType, IntegrationInfrastructure> = {
  payor: "Core-Platform",
  matrix: "Core-Platform",
  "mail-vendor-consumer": "Kafka",
  welltok: "Core-Platform",
  carewebqi: "Core-Platform",
  salesforce: "Core-Platform",
  fax_final_determination: "Core-Platform",
  tat_matrix: "Core-Platform",
  attachments: "Core-Platform",
};

export const IntegrationStatuses: Record<IntegrationStatus, string> = {
  READY: "Ready",
  REQUEST_ERROR: "Request Error",
  FAILED: "Failed",
  PROCESSING: "Processing",
  SENT: "Sent",
  COMPLETE: "Complete",
  WITHDRAWN: "Withdrawn",
  INTERNALLY_COMPLETE: "Internally Complete",
};

export const IntObsIntegrationStatuses: Partial<Record<IntObsIntegrationStatus, string>> = {
  READY: "Ready",
};

export const KafkaIntegrationStatuses: Record<KafkaIntegrationStatus, string> = {
  SUCCESS: "Success",
  FAILURE: "Failure",
  RETRIED: "Retried",
};

type KafkaRetryMessageInformativeModalStage = "confirmation" | "success" | "failure";

interface KafkaRetryMessageInformativeModalProps {
  confirmation: Omit<InformativeModalProps, "open" | "onClose">;
  success: Omit<InformativeModalProps, "open" | "onClose">;
  failure: Omit<InformativeModalProps, "open" | "onClose">;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.warning.dark,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.success.main,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.error.main,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledH6 = styled(H6)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

function assertIsIntegrationType(type: string): asserts type is IntegrationType {
  if (!Object.keys(IntegrationInfrastructureMapping).includes(type)) {
    throw new Error("Invalid integration type");
  }
}
