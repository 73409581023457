import { useRef } from "react";
import { ProcedureCode, ServiceDeterminationQuestionOption } from "@coherehealth/core-platform-api";
import { Grid, useTheme, Container, CircularProgress } from "@material-ui/core";
import { Box, Tooltip, Typography } from "@mui/material";
import { Alert, Chip } from "@coherehealth/common";
import { ButtonSelectGroup } from "@coherehealth/design-system";
import useIsOverflow from "hooks/useIsOverflow";
import { useMuiContainerStyles, SecondaryButton } from "@coherehealth/common";

interface Props {
  procedureCode: ProcedureCode;
  serviceOptions: ServiceDeterminationQuestionOption[];
  selectedServicesIds: string[];
  changeSelectedServices: (selectedServicesIds: string[]) => void;
  onRemove: () => void;
  onAdd: () => void;
  servicesLoading: boolean;
  nonPalProcedureCodes: ProcedureCode[];
  isInpatient?: boolean;
}

export default function ServicesSelect({
  procedureCode,
  serviceOptions,
  selectedServicesIds,
  changeSelectedServices,
  onRemove,
  onAdd,
  servicesLoading,
  nonPalProcedureCodes,
  isInpatient,
}: Props) {
  const theme = useTheme();
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const containerClasses = useMuiContainerStyles();
  const { hoverStatus } = useIsOverflow(textElementRef);
  const isNonPalPxCode = nonPalProcedureCodes.some((nonPalPxCode) => nonPalPxCode.code === procedureCode.code);
  return (
    <Container classes={containerClasses} style={{ padding: 0 }} maxWidth="lg">
      <Box marginTop={4}>
        <Grid item xs={12} key={procedureCode.code}>
          <div>
            {isNonPalPxCode && !isInpatient && (
              <Box marginTop={-4} position="relative" left={-10}>
                <Alert severity="warning" message="This is a non-PAL code" style={{ background: "transparent" }} />
              </Box>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <Grid container spacing={3} style={{ paddingBottom: theme.spacing(3) }}>
              <Box
                key={procedureCode.code}
                display="flex"
                alignItems="top"
                marginBottom="12px"
                flex={1}
                padding="12px"
                width="fit-content"
                minWidth="35%"
              >
                <Chip label={procedureCode.code} size="small" data-public />
                <Tooltip title={procedureCode.description || ""} placement="top" disableHoverListener={!hoverStatus}>
                  <Typography
                    ref={textElementRef}
                    style={{
                      paddingLeft: theme.spacing(1),
                      width: "100%",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: 400,
                      fontSize: "13px",
                      lineHeight: "15px",
                      letterSpacing: "0.15px",
                      maxHeight: "80%",
                    }}
                    data-public
                  >
                    {procedureCode.description || ""}
                  </Typography>
                </Tooltip>
              </Box>
              <Grid item style={{ flex: "1 1 50%" }}>
                {servicesLoading ? (
                  <CircularProgress size={64} />
                ) : (
                  <ButtonSelectGroup
                    options={
                      serviceOptions
                        ?.sort(({ name: name0 }, { name: name1 }) => ((name0 || "") < (name1 || "") ? -1 : 1))
                        ?.map(({ id, name }) => ({
                          id: id || "",
                          label: name || "",
                        })) || []
                    }
                    type="multiSelect"
                    values={selectedServicesIds}
                    onChangeValues={async (selectedServicesIds) => {
                      changeSelectedServices(selectedServicesIds);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Box>
      <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
        <SecondaryButton warning onClick={onRemove} style={{ marginRight: 16 }}>
          Remove
        </SecondaryButton>
        <SecondaryButton onClick={onAdd} disabled={!(selectedServicesIds?.length > 0)}>
          Add procedure code
        </SecondaryButton>
      </div>
    </Container>
  );
}
