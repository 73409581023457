import { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  useGetAssociateFaxToServiceRequest,
  ServiceRequestResponse,
  useCreateServiceRequestAttachment,
  ReferralRequestResponse,
  useCreateReferralRequestAttachment,
  AuthorizationResponse,
} from "@coherehealth/core-platform-api";
import {
  SelectedPatientInfo,
  useFeature,
  InlineButton,
  queueMgmtBaseUrl,
  HEALTH_HELP_V2_NAME,
} from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { error as logError } from "logger";
import { useSnackbar } from "notistack";
import { generatePath, useNavigate } from "react-router";
import routes from "routes";
import { WORKFLOW_REFERER_PARAM, useFullLocationPath, FAX_INTAKE, QUEUE_FAX_INTAKE } from "util/queryParams";
import { FaxSidebarView } from "../common";
import { IsCohereTemplateContext } from "../FaxAttachmentSidebar";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import { ServiceCase, useCompleteServiceCase } from "@coherehealth/qm-api";
import CommonFaxFooter from "../CommonFaxFooter";
import {
  useTrackUserInteraction,
  activityContextFromServiceRequest,
  activityContextFromCreateReferralRequest,
  activityContextFromReferralRequest,
} from "util/userActivityTracker";
import { CircularProgress } from "@material-ui/core";
import { AvailableRequestTypes, RequestTypeSelectionTab } from "./RequestTypeSelectionTab";
import { useGetPatientWithReferralEligibility } from "util/referralRequest";
import { useGetFaxIntakeConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import config from "api/config";
import { FaxAuthorizationContext } from "util/context/FaxAuthorizationContext";
import { AuthorizationCard } from "./AuthorizationCard/AuthorizationCard";
import { getLatestDeterminedServiceRequest, isAuthorizationValidForContinuations } from "util/authorization";
import PatientReferralRequestsList from "./PatientReferralRequestsList";
import AuthDetailsFlyout from "./AuthDetailsFlyout";

interface Props {
  authorizations: Array<AuthorizationResponse> | [];
  referralRequests: Array<ReferralRequestResponse> | undefined;
  patient?: Patient;
  onCancel: () => void;
  onAllFileAttachmentsComplete: () => void;
  faxCohereId?: string;
  faxId?: string;
  showCreateNewServiceRequest?: boolean;
  showCreateNewReferralRequest?: boolean;
  setSidebarView: Dispatch<FaxSidebarView>;
  ocrServiceRequest?: ServiceRequestResponse;
  serviceCase?: ServiceCase | undefined | null;
  externalReferenceId?: string | undefined;
  healthPlanName?: string;
  loadingServiceRequests: boolean;
  isSearchingByTrackingNumber?: boolean;
  showOnlyRequestOfType?: AvailableRequestTypes;
  setSelectedServiceId: Dispatch<string>;
}

const useStyles = makeStyles((theme) => ({
  serviceRequestResultsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    paddingBottom: 72,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  fullWidthDivider: {
    margin: theme.spacing(4, -3, 3),
  },
  fullWidthBottomDivider: {
    margin: theme.spacing(0, -3, 0),
  },
  fullWidthBottomDividerWithMarginBlock: {
    margin: theme.spacing(3, -3, 0),
  },
  createServiceRequestBtn: {
    padding: theme.spacing(1),
  },
  scrollableResultList: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: "1 1 0",
    padding: theme.spacing(2, 3, 3),
    margin: theme.spacing(0, -3),
  },
  resultListHeader: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  leftButton: {
    alignItems: "flex-start",
    margin: "0px 8px 0px 12px",
  },
  rightButton: {
    minWidth: theme.spacing(25),
    margin: "0px 12px 0px 8px",
  },
  loadingSpinnerContainer: {
    display: "block",
    alignSelf: "center",
    margin: "24px auto 8px auto",
  },
  createButtonsContainer: {
    flex: 1,
    display: "flex",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0),
  },
  heightDivider: {
    width: "1px",
    height: "28px",
    alignSelf: "center",
  },
  ineligibleForReferralsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  resultsListContainer: {
    height: "100%",
    width: "100%",
  },
  cardPadding: {
    marginTop: "10px",
  },
}));

export function useGenerateFaxWorkflowRedirectPath(
  patient?: Patient,
  ocrServiceRequestId?: string,
  serviceCaseSpecialty?: string
) {
  const { caseId, faxDbId: faxId } = useContext(FaxAttachmentContext);
  const fullLocationPath = useFullLocationPath();

  let path;
  path = `${generatePath(routes.FAX_AUTH_BUILDER, { faxId: faxId, patientId: patient?.id || "" })}?`;
  path += ocrServiceRequestId ? `&serviceRequestId=${ocrServiceRequestId}` : "";
  path += caseId ? `&caseId=${caseId}` : "";

  if (serviceCaseSpecialty === "triage") {
    path += `&workflowLength=${"short"}`;
  }

  path += `&${encodeURIComponent(WORKFLOW_REFERER_PARAM)}=${encodeURIComponent(fullLocationPath)}`;
  path += `&faxStep=SIDE_BY_SIDE_VIEW`;
  return path;
}

export function useGenerateReferralFaxWorkflowRedirectPath(patient?: Patient) {
  const { faxDbId: faxId, caseId } = useContext(FaxAttachmentContext);

  let path;
  path = `${generatePath(routes.FAX_REFERRAL_BUILDER, { faxId: faxId, patientId: patient?.id || "" })}?`;
  path += caseId ? `&caseId=${caseId}` : "";
  path += `&faxStep=REFERRAL_SIDE_BY_SIDE_VIEW`;
  return path;
}

export const shouldShowCreateContinuation = (
  latestSR: ServiceRequestResponse,
  latestNoneDraftSr: ServiceRequestResponse
) => {
  return (
    !latestNoneDraftSr.clinicalService?.hasUnitsOnPxChanged &&
    latestNoneDraftSr.delegatedVendor !== HEALTH_HELP_V2_NAME &&
    latestNoneDraftSr.allowContinuations &&
    isAuthorizationValidForContinuations(latestSR, latestNoneDraftSr)
  );
};

export default function AuthorizationRequestResults({
  patient,
  onCancel,
  onAllFileAttachmentsComplete,
  showCreateNewServiceRequest,
  showCreateNewReferralRequest,
  faxCohereId,
  faxId,
  setSidebarView,
  ocrServiceRequest,
  serviceCase,
  externalReferenceId,
  healthPlanName,
  loadingServiceRequests,
  referralRequests,
  isSearchingByTrackingNumber,
  showOnlyRequestOfType,
  authorizations,
  setSelectedServiceId,
}: Props) {
  const classes = useStyles();
  const {
    handleServiceRequestSelection,
    handleReferralRequestSelection,
    handleNewlyAttachedServiceRequest,
    authContext,
    handleUploadState,
    handleNewlyAttachedReferralRequestIds,
    handleAuthDetailsSelected,
    isReferralRequestSelected,
    isRRAttached,
  } = useContext(FaxAuthorizationContext);
  const { data: faxIntakeConfiguration } = useGetFaxIntakeConfigurationByPayer(healthPlanName ?? "");
  const { caseId, queueId } = useContext(FaxAttachmentContext);
  const { mutate: updateServiceCase } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const trackUserActivityInteraction = useTrackUserInteraction();
  const stage = useContext(IsCohereTemplateContext) ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW";
  const sideBySideEditSrFaxIntakeWorkflow = useFeature("sideBySideEditSrFaxIntakeWorkflow");

  const [selectedRequestType, setSelectedRequestType] = useState<AvailableRequestTypes>(
    showOnlyRequestOfType ?? "service"
  );
  const selectedPatientReferralEligibility = useGetPatientWithReferralEligibility(patient?.id || "")
    ?.referralEligibility?.delegated;

  const {
    mutate: uploadSRFax,
    loading: uploadingSRFax,
    error: faxUploadSRError,
  } = useCreateServiceRequestAttachment({
    id: "",
    requestOptions: { headers: { Accept: "application/json" } },
  });

  const {
    mutate: uploadRRFax,
    loading: uploadingRRFax,
    error: faxUploadRRError,
  } = useCreateReferralRequestAttachment({
    id: "",
    requestOptions: { headers: { Accept: "application/json" } },
  });
  const { mutate: getAssociateFaxToServiceRequest, error: associateSubmissionFaxError } =
    useGetAssociateFaxToServiceRequest({
      queryParams: {
        externalReferenceId: serviceCase?.externalSources?.at(0)?.externalReferenceId || externalReferenceId,
        serviceRequestId: "",
      },
    });

  const listAndCreateReferralsFeatureEnabled = faxIntakeConfiguration?.referralsCreationFromFaxEnabled ?? false;
  const {
    newlyAttachedReferralRequestIds,
    fileUploadComplete,
    selectedServiceRequestIds,
    newlyAttachedServiceRequestIds,
    selectedReferralRequestId,
    selectedAuth,
  } = authContext;

  const userHasUploadedFile = useMemo(() => {
    return (
      fileUploadComplete &&
      selectedServiceRequestIds.length > 0 &&
      newlyAttachedServiceRequestIds.length === selectedServiceRequestIds.length
    );
  }, [fileUploadComplete, newlyAttachedServiceRequestIds.length, selectedServiceRequestIds.length]);

  const onSRAttachmentFailed = useCallback(
    (sr: ServiceRequestResponse) => {
      const errorMessage = `Adding fax attachment to service request failed (srId: ${sr.id}, faxCohereId: ${faxCohereId})`;
      logError(errorMessage);
      enqueueSnackbar(errorMessage, { variant: "error" });
      handleUploadState?.(false);
      handleServiceRequestSelection?.(sr);
    },
    [enqueueSnackbar, faxCohereId, handleServiceRequestSelection, handleUploadState]
  );

  const onRRAttachmentFailed = useCallback(
    (id: string) => {
      const errorMessage = `Adding fax attachment to referral request failed (rrId: ${id}, faxCohereId: ${faxCohereId})`;
      logError(errorMessage);
      enqueueSnackbar(errorMessage, { variant: "error" });
      handleUploadState?.(false);
      handleReferralRequestSelection?.(id);
    },
    [enqueueSnackbar, faxCohereId, handleReferralRequestSelection, handleUploadState]
  );
  const faxWorkflowRedirectPath = useGenerateFaxWorkflowRedirectPath(
    patient,
    ocrServiceRequest?.id,
    serviceCase?.specialty
  );

  const referralFaxWorkflowRedirectPath = useGenerateReferralFaxWorkflowRedirectPath(patient);
  const handleUploadAttachments = async () => {
    if (faxCohereId !== undefined && selectedServiceRequestIds?.length > 0) {
      const handleFaxSRUpload = async (serviceRequest: ServiceRequestResponse) => {
        const formData = new FormData();
        formData.set("faxId", faxCohereId);
        await uploadSRFax(formData as unknown as void, { pathParams: { id: serviceRequest.id } }).then(
          async () => {
            //track when a user attaches a fax to a service request
            trackUserActivityInteraction({
              event: "ATTACHED_FAX_TO_AUTH",
              stage: stage,
              activityContext: { ...activityContextFromServiceRequest(serviceRequest), faxId: faxId },
            }).finally(() => handleNewlyAttachedServiceRequest?.(serviceRequest.id));
          },
          () => {
            onSRAttachmentFailed(serviceRequest);
          }
        );
      };
      for (const selectedServiceId of selectedServiceRequestIds) {
        const selectedServiceRequest = authContext.selectedServiceRequests?.find((serviceRequest) => {
          return serviceRequest.id === selectedServiceId;
        });

        if (selectedServiceRequest) {
          try {
            //If fax is a hmk fax, associate fax to service request before uploading fax
            if (healthPlanName === "Highmark") {
              await getAssociateFaxToServiceRequest({} as unknown as void, {
                queryParams: {
                  externalReferenceId: serviceCase?.externalSources?.at(0)?.externalReferenceId || externalReferenceId,
                  serviceRequestId: selectedServiceRequest.id ?? "",
                },
              });
            }

            await handleFaxSRUpload(selectedServiceRequest);
          } catch (error) {
            onSRAttachmentFailed(selectedServiceRequest);
          }
        }
      }
      handleUploadState?.(true);
    }

    if (faxCohereId !== undefined && selectedReferralRequestId) {
      const formData = new FormData();
      formData.set("faxId", faxCohereId);

      const selectedReferralRequest = referralRequests?.find((referralRequest) => {
        return referralRequest.id === selectedReferralRequestId;
      });

      if (selectedReferralRequest) {
        try {
          await uploadRRFax(formData as unknown as void, { pathParams: { id: selectedReferralRequestId } }).then(
            async () => {
              // track when a user attaches a fax to a referral request
              trackUserActivityInteraction({
                event: "ATTACHED_FAX_TO_REFERRAL",
                stage: stage,
                activityContext: { ...activityContextFromReferralRequest(selectedReferralRequest), faxId },
              }).finally(() => {
                handleNewlyAttachedReferralRequestIds?.(selectedReferralRequestId);
                updateServiceCase({
                  outcome: "FAX_ATTACHED_TO_RF",
                  dateCompleted: new Date().toISOString(),
                  description: "Fax attached to RF",
                  referralRequest: {
                    referralRequestId: selectedReferralRequest.id,
                    cohereId: selectedReferralRequest.cohereId,
                    dateCreated: new Date(selectedReferralRequest.dateCreated).toISOString(),
                    status: selectedReferralRequest.referralStatus,
                  },
                });
              });
            }
          );
        } catch (error) {
          onRRAttachmentFailed(selectedReferralRequestId);
        }
      }
    }
  };

  const handleCreateServiceClick = async () => {
    //track when a user clicks on "Create new authorization"
    trackUserActivityInteraction({
      event: "START_NEW_SR",
      stage: stage,
      activityContext: { ...activityContextFromCreateReferralRequest(patient?.id ?? ""), faxId: faxId },
    }).finally(() => {
      navigate(faxWorkflowRedirectPath);
    });
  };

  const handleCreateReferralClick = async () => {
    //track when a user clicks on "Create new referral"
    trackUserActivityInteraction({
      event: "START_NEW_RF",
      stage: stage,
      activityContext: { ...activityContextFromCreateReferralRequest(patient?.id ?? ""), faxId: faxId },
    }).finally(() => {
      navigate(referralFaxWorkflowRedirectPath);
    });
  };

  const onEditClick = useCallback(
    (serviceRequestId: string) => {
      if (serviceRequestId && serviceRequestId !== "") {
        setSelectedServiceId(serviceRequestId);
        if (sideBySideEditSrFaxIntakeWorkflow) {
          navigate(
            generatePath(routes.EDIT_SR_SIDE_BY_SIDE, {
              faxId: faxId,
              serviceRequestId: serviceRequestId,
              caseId: caseId,
            })
              .concat(`?${FAX_INTAKE}=${faxId}&${QUEUE_FAX_INTAKE}=${queueId}`)
              .concat(`&faxStep=EDIT_VIEW`)
          );
        } else {
          window.open(
            generatePath(routes.EDIT_SERVICE_REQUEST_FAX, {
              serviceRequestId: serviceRequestId,
              caseId: caseId,
            })
              .concat(`?${FAX_INTAKE}=${faxId}&${QUEUE_FAX_INTAKE}=${queueId}`)
              .concat(`&faxStep=EDIT_VIEW`)
          );
          setSidebarView("EDIT_SERVICE_VIEW");
        }
      }
    },
    [setSelectedServiceId, sideBySideEditSrFaxIntakeWorkflow, navigate, caseId, faxId, queueId, setSidebarView]
  );
  const disableButton = selectedReferralRequestId !== "";
  const disableNewCtaButtons = disableButton || selectedServiceRequestIds.length !== 0;

  useEffect(() => {
    if (userHasUploadedFile) {
      enqueueSnackbar(`Fax attached (${newlyAttachedServiceRequestIds.length}/${selectedServiceRequestIds.length})`, {
        variant: "success",
      });

      onAllFileAttachmentsComplete();
    }
  }, [
    userHasUploadedFile,
    enqueueSnackbar,
    newlyAttachedServiceRequestIds,
    selectedServiceRequestIds,
    onAllFileAttachmentsComplete,
  ]);

  useEffect(() => {
    if (faxUploadSRError || faxUploadRRError) {
      const errorMessageSR =
        typeof faxUploadSRError?.data === "object"
          ? faxUploadSRError.data.message || faxUploadSRError.message
          : faxUploadSRError?.message;
      const errorMessageRR =
        typeof faxUploadRRError?.data === "object"
          ? faxUploadRRError.data.message || faxUploadRRError.message
          : faxUploadRRError?.message;
      enqueueSnackbar(`Failed to attach fax to service request: ${errorMessageSR || errorMessageRR}`, {
        variant: "error",
      });
    }
    if (associateSubmissionFaxError) {
      enqueueSnackbar(`Could not associate Highmark fax to service request, please try again`, {
        variant: "error",
      });
    }
  }, [associateSubmissionFaxError, enqueueSnackbar, faxUploadSRError, faxUploadRRError]);

  const shouldDisableContinue =
    (selectedServiceRequestIds.length === 0 &&
      !selectedReferralRequestId &&
      newlyAttachedServiceRequestIds.length === 0 &&
      newlyAttachedReferralRequestIds.length === 0) ||
    uploadingSRFax ||
    uploadingRRFax;

  return (
    <div className={classes.serviceRequestResultsContainer} key={`AuthView_${faxCohereId}`}>
      {patient && (
        <>
          <SelectedPatientInfo patient={patient} />
          {!isSearchingByTrackingNumber && listAndCreateReferralsFeatureEnabled && (
            <RequestTypeSelectionTab
              selectedRequestType={selectedRequestType}
              onHandleTabChange={(requestType) => setSelectedRequestType(requestType)}
              totalAuthorizations={authorizations?.length || 0}
              totalReferrals={selectedPatientReferralEligibility ? referralRequests?.length || 0 : 0}
            />
          )}
          <Divider
            className={
              isSearchingByTrackingNumber || !listAndCreateReferralsFeatureEnabled
                ? classes.fullWidthBottomDividerWithMarginBlock
                : classes.fullWidthBottomDivider
            }
          />
        </>
      )}
      <div className={classes.scrollableResultList}>
        <Grid container spacing={2}>
          {loadingServiceRequests ? (
            <div className={classes.loadingSpinnerContainer}>
              <CircularProgress size={26} />
            </div>
          ) : (
            <>
              <div
                hidden={selectedRequestType === "service" || !listAndCreateReferralsFeatureEnabled}
                id={"referralsList"}
                className={classes.resultsListContainer}
              >
                <PatientReferralRequestsList
                  // once we release to everyone, we should move these
                  // props into the components using the new context.
                  patientId={patient?.id}
                  referralRequests={referralRequests}
                  healthPlanName={healthPlanName}
                  selectedPatientReferralEligibility={selectedPatientReferralEligibility}
                  isRRSelected={isReferralRequestSelected}
                  isRRAttached={isRRAttached}
                  selectRR={handleReferralRequestSelection}
                  unselectRR={handleReferralRequestSelection}
                  selectedReferralRequestId={selectedReferralRequestId}
                  selectedServiceRequestIds={selectedServiceRequestIds}
                  canAttach={selectedReferralRequestId === null && !selectedServiceRequestIds}
                />
              </div>

              <div
                hidden={selectedRequestType === "referral"}
                id={"serviceRequestsList"}
                className={classes.resultsListContainer}
              >
                {authorizations?.map((auth) => {
                  const latestNoneDraftSr = auth && getLatestDeterminedServiceRequest(auth);
                  const latestSR = auth?.serviceRequestsOnAuth?.[0];
                  if (latestNoneDraftSr === undefined || latestSR === undefined) {
                    return <div key={`empty_${auth.id}`}></div>;
                  }

                  return (
                    <Grid item xs={12} key={`auth${auth.id}`} className={classes.cardPadding}>
                      <AuthorizationCard
                        authorization={auth}
                        latestServiceRequest={latestNoneDraftSr ?? null}
                        faxCohereId={faxCohereId}
                        isFacilityBased={latestNoneDraftSr.isFacilityBased}
                        attachingFile={uploadingRRFax || uploadingSRFax}
                        showCreateContinuation={shouldShowCreateContinuation(latestSR, latestNoneDraftSr)}
                        disableAttachmentCta={disableButton}
                        onEditClick={() => onEditClick(latestNoneDraftSr.id)}
                        showEditRequest={latestNoneDraftSr?.authStatus !== "DRAFT"}
                        setSidebarView={setSidebarView}
                      />
                    </Grid>
                  );
                })}
              </div>
            </>
          )}

          {isSearchingByTrackingNumber &&
            showCreateNewServiceRequest &&
            ((referralRequests && referralRequests.length > 0) || (authorizations && authorizations?.length > 0)) && (
              <div key="create-new-authorization-and-create-new-referral" className={classes.createButtonsContainer}>
                <InlineButton
                  disabled={disableNewCtaButtons}
                  className={classes.createServiceRequestBtn}
                  onClick={handleCreateServiceClick}
                  data-public
                >
                  Create new authorization
                </InlineButton>
                {selectedPatientReferralEligibility && listAndCreateReferralsFeatureEnabled && (
                  <>
                    <Divider className={classes.heightDivider} />
                    <InlineButton
                      disabled={disableNewCtaButtons}
                      className={classes.createServiceRequestBtn}
                      onClick={handleCreateReferralClick}
                      data-public
                    >
                      Create new referral
                    </InlineButton>
                  </>
                )}
              </div>
            )}

          {!isSearchingByTrackingNumber && showCreateNewServiceRequest && selectedRequestType === "service" && (
            <Grid item xs={6} key="create-new-authorization">
              <InlineButton
                disabled={disableNewCtaButtons}
                className={classes.createServiceRequestBtn}
                onClick={handleCreateServiceClick}
                data-public
              >
                Create new authorization
              </InlineButton>
            </Grid>
          )}

          {!isSearchingByTrackingNumber &&
            showCreateNewReferralRequest &&
            listAndCreateReferralsFeatureEnabled &&
            selectedRequestType === "referral" &&
            selectedPatientReferralEligibility && (
              <Grid item xs={6}>
                <InlineButton
                  disabled={disableNewCtaButtons}
                  className={classes.createServiceRequestBtn}
                  onClick={handleCreateReferralClick}
                  data-public
                >
                  Create new referral
                </InlineButton>
              </Grid>
            )}
        </Grid>
      </div>
      {selectedAuth && (
        <>
          <AuthDetailsFlyout
            key={selectedAuth.id}
            authorization={selectedAuth}
            setFlyoutOpen={handleAuthDetailsSelected}
          />
        </>
      )}

      <CommonFaxFooter
        onPrimaryButtonClick={() => handleUploadAttachments()}
        disabledFinish={shouldDisableContinue}
        onCancelButtonClick={onCancel}
        setSidebarView={setSidebarView}
      />
    </div>
  );
}
