import { H6, useConfiguration } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core";

interface Props {
  serviceRequest: ServiceRequestResponse;
}

export default function DelegatedVendor({ serviceRequest }: Props) {
  const requestStatusConfiguration =
    useConfiguration("requestStatusConfiguration", serviceRequest.healthPlanName, serviceRequest?.delegatedVendor) ??
    {};
  const delegatedVendorDisplay = requestStatusConfiguration?.pendingWithDisplayHealthPlan
    ? serviceRequest?.healthPlanDisplayName
    : serviceRequest?.delegatedVendor;
  const hasDelegatedVendor = !!serviceRequest?.delegatedVendor;
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { spacing } = theme;

  return (
    <DelegatedVendorContainer>
      <DelegatedVendorHeader>
        Delegated vendor: {hasDelegatedVendor ? `${delegatedVendorDisplay}` : "null"}
      </DelegatedVendorHeader>
    </DelegatedVendorContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DelegatedVendorContainer = styled("div")(({ theme }) => ({
  display: "flex",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DelegatedVendorHeader = styled(H6)(({ theme }) => ({
  marginRight: theme.spacing(0.25),
}));
