import { InformativeModal } from "@coherehealth/common";
import ClipboardGrayWarningIcon from "../../../images/ClipboardGrayWarningIcon.svg";
import { ModalManagerProps, ReviewCorrectionPayload } from "./hooks/useReviewCorrection";
import {
  AuthStatusUpdatePayload,
  ReviewSendLettersPayload,
  SendLettersResponse,
  UpdateAuthStatusResponse,
} from "@coherehealth/core-platform-api";
import { MutateMethod } from "restful-react";
import { format } from "date-fns";

type ReviewConfirmationModalProps = {
  open: boolean;
  updateAuthStatus: MutateMethod<UpdateAuthStatusResponse, AuthStatusUpdatePayload, void, void>;
  sendLetters: MutateMethod<SendLettersResponse, ReviewSendLettersPayload, void, void>;
  setReviewCorrectionsResponse: React.Dispatch<React.SetStateAction<string>>;
  setModalManager: React.Dispatch<React.SetStateAction<ModalManagerProps>>;
  onClose: () => void;
  reviewCorrectionPayload: ReviewCorrectionPayload;
  cohereId: string;
  nextReviewDate: Date | null;
  nextReviewDateAcknowledged: boolean;
};

export const ReviewConfirmationModal = ({
  open,
  updateAuthStatus,
  sendLetters,
  setReviewCorrectionsResponse,
  setModalManager,
  onClose,
  reviewCorrectionPayload,
  cohereId,
  nextReviewDate,
  nextReviewDateAcknowledged,
}: ReviewConfirmationModalProps) => {
  const handleAuthStatusUpdate = async () => {
    // eslint-disable-next-line prettier/prettier
    const { reviewId, correctionReason, actionType, integrationMatrixStatus, authStatus, pendingReason } =
      reviewCorrectionPayload;
    const response = await updateAuthStatus({
      reviewId,
      correctionReason,
      actionType,
      cohereId,
      ...(integrationMatrixStatus ? { integrationMatrixStatus } : {}),
      ...(authStatus ? { authStatus } : {}),
      ...(pendingReason ? { pendingReason } : {}),
      ...(nextReviewDate ? { nextReviewDate: format(nextReviewDate, "yyyy-MM-dd") } : {}),
      ...(nextReviewDateAcknowledged ? { nextReviewDateAcknowledged } : {}),
    });
    return response;
  };

  const handleLetterTrigger = async () => {
    const { reviewId, correctionReason } = reviewCorrectionPayload;
    const response = await sendLetters({ reviewId, correctionReason });
    return response;
  };

  const handleClickConfirmationButton = async () => {
    try {
      const response = ["MOVE_FORWARD", "MOVE_BACKWARD"].includes(reviewCorrectionPayload.actionType || "")
        ? await handleAuthStatusUpdate()
        : await handleLetterTrigger();

      setReviewCorrectionsResponse(response?.message || "");
    } finally {
      setModalManager((prev) => ({
        ...prev,
        openConfirmationModal: false,
        openUpdateOutcomeModal: true,
      }));
    }
  };

  const modalConfig = {
    headerText: "Are you sure you want to proceed with review correction?",
    icon: <img src={ClipboardGrayWarningIcon} alt="" />,
    open,
    primaryButtonText: "Go back to patient summary",
    primaryButtonAction: onClose,
    tertiaryButtonText: "Continue with review correction",
    tertiaryButtonAction: handleClickConfirmationButton,
    onClose,
  };

  return <InformativeModal {...modalConfig} />;
};
