import { UserType } from "./types/userType";
import { enumifyList, generateAuthorizer } from "@coherehealth/common";
import useUserRoles from "./hooks/useUserRoles";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";
import { error as logError } from "logger";
/**
 * Roles granted access to each feature type
 */
export const Permissions = enumifyList([
  "AUTH_VIEW_ONLY",
  "EDIT_SERVICE_REQUEST_STATUS",
  "EDIT_SERVICE_REQUEST_STATUS_AFTER_MANUAL_ENTRY",
  "EDIT_ATTACHMENT_PRACTICE_VIEWABLE",
  "ADD_ATTACHMENT_AFTER_TERMINAL_STATUS",
  "DELETE_ATTACHMENT_AFTER_DRAFT",
  "VIEW_INCOMING_FAXES",
  "VIEW_SERVICE_REQUEST_AUDIT_LOG",
  "START_AUTH_REQUEST",
  "EDIT_SERVICE_REQUEST",
  "EDIT_ATTACHMENTS",
  "EDIT_TEXT_HIGHLIGHTS",
  "VIEW_RULE_ACTION_SOURCE",
  "EDIT_CLINICAL_ASSESSMENT",
  "EDIT_SERVICE_REQUEST_IN_REVIEW_STAGE",
  "EDIT_APPROVED_SR_PLACE_OF_SERVICE",
  "VIEW_ATTACHMENT_CLASSIFICATION_SCORE",
  "VIEW_REVIEW",
  "EDIT_NURSE_REVIEW",
  "EDIT_OUT_OF_NETWORK_REVIEW",
  "EDIT_MD_REVIEW",
  "EDIT_FINALIZED_MD_REVIEW",
  "SUBMIT_DENIAL",
  "EDIT_P2P_REVIEW",
  "EDIT_HMOEXCEPTION_REVIEW",
  "MY_ORGANIZATION_PAGE",
  "ORGANIZATION_MANAGEMENT_PAGE",
  "INTEGRATION_MANAGEMENT_PAGE",
  "INTEGRATION_OBSERVABILITY_PAGE",
  "PATIENT_MANAGEMENT_PAGE",
  "EDIT_PATIENT_COVERAGE",
  "FEATURE_FLAG_PAGE",
  "ADMIN_CREATED_PROVIDER_EDIT",
  "REQUESTOR_FORM",
  "VIEW_SERVICE_REQUEST_INTEGRATION",
  "VIEW_PATIENT_SUMMARY_INTEGRATIONS_UI",
  "VIEW_PATIENT_SUMMARY_NOTIFICATION_UI",
  "SKIP_CLINICAL_ASSESSMENT",
  "FINAL_DETERMINATION_LETTER_DISPLAY",
  "PATIENT_FINAL_DETERMINATION_LETTER_DISPLAY",
  "START_APPEAL_LETTER",
  "SERVICE_OPS_ICON",
  "UPLOAD_ORGS_FROM_FILE",
  "EDIT_WITHDRAWAL_REQUESTOR",
  "VIEW_ALL_WITHDRAWAL_REASONS",
  "CAN_DELETE_SERVICE_REQUEST_COLLECTION",
  "CAN_OPT_IN_FOR_E_LETTERS",
  "VIEW_OUTREACH_OPPORTUNITIES",
  "VIEW_SET_AUTH_STATUS_OUTREACH_OPPORTUNITIES",
  "VIEW_ATTACHMENT_ID",
  "USE_NEW_DENIALS_WORKFLOW",
  "USE_CLINICAL_REVIEW_REDESIGN_GENERAL",
  "HEALTH_HELP_ADMIN_ONLY",
  "VIEW_CLINICAL_ASSESSMENT_TEST_PAGE",
  "VIEW_NEW_EMAIL_VERIFIED_USERS",
  "EDIT_HEALTH_HELP_STATUS",
  "EDIT_CHANNEL",
  "QM_PILOT",
  "CASE_HISTORY",
  "AUDIT_REVIEW",
  "DOC_SEGMENTATION_BETA",
  "MISSING_INFO",
  "VIEW_NURSE_REVIEWER_CASE",
  "VIEW_MD_REVIEWER_CASE",
  "VIEW_LETTER_WRITING_CASE",
  "VIEW_OUTREACH_CASE",
  "CAN_SKIP_OON",
  "CAN_REVIEW_AND_EDIT_UNVERIFIED_USERS",
  "CAN_EDIT_MY_ORGANIZATION",
  "CAN_DELETE_MY_ORGANIZATION",
  "CAN_REVIEW_AND_EDIT_VERIFIED_USERS",
  "VIEW_ESCALATED_STATE",
  "UPGRADE_REQUESTS_TO_EXPEDITED",
  "CAN_EDIT_CARE_SETTINGS",
  "CAN_VIEW_PREVIOUSLY_APPROVED_REQUESTS",
  "ACCESS_LOG_OUTREACH_PAGE",
  "EDIT_NOTES",
  "EDIT_APPEAL",
  "VIEW_NOTES",
  "CAN_SKIP_MANUAL_NPI_TIN",
  "ADD_PX_PENDING_SERV_REQUEST",
  "CAN_BYPASS_REDIRECTS",
  "CAN_VIEW_AUTH_STATUS_HISTORY",
  "CREATE_PLACEHOLDER_PATIENT",
  "POST_DECISION_AUTH_STATUS_UPDATE",
  "TRANSFER_AUTHORIZATION",
  "VIEW_LETTERS",
  "VIEW_LETTER_STATUS",
  "VIEW_CONFIGURATIONS",
  "AUTHORIZE_VIEW_ONLY",
  "CLINICAL_VIEWER",
  "VIEW_PA_CHECK",
  "VIEW_HEALTH_PLAN",
  "VIEW_ENHANCED_WITHDRAWAL_MODAL",
  "CAN_SWITCH_PA_REFERRAL_PATIENT_SUMMARY",
  "VIEW_MY_ORGANIZATION",
  "CORRECT_AUTH_STATUS",
  "SEND_LETTER_REVIEW_CORRECTION",
] as const);

/**
 * The set of features recognized in the application as a union of string constants
 */
type Feature = keyof typeof Permissions;

/**
 * This is a poor man's enum:
 * so that other files can just refer to a constant like `Permission.DO_SOMETHING`
 */
const StandaloneRoles = enumifyList([
  // Sort of a placeholder, but can be used for things like ADMINSTER_ANY_ORG etc from core-platform code
  "GENERIC_REVIEWER",
] as const);
type StandaloneRole = keyof typeof StandaloneRoles;

type Permission = Feature;
type Role = StandaloneRole | UserType;
type Grant = Permission | Role;

/* New hierarchical implementation to replace the non-hierarchical version
 */
const PERMISSION_HIERARCHY: Partial<Record<Role, readonly Grant[]>> = Object.freeze({
  [UserType.Admin]: [
    UserType.ServiceOpsLeadership,
    UserType.NurseReviewer,
    UserType.MDReviewer,
    UserType.FinalReviewAuditor,
    UserType.ClinicalContent,
    UserType.IntegrationViewer,
    UserType.OpsUserAdmin,
    UserType.ReviewDenialSubmitter,
    UserType.BackOfficeAdmin,
    UserType.InternalUserAdmin,
    UserType.PatientCoverageEditor,
    UserType.FeatureFlagEditor,
    UserType.ClinicalReviewRedesignReviewerBeta,
    UserType.Assigner,
    UserType.AuditReviewer,
    UserType.DocTypeandOrderingBetaUsers,
    UserType.BypassRedirects,
    UserType.PlaceholderPatientCreator,
    UserType.TransferAuthorization,
    UserType.Cypress,
    Permissions.ADMIN_CREATED_PROVIDER_EDIT,
    Permissions.UPLOAD_ORGS_FROM_FILE,
    Permissions.CAN_DELETE_SERVICE_REQUEST_COLLECTION,
    Permissions.USE_NEW_DENIALS_WORKFLOW,
    Permissions.HEALTH_HELP_ADMIN_ONLY,
    Permissions.VIEW_CLINICAL_ASSESSMENT_TEST_PAGE,
    Permissions.QM_PILOT,
    Permissions.EDIT_TEXT_HIGHLIGHTS,
    Permissions.VIEW_CONFIGURATIONS,
    Permissions.VIEW_PA_CHECK,
    Permissions.VIEW_LETTERS,
    Permissions.CAN_SWITCH_PA_REFERRAL_PATIENT_SUMMARY,
    Permissions.CORRECT_AUTH_STATUS,
    Permissions.SEND_LETTER_REVIEW_CORRECTION,
  ],

  // Ops roles
  [StandaloneRoles.GENERIC_REVIEWER]: [
    Permissions.VIEW_SERVICE_REQUEST_AUDIT_LOG,
    Permissions.START_AUTH_REQUEST,
    Permissions.EDIT_SERVICE_REQUEST,
    Permissions.EDIT_ATTACHMENTS,
    Permissions.EDIT_CLINICAL_ASSESSMENT,
    Permissions.EDIT_SERVICE_REQUEST_IN_REVIEW_STAGE,
    Permissions.EDIT_APPROVED_SR_PLACE_OF_SERVICE,
    Permissions.VIEW_ATTACHMENT_CLASSIFICATION_SCORE,
    Permissions.VIEW_REVIEW,
    Permissions.VIEW_ALL_WITHDRAWAL_REASONS,
    Permissions.VIEW_OUTREACH_OPPORTUNITIES,
    Permissions.USE_CLINICAL_REVIEW_REDESIGN_GENERAL,
    Permissions.CAN_SKIP_OON,
    Permissions.VIEW_ESCALATED_STATE,
    Permissions.CAN_EDIT_CARE_SETTINGS,
    Permissions.CAN_VIEW_PREVIOUSLY_APPROVED_REQUESTS,
    Permissions.EDIT_NOTES,
    Permissions.EDIT_APPEAL,
    Permissions.VIEW_NOTES,
    Permissions.ADD_PX_PENDING_SERV_REQUEST,
    Permissions.ACCESS_LOG_OUTREACH_PAGE,
    Permissions.EDIT_WITHDRAWAL_REQUESTOR,
    Permissions.ADD_ATTACHMENT_AFTER_TERMINAL_STATUS,
    Permissions.CAN_VIEW_AUTH_STATUS_HISTORY,
    Permissions.VIEW_LETTERS,
    Permissions.VIEW_LETTER_STATUS,
    Permissions.START_APPEAL_LETTER,
  ],
  [UserType.NurseReviewer]: [
    StandaloneRoles.GENERIC_REVIEWER,
    Permissions.EDIT_ATTACHMENT_PRACTICE_VIEWABLE,
    Permissions.DELETE_ATTACHMENT_AFTER_DRAFT,
    Permissions.VIEW_INCOMING_FAXES,
    Permissions.EDIT_NURSE_REVIEW,
    Permissions.VIEW_NURSE_REVIEWER_CASE,
    Permissions.VIEW_LETTER_WRITING_CASE,
    Permissions.EDIT_TEXT_HIGHLIGHTS,
    Permissions.EDIT_OUT_OF_NETWORK_REVIEW,
  ],
  [UserType.MDReviewer]: [
    StandaloneRoles.GENERIC_REVIEWER,
    Permissions.EDIT_MD_REVIEW,
    Permissions.EDIT_P2P_REVIEW,
    Permissions.VIEW_MD_REVIEWER_CASE,
    Permissions.EDIT_TEXT_HIGHLIGHTS,
  ],
  [UserType.FinalReviewAuditor]: [
    Permissions.EDIT_FINALIZED_MD_REVIEW,
    Permissions.PATIENT_FINAL_DETERMINATION_LETTER_DISPLAY,
    Permissions.VIEW_LETTERS,
    Permissions.VIEW_LETTER_STATUS,
  ],
  [UserType.AuditReviewer]: [
    Permissions.AUDIT_REVIEW,
    Permissions.EDIT_TEXT_HIGHLIGHTS,
    Permissions.USE_CLINICAL_REVIEW_REDESIGN_GENERAL,
  ],
  [UserType.ReviewDenialSubmitter]: [Permissions.SUBMIT_DENIAL],
  [UserType.DocTypeandOrderingBetaUsers]: [Permissions.DOC_SEGMENTATION_BETA],
  [UserType.ServiceOpsLeadership]: [
    UserType.ServiceOps,
    Permissions.EDIT_SERVICE_REQUEST_STATUS_AFTER_MANUAL_ENTRY,
    Permissions.VIEW_SET_AUTH_STATUS_OUTREACH_OPPORTUNITIES,
    Permissions.EDIT_HEALTH_HELP_STATUS,
  ],
  [UserType.ServiceOps]: [
    UserType.ServiceOpsGeneral,
    Permissions.ORGANIZATION_MANAGEMENT_PAGE,
    Permissions.INTEGRATION_MANAGEMENT_PAGE,
    Permissions.INTEGRATION_OBSERVABILITY_PAGE,
    Permissions.VIEW_ALL_WITHDRAWAL_REASONS,
    Permissions.EDIT_CHANNEL,
    Permissions.MISSING_INFO,
    Permissions.VIEW_OUTREACH_CASE,
    Permissions.CAN_EDIT_MY_ORGANIZATION,
    Permissions.CAN_REVIEW_AND_EDIT_VERIFIED_USERS,
    Permissions.EDIT_NOTES,
    Permissions.EDIT_APPEAL,
    Permissions.CAN_SWITCH_PA_REFERRAL_PATIENT_SUMMARY,
  ],
  [UserType.ServiceOpsGeneral]: [
    Permissions.EDIT_SERVICE_REQUEST_STATUS,
    Permissions.EDIT_ATTACHMENT_PRACTICE_VIEWABLE,
    Permissions.DELETE_ATTACHMENT_AFTER_DRAFT,
    Permissions.VIEW_INCOMING_FAXES,
    Permissions.VIEW_SERVICE_REQUEST_AUDIT_LOG,
    Permissions.START_AUTH_REQUEST,
    Permissions.EDIT_SERVICE_REQUEST,
    Permissions.EDIT_ATTACHMENTS,
    Permissions.EDIT_CLINICAL_ASSESSMENT,
    Permissions.EDIT_SERVICE_REQUEST_IN_REVIEW_STAGE,
    Permissions.EDIT_APPROVED_SR_PLACE_OF_SERVICE,
    Permissions.VIEW_ATTACHMENT_CLASSIFICATION_SCORE,
    Permissions.VIEW_REVIEW,
    Permissions.EDIT_OUT_OF_NETWORK_REVIEW,
    Permissions.EDIT_HMOEXCEPTION_REVIEW,
    Permissions.PATIENT_MANAGEMENT_PAGE,
    Permissions.REQUESTOR_FORM,
    Permissions.VIEW_PATIENT_SUMMARY_INTEGRATIONS_UI,
    Permissions.VIEW_PATIENT_SUMMARY_NOTIFICATION_UI,
    Permissions.SKIP_CLINICAL_ASSESSMENT,
    Permissions.FINAL_DETERMINATION_LETTER_DISPLAY,
    Permissions.PATIENT_FINAL_DETERMINATION_LETTER_DISPLAY,
    Permissions.VIEW_ALL_WITHDRAWAL_REASONS,
    Permissions.SERVICE_OPS_ICON,
    Permissions.VIEW_OUTREACH_OPPORTUNITIES,
    Permissions.CAN_SKIP_OON,
    Permissions.VIEW_ESCALATED_STATE,
    Permissions.CAN_EDIT_CARE_SETTINGS,
    Permissions.CAN_VIEW_PREVIOUSLY_APPROVED_REQUESTS,
    Permissions.EDIT_NOTES,
    Permissions.EDIT_APPEAL,
    Permissions.VIEW_NOTES,
    Permissions.CAN_SKIP_MANUAL_NPI_TIN,
    Permissions.ACCESS_LOG_OUTREACH_PAGE,
    Permissions.EDIT_WITHDRAWAL_REQUESTOR,
    Permissions.ADD_ATTACHMENT_AFTER_TERMINAL_STATUS,
    Permissions.UPGRADE_REQUESTS_TO_EXPEDITED,
    Permissions.CAN_VIEW_AUTH_STATUS_HISTORY,
    Permissions.POST_DECISION_AUTH_STATUS_UPDATE,
    Permissions.VIEW_LETTERS,
    Permissions.VIEW_LETTER_STATUS,
    Permissions.VIEW_ENHANCED_WITHDRAWAL_MODAL,
    Permissions.CAN_SWITCH_PA_REFERRAL_PATIENT_SUMMARY,
  ],

  [UserType.InternalUserAdmin]: [
    Permissions.ORGANIZATION_MANAGEMENT_PAGE,
    Permissions.CAN_REVIEW_AND_EDIT_UNVERIFIED_USERS,
    Permissions.CAN_EDIT_MY_ORGANIZATION,
    Permissions.CAN_DELETE_MY_ORGANIZATION,
    Permissions.CAN_REVIEW_AND_EDIT_VERIFIED_USERS,
    Permissions.CAN_SWITCH_PA_REFERRAL_PATIENT_SUMMARY,
  ],
  [UserType.PatientCoverageEditor]: [Permissions.EDIT_PATIENT_COVERAGE],
  [UserType.BypassRedirects]: [Permissions.CAN_BYPASS_REDIRECTS],
  [UserType.FeatureFlagEditor]: [Permissions.FEATURE_FLAG_PAGE],
  [UserType.QMPilot]: [Permissions.QM_PILOT, Permissions.VIEW_LETTER_WRITING_CASE, Permissions.VIEW_OUTREACH_CASE],
  [UserType.Assigner]: [
    Permissions.CASE_HISTORY,
    Permissions.VIEW_NURSE_REVIEWER_CASE,
    Permissions.VIEW_MD_REVIEWER_CASE,
    Permissions.VIEW_LETTER_WRITING_CASE,
    Permissions.VIEW_OUTREACH_CASE,
  ],

  // Auth submitter roles
  [UserType.BackOfficeAdmin]: [
    UserType.BackOffice,
    Permissions.MY_ORGANIZATION_PAGE,
    Permissions.CAN_OPT_IN_FOR_E_LETTERS,
    Permissions.VIEW_NEW_EMAIL_VERIFIED_USERS,
    Permissions.CAN_REVIEW_AND_EDIT_UNVERIFIED_USERS,
    Permissions.CAN_EDIT_MY_ORGANIZATION,
    Permissions.CAN_REVIEW_AND_EDIT_VERIFIED_USERS,
    Permissions.CAN_SWITCH_PA_REFERRAL_PATIENT_SUMMARY,
  ],
  [UserType.BackOffice]: [
    Permissions.START_AUTH_REQUEST,
    Permissions.VIEW_MY_ORGANIZATION,
    Permissions.EDIT_SERVICE_REQUEST,
    Permissions.EDIT_ATTACHMENTS,
    Permissions.EDIT_CLINICAL_ASSESSMENT,
    Permissions.FINAL_DETERMINATION_LETTER_DISPLAY,
    Permissions.VIEW_LETTERS,
    Permissions.CAN_SWITCH_PA_REFERRAL_PATIENT_SUMMARY,
  ],

  // Other roles
  [UserType.UMReadOnly]: [UserType.AuthViewOnly, Permissions.VIEW_NOTES, Permissions.CAN_VIEW_AUTH_STATUS_HISTORY],
  [UserType.AuthViewOnly]: [
    Permissions.AUTH_VIEW_ONLY,
    Permissions.VIEW_REVIEW,
    Permissions.FINAL_DETERMINATION_LETTER_DISPLAY,
    Permissions.PATIENT_FINAL_DETERMINATION_LETTER_DISPLAY,
    Permissions.VIEW_OUTREACH_OPPORTUNITIES,
    Permissions.VIEW_LETTERS,
  ],
  [UserType.ViewSrAuditHistory]: [Permissions.VIEW_SERVICE_REQUEST_AUDIT_LOG],
  [UserType.ClinicalContent]: [
    Permissions.VIEW_RULE_ACTION_SOURCE,
    Permissions.VIEW_SET_AUTH_STATUS_OUTREACH_OPPORTUNITIES,
    Permissions.VIEW_ATTACHMENT_ID,
    Permissions.VIEW_CONFIGURATIONS,
  ],
  [UserType.IntegrationViewer]: [
    Permissions.VIEW_RULE_ACTION_SOURCE,
    Permissions.VIEW_SERVICE_REQUEST_INTEGRATION,
    Permissions.VIEW_PATIENT_SUMMARY_INTEGRATIONS_UI,
    Permissions.VIEW_PATIENT_SUMMARY_NOTIFICATION_UI,
    Permissions.FINAL_DETERMINATION_LETTER_DISPLAY,
    Permissions.PATIENT_FINAL_DETERMINATION_LETTER_DISPLAY,
    Permissions.VIEW_SET_AUTH_STATUS_OUTREACH_OPPORTUNITIES,
    Permissions.VIEW_ATTACHMENT_ID,
    Permissions.VIEW_LETTERS,
    Permissions.VIEW_LETTER_STATUS,
  ],
  [UserType.CohereEngOnCall]: [
    UserType.ServiceOps,
    UserType.Assigner,
    UserType.QMManager,
    UserType.ClinicalViewer,
    UserType.InternalUserAdmin,
    UserType.IntegrationViewer,
    UserType.AuthViewOnly,
    UserType.TransferAuthorization,
    UserType.PlaceholderPatientCreator,
    Permissions.VIEW_SERVICE_REQUEST_AUDIT_LOG,
    Permissions.FEATURE_FLAG_PAGE,
    Permissions.START_AUTH_REQUEST,
    Permissions.VIEW_CONFIGURATIONS,
    Permissions.VIEW_PA_CHECK,
    Permissions.VIEW_LETTERS,
    Permissions.CORRECT_AUTH_STATUS,
    Permissions.SEND_LETTER_REVIEW_CORRECTION,
  ],
  [UserType.CoherePm]: [
    UserType.Assigner,
    UserType.QMManager,
    UserType.ClinicalViewer,
    UserType.InternalUserAdmin,
    UserType.IntegrationViewer,
    UserType.AuthViewOnly,
    Permissions.VIEW_SERVICE_REQUEST_AUDIT_LOG,
    Permissions.FEATURE_FLAG_PAGE,
    Permissions.START_AUTH_REQUEST,
    Permissions.VIEW_CONFIGURATIONS,
    Permissions.VIEW_PA_CHECK,
    Permissions.VIEW_LETTERS,
    Permissions.CORRECT_AUTH_STATUS,
    Permissions.SEND_LETTER_REVIEW_CORRECTION,
  ],
  [UserType.TechnicalServices]: [
    UserType.IntegrationViewer,
    Permissions.AUTHORIZE_VIEW_ONLY,
    Permissions.CLINICAL_VIEWER,
    Permissions.VIEW_CONFIGURATIONS,
    Permissions.VIEW_HEALTH_PLAN,
    Permissions.VIEW_PA_CHECK,
    Permissions.VIEW_LETTERS,
    Permissions.CORRECT_AUTH_STATUS,
    Permissions.SEND_LETTER_REVIEW_CORRECTION,
  ],
  [UserType.PlaceholderPatientCreator]: [Permissions.CREATE_PLACEHOLDER_PATIENT],
  [UserType.TransferAuthorization]: [Permissions.TRANSFER_AUTHORIZATION],
});

/**
 * Hook which returns a boolean: whether the given feature is authorized in
 * the current context.
 *
 * NOTE: this will initially return false when first rendered to the DOM
 * because it requires calling an asynchronous function to get the current user's
 * profile to get their user type.
 *
 * NOTE: frontend code is inherently hackable. Don't think
 * that just by guarding behind this hook that your feature can't
 * be hacked. It's imperative that real authorization checks are
 * maintained on the server.
 */
export function useAuthorized(feature: Feature): boolean {
  const userRoles = useUserRoles();

  if (userRoles === undefined || userRoles.length === 0) {
    return false;
  }

  return isAuthorized(feature, userRoles);
}
const isAuthorized = generateAuthorizer<Feature>(PERMISSION_HIERARCHY);

/**
 * Hook which returns a boolean: True if the current signed in user is a member of
 * `opsGroup` (e.g. Geisinger) based on the user's opsGroup Okta claim
 *
 * NOTE: this will initially return false when first rendered to the DOM
 * because it requires calling an asynchronous function to get the user's profile.
 *
 */
export function useBelongsToOpsGroup(opsGroup: string): boolean | undefined {
  const [userOpsGroup, setUserOpsGroup] = useState<string | undefined>();
  const { getUser } = useContext(UserContext);
  useEffect(() => {
    getUser()
      ?.then((user) => {
        setUserOpsGroup(user?.opsGroup);
      })
      .catch((e) => {
        logError(e);
      });
  }, [getUser]);
  return userOpsGroup ? userOpsGroup.toUpperCase() === opsGroup.toUpperCase() : undefined;
}
