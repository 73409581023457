import { ProcedureCodeWithId, today, useFeature, withId } from "@coherehealth/common";
import {
  AuthorizationResponse,
  AuthStatus,
  ClinicalService,
  Patient,
  ProcedureCode,
  PxCodeAttributeConfiguration,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { H4 } from "@coherehealth/design-system";
import { Dialog, Divider, Grid, useTheme } from "@material-ui/core";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import {
  ContinuationConfiguration,
  FormConfiguration,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { NonCohereCodes, procedureBucketDataModel } from "../common";
import { RequestExpedited } from "components/ServiceRequest/ServiceRequestForm/Expedited";
import ProcedureCodeSelector from "components/ServiceRequest/ServiceRequestForm/components/ProcedureCodeSelector";
import ServicesSelector from "components/ServiceRequest/ServiceRequestForm/components/ServicesSelector";
import { getCurrentPrimaryCoverage, getPatientHealthPlanName, getPatientRiskBearingEntity } from "util/patientUtils";
import listReplace from "util/listReplace";
import NonPalPDFProvider from "components/ServiceRequest/ServiceRequestForm/NonPalDocument/NonPalPDFProvider";
import DocumentViewer from "../../DocumentViewer";
import ProceduresBucket, { Props as ProceduresBucketProps } from "./ProceduresBucket";
import { RedirectModalErrorObjectType } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { RedirectRuleCheckFn } from "common/SharedServiceRequestFormComponents";
import { useCheckServiceRequestProcedureCodes } from "../FillForms/ServiceRequestForm";
import DateRangeSelect from "./DateRangeSelect";
import { isEqualWith } from "lodash";
import useSearchAndLocation from "hooks/useSearchAndLocation";
import { SRFormConfigFieldWrapper } from "common/FormConfigUtils";
import { useGetServiceRequestStateConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";
import { getPendingAuthStatuses } from "util/serviceRequest";
import { useAuthorized } from "authorization";

export interface ProcedureCodeBucketsState {
  palProcedureCodes: ProcedureCode[];
  setPalProcedureCodes: (pxs: ProcedureCode[]) => void;
  nonPalProcedureCodes: ProcedureCode[];
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  nonCohereProcedureCodes: NonCohereCodes[];
  setNonCohereProcedureCodes: Dispatch<SetStateAction<NonCohereCodes[]>>;
}

interface Props extends ProcedureCodeBucketsState {
  formConfiguration: FormConfiguration | ContinuationConfiguration;
  pxCodeAttributeConfiguration?: PxCodeAttributeConfiguration;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  onUserEdit?: Dispatch<ServiceRequestFormContent>;
  patient: Patient | null;
  attemptedSubmit: boolean;
  authStatus: AuthStatus;
  workflowId?: string;
  dispatchUrgencyRuleCheck: (isExpedited: boolean) => Promise<void>;
  dispatchRedirectRuleCheck: (errorObjectType: RedirectModalErrorObjectType) => RedirectRuleCheckFn;
  isContinuation?: boolean;
  authorization?: AuthorizationResponse;
  requestType?: string;
  showEndDate: boolean;
  onPatientSummary?: boolean;
  facilityBasedFeatureEnabled?: boolean;
  isUsedInPACheckRedesign?: boolean;
  hasValidStartDate?: boolean;
  startDateErrorMessage?: string;
  hideExpeditedRequestCheckbox?: boolean;
  allowMutablePxCodesWithNonPal?: boolean;
  serviceRequest?: Partial<ServiceRequestResponse>;
  setIsAdditionalUnitsRequested?: Dispatch<SetStateAction<boolean>>;
  allowedPxCodePerPayer?: number;
  showPxBasedAttributedField?: boolean;
  doPalOrCrdCheck?: (payload: { desiredProcedureCodes: ProcedureCode[] }) => Promise<void>;
  showPxAttributeValidation?: boolean;
}

export default function ServicesAndProceduresCard({
  formConfiguration,
  pxCodeAttributeConfiguration,
  formContent,
  setFormContent,
  onUserEdit,
  patient,
  attemptedSubmit,
  authStatus: currAuthStatus,
  workflowId,
  dispatchRedirectRuleCheck,
  dispatchUrgencyRuleCheck,
  /** Procedure Code Buckets */
  palProcedureCodes,
  nonPalProcedureCodes,
  nonCohereProcedureCodes,
  setPalProcedureCodes,
  setNonCohereProcedureCodes,
  setNonPalProcedureCodes,
  isContinuation,
  authorization,
  requestType,
  showEndDate,
  onPatientSummary,
  facilityBasedFeatureEnabled,
  isUsedInPACheckRedesign,
  hasValidStartDate,
  startDateErrorMessage,
  hideExpeditedRequestCheckbox,
  allowMutablePxCodesWithNonPal,
  serviceRequest,
  setIsAdditionalUnitsRequested,
  allowedPxCodePerPayer,
  doPalOrCrdCheck,
  showPxBasedAttributedField,
  showPxAttributeValidation,
}: Props) {
  const { spacing } = useTheme();

  const multipleUnitsHomeHealthFF = useFeature("multipleUnitsHomeHealth");
  const canAddPxPendingSR = useAuthorized("ADD_PX_PENDING_SERV_REQUEST");
  const servicesEditabilityEnabled = useFeature("servicesEditability") && onPatientSummary;

  //State
  const [nonPalPDFOpen, setNonPalPDFOpen] = useState(false);

  const patientHealthPlanName = getPatientHealthPlanName(patient || undefined, formContent.startDate) || "";
  const patientRiskBearingEntity = getPatientRiskBearingEntity(patient || undefined, formContent.startDate) || "";

  const [isPxCodesValid, setIsPxCodeValid] = useState(false);
  const [procedureCodeSelectionActive, setProcedureCodeSelectionActive] = useState(false);
  const [selectedProcedureCode, setSelectedProcedureCode] = useState<ProcedureCode[]>([]);

  const { location, search } = useSearchAndLocation();

  /**
   * This sets the service request form content, but it also runs the onUserEdit callback.
   *
   * This is useful if we want to do something after the user explicitly edits the service request form content, not
   * just whenever the serviceRequestForm content changes (like from a PAR check effect or whatever)
   * @param newFormContent
   */
  const setFormContentOnUserEdit: Dispatch<SetStateAction<ServiceRequestFormContent>> = useCallback(
    (setStateAction) => {
      // In order to get the next state value to pass to onUserEdit, we actually need to call setFormContent
      // because we might in fact have a function, not a realized value
      setFormContent((prev) => {
        const newFormContent: ServiceRequestFormContent =
          typeof setStateAction === "function" ? setStateAction(prev) : setStateAction;
        onUserEdit?.(newFormContent);
        return newFormContent;
      });
    },
    [onUserEdit, setFormContent]
  );

  useCheckServiceRequestProcedureCodes({
    procedureCodes: formContent.procedureCodes,
    palPxCodes: palProcedureCodes,
    isInpatient: formContent.isInpatient,
    dispatchRedirectRuleCheck,
    placeOfServiceId: formContent.placeOfService?.id,
    startDate: formContent.startDate,
    patient: patient || undefined,
    workflowId,
  });

  // Checks if two arrays of ProcedureCode objects are equal based on code and units.
  const compareFormComtentAndServiceRequestPxCode = (
    formContectPxCode: ProcedureCode[],
    serviceRequestPxCode: ProcedureCode[]
  ) => {
    if (formContectPxCode?.length !== serviceRequestPxCode?.length) {
      return false;
    }
    if (
      formContectPxCode?.every((code) =>
        serviceRequestPxCode?.some(
          (code2) => code?.code === code2?.code && Number(code?.units) === Number(code2?.units)
        )
      )
    ) {
      return true;
    }
    return false;
  };

  // Function to determine if formContent's ProcedureCodes match serviceRequest's semanticProcedureCodes.
  const isFormContentMatchingServiceRequestPxCode = useMemo(() => {
    const isUnitsAndPxCodeEqual = isEqualWith(
      formContent?.procedureCodes,
      serviceRequest?.semanticProcedureCodes,
      compareFormComtentAndServiceRequestPxCode
    );
    return isUnitsAndPxCodeEqual;
  }, [formContent, serviceRequest]);

  const [servicesFormContents, setServiceFormContents] = useState<ServiceRequestFormContent[]>(
    createFormContentForEachGroup({
      procedureCodesAndGroups: procedureBucketDataModel(
        formContent?.procedureCodes?.map(withId),
        isUsedInPACheckRedesign ? palProcedureCodes : undefined
      ),
      clinicalServices: formContent.clinicalServices || [],
      isInpatient: formContent.isInpatient,
      startDate: formContent.startDate,
      approvedUnits: formContent.approvedUnits,
      isExpedited: formContent.isExpedited,
      expeditedReason: formContent.expeditedReason,
      userSelectedNonPalCode: formContent.userSelectedNonPalCode || false,
      isContinuation: isContinuation || false,
      userSelectedOONException: formContent.userSelectedOONException || false,
      authorization: authorization,
      id: formContent.id,
      cohereId: formContent.cohereId,
    })
  );

  const updateFormContentFromBucketedContents = useCallback(
    (updatedServicesFormContents: ServiceRequestFormContent[]) => {
      const aggregateProcedureCodes: ProcedureCode[] = [];
      let totalUnits = 0;
      updatedServicesFormContents.forEach((group) => {
        aggregateProcedureCodes.push(...group.procedureCodes);
        totalUnits += +group.units;
      });
      const valid = aggregateProcedureCodes.some((pxCode) => !!pxCode.units);
      setIsPxCodeValid(facilityBasedFeatureEnabled && formContent.isInpatient ? true : valid);
      //TODO: temporary solution to be able to support mutiple units for "Home Health" clinical service
      if (multipleUnitsHomeHealthFF) {
        const homeHealthService = formContent.clinicalServices?.find((clinicalService) => {
          return clinicalService.name === "Home Health";
        });

        if (homeHealthService) {
          const g0156Procedure = aggregateProcedureCodes?.find((px) => {
            return px.code === "G0156" && px.groupId === homeHealthService?.id;
          });

          if (!!g0156Procedure && g0156Procedure.unitType !== "Number of visits") {
            g0156Procedure.unitType = "Number of visits";
          }
        }
      }

      setFormContentOnUserEdit((prev) => {
        // filter for nonpalProcedureCode from formContent.procedurecode as aggregateNonPalPxCodes
        // combine aggregateNonPalPxCodes and aggregatePalProcedureCodes as allPxCode and updateFormContent with the new list
        let allPxCodes: ProcedureCode[] = [];
        if (allowMutablePxCodesWithNonPal) {
          allPxCodes = findUpdatedPxCodeList(prev.procedureCodes, nonPalProcedureCodes, aggregateProcedureCodes);
        } else {
          allPxCodes = aggregateProcedureCodes;
        }

        return formContent.isInpatient
          ? {
              ...prev,
              procedureCodes: allPxCodes,
            }
          : {
              ...prev,
              procedureCodes: allPxCodes,
              units: !isFormContentMatchingServiceRequestPxCode ? totalUnits.toString() : prev.units,
            };
      });
    },
    [
      setFormContentOnUserEdit,
      setIsPxCodeValid,
      facilityBasedFeatureEnabled,
      formContent.isInpatient,
      formContent.clinicalServices,
      multipleUnitsHomeHealthFF,
      nonPalProcedureCodes,
      allowMutablePxCodesWithNonPal,
      isFormContentMatchingServiceRequestPxCode,
    ]
  );

  useEffect(() => {
    if (servicesFormContents) {
      updateFormContentFromBucketedContents(servicesFormContents);
    }
  }, [servicesFormContents, updateFormContentFromBucketedContents]);

  const setProcedureCodes = useCallback(
    ({
      setStateAction,
      content,
      index,
    }: {
      setStateAction: SetStateAction<ProcedureCodeWithId[]>;
      content: ServiceRequestFormContent;
      index: number;
    }) => {
      const updatedProcedureCodes: ProcedureCodeWithId[] =
        typeof setStateAction === "function"
          ? setStateAction(content.procedureCodes?.map(withId) || [])
          : setStateAction;
      if (updatedProcedureCodes.length === 0) {
        setServiceFormContents((prev) => prev.filter((_, idx) => idx !== index));
      } else {
        const updatedContentForBucket: ServiceRequestFormContent = {
          ...content,
          units: updatedProcedureCodes.length === 0 ? "0" : content.units,
          procedureCodes: updatedProcedureCodes,
        };
        setServiceFormContents((prev) => listReplace(prev, index, updatedContentForBucket));
      }
    },
    []
  );

  const setUnits = useCallback(
    ({
      setStateAction,
      content,
      index,
    }: {
      setStateAction: SetStateAction<string>;
      content: ServiceRequestFormContent;
      index: number;
    }) => {
      const updatedUnits: string =
        typeof setStateAction === "function" ? setStateAction(content.units) : setStateAction;
      const updatedContentForBucket: ServiceRequestFormContent = {
        ...content,
        units: updatedUnits,
      };
      setServiceFormContents((prev) => listReplace(prev, index, updatedContentForBucket));
    },
    []
  );

  const showExpedited =
    ((formContent?.isInpatient && facilityBasedFeatureEnabled) ||
      (formContent?.startDate && formContent?.startDate >= today())) &&
    formConfiguration.urgency.fieldSpec !== "OMIT" &&
    !hideExpeditedRequestCheckbox;
  const { isFieldDisabled } =
    useGetServiceRequestStateConfigurationByPayerAndAuthStatus({
      healthPlanName: patientHealthPlanName,
      authStatus: currAuthStatus,
      delegatedVendorName:
        serviceRequest?.delegatedVendor || (currAuthStatus === "DRAFT" ? patientRiskBearingEntity : undefined) || "",
      requestType: isContinuation ? "CONTINUATION" : "INITIAL",
    }) || {};

  const totalProcedureCount = servicesFormContents?.reduce((total, value) => total + value.procedureCodes.length, 0);
  const canAddMoreProcedureCodes = Boolean(
    totalProcedureCount && allowedPxCodePerPayer && totalProcedureCount < allowedPxCodePerPayer
  );
  const isPxCodeLimitExceeded = Boolean(
    allowedPxCodePerPayer &&
      allowedPxCodePerPayer > 0 &&
      totalProcedureCount &&
      totalProcedureCount > allowedPxCodePerPayer
  );

  useEffect(() => {
    //if we don't show the expedited checkbox, isExpedited should be set to false
    if (!onPatientSummary && !showExpedited && (formContent.isExpedited || formContent.expeditedReason !== "")) {
      setFormContent({
        ...formContent,
        isExpedited: false,
        expeditedReason: "",
      });
    }
  }, [
    showExpedited,
    formContent.isExpedited,
    formContent.expeditedReason,
    setFormContent,
    formContent,
    onPatientSummary,
  ]);

  if (formContent.isInpatient && !servicesFormContents.length) {
    return null;
  }

  return (
    <>
      <Grid container>
        {!isUsedInPACheckRedesign && (
          <Grid item xs={12}>
            <H4 style={{ marginBottom: 16 }} data-public>
              Services
            </H4>
          </Grid>
        )}
        <DateRangeSelect
          formConfiguration={formConfiguration}
          formContent={formContent}
          setFormContent={setFormContent}
          onUserEdit={onUserEdit}
          patient={patient}
          attemptedSubmit={attemptedSubmit}
          showEndDate={showEndDate}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
          hasValidStartDate={hasValidStartDate}
          startDateErrorMessage={startDateErrorMessage}
          isContinuation={isContinuation}
          authorization={authorization}
          dateRangeDisabled={isFieldDisabled("startEndDate")}
          stayDaysDisabled={isFieldDisabled("admissionDischargeDate")}
        />

        {servicesFormContents.map((content, index) => (
          <ProcedureCodeGroupWrapper
            key={index}
            formContent={{ ...content, isInpatient: formContent.isInpatient }}
            formConfiguration={formConfiguration}
            pxCodeAttributeConfiguration={pxCodeAttributeConfiguration}
            servicesFormContents={servicesFormContents}
            isFormContentMatchingServiceRequestPxCode={isFormContentMatchingServiceRequestPxCode}
            clinicalService={content.clinicalService}
            procedureCodes={content.procedureCodes?.map(withId)}
            setProcedureCodes={(setStateAction: SetStateAction<ProcedureCodeWithId[]>) =>
              setProcedureCodes({ setStateAction, content, index })
            }
            setUnits={(setStateAction: SetStateAction<string>) => setUnits({ setStateAction, content, index })}
            attemptedSubmit={attemptedSubmit}
            patient={patient}
            setNonPalPDFOpen={setNonPalPDFOpen}
            setNonPalProcedureCodes={setNonPalProcedureCodes}
            nonPalProcedureCodes={nonPalProcedureCodes}
            nonCohereProcedureCodes={nonCohereProcedureCodes}
            setNonCohereProcedureCodes={setNonCohereProcedureCodes}
            palProcedureCodes={palProcedureCodes}
            setPalProcedureCodes={setPalProcedureCodes}
            requestType={requestType}
            authorization={authorization}
            authStatus={currAuthStatus}
            isPxCodesValid={isPxCodesValid}
            onPatientSummary={onPatientSummary}
            isUsedInPACheckRedesign={isUsedInPACheckRedesign}
            lastElement={index === servicesFormContents.length - 1}
            showExpedited={showExpedited}
            setIsAdditionalUnitsRequested={setIsAdditionalUnitsRequested}
            allowedPxCodePerPayer={allowedPxCodePerPayer}
            doPalOrCrdCheck={doPalOrCrdCheck}
            showPxBasedAttributedField={showPxBasedAttributedField}
            showPxAttributeValidation={showPxAttributeValidation}
          />
        ))}
        {(!onPatientSummary || (getPendingAuthStatuses().includes(currAuthStatus || "") && canAddPxPendingSR)) &&
          servicesEditabilityEnabled &&
          !isFieldDisabled("procedureCodes") && (
            <ProcedureCodeSelector
              formContent={formContent}
              canAddMoreProcedureCodes={canAddMoreProcedureCodes && !isPxCodeLimitExceeded}
              allowedPxCodePerPayer={allowedPxCodePerPayer}
              palProcedureCodes={palProcedureCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              setNonPalPDFOpen={setNonPalPDFOpen}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereProcedureCodes={nonCohereProcedureCodes}
              setNonCohereProcedureCodes={setNonCohereProcedureCodes}
              procedureCodeSelectionActive={procedureCodeSelectionActive}
              setProcedureCodeSelectionActive={setProcedureCodeSelectionActive}
              procedureCodes={selectedProcedureCode}
              setProcedureCodes={(codes) => setSelectedProcedureCode(codes)}
              patientId={patient?.id}
              healthPlanName={patientHealthPlanName}
              attemptedSubmit={attemptedSubmit}
              error={formContent.isInpatient ? formContent.procedureCodes.length < 0 : !isPxCodesValid}
              isPxCodesValid={isPxCodesValid}
              onPatientSummary={onPatientSummary}
            />
          )}
        {serviceRequest && (
          <ServicesSelector
            selectedProcedureCodes={selectedProcedureCode}
            setSelectedProcedureCodes={setSelectedProcedureCode}
            serviceRequest={serviceRequest}
            servicesFormContents={servicesFormContents}
            setServiceFormContent={setServiceFormContents}
            requestType={requestType}
            formContent={formContent}
            nonPalProcedureCodes={nonPalProcedureCodes}
          />
        )}
        {!hideExpeditedRequestCheckbox &&
          formContent?.startDate &&
          (formContent?.startDate >= today() || (facilityBasedFeatureEnabled && formContent?.isInpatient)) && (
            <>
              <SRFormConfigFieldWrapper {...formConfiguration.urgency}>
                <Grid item xs={8}>
                  <div style={{ marginTop: spacing(3), paddingBottom: 0 }}>
                    {currAuthStatus === "DRAFT" && (
                      <RequestExpedited
                        isExpedited={formContent?.isExpedited}
                        setIsExpedited={(isExpedited: boolean, reason: string) => {
                          dispatchUrgencyRuleCheck(isExpedited);
                          setFormContentOnUserEdit({
                            ...formContent,
                            isExpedited: isExpedited,
                            expeditedReason: reason,
                          });
                        }}
                        authStatus={currAuthStatus}
                        startDate={formContent?.startDate}
                        patientId={patient?.id}
                        clinicalServiceId={formContent?.clinicalService?.id}
                        patientHealthPlanName={patientHealthPlanName || undefined}
                        disabled={isFieldDisabled("urgency")}
                      />
                    )}
                  </div>
                </Grid>
              </SRFormConfigFieldWrapper>
            </>
          )}
      </Grid>
      <Dialog fullScreen open={nonPalPDFOpen} onClose={() => setNonPalPDFOpen(false)}>
        <NonPalPDFProvider
          patient={patient || undefined}
          nonPalProcedureCodes={nonPalProcedureCodes}
          coverage={getCurrentPrimaryCoverage(patient || undefined) || undefined}
        >
          {({ url, loading }) => (
            <DocumentViewer
              documentInfo={{
                name: `CohereAuthCheck_${patient?.memberId}.pdf`,
                contentType: "application/pdf",
              }}
              loading={loading}
              url={url || undefined}
              handleClose={() => setNonPalPDFOpen(false)}
              canDelete={false}
              location={location}
              search={search}
            />
          )}
        </NonPalPDFProvider>
      </Dialog>
    </>
  );
}

/**
 * Creating form contents for each based on grouped procedure codes
 * @param procedureCodesAndGroups Map of procedure codes to groupId
 * @param clinicalServices List of clinicalServices associated with service request
 * @param isInpatient Boolean representation of encounterType
 * @param startDate Service request start date
 * @param approvedUnits Previously approved units
 * @param isExpedited Expedited status of this service request
 * @param expeditedReason  Expedited reason of this service request
 * @param userSelectedNonPalCode Boolean representation of non pal code selection
 * @param isContinuation Boolean representation if this service request is a continuation
 * @param userSelectedOONException Boolean representation of oon exception selection
 * @param authorization authorization object for specific service request
 * @param authStatus authStatus of request
 * @param id the service request id or undefined
 * @param cohereId the service request cohereId or undefined
 */
export const createFormContentForEachGroup = ({
  procedureCodesAndGroups,
  clinicalServices,
  isInpatient,
  startDate,
  approvedUnits,
  isExpedited,
  expeditedReason,
  userSelectedNonPalCode,
  isContinuation,
  userSelectedOONException,
  authorization,
  authStatus,
  id,
  cohereId,
}: {
  procedureCodesAndGroups: Map<string, ProcedureCodeWithId[]>;
  clinicalServices: ClinicalService[];
  isInpatient: boolean;
  startDate: Date;
  approvedUnits: string;
  isExpedited: boolean;
  expeditedReason: string;
  userSelectedNonPalCode: boolean;
  isContinuation: boolean;
  userSelectedOONException: boolean;
  authorization?: AuthorizationResponse;
  authStatus?: AuthStatus;
  id?: string;
  cohereId?: string;
}) => {
  let formContentGroups: ServiceRequestFormContent[] = [];
  let keys = Array.from(procedureCodesAndGroups.keys());
  const definePxCodesWithNoApprovedUnits = [
    "DRAFT",
    "PENDING_FACILITY_VERIFICATION",
    "PENDING_EXTERNAL_DETERMINATION",
    "INTAKE",
    "PENDING",
    "PENDING_ASSESSMENT",
  ].includes(authStatus || "");
  keys.forEach((cs) => {
    const clinicalServiceForGroup = clinicalServices?.find((clinServ) => clinServ.id === cs) || undefined;
    const isUnitsOnPx = clinicalServiceForGroup ? !!clinicalServiceForGroup?.isUnitsOnPx : true;
    // If isUnitsOnPx get sum of group, else get max of group
    const previouslyApprovedUnits = isUnitsOnPx
      ? procedureCodesAndGroups.get(cs)?.reduce((a, b) => a + (b.approvedUnits ? b.approvedUnits : 0), 0)
      : procedureCodesAndGroups
          .get(cs)
          ?.reduce((a, b) => (a > (b.approvedUnits ? b.approvedUnits : 0) ? a : b?.approvedUnits || 0), 0);

    const units = isUnitsOnPx
      ? procedureCodesAndGroups.get(cs)?.reduce((a, b) => a + (b.units ? b.units : 0), 0)
      : procedureCodesAndGroups
          .get(cs)
          ?.reduce(
            (a, b) => (a > (b.units ? b.units : 0) ? a : b?.units || 0),
            clinicalServiceForGroup?.defaultUnits || 0
          );

    const prevUnits = isContinuation
      ? (units && units !== 0 ? units : "").toString()
      : (units && units !== 0 ? units : clinicalServiceForGroup?.defaultUnits || 1).toString();

    const prevApprovedUnits = (previouslyApprovedUnits || approvedUnits || 0).toString();
    formContentGroups.push({
      units: prevUnits,
      clinicalService: clinicalServices?.find((clinServ) => clinServ.id === cs) || undefined,
      procedureCodes: definePxCodesWithNoApprovedUnits
        ? procedureCodesAndGroups.get(cs)?.map((px) => {
            return { ...px, approvedUnits: undefined };
          }) || []
        : procedureCodesAndGroups.get(cs)?.map((px) => {
            return { ...px };
          }) || [],
      unitType: clinicalServiceForGroup?.defaultUnitType,
      isInpatient,
      startDate,
      approvedUnits: prevApprovedUnits,
      isExpedited,
      expeditedReason,
      userSelectedNonPalCode,
      userSelectedOONException,
      id,
      cohereId,
    });
  });
  let removedServices: Set<ClinicalService> = new Set<ClinicalService>();
  if (authorization) {
    let services = authorization?.clinicalServices?.filter((s): s is ClinicalService => !keys.find((k) => k === s?.id));
    services?.forEach((x) => {
      removedServices.add(x);
    });
  }
  removedServices.forEach((service) => {
    formContentGroups.push({
      units: "",
      clinicalService: service,
      procedureCodes: [],
      unitType: service?.defaultUnitType,
      isInpatient,
      startDate,
      approvedUnits: "",
      isExpedited,
      expeditedReason,
      userSelectedNonPalCode,
      userSelectedOONException,
      id,
      cohereId,
    });
  });
  if (
    authorization?.semanticProcedureCodes?.find((code) => code.groupBy === "Unclassified" || code.groupId === "") !==
      undefined &&
    !procedureCodesAndGroups.has("Uncategorized Service")
  ) {
    formContentGroups.push({
      units: "",
      clinicalService: undefined,
      procedureCodes: [],
      unitType: undefined,
      isInpatient,
      startDate,
      approvedUnits: "",
      isExpedited,
      expeditedReason,
      userSelectedNonPalCode,
      userSelectedOONException,
      id,
      cohereId,
    });
  }

  return formContentGroups.sort((a, b) =>
    (a.clinicalService?.id || "Uncategorized Service").localeCompare(b.clinicalService?.id || "Uncategorized Service")
  );
};

function ProcedureCodeGroupWrapper(props: ProceduresBucketProps) {
  const { spacing } = useTheme();
  return (
    <>
      <ProceduresBucket {...props} />
      {(!props.lastElement || props.showExpedited) && (
        <Grid item xs={12}>
          <Divider style={{ marginTop: spacing(2) }} />
        </Grid>
      )}
    </>
  );
}

/*
@param procedureCodeInForm - corresponds to pxCodes currently available in form pre-update
@param nonPalProcedureCodes - contains API response with info which procedureCodes are classified as nonPal
@parm aggregateNonPalProcedureCodes - contains PalProcedures codes only with updated units and removed codes
*/
export const findUpdatedPxCodeList = (
  procedureCodeInForm: ProcedureCode[],
  nonPalProcedureCodes: ProcedureCode[],
  aggregateNonPalProcedureCodes: ProcedureCode[]
) => {
  let allPxCodes = [];
  let aggregateNonPalPxCodes: ProcedureCode[] = [];
  procedureCodeInForm.forEach((pxCode) => {
    const isNonPalPxCode = nonPalProcedureCodes.some((nonPalPxcode) => pxCode.code === nonPalPxcode.code);
    if (isNonPalPxCode) {
      aggregateNonPalPxCodes.push(pxCode);
    }
  });
  //aggregateProcedureCodes only contains PalCodes in PatientStayAndServicesCard page (during Auth Buider flow)
  allPxCodes = aggregateNonPalPxCodes.concat(aggregateNonPalProcedureCodes);

  return allPxCodes;
};
