import { createContext, useState } from "react";
import { H5, SingleSelectDropdown, TextField, DateTextField, RadioGroup } from "@coherehealth/common";
import { makeStyles, Grid, Divider } from "@material-ui/core";

import { MissingInfoForm, MissingInfoFormForReferral, OutOfScopeForm } from "./ReasonForNotice";
import FaxMetaDataDisplay from "../FaxMetaDataDisplay";
import {
  CommonFaxSidebarViewProps,
  FaxNoticeMissingInformation,
  ReasonForNoticeOptions,
  FaxNoticeOutOfScopeInformation,
  backToMenuView,
  FaxbackRequestType,
} from "../common";
import SendFaxModal from "./SendFaxModal";
import CommonFaxFooter from "../CommonFaxFooter";
import { Caption } from "@coherehealth/design-system";
import { useGetFaxIntakeConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { translateServiceCaseHealthPlan } from "util/serviceCaseUtils";

interface FaxNoticeProps {
  autofilledInfo?: boolean; //this is a placeholder for OCR input
}

const reasonForNoticeDropdownOptions: { id: ReasonForNoticeOptions; label: string }[] = [
  { id: "MISSING_INFO", label: "Missing information" },
  { id: "OUT_OF_SCOPE", label: "Outside of Cohere scope" },
];

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    marginTop: "auto",
    marginLeft: 1,
    marginBottom: theme.spacing(3),
    width: "100%",
  },
  leftButton: {
    alignItems: "flex-start",
    margin: "0px 8px 0px 12px",
  },
  rightButton: {
    minWidth: theme.spacing(25),
    margin: "0px 12px 0px 8px",
  },
  faxNoticeContainer: {
    paddingBottom: theme.spacing(15.5),
  },
  divider: {
    margin: theme.spacing(3, 0, 1, 0),
  },
}));

export const FaxIdAndIsCohereTemplateContext = createContext<
  | {
      faxId: string | undefined;
      isCohereTemplate: boolean | undefined;
    }
  | undefined
>(undefined);

export default function CreateFaxNotice({
  setSidebarView,
  fileData,
  autofilledInfo,
  serviceCase,
}: CommonFaxSidebarViewProps & FaxNoticeProps) {
  const [reasonForNotice, setReasonForNotice] = useState<string>();
  const [serviceType, setServiceType] = useState<FaxbackRequestType>("SERVICE_REQUEST");

  const [patientName, setPatientName] = useState("");
  const [dateRequested, setDateRequested] = useState("");
  const [sendFaxModalOpen, setSendFaxModalOpen] = useState(false);

  const classes = useStyles();

  const { data: faxIntakeConfiguration } = useGetFaxIntakeConfigurationByPayer(
    translateServiceCaseHealthPlan(serviceCase?.healthPlan) ?? ""
  );
  const isReferralEnabled = faxIntakeConfiguration?.referralsCreationFromFaxEnabled;

  const blankMissingInformation: FaxNoticeMissingInformation = {
    patientInfoMissing: false,
    practiceAuthContactMissing: false,
    diagnosisInfoMissing: false,
    servicesRequestedInfoMissing: false,
    orderingPhysicianInfoMissing: false,
    facilityInfoMissing: false,
    additionalNotes: undefined,
  };

  const blankOutOfScopeInformation: FaxNoticeOutOfScopeInformation = {
    ineligiblePatient: false,
    ineligibleProvider: false,
    mustBePCPSubmitted: false,
    additionalNotes: undefined,
  };

  const [missingInformation, setMissingInformation] = useState<FaxNoticeMissingInformation>(blankMissingInformation);

  const [outOfScopeInformation, setOutOfScopeInformation] =
    useState<FaxNoticeOutOfScopeInformation>(blankOutOfScopeInformation);

  const clearInformation = () => {
    setReasonForNotice(undefined);
    setPatientName("");
    setDateRequested("");
    setMissingInformation(blankMissingInformation);
    setOutOfScopeInformation(blankOutOfScopeInformation);
  };

  const clearMissingAndOutOfScopeInformation = () => {
    setMissingInformation(blankMissingInformation);
    setOutOfScopeInformation(blankOutOfScopeInformation);
  };

  const ableToSend =
    reasonForNotice &&
    (reasonForNotice === "MISSING_INFO"
      ? isAbleToSendMissingInfoFax(missingInformation)
      : isAbleToSendOutOfScopeFax(outOfScopeInformation));

  return (
    <div className={classes.container}>
      <FaxMetaDataDisplay fileData={fileData} serviceCase={serviceCase} />
      <Grid container spacing={2} className={classes.faxNoticeContainer}>
        {autofilledInfo && (
          <Grid item xs={12}>
            <div>Possibly missing import information div goes here</div>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <H5 data-public>Fax notice</H5>
        </Grid>
        <Grid item xs={12}>
          <SingleSelectDropdown
            label="Reason for notice"
            fullWidth
            options={reasonForNoticeDropdownOptions}
            value={reasonForNotice}
            onChange={(val) => {
              assertIsValidReason(val);
              setReasonForNotice(val);
            }}
            dataPublic
          />
        </Grid>
        {reasonForNotice && (
          <>
            <Grid item xs={12}>
              <TextField
                label="Patient name"
                fullWidth
                onChangeValue={(name) => {
                  setPatientName(name);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTextField
                label="Start date requested"
                fullWidth
                onChangeValue={(date) => {
                  setDateRequested(date);
                }}
              />
            </Grid>
            {reasonForNotice === "MISSING_INFO" && isReferralEnabled && (
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Caption>Request type</Caption>
                <RadioGroup
                  style={{ marginTop: -8 }}
                  row
                  options={[
                    { label: "Authorization", id: "SERVICE_REQUEST" },
                    { label: "Referral", id: "REFERRAL_REQUEST" },
                  ]}
                  value={serviceType}
                  onChange={(state) => {
                    setServiceType(state as FaxbackRequestType);
                    clearMissingAndOutOfScopeInformation();
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <div className={classes.checkboxContainer}>
                {reasonForNotice === "MISSING_INFO" ? (
                  serviceType === "SERVICE_REQUEST" ? (
                    <MissingInfoForm
                      missingInformation={missingInformation}
                      setMissingInformation={setMissingInformation}
                    />
                  ) : (
                    <MissingInfoFormForReferral
                      missingInformation={missingInformation}
                      setMissingInformation={setMissingInformation}
                    />
                  )
                ) : (
                  <OutOfScopeForm
                    outOfScopeInformation={outOfScopeInformation}
                    setOutOfScopeInformation={setOutOfScopeInformation}
                  />
                )}
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <CommonFaxFooter
        onPrimaryButtonClick={() => setSendFaxModalOpen(true)}
        disabledFinish={!ableToSend}
        showBackButton={false}
        onCancelButtonClick={() => {
          setSidebarView(backToMenuView);
          clearInformation();
        }}
        setSidebarView={setSidebarView}
      />
      <FaxIdAndIsCohereTemplateContext.Provider
        value={{
          faxId: fileData.id,
          isCohereTemplate:
            fileData.usesCohereTemplate === undefined ? undefined : fileData.usesCohereTemplate === "Yes",
        }}
      >
        <SendFaxModal
          faxbackRequestType={serviceType}
          reasonForNotice={reasonForNotice}
          missingInformation={missingInformation}
          outOfScopeInformation={outOfScopeInformation}
          patientName={patientName}
          dateRequested={dateRequested}
          open={sendFaxModalOpen}
          onClose={() => setSendFaxModalOpen(false)}
          setModalOpen={setSendFaxModalOpen}
          setSidebarView={setSidebarView}
        />
      </FaxIdAndIsCohereTemplateContext.Provider>
    </div>
  );
}

function assertIsValidReason(reason: string): asserts reason is ReasonForNoticeOptions {
  if (!["MISSING_INFO", "OUT_OF_SCOPE"].includes(reason)) {
    throw new Error("Invalid reason for notice");
  }
}

function isAbleToSendMissingInfoFax(missingInformation: FaxNoticeMissingInformation) {
  return (
    missingInformation.diagnosisInfoMissing ||
    missingInformation.facilityInfoMissing ||
    missingInformation.orderingPhysicianInfoMissing ||
    missingInformation.patientInfoMissing ||
    missingInformation.practiceAuthContactMissing ||
    missingInformation.servicesRequestedInfoMissing ||
    missingInformation.referringProviderInfoMissing ||
    missingInformation.specialtyMissing ||
    missingInformation.specialistInfoMissing ||
    missingInformation.specialistPracticeInfoMissing ||
    missingInformation.additionalNotes
  );
}

function isAbleToSendOutOfScopeFax(outOfScopeInformation: FaxNoticeOutOfScopeInformation) {
  return (
    outOfScopeInformation.ineligiblePatient ||
    outOfScopeInformation.ineligibleProvider ||
    outOfScopeInformation.mustBePCPSubmitted ||
    outOfScopeInformation.additionalNotes
  );
}
