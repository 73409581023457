/* Generated by restful-react */

import React from "react";
import {
  Get,
  GetProps,
  useGet,
  UseGetProps,
  Mutate,
  MutateProps,
  useMutate,
  UseMutateProps,
} from "restful-react";
export const SPEC_VERSION = "1.0.0";
/**
 * Shared fields amongst all stampable objects
 */
export interface StampedObject {
  dateCreated?: string;
  lastUpdated?: string;
  createdBy?: string;
  updatedBy?: string;
}

export type ServiceCase = StampedObject & {
  id?: string;
  deleted?: boolean;
  caseNumber?: string;
  caseStatus?: CaseStatus;
  casePriority?: CasePriority;
  caseType?: CaseType;
  expedited?: boolean;
  lastReviewedBy?: string;
  description?: string;
  dueDate?: string;
  dueDateAsLong?: number;
  managedBySF?: boolean;
  salesforceId?: string;
  healthPlan?: string;
  faxDetail?: FaxDetail;
  faxAttachmentId?: string;
  caseLabels?: string[];
  outcome?: CaseOutcome;
  serviceRequest?: ServiceRequest;
  requiredSkills?: string[];
  assignee?: Assignee;
  isPostDenialP2P?: boolean;
  isEscalated?: boolean;
  externalSources?: ExternalSource[];
  specialty?: string;
  hasNewAttachments?: boolean;
  delegatedVendor?: DelegatedVendor;
  caseHolds?: CaseHold[];
  activeCaseHold?: CaseHold;
};

export interface CaseHold {
  reason?: string;
  otherReason?: string;
  heldBy?: string;
  heldAt?: string;
  removedBy?: string;
  removedAt?: string;
  isDismissed?: boolean;
  status?: CaseHoldStatus;
  expiry?: string;
}

export type CaseHoldStatus = "ACTIVE" | "INACTIVE";

export type FacilityCategory = "INPATIENT" | "OUTPATIENT";

export type RequestTiming = "PRE_SERVICE" | "CONCURRENT" | "POST_SERVICE";

export interface ExternalSource {
  source?: string;
  externalReferenceId?: string;
}

export interface Assignee {
  name?: string;
  userName?: string;
  salesforceId?: string;
}

export type CaseType =
  | "FAX_INTAKE"
  | "RN_REVIEW"
  | "OUT_OF_NETWORK_REVIEW"
  | "MD_REVIEW"
  | "P2P_REVIEW"
  | "NEW_HIRE_AUDIT_REVIEW"
  | "CPP_REVIEW"
  | "LETTER_WRITING"
  | "NUDGE_OUTREACH"
  | "MISSING_INFORMATION"
  | "SCHEDULING_OUTREACH"
  | "OUT_OF_SCOPE_AUDIT_REVIEW"
  | "FAX_AUDIT_REVIEW"
  | "REQUIRES_VERBAL_NOTIFICATIONS";

export type DelegatedVendor = "COHERE" | "GEISINGER" | "OAK_STREET_HEALTH";

export type AssignmentMethod =
  | "AUTO_ASSIGNMENT"
  | "MANUAL_ASSIGNMENT"
  | "VIEW_ONLY_PAGE";

export interface AssignmentMethodDetails {
  assignmentMethod?: AssignmentMethod;
  prioritizationStep?: string;
}

export interface ServiceRequest {
  serviceRequestId?: string;
  cohereId?: string;
  authNumber?: string;
  tatStartTimestamp?: string;
  tatDueTimestamp?: string;
  tatDueTimestampAsLong?: number;
  authDecisionGroup?: string;
  semanticProcedureCodes?: ProcedureCode[];
  reconClaim?: boolean;
  startDate?: string;
  endDate?: string;
  lastUpdated?: string;
  stateOfIssue?: string;
  isCommercial?: boolean;
  isMedicare?: boolean;
  isMedicaid?: boolean;
  lastSuccessfulOutreachAttempt?: LastSuccessfulOutreachAttempt;
  lastSuccessfulMICOutreachAttempt?: LastSuccessfulMICOutreachAttempt;
  lastSuccessfulOutreachAttemptMap?: {
    [key: string]: LastSuccessfulOutreachAttempt;
  };
  nextReviewDateTimestamp?: string;
  facilityNpi?: string;
  facilityName?: string;
  requestType?: RequestType;
  encounterType?: FacilityCategory;
  requestTiming?: RequestTiming;
  requestClassification?: RequestTiming;
  authCategory?: AuthCategory;
  authSubcategory?: string;
  patientStatus?: PatientStatus;
  patientName?: string;
  lineOfBusinessType?: string;
}

/**
 * Used to describe and categorize auths, typically when these auths have no procedure codes
 */
export type AuthCategory =
  | "MEDICAL_CARE"
  | "SURGICAL"
  | "CONSULTATION"
  | "DIAGNOSTIC_XRAY"
  | "DIAGNOSTIC_LAB"
  | "RADIATION_THERAPY"
  | "ANESTHESIA"
  | "SURGICAL_ASSISTANCE"
  | "BLOOD"
  | "DURABLE_MEDICAL_EQUIPMENT_USED"
  | "DURABLE_MEDICAL_EQUIPMENT_PURCHASED"
  | "RENAL_SUPPLIES"
  | "PREADMISSION_TESTING"
  | "DURABLE_MEDICAL_EQUIPMENT_RENTAL"
  | "PNEUMONIA_VACCINE"
  | "SECOND_SURGICAL_OPINION"
  | "THIRD_SURGICAL_OPINION"
  | "SOCIAL_WORK"
  | "DIAGNOSTIC_DENTAL"
  | "PERIODONTICS"
  | "RESTORATIVE"
  | "ENDODONTICS"
  | "MAXILLOFACIAL_PROSTHETICS"
  | "ADJUNCTIVE_DENTAL_SERVICES"
  | "CHIROPRACTIC"
  | "DENTAL_CARE"
  | "DENTAL_CROWNS"
  | "ORTHODONTICS"
  | "PROSTHODONTICS"
  | "ORAL_SURGERY"
  | "PREVENTIVE_DENTAL"
  | "HOME_HEALTH_CARE"
  | "HOSPICE"
  | "RESPITE_CARE"
  | "LONG_TERM_CARE"
  | "LONG_TERM_ACUTE_CARE"
  | "MEDICALLY_RELATED_TRANSPORTATION"
  | "INVITRO_FERTILIZATION"
  | "MRI_SCAN"
  | "DONOR_PROCEDURES"
  | "ACUPUNCTURE"
  | "NEWBORN_CARE"
  | "PATHOLOGY"
  | "SMOKING_CESSATION"
  | "WELL_BABY_CARE"
  | "MATERNITY"
  | "TRANSPLANTS"
  | "AUDIOLOGY"
  | "INHALATION_THERAPY"
  | "DIAGNOSTIC_MEDICAL"
  | "PRIVATE_DUTY_NURSING"
  | "PROSTHETICS"
  | "DIALYSIS"
  | "OTOLOGY"
  | "CHEMOTHERAPY"
  | "ALLERGY_TESTING"
  | "IMMUNIZATIONS"
  | "ROUTINE_PHYSICAL"
  | "FAMILY_PLANNING"
  | "INFERTILITY"
  | "ABORTION"
  | "HIV_AIDS_TREATMENT"
  | "EMERGENCY_SERVICES"
  | "CANCER_TREATMENT"
  | "RETAIL_INDEPENDENT_PHARMACY"
  | "BRAND_NAME_PRESCRIPTION_DRUG"
  | "GENERIC_PRESCRIPTION_DRUG"
  | "PODIATRY"
  | "PSYCHIATRIC"
  | "PSYCHOTHERAPY"
  | "REHABILITATION"
  | "OCCUPATIONAL_THERAPY"
  | "PHYSICAL_MEDICINE"
  | "SPEECH_THERAPY"
  | "SKILLED_NURSING_CARE"
  | "SUBSTANCE_ABUSE"
  | "ALCOHOLISM_TREATMENT"
  | "DRUG_ADDICTION"
  | "OPTOMETRY"
  | "FRAMES"
  | "LENSES"
  | "ROUTINE_EYE_EXAM"
  | "NONMEDICALLY_NECESSARY_PHYSICAL_"
  | "EXPERIMENTAL_DRUG_THERAPY"
  | "BURN_CARE"
  | "INDEPENDENT_MEDICAL_EVALUATION"
  | "PSYCHIATRIC_TREATMENT_PARTIAL_HOSPITALIZATION"
  | "DAY_CARE_"
  | "COGNITIVE_THERAPY"
  | "MASSAGE_THERAPY"
  | "PULMONARY_REHABILITATION"
  | "CARDIAC_REHABILITATION"
  | "PEDIATRIC"
  | "NURSERY_ROOM_AND_BOARD"
  | "ORTHOPEDIC"
  | "CARDIAC"
  | "LYMPHATIC"
  | "GASTROINTESTINAL"
  | "ENDOCRINE"
  | "NEUROLOGY"
  | "GYNECOLOGICAL"
  | "OBSTETRICAL"
  | "CORONARY_CARE"
  | "SCREENING_XRAY"
  | "SCREENING_LABORATORY"
  | "MAMMOGRAM_HIGH_RISK_PATIENT"
  | "MAMMOGRAM_LOW_RISK_PATIENT"
  | "FLU_VACCINATION"
  | "EYEWEAR_ACCESSORIES"
  | "CASE_MANAGEMENT"
  | "DERMATOLOGY"
  | "DURABLE_MEDICAL_EQUIPMENT"
  | "DIABETIC_SUPPLIES"
  | "APPLIED_BEHAVIORAL_ANALYSIS_THERAPY"
  | "NONMEDICAL_EQUIPMENT_"
  | "PSYCHIATRIC_EMERGENCY"
  | "STEP_DOWN_UNIT"
  | "SKILLED_NURSING_FACILITY_HEAD_LEVEL_OF_CARE"
  | "SKILLED_NURSING_FACILITY_VENTILATOR_LEVEL_OF_CARE"
  | "LEVEL_OF_CARE_1"
  | "LEVEL_OF_CARE_2"
  | "LEVEL_OF_CARE_3"
  | "RADIOGRAPHS"
  | "DIAGNOSTIC_IMAGING"
  | "FIXED_PROSTHODONTICS"
  | "REMOVABLE_PROSTHODONTICS"
  | "INTRAORAL_IMAGES_COMPLETE_SERIES"
  | "ORAL_EVALUATION"
  | "DENTAL_PROPHYLAXIS"
  | "PANORAMIC_IMAGES"
  | "SEALANTS"
  | "FLUORIDE_TREATMENTS"
  | "DENTAL_IMPLANTS"
  | "TEMPOROMANDIBULAR_JOINT_DYSFUNCTION"
  | "LONG_TERM_CARE_PHARMACY"
  | "COMPREHENSIVE_MEDICATION_THERAPY_MANAGEMENT_REVIEW"
  | "TARGETED_MEDICATION_THERAPY_MANAGEMENT_REVIEW"
  | "DIETARY_NUTRITIONAL_SERVICES"
  | "TELEMEDICINE"
  | "PHARMACIST_SERVICES"
  | "DIABETIC_EDUCATION"
  | "EARLY_INTERVENTION"
  | "PREVENTIVE_SERVICES"
  | "SPECIALTY_PHARMACY"
  | "DURABLE_MEDICAL_EQUIPMENT_NEW"
  | "CAT_SCAN"
  | "OPHTHALMOLOGY"
  | "CONTACT_LENSES"
  | "ALLERGY"
  | "INTENSIVE_CARE"
  | "MENTAL_HEALTH"
  | "NEONATAL_INTENSIVE_CARE"
  | "ONCOLOGY"
  | "POSITRON_EMISSION_TOMOGRAPHY_SCAN"
  | "PHYSICAL_THERAPY"
  | "PULMONARY"
  | "RENAL"
  | "RESIDENTIAL_PSYCHIATRIC_TREATMENT"
  | "SERIOUS_MENTAL_HEALTH"
  | "TRANSITIONAL_CARE"
  | "TRANSITIONAL_NURSERY_CARE"
  | "URGENT_CARE";

export type PatientStatus =
  | "DISCHARGED"
  | "CURRENTLY_ADMITTED"
  | "EXPIRED"
  | "NOT_YET_ADMITTED";

export interface LastSuccessfulOutreachAttempt {
  outreachAttemptId?: string;
  lastUpdated?: string;
  dateCreated?: string;
  completedDate?: string | null;
  type?: OutreachType;
  outreachAttemptNumber?: number;
  lastWorkedBy?: string;
}

export interface LastSuccessfulMICOutreachAttempt {
  outreachAttemptId?: string;
  completedDate?: string;
  outreachType?: OutreachType;
  outreachAttemptNumber?: number;
}

export type RequestType = "INITIAL" | "CONTINUATION";

export type ProcedureCode = SemanticProcedureCode & {
  approvedUnits?: number;
  default?: boolean;
};

export type SemanticProcedureCode = SemanticCode & {
  code: string;
  units?: number;
  defaultUnits?: number;
  maxUnits?: number;
};

export interface SemanticCode {
  code?: string;
  description?: string;
  system?: CodeSystem;
}

export type CodeSystem =
  | "hcc"
  | "hcpcs"
  | "icd10"
  | "snomed"
  | "cohere"
  | "cpt"
  | "rxnorm"
  | "ndc"
  | "revcode"
  | "TEMP-COHERE";

export interface CaseOutcome {
  outcome?: string;
  reviewOutcome?: ReviewOutcome;
  reviewId?: string;
  auditOutcome?: AuditOutcome;
  dateCompleted?: string;
  dateCompletedAsLong?: number;
  completedBy?: string;
  completedByName?: string;
  description?: string;
  noticeInfo?: FaxNoticeInfo;
  faxbackTime?: string;
  faxbackTimeAsLong?: number;
  faxbackNumber?: string;
  serviceRequests?: ServiceRequestStatus[];
  caseNotes?: string;
  outreachCaseOutcome?: OutreachCaseOutcome;
  faxForwardLine?: string;
  referralRequest?: ReferralRequestStatus;
}

export type AuditOutcome = "PASS" | "WITHDRAWN" | "FAIL";

export interface OutreachCaseOutcome {
  outreachAttemptId?: string;
  contactType?: ContactType;
  outreachType?: OutreachType;
  outreachChannel?: AttemptChannel;
  phoneNumber?: string;
  outreachOutcome?: OutreachOutcome;
  outreachNote?: string;
}

export interface ReferralRequestStatus {
  referralRequestId?: string;
  status?: string;
  cohereId?: string;
  dateCreated?: string;
}

export type ContactType = "INBOUND" | "OUTBOUND";

export type OutreachType =
  | "CREATE_A_NEW_SUBMISSION"
  | "EXISTING_AUTH_STATUS_INQUIRY"
  | "EXISTING_AUTH_UPDATE"
  | "SCHEDULING_OUTREACH"
  | "OTHER"
  | "MISSING_INFO_CLINICAL"
  | "MISSING_INFO_NON_CLINICAL"
  | "NOTIFICATION"
  | "NUDGES";

export type AttemptChannel = "PHONE" | "EMAIL" | "FAX" | "SET_AUTH_STATUS";

export type OutreachOutcome =
  | "SUCCESS"
  | "FAILED"
  | "OFFICE_STAFF_MEMBER_NOTIFIED"
  | "LEFT_SCRIPTED_MESSAGE"
  | "P2P_RIGHTS_GIVEN"
  | "UNABLE_TO_REACH"
  | "MEMBER_NOTIFIED"
  | "AUTHORIZED_REP_NOTIFIED"
  | "UNABLE_TO_REACH_NO_ANSWER";

export type Outcome =
  | "NEW_SR"
  | "FAX_ATTACHED"
  | "FAXBACK"
  | "FORWARDED"
  | "HEALTH_HELP"
  | "CLAIM"
  | "UNASSIGNED_CLAIMS"
  | "UNASSIGNED_MISSING_NPI"
  | "UNASSIGNED_UNABLE_TO_WORK"
  | "UNASSIGNED_HOLD_IN_QUEUE"
  | "LETTER_QUEUED_FOR_DELIVERY"
  | "LETTER_SAVED"
  | "SEND_TO_ESSETTE"
  | "ANOTHER_SYSTEM"
  | "OUT_OF_SCOPE";

export type Delegation = "COHERE" | "GEISINGER";

export interface Filter {
  attribute: string;
  filterValue: string;
  filterValues?: string[];
  queryOperator: QueryOperator;
  logicalOperator: LogicalOperator;
  filterValueIsRelativeTime?: boolean;
}

export type LogicalOperator = "AND";

export interface PrioritizationConfig {
  name: string;
  parameterMap?: {
    [key: string]: string;
  };
}

export type QueryOperator = "EQ" | "NEQ" | "IN" | "NIN";

/**
 * Queue
 */
export interface Queue {
  name: string;
  description?: string;
  delegation?: Delegation;
  filters?: Filter[];
  prioritizationConfigs?: PrioritizationConfig[];
  dateCreated?: string;
  lastUpdated?: string;
  createdBy?: string;
  updatedBy?: string;
  id: string;
  version?: number;
  displayConfig?: DisplayConfig;
  allowUploads?: boolean;
  routeConfigByCaseType?: RouteConfigByCaseType;
  holdsEnabled?: boolean;
  holdReasons?: string[];
  massActionsEnabled?: boolean;
}

export type QueueViewState =
  | "FAX_INTAKE"
  | "RN_REVIEW"
  | "MD_REVIEW"
  | "P2P_REVIEW"
  | "NEW_HIRE_AUDIT_REVIEW"
  | "LETTER_WRITING"
  | "OUTREACH"
  | "CPP_REVIEW"
  | "CONCURRENT_RN_REVIEW"
  | "CONCURRENT_MD_REVIEW"
  | "CONCURRENT_MISSING_INFORMATION"
  | "CONCURRENT_LETTER_WRITING"
  | "CONCURRENT_P2P_REVIEW"
  | "CONCURRENT_OUTREACH"
  | "REQUIRES_VERBAL_NOTIFICATIONS"
  | "NO_QUEUE";

export interface DisplayConfig {
  view?: QueueViewState;
  title?: string;
  supervisorTableColumnConfigs?: ColumnConfig[];
  agentHistoryTableColumnConfigs?: ColumnConfig[];
  getNextCaseUrl?: string;
  supervisorViewUrl?: string;
  hideReviewOutcomeChip?: boolean;
  showAuditOutcomeChip?: boolean;
  showEscalatedChip?: boolean;
}

export interface ColumnConfig {
  name: string;
  label: string;
  width?: string;
  useSentenceCase?: boolean;
}

export interface RouteConfigByCaseType {
  [key: string]: RouteConfigByOrigin;
}

export interface RouteConfigByOrigin {
  supervisorDashboardRoute: RouteConfig;
  agentWorkCaseRoute: RouteConfig;
  myWorkCaseRoute: RouteConfig;
}

export interface RouteConfig {
  baseApp: ApplicationType;
  url: string;
}

export type ApplicationType = "AUTH" | "QM";

export type TablePageViewState = "AGENT_DASHBOARD" | "SUPERVISOR_DASHBOARD";

export type ReviewOutcome =
  | "APPROVED"
  | "WITHDRAWN"
  | "PENDING_ADMIN_VOID"
  | "PENDING_MISSING_CLINICAL_INFO"
  | "PENDING_EXTERNAL_REVIEW"
  | "PENDING_SCHEDULING_OUTREACH"
  | "PENDING_NUDGE_OUTREACH"
  | "PENDING_MD_REVIEW"
  | "PENDING_RN_REVIEW"
  | "PENDING_P2P_REVIEW"
  | "PARTIALLY_APPROVED"
  | "RECOMMENDED_APPROVAL"
  | "RECOMMENDED_PARTIAL_APPROVAL"
  | "RECOMMENDED_DENIAL"
  | "DENIED"
  | "MISROUTE"
  | "OUT_OF_SCOPE"
  | "UNWORKABLE"
  | "ADMINISTRATIVE_DENIAL";

export interface ReviewCompletion {
  serviceRequestId?: string;
  reviewId?: string;
  reviewType?: string;
  reviewOutcome?: ReviewOutcome;
}

export type CaseStatus = "OPEN" | "IN_PROGRESS" | "COMPLETE" | "CANCELLED";

export type CasePriority = "LOW" | "MEDIUM" | "HIGH" | "URGENT";

export interface FaxNoticeInfo {
  faxbackRequestType?: "SERVICE_REQUEST" | "REFERRAL_REQUEST";
  reason?: string;
  subReason?: string[];
  additionalNotes?: string;
}

export interface FaxDetail {
  attachmentId?: string;
  cohereId?: string;
  faxNumber?: string;
  numberOfPages?: number;
  useCohereTemplate?: boolean;
  faxReceivedTime?: string;
  faxReceivedTimeAsLong?: number;
}

export interface User {
  id?: string;
  oktaId?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  roles?: string[];
  organization?: string;
  username?: string;
  salesforceId?: string;
  skills?: string[];
  queues?: string[];
  activeQueueId?: string;
  isAudited?: boolean;
}

export interface UserSkill {
  id?: string;
  opsGroup?: string;
  name?: string;
  pxCodes?: string[];
  type?: SkillType;
}

export type SkillType = "NURSE" | "MD";

export interface ServiceRequestStatus {
  serviceRequestId?: string;
  status?: string;
  cohereId?: string;
  dateCreated?: string;
}

export interface ServiceCaseSearch {
  query?: string;
  caseStatusList?: CaseStatus[];
  casePriorityList?: CasePriority[];
  caseType?: CaseType;
  assigneeName?: string;
  dueDate?: string;
  sortBy?: string[];
  sortDesc?: boolean;
  max?: number;
  offset?: number;
  page?: number;
  serviceRequestId?: string;
  isPaginationOff?: boolean;
}

export interface ServiceCaseUserHold {
  reason?: string;
  otherReason?: string;
  heldBy?: string;
  heldAt: string;
  removedAt?: string;
  removedBy?: string;
  isDismissed?: boolean;
  status?: string;
  expiry?: string;
  serviceCase: ServiceCase;
}

export interface ServiceCaseUserHolds {
  totalSize: number;
  totalPages: number;
  size: number;
  offset: number;
  content?: ServiceCaseUserHold[];
}

export interface CountResponse {
  /**
   * Get total count
   */
  count?: number;
}

export interface UserManagementResponse {
  /**
   * number of created profiles
   */
  createCount?: number;
  /**
   * number of updated profiles
   */
  updateCount?: number;
  /**
   * number of deleted profiles
   */
  deleteCount?: number;
  /**
   * number of errored profiles
   */
  errorCount?: number;
  userNamesWithInvalidStateName?: string[];
  userNamesWithInvalidSkillSet?: string[];
  userNamesWithInvalidQueueName?: string[];
  invalidStateNames?: string[];
  invalidSkillSetNames?: string[];
  invalidQueueNames?: string[];
}

export type AbstractOperator =
  | "eq"
  | "neq"
  | "gt"
  | "lt"
  | "gte"
  | "lte"
  | "inlist"
  | "ninlist";

export type Clause = "MUST" | "SHOULD," | "MUST_NOT";

export interface GenericCondition {
  key: string;
  operator: AbstractOperator;
  value: string | string[] | number | boolean;
}

export interface ClausalCondition {
  clause: Clause;
  conditions: GenericCondition[];
}

export interface Aggregation {
  name: string;
  fieldName: string;
  type?: AggregationType;
  aggregations?: Aggregation[];
}

export type AggregationType = "SUM" | "TERM";

export interface ElasticStatsSearchPayload {
  index?: string;
  size?: number;
  offset?: number;
  filterOn?: ClausalCondition[];
  aggregations?: Aggregation[];
}

export interface ElasticStatsSearchHits {
  totalHits: {
    value?: number;
  };
}

export interface ElasticStatsSearchResponse {
  hits: ElasticStatsSearchHits;
  aggregations?: Aggregations;
}

export interface Aggregations {
  count?: number;
  value?: number;
  aggregations?: Aggregations;
  [key: string]: any;
}

export interface UploadAuditCasesResponse {
  created?: number;
  failed?: number;
  duplicates?: number;
  totalRecords?: number;
  duplicatesInUpload?: {
    [key: string]: number;
  };
  failedCohereIds?: string[];
  preExistingCases?: string[];
  metadata?: {
    queueId?: string;
    auditTag?: string;
    uploadedBy?: string;
  };
  aggregations?: Aggregations;
}

export interface SoftDeleteCasesResponse {
  deletedCount: number;
}

export interface UnassignCasesResponse {
  unassignedCount: number;
}

export interface CaseHoldEntry {
  reason?: string;
  otherReason?: string;
}

/**
 * {
 *     "errorCode": "String",
 *     "errorDescription": "String"
 * }
 *
 */
export type ExampleErrorResponseResponse = void;

export type SearchServiceCaseProps = Omit<
  MutateProps<ServiceCase[], unknown, void, ServiceCaseSearch, void>,
  "path" | "verb"
>;

export const SearchServiceCase = (props: SearchServiceCaseProps) => (
  <Mutate<ServiceCase[], unknown, void, ServiceCaseSearch, void>
    verb="POST"
    path={`/serviceCase/search`}
    {...props}
  />
);

export type UseSearchServiceCaseProps = Omit<
  UseMutateProps<ServiceCase[], unknown, void, ServiceCaseSearch, void>,
  "path" | "verb"
>;

export const useSearchServiceCase = (props: UseSearchServiceCaseProps) =>
  useMutate<ServiceCase[], unknown, void, ServiceCaseSearch, void>(
    "POST",
    `/serviceCase/search`,
    props
  );

export interface SearchServiceCasesInQueueQueryParams {
  query?: string;
  dateCreatedAfter?: string;
  tatDueBefore?: string;
  timeSinceLastOutreach?: string;
  assigneeNameList?: string[];
  trackingNumberList?: string[];
  outreachAttemptList?: string[];
  lastReviewedByList?: string[];
  caseNumberList?: string[];
  procedureCodesList?: string[];
  caseStatusList?: CaseStatus[];
  casePriorityList?: CasePriority[];
  caseType?: CaseType;
  assigneeName?: string;
  dueDate?: string;
  sortBy?: string[];
  sortDesc?: boolean;
  max?: number;
  offset?: number;
  page?: number;
}

export interface SearchServiceCasesInQueuePathParams {
  /**
   * A unique identifier for a `Queue`.
   */
  queueId: string;
}

export type SearchServiceCasesInQueueProps = Omit<
  GetProps<
    ServiceCase[],
    unknown,
    SearchServiceCasesInQueueQueryParams,
    SearchServiceCasesInQueuePathParams
  >,
  "path"
> &
  SearchServiceCasesInQueuePathParams;

export const SearchServiceCasesInQueue = ({
  queueId,
  ...props
}: SearchServiceCasesInQueueProps) => (
  <Get<
    ServiceCase[],
    unknown,
    SearchServiceCasesInQueueQueryParams,
    SearchServiceCasesInQueuePathParams
  >
    path={`/queue/${queueId}/cases`}
    {...props}
  />
);

export type UseSearchServiceCasesInQueueProps = Omit<
  UseGetProps<
    ServiceCase[],
    unknown,
    SearchServiceCasesInQueueQueryParams,
    SearchServiceCasesInQueuePathParams
  >,
  "path"
> &
  SearchServiceCasesInQueuePathParams;

export const useSearchServiceCasesInQueue = ({
  queueId,
  ...props
}: UseSearchServiceCasesInQueueProps) =>
  useGet<
    ServiceCase[],
    unknown,
    SearchServiceCasesInQueueQueryParams,
    SearchServiceCasesInQueuePathParams
  >(
    (paramsInPath: SearchServiceCasesInQueuePathParams) =>
      `/queue/${paramsInPath.queueId}/cases`,
    { pathParams: { queueId }, ...props }
  );

export type SearchServiceCaseCountProps = Omit<
  MutateProps<CountResponse, unknown, void, ServiceCaseSearch, void>,
  "path" | "verb"
>;

export const SearchServiceCaseCount = (props: SearchServiceCaseCountProps) => (
  <Mutate<CountResponse, unknown, void, ServiceCaseSearch, void>
    verb="POST"
    path={`/serviceCase/searchCount`}
    {...props}
  />
);

export type UseSearchServiceCaseCountProps = Omit<
  UseMutateProps<CountResponse, unknown, void, ServiceCaseSearch, void>,
  "path" | "verb"
>;

export const useSearchServiceCaseCount = (
  props: UseSearchServiceCaseCountProps
) =>
  useMutate<CountResponse, unknown, void, ServiceCaseSearch, void>(
    "POST",
    `/serviceCase/searchCount`,
    props
  );

export interface SearchServiceCaseCountInQueueQueryParams {
  query?: string;
  caseStatusList?: CaseStatus[];
  casePriorityList?: CasePriority[];
  caseType?: CaseType;
  assigneeName?: string;
  dueDate?: string;
}

export interface SearchServiceCaseCountInQueuePathParams {
  /**
   * A unique identifier for a `Queue`.
   */
  queueId: string;
}

export type SearchServiceCaseCountInQueueProps = Omit<
  GetProps<
    CountResponse,
    unknown,
    SearchServiceCaseCountInQueueQueryParams,
    SearchServiceCaseCountInQueuePathParams
  >,
  "path"
> &
  SearchServiceCaseCountInQueuePathParams;

export const SearchServiceCaseCountInQueue = ({
  queueId,
  ...props
}: SearchServiceCaseCountInQueueProps) => (
  <Get<
    CountResponse,
    unknown,
    SearchServiceCaseCountInQueueQueryParams,
    SearchServiceCaseCountInQueuePathParams
  >
    path={`/queue/${queueId}/count`}
    {...props}
  />
);

export type UseSearchServiceCaseCountInQueueProps = Omit<
  UseGetProps<
    CountResponse,
    unknown,
    SearchServiceCaseCountInQueueQueryParams,
    SearchServiceCaseCountInQueuePathParams
  >,
  "path"
> &
  SearchServiceCaseCountInQueuePathParams;

export const useSearchServiceCaseCountInQueue = ({
  queueId,
  ...props
}: UseSearchServiceCaseCountInQueueProps) =>
  useGet<
    CountResponse,
    unknown,
    SearchServiceCaseCountInQueueQueryParams,
    SearchServiceCaseCountInQueuePathParams
  >(
    (paramsInPath: SearchServiceCaseCountInQueuePathParams) =>
      `/queue/${paramsInPath.queueId}/count`,
    { pathParams: { queueId }, ...props }
  );

export interface ServiceCaseSimpleSearchQueryParams {
  /**
   * A unique identifier for either a serviceCase or a serviceRequest
   */
  query: string;
}

export type ServiceCaseSimpleSearchProps = Omit<
  GetProps<ServiceCase[], unknown, ServiceCaseSimpleSearchQueryParams, void>,
  "path"
>;

export const ServiceCaseSimpleSearch = (
  props: ServiceCaseSimpleSearchProps
) => (
  <Get<ServiceCase[], unknown, ServiceCaseSimpleSearchQueryParams, void>
    path={`/serviceCase/simpleSearch`}
    {...props}
  />
);

export type UseServiceCaseSimpleSearchProps = Omit<
  UseGetProps<ServiceCase[], unknown, ServiceCaseSimpleSearchQueryParams, void>,
  "path"
>;

export const useServiceCaseSimpleSearch = (
  props: UseServiceCaseSimpleSearchProps
) =>
  useGet<ServiceCase[], unknown, ServiceCaseSimpleSearchQueryParams, void>(
    `/serviceCase/simpleSearch`,
    props
  );

export interface GetWorkedCountQueryParams {
  afterDate?: string;
  status?: string;
}

export type GetWorkedCountProps = Omit<
  GetProps<CountResponse, unknown, GetWorkedCountQueryParams, void>,
  "path"
>;

export const GetWorkedCount = (props: GetWorkedCountProps) => (
  <Get<CountResponse, unknown, GetWorkedCountQueryParams, void>
    path={`/serviceCase/workCount`}
    {...props}
  />
);

export type UseGetWorkedCountProps = Omit<
  UseGetProps<CountResponse, unknown, GetWorkedCountQueryParams, void>,
  "path"
>;

export const useGetWorkedCount = (props: UseGetWorkedCountProps) =>
  useGet<CountResponse, unknown, GetWorkedCountQueryParams, void>(
    `/serviceCase/workCount`,
    props
  );

export interface GetQueueWorkedCountQueryParams {
  afterDate?: string;
  status?: string;
}

export interface GetQueueWorkedCountPathParams {
  /**
   * A unique identifier for a `Queue`.
   */
  queueId: string;
}

export type GetQueueWorkedCountProps = Omit<
  GetProps<
    CountResponse,
    unknown,
    GetQueueWorkedCountQueryParams,
    GetQueueWorkedCountPathParams
  >,
  "path"
> &
  GetQueueWorkedCountPathParams;

export const GetQueueWorkedCount = ({
  queueId,
  ...props
}: GetQueueWorkedCountProps) => (
  <Get<
    CountResponse,
    unknown,
    GetQueueWorkedCountQueryParams,
    GetQueueWorkedCountPathParams
  >
    path={`/queue/${queueId}/workCount`}
    {...props}
  />
);

export type UseGetQueueWorkedCountProps = Omit<
  UseGetProps<
    CountResponse,
    unknown,
    GetQueueWorkedCountQueryParams,
    GetQueueWorkedCountPathParams
  >,
  "path"
> &
  GetQueueWorkedCountPathParams;

export const useGetQueueWorkedCount = ({
  queueId,
  ...props
}: UseGetQueueWorkedCountProps) =>
  useGet<
    CountResponse,
    unknown,
    GetQueueWorkedCountQueryParams,
    GetQueueWorkedCountPathParams
  >(
    (paramsInPath: GetQueueWorkedCountPathParams) =>
      `/queue/${paramsInPath.queueId}/workCount`,
    { pathParams: { queueId }, ...props }
  );

export interface GetIncompleteCaseTypeVolumeQueryParams {
  caseType: CaseType;
  includeSFCases?: boolean;
}

export type GetIncompleteCaseTypeVolumeProps = Omit<
  GetProps<
    CountResponse,
    unknown,
    GetIncompleteCaseTypeVolumeQueryParams,
    void
  >,
  "path"
>;

export const GetIncompleteCaseTypeVolume = (
  props: GetIncompleteCaseTypeVolumeProps
) => (
  <Get<CountResponse, unknown, GetIncompleteCaseTypeVolumeQueryParams, void>
    path={`/serviceCase/incompleteQueueVolume`}
    {...props}
  />
);

export type UseGetIncompleteCaseTypeVolumeProps = Omit<
  UseGetProps<
    CountResponse,
    unknown,
    GetIncompleteCaseTypeVolumeQueryParams,
    void
  >,
  "path"
>;

export const useGetIncompleteCaseTypeVolume = (
  props: UseGetIncompleteCaseTypeVolumeProps
) =>
  useGet<CountResponse, unknown, GetIncompleteCaseTypeVolumeQueryParams, void>(
    `/serviceCase/incompleteQueueVolume`,
    props
  );

export interface GetIncompleteQueueVolumeQueryParams {
  includeSFCases?: boolean;
}

export interface GetIncompleteQueueVolumePathParams {
  /**
   * A unique identifier for a `Queue`.
   */
  queueId: string;
}

export type GetIncompleteQueueVolumeProps = Omit<
  GetProps<
    CountResponse,
    unknown,
    GetIncompleteQueueVolumeQueryParams,
    GetIncompleteQueueVolumePathParams
  >,
  "path"
> &
  GetIncompleteQueueVolumePathParams;

export const GetIncompleteQueueVolume = ({
  queueId,
  ...props
}: GetIncompleteQueueVolumeProps) => (
  <Get<
    CountResponse,
    unknown,
    GetIncompleteQueueVolumeQueryParams,
    GetIncompleteQueueVolumePathParams
  >
    path={`/queue/${queueId}/incompleteQueueVolume`}
    {...props}
  />
);

export type UseGetIncompleteQueueVolumeProps = Omit<
  UseGetProps<
    CountResponse,
    unknown,
    GetIncompleteQueueVolumeQueryParams,
    GetIncompleteQueueVolumePathParams
  >,
  "path"
> &
  GetIncompleteQueueVolumePathParams;

export const useGetIncompleteQueueVolume = ({
  queueId,
  ...props
}: UseGetIncompleteQueueVolumeProps) =>
  useGet<
    CountResponse,
    unknown,
    GetIncompleteQueueVolumeQueryParams,
    GetIncompleteQueueVolumePathParams
  >(
    (paramsInPath: GetIncompleteQueueVolumePathParams) =>
      `/queue/${paramsInPath.queueId}/incompleteQueueVolume`,
    { pathParams: { queueId }, ...props }
  );

export interface GetServiceCasePathParams {
  /**
   * A unique identifier for a `ServiceCase`.
   */
  id: string;
}

export type GetServiceCaseProps = Omit<
  GetProps<ServiceCase, unknown, void, GetServiceCasePathParams>,
  "path"
> &
  GetServiceCasePathParams;

/**
 * Gets the requested ServiceCase
 */
export const GetServiceCase = ({ id, ...props }: GetServiceCaseProps) => (
  <Get<ServiceCase, unknown, void, GetServiceCasePathParams>
    path={`/serviceCase/${id}`}
    {...props}
  />
);

export type UseGetServiceCaseProps = Omit<
  UseGetProps<ServiceCase, unknown, void, GetServiceCasePathParams>,
  "path"
> &
  GetServiceCasePathParams;

/**
 * Gets the requested ServiceCase
 */
export const useGetServiceCase = ({ id, ...props }: UseGetServiceCaseProps) =>
  useGet<ServiceCase, unknown, void, GetServiceCasePathParams>(
    (paramsInPath: GetServiceCasePathParams) =>
      `/serviceCase/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface UpdateServiceCasePathParams {
  /**
   * A unique identifier for a `ServiceCase`.
   */
  id: string;
}

export type UpdateServiceCaseProps = Omit<
  MutateProps<
    ServiceCase,
    unknown,
    void,
    ServiceCase,
    UpdateServiceCasePathParams
  >,
  "path" | "verb"
> &
  UpdateServiceCasePathParams;

/**
 * Update a ServiceCase
 *
 * Updates an existing `ServiceCase`.
 */
export const UpdateServiceCase = ({ id, ...props }: UpdateServiceCaseProps) => (
  <Mutate<ServiceCase, unknown, void, ServiceCase, UpdateServiceCasePathParams>
    verb="PATCH"
    path={`/serviceCase/${id}`}
    {...props}
  />
);

export type UseUpdateServiceCaseProps = Omit<
  UseMutateProps<
    ServiceCase,
    unknown,
    void,
    ServiceCase,
    UpdateServiceCasePathParams
  >,
  "path" | "verb"
> &
  UpdateServiceCasePathParams;

/**
 * Update a ServiceCase
 *
 * Updates an existing `ServiceCase`.
 */
export const useUpdateServiceCase = ({
  id,
  ...props
}: UseUpdateServiceCaseProps) =>
  useMutate<
    ServiceCase,
    unknown,
    void,
    ServiceCase,
    UpdateServiceCasePathParams
  >(
    "PATCH",
    (paramsInPath: UpdateServiceCasePathParams) =>
      `/serviceCase/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface UpdateServiceCaseAssigneeRequestBody {
  serviceCaseId?: string;
  assignee?: Assignee;
  assignmentMethodDetails?: AssignmentMethodDetails;
}

export type UpdateServiceCaseAssigneeProps = Omit<
  MutateProps<
    ServiceCase,
    unknown,
    void,
    UpdateServiceCaseAssigneeRequestBody,
    void
  >,
  "path" | "verb"
>;

export const UpdateServiceCaseAssignee = (
  props: UpdateServiceCaseAssigneeProps
) => (
  <Mutate<
    ServiceCase,
    unknown,
    void,
    UpdateServiceCaseAssigneeRequestBody,
    void
  >
    verb="PATCH"
    path={`/serviceCase/assignee`}
    {...props}
  />
);

export type UseUpdateServiceCaseAssigneeProps = Omit<
  UseMutateProps<
    ServiceCase,
    unknown,
    void,
    UpdateServiceCaseAssigneeRequestBody,
    void
  >,
  "path" | "verb"
>;

export const useUpdateServiceCaseAssignee = (
  props: UseUpdateServiceCaseAssigneeProps
) =>
  useMutate<
    ServiceCase,
    unknown,
    void,
    UpdateServiceCaseAssigneeRequestBody,
    void
  >("PATCH", `/serviceCase/assignee`, props);

export type SearchElasticStatsProps = Omit<
  MutateProps<
    ElasticStatsSearchResponse,
    unknown,
    void,
    ElasticStatsSearchPayload,
    void
  >,
  "path" | "verb"
>;

export const SearchElasticStats = (props: SearchElasticStatsProps) => (
  <Mutate<
    ElasticStatsSearchResponse,
    unknown,
    void,
    ElasticStatsSearchPayload,
    void
  >
    verb="POST"
    path={`/stats`}
    {...props}
  />
);

export type UseSearchElasticStatsProps = Omit<
  UseMutateProps<
    ElasticStatsSearchResponse,
    unknown,
    void,
    ElasticStatsSearchPayload,
    void
  >,
  "path" | "verb"
>;

export const useSearchElasticStats = (props: UseSearchElasticStatsProps) =>
  useMutate<
    ElasticStatsSearchResponse,
    unknown,
    void,
    ElasticStatsSearchPayload,
    void
  >("POST", `/stats`, props);

export interface GetUserPathParams {
  /**
   * A unique identifier for a `User`.
   */
  id: string;
}

export type GetUserProps = Omit<
  GetProps<User, unknown, void, GetUserPathParams>,
  "path"
> &
  GetUserPathParams;

/**
 * Gets the requested User
 */
export const GetUser = ({ id, ...props }: GetUserProps) => (
  <Get<User, unknown, void, GetUserPathParams>
    path={`/user/${id}`}
    {...props}
  />
);

export type UseGetUserProps = Omit<
  UseGetProps<User, unknown, void, GetUserPathParams>,
  "path"
> &
  GetUserPathParams;

/**
 * Gets the requested User
 */
export const useGetUser = ({ id, ...props }: UseGetUserProps) =>
  useGet<User, unknown, void, GetUserPathParams>(
    (paramsInPath: GetUserPathParams) => `/user/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface UpdateUserPathParams {
  /**
   * A unique identifier for a `User`.
   */
  id: string;
}

export type UpdateUserProps = Omit<
  MutateProps<User, unknown, void, User, UpdateUserPathParams>,
  "path" | "verb"
> &
  UpdateUserPathParams;

/**
 * Update a User
 *
 * Updates an existing `User`.
 */
export const UpdateUser = ({ id, ...props }: UpdateUserProps) => (
  <Mutate<User, unknown, void, User, UpdateUserPathParams>
    verb="PUT"
    path={`/user/${id}`}
    {...props}
  />
);

export type UseUpdateUserProps = Omit<
  UseMutateProps<User, unknown, void, User, UpdateUserPathParams>,
  "path" | "verb"
> &
  UpdateUserPathParams;

/**
 * Update a User
 *
 * Updates an existing `User`.
 */
export const useUpdateUser = ({ id, ...props }: UseUpdateUserProps) =>
  useMutate<User, unknown, void, User, UpdateUserPathParams>(
    "PUT",
    (paramsInPath: UpdateUserPathParams) => `/user/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface SearchUserQueryParams {
  /**
   * Searches for user by `name` field
   */
  query?: string;
  /**
   * Max number of results to return (defaults 10, up to 100)
   */
  max?: number;
  /**
   * Starting result offset
   */
  offset?: number;
  name?: string;
  oktaId?: string;
  isAudited?: boolean;
  supportOnly?: boolean;
}

export type SearchUserProps = Omit<
  GetProps<User[], unknown, SearchUserQueryParams, void>,
  "path"
>;

/**
 * List All users
 *
 * Gets a list of all `User` entities.
 */
export const SearchUser = (props: SearchUserProps) => (
  <Get<User[], unknown, SearchUserQueryParams, void>
    path={`/user/search`}
    {...props}
  />
);

export type UseSearchUserProps = Omit<
  UseGetProps<User[], unknown, SearchUserQueryParams, void>,
  "path"
>;

/**
 * List All users
 *
 * Gets a list of all `User` entities.
 */
export const useSearchUser = (props: UseSearchUserProps) =>
  useGet<User[], unknown, SearchUserQueryParams, void>(`/user/search`, props);

export type UserQmProfileProps = Omit<
  GetProps<User, unknown, void, void>,
  "path"
>;

/**
 * User details
 *
 * Used for getting user information inside queue-management
 */
export const UserQmProfile = (props: UserQmProfileProps) => (
  <Get<User, unknown, void, void> path={`/user/myProfile`} {...props} />
);

export type UseUserQmProfileProps = Omit<
  UseGetProps<User, unknown, void, void>,
  "path"
>;

/**
 * User details
 *
 * Used for getting user information inside queue-management
 */
export const useUserQmProfile = (props: UseUserQmProfileProps) =>
  useGet<User, unknown, void, void>(`/user/myProfile`, props);

export type DownloadUsersAsCsvProps = Omit<
  GetProps<void, unknown, void, void>,
  "path"
>;

export const DownloadUsersAsCsv = (props: DownloadUsersAsCsvProps) => (
  <Get<void, unknown, void, void> path={`/user/userAsCsv`} {...props} />
);

export type UseDownloadUsersAsCsvProps = Omit<
  UseGetProps<void, unknown, void, void>,
  "path"
>;

export const useDownloadUsersAsCsv = (props: UseDownloadUsersAsCsvProps) =>
  useGet<void, unknown, void, void>(`/user/userAsCsv`, props);

export type BatchUpdateUserInfoProps = Omit<
  MutateProps<UserManagementResponse, unknown, void, void, void>,
  "path" | "verb"
>;

/**
 * Batch update user info with csv file
 */
export const BatchUpdateUserInfo = (props: BatchUpdateUserInfoProps) => (
  <Mutate<UserManagementResponse, unknown, void, void, void>
    verb="POST"
    path={`/user/userAsCsv`}
    {...props}
  />
);

export type UseBatchUpdateUserInfoProps = Omit<
  UseMutateProps<UserManagementResponse, unknown, void, void, void>,
  "path" | "verb"
>;

/**
 * Batch update user info with csv file
 */
export const useBatchUpdateUserInfo = (props: UseBatchUpdateUserInfoProps) =>
  useMutate<UserManagementResponse, unknown, void, void, void>(
    "POST",
    `/user/userAsCsv`,
    props
  );

export type GetSkillsProps = Omit<
  GetProps<UserSkill[], unknown, void, void>,
  "path"
>;

export const GetSkills = (props: GetSkillsProps) => (
  <Get<UserSkill[], unknown, void, void> path={`/userSkill`} {...props} />
);

export type UseGetSkillsProps = Omit<
  UseGetProps<UserSkill[], unknown, void, void>,
  "path"
>;

export const useGetSkills = (props: UseGetSkillsProps) =>
  useGet<UserSkill[], unknown, void, void>(`/userSkill`, props);

export interface GetSkillsByNamePatternQueryParams {
  /**
   * A skill name or substring
   */
  pattern: string;
}

export type GetSkillsByNamePatternProps = Omit<
  GetProps<UserSkill[], unknown, GetSkillsByNamePatternQueryParams, void>,
  "path"
>;

export const GetSkillsByNamePattern = (props: GetSkillsByNamePatternProps) => (
  <Get<UserSkill[], unknown, GetSkillsByNamePatternQueryParams, void>
    path={`/userSkill/nameSearch`}
    {...props}
  />
);

export type UseGetSkillsByNamePatternProps = Omit<
  UseGetProps<UserSkill[], unknown, GetSkillsByNamePatternQueryParams, void>,
  "path"
>;

export const useGetSkillsByNamePattern = (
  props: UseGetSkillsByNamePatternProps
) =>
  useGet<UserSkill[], unknown, GetSkillsByNamePatternQueryParams, void>(
    `/userSkill/nameSearch`,
    props
  );

export type GetUserCasesProps = Omit<
  GetProps<ServiceCase[], unknown, void, void>,
  "path"
>;

export const GetUserCases = (props: GetUserCasesProps) => (
  <Get<ServiceCase[], unknown, void, void>
    path={`/serviceCase/userCases`}
    {...props}
  />
);

export type UseGetUserCasesProps = Omit<
  UseGetProps<ServiceCase[], unknown, void, void>,
  "path"
>;

export const useGetUserCases = (props: UseGetUserCasesProps) =>
  useGet<ServiceCase[], unknown, void, void>(`/serviceCase/userCases`, props);

export interface GetUserCasesForQueuePathParams {
  /**
   * A unique identifier for a `Queue`.
   */
  queueId: string;
}

export type GetUserCasesForQueueProps = Omit<
  GetProps<ServiceCase[], unknown, void, GetUserCasesForQueuePathParams>,
  "path"
> &
  GetUserCasesForQueuePathParams;

export const GetUserCasesForQueue = ({
  queueId,
  ...props
}: GetUserCasesForQueueProps) => (
  <Get<ServiceCase[], unknown, void, GetUserCasesForQueuePathParams>
    path={`/queue/${queueId}/userCases`}
    {...props}
  />
);

export type UseGetUserCasesForQueueProps = Omit<
  UseGetProps<ServiceCase[], unknown, void, GetUserCasesForQueuePathParams>,
  "path"
> &
  GetUserCasesForQueuePathParams;

export const useGetUserCasesForQueue = ({
  queueId,
  ...props
}: UseGetUserCasesForQueueProps) =>
  useGet<ServiceCase[], unknown, void, GetUserCasesForQueuePathParams>(
    (paramsInPath: GetUserCasesForQueuePathParams) =>
      `/queue/${paramsInPath.queueId}/userCases`,
    { pathParams: { queueId }, ...props }
  );

export interface SearchServiceCaseUserHoldsInQueueQueryParams {
  offset?: number;
}

export interface SearchServiceCaseUserHoldsInQueuePathParams {
  /**
   * A unique identifier for a `Queue`.
   */
  queueId: string;
}

export type SearchServiceCaseUserHoldsInQueueProps = Omit<
  GetProps<
    ServiceCaseUserHolds,
    unknown,
    SearchServiceCaseUserHoldsInQueueQueryParams,
    SearchServiceCaseUserHoldsInQueuePathParams
  >,
  "path"
> &
  SearchServiceCaseUserHoldsInQueuePathParams;

export const SearchServiceCaseUserHoldsInQueue = ({
  queueId,
  ...props
}: SearchServiceCaseUserHoldsInQueueProps) => (
  <Get<
    ServiceCaseUserHolds,
    unknown,
    SearchServiceCaseUserHoldsInQueueQueryParams,
    SearchServiceCaseUserHoldsInQueuePathParams
  >
    path={`/queue/${queueId}/userHolds`}
    {...props}
  />
);

export type UseSearchServiceCaseUserHoldsInQueueProps = Omit<
  UseGetProps<
    ServiceCaseUserHolds,
    unknown,
    SearchServiceCaseUserHoldsInQueueQueryParams,
    SearchServiceCaseUserHoldsInQueuePathParams
  >,
  "path"
> &
  SearchServiceCaseUserHoldsInQueuePathParams;

export const useSearchServiceCaseUserHoldsInQueue = ({
  queueId,
  ...props
}: UseSearchServiceCaseUserHoldsInQueueProps) =>
  useGet<
    ServiceCaseUserHolds,
    unknown,
    SearchServiceCaseUserHoldsInQueueQueryParams,
    SearchServiceCaseUserHoldsInQueuePathParams
  >(
    (paramsInPath: SearchServiceCaseUserHoldsInQueuePathParams) =>
      `/queue/${paramsInPath.queueId}/userHolds`,
    { pathParams: { queueId }, ...props }
  );

export interface CompleteServiceCasePathParams {
  /**
   * A unique identifier for a `ServiceCase`.
   */
  id: string;
}

export type CompleteServiceCaseProps = Omit<
  MutateProps<
    ServiceCase,
    unknown,
    void,
    CaseOutcome,
    CompleteServiceCasePathParams
  >,
  "path" | "verb"
> &
  CompleteServiceCasePathParams;

/**
 * Completes a ServiceCase
 *
 * completes an existing `ServiceCase`.
 */
export const CompleteServiceCase = ({
  id,
  ...props
}: CompleteServiceCaseProps) => (
  <Mutate<
    ServiceCase,
    unknown,
    void,
    CaseOutcome,
    CompleteServiceCasePathParams
  >
    verb="PATCH"
    path={`/serviceCase/${id}/completeCase`}
    {...props}
  />
);

export type UseCompleteServiceCaseProps = Omit<
  UseMutateProps<
    ServiceCase,
    unknown,
    void,
    CaseOutcome,
    CompleteServiceCasePathParams
  >,
  "path" | "verb"
> &
  CompleteServiceCasePathParams;

/**
 * Completes a ServiceCase
 *
 * completes an existing `ServiceCase`.
 */
export const useCompleteServiceCase = ({
  id,
  ...props
}: UseCompleteServiceCaseProps) =>
  useMutate<
    ServiceCase,
    unknown,
    void,
    CaseOutcome,
    CompleteServiceCasePathParams
  >(
    "PATCH",
    (paramsInPath: CompleteServiceCasePathParams) =>
      `/serviceCase/${paramsInPath.id}/completeCase`,
    { pathParams: { id }, ...props }
  );

export type UpdateCasesAfterReviewProps = Omit<
  MutateProps<ServiceCase[], unknown, void, ReviewCompletion, void>,
  "path" | "verb"
>;

export const UpdateCasesAfterReview = (props: UpdateCasesAfterReviewProps) => (
  <Mutate<ServiceCase[], unknown, void, ReviewCompletion, void>
    verb="POST"
    path={`/serviceCase/updateCasesAfterReview`}
    {...props}
  />
);

export type UseUpdateCasesAfterReviewProps = Omit<
  UseMutateProps<ServiceCase[], unknown, void, ReviewCompletion, void>,
  "path" | "verb"
>;

export const useUpdateCasesAfterReview = (
  props: UseUpdateCasesAfterReviewProps
) =>
  useMutate<ServiceCase[], unknown, void, ReviewCompletion, void>(
    "POST",
    `/serviceCase/updateCasesAfterReview`,
    props
  );

export interface GetServiceRequestCasesQueryParams {
  caseType?: string;
}

export interface GetServiceRequestCasesPathParams {
  /**
   * A unique identifier for a `ServiceRequest`.
   */
  id: string;
}

export type GetServiceRequestCasesProps = Omit<
  GetProps<
    ServiceCase[],
    unknown,
    GetServiceRequestCasesQueryParams,
    GetServiceRequestCasesPathParams
  >,
  "path"
> &
  GetServiceRequestCasesPathParams;

export const GetServiceRequestCases = ({
  id,
  ...props
}: GetServiceRequestCasesProps) => (
  <Get<
    ServiceCase[],
    unknown,
    GetServiceRequestCasesQueryParams,
    GetServiceRequestCasesPathParams
  >
    path={`/serviceRequest/${id}/cases`}
    {...props}
  />
);

export type UseGetServiceRequestCasesProps = Omit<
  UseGetProps<
    ServiceCase[],
    unknown,
    GetServiceRequestCasesQueryParams,
    GetServiceRequestCasesPathParams
  >,
  "path"
> &
  GetServiceRequestCasesPathParams;

export const useGetServiceRequestCases = ({
  id,
  ...props
}: UseGetServiceRequestCasesProps) =>
  useGet<
    ServiceCase[],
    unknown,
    GetServiceRequestCasesQueryParams,
    GetServiceRequestCasesPathParams
  >(
    (paramsInPath: GetServiceRequestCasesPathParams) =>
      `/serviceRequest/${paramsInPath.id}/cases`,
    { pathParams: { id }, ...props }
  );

export interface GetUserQPHQueryParams {
  /**
   * get qph from this optional startDate if no value provided start of the day will be considered
   */
  startDate?: string;
  /**
   * get qph from this optional endDate if no value provided end of the day will be considered
   */
  endDate?: string;
}

export type GetUserQPHProps = Omit<
  GetProps<CountResponse, unknown, GetUserQPHQueryParams, void>,
  "path"
>;

export const GetUserQPH = (props: GetUserQPHProps) => (
  <Get<CountResponse, unknown, GetUserQPHQueryParams, void>
    path={`/serviceCase/QPH`}
    {...props}
  />
);

export type UseGetUserQPHProps = Omit<
  UseGetProps<CountResponse, unknown, GetUserQPHQueryParams, void>,
  "path"
>;

export const useGetUserQPH = (props: UseGetUserQPHProps) =>
  useGet<CountResponse, unknown, GetUserQPHQueryParams, void>(
    `/serviceCase/QPH`,
    props
  );

export interface CancelP2PCaseAndGenerateMDCasePathParams {
  /**
   * A unique identifier for a `ServiceCase`.
   */
  id: string;
}

export interface CancelP2PCaseAndGenerateMDCaseRequestBody {
  [key: string]: any;
}

export type CancelP2PCaseAndGenerateMDCaseProps = Omit<
  MutateProps<
    ServiceCase,
    unknown,
    void,
    CancelP2PCaseAndGenerateMDCaseRequestBody,
    CancelP2PCaseAndGenerateMDCasePathParams
  >,
  "path" | "verb"
> &
  CancelP2PCaseAndGenerateMDCasePathParams;

/**
 * Cancel P2P Case and Create New MD Case with same details
 *
 * cancel current p2p case and create new md case.
 */
export const CancelP2PCaseAndGenerateMDCase = ({
  id,
  ...props
}: CancelP2PCaseAndGenerateMDCaseProps) => (
  <Mutate<
    ServiceCase,
    unknown,
    void,
    CancelP2PCaseAndGenerateMDCaseRequestBody,
    CancelP2PCaseAndGenerateMDCasePathParams
  >
    verb="PATCH"
    path={`/serviceCase/${id}/changeP2PToMD`}
    {...props}
  />
);

export type UseCancelP2PCaseAndGenerateMDCaseProps = Omit<
  UseMutateProps<
    ServiceCase,
    unknown,
    void,
    CancelP2PCaseAndGenerateMDCaseRequestBody,
    CancelP2PCaseAndGenerateMDCasePathParams
  >,
  "path" | "verb"
> &
  CancelP2PCaseAndGenerateMDCasePathParams;

/**
 * Cancel P2P Case and Create New MD Case with same details
 *
 * cancel current p2p case and create new md case.
 */
export const useCancelP2PCaseAndGenerateMDCase = ({
  id,
  ...props
}: UseCancelP2PCaseAndGenerateMDCaseProps) =>
  useMutate<
    ServiceCase,
    unknown,
    void,
    CancelP2PCaseAndGenerateMDCaseRequestBody,
    CancelP2PCaseAndGenerateMDCasePathParams
  >(
    "PATCH",
    (paramsInPath: CancelP2PCaseAndGenerateMDCasePathParams) =>
      `/serviceCase/${paramsInPath.id}/changeP2PToMD`,
    { pathParams: { id }, ...props }
  );

export interface StartWorkingCasePathParams {
  /**
   * A unique identifier for a `ServiceCase`.
   */
  id: string;
}

export interface StartWorkingCaseRequestBody {
  [key: string]: any;
}

export type StartWorkingCaseProps = Omit<
  MutateProps<
    ServiceCase,
    unknown,
    void,
    StartWorkingCaseRequestBody,
    StartWorkingCasePathParams
  >,
  "path" | "verb"
> &
  StartWorkingCasePathParams;

/**
 * Start working a case in QM
 *
 * Start working a case in QM assign to current user and set status to in-progress
 */
export const StartWorkingCase = ({ id, ...props }: StartWorkingCaseProps) => (
  <Mutate<
    ServiceCase,
    unknown,
    void,
    StartWorkingCaseRequestBody,
    StartWorkingCasePathParams
  >
    verb="PATCH"
    path={`/serviceCase/${id}/startCase`}
    {...props}
  />
);

export type UseStartWorkingCaseProps = Omit<
  UseMutateProps<
    ServiceCase,
    unknown,
    void,
    StartWorkingCaseRequestBody,
    StartWorkingCasePathParams
  >,
  "path" | "verb"
> &
  StartWorkingCasePathParams;

/**
 * Start working a case in QM
 *
 * Start working a case in QM assign to current user and set status to in-progress
 */
export const useStartWorkingCase = ({
  id,
  ...props
}: UseStartWorkingCaseProps) =>
  useMutate<
    ServiceCase,
    unknown,
    void,
    StartWorkingCaseRequestBody,
    StartWorkingCasePathParams
  >(
    "PATCH",
    (paramsInPath: StartWorkingCasePathParams) =>
      `/serviceCase/${paramsInPath.id}/startCase`,
    { pathParams: { id }, ...props }
  );

export interface GetUserQPHForQueueQueryParams {
  /**
   * get qph from this optional startDate if no value provided start of the day will be considered
   */
  startDate?: string;
  /**
   * get qph from this optional endDate if no value provided end of the day will be considered
   */
  endDate?: string;
}

export interface GetUserQPHForQueuePathParams {
  queueId: string;
}

export type GetUserQPHForQueueProps = Omit<
  GetProps<
    CountResponse,
    unknown,
    GetUserQPHForQueueQueryParams,
    GetUserQPHForQueuePathParams
  >,
  "path"
> &
  GetUserQPHForQueuePathParams;

export const GetUserQPHForQueue = ({
  queueId,
  ...props
}: GetUserQPHForQueueProps) => (
  <Get<
    CountResponse,
    unknown,
    GetUserQPHForQueueQueryParams,
    GetUserQPHForQueuePathParams
  >
    path={`/queue/${queueId}/QPH`}
    {...props}
  />
);

export type UseGetUserQPHForQueueProps = Omit<
  UseGetProps<
    CountResponse,
    unknown,
    GetUserQPHForQueueQueryParams,
    GetUserQPHForQueuePathParams
  >,
  "path"
> &
  GetUserQPHForQueuePathParams;

export const useGetUserQPHForQueue = ({
  queueId,
  ...props
}: UseGetUserQPHForQueueProps) =>
  useGet<
    CountResponse,
    unknown,
    GetUserQPHForQueueQueryParams,
    GetUserQPHForQueuePathParams
  >(
    (paramsInPath: GetUserQPHForQueuePathParams) =>
      `/queue/${paramsInPath.queueId}/QPH`,
    { pathParams: { queueId }, ...props }
  );

export interface GetNextCaseForQueueQueryParams {
  timeStamp: string;
}

export interface GetNextCaseForQueuePathParams {
  queueId: string;
}

export type GetNextCaseForQueueProps = Omit<
  GetProps<
    ServiceCase,
    unknown,
    GetNextCaseForQueueQueryParams,
    GetNextCaseForQueuePathParams
  >,
  "path"
> &
  GetNextCaseForQueuePathParams;

/**
 * Find the next case for the user
 *
 * Find an open cases with the highest priority and assign to the user. If there are no open cases available, returns a valid message
 */
export const GetNextCaseForQueue = ({
  queueId,
  ...props
}: GetNextCaseForQueueProps) => (
  <Get<
    ServiceCase,
    unknown,
    GetNextCaseForQueueQueryParams,
    GetNextCaseForQueuePathParams
  >
    path={`/queue/${queueId}/nextCase`}
    {...props}
  />
);

export type UseGetNextCaseForQueueProps = Omit<
  UseGetProps<
    ServiceCase,
    unknown,
    GetNextCaseForQueueQueryParams,
    GetNextCaseForQueuePathParams
  >,
  "path"
> &
  GetNextCaseForQueuePathParams;

/**
 * Find the next case for the user
 *
 * Find an open cases with the highest priority and assign to the user. If there are no open cases available, returns a valid message
 */
export const useGetNextCaseForQueue = ({
  queueId,
  ...props
}: UseGetNextCaseForQueueProps) =>
  useGet<
    ServiceCase,
    unknown,
    GetNextCaseForQueueQueryParams,
    GetNextCaseForQueuePathParams
  >(
    (paramsInPath: GetNextCaseForQueuePathParams) =>
      `/queue/${paramsInPath.queueId}/nextCase`,
    { pathParams: { queueId }, ...props }
  );

export type GetActiveQueueForUserProps = Omit<
  GetProps<Queue, unknown, void, void>,
  "path"
>;

/**
 * Get the active queue information for the user
 *
 * Get the active queue information for the user.
 */
export const GetActiveQueueForUser = (props: GetActiveQueueForUserProps) => (
  <Get<Queue, unknown, void, void> path={`/queue/myActiveQueue`} {...props} />
);

export type UseGetActiveQueueForUserProps = Omit<
  UseGetProps<Queue, unknown, void, void>,
  "path"
>;

/**
 * Get the active queue information for the user
 *
 * Get the active queue information for the user.
 */
export const useGetActiveQueueForUser = (
  props: UseGetActiveQueueForUserProps
) => useGet<Queue, unknown, void, void>(`/queue/myActiveQueue`, props);

export interface GetQueueByIdPathParams {
  queueId: string;
}

export type GetQueueByIdProps = Omit<
  GetProps<Queue, unknown, void, GetQueueByIdPathParams>,
  "path"
> &
  GetQueueByIdPathParams;

/**
 * Find a Queue by id
 *
 * Find a Queue via Queue Id
 */
export const GetQueueById = ({ queueId, ...props }: GetQueueByIdProps) => (
  <Get<Queue, unknown, void, GetQueueByIdPathParams>
    path={`/queue/${queueId}`}
    {...props}
  />
);

export type UseGetQueueByIdProps = Omit<
  UseGetProps<Queue, unknown, void, GetQueueByIdPathParams>,
  "path"
> &
  GetQueueByIdPathParams;

/**
 * Find a Queue by id
 *
 * Find a Queue via Queue Id
 */
export const useGetQueueById = ({ queueId, ...props }: UseGetQueueByIdProps) =>
  useGet<Queue, unknown, void, GetQueueByIdPathParams>(
    (paramsInPath: GetQueueByIdPathParams) => `/queue/${paramsInPath.queueId}`,
    { pathParams: { queueId }, ...props }
  );

export type GetQueuesForUserProps = Omit<
  GetProps<Queue[], unknown, void, void>,
  "path"
>;

/**
 * Get user Queues
 */
export const GetQueuesForUser = (props: GetQueuesForUserProps) => (
  <Get<Queue[], unknown, void, void> path={`/queue/myQueues`} {...props} />
);

export type UseGetQueuesForUserProps = Omit<
  UseGetProps<Queue[], unknown, void, void>,
  "path"
>;

/**
 * Get user Queues
 */
export const useGetQueuesForUser = (props: UseGetQueuesForUserProps) =>
  useGet<Queue[], unknown, void, void>(`/queue/myQueues`, props);

export interface SetActiveQueueForUserQueryParams {
  /**
   * A unique identifier for a queue
   */
  queueId: string;
}

export interface SetActiveQueueForUserRequestBody {
  [key: string]: any;
}

export type SetActiveQueueForUserProps = Omit<
  MutateProps<
    Queue,
    unknown,
    SetActiveQueueForUserQueryParams,
    SetActiveQueueForUserRequestBody,
    void
  >,
  "path" | "verb"
>;

/**
 * Set the active queue information for the user
 *
 * Set the active queue information for the user.
 */
export const SetActiveQueueForUser = (props: SetActiveQueueForUserProps) => (
  <Mutate<
    Queue,
    unknown,
    SetActiveQueueForUserQueryParams,
    SetActiveQueueForUserRequestBody,
    void
  >
    verb="PATCH"
    path={`/queue/setMyActiveQueue`}
    {...props}
  />
);

export type UseSetActiveQueueForUserProps = Omit<
  UseMutateProps<
    Queue,
    unknown,
    SetActiveQueueForUserQueryParams,
    SetActiveQueueForUserRequestBody,
    void
  >,
  "path" | "verb"
>;

/**
 * Set the active queue information for the user
 *
 * Set the active queue information for the user.
 */
export const useSetActiveQueueForUser = (
  props: UseSetActiveQueueForUserProps
) =>
  useMutate<
    Queue,
    unknown,
    SetActiveQueueForUserQueryParams,
    SetActiveQueueForUserRequestBody,
    void
  >("PATCH", `/queue/setMyActiveQueue`, props);

export type SoftDeleteCasesProps = Omit<
  MutateProps<SoftDeleteCasesResponse, unknown, void, string[], void>,
  "path" | "verb"
>;

/**
 * Soft delete multiple service cases
 */
export const SoftDeleteCases = (props: SoftDeleteCasesProps) => (
  <Mutate<SoftDeleteCasesResponse, unknown, void, string[], void>
    verb="DELETE"
    path={`/serviceCase/deleteMany`}
    {...props}
  />
);

export type UseSoftDeleteCasesProps = Omit<
  UseMutateProps<SoftDeleteCasesResponse, unknown, void, string[], void>,
  "path" | "verb"
>;

/**
 * Soft delete multiple service cases
 */
export const useSoftDeleteCases = (props: UseSoftDeleteCasesProps) =>
  useMutate<SoftDeleteCasesResponse, unknown, void, string[], void>(
    "DELETE",
    `/serviceCase/deleteMany`,
    { ...props }
  );

export type UnassignCasesProps = Omit<
  MutateProps<UnassignCasesResponse, unknown, void, string[], void>,
  "path" | "verb"
>;

/**
 * Unassign multiple service cases
 */
export const UnassignCases = (props: UnassignCasesProps) => (
  <Mutate<UnassignCasesResponse, unknown, void, string[], void>
    verb="PATCH"
    path={`/serviceCase/unassignMany`}
    {...props}
  />
);

export type UseUnassignCasesProps = Omit<
  UseMutateProps<UnassignCasesResponse, unknown, void, string[], void>,
  "path" | "verb"
>;

/**
 * Unassign multiple service cases
 */
export const useUnassignCases = (props: UseUnassignCasesProps) =>
  useMutate<UnassignCasesResponse, unknown, void, string[], void>(
    "PATCH",
    `/serviceCase/unassignMany`,
    props
  );

export interface AppendHoldPathParams {
  id: string;
}

export type AppendHoldProps = Omit<
  MutateProps<ServiceCase, unknown, void, CaseHoldEntry, AppendHoldPathParams>,
  "path" | "verb"
> &
  AppendHoldPathParams;

/**
 * Append hold entry for user and service case
 */
export const AppendHold = ({ id, ...props }: AppendHoldProps) => (
  <Mutate<ServiceCase, unknown, void, CaseHoldEntry, AppendHoldPathParams>
    verb="PATCH"
    path={`/serviceCase/${id}/caseHolds`}
    {...props}
  />
);

export type UseAppendHoldProps = Omit<
  UseMutateProps<
    ServiceCase,
    unknown,
    void,
    CaseHoldEntry,
    AppendHoldPathParams
  >,
  "path" | "verb"
> &
  AppendHoldPathParams;

/**
 * Append hold entry for user and service case
 */
export const useAppendHold = ({ id, ...props }: UseAppendHoldProps) =>
  useMutate<ServiceCase, unknown, void, CaseHoldEntry, AppendHoldPathParams>(
    "PATCH",
    (paramsInPath: AppendHoldPathParams) =>
      `/serviceCase/${paramsInPath.id}/caseHolds`,
    { pathParams: { id }, ...props }
  );

export interface RemoveHoldPathParams {
  id: string;
}

export type RemoveHoldProps = Omit<
  MutateProps<ServiceCase, unknown, void, void, RemoveHoldPathParams>,
  "path" | "verb"
> &
  RemoveHoldPathParams;

/**
 * Soft delete hold entry for user and service case
 */
export const RemoveHold = ({ id, ...props }: RemoveHoldProps) => (
  <Mutate<ServiceCase, unknown, void, void, RemoveHoldPathParams>
    verb="DELETE"
    path={`/serviceCase/${id}/caseHolds`}
    {...props}
  />
);

export type UseRemoveHoldProps = Omit<
  UseMutateProps<ServiceCase, unknown, void, void, RemoveHoldPathParams>,
  "path" | "verb"
> &
  RemoveHoldPathParams;

/**
 * Soft delete hold entry for user and service case
 */
export const useRemoveHold = ({ id, ...props }: UseRemoveHoldProps) =>
  useMutate<ServiceCase, unknown, void, void, RemoveHoldPathParams>(
    "DELETE",
    (paramsInPath: RemoveHoldPathParams) =>
      `/serviceCase/${paramsInPath.id}/caseHolds`,
    { pathParams: { id }, ...props }
  );

export type UploadServiceCaseForAuditProps = Omit<
  MutateProps<UploadAuditCasesResponse, unknown, void, void, void>,
  "path" | "verb"
>;

/**
 * Upload service cases for audit.
 *
 * Upload service cases for audit.
 */
export const UploadServiceCaseForAudit = (
  props: UploadServiceCaseForAuditProps
) => (
  <Mutate<UploadAuditCasesResponse, unknown, void, void, void>
    verb="POST"
    path={`/serviceCase/uploadAuditCases`}
    {...props}
  />
);

export type UseUploadServiceCaseForAuditProps = Omit<
  UseMutateProps<UploadAuditCasesResponse, unknown, void, void, void>,
  "path" | "verb"
>;

/**
 * Upload service cases for audit.
 *
 * Upload service cases for audit.
 */
export const useUploadServiceCaseForAudit = (
  props: UseUploadServiceCaseForAuditProps
) =>
  useMutate<UploadAuditCasesResponse, unknown, void, void, void>(
    "POST",
    `/serviceCase/uploadAuditCases`,
    props
  );
