import { PrimaryButton, SecondaryButton, TertiaryButton, useFeature } from "@coherehealth/common";
import { MdReview, NurseReview, PeerToPeerReview, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Dispatch, SetStateAction, useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { SaveReviewDeterminationUpdatesProps } from "./DenialLetterContent";
import OutcomeContextCollectionModal from "../Modals/OutcomeContextCollectionModal";
import { useLocation, useNavigate } from "react-router";
import { redirectToPatientSummaryFromReview } from "util/routeUtils/routeUtils";
import { useLatestReviewCheck } from "./hooks/useLatestReviewCheck";

interface DenialLetterControlPanelProps {
  serviceRequest: ServiceRequestResponse | null;
  review: MdReview | PeerToPeerReview | NurseReview | null;
  reviewUpdateLoading: boolean;
  reviewSubmitLoading: boolean;
  canSubmitForReview: boolean;
  hasMadeChanges: boolean;
  setDenialSentModalOpen: Dispatch<SetStateAction<boolean>>;
  setEditsMadeModalOpen: Dispatch<SetStateAction<boolean>>;
  saveReviewDeterminationUpdates: (
    saveReviewDeterminationUpdatesProps: SaveReviewDeterminationUpdatesProps
  ) => Promise<void>;
  getServiceRequestOnClick: () => Promise<ServiceRequestResponse | null>;
  serviceRequestLoadingOnClick: boolean;
  hasDenialBeenSent: boolean | null;
  selectTemplate?: boolean;
  handleSelectTemplate?: () => void;
  handleCancel?: () => void;
  disableSelection?: boolean;
  setIncorrectReviewForDenialLetterModal?: Dispatch<SetStateAction<boolean>>;
  disableDueToWrongReview?: boolean;
  setDisableDueToWrongReview?: Dispatch<SetStateAction<boolean>>;
  discardReviewDraft: (stayOnPage?: any) => Promise<void>;
  isValidDenialLetterProcedureCodePopulate: boolean;
  setIsPreviewLetterButtonClicked: Dispatch<SetStateAction<boolean>>;
}

export default function DenialLetterControlPanel({
  serviceRequest,
  review,
  reviewUpdateLoading,
  reviewSubmitLoading,
  canSubmitForReview,
  hasMadeChanges,
  hasDenialBeenSent,
  setDenialSentModalOpen,
  setEditsMadeModalOpen,
  saveReviewDeterminationUpdates,
  getServiceRequestOnClick,
  serviceRequestLoadingOnClick,
  selectTemplate,
  handleSelectTemplate,
  handleCancel,
  disableSelection,
  setIncorrectReviewForDenialLetterModal,
  disableDueToWrongReview,
  setDisableDueToWrongReview,
  discardReviewDraft,
  isValidDenialLetterProcedureCodePopulate,
  setIsPreviewLetterButtonClicked,
}: DenialLetterControlPanelProps) {
  return (
    //compliance user edit case - should show big red deny button and go back button
    <DenialLetterFlowControlButtons
      hasDenialBeenSent={hasDenialBeenSent}
      setDenialSentModalOpen={setDenialSentModalOpen}
      hasMadeChanges={hasMadeChanges}
      setEditsMadeModalOpen={setEditsMadeModalOpen}
      saveReviewDeterminationUpdates={saveReviewDeterminationUpdates}
      reviewUpdateLoading={reviewUpdateLoading || reviewSubmitLoading}
      getServiceRequestOnClick={getServiceRequestOnClick}
      serviceRequestLoadingOnClick={serviceRequestLoadingOnClick}
      serviceRequest={serviceRequest}
      selectTemplate={selectTemplate}
      handleSelectTemplate={handleSelectTemplate}
      handleCancel={handleCancel}
      disableSelection={disableSelection}
      canSubmitForReview={canSubmitForReview}
      review={review}
      setIncorrectReviewForDenialLetterModal={setIncorrectReviewForDenialLetterModal}
      disableDueToWrongReview={disableDueToWrongReview}
      setDisableDueToWrongReview={setDisableDueToWrongReview}
      discardReviewDraft={discardReviewDraft}
      isValidDenialLetterProcedureCodePopulate={isValidDenialLetterProcedureCodePopulate}
      setIsPreviewLetterButtonClicked={setIsPreviewLetterButtonClicked}
    />
  );
}

interface DenialLetterFlowControlProps {
  hasDenialBeenSent: boolean | null;
  setDenialSentModalOpen: Dispatch<SetStateAction<boolean>>;
  hasMadeChanges: boolean;
  setEditsMadeModalOpen: Dispatch<SetStateAction<boolean>>;
  saveReviewDeterminationUpdates: (
    saveReviewDeterminationUpdatesProps: SaveReviewDeterminationUpdatesProps
  ) => Promise<void>;
  reviewUpdateLoading: boolean;
  getServiceRequestOnClick: () => Promise<ServiceRequestResponse | null>;
  serviceRequestLoadingOnClick: boolean;
  serviceRequest: ServiceRequestResponse | null;
  selectTemplate?: boolean;
  handleSelectTemplate?: () => void;
  handleCancel?: () => void;
  disableSelection?: boolean;
  canSubmitForReview: boolean;
  review: MdReview | PeerToPeerReview | NurseReview | null;
  setIncorrectReviewForDenialLetterModal?: Dispatch<SetStateAction<boolean>>;
  disableDueToWrongReview?: boolean;
  setDisableDueToWrongReview?: Dispatch<SetStateAction<boolean>>;
  discardReviewDraft: (stayOnPage?: any, denialStatus?: "COMPLETE" | "DRAFT") => Promise<void>;
  isValidDenialLetterProcedureCodePopulate: boolean;
  setIsPreviewLetterButtonClicked: Dispatch<SetStateAction<boolean>>;
}

const DenialLetterFlowControlButtons = ({
  hasDenialBeenSent,
  setDenialSentModalOpen,
  hasMadeChanges,
  setEditsMadeModalOpen,
  saveReviewDeterminationUpdates,
  reviewUpdateLoading,
  getServiceRequestOnClick,
  serviceRequestLoadingOnClick,
  serviceRequest,
  selectTemplate,
  handleSelectTemplate,
  handleCancel,
  disableSelection,
  canSubmitForReview,
  review,
  setIncorrectReviewForDenialLetterModal,
  disableDueToWrongReview,
  setDisableDueToWrongReview,
  discardReviewDraft,
  isValidDenialLetterProcedureCodePopulate,
  setIsPreviewLetterButtonClicked,
}: DenialLetterFlowControlProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const disableDenialPreview =
    !canSubmitForReview || serviceRequestLoadingOnClick || reviewUpdateLoading || disableDueToWrongReview;
  const isGHPServiceRequest = serviceRequest?.healthPlanName === "Geisinger";
  const [isOutcomeContextCollectionModalOpen, setIsOutcomeContextCollectionModalOpen] = useState(false);
  const caseId = search.get("caseId") || "";
  const saveReviews = useFeature("saveReviews");

  const saveAndGoBack = () => {
    if (hasDenialBeenSent) {
      setDenialSentModalOpen(true);
    } else if (hasMadeChanges) {
      setEditsMadeModalOpen(true);
    } else {
      saveReviewDeterminationUpdates({ denialStatus: "DRAFT" });
    }
  };

  const { checkLatestReview, isCheckingLatestReview } = useLatestReviewCheck({
    serviceRequestId: serviceRequest?.id || "",
    currentReviewId: review?.id,
    onIncorrectReview: () => {
      if (setIncorrectReviewForDenialLetterModal) {
        setIncorrectReviewForDenialLetterModal(true);
      }
      if (setDisableDueToWrongReview) {
        setDisableDueToWrongReview(true);
      }
    },
  });

  const handlePreviewClick = async () => {
    setIsPreviewLetterButtonClicked(true);

    const isCorrectReview = await checkLatestReview();
    if (!isCorrectReview) {
      setDisableDueToWrongReview?.(true);
      setIncorrectReviewForDenialLetterModal?.(true);
      return;
    }

    if (isGHPServiceRequest) {
      setIsOutcomeContextCollectionModalOpen(true);
    } else if (isValidDenialLetterProcedureCodePopulate) {
      getServiceRequestOnClick();
    }
  };

  return (
    <>
      {selectTemplate ? (
        <>
          <SecondarySaveAndGoBackWithoutMDReviewButton onClick={handleCancel}>
            Cancel
          </SecondarySaveAndGoBackWithoutMDReviewButton>
          <PrimaryDeterminationLetterButton
            onClick={handleSelectTemplate}
            disableRipple
            loading={reviewUpdateLoading}
            disabled={disableSelection || reviewUpdateLoading}
          >
            Confirm selection
          </PrimaryDeterminationLetterButton>
        </>
      ) : (
        <>
          {disableDueToWrongReview ? (
            <TertiaryDiscardAndExitButton
              onClick={() => {
                redirectToPatientSummaryFromReview({ serviceRequest, caseId, navigate });
              }}
            >
              Discard and exit
            </TertiaryDiscardAndExitButton>
          ) : (
            <>
              {saveReviews ? (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <TertiaryCustomButton
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      discardReviewDraft(undefined, "DRAFT");
                    }}
                    disabled={reviewUpdateLoading}
                  >
                    Discard and exit
                  </TertiaryCustomButton>
                  <TertiaryCustomButton
                    style={{ marginLeft: "10px" }}
                    onClick={saveAndGoBack}
                    disabled={reviewUpdateLoading}
                    data-public
                  >
                    Save and exit
                  </TertiaryCustomButton>
                </div>
              ) : (
                <SecondarySaveAndGoBackWithoutMDReviewButton
                  onClick={saveAndGoBack}
                  loading={reviewUpdateLoading}
                  disabled={reviewUpdateLoading}
                >
                  Save & go back
                </SecondarySaveAndGoBackWithoutMDReviewButton>
              )}
            </>
          )}
          <PrimaryDeterminationLetterButton
            onClick={handlePreviewClick}
            disableRipple
            loading={serviceRequestLoadingOnClick || isCheckingLatestReview}
            disabled={disableDenialPreview || isCheckingLatestReview}
            style={disableDueToWrongReview || saveReviews ? { width: "260px", marginLeft: "20px" } : undefined}
          >
            Preview determination letter
          </PrimaryDeterminationLetterButton>
          <OutcomeContextCollectionModal
            isOpen={isOutcomeContextCollectionModalOpen}
            setIsOpen={setIsOutcomeContextCollectionModalOpen}
            review={review}
            serviceRequest={serviceRequest}
            getServiceRequestOnClick={getServiceRequestOnClick}
            saveReviewDeterminationUpdates={saveReviewDeterminationUpdates}
          />
        </>
      )}
    </>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const PrimaryDeterminationLetterButton = styled(PrimaryButton)(({ theme }) => ({
  height: theme.spacing(6),
  width: theme.spacing(32.25),
  padding: theme.spacing(2, 2.625),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SecondarySaveAndGoBackWithoutMDReviewButton = styled(SecondaryButton)(({ theme }) => ({
  height: theme.spacing(6),
  width: theme.spacing(32.25),
  marginRight: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TertiaryDiscardAndExitButton = styled(TertiaryButton)(({ theme }) => ({
  minWidth: 150,
  padding: 5,
  height: 48,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const TertiaryCustomButton = styled(TertiaryButton)(({ theme }) => ({
  width: 138,
  height: 48,
  padding: theme.spacing(0),
}));
