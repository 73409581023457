import type { AuthorizationResponse, AuthStatus, PatientStatus } from "@coherehealth/core-platform-api";
import type { StayGroup } from "../PatientStayTimeline/PatientStayTimelineItem";
import type { PatientStayDateTableData } from "../hooks/useFetchPatientStayDates";
import type { TimelineItem } from "../hooks/usePatientStayTimelineItems";
import type { Theme } from "@material-ui/core";

import { isTerminalStatus } from "util/serviceRequest";

/**
 * Represents the minimal required data structure to construct a timeline stay entry
 */
export type TimelineStayData = {
  record: Pick<PatientStayDateTableData["record"], "dates" | "status" | "days">;
};

/**
 * Main function to generate timeline groups based on authorization and stay dates
 * @param authorization - The authorization response containing patient and auth status
 * @param groupedStayDatesForTimeline - Array of grouped stay dates for timeline display
 * @returns Array of StayGroup objects representing timeline segments
 */
export const getTimelineGroups = (
  authorization?: AuthorizationResponse,
  groupedStayDatesForTimeline?: TimelineStayData[] | null
): StayGroup[] => {
  if (!authorization) {
    return [];
  }

  const { patientStatus, authStatus = "PENDING" } = authorization;

  const hasStayDates = groupedStayDatesForTimeline && groupedStayDatesForTimeline?.length > 0;

  // Case 1: Not yet admitted patients without stay dates
  if (patientStatus === "NOT_YET_ADMITTED" && !hasStayDates) {
    return createNotYetAdmittedGroup(authStatus);
  }

  // Case 2: Patients with stay dates
  if (hasStayDates) {
    return formatTimelineGroups(groupedStayDatesForTimeline);
  }

  // Case 3: Default fallback - THIS SHOULD NEVER HAPPEN, but just in case
  return groupedStayDatesForTimeline ?? [];
};

/**
 * Creates a timeline group for patients not yet admitted
 * @param authStatus - The authorization status
 * @returns Array of StayGroup for not yet admitted patients
 */
const createNotYetAdmittedGroup = (authStatus: string): StayGroup[] => {
  const isPending = authStatus === "PENDING";

  const mainText = isPending
    ? "Stay dates will display in timeline as they are added"
    : `Admit ${authStatus?.toLowerCase() || ""}, additional stay day review pending`;

  return [
    {
      record: {
        status: authStatus,
        days: mainText,
        dates: "",
        hideStatus: true,
      },
    },
  ];
};

/**
 * Determines the admission status based on the grouped stay dates
 * @param groupedStayDates - Array of stay dates
 * @returns Admission status (APPROVED, DENIED, or PENDING)
 */
const determineAdmitStatus = (groupedStayDates: TimelineStayData[]): string => {
  const hasTerminalOutcome = groupedStayDates.some((stay) =>
    isTerminalStatus({ authStatus: stay.record.status.toUpperCase() as AuthStatus })
  );
  const hasApproved = groupedStayDates.some((stay) => stay.record.status.toUpperCase() === "APPROVED");

  if (hasTerminalOutcome) {
    return hasApproved ? "APPROVED" : "DENIED";
  }
  return "PENDING";
};

/**
 * Formats timeline groups with admission stay information
 * @param authStatus - The authorization status
 * @param groupedStayDates - Array of stay dates
 * @returns Formatted array of StayGroup
 */
const formatTimelineGroups = (groupedStayDates: TimelineStayData[]): StayGroup[] => {
  const groups: StayGroup[] = [...groupedStayDates];

  if (groups.length > 0) {
    const admitStatus = determineAdmitStatus(groups);
    groups.unshift(formatAdmitStay(admitStatus, groups[0]));
  }

  return groups;
};

/**
 * Formats a single admission stay
 * @param admitStatus - The determined admit status based on all stays
 * @param stay - The stay information
 * @returns Formatted StayGroup
 */
const formatAdmitStay = (admitStatus: string, stay: TimelineStayData | StayGroup): StayGroup => {
  const admitStatusText = admitStatus?.toLowerCase() || "";
  const mainText = `Admission ${admitStatusText}`;

  return {
    record: {
      status: admitStatusText.toUpperCase(),
      days: mainText,
      dates: stay.record.dates.split(" - ")[0],
      hideStatus: true,
    },
  };
};

/**
 * Interface defining the style properties for timeline dots and lines
 */
type StatusColorMap = Record<string, { main: string; light: string; dark: string }>;

/**
 * Union type defining the different types of dots that can appear in the timeline
 * and their required properties
 */
type DotType =
  | { type: "start"; status: string }
  | { type: "middle"; status: string }
  | { type: "end"; status: string; patientStatus: PatientStatus | undefined; authStatus: AuthStatus | undefined };

/**
 * Generates the style configuration for timeline dots based on their type and status
 * @param dot - The dot configuration object containing type and status information
 * @param statusColorMap - Map of status codes to their corresponding colors
 * @returns Style object for the dot
 */
export const getDotStyle = (dot: DotType, statusColorMap: StatusColorMap) => {
  const defaultDot = {
    borderColor: statusColorMap["PENDING"]?.dark,
    backgroundColor: "white",
  };

  switch (dot.type) {
    case "start":
      if (dot.status !== "PENDING") {
        return {
          ...defaultDot,
          borderColor: statusColorMap[dot.status.toUpperCase()]?.dark,
          backgroundColor: statusColorMap[dot.status.toUpperCase()]?.dark,
        };
      }
      return defaultDot;

    case "middle":
      if (dot.status !== "PENDING" && dot.status !== "PENDING REVIEW") {
        return {
          ...defaultDot,
          borderColor: statusColorMap[dot.status.toUpperCase()]?.dark,
          backgroundColor: statusColorMap[dot.status.toUpperCase()]?.dark,
        };
      }
      return defaultDot;

    case "end":
      if (dot.patientStatus === "DISCHARGED" && isTerminalStatus({ authStatus: dot.authStatus })) {
        return {
          ...defaultDot,
          borderColor: statusColorMap[dot.status.toUpperCase()]?.dark,
          backgroundColor: statusColorMap[dot.status.toUpperCase()]?.dark,
        };
      }
      return defaultDot;
  }
};

/**
 * Generates the style configuration for timeline line segments
 * @param status - The current status of the line segment
 * @param patientStatus - The overall patient status
 * @param statusColorMap - Map of status codes to their corresponding colors
 * @returns Style object for the line segment
 */
export const getLineStyle = (status: string, statusColorMap: StatusColorMap) => {
  return {
    backgroundColor: statusColorMap[status.toUpperCase()]?.dark || statusColorMap.DEFAULT.dark,
  };
};

/**
 * Formats a date range string into a localized date range display
 * @param dateRange - A string containing two dates separated by " - " (e.g. "2023-01-01 - 2023-01-31")
 * @returns A formatted string with localized dates in the following cases:
 * - Empty string if input is empty or start date is missing
 * - Single date (e.g. "1/1/2023") if:
 *   - Only start date is provided
 *   - Start and end dates are the same
 * - Date range (e.g. "1/1/2023 - 1/31/2023") if:
 *   - Both start and end dates are provided and different
 */
export const formatDateRange = (dateRange: string) => {
  if (!dateRange) {
    return "";
  }

  const [start, end] = dateRange.split(" - ").map((date) => (date ? formatDate(new Date(date)) : null));
  if (!start) {
    return "";
  }

  return end && end !== start
    ? `${formatDate(new Date(start))} - ${formatDate(new Date(end))}`
    : formatDate(new Date(start));
};

/**
 * Formats a date into a localized date display
 * @param date - The date to format
 * @returns A formatted string with localized date (e.g. "1/1/2023")
 */
const formatDate = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  return `${month}/${day}/${year}`;
};

/**
 * Creates a timeline dot item
 */
const createDotItem = (
  dotType: "start" | "middle" | "end",
  status: string,
  authorization: AuthorizationResponse,
  statusColorMap: StatusColorMap
): TimelineItem => ({
  type: "dot",
  style: getDotStyle(
    {
      type: dotType,
      status,
      patientStatus: authorization?.patientStatus,
      authStatus: authorization?.authStatus,
    },
    statusColorMap
  ),
});

/**
 * Creates a timeline line item
 */
const createLineItem = (
  group: StayGroup["record"],
  numGroups: number,
  statusColorMap: StatusColorMap,
  options?: {
    isLast?: boolean;
    hasEndDot?: boolean;
    dischargedText?: string;
  }
): TimelineItem => ({
  type: "line",
  style: {
    ...getLineStyle(group.status.toUpperCase(), statusColorMap),
    ...(options?.isLast && {
      width: `calc(100% - ${options.hasEndDot ? `${192 * (numGroups - 1)}px` : "4px"}`,
      overflow: "hidden",
    }),
  },
  mainText: `${group.days} ${group.hideStatus ? "" : group.status.toLowerCase()}`,
  subText: formatDateRange(group.dates),
  ...(options?.dischargedText && { dischargedText: options.dischargedText }),
});

/**
 * Gets discharge text based on patient status and auth status
 */
const getDischargeText = (authorization?: AuthorizationResponse): string | undefined => {
  if (authorization?.patientStatus !== "DISCHARGED") {
    return undefined;
  }

  return isTerminalStatus({ authStatus: authorization.authStatus }) ? "Discharged" : "Pending discharge review";
};

/**
 * Builds the array of timeline items with appropriate styling and content
 */
export const buildTimelineItems = (
  timelineGroups: StayGroup[],
  authorization: AuthorizationResponse,
  statusColorMap: StatusColorMap
): TimelineItem[] => {
  const timelineItems: TimelineItem[] = [];
  const hasEndDot = authorization?.patientStatus === "DISCHARGED";

  timelineGroups.forEach((group, index) => {
    const isFirst = index === 0;
    const isLast = index === timelineGroups.length - 1;

    // Add dot
    timelineItems.push(
      createDotItem(isFirst ? "start" : "middle", group.record.status.toUpperCase(), authorization, statusColorMap)
    );

    // Add line
    timelineItems.push(
      createLineItem(group.record, timelineGroups.length, statusColorMap, {
        isLast,
        hasEndDot,
        dischargedText: isLast ? getDischargeText(authorization) : undefined,
      })
    );
  });

  // Add end dot if discharged
  if (hasEndDot) {
    timelineItems.push(createDotItem("end", timelineGroups.at(-1)?.record.status || "", authorization, statusColorMap));
  }

  return timelineItems;
};

/**
 * Returns a mapping of status types to their corresponding theme colors
 * @param theme - Material UI theme object containing color palette definitions
 * @returns Record mapping status strings to color objects with main, light and dark variants
 */
export const getStatusColorMap = (theme: Theme) => {
  const warningColors = {
    main: theme.palette.warning.main,
    light: theme.palette.warning.light,
    dark: theme.palette.warning.dark,
  };

  const successColors = {
    main: theme.palette.success.main,
    light: theme.palette.success.light,
    dark: theme.palette.success.dark,
  };

  const errorColors = {
    main: theme.palette.error.main,
    light: theme.palette.error.light,
    dark: theme.palette.error.dark,
  };

  return {
    APPROVED: successColors,
    DENIED: errorColors,
    PENDING: warningColors,
    "PENDING REVIEW": warningColors,
    "PENDING CONCURRENT REVIEW": warningColors,
    DEFAULT: warningColors,
  } as Record<string, { main: string; light: string; dark: string }>;
};

/**
 * Gets the theme colors for a given status
 * @param status - The status string to get colors for
 * @param theme - Material UI theme object containing color palette definitions
 * @returns Object containing main, light and dark color variants for the status
 */
export const getStatusColors = (status: string, theme: Theme) => {
  const statusColorMap = getStatusColorMap(theme);
  return statusColorMap[status?.toUpperCase()] || statusColorMap.DEFAULT;
};
