import { Grid } from "@material-ui/core";
import { CardTextField, formatDateStr } from "@coherehealth/common";
import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { aggregateStayDateByCommonFields } from "components/ServiceRequest/PatientStay/PatientStays";
import { getStayDateRangeString } from "util/serviceRequest";
import { requestTimingToString } from "util/authorization";

export interface CommonSelectionCardProps {
  authorization: AuthorizationResponse | null;
  latestServiceRequest: ServiceRequestResponse | null;
}

export function AuthorizationInpatientSection(props: CommonSelectionCardProps): JSX.Element {
  const { authorization, latestServiceRequest } = props;
  const {
    primaryDiagnosis,
    secondaryDiagnoses,
    admissionDate,
    dischargeDate,
    expectedAdmissionDate,
    patientStayDates,
  } = latestServiceRequest ?? {};

  const { patientStatus } = authorization ?? {};

  const patientStayDateRange = patientStayDates ? aggregateStayDateByCommonFields(patientStayDates) : [];
  const patientStayDateRangeString = getStayDateRangeString(patientStayDateRange);

  //auth builder currently admitted label is too long for the fax card
  const patientStatusString =
    patientStatus === "CURRENTLY_ADMITTED" ? "Admitted" : requestTimingToString(patientStatus);

  const secondaryDiagnosisString = secondaryDiagnoses
    ? secondaryDiagnoses.map((diagnosis) => diagnosis.code).join(",")
    : "--";
  return (
    <>
      <Grid item xs={3} data-public data-testid="primary_dx">
        <CardTextField label="Primary diagnosis" content={`${primaryDiagnosis?.code}`} data-public />
      </Grid>
      <Grid item xs={4} data-public data-testid="secondary_dx">
        <CardTextField label="Secondary diagnosis" content={secondaryDiagnosisString} data-public />
      </Grid>
      <Grid item xs={5}>
        <CardTextField label={"Review dates"} content={patientStayDateRangeString} />
      </Grid>

      <Grid item xs={3} data-public data-testid="patient-status">
        <CardTextField label="Patient status" content={patientStatusString} data-public />
      </Grid>
      {admissionDate && (
        <Grid item xs={4} data-public data-testid="admission-date">
          <CardTextField label="Admission date" content={formatDateStr(admissionDate)} />
        </Grid>
      )}
      {!admissionDate && (
        <Grid item xs={4} data-public data-testid="expect-admission-date">
          <CardTextField label="Expected admission date" content={formatDateStr(expectedAdmissionDate)} />
        </Grid>
      )}
      <Grid item xs={5}>
        <CardTextField label="Discharge date" content={formatDateStr(dischargeDate)} />
      </Grid>
    </>
  );
}
