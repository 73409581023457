import React, { SVGProps } from "react";

export default function EnvironmentallyFriendlyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="eco_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24 1">
        <path
          id="Vector"
          d="M23.4 84.9335C20.15 81.6835 17.6042 77.928 15.7625 73.6669C13.9208 69.4058 13 65.0002 13 60.4502C13 55.9002 13.8667 51.4044 15.6 46.9627C17.3333 42.521 20.15 38.3502 24.05 34.4502C26.5778 31.9224 29.7014 29.7558 33.4208 27.9502C37.1403 26.1446 41.5458 24.7183 46.6375 23.671C51.7292 22.6238 57.5431 21.9919 64.0792 21.7752C70.6153 21.5585 77.9278 21.8113 86.0167 22.5335C86.5945 30.1891 86.775 37.2308 86.5583 43.6585C86.3417 50.0863 85.7458 55.8821 84.7708 61.046C83.7958 66.2099 82.4236 70.7238 80.6542 74.5877C78.8847 78.4516 76.7 81.6835 74.1 84.2835C70.2722 88.1113 66.2097 90.9099 61.9125 92.6794C57.6153 94.4488 53.2278 95.3335 48.75 95.3335C44.0556 95.3335 39.4694 94.4127 34.9917 92.571C30.5139 90.7294 26.65 88.1835 23.4 84.9335ZM35.5333 83.2002C37.6278 84.428 39.7764 85.3127 41.9792 85.8544C44.1819 86.396 46.4389 86.6669 48.75 86.6669C52.0722 86.6669 55.3583 85.9988 58.6083 84.6627C61.8583 83.3266 64.9639 81.178 67.925 78.2169C69.225 76.9169 70.5431 75.0933 71.8792 72.746C73.2153 70.3988 74.3708 67.3294 75.3458 63.5377C76.3208 59.746 77.0611 55.1599 77.5667 49.7794C78.0722 44.3988 78.1444 37.9891 77.7833 30.5502C74.2444 30.4058 70.2542 30.3516 65.8125 30.3877C61.3708 30.4238 56.9472 30.7669 52.5417 31.4169C48.1361 32.0669 43.9472 33.1141 39.975 34.5585C36.0028 36.003 32.7528 37.9891 30.225 40.5169C26.975 43.7669 24.7361 46.9808 23.5083 50.1585C22.2806 53.3363 21.6667 56.4058 21.6667 59.3669C21.6667 63.628 22.4792 67.3655 24.1042 70.5794C25.7292 73.7933 27.1556 76.0502 28.3833 77.3502C31.4167 71.5724 35.425 66.0294 40.4083 60.721C45.3917 55.4127 51.2056 51.0613 57.85 47.6669C52.65 52.2169 48.1181 57.3627 44.2542 63.1044C40.3903 68.846 37.4833 75.5446 35.5333 83.2002Z"
          fill="#04A15F"
        />
      </g>
    </svg>
  );
}
