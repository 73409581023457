import React, {
  ComponentProps,
  Dispatch,
  FunctionComponent,
  HTMLAttributes,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  PrimaryButton,
  TertiaryButton,
  today,
  parseDateFromISOString,
  formatDateToISODate,
  parseDateFromISOStringWithoutFallback,
  useFeature,
  Caption,
  useGeneralAuthSubmissionWorflowOn,
  getHoursAndMinutesAndSecondsStrFromDate,
  useGetAuthorizationByIdWithFallback,
} from "@coherehealth/common";

import {
  Attachment,
  DiagnosisCode,
  ServiceRequestCreatePayload,
  AuthStatus,
  useGenerateServiceRequestTrackingNumber,
  Patient,
  useUpdateUserExtension,
  ServiceRequestResponse,
  WithdrawRequestor,
  AuthorizationResponse,
  ProcedureCode,
  ServiceRequestEditConfig,
} from "@coherehealth/core-platform-api";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import elementIsNonNull from "util/elementIsNonNull";
import cloneDeep from "lodash/cloneDeep";

import { AddAttachmentsToRequest } from "../../AddAttachments";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import WithdrawModal from "components/PatientSummary/ServiceRequestSummaryCard/WithdrawModal";
import ServiceRequestForm from "../../AuthBuilder/FillForms/ServiceRequestForm";
import {
  isInpatient,
  isRecurring,
  isTerminalStatus,
  isFinallyDeterminedUtility,
  payloadFromSRFormContent,
  safelyGetFormConfig,
} from "util/serviceRequest";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { User, UserContext } from "UserContext";
import {
  TrackUserActivityProps,
  useTrackUserInteraction,
  activitySnapshotFromServiceRequest,
} from "util/userActivityTracker";
import { getPatientHealthPlanName } from "util/patientUtils";
import { makeStyles, useTheme } from "@material-ui/core";
import { DEFAULT_SR_CONFIG_SPEC } from "../ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import ServiceRequestContinuationForm from "../../AuthBuilder/FillFormsContinuation/ServiceRequestContinuationForm";
import { convertFormConfigToContinuationConfig } from "../../AuthBuilder/FillFormsContinuation";
import { GeneralAuthSubmissionForms } from "components/AuthBuilder/FillFormsGeneralAuthSubmission";
import { NonCohereCodes } from "components/AuthBuilder/common";
import GeneralAuthServiceRequestContinuationForm from "components/AuthBuilder/FillFormsContinuation/GeneralAuthServiceRequestContinuationForm";
import { aggregateStayDateByCommonFields } from "components/ServiceRequest/PatientStay/PatientStays";
import { removeEmptyAttachments } from "util/attachmentUtil";
import ExpeditedUpgradeDatePicker from "components/AuthBuilder/FillFormsContinuation/ExpeditedUpgradeDatePicker";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "../../../hooks/useGetFeatureConfigurations";
import ProcedureCodeChangeBanner from "components/PatientSummary/ServiceRequestSummaryCard/ProcedureCodeChangeBanner";
import { useProcedureCodeChangeBanner } from "components/PatientSummary/ServiceRequestSummaryCard/hooks/useProcedureCodeChangeBanner";
const useStyles = makeStyles((theme) => ({
  secondaryContainer: {
    padding: "16px",
  },
  caption: {
    color: theme.palette.error.dark,
  },
  deleteWithdrawBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
    minWidth: theme.spacing(15),
    padding: theme.spacing(2, 3, 1.5, 3),
  },
  submitBtn: {
    width: theme.spacing(19),
    padding: theme.spacing(2, 7, 1.5, 7),
  },
}));

export interface Props {
  submitting?: boolean;
  primaryDiagnosisCode?: DiagnosisCode;
  serviceRequest?: Partial<ServiceRequestResponse>;
  patient?: Patient;
  onSave(payload: ServiceRequestCreatePayload, formContent: ServiceRequestFormContent): Promise<any>;
  submitDraftToIntake?: boolean;
  onWithdraw?: () => Promise<any>;
  withdrawnReason?: string;
  setWithdrawnReason?: (reason: string) => void;
  withdrawRequestor?: WithdrawRequestor;
  setWithdrawRequestor?: (requestor: WithdrawRequestor) => void;
  onDelete?: () => Promise<any>;
  attachments?: Attachment[];
  onUpdateAttachments?: (arg0: Attachment[]) => void;
  showAttachments?: boolean;
  submitButtonText?: string;
  setAttemptedSubmitService?: (b: boolean) => void;
  isCohereFaxForm?: boolean;
  workflowId?: string;
  populateDefaults?: boolean;
  isAuthorizationSr?: boolean;
  onPatientSummary?: boolean;
  initialAuthorization?: AuthorizationResponse;
  refreshAuthorization?: () => Promise<void>;
  setPerformingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderExceptionRequest?: boolean;
  setFacilityExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  facilityExceptionRequest?: boolean;
  setOrderingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  orderingProviderExceptionRequest?: boolean;
  setCareParticipantExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  formEditConfiguration?: ServiceRequestEditConfig | undefined;
  setPerformingProviderPracticeExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderPracticeExceptionRequest?: boolean;
  setPerformingProviderPracticeOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
}

const ServiceRequestFormSubmissionWrapper: FunctionComponent<Props> = ({
  primaryDiagnosisCode: initialPrimaryDiagnosisCode,
  serviceRequest,
  submitting: initialSubmitting,
  submitDraftToIntake,
  onSave,
  onDelete,
  onWithdraw,
  withdrawnReason,
  setWithdrawnReason,
  withdrawRequestor,
  setWithdrawRequestor,
  attachments = [],
  onUpdateAttachments,
  showAttachments = false,
  submitButtonText = "Continue",
  patient,
  setAttemptedSubmitService,
  isCohereFaxForm,
  workflowId,
  populateDefaults,
  isAuthorizationSr,
  onPatientSummary,
  initialAuthorization,
  refreshAuthorization,
  setPerformingProviderExceptionRequest,
  performingProviderExceptionRequest,
  setFacilityExceptionRequest,
  facilityExceptionRequest,
  setOrderingProviderExceptionRequest,
  orderingProviderExceptionRequest,
  setCareParticipantExceptionRequest,
  careParticipantExceptionRequest,
  formEditConfiguration,
  performingProviderPracticeExceptionRequest,
  setPerformingProviderPracticeExceptionRequest,
  setPerformingProviderPracticeOonCheckLoading,
}) => {
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const clinicalService = serviceRequest?.clinicalService;
  const currAuthStatus: AuthStatus = serviceRequest?.authStatus || "DRAFT";
  const [formContent, setFormContent] = useState<ServiceRequestFormContent>({
    id: serviceRequest?.id,
    cohereId: serviceRequest?.cohereId,
    crdLogId: serviceRequest?.crdLogId,
    additionalInsurance: serviceRequest?.additionalInsurance || undefined,
    palCategory: serviceRequest?.palCategory || null,
    carePathJourney: serviceRequest?.carePathJourney,
    clinicalService: serviceRequest?.clinicalService,
    nonPxClinicalServiceIds: serviceRequest?.nonPxClinicalServiceIds,
    clinicalServices: serviceRequest?.clinicalServices?.filter(elementIsNonNull),
    isInpatient: isInpatient(serviceRequest),
    placeOfService: serviceRequest?.placeOfService || clinicalService?.defaultPlaceOfService || null,
    primaryDiagnosisCode: initialPrimaryDiagnosisCode || serviceRequest?.primaryDiagnosis || null,
    secondaryDiagnosisCodes: serviceRequest?.secondaryDiagnoses || [],
    procedureCodes: serviceRequest?.procedureCodes || [],
    isRecurring: simplifiedServiceFrequency ? undefined : isRecurring(serviceRequest),
    startDate: parseDateFromISOString(serviceRequest?.startDate) || formatDateToISODate(today()),
    endDate: parseDateFromISOStringWithoutFallback(serviceRequest?.endDate),
    expeditedTatUpdateTimestamp: parseDateFromISOStringWithoutFallback(serviceRequest?.expeditedTatUpdateTimestamp),
    tatStartTimestamp: parseDateFromISOString(serviceRequest?.tatStartTimestamp) || formatDateToISODate(today()),
    units: serviceRequest?.units
      ? `${serviceRequest?.units}`
      : `${clinicalService?.defaultUnits ? clinicalService?.defaultUnits : 0}`,
    unitType: simplifiedServiceFrequency ? undefined : serviceRequest?.unitType || clinicalService?.defaultUnitType,
    orderingProvider: serviceRequest?.orderingProvider || null,
    orderingProviderSelectedTin: serviceRequest?.orderingProviderSelectedTin || null,
    performingProvider: serviceRequest?.performingProvider || null,
    performingProviderSelectedTin: serviceRequest?.performingProviderSelectedTin || null,
    performingProviderOONExceptionRequired: serviceRequest?.formConfiguration
      ? serviceRequest.formConfiguration["performingProviderOutOfNetworkExceptionReason"].fieldSpec === "REQUIRED"
      : undefined,
    facility: serviceRequest?.facility || null,
    facilitySelectedTin: serviceRequest?.facilitySelectedTin || null,
    facilityParCheck: serviceRequest?.participateCheck?.facilityParCheck,
    facilityOONExceptionRequired: serviceRequest?.formConfiguration
      ? serviceRequest.formConfiguration["facilityOutOfNetworkExceptionReason"].fieldSpec === "REQUIRED"
      : undefined,
    isExpedited: serviceRequest?.urgency?.isExpedited || false,
    expeditedReason: serviceRequest?.urgency?.reasonNote || "",
    admissionDate: parseDateFromISOStringWithoutFallback(serviceRequest?.admissionDate),
    expectedAdmissionDate: parseDateFromISOStringWithoutFallback(serviceRequest?.expectedAdmissionDate),
    expectedDischargeDate: parseDateFromISOStringWithoutFallback(serviceRequest?.expectedDischargeDate),
    followUpFaxNumber: serviceRequest?.followUpFaxNumber,
    bHAdmissionType: serviceRequest?.behavioralHealthAdmissionType,
    bHReviewType: serviceRequest?.behavioralHealthReviewType,
    serviceType: serviceRequest?.serviceType,
    facilitySelectedAddress: serviceRequest?.facilityLocation ? serviceRequest?.facilityLocation : null,
    performingProviderSelectedAddress: serviceRequest?.performingProviderLocation
      ? serviceRequest?.performingProviderLocation
      : null,
    orderingProviderSelectedAddress: serviceRequest?.orderingProviderLocation
      ? serviceRequest?.orderingProviderLocation
      : null,
    selectedFacility: serviceRequest?.selectedFacility,
    selectedOrderingProvider: serviceRequest?.selectedOrderingProvider,
    selectedPerformingProvider: serviceRequest?.selectedPerformingProvider,
    selectedPerformingProviderPractice: serviceRequest?.selectedPerformingProviderPractice,
    approvedUnits: `${serviceRequest?.approvedUnits}`,
    userSelectedOONException: serviceRequest?.userSelectedOONException
      ? serviceRequest?.userSelectedOONException
      : false,
    userSelectedNonPalCode: serviceRequest?.userSelectedNonPalCode,
    authCategory: serviceRequest?.authCategory,
    authSubcategory: serviceRequest?.authSubcategory,
    patientStatus: serviceRequest?.patientStatus,
    admissionTime: serviceRequest?.admissionTime,
    admissionSource: serviceRequest?.admissionSource,
    dischargeDate: parseDateFromISOStringWithoutFallback(serviceRequest?.dischargeDate),
    dischargeTime: serviceRequest?.dischargeTime,
    dischargedTo: serviceRequest?.dischargedTo,
    patientStayDateRanges:
      serviceRequest?.patientStayDates && (!onPatientSummary || (onPatientSummary && !isTerminalStatus(serviceRequest)))
        ? aggregateStayDateByCommonFields(serviceRequest.patientStayDates)
        : [],
    additionalCareParticipants: serviceRequest?.additionalCareParticipants || [],
    turnAroundTimeDueDate: serviceRequest?.turnAroundTimeDueDate,
    authStatus: serviceRequest?.authStatus,
    expeditedTime: serviceRequest?.expeditedTatUpdateTimestamp
      ? getHoursAndMinutesAndSecondsStrFromDate(new Date(serviceRequest?.expeditedTatUpdateTimestamp))
      : undefined,
    expeditedDate: serviceRequest?.expeditedTatUpdateTimestamp
      ? new Date(serviceRequest?.expeditedTatUpdateTimestamp)
      : undefined,
  });

  const classes = useStyles();
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [attachmentsCompleted, setAttachmentsCompleted] = useState(true);

  const healthPlan =
    getPatientHealthPlanName(patient, parseDateFromISOString(serviceRequest?.startDate)) ||
    serviceRequest?.healthPlanName ||
    "";
  const generalAuthSubmissionWorkflow = useGeneralAuthSubmissionWorflowOn(healthPlan);
  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    healthPlanName: healthPlan || "",
  });

  // returns max allowed px code per payer
  const allowedPxCodePerPayer = srEditConfig?.maxAllowedPxCodePerPayer;

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [canBeSubmitted, setCanBeSubmitted] = useState(false);
  const [generalAuthCanBeSubmitted, setGeneralAuthCanBeSubmitted] = useState<boolean[]>([]);

  const { getUser } = useContext(UserContext);
  const [currUser, setUser] = useState<User>();
  const [palProcedureCodes, setPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const [nonPalProcedureCodes, setNonPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const [nonCohereCodes, setNonCohereCodes] = useState<NonCohereCodes[]>([]);
  const [orderingProviderOonCheckLoading, setOrderingProviderOonCheckLoading] = useState<boolean>(false);
  const [providerOonCheckLoading, setProviderOonCheckLoading] = useState<boolean>(false);
  const [performingProviderPracticeOonLoadingLocal, setPerformingProviderPracticeOonLoadingLocal] =
    useState<boolean>(false);
  const [facilityOonCheckLoading, setFacilityOonCheckLoading] = useState<boolean>(false);

  const { data: authorizationData } = useGetAuthorizationByIdWithFallback({
    id: serviceRequest?.authorization?.id || "",
  });
  const [authorization, setAuthorization] = useState<AuthorizationResponse | null>(authorizationData);
  const hideExpeditedRequestCheckbox = serviceRequest?.authStatus !== "DRAFT";
  const trackInteraction = useTrackUserInteraction();
  const originalExpeditedStatus = serviceRequest?.urgency?.isExpedited ?? false;

  useEffect(() => {
    const canSubmitGeneralAuth = generalAuthCanBeSubmitted.every((val) => val);
    if (canBeSubmitted !== canSubmitGeneralAuth) {
      setCanBeSubmitted(canSubmitGeneralAuth);
    }
  }, [canBeSubmitted, generalAuthCanBeSubmitted]);

  useEffect(() => {
    if (authorizationData) {
      setAuthorization(authorizationData);
    }
  }, [authorizationData, setAuthorization]);

  useEffect(() => {
    getUser()
      ?.then((currentUser) => {
        setUser(currentUser);
      })
      .catch((error) => {
        console.log("error loading current user: ", error);
      });
  }, [getUser]);

  const {
    mutate: updateUser,
    loading: userUpdateLoading,
    error: userUpdateError,
  } = useUpdateUserExtension({
    id: currUser?.sub || "",
  });

  const submitting = initialSubmitting || userUpdateLoading;

  const trackUserActivityInteraction = useTrackUserInteraction();

  const onSubmit: HTMLAttributes<HTMLFormElement>["onSubmit"] = async (e) => {
    e.preventDefault();
    setAttemptedSubmit(true);
    if (canBeSubmitted) {
      await saveServiceRequest();
      await refreshAuthorization?.();
    }
    //if the follow up fax number was updated, persist to BE
    if (serviceRequest?.followUpFaxNumber?.number !== formContent.followUpFaxNumber?.number) {
      await updateUser(
        { mostRecentlyUsedFaxNumber: formContent.followUpFaxNumber?.number },
        { pathParams: { id: currUser?.sub || "" } }
      );
      if (!userUpdateError) {
        const userActivityInteractionPayload: TrackUserActivityProps = {
          event: "UPDATE_REQUESTOR_FAX",
          stage: "AUTH_CREATION",
          beforeSnapshot: { requestorFaxNumber: serviceRequest?.followUpFaxNumber?.number },
          afterSnapshot: { requestorFaxNumber: formContent?.followUpFaxNumber?.number },
        };
        userActivityInteractionPayload.activityContext = {
          patientId: patient?.id,
          serviceRequestId: serviceRequest?.id,
          workflowId: workflowId,
        };
        trackUserActivityInteraction({ ...userActivityInteractionPayload });
      }
    }
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (urlSearchParams.has("duplicate")) {
      const duplicateWorkflowId = urlSearchParams.get("duplicate");

      await trackUserActivityInteraction({
        event: "EDIT_ORIGINAL_DUPLICATE",
        stage: "DUPLICATE_SUBMISSION_REVIEW",
        activityContext: {
          serviceRequestId: serviceRequest?.id,
          workflowId: duplicateWorkflowId || undefined,
        },
      });
    }
  };

  const getAuthStatus = useCallback((): AuthStatus => {
    if (currAuthStatus === "DRAFT") {
      // In general, we don't want to change the auth status here once a SR has been submitted for intake.
      // However for new/DRAFT service requests, we do have to do some manipulation

      // If saving this draft should also submit it for INTAKE (a.k.a. queue for review), then do that
      // This is determined by the parent because page context matters (i.e. for /PatientSummary we want to submit
      // any changes to intake. If in the /createJourney flow, then we want to keep things as draft)
      return submitDraftToIntake ? "INTAKE" : "DRAFT";
    }
    if (currAuthStatus === "PENDING_FACILITY_VERIFICATION") {
      // If we are currently waiting for facility redirection and we now do not need to redirect then treat this SR like a current DRAFT.
      // If the parent wants to submit to INTAKE then do that, otherwise keep it in DRAFT
      return submitDraftToIntake ? "INTAKE" : "DRAFT";
    }

    // otherwise just return the current status
    return currAuthStatus;
  }, [submitDraftToIntake, currAuthStatus]);

  const saveServiceRequest = async () => {
    const expeditedByModal = formContent?.isExpeditedByModal ?? false;
    const expeditedStatus = formContent?.isExpedited ?? false;
    const payload = (): ServiceRequestCreatePayload => {
      let decoratedFormContent = cloneDeep(formContent);

      if (
        isFinallyDeterminedUtility(serviceRequest?.authStatus) &&
        !formContent.patientStayDateRanges?.length &&
        !!serviceRequest?.patientStayDates?.length
      ) {
        // If we didn't add any patient stay dates with the form, then just use the existing decisioned patient stay dates
        decoratedFormContent["patientStayDateRanges"] = aggregateStayDateByCommonFields(
          serviceRequest?.patientStayDates
        );
      }
      //if isExpeditedByModal is set to true, expeditedTatUpdateTimestamp needs to be set to current Date at point of save
      if (decoratedFormContent.isExpeditedByModal) {
        decoratedFormContent.expeditedTatUpdateTimestamp = new Date();
      }
      return {
        ...payloadFromSRFormContent(
          decoratedFormContent,
          simplifiedServiceFrequency,
          getAuthStatus(),
          undefined,
          serviceRequest?.requestType === "CONTINUATION"
        ),
      };
    };
    await onSave(payload(), formContent);
    //track changes in expedited status after save completes, but only for submitted requests
    if (serviceRequest && serviceRequest?.authStatus !== "DRAFT" && expeditedStatus !== originalExpeditedStatus) {
      await trackInteraction({
        event: "POST_SUBMISSION_EDIT",
        stage: "POST_SUBMISSION",
        field: "EXPEDITED",
        activityContext: {
          serviceRequestId: serviceRequest?.id,
          userId: currUser?.sub,
          userEmail: currUser?.email,
          userRoles: currUser?.roles,
          expeditedInfo: {
            updatedUsingModal: expeditedStatus && expeditedByModal,
            updatedUsingDates: expeditedStatus && !expeditedByModal,
          },
        },
        beforeSnapshot: serviceRequest ? activitySnapshotFromServiceRequest(serviceRequest) : undefined,
        afterSnapshot: activitySnapshotFromServiceRequest({
          urgency: { isExpedited: expeditedStatus },
        }),
      });
    }
  };
  const theme = useTheme();

  const { formFieldConfigurations } = useServiceRequestConfigSpec({
    ...formContent,
    patientId: patient?.id || "",
    healthPlanName: healthPlan || "",
  });

  // When this is an internal request generated by an API request (i.e a CPP Request), always default to showing the service request form edit state
  const isInternalRequest =
    serviceRequest?.requestor?.channel && serviceRequest?.requestor?.channel === "INTERNAL_API_REQUEST";
  const expeditedTatUpdateTimestampNotValid =
    formContent?.expeditedTatUpdateTimestamp && formContent?.expeditedTatUpdateTimestamp > new Date();
  const isOonCheckRunning =
    orderingProviderOonCheckLoading ||
    providerOonCheckLoading ||
    performingProviderPracticeOonLoadingLocal ||
    facilityOonCheckLoading;
  const disableSave =
    submitting ||
    !attachmentsCompleted ||
    (!canBeSubmitted && attemptedSubmit) ||
    expeditedTatUpdateTimestampNotValid ||
    isOonCheckRunning;

  const showProcedureCodeChangeBanner = useProcedureCodeChangeBanner(
    serviceRequest?.semanticProcedureCodes ?? [],
    formContent?.procedureCodes
  );

  return (
    <>
      <Grid container spacing={2} component="form" onSubmit={onSubmit}>
        <Row>
          {generalAuthSubmissionWorkflow && isAuthorizationSr && !isInternalRequest ? (
            <GeneralAuthServiceRequestContinuationForm
              authStatus={currAuthStatus}
              formContent={formContent}
              setFormContent={setFormContent}
              formConfiguration={formFieldConfigurations}
              attemptedSubmit={attemptedSubmit}
              setServiceRequestFormsCanBeSubmitted={setGeneralAuthCanBeSubmitted}
              patient={patient}
              hideDiagnoses
              workflowId={workflowId}
              authorization={authorization}
              serviceRequest={serviceRequest}
              requestType={serviceRequest?.requestType}
              onPatientSummary={onPatientSummary}
              hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
              allowedPxCodePerPayer={allowedPxCodePerPayer}
              curreUser={currUser}
              formEditConfiguration={formEditConfiguration}
              setOrderingProviderOonCheckLoading={setOrderingProviderOonCheckLoading}
              setProviderOonCheckLoading={setProviderOonCheckLoading}
              setPerformingProviderPracticeOonCheckLoading={setPerformingProviderPracticeOonLoadingLocal}
              setFacilityOonCheckLoading={setFacilityOonCheckLoading}
            />
          ) : generalAuthSubmissionWorkflow && !isInternalRequest ? (
            <GeneralAuthSubmissionForms
              formContent={formContent}
              setFormContent={setFormContent}
              attemptedSubmit={attemptedSubmit}
              patientHealthPlanName={healthPlan || ""}
              patient={patient || null}
              setServiceRequestFormsCanBeSubmitted={setGeneralAuthCanBeSubmitted}
              delegatedVendorName={serviceRequest?.delegatedVendor}
              authStatus={currAuthStatus}
              /* Procedure Code Buckets */
              palProcedureCodes={palProcedureCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereProcedureCodes={nonCohereCodes}
              setNonCohereProcedureCodes={setNonCohereCodes}
              presubmissionReviewPage={true}
              workflowId={workflowId}
              setRequestor={() => {}}
              isEdit={true}
              performingProviderExceptionRequest={performingProviderExceptionRequest}
              setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
              facilityExceptionRequest={facilityExceptionRequest}
              setFacilityExceptionRequest={setFacilityExceptionRequest}
              setPerformingProviderPracticeExceptionRequest={setPerformingProviderPracticeExceptionRequest}
              performingProviderPracticeExceptionRequest={performingProviderPracticeExceptionRequest}
              setPerformingProviderPracticeOonCheckLoading={setPerformingProviderPracticeOonCheckLoading}
              careParticipantExceptionRequest={careParticipantExceptionRequest}
              setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
              serviceRequest={serviceRequest}
              hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
              allowedPxCodePerPayer={allowedPxCodePerPayer}
            />
          ) : isAuthorizationSr && !isInternalRequest ? (
            <ServiceRequestContinuationForm
              formContent={formContent}
              setFormContent={setFormContent}
              formConfiguration={convertFormConfigToContinuationConfig(formFieldConfigurations)}
              patient={patient}
              attemptedSubmit={attemptedSubmit}
              setCanBeSubmitted={setCanBeSubmitted}
              isCohereFaxForm={isCohereFaxForm}
              authStatus={currAuthStatus}
              delegatedVendorName={serviceRequest?.delegatedVendor}
              dateCreated={serviceRequest?.dateCreated || ""}
              populateDefaults={populateDefaults}
              onPatientSummary={onPatientSummary}
              authorization={authorization}
              setAuthorization={setAuthorization}
              requestType={serviceRequest?.requestType}
              hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
            />
          ) : (
            <ServiceRequestForm
              formContent={formContent}
              setFormContent={setFormContent}
              formConfiguration={
                serviceRequest?.authStatus === "DRAFT"
                  ? formFieldConfigurations
                  : safelyGetFormConfig(
                      serviceRequest?.formConfiguration || DEFAULT_SR_CONFIG_SPEC.formFieldConfigurations
                    )
              }
              patient={patient}
              attemptedSubmit={attemptedSubmit}
              setCanBeSubmitted={setCanBeSubmitted}
              isCohereFaxForm={isCohereFaxForm}
              authStatus={currAuthStatus}
              delegatedVendorName={serviceRequest?.delegatedVendor}
              dateCreated={serviceRequest?.dateCreated || ""}
              populateDefaults={populateDefaults}
              hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
            />
          )}
        </Row>
        {showAttachments && serviceRequest?.id && (
          <Row>
            <AddAttachmentsToRequest
              serviceRequestIds={[serviceRequest.id]}
              initialAttachments={removeEmptyAttachments(attachments)}
              onUpdateAttachments={onUpdateAttachments}
              setAttachmentsCompleted={setAttachmentsCompleted}
              workflowId={serviceRequest?.workflowId}
            />
          </Row>
        )}
        {hideExpeditedRequestCheckbox ? (
          <Row container style={{ marginTop: -theme.spacing(2) }}>
            <ExpeditedUpgradeDatePicker
              formContent={formContent}
              setFormContent={setFormContent}
              currentUser={currUser}
              formEditConfiguration={formEditConfiguration}
              patient={patient}
              healthPlan={healthPlan}
              serviceRequestId={serviceRequest?.id}
              clinicalServiceId={serviceRequest?.clinicalService?.id}
              attemptedSubmit={attemptedSubmit}
            />
          </Row>
        ) : (
          <Row container style={{ marginTop: theme.spacing(1) }}></Row>
        )}

        <Row container>
          <ProcedureCodeChangeBanner show={showProcedureCodeChangeBanner} />
        </Row>

        <Row container style={{ gap: theme.spacing(2) }}>
          {!Boolean(setAttemptedSubmitService) && (
            <Grid item>
              <PrimaryButton
                className={classes.submitBtn}
                disabled={disableSave}
                loading={submitting || isOonCheckRunning}
                type="submit"
              >
                {submitButtonText}
              </PrimaryButton>
            </Grid>
          )}
          {!canBeSubmitted && attemptedSubmit && (
            <Grid item className={classes.secondaryContainer}>
              <Caption className={classes.caption}>{"There are some errors with the details above. "}</Caption>
            </Grid>
          )}
          {onDelete && (
            <Grid item>
              <TertiaryButton
                className={classes.deleteWithdrawBtn}
                warning
                disabled={submitting || !attachmentsCompleted}
                onClick={(e) => {
                  e.preventDefault();
                  onDelete();
                }}
              >
                Delete this Service
              </TertiaryButton>
            </Grid>
          )}
        </Row>
      </Grid>
      {setWithdrawnReason && setWithdrawRequestor && (
        <WithdrawModal
          onWithdraw={onWithdraw}
          withdrawnReason={withdrawnReason}
          setWithdrawnReason={setWithdrawnReason}
          withdrawRequestor={withdrawRequestor}
          setWithdrawRequestor={setWithdrawRequestor}
          open={withdrawModalOpen}
          onClose={() => {
            setWithdrawModalOpen(false);
            setWithdrawnReason("");
          }}
          withdrawnReasonOptionsList={serviceRequest?.reviewOutcomeWithdrawOptions}
          serviceRequestId={serviceRequest?.id || ""}
        />
      )}
    </>
  );
};
export default function ServiceRequestFormInterceptor(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [trackingNumber, setTrackingNumber] = useState(props.serviceRequest?.cohereId);
  const { mutate: generateTrackingNumber, cancel } = useGenerateServiceRequestTrackingNumber({});
  useEffect(() => {
    if (!trackingNumber) {
      generateTrackingNumber()
        .then(({ trackingNumber }) => {
          setTrackingNumber(trackingNumber);
        })
        .catch((e) => {
          enqueueSnackbar(
            `We're sorry, we were unable to generate a tracking number for this request. Please try again`,
            { variant: "error" }
          );
        });
      return cancel;
    }
    // `mutate` changes identity on re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, trackingNumber]);

  if (!trackingNumber) {
    return <CircularProgress />;
  }

  return (
    <ServiceRequestFormSubmissionWrapper
      {...props}
      serviceRequest={{
        ...props.serviceRequest,
        cohereId: trackingNumber,
      }}
      workflowId={props.workflowId}
      populateDefaults={props.populateDefaults}
    />
  );
}

const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;
