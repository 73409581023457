import { useEffect, useRef, useState } from "react";
import { Application, Category, TimeTracker, ServiceCase } from "./trackConfig";
import { useGet } from "restful-react";
import { useGetOktaTokenWithoutRefresh } from "../../util/getOktaToken";
import isEqual from "lodash/isEqual";

export interface TrackRouteProps {
  baseUrl: string;
  application: Application;
  component: string;
  category: Category;
  caseId?: string | null;
  serviceRequestId?: string;
  queueId?: string;
  reviewId?: string;
}

export interface TimeTrackerCaseData {
  application: Application;
  component: string;
  category: Category;
  caseId?: string | null;
  queueId?: string;
  reviewId?: string;
}

export default function TrackRoute({
  baseUrl,
  application,
  component,
  category,
  caseId,
  serviceRequestId,
  queueId,
  reviewId,
}: TrackRouteProps) {
  const endpoint = baseUrl + "timeTracker";
  const intervalId = useRef<NodeJS.Timer>();
  const token = useGetOktaTokenWithoutRefresh();
  const validToken = !!token;
  const serviceCaseId = useRef<string | undefined | null>(caseId);
  const [queueIdRef, setQueueId] = useState<string | undefined>(queueId);

  // Track the data we use to start a time tracker, and avoid starting
  // multiple time trackers for the same case/data. This was happening
  // when the Okta token expires and then is refreshed from another
  // part of the app, re-triggering the useEffect that starts the time
  // tracker.
  const [lastCreatedTrackerData, setLastCreatedTrackerData] = useState<TimeTrackerCaseData | null>(null);

  const { refetch: searchServiceCases } = useGet<ServiceCase[]>(baseUrl + "serviceCase/search", {
    requestOptions: {
      method: "POST",
      body: JSON.stringify({
        serviceRequestId: serviceRequestId,
        caseType: "P2P_REVIEW",
        caseStatusList: ["OPEN", "IN_PROGRESS"],
      }),
      headers: { "Content-Type": "application/json", authorization: `Bearer ${token}` },
    },
    lazy: true,
  });

  const timeTrackerCaseData: TimeTrackerCaseData = {
    application,
    category,
    component,
    caseId: serviceCaseId.current,
    queueId: queueIdRef,
    reviewId,
  };

  const {
    data,
    error: startTrackingError,
    refetch: startTracking,
  } = useGet<TimeTracker>(endpoint, {
    requestOptions: {
      method: "POST",
      body: JSON.stringify(timeTrackerCaseData),
      headers: { "Content-Type": "application/json", authorization: `Bearer ${token}` },
    },
    lazy: true,
  });

  const { error: keepAliveError, refetch: keepAlive } = useGet<TimeTracker>(
    endpoint + "/" + (data?.id || "") + "/keepAlive",
    {
      requestOptions: {
        method: "PATCH",
        headers: { authorization: `Bearer ${token}` },
        keepalive: true,
      },
      lazy: true,
    }
  );

  const { refetch: stop } = useGet<TimeTracker>(endpoint + "/" + (data?.id || "") + "/stop", {
    requestOptions: {
      method: "PATCH",
      headers: { authorization: `Bearer ${token}` },
      keepalive: true,
    },
    lazy: true,
  });

  useEffect(() => {
    const handlePageHide = async () => {
      try {
        if (data?.id) {
          await stop();
        }
      } catch (error: any) {
        console.error("Failed to execute callback for useOnUnload.", error);
      }
    };

    window.addEventListener("pagehide", handlePageHide);

    return () => document.removeEventListener("pagehide", handlePageHide);
    // We only want to run this hook when the "data.id" changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);

  useEffect(() => {
    if (validToken) {
      if (!caseId && component === "Clinical Review") {
        searchServiceCases()
          .then((data) => (serviceCaseId.current = data?.at(0)?.id))
          .then(() => {
            if (serviceCaseId.current && !isEqual(timeTrackerCaseData, lastCreatedTrackerData)) {
              startTracking();
            }
          });
      } else {
        if (!isEqual(timeTrackerCaseData, lastCreatedTrackerData)) {
          startTracking().then((ttData: any) => {
            setLastCreatedTrackerData(timeTrackerCaseData);
          });
        }
      }
    }
    // We do *not* want this to fire if lastCreatedTrackerData changes
    // (it will always change after we start a new tracker)

    // eslint-disable-next-line
  }, [application, component, caseId, validToken, startTracking, searchServiceCases, serviceCaseId, queueIdRef]);

  useEffect(() => {
    if (data?.id) {
      let interval = setInterval(() => {
        keepAlive();
      }, 30000);
      intervalId.current = interval;
      return () => {
        clearInterval(interval);
      };
    }
  }, [data?.id, keepAlive]);

  useEffect(() => {
    if (startTrackingError) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      console.error(
        `Error starting tracker for component ${component} in ${application}: ${startTrackingError.message}`
      );
    }
  }, [application, component, startTrackingError]);

  useEffect(() => {
    if (keepAliveError) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      console.error(
        `Error keeping tracker alive for component ${component} in ${application}: ${keepAliveError.message}`
      );
    }
  }, [application, component, keepAliveError]);

  useEffect(() => {
    if (data?.id && queueId !== queueIdRef) {
      stop({
        queryParams: { stoppedReason: "QUEUE_SWITCH" },
      }).then(() => setQueueId(queueId));
    }
  }, [data?.id, queueId, queueIdRef, stop]);
  return null;
}
