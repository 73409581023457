import { H6 } from "@coherehealth/common";
import { CohereTableWithPanels, CohereTableWithPanelsColumn } from "@coherehealth/design-system";
import { useTheme } from "@material-ui/core/styles";
import { ServiceRequestResponse, MdReview, PeerToPeerReview, NurseReview } from "@coherehealth/core-platform-api";
import { unitsString } from "components/PatientSummary/ServiceRequestSummaryCard/ProcedureCodeList";
import { DetailsField } from "./CaseOverview";
import { isSamePxCode } from "components/ServiceRequest/ReviewSection/util/useProcedureCodesState";
import { selectedDetailsCell } from "components/PatientSummary/ServiceRequestSummaryCard/GeneralAuthProcedureCodeList";
interface Props {
  serviceRequest: ServiceRequestResponse | undefined | null;
  review?: MdReview | PeerToPeerReview | NurseReview | null;
}
export default function RequestedProceduresInformation({ serviceRequest, review }: Props) {
  const theme = useTheme();
  type ProcRow = {
    code: string;
    status?: string;
    requested?: string;
    approved?: string;
    details?: React.ReactNode;
    description?: string;
  };
  const doesAnyPxHaveSelectedDetails = serviceRequest?.semanticProcedureCodes?.some(
    (code) => code.selectedDetails && code.selectedDetails.length > 0
  );
  const columnsWithUnitsOnPx: CohereTableWithPanelsColumn<ProcRow>[] = [
    {
      columnName: "Code",
      value: (row: ProcRow) => row.code,
    },
    {
      columnName: "Requested",
      value: (row: ProcRow) => row.requested,
    },
    {
      columnName: "Approved",
      value: (row: ProcRow) => row.approved,
    },
    ...(doesAnyPxHaveSelectedDetails
      ? [
          {
            columnName: "Details",
            value: (row: ProcRow) => row.details,
          },
        ]
      : []),
    {
      columnName: "Description",
      value: (row: ProcRow) => row.description,
    },
  ];
  const requestUnits = serviceRequest?.units || 1;
  const isDenialOutcome = review?.reviewOutcome === "DENIED" || review?.reviewOutcome === "RECOMMENDED_DENIAL";
  const approvedUnitsOnPx = review?.approvedSemanticProcedureCodes?.length
    ? review?.approvedSemanticProcedureCodes.reduce((a, b) => a + (b.approvedUnits ? b.approvedUnits : 0), 0)
    : 0;
  const approvedUnits = review?.approvedUnits || approvedUnitsOnPx;
  const panelsWithUnitsOnPx: ProcRow[] =
    serviceRequest?.semanticProcedureCodes?.map((procedure) => {
      const pxCode = !isDenialOutcome
        ? review?.approvedSemanticProcedureCodes?.find((pxCode) => isSamePxCode(pxCode, procedure))
        : undefined;
      return {
        code: procedure.code,
        requested: unitsString(procedure?.units, procedure?.unitType || ""),
        approved: unitsString(pxCode ? pxCode?.approvedUnits : 0, procedure?.unitType || ""),
        ...(doesAnyPxHaveSelectedDetails && {
          details: selectedDetailsCell(procedure),
        }),
        description: procedure.description,
      };
    }) || [];
  const unitsOnServiceLevel = !serviceRequest?.clinicalService?.isUnitsOnPx;
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: theme.spacing(2) }}>
        <H6>Requested procedures</H6>
      </div>
      <CohereTableWithPanels columns={columnsWithUnitsOnPx} panels={panelsWithUnitsOnPx} />
      {unitsOnServiceLevel && (
        <div style={{ marginTop: theme.spacing(2) }}>
          <DetailsField
            label={"Number of service dates:"}
            value={`${requestUnits} requested, ${approvedUnits} approved`}
          ></DetailsField>
        </div>
      )}
    </>
  );
}
