import {
  SelectOptionsHook,
  SingleSelectForProviderFacilitySelect,
  useLazyLoadingQueryOptionsHook,
  useFeature,
} from "@coherehealth/common";
import {
  Facility,
  Location,
  Patient,
  useGetProvidersOrFacilities,
  UseGetProvidersOrFacilitiesProps,
} from "@coherehealth/core-platform-api";
import { Dispatch, FunctionComponent, SetStateAction, useContext, useState } from "react";
import { getCurrentCoverage, getPatientHealthPlanName } from "util/patientUtils";
import { ReferralRequestFormContent } from "../ReferralRequestForm";
import { ReferralFormConfiguration } from "components/ReferralManagement/FormContentSpecification/ReferralRequestFormContentSpecifications";
import { Grid, makeStyles } from "@material-ui/core";
import {
  providerFacilityAddressRenderer,
  providerFacilityDropdownRender,
  providerFacilityTinRenderer,
} from "components/ServiceRequest/ProviderFacilityDropdownRender";
import { getAddress } from "components/ServiceRequest/ServiceRequestForm/components/ProviderSelectManual";
import {
  FacilityOption,
  getLocationsForTin,
  getLocationsWithOptions,
  getTinListWithOptions,
  getTinWithOptions,
  LocationOption,
  TinOption,
} from "components/ServiceRequest/ServiceRequestForm/components/FacilitySelectManual";
import { RecentProvidersContext } from "common/RecentProviders/context";
// * Code tag RECENT_PROVIDERS_8B12CDB1
import Recents from "common/RecentProviders";

// Update this function for fetching facility based on specialty / clinical area
function getUseFacilitiesDedicatedToHealthPlan(
  patient: Patient | undefined,
  useCMSProviders: string,
  ignoreTin: boolean,
  setCurrSpecialty: Dispatch<SetStateAction<string | undefined>>,
  specialty?: string,
  currSpecialty?: string
): SelectOptionsHook<FacilityOption> {
  return function useFacilities(selectOptionsParams) {
    const asOfToday = new Date();
    const healthPlanName = patient ? getPatientHealthPlanName(patient, asOfToday) : undefined;

    const patientCoverage = getCurrentCoverage(patient);
    const rpn = patientCoverage?.payerCustomFields?.find(
      (field) => field.fieldName?.toLocaleLowerCase() === "rpn"
    )?.valueString;

    const queryParams = {
      healthPlanName: healthPlanName,
      ignoreTin: ignoreTin,
      useCMSProviders: useCMSProviders,
      rpn,
      specialty,
    };

    return useLazyLoadingQueryOptionsHook({
      useGetHook: (args: UseGetProvidersOrFacilitiesProps) => useGetProvidersOrFacilities({ ...args, debounce: 600 }),
      additionalQueryParams: { ...queryParams, recordType: "FACILITY" },
      ...selectOptionsParams,
      reloadData: currSpecialty !== specialty,
      onReloadData: () => setCurrSpecialty(specialty),
    });
  };
}

export const useReferralFacilityStyles = makeStyles((theme) => ({
  facilityFlex: {
    "@media (max-width: 499px)": {
      flex: `1 1 584px`,
    },
    "@media (min-width: 500px) and (max-width: 1169px)": {
      flex: `1 1 calc(100vw - 48px)`,
    },
    "@media (min-width: 1170px)": {
      flex: `1 1 40%`,
    },
    minWidth: "200px",
  },
  addressTinFlex: {
    "@media (max-width: 499px)": {
      flex: `1 1 50%`,
    },
    "@media (min-width: 500px) and (max-width: 1169px)": {
      flex: `1 1 50%`,
    },
    "@media (min-width: 1170px)": {
      flex: `1 1 40%`,
    },
  },
  tin: {
    "@media (max-width: 499px)": {
      width: "calc(100vw - 98px)",
    },
    "@media (min-width: 500px)": {
      width: theme.spacing(20),
    },
  },
}));

export const FacilitySelect: FunctionComponent<{
  facilityError: boolean;
  isSoloPractitionerChecked: boolean;
  npiError?: boolean;
  tinError?: boolean;
  addressError?: boolean;
  rrFormContent: ReferralRequestFormContent;
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>;
  patient?: Patient;
  formConfiguration?: ReferralFormConfiguration;
  setHasFacilityAttestation?: Dispatch<SetStateAction<boolean>>;
  hideTinField?: boolean;
  label?: string;
}> = ({
  facilityError,
  isSoloPractitionerChecked,
  npiError,
  tinError,
  addressError,
  rrFormContent,
  setRRFormContent,
  patient,
  formConfiguration,
  setHasFacilityAttestation,
  hideTinField = false,
  label = "",
}) => {
  const { trackingStateClickChangeCheck } = useContext(RecentProvidersContext);
  const healthPlanName = patient ? getPatientHealthPlanName(patient, new Date()) : undefined; //-- Needed for recents
  const classes = useReferralFacilityStyles();
  const [currSpecialty, setCurrSpecialty] = useState<string | undefined>("");
  const isSelectedSpecialistSoloPractitionerAndChecked =
    useFeature("bcbsScProviderPracticeLogic") &&
    healthPlanName === "BCBS South Carolina" &&
    rrFormContent?.performingSpecialistSelectedAddress?.providerType === "P" &&
    isSoloPractitionerChecked;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item className={classes.facilityFlex}>
          <SingleSelectForProviderFacilitySelect<FacilityOption>
            disablePortal
            disabled={isSelectedSpecialistSoloPractitionerAndChecked}
            markSelectedOptions={false}
            error={facilityError || npiError}
            clearable={formConfiguration?.selectedFacility.fieldSpec === "OPTIONAL"}
            label={label}
            emptyLabel={"Specialist practice by NPI, TIN, or name"}
            useOptions={getUseFacilitiesDedicatedToHealthPlan(
              patient,
              "true",
              hideTinField,
              setCurrSpecialty,
              rrFormContent?.specialty,
              currSpecialty
            )}
            getOptionLabel={({ name, npi }) => `${name} / NPI - ${npi}`}
            renderOption={({ name, tinList, npi, optionType }) =>
              providerFacilityDropdownRender(
                name,
                tinList,
                npi,
                optionType,
                (tin: string | null) => setRRFormContent({ ...rrFormContent, facilitySelectedTin: tin }),
                hideTinField,
                false,
                true
              )
            }
            selectedValue={rrFormContent?.selectedFacility || null}
            setSelectedValue={async (facility) => {
              setHasFacilityAttestation?.(false);
              const selectedTin = facility?.tinList?.length === 1 ? facility.tinList[0] : undefined;
              let locationsForTin = getLocationsForTin(selectedTin, facility, hideTinField);
              let selectedLocation: Location = {};
              if (locationsForTin && locationsForTin?.length === 1) {
                selectedLocation = locationsForTin[0];
              }
              if (
                hideTinField ||
                (facility?.source === "CMS" && !facility?.manuallyCreated && !selectedLocation.address)
              ) {
                if (facility?.locations && facility.locations.length > 0) {
                  selectedLocation = facility.locations[0];
                }
              }

              if (["noResults", "someResults"].includes(facility?.optionType || "")) {
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  facilitySelectedTin: undefined,
                  facilitySelectedAddress: undefined,
                  selectedFacility: facility,
                }));
              } else if (facility === null) {
                setRRFormContent((prevContent) => {
                  return {
                    ...prevContent,
                    facilitySelectedAddress: null,
                    selectedFacility: null,
                    facilitySelectedTin: null,
                    facilityOONExceptionRequired: false,
                  };
                });
              } else {
                const selectedFacility: Facility = {
                  ...facility,
                  selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                  type: "FACILITY",
                } as Facility;
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  facilitySelectedTin: facility?.tinList?.length === 1 ? facility.tinList[0] : null,
                  facilitySelectedAddress: selectedLocation,
                  selectedFacility: selectedFacility,
                }));
              }
              // * Code tag RECENT_PROVIDERS_8B12CDB1
              if (trackingStateClickChangeCheck) {
                trackingStateClickChangeCheck("SpecialistFacility");
              }
            }}
            data-testid="selected-facility-npi"
            dataPublic={true}
          />
        </Grid>
        <Grid item>
          <SingleSelectForProviderFacilitySelect<TinOption>
            disablePortal
            label={"TIN"}
            clearable={formConfiguration?.facilityTIN.fieldSpec === "OPTIONAL"}
            disabled={!Boolean(rrFormContent?.selectedFacility) || isSelectedSpecialistSoloPractitionerAndChecked}
            markSelectedOptions={false}
            setSelectedValue={async (tin) => {
              if (tin && tin.id !== "") {
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  facilitySelectedTin: tin.id,
                }));

                let locationsForTin = getLocationsForTin(tin.id, rrFormContent?.selectedFacility);

                let selectedLocation: Location = {};
                if (locationsForTin && locationsForTin?.length === 1) {
                  selectedLocation = locationsForTin[0];
                }
                const selectedFacility: Facility = {
                  ...rrFormContent?.selectedFacility,
                  selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                } as Facility;

                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  facilitySelectedAddress: selectedLocation,
                  selectedFacility: selectedFacility,
                }));
              } else if (["noResults", "someResults"].includes(tin?.optionType || "")) {
                const selectedFacility: Facility = {
                  ...rrFormContent?.selectedFacility,
                  selectedLocation: undefined,
                } as Facility;
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  facilitySelectedTin: undefined,
                  facilitySelectedAddress: undefined,
                  selectedFacility: selectedFacility,
                }));
              }
              // * Code tag RECENT_PROVIDERS_8B12CDB1
              if (trackingStateClickChangeCheck) {
                trackingStateClickChangeCheck("SpecialistFacility");
              }
            }}
            useOptions={() => ({
              options: getTinListWithOptions(rrFormContent?.selectedFacility?.tinList),
              optionsLoading: false,
              filterOptions: (options, state) =>
                options.filter((option) => option.val?.toLowerCase().includes(state.inputValue.toLowerCase())),
            })}
            data-testid="selected-facility-tin"
            selectedValue={getTinWithOptions(
              rrFormContent?.facilitySelectedTin || rrFormContent?.selectedFacility?.selectedLocation?.tin
            )}
            error={tinError}
            getOptionLabel={({ val }) => val}
            renderOption={({ id, val, optionType }) => providerFacilityTinRenderer(id, val, optionType, true)}
            helperText={tinError && "Required"}
            dataPublic={false}
            className={classes.tin}
          />
        </Grid>
        <Grid item className={classes.addressTinFlex}>
          <SingleSelectForProviderFacilitySelect<LocationOption>
            disablePortal
            label={"Address"}
            disabled={
              !(Boolean(rrFormContent?.selectedFacility) && (hideTinField || rrFormContent?.facilitySelectedTin)) ||
              isSelectedSpecialistSoloPractitionerAndChecked
            }
            clearable={formConfiguration?.facilityAddress.fieldSpec === "OPTIONAL"}
            markSelectedOptions={false}
            useOptions={() => ({
              options: getLocationsWithOptions(
                getLocationsForTin(rrFormContent?.facilitySelectedTin, rrFormContent?.selectedFacility, hideTinField),
                false,
                rrFormContent?.facilitySelectedTin,
                hideTinField
              ),
              optionsLoading: false,
              filterOptions: (options, state) =>
                options.filter(
                  (option) =>
                    option?.address?.city?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                    option?.address?.line1?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                    option?.address?.line2?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                    option?.address?.state?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                    option?.address?.zipCode?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                    option?.optionType === "noResults" ||
                    option?.optionType === "someResults"
                ),
            })}
            helperText={addressError && "Required"}
            selectedValue={
              rrFormContent?.facilitySelectedAddress || rrFormContent?.selectedFacility?.selectedLocation || null
            }
            getOptionLabel={({ address }) => getAddress(address)}
            setSelectedValue={(location) => {
              if (["noResults", "someResults"].includes(location?.optionType || "")) {
                const selectedFacility: Facility = {
                  ...rrFormContent?.selectedFacility,
                  selectedLocation: undefined,
                  type: "FACILITY",
                } as Facility;
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  facilitySelectedAddress: undefined,
                  selectedFacility: selectedFacility,
                }));
              } else {
                const selectedFacility: Facility = {
                  ...rrFormContent?.selectedFacility,
                  selectedLocation: location,
                  type: "FACILITY",
                } as Facility;
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  facilitySelectedAddress: location,
                  selectedFacility: selectedFacility,
                }));
              }
              // * Code tag RECENT_PROVIDERS_8B12CDB1
              if (trackingStateClickChangeCheck) {
                trackingStateClickChangeCheck("SpecialistFacility");
              }
            }}
            renderOption={({ address, optionType }) => providerFacilityAddressRenderer(address, optionType, true)}
            error={addressError}
            data-testid="selected-facility-address"
            dataPublic={true}
          />
        </Grid>
        {!isSelectedSpecialistSoloPractitionerAndChecked && (
          <Grid item xs={12} style={{ padding: 0 }}>
            <Recents<ReferralRequestFormContent>
              bucketName="SpecialistFacility"
              setFormContent={setRRFormContent}
              specialistFacilityId={rrFormContent?.selectedFacility?.id}
              healthPlan={healthPlanName}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
