import React from "react";

import { Caption, InlineButton } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import {
  DiagnosisCode,
  GetTransientServiceRequestRuleActionsRequestBody,
  Facility,
  PALCategory,
  PlaceOfService,
  ProcedureCode,
  Provider,
  ServiceRequestCommon,
  ServiceRequestExpansion,
  StandardFields,
  UnitType,
  CarePathJourney,
  ClinicalService,
  CarePath,
  OscarServiceType,
  BehavioralHealthReviewType,
  BehavioralHealthAdmissionType,
  PhoneNumber,
  RxnormDrug,
  Drug,
  AuthBuilderWorkflowStep,
  Location,
  AuthCategoryResponse,
  PatientStatus,
  AuthorizationType,
  AdmissionSourceResponse,
  PatientDischargeStatusResponse,
  AdditionalCareParticipant,
  AdditionalInsurance,
  AuthStatus,
  PossibleAttachmentNudgeReasons,
} from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import { TurnAroundTime } from "@coherehealth/core-platform-api";
export type PartialServiceRequest =
  | (Omit<ServiceRequestCommon & ServiceRequestExpansion, "patient"> & Partial<StandardFields>)
  | undefined;

export type DrugSelection = {
  drug: RxnormDrug | null;
  strengthId?: string;
  strengthDetails?: RxnormStrength;
  quantity?: string;
  days?: string;
  genericAllowed?: boolean | null;
};

export type RxnormStrength = {
  strength: string;
  strengthForm: string;
  dosageLabel: string;
  dosageCode: string;
  dosageCui: string;
  dosageUri: string;
  dosageTty: string;
  ndc: string;
  route: string;
};

export type ServiceRequestFormContent = ContinuationFormContent & {
  carePath?: CarePath;
  isPal?: boolean;
  palCategory?: PALCategory | null;
  bHReviewType?: BehavioralHealthReviewType;
  bHAdmissionType?: BehavioralHealthAdmissionType;
  serviceType?: OscarServiceType;
  drugSelection?: DrugSelection;
  prescribedDrug?: Drug;
  paCaseId?: string;
  authorizationType?: AuthorizationType;
  crdLogId?: string | null;
  nonPalReason?: string;
  nonPalJustification?: string;
};

export type AdditionalCareParticipantFormContent = AdditionalCareParticipant & {
  selectedTin?: string | null;
  selectedAddress?: Location | null;
  isCleared?: boolean;
  isDisabled?: boolean;
  careParticipantOONExceptionRequired?: boolean;
};

export interface PatientStayDateRange {
  rangeStartDate: Date | null;
  rangeEndDate: Date | null;
  requestedLevelOfCareCode: string | null | undefined;
  approvedLevelOfCareCode: string | null | undefined;
  reviewStatus: "APPROVED" | "DENIED" | "PENDING" | "VOID" | "AMENDED" | null;
  initialReviewDecision?: "APPROVED" | "DENIED" | "PENDING" | "VOID" | "AMENDED" | null;
}

export type ContinuationFormContent = {
  id?: string;
  cohereId?: string;
  clinicalService?: ClinicalService;
  clinicalServices?: ClinicalService[];
  nonPxClinicalServiceIds?: string[];
  carePathJourney?: CarePathJourney;
  procedureCodes: ProcedureCode[];
  semanticProcedureCodes?: ProcedureCode[];
  isInpatient: boolean;
  isRecurring?: boolean;
  startDate: Date;
  endDate?: Date;
  expeditedTatUpdateTimestamp?: Date;
  units: string;
  additionalUnits?: string;
  approvedUnits: string;
  unitType: UnitType | undefined;
  isExpedited: boolean;
  isExpeditedByModal?: boolean;
  expeditedReason: string;
  admissionDate?: Date;
  admissionTime?: string;
  admissionSource?: AdmissionSourceResponse;
  expectedAdmissionDate?: Date;
  expectedDischargeDate?: Date;
  dischargeDate?: Date;
  dischargeTime?: string;
  dischargedTo?: PatientDischargeStatusResponse;
  patientStatus?: PatientStatus;
  followUpFaxNumber?: PhoneNumber;
  type?: string;
  workflowStep?: AuthBuilderWorkflowStep;
  additionalVisits?: number;
  authCategory?: AuthCategoryResponse;
  authSubcategory?: string;
  patientStayDateRanges?: PatientStayDateRange[];
  primaryDiagnosisCode?: DiagnosisCode | null;
  secondaryDiagnosisCodes?: DiagnosisCode[];
  orderingProvider?: Provider | null;
  orderingProviderSelectedTin?: string | null;
  orderingProviderSelectedAddress?: Location | null;
  performingProvider?: Provider | null;
  performingProviderSelectedTin?: string | null;
  performingProviderParCheck?: boolean;
  performingProviderSelectedAddress?: Location | null;
  possibleAttachmentNudgeReasons?: Array<PossibleAttachmentNudgeReasons>;
  facility?: Facility | null;
  facilitySelectedTin?: string | null;
  facilityParCheck?: boolean;
  facilitySelectedAddress?: Location | null;
  placeOfService?: PlaceOfService | null;
  userSelectedOONException?: boolean;
  facilityOONExceptionRequired?: boolean;
  performingProviderOONExceptionRequired?: boolean;
  orderingProviderOONExceptionRequired?: boolean;
  userSelectedNonPalCode?: boolean;
  selectedOrderingProvider?: Provider | null;
  selectedPerformingProvider?: Provider | null;
  selectedFacility?: Facility | null;
  selectedPerformingProviderPractice?: Facility | null;
  selectedPerformingProviderPracticeSelectedTIN?: string | null;
  selectedPerformingProviderPracticeAddress?: Location | null;
  selectedPerformingProviderPracticeOONExceptionRequired?: boolean;
  additionalCareParticipants?: AdditionalCareParticipantFormContent[];
  additionalInsurance?: AdditionalInsurance;
  turnAroundTimeDueDate?: TurnAroundTime;
  tatStartTimestamp?: Date;
  authStatus?: AuthStatus;
  expeditedTime?: string;
  expeditedDate?: Date;
};

export const ServiceRequestFormContentInitialization = {
  palCategory: null,
  isInpatient: false,
  placeOfService: null,
  primaryDiagnosisCode: null,
  secondaryDiagnosisCodes: [],
  procedureCodes: [],
  startDate: new Date(),
  units: "",
  unitType: undefined,
  orderingProvider: null,
  orderingProviderSelectedTin: null,
  performingProvider: null,
  performingProviderSelectedTin: null,
  facility: null,
  facilitySelectedTin: null,
  isExpedited: false,
  expeditedReason: "",
  facilitySelectedAddress: null,
  performingProviderSelectedAddress: null,
  orderingProviderSelectedAddress: null,
  approvedUnits: "",
  patientStayDateRanges: [],
  additionalCareParticipants: [],
};

export type RedirectRuleCheckFn = (
  ruleActionRequest: GetTransientServiceRequestRuleActionsRequestBody
) => Promise<void>;

interface ApprovedSREditWarningProps {
  message: string;
  onCancelChange?: () => void;
}

export const ApprovedSREditWarning = ({ message, onCancelChange }: ApprovedSREditWarningProps) => (
  <>
    <WarningMessage>{message}</WarningMessage>
    {Boolean(onCancelChange) && (
      <CancelChangesButton onClick={onCancelChange}>
        <Caption>Cancel change</Caption>
      </CancelChangesButton>
    )}
  </>
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningMessage = styled("span")(({ theme }) => ({
  paddingRight: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CancelChangesButton = styled((props) => <InlineButton {...props} />)({
  padding: 0,
  verticalAlign: "baseline",
});

export const WithdrawAndReplaceWarning =
  "We will submit this change as a new service request. Your new authorization number will replace your original request which will be withdrawn.";

export const NON_COHERE_USER_EDIT_DISABLED_TOOLTIP =
  "To make changes to this field please contact Cohere Health at 1-833-283-0033";

export const ReplaceWarning = "We will submit this change as a new service request with a new authorization number.";

export const UnitsInitialWidth = 125;
export const UnitsInitialWidthNotOnPx = 92;
export const UnitsContinuationWidth = 200;
export const DateSelectResponsiveWidth = 224;
export const DateSelectWidth = 278;
export const FullUnitsWidth = 278;
export const ServiceRequestReviewFormWidth = 856;
export const ServiceRequestReviewFormCenterWidth = 912;
export const ServiceRequestSummaryFormWidth = 830;

/*
  Creates an MUI Grid item that is flexed, this is useful when you want to wrap a few objects in a row
  An MUI Grid container will add some negative margin, while just a plain item won't be flexed and can
  have issues wrapping on a small screen
*/
// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FlexGridItem = styled(Grid)({
  display: "flex",
  padding: "8px",
});
export const convertSRFormToContinuationForm = (content: ServiceRequestFormContent): ServiceRequestFormContent => {
  return {
    id: content?.id,
    cohereId: content?.cohereId,
    clinicalService: content?.clinicalService,
    clinicalServices: content?.clinicalServices,
    procedureCodes: content?.procedureCodes,
    semanticProcedureCodes: content?.semanticProcedureCodes,
    isInpatient: content?.isInpatient,
    isRecurring: content?.isRecurring,
    startDate: content?.startDate,
    endDate: content?.endDate,
    units: content?.units,
    approvedUnits: content?.approvedUnits,
    unitType: content?.unitType,
    isExpedited: content?.isExpedited,
    expeditedReason: content?.expeditedReason,
    expeditedTatUpdateTimestamp: content?.expeditedTatUpdateTimestamp,
    tatStartTimestamp: content?.tatStartTimestamp,
    patientStatus: content?.patientStatus ?? "NOT_YET_ADMITTED",
    admissionDate: content?.admissionDate,
    admissionTime: content?.admissionTime,
    admissionSource: content?.admissionSource,
    expectedAdmissionDate: content?.expectedAdmissionDate,
    expectedDischargeDate: content?.expectedDischargeDate,
    dischargeDate: content?.dischargeDate,
    dischargeTime: content?.dischargeTime,
    dischargedTo: content?.dischargedTo,
    patientStayDateRanges: content?.patientStayDateRanges,
    followUpFaxNumber: content?.followUpFaxNumber,
    type: content?.type,
    workflowStep: content?.workflowStep,
    additionalVisits: content?.additionalVisits,
    primaryDiagnosisCode: content?.primaryDiagnosisCode,
    secondaryDiagnosisCodes: content?.secondaryDiagnosisCodes,
    orderingProvider: content?.orderingProvider,
    orderingProviderSelectedTin: content?.orderingProviderSelectedTin,
    orderingProviderSelectedAddress: content?.orderingProviderSelectedAddress,
    selectedFacility: content?.selectedFacility,
    selectedOrderingProvider: content?.selectedOrderingProvider,
    selectedPerformingProvider: content?.selectedPerformingProvider,
    selectedPerformingProviderPractice: content?.selectedPerformingProviderPractice,
    selectedPerformingProviderPracticeAddress: content?.selectedPerformingProviderPracticeAddress,
    selectedPerformingProviderPracticeSelectedTIN: content?.selectedPerformingProviderPracticeSelectedTIN,
    selectedPerformingProviderPracticeOONExceptionRequired:
      content?.selectedPerformingProviderPracticeOONExceptionRequired,
    performingProvider: content?.performingProvider,
    performingProviderSelectedTin: content?.performingProviderSelectedTin,
    performingProviderParCheck: content?.performingProviderParCheck,
    performingProviderSelectedAddress: content?.performingProviderSelectedAddress,
    possibleAttachmentNudgeReasons: content?.possibleAttachmentNudgeReasons,
    facility: content?.facility,
    facilitySelectedTin: content?.facilitySelectedTin,
    facilityParCheck: content?.facilityParCheck,
    facilitySelectedAddress: content?.facilitySelectedAddress,
    placeOfService: content?.placeOfService,
    authCategory: content?.authCategory,
    authSubcategory: content?.authSubcategory,
    userSelectedOONException: content?.userSelectedOONException,
    userSelectedNonPalCode: content?.userSelectedNonPalCode,
    additionalCareParticipants: content?.additionalCareParticipants,
    turnAroundTimeDueDate: content?.turnAroundTimeDueDate,
    authStatus: content?.authStatus,
    nonPalReason: content?.nonPalReason,
    nonPalJustification: content?.nonPalJustification,
  };
};
