import { Coverage, Patient } from "@coherehealth/core-platform-api";
import { compareAsc, isWithinInterval, max, isBefore, addDays, isAfter, isSameDay, isEqual } from "date-fns";
import {
  formatDateStr,
  parseDateFromISOString,
  parseDateFromISOStringWithoutFallback,
  removeTimeFromDate,
  today,
} from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";

/**
 * Get the current primary coverage for a patient
 *
 * See PatientService::findPrimaryCoverage in core-platform
 */

// returns only active coverage
export const getCurrentPrimaryCoverage = (patient?: Patient): Coverage | null => {
  const sortedCoverages = getSortedListOfCoverages(patient);
  if (sortedCoverages?.[0]?.planActive) {
    return sortedCoverages[0];
  }
  return null;
};

// returns all active and inactive coverage
export const getCurrentCoverage = (patient?: Patient): Coverage | null => {
  const sortedCoverages = getSortedListOfCoverages(patient);
  return sortedCoverages?.[0] || null;
};

/**
 * getCoverageBasedOnDate: Gets the coverage of a patient based on a given date, if no coverage exists return null
 *
 * @param date: given date to compare coverage start and end dates with
 * @param patient: given patient to retrieve coverage based on a certain date
 *
 * @return Possible coverage based on sorted patient coverages
 **/
export const getCoverageBasedOnDate = (date?: Date, patient?: Patient): Coverage | null => {
  if (date) {
    return (
      getSortedListOfCoverages(patient)?.find((coverage) => {
        const coverageStart = parseDateFromISOStringWithoutFallback(coverage.planStart);
        const coverageEnd = parseDateFromISOStringWithoutFallback(coverage.planEnd);
        return coverageStart && coverageEnd && coverageStart <= date && date <= coverageEnd;
      }) || null
    );
  }

  return null;
};

export const getSortedListOfCoverages = (patient?: Patient): Coverage[] | null => {
  if (!patient) {
    return null;
  }

  // "coverage" is the legacy single coverage, "coverages" is the new format which allows for multiple coverages
  const { coverage, coverages } = patient;
  if (!Boolean(coverages)) {
    // if there are none of the new "coverages" then just return coverage
    // todo this can probably be removed after the member flat file is released and the old coverage field is deprecated
    return coverage ? [coverage] : null;
  }

  return coveragePriority(coverages ? coverages : []);
};

export const coveragePriority = (coverages: Coverage[]): Coverage[] | null => {
  return (
    [...(coverages || [])].sort((c0, c1) => {
      // if 1 plan is active and the other is not, the active coverage has higher priority
      if (c0.planActive && !c1.planActive) {
        return -1;
      }
      if (!c0.planActive && c1.planActive) {
        return 1;
      }
      if (!c0.planActive && !c1.planActive) {
        // if both plans are inactive, return the one with the earlier start date
        return compareAsc(parseDateFromISOString(c0.planStart), parseDateFromISOString(c1.planStart));
      }

      // both plans are active
      return getCoveragePriority(c0) - getCoveragePriority(c1);
    }) || null
  );
};

export const removeDuplicateCoverages = (coverages: Coverage[]): Coverage[] => {
  const seen: { [key: string]: boolean } = {};

  return coverages.reduce((uniqueCoverages: Coverage[], coverage: Coverage) => {
    const { healthPlanName: planName, planType, memberId } = coverage;
    const memberType = coverage.coverageLineOfBusinessType || coverage.lineOfBusinessType;
    const key = `${planName}-${planType}-${memberId}-${memberType}`;

    if (!seen[key]) {
      seen[key] = true;
      uniqueCoverages.push(coverage);
    }

    return uniqueCoverages;
  }, []);
};
/**
 * getPatientHealthPlanName: Gets the healthPlanName of a patient based on a given date
 *  if no coverage exists for given date return the highestPriorityCoverage available (active/inactive)
 *  if no startDate was passed get the highestPriorityCoverage available (active/inactive)
 *
 * @param patient: given patient to retrieve healthPlanName based on a certain date
 * @param startDate: given date to compare coverage start and end dates with
 *
 * @return Possible healthPlanName based on sorted patient coverages
 **/
export const getPatientHealthPlanName = (patient?: Patient, startDate?: Date): string | null => {
  const sortedCoverages = getSortedListOfCoverages(patient);
  if (startDate) {
    return (
      sortedCoverages?.find((coverage) => {
        const coverageStart = parseDateFromISOStringWithoutFallback(coverage.planStart);
        const coverageEnd = parseDateFromISOStringWithoutFallback(coverage.planEnd);
        return coverageStart && coverageEnd && coverageStart <= startDate && startDate <= coverageEnd;
      })?.healthPlanName ||
      sortedCoverages?.[0]?.healthPlanName ||
      null
    );
  }

  return sortedCoverages?.[0]?.healthPlanName || null;
};

export const getPatientRiskBearingEntity = (patient?: Patient, startDate?: Date): string | null => {
  const sortedCoverages = getSortedListOfCoverages(patient);
  if (startDate) {
    return (
      sortedCoverages?.find((coverage) => {
        const coverageStart = parseDateFromISOStringWithoutFallback(coverage.planStart);
        const coverageEnd = parseDateFromISOStringWithoutFallback(coverage.planEnd);
        return coverageStart && coverageEnd && coverageStart <= startDate && startDate <= coverageEnd;
      })?.riskBearingEntity ||
      sortedCoverages?.[0]?.riskBearingEntity ||
      null
    );
  }

  return sortedCoverages?.[0]?.riskBearingEntity || null;
};

/**
 * Used for determining primary coverage. The lower priority values should be chosen first.
 *
 * See PatientService::findPrimaryCoverage in core-platform
 */
const getCoveragePriority = (coverage: Coverage): number => {
  if (coverage.isCommercial) {
    return 1;
  } else if (coverage.coverageLineOfBusinessType === "Other - TPA") {
    return 2;
  } else if (coverage.isMedicare) {
    return 3;
  } else if (coverage.coverageLineOfBusinessType === "Other - CHIP") {
    return 4;
  } else if (coverage.isMedicaid) {
    return 4;
  } else {
    return 99;
  }
};

/**
 * will combine coverage intervals iff they are consecutive or if one starts in the middle of the other
 * @param coverages member coverages
 */

export const getCombinedCoverageRanges = (coverages: Coverage[]): { start: Date; end: Date }[] => {
  const coverageDates = coverages
    .map((coverage: Coverage) => {
      return { start: parseDateFromISOString(coverage.planStart), end: parseDateFromISOString(coverage.planEnd) };
    })
    .sort((a, b) => {
      return compareAsc(a.start, b.start);
    });

  if (coverageDates && coverageDates.length > 0) {
    const mergedDates = [coverageDates[0]];

    for (let i = 1; i < coverageDates.length; i++) {
      const currentInterval = coverageDates[i];
      const lastMergedInterval = mergedDates[mergedDates.length - 1];
      if (
        isBefore(currentInterval.start, lastMergedInterval.end) ||
        isSameDay(currentInterval.start, lastMergedInterval.end) ||
        isSameDay(addDays(lastMergedInterval.end, 1), currentInterval.start)
      ) {
        lastMergedInterval.end = max([lastMergedInterval.end, currentInterval.end]);
      } else {
        mergedDates.push(currentInterval);
      }
    }

    return mergedDates;
  } else {
    return [];
  }
};

const checkWithinRange = (startDate: Date | number, endDate: Date | number, serviceDate: Date): boolean => {
  try {
    return isWithinInterval(serviceDate, {
      start: startDate,
      end: endDate,
    });
  } catch (e) {
    return false;
  }
};

export interface CoverageCheck {
  inRange: boolean;
  messageToDisplay?: string[];
}

export interface DateCheck {
  inRange: boolean;
  messageToDisplay?: string[];
}

export const checkMinDate = (serviceDate: Date): DateCheck => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today().getDate() - 30);

  //Check if the service date is more than 30 days in the past
  if (serviceDate < thirtyDaysAgo) {
    return {
      inRange: false,
      messageToDisplay: [`Date is more than 30 days in the past.`],
    };
  }
  return {
    inRange: true,
  };
};

export const checkOnetimeRequestCoverage = (
  coverages: Coverage[],
  serviceDate: Date,
  bypassCoverage?: boolean
): CoverageCheck => {
  if (coverages.length >= 1) {
    // get the combined coverage intervals, this is to make sure we get ride of any overlapping or consecutive date ranges.
    const combinedCoveragesIfAny = getCombinedCoverageRanges(coverages);

    // calculate if the selected service date is in range any of the coverage intervals
    const isDateWithinAnyCoverage = combinedCoveragesIfAny
      .map((coverageInterval: Interval): boolean =>
        checkWithinRange(coverageInterval.start, coverageInterval.end, serviceDate)
      )
      .includes(true);

    // if it is, then we are done, no need to alert the user.
    // If bypassCoverage is true then we will always return true as long as there is some coverage
    if (isDateWithinAnyCoverage || bypassCoverage) {
      return {
        inRange: true,
      };
      // else if we cant find any coverage range that fits with our service date
    } else {
      // then check if today is in any of the coverage ranges as we need to a date to show the user.
      // we need to find which coverage to show when the member has multiple coverages.
      const coveragesWithToday = combinedCoveragesIfAny.filter((coverageInterval: Interval) => {
        return checkWithinRange(coverageInterval.start, coverageInterval.end, today());
      });
      // if today is in some coverage range
      if (coveragesWithToday.length >= 1) {
        // then if the service date is before the first coverage start date, then we are done, alert the user that the selected date is before that date
        // getting the first of the coverages as they are already sorted via the start date.
        if (isBefore(serviceDate, coveragesWithToday[0].start)) {
          return {
            inRange: false,
            messageToDisplay: [
              `Coverage doesn't start until ${formatDateStr(
                coveragesWithToday[0].start instanceof Date ? coveragesWithToday[0].start : ""
              )}.`,
            ],
          };
          // if the service date is after the coverage end date, then alert the user accordingly
        } else if (isAfter(removeTimeFromDate(serviceDate), coveragesWithToday[0].end)) {
          return {
            inRange: false,
            messageToDisplay: [
              `Coverage ends on ${formatDateStr(
                coveragesWithToday[0].end instanceof Date ? coveragesWithToday[0].end : ""
              )}.`,
            ],
          };
          // if the service date is same as the coverage end date, return true
        } else if (isEqual(removeTimeFromDate(serviceDate), coveragesWithToday[0].end)) {
          return {
            inRange: true,
          };
        }
        // if today is not in any of the coverage ranges
      } else {
        // we need to find the future coverage interval from service date
        // then loop through and get the first coverage interval such that the service date is before the coverage start date.
        let dateToShow: Date | number = 0;
        for (let j = 0; j < combinedCoveragesIfAny.length; j++) {
          if (isBefore(serviceDate, combinedCoveragesIfAny[j].start)) {
            dateToShow = combinedCoveragesIfAny[j].start;
            break;
          }
        }
        // if we have a coverage range that suits the above criteria, then we are done, alert the user that the selected date before the future coverage start date.
        if (dateToShow) {
          return {
            inRange: false,
            messageToDisplay: [
              `Coverage doesn't start until ${formatDateStr(dateToShow instanceof Date ? dateToShow : "")}.`,
            ],
          };
          // else it means that all the coverages ended before the service date, and so we should alert the user accordingly.
        } else {
          dateToShow = combinedCoveragesIfAny[combinedCoveragesIfAny.length - 1].end;
          return {
            inRange: false,
            messageToDisplay: [`Coverage ends on ${formatDateStr(dateToShow instanceof Date ? dateToShow : "")}.`],
          };
        }
      }
    }
  }
  return {
    inRange: false,
  };
};

/**
 * To determine if the selected service dates are not in-sync with any of the coverages.
 */
export const checkRecurringRequestCoverage = (
  coverages: Coverage[],
  serviceStartDate: Date,
  serviceEndDate: Date
): CoverageCheck => {
  const combinedCoverageIntervalsIfAny = getCombinedCoverageRanges(coverages);

  // calculate if the selected service dates both in range with any of the coverage intervals
  const areDateWithinAnyCoverage = combinedCoverageIntervalsIfAny
    .map((coverageInterval: Interval): boolean => {
      return (
        checkWithinRange(coverageInterval.start, coverageInterval.end, serviceStartDate) &&
        checkWithinRange(coverageInterval.start, coverageInterval.end, new Date(serviceEndDate.toDateString()))
      );
    })
    .includes(true);

  // if there are any, then we are good, no need to alert the user.
  if (areDateWithinAnyCoverage) {
    return {
      inRange: true,
    };
  } else {
    // else we want to show tha user all the coveage the member has.
    const message = combinedCoverageIntervalsIfAny.map((coverageInterval: Interval): string => {
      return `Member has coverage from ${formatDateStr(
        coverageInterval.start instanceof Date ? coverageInterval.start : ""
      )} to ${formatDateStr(coverageInterval.end instanceof Date ? coverageInterval.end : "")}.`;
    });
    return {
      inRange: false,
      messageToDisplay: message,
    };
  }
};

const getCoveragesInfo = (patient: Patient): Coverage[] | undefined => {
  if (patient?.coverages) {
    return patient.coverages;
  } else {
    return patient?.coverage ? [patient?.coverage] : undefined;
  }
};

/**
 * To display the member coverage info on the patient search.
 */
export const checkMemberCoverageInfo = (patient: Patient): string => {
  const coverages: Coverage[] | undefined = getCoveragesInfo(patient);
  const currentPrimaryCoverage = getCurrentPrimaryCoverage(patient);
  // if there is a an active coverage associated with member, then no need to show any alert message.
  if (currentPrimaryCoverage) {
    return "";
  } else {
    // else check if the member has any coverages to start with
    if (coverages) {
      // if there are coverages, then we should find the future coverage date to show the user
      const combinedCoveragesIfAny = getCombinedCoverageRanges(coverages);
      const dateToCheck = today();
      let dateToShow: Date | number = 0;
      for (let j = 0; j < combinedCoveragesIfAny.length; j++) {
        if (isBefore(dateToCheck, combinedCoveragesIfAny[j].start)) {
          dateToShow = combinedCoveragesIfAny[j].start;
          break;
        }
      }
      // if we find one, then diplay the alert accordingly
      if (dateToShow) {
        const displayDateStr = formatDateStr(dateToShow instanceof Date ? dateToShow : "");
        return `The coverage start date for this patient is ${displayDateStr}. Any service you schedule must occur after ${displayDateStr}.`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
};

export const isMemberCoverageExpired = (
  patient: Patient,
  healthPlanName: string,
  startDateOfService: Date,
  lastDateOfService: Date,
  serviceRequest: ServiceRequestResponse | null
) => {
  if (serviceRequest?.encounterType === "INPATIENT") {
    return false;
  } else {
    const coverages = patient?.coverages || (patient?.coverage ? [patient.coverage] : undefined);
    return coverages
      ? coverageEndsBeforeLastDateOfService(coverages, healthPlanName, startDateOfService, lastDateOfService)
      : true;
  }
};

export const coverageEndsBeforeLastDateOfService = (
  coverages: Coverage[],
  healthPlanName: string,
  startDateOfService: Date,
  lastDateOfService: Date
) => {
  const healthPlanCoverages = coverages?.filter((coverage) => coverage.healthPlanName === healthPlanName);
  if (healthPlanCoverages && healthPlanCoverages.length > 0) {
    const combinedCoverageIntervalsIfAny = getCombinedCoverageRanges(healthPlanCoverages);
    return !combinedCoverageIntervalsIfAny
      ?.map((coverageInterval: Interval): boolean => {
        return isBefore(coverageInterval.end, startDateOfService) || isBefore(coverageInterval.end, lastDateOfService);
      })
      .includes(false);
  } else {
    // no healthPlan coverage so should return true
    return true;
  }
};
