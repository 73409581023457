import {
  AuthBuilderWorkflowStep,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
  useGetPublishedClinicalService,
  useUpdateServiceRequest,
} from "@coherehealth/core-platform-api";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getParametersFaxIntake, setParams } from "util/queryParams";
import { lastWorkflowStepPath } from "util/serviceRequest";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";

export default function useDraftContinue(
  serviceRequest?: ServiceRequestResponse | ServiceRequestSearchResponse,
  isFaxAuthBuilderFlow?: boolean
) {
  const { mutate: updateServiceRequest } = useUpdateServiceRequest({ id: "" });
  const { refetch: getClinicalServiceData } = useGetPublishedClinicalService({
    originalVersionId: "",
    lazy: true,
  });

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);
  const navigate = useNavigate();
  const { faxAttachment, caseId, queueId } = useContext(FaxAttachmentContext);

  if (!serviceRequest) {
    return async (_: AuthBuilderWorkflowStep) => {};
  } else {
    const { id, clinicalService } = serviceRequest;

    // navigate to the page corresponding with "workflowStep"
    const navigateToLastWorkflowStep = (workflowStep: AuthBuilderWorkflowStep) => {
      const { pathname, search } = lastWorkflowStepPath({
        workflowStep: workflowStep,
        serviceRequest: serviceRequest,
        inSmartOnFhirWorkflow: inSmartOnFhirWorkflow,
        faxId: faxAttachment?.id,
        isFaxAuthBuilderWorkflow: Boolean(isFaxAuthBuilderFlow),
      });

      if (faxAttachment?.id) {
        const faxParameters = getParametersFaxIntake(search, faxAttachment?.id, caseId, queueId);
        const updatedSearchParamPath = `?${setParams("faxStep", faxParameters, workflowStep)}`;
        const url = `${pathname}${updatedSearchParamPath}`;
        navigate(url);
      } else {
        const updatedSearchParamPath = `?${setParams("authBuilderStep", search, workflowStep)}`;
        const url = `${pathname}${updatedSearchParamPath}`;
        navigate(url);
      }
    };

    const onDraftContinue = async (workflowStep: AuthBuilderWorkflowStep) => {
      if (!clinicalService?.isCurrentPublished && clinicalService?.originalVersionId) {
        const response = await getClinicalServiceData({
          pathParams: { originalVersionId: clinicalService.originalVersionId },
        });
        if (response?.isUnitsOnPx !== clinicalService.isUnitsOnPx) {
          await updateServiceRequest(
            {
              clinicalService: response?.id,
              workflowStep: "GATHER_REQUIREMENTS_MEDICAL",
            },
            { pathParams: { id: id } }
          );
          navigateToLastWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
        } else {
          navigateToLastWorkflowStep(workflowStep);
        }
      } else {
        switch (workflowStep) {
          case "GATHER_REQUIREMENTS_MEDICAL":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "CLINICAL_ASSESSMENT":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "VENDOR_CLINICAL_ASSESSMENT":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "ADD_ATTACHMENTS":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "REVIEW_NUDGES":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "REVIEW":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "FILL_FORMS_CONTINUATION":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "CLINICAL_ASSESSMENT_CONTINUATION":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "ADD_ATTACHMENTS_CONTINUATION":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "REVIEW_CONTINUATION":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "FILL_FORMS_GENERAL_AUTH_SUBMISSION":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "PROVIDER_DETAILS":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "SERVICE_DETAILS":
            navigateToLastWorkflowStep(workflowStep);
            break;
          case "PRE_APPROVED_CARE_DETAILS":
            navigateToLastWorkflowStep(workflowStep);
            break;
        }
      }
    };

    return onDraftContinue;
  }
}
