import { AdditionalCareParticipant, PhoneNumber } from "@coherehealth/core-platform-api";
import { ContactButton } from "./RequestDetails";
import { ContactInfoModalProps } from "components/ContactInfoModal";
import { strToPhoneNumber } from "util/phoneUtils";
import { LocationNetworkStatusPill } from "../utils/serviceRequestUtils";
import { useCallback } from "react";

export default function CareParticipantModal({
  careParticipant,
  setModalState,
  closeModal,
  outOfNetworkStatusOverrideEnabled,
  contactInfoButtonText,
}: {
  careParticipant: AdditionalCareParticipant;
  setModalState: React.Dispatch<React.SetStateAction<ContactInfoModalProps>>;
  closeModal: () => void;
  outOfNetworkStatusOverrideEnabled?: boolean;
  contactInfoButtonText: (phones?: PhoneNumber[], faxes?: PhoneNumber[]) => string;
}) {
  const careParticipantOnClickHandler = useCallback(
    () =>
      setModalState({
        open: true,
        name: careParticipant?.name || "None",
        phones: careParticipant?.phoneNumbers || careParticipant?.selectedLocation?.phoneNumbers,
        fax: careParticipant.selectedLocation?.faxNumbers?.map(strToPhoneNumber),
        tinList:
          careParticipant?.tinList ||
          (careParticipant?.selectedLocation?.tin ? [careParticipant.selectedLocation.tin] : []),
        npi: careParticipant?.npi,
        addresses:
          careParticipant?.addresses ||
          (careParticipant?.selectedLocation?.address ? [careParticipant.selectedLocation.address] : []),
        type: careParticipant.careParticipantType || "ORDERING_PROVIDER",
        npiLabel: careParticipant?.npi || "N/A",
        handleClose: closeModal,
      }),
    [careParticipant, closeModal, setModalState]
  );

  const config = useCallback(
    () => ({
      locationIsOutOfNetwork: careParticipant?.selectedLocation?.isOutOfNetwork,
      outOfNetworkStatusOverrideEnabled,
    }),
    [careParticipant, outOfNetworkStatusOverrideEnabled]
  );

  return (
    <>
      <ContactButton onClick={careParticipantOnClickHandler}>
        {contactInfoButtonText(careParticipant?.phoneNumbers, undefined)}
      </ContactButton>
      <LocationNetworkStatusPill location={careParticipant?.selectedLocation ?? undefined} config={config()} />
    </>
  );
}
