import React, { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import {
  Body3,
  Caption,
  TableWithPanels,
  TableWithPanelsColumn,
  TextField,
  colorsLight,
  formatDateToISODate,
  useFeature,
} from "@coherehealth/common";
import {
  Attachment,
  AttachmentUpdatePayload,
  CreateCarePathJourneyAttachmentPathParams,
  CreateServiceRequestAttachmentPathParams,
  ProcedureCode,
  RecommendChangeRuleAction,
  ServiceChangeOption,
  ServiceDeterminationProcedureCodeBucket,
  ServiceDeterminationResponse,
  ServiceRequestResponse,
  useGetClinicalServiceDetermination,
} from "@coherehealth/core-platform-api";
import { Grid, makeStyles, useTheme, Collapse, CircularProgress } from "@material-ui/core";
import { getAdditionalBadgeText, updatedUnits } from "util/NudgeUtils";
import { H6 } from "@coherehealth/design-system";
import { IconButton } from "components/Requestor/common";
import { UploadAttachmentOnNudge } from "./UploadAttachmentOnNudge";
import { UploadFile } from "components/AddAttachments/FileUploadItem";
import { MutateMethod } from "restful-react";
import { useSnackbar } from "notistack";
import { SlimServiceSelect } from "./SlimServiceSelect";

interface Props {
  ruleNudge: RecommendChangeRuleAction;
  answerToNudge: string;
  chosenOption?: string;
  handleNudgeClick: (accept: boolean, groupId: string, optionId?: string) => number | undefined;
  nudgeCopy: {
    title: string;
    subtitle: string;
    message: string;
    affirmativeText: string;
    negativeText: string;
    affirmativeBoldedText: string;
    affirmativeAdditionalText: string;
    negativeBoldedText: string;
    additionalText: string;
    negativeUnits: string;
    negativePxCode: string;
  };
  serviceRequest: ServiceRequestResponse;
  loadingSRAttachment: boolean;
  afterUpload?: (arg0: Attachment, totalFiles: number) => void;
  workflowId?: string;
  uploadFile: MutateMethod<
    Attachment,
    void,
    void,
    CreateCarePathJourneyAttachmentPathParams | CreateServiceRequestAttachmentPathParams
  >;
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  onRemoveFile: ({ id }: { id: string }) => void;
  onUpdateFile: (file: UploadFile) => void;
  updateAttachmentGroup: (fileId: string, attachmentUpdatePayload: AttachmentUpdatePayload) => Promise<void>;
  updateLoading: boolean;
  onUpdateAttachments?: (arg0: Attachment[]) => void;
  deleteAttachment: MutateMethod<void | void[], string, void, any>;
  setUpdatedClinicalServices: Dispatch<SetStateAction<string[] | undefined>>;
  setNeedToSelectClinicalService: Dispatch<SetStateAction<boolean>>;
  selectedCarePathId: string | undefined;
  dismissalReason?: string;
  setDismissalReason: Dispatch<SetStateAction<string>>;
}

export const ServiceChangeOptions = ({
  ruleNudge,
  answerToNudge,
  chosenOption,
  handleNudgeClick,
  nudgeCopy,
  serviceRequest,
  loadingSRAttachment,
  afterUpload,
  workflowId,
  uploadFile,
  files,
  setFiles,
  onRemoveFile,
  onUpdateFile,
  updateLoading,
  updateAttachmentGroup,
  onUpdateAttachments,
  deleteAttachment,
  setUpdatedClinicalServices,
  setNeedToSelectClinicalService,
  selectedCarePathId,
  dismissalReason,
  setDismissalReason,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [buckets, setBuckets] = useState<ServiceDeterminationProcedureCodeBucket[]>([]);
  const [serviceDeterminationResponse, setServiceDeterminationResponse] = useState<ServiceDeterminationResponse>();
  const [selectedServiceOptionIds, setSelectedServiceOptionIds] = useState<string[]>([]);
  const [selectedCategoryOptionId, setSelectedCategoryOptionId] = useState<string>("");
  const collectDismissalReason = useFeature("nudgeDismissalImprovements");

  const {
    mutate: getClinicalServiceDetermination,
    loading: serviceDeterminationLoading,
    error: serviceDeterminationError,
  } = useGetClinicalServiceDetermination({});

  const { enqueueSnackbar } = useSnackbar();
  const resultsLoading = serviceDeterminationLoading;

  useEffect(() => {
    if (serviceDeterminationError) {
      enqueueSnackbar("Failed to get services, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [serviceDeterminationError, enqueueSnackbar]);

  const basePayload = useMemo(
    () => ({
      patientId: serviceRequest.patient?.id,
      encounterType: serviceRequest.encounterType,
      authCategory: serviceRequest.authCategory?.enumName,
      primarySemanticDiagnosisCode: serviceRequest.primaryDiagnosis,
      secondarySemanticDiagnosisCodes: serviceRequest.secondaryDiagnoses || [],
      serviceStartDate: formatDateToISODate(serviceRequest.startDate),
      selectedPlaceOfServiceId: serviceRequest.placeOfService?.id,
      groupProcedureCodes: true,
    }),
    [serviceRequest]
  );

  const responseOptions = useMemo(
    () => ({
      serviceOptions: serviceDeterminationResponse?.procedureCodeGroupOptions?.[0].serviceOptions,
      categoryOptions: serviceDeterminationResponse?.procedureCodeGroupOptions?.[0].categoryOptions,
    }),
    [serviceDeterminationResponse]
  );

  const handleOptionClick = async (accept: boolean, groupId: string, option?: ServiceChangeOption) => {
    handleNudgeClick(accept, groupId, option?.id);

    getClinicalServiceDetermination({
      ...basePayload,
      semanticProcedureCodes: [option?.procedureCode as ProcedureCode],
      selectedCarePathId: selectedCarePathId,
    }).then(async (response) => {
      // if we get buckets in the response then we have succeeded in mapping the inputs to services, and can move on
      if (response.procedureCodeGroupOptions === undefined && response.buckets && response.buckets.length > 0) {
        setUpdatedClinicalServices([response.buckets[0].clinicalService?.id || ""]);
        setServiceDeterminationResponse(response);
        setBuckets(response.buckets);
      } else {
        // multiple services available
        setServiceDeterminationResponse(response);
      }
    });
  };

  // we need user to select a clinical service when applicable to decide if they can continue to the next page
  // selected px code has no clinical services to select, or user has selected a clinical service
  const hasNoClinicalServiceToSelectOrClinicalServiceSelected =
    responseOptions.serviceOptions === undefined ||
    responseOptions.categoryOptions === undefined ||
    (responseOptions.serviceOptions !== undefined && selectedServiceOptionIds.length > 0) ||
    (responseOptions.categoryOptions !== undefined && selectedCategoryOptionId !== "");

  // selected px code has clinical services that need to be selected
  const clinicalServiceNotSelected =
    (responseOptions.serviceOptions !== undefined && selectedServiceOptionIds.length === 0) ||
    (responseOptions.categoryOptions !== undefined && selectedCategoryOptionId === "");

  useEffect(() => {
    if (hasNoClinicalServiceToSelectOrClinicalServiceSelected) {
      setNeedToSelectClinicalService(false);
    } else if (clinicalServiceNotSelected) {
      setNeedToSelectClinicalService(true);
    }
  }, [
    clinicalServiceNotSelected,
    hasNoClinicalServiceToSelectOrClinicalServiceSelected,
    setNeedToSelectClinicalService,
  ]);

  return (
    <>
      {ruleNudge.onAcceptValue?.value instanceof Array &&
        ruleNudge.onAcceptValue?.value.map((option: ServiceChangeOption, index: number) => {
          return (
            <Grid
              container //this div makes the entire option card clickable
              className={`${classes.fullWidthButton} ${
                answerToNudge === "accept" && chosenOption === option.id ? "highlightOption" : ""
              }`}
              onClick={async () => await handleOptionClick(true, ruleNudge.groupId!, option)}
              key={option.id}
              alignItems="center"
              justifyContent="space-between"
              style={{ marginBottom: theme.spacing(2) }}
            >
              <Grid item xs={7}>
                <OptionDetails>
                  {answerToNudge === "accept" && chosenOption === option.id ? (
                    <IconButton>
                      <RadioButtonCheckedIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <RadioButtonUncheckedIcon
                        data-tracking-id="accept-nudge-option"
                        onClick={async () => await handleOptionClick(true, ruleNudge.groupId!, option)}
                      />
                    </IconButton>
                  )}
                  <OptionCopy>
                    <H6 className={classes.selectionText}>
                      Change to <b style={{ color: colorsLight.font.main }}>{option.label}</b>
                    </H6>
                    <Body3 className={classes.selectionTextForServiceChange}>
                      {updatedUnits(option)} • {option.procedureCode?.code}
                    </Body3>
                  </OptionCopy>
                </OptionDetails>
              </Grid>
              <Grid item className={classes.captionContainer}>
                <Caption className={`${classes.caption} ${"acceptNudgeBanner"}`}>
                  {/** using indices is not ideal, but we're confident of having the order maintained */}
                  {getAdditionalBadgeText(index)}
                </Caption>
              </Grid>
              <Grid item xs={12} style={{ marginLeft: theme.spacing(4) }}>
                <Collapse in={answerToNudge === "accept" && chosenOption === option.id} timeout="auto">
                  <TableWithPanels
                    columns={pxTableColumns}
                    panels={[
                      {
                        code: option.procedureCode?.code || "",
                        description: option.procedureCode?.description || "",
                      },
                    ]}
                  />
                </Collapse>
              </Grid>
              <Grid item xs={12} style={{ marginLeft: theme.spacing(4) }}>
                <Collapse
                  in={answerToNudge === "accept" && chosenOption === option.id && resultsLoading}
                  timeout="auto"
                >
                  <OptionCopy>
                    <CircularProgress className={classes.loadingSpinner} size={48} />
                  </OptionCopy>
                </Collapse>
              </Grid>
              <Grid item xs={12} style={{ marginLeft: theme.spacing(4) }}>
                <Collapse
                  in={answerToNudge === "accept" && chosenOption === option.id && (!!responseOptions || !buckets)}
                  timeout="auto"
                >
                  <SlimServiceSelect
                    initialResponse={responseOptions}
                    setUpdatedClinicalServices={setUpdatedClinicalServices}
                    selectedServiceOptionIds={selectedServiceOptionIds}
                    setSelectedServiceOptionIds={setSelectedServiceOptionIds}
                    selectedCategoryOptionId={selectedCategoryOptionId}
                    setSelectedCategoryOptionId={setSelectedCategoryOptionId}
                  />
                </Collapse>
              </Grid>
            </Grid>
          );
        })}
      <Grid
        container //this div makes the entire option card clickable
        className={`${classes.fullWidthButton} ${answerToNudge === "decline" ? "highlightOption" : ""}`}
        onClick={() => handleNudgeClick(false, ruleNudge.groupId!)}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={7}>
          <OptionDetails>
            {answerToNudge === "decline" ? (
              <IconButton>
                <RadioButtonCheckedIcon color="primary" />
              </IconButton>
            ) : (
              <IconButton>
                <RadioButtonUncheckedIcon
                  onClick={() => handleNudgeClick(false, ruleNudge.groupId!)}
                  data-tracking-id="decline-nudge-option"
                  color="inherit"
                />
              </IconButton>
            )}
            <OptionCopy>
              <H6 className={classes.selectionText}>
                {nudgeCopy.negativeText}
                <b style={{ color: colorsLight.font.main }}>{nudgeCopy.negativeBoldedText}</b>
                {nudgeCopy.additionalText}
              </H6>
              <Body3 className={classes.selectionTextForServiceChange}>
                {nudgeCopy.negativeUnits} • {nudgeCopy.negativePxCode}
              </Body3>
            </OptionCopy>
          </OptionDetails>
        </Grid>
        <Grid item className={classes.captionContainer}>
          <Caption className={`${classes.caption} ${"rejectNudgeBanner"}`}>
            Documentation to justify is recommended
          </Caption>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: theme.spacing(4) }}>
          <Collapse in={answerToNudge === "decline"} timeout="auto">
            {collectDismissalReason && ruleNudge.collectDeclineReason === true && (
              <TextField
                multiline
                fullWidth
                label={ruleNudge.declineReasonRequired ? "Reasoning" : "Reasoning (optional)"}
                style={{ marginTop: "8px" }}
                value={dismissalReason}
                onChangeValue={setDismissalReason}
              />
            )}
            <UploadAttachmentOnNudge
              serviceRequest={serviceRequest}
              loadingSRAttachment={loadingSRAttachment}
              afterUpload={afterUpload}
              workflowId={workflowId}
              uploadFile={uploadFile}
              files={files}
              setFiles={setFiles}
              onRemoveFile={onRemoveFile}
              onUpdateFile={onUpdateFile}
              updateLoading={updateLoading}
              updateAttachmentGroup={updateAttachmentGroup}
              deleteAttachment={deleteAttachment}
              onUpdateAttachments={onUpdateAttachments}
            />
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
};

const OptionDetails = ({ children }: { children: ReactNode }) => (
  <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
);

const OptionCopy = ({ children }: { children: ReactNode }) => (
  <div style={{ display: "flex", flexDirection: "column", gap: 4, paddingLeft: 8 }}>{children}</div>
);

interface PxDataInterface {
  code: string;
  description: string;
}

const pxTableColumns: TableWithPanelsColumn<PxDataInterface>[] = [
  {
    columnName: "Code",
    value: (palCatProcCode: ProcedureCode) => palCatProcCode.code,
    width: "5%",
  },
  {
    columnName: "Description",
    value: (palCatProcCode: ProcedureCode) => palCatProcCode.description,
    width: "85%",
  },
];

const useStyles = makeStyles((theme) => ({
  fullWidthButton: {
    "&.highlightOption": {
      border: `2px solid ${colorsLight.brand.blue}`,
    },
    borderRadius: theme.spacing(1),
    border: `1px solid ${colorsLight.gray.divider}`,
    cursor: "pointer",
    padding: theme.spacing(2),
  },
  captionContainer: {
    display: "flex",
  },
  caption: {
    "&.acceptNudgeBanner": {
      backgroundColor: colorsLight.success.light,
      color: colorsLight.success.dark,
    },
    "&.rejectNudgeBanner": {
      backgroundColor: colorsLight.warning.light,
      color: colorsLight.warning.dark,
    },
    padding: "4px 8px 4px 8px",
    display: "flex",
    borderRadius: theme.spacing(0.5),
  },
  selectionTextForServiceChange: {
    color: colorsLight.font.secondary,
    alignItems: "center",
    height: theme.spacing(3),
    alignSelf: "stretch",
  },
  selectionText: {
    color: colorsLight.font.secondary,
  },
  loadingSpinner: {
    display: "block",
    alignSelf: "center",
    margin: "auto",
    marginTop: theme.spacing(3),
  },
}));
