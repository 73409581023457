import { AppealPayload, Attachment } from "@coherehealth/core-platform-api";

export type AppealNoteFormState = Pick<
  AppealPayload,
  | "appealType"
  | "requestorType"
  | "appealChannel"
  | "caseDescription"
  | "isExpedited"
  | "postAppealNote"
  | "outcome"
  | "attachments"
  | "attestationOfRequiredDocs"
> & {
  appealInitiationDate?: Date | null;
  appealInitiationTime?: string;
  appealDeterminationDate?: Date | null;
  postAppealType?: string;
  coverageEndedBy?: string;
};

export const initialAppealNote: AppealNoteFormState = {
  requestorType: undefined,
  appealType: undefined,
  appealChannel: undefined,
  appealInitiationDate: null,
  appealInitiationTime: undefined,
  appealDeterminationDate: null,
  postAppealType: undefined,
  caseDescription: "",
  isExpedited: false,
  coverageEndedBy: undefined,
  postAppealNote: "",
  outcome: undefined,
  attachments: [],
  attestationOfRequiredDocs: false,
};

export type AppealNoteFormAction =
  | { type: "updateAppealNoteForm"; value: AppealNoteFormState }
  | { type: "updateAppealNote"; value: AppealNoteFormState["postAppealNote"] }
  | { type: "addFile"; value: Attachment }
  | { type: "addFiles"; value: Attachment[] }
  | { type: "removeFile"; attachmentId: string }
  | {
      type: "updatePostAppealOutcome";
      value: AppealNoteFormState["outcome"];
    }
  | { type: "updateAttestationOfRequiredDocs" }
  | { type: "updateAppealType"; value: AppealNoteFormState["appealType"] }
  | { type: "updateAppealInitiationDate"; value: AppealNoteFormState["appealInitiationDate"] }
  | { type: "updateAppealInitiationTime"; value: AppealNoteFormState["appealInitiationTime"] }
  | { type: "updateAppealDeterminationDate"; value: AppealNoteFormState["appealDeterminationDate"] }
  | { type: "updateCoverageEndedBy"; value: AppealNoteFormState["coverageEndedBy"] }
  | { type: "updatePostAppealType"; value: AppealNoteFormState["postAppealType"] }
  | { type: "updateAppealChannel"; value: AppealNoteFormState["appealChannel"] }
  | { type: "updateIsExpedited"; value: AppealNoteFormState["isExpedited"] }
  | { type: "updateCaseDescription"; value: AppealNoteFormState["caseDescription"] }
  | { type: "updateRequestorType"; value: AppealNoteFormState["requestorType"] };

export const appealNoteReducer = (state: AppealNoteFormState, action: AppealNoteFormAction): AppealNoteFormState => {
  switch (action.type) {
    case "updateAppealNoteForm":
      return { ...state, ...action.value };
    case "updateAppealNote":
      return { ...state, postAppealNote: action.value };
    case "addFile":
      const files = state.attachments ?? [];
      return { ...state, attachments: [...files, action.value] };
    case "addFiles":
      return { ...state, attachments: action.value };
    case "removeFile":
      const filesWithoutRemovedFile = state.attachments?.filter((attachment) => attachment.id !== action.attachmentId);
      return { ...state, attachments: filesWithoutRemovedFile };
    case "updatePostAppealOutcome":
      return { ...state, outcome: action.value };
    case "updateAttestationOfRequiredDocs":
      return { ...state, attestationOfRequiredDocs: !state.attestationOfRequiredDocs };
    case "updateAppealType":
      return { ...state, appealType: action.value };
    case "updatePostAppealType":
      return { ...state, postAppealType: action.value };
    case "updateRequestorType":
      return { ...state, requestorType: action.value };
    case "updateAppealInitiationDate":
      return { ...state, appealInitiationDate: action.value };
    case "updateAppealInitiationTime":
      return { ...state, appealInitiationTime: action.value };
    case "updateAppealDeterminationDate":
      return { ...state, appealDeterminationDate: action.value };
    case "updateAppealChannel":
      return { ...state, appealChannel: action.value };
    case "updateCoverageEndedBy":
      return { ...state, coverageEndedBy: action.value };
    case "updateIsExpedited":
      return { ...state, isExpedited: action.value };
    case "updateCaseDescription":
      return { ...state, caseDescription: action.value };
    default:
      return state;
  }
};
