import { Grid } from "@material-ui/core";
import {
  statusCopy as authStatusCopy,
  Alert,
  H6,
  pendingReasonMessage,
  Pill,
  PillVariant,
  Tooltip,
  InlineButton,
} from "@coherehealth/common";
import { ClassNameMap } from "@material-ui/styles";
import {
  AuthCategoryResponse,
  AuthorizationResponse,
  AuthStatus,
  PendingReason,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { useContext, useRef } from "react";
import useIsOverflow from "hooks/useIsOverflow";
import SelectedPatientInfo from "./SelectedPatientInfo";
import { FaxAuthorizationContext } from "util/context/FaxAuthorizationContext";

export interface SelectionCardHeaderProps {
  classes: ClassNameMap;
  extractClinicalServiceText: (
    clinicalServices?: AuthorizationResponse["clinicalServices"],
    authCategory?: AuthCategoryResponse
  ) => string;
  authorization: AuthorizationResponse | null;
  latestServiceRequest: ServiceRequestResponse | null;
  serviceCaseId?: string;
  showPatientAlert: boolean;
}

export const authStatusPillVariant: Record<AuthStatus, PillVariant> = {
  DRAFT: "info",
  PENDING_FACILITY_VERIFICATION: "warning",
  PENDING_EXTERNAL_DETERMINATION: "warning",
  PENDING_ASSESSMENT: "info",
  PENDING: "warning",
  INTAKE: "warning",
  RECOMMENDED_PARTIAL_APPROVAL: "warning",
  RECOMMENDED_DENIAL: "warning",
  APPROVED: "success",
  NO_LONGER_NEEDED: "success",
  PARTIALLY_APPROVED: "error",
  DENIED: "error",
  WITHDRAWN: "info",
  VOIDED: "info",
  DISMISSED: "info",
  POST_DENIAL_PEER_TO_PEER: "warning",
};

export const getAuthStatusCopy = (
  authStatus: AuthStatus,
  pendingReason?: PendingReason,
  delegatedVendor?: string,
  healthPlanName?: string
): string => {
  let copy = authStatusCopy(delegatedVendor, healthPlanName)[authStatus];
  if (authStatus === "PENDING" && !!pendingReason) {
    copy = `${copy}: ${pendingReasonMessage(pendingReason)}`;
  }
  return copy;
};

export function AuthorizationCardHeader(props: SelectionCardHeaderProps): JSX.Element {
  const { classes, extractClinicalServiceText, serviceCaseId, authorization, latestServiceRequest, showPatientAlert } =
    props;

  const { handleAuthDetailsSelected } = useContext(FaxAuthorizationContext);

  const { cohereId, authCategory, delegatedVendor, patient } = latestServiceRequest ?? {};

  const { authStatus, authNumber, clinicalServices, healthPlanName, pendingReason } = authorization ?? {};

  const textElementRef = useRef<HTMLInputElement | null>(null);

  const { hoverStatus } = useIsOverflow(textElementRef);

  return (
    <>
      {showPatientAlert && (
        <Grid item xs={12}>
          <Alert severity="warning" message="The patient for this service request is different">
            {patient && <SelectedPatientInfo patient={patient!} />}
          </Alert>
        </Grid>
      )}
      <Grid xs={12} item>
        <div className={classes.cardHeaderLine1}>
          <Tooltip
            title={extractClinicalServiceText(clinicalServices, authCategory)}
            disableHoverListener={!hoverStatus}
            placement="top"
            classes={{ tooltip: classes.customWidth }}
            data-public
          >
            <div
              ref={textElementRef}
              className={
                hoverStatus
                  ? `${classes.h5BodyContainer} ${classes.hover} ${classes.zeroMargin}`
                  : `${classes.h5BodyContainer}`
              }
              data-public
            >
              <H6 data-public>{extractClinicalServiceText(clinicalServices, authCategory)}</H6>
            </div>
          </Tooltip>

          {!serviceCaseId && (
            <InlineButton
              className={classes.requestDetailsLink}
              onClick={() => handleAuthDetailsSelected?.(authorization!!)}
              data-public
            >
              View details
            </InlineButton>
          )}
        </div>
        <div>
          {!!authNumber && (
            <Pill className={classes.cardHeaderItem} label={`Auth #${authNumber}`} variant="info" data-public />
          )}
          {!!cohereId && (
            <Pill className={classes.cardHeaderItem} label={`Tracking #${cohereId}`} variant="info" data-public />
          )}
          {!!authStatus && (
            <Pill
              className={classes.cardHeaderItem}
              label={getAuthStatusCopy(authStatus, pendingReason, delegatedVendor, healthPlanName)}
              variant={authStatusPillVariant[authStatus]}
              data-public
            />
          )}
        </div>
      </Grid>
    </>
  );
}
