import {
  ErrorMessage,
  ReviewType,
  ServiceRequestResponse,
  useGetAllowedPendingReasons,
  useUpdateAuthStatus,
  useSendLetters,
} from "@coherehealth/core-platform-api";
import { SubmissionStatus } from "components/ServiceRequest/Submission/SubmissionModal";
import { pendingReasonOptions } from "components/ServiceRequestStatusDisplay/utils";
import { useState } from "react";

type ReviewCorrectionProps = {
  completedReviews: ReviewType[];
  serviceRequest: ServiceRequestResponse;
};
export type ReviewCorrectionPayload = {
  reviewId?: string;
  correctionReason?: string;
  cohereId?: string;
  pendingReason?: string;
  actionType?: "MOVE_FORWARD" | "MOVE_BACKWARD";
  integrationMatrixStatus?: string;
  authStatus?: string;
};

export type ModalManagerProps = {
  openReviewSelectionModal: boolean;
  openReviewCorrectionModal: boolean;
  openConfirmationModal: boolean;
  openUpdateOutcomeModal: boolean;
};
export const useReviewCorrection = ({ serviceRequest }: ReviewCorrectionProps) => {
  const [nextReviewDateAcknowledged, setNextReviewDateAcknowledged] = useState<boolean>(false);
  const [modalManager, setModalManager] = useState<ModalManagerProps>({
    openReviewSelectionModal: false,
    openReviewCorrectionModal: false,
    openConfirmationModal: false,
    openUpdateOutcomeModal: false,
  });
  const [nextReviewDate, setNextReviewDate] = useState<Date | null>(null);
  const [reviewCorrectionPayload, setReviewCorrectionPayload] = useState<ReviewCorrectionPayload>({});
  const [reviewCorrectionsResponse, setReviewCorrectionsResponse] = useState<string>("");
  const { data: allowedPendingReasonsResponse, refetch: refetchPendingReasons } = useGetAllowedPendingReasons({
    id: serviceRequest.id,
    lazy: true,
  });
  const {
    mutate: updateAuthStatus,
    loading: loadingUpdateAuthStatusRequest,
    error: authStatusRequestError,
  } = useUpdateAuthStatus({});
  const { mutate: sendLetters, loading: loadingSendLettersRequest, error: sendLettersError } = useSendLetters({});
  const isReviewCorrectionsButtonDisabled = () => {
    const { actionType, pendingReason, correctionReason, authStatus } = reviewCorrectionPayload;
    if (reviewCorrectionPayload.actionType === "MOVE_BACKWARD") {
      return !pendingReason || !actionType || !correctionReason;
    } else if (reviewCorrectionPayload.actionType === "MOVE_FORWARD") {
      return (
        !actionType ||
        !correctionReason ||
        !authStatus ||
        (!nextReviewDate && ["CURRENTLY_ADMITTED", "NOT_YET_ADMITTED"].includes(serviceRequest.patientStatus || ""))
      );
    } else {
      return !actionType || !correctionReason;
    }
  };
  const resetReviewCorrectionFields = () => {
    setModalManager({
      openReviewCorrectionModal: false,
      openConfirmationModal: false,
      openReviewSelectionModal: false,
      openUpdateOutcomeModal: false,
    });
    setReviewCorrectionPayload({});
    setNextReviewDate(null);
    setNextReviewDateAcknowledged(false);
  };
  const getPendingReasonOptions = () => {
    return allowedPendingReasonsResponse?.allowedPendingReasons?.length
      ? allowedPendingReasonsResponse.allowedPendingReasons.map(({ id, label }) => ({
          id,
          label,
        }))
      : Object.entries(pendingReasonOptions).map(([id, { label }]) => ({
          id,
          label: String(label),
        })) || [];
  };
  const getSubmissionModalStatus = () => {
    if (loadingUpdateAuthStatusRequest || loadingSendLettersRequest) {
      return SubmissionStatus.Loading;
    }
    if (authStatusRequestError || sendLettersError) {
      return SubmissionStatus.Failure;
    }
    return SubmissionStatus.Success;
  };
  const getFailedSubmissionMessage = () => {
    return (
      (sendLettersError?.data as ErrorMessage)?.message || (authStatusRequestError?.data as ErrorMessage)?.message || ""
    );
  };
  return {
    resetReviewCorrectionFields,
    isReviewCorrectionsButtonDisabled,
    reviewCorrectionsResponse,
    allowedPendingReasonsResponse,
    getPendingReasonOptions,
    getSubmissionModalStatus,
    modalManager,
    setModalManager,
    reviewCorrectionPayload,
    setReviewCorrectionPayload,
    setReviewCorrectionsResponse,
    getFailedSubmissionMessage,
    updateAuthStatus,
    sendLetters,
    refetchPendingReasons,
    nextReviewDate,
    setNextReviewDate,
    nextReviewDateAcknowledged,
    setNextReviewDateAcknowledged,
  };
};
