import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { NotificationViewerConfig } from "./Viewers";
import NotificationViewerShell from "./NotificationViewerShell";
import NotificationViewerSidePanel from "./NotificationViewerSidePanel";
import { useGetNotifications } from "./hooks/useGetNotifications";

// Parent component can specify button appearance via render prop
interface NotificationViewerProps {
  serviceRequest?: ServiceRequestResponse;
  authorization?: AuthorizationResponse;
  serviceRequestsOnAuth?: ServiceRequestResponse[];
  paperlessOptedIn?: boolean;
  renderButton: (handleOpenViewer: () => void) => JSX.Element;
}

export default function NotificationViewer({
  serviceRequest,
  authorization,
  serviceRequestsOnAuth,
  paperlessOptedIn,
  renderButton,
}: NotificationViewerProps) {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const handleOpenViewer = () => setIsViewerOpen(true);
  const handleCloseViewer = () => setIsViewerOpen(false);
  const {
    fetchNotifications,
    notifications,
    setNotifications,
    notificationIndexOpen,
    notificationsLoading,
    currentPage,
    zoomLevel,
    currentRotate,
    onViewNotification,
  } = useGetNotifications({ serviceRequest, authorization, serviceRequestsOnAuth });

  const notificationViewerConfig: NotificationViewerConfig = {
    viewerType: "NotificationViewer",
    entityLabel: "notifications",
    sidePanelLabel: "table of contents",
    sidePanelComponent: NotificationViewerSidePanel,
    closeDialogFunction: handleCloseViewer,
  };

  useEffect(() => {
    if (isViewerOpen) {
      fetchNotifications();
    }
  }, [isViewerOpen, fetchNotifications]);

  const sortedNotifications = notifications.sort((a, b) => {
    const dateA = a.data?.dateCreated ? new Date(a.data.dateCreated).getTime() : null;
    const dateB = b.data?.dateCreated ? new Date(b.data.dateCreated).getTime() : null;

    // Handle the case where either dateCreated is missing or invalid
    if (dateA === null && dateB === null) {
      return 0;
    } // both are missing
    if (dateA === null) {
      return 1;
    } // a goes after b
    if (dateB === null) {
      return -1;
    } // b goes after a

    // If both are valid dates, sort them in chronological order
    return dateA - dateB;
  });

  return (
    <>
      {renderButton(handleOpenViewer)}
      <Dialog fullScreen open={isViewerOpen} onClose={handleCloseViewer}>
        <NotificationViewerShell
          id="notifications"
          notifications={sortedNotifications}
          setNotifications={setNotifications}
          notificationIndexOpen={notificationIndexOpen}
          notificationsLoading={notificationsLoading}
          currentPage={currentPage}
          zoomLevel={zoomLevel}
          currentRotate={currentRotate}
          onViewNotification={onViewNotification}
          viewerConfig={notificationViewerConfig}
          paperlessOptedIn={paperlessOptedIn}
        />
      </Dialog>
    </>
  );
}
