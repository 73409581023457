import { Menu, Popover, Divider, MenuItem } from "@material-ui/core";
import ServiceRequestPrintMenuItems from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestPrintMenuItems";
import NotificationViewer from "components/NotificationViewer/NotificationViewer";
import { AuthorizationResponse, ServiceRequestResponse, VatNotification } from "@coherehealth/core-platform-api";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Body1, Caption, Tooltip, useFeature } from "@coherehealth/common";
import { IconButton } from "@material-ui/core";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PrintIcon from "@material-ui/icons/Print";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { KeyboardArrowRight } from "@material-ui/icons";
import { Permissions, useAuthorized } from "authorization";
import { useDropdownMenuStyles } from "./authorizationDropDownMenuStyles";
import { isTerminalStatus } from "util/serviceRequest";
import { useGetTransferAuthorizationConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { useSnackbar } from "notistack";
import { error as logError, stringifyError } from "logger";
import { useEffect } from "react";
import EcoFriendlyIcon from "components/images/EcoFriendlyIcon.svg";

export type AuthorizationSummaryDropdownMenuT = {
  handlePrintClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  handleClosePrintMenu: () => void;
  handleCloseSubmenu: (e: React.MouseEvent<HTMLLIElement>) => void;
  handleServiceSummaryClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  handleClickPrintButton: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleOpenSearchModal: (e: React.MouseEvent<HTMLLIElement>) => void;
  handleCloseSearchModal: () => void;
  printButtonAnchorEl?: HTMLElement;
  popoverOpen: boolean;
  submenuAnchorEl?: HTMLElement | null;
  generateCallLogPdf?: string;
  vatNotification?: VatNotification[] | null;
  serviceRequest: ServiceRequestResponse;
  authorization?: AuthorizationResponse;
  currentAndPreviousServiceRequests?: ServiceRequestResponse[];
  printButtonDisabled: boolean;
  searchModalOpen: boolean;

  onShowCallLog?: (arg0: ServiceRequestResponse, arg1?: VatNotification | null, arg2?: string) => void;
  showNotificationViewer?: boolean;
};

export const AuthorizationSummaryDropDownMenu = ({
  handleClickPrintButton,
  printButtonDisabled,
  printButtonAnchorEl,
  handleClosePrintMenu,
  handlePrintClick,
  handleOpenSearchModal,
  handleCloseSubmenu,
  handleServiceSummaryClick,
  onShowCallLog,
  popoverOpen,
  submenuAnchorEl,
  generateCallLogPdf,
  serviceRequest,
  authorization,
  vatNotification,
  currentAndPreviousServiceRequests,
  showNotificationViewer,
}: AuthorizationSummaryDropdownMenuT) => {
  const healthPlanName =
    authorization?.healthPlanName ??
    serviceRequest?.healthPlanName ??
    serviceRequest?.patient?.coverage?.healthPlanName;
  const authMenuStyles = useDropdownMenuStyles({});
  const { enqueueSnackbar } = useSnackbar();

  const canViewFinalDeterminationLetter = useAuthorized("FINAL_DETERMINATION_LETTER_DISPLAY");
  const isTransferRequestUser = useAuthorized(Permissions.TRANSFER_AUTHORIZATION);
  const { data: authTransferConfig, error: authTransferConfigError } = useGetTransferAuthorizationConfigurationByPayer(
    healthPlanName ?? ""
  );
  const authorizationTransferEnabled = authTransferConfig?.enabled;

  const serviceRequestsOnAuth = authorization?.serviceRequestsOnAuth ?? currentAndPreviousServiceRequests;
  const printableServiceRequests = [...(serviceRequestsOnAuth ?? [])];
  if (!serviceRequestsOnAuth) {
    printableServiceRequests.push(serviceRequest);
  }
  const isPlaceholderMemberAuthorization =
    authorization?.isPlaceholderMemberAuthorization ?? serviceRequest?.patient?.placeHolderMember;
  const enablePlaceholderMemberTransfer =
    isPlaceholderMemberAuthorization && authTransferConfig?.alwaysEnablePlaceholderMemberAuthTransfers;
  const isAuth = !!authorization && !!printableServiceRequests;
  const disallowAllTerminalRequests =
    !!serviceRequestsOnAuth?.some((sr) => isTerminalStatus(sr)) && !enablePlaceholderMemberTransfer;
  const showPaperlessNotificationStatus = useFeature("updatePaperlessNotificationChanges");

  const authTransferDisabledReason = (): string => {
    const authStatus = authorization?.authStatus ?? serviceRequest?.authStatus;

    if (enablePlaceholderMemberTransfer || !isTransferRequestUser) {
      return "";
    }
    if (isTerminalStatus({ authStatus })) {
      return "Authorizations with a final decision under permanent members cannot be transferred";
    }
    if (disallowAllTerminalRequests) {
      return "Authorizations cannot be transferred if a service request under it has a final decision";
    }
    return "";
  };

  useEffect(() => {
    if (authTransferConfigError) {
      const errorMsg = "Error fetching authTransfer configuration";
      enqueueSnackbar(errorMsg, { preventDuplicate: true });
      logError(`${errorMsg} ${stringifyError(authTransferConfigError.data)}`);
    }
  }, [authTransferConfigError, enqueueSnackbar]);

  return (
    <>
      <IconButton
        disabled={printButtonDisabled}
        onClick={handleClickPrintButton}
        data-testid="drop-down-summary-btn"
        className={authMenuStyles.moreVertIconBtn}
        disableTouchRipple
      >
        <MoreVertIcon className={authMenuStyles.moreVertIcon} />
      </IconButton>

      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: 190,
        }}
        keepMounted
        getContentAnchorEl={null}
        anchorEl={printButtonAnchorEl}
        open={Boolean(printButtonAnchorEl)}
        onClose={handleClosePrintMenu}
        MenuListProps={{ className: authMenuStyles.menuListStyles }}
      >
        {showNotificationViewer ? (
          <div>
            <NotificationViewer
              serviceRequest={isAuth ? undefined : serviceRequest}
              authorization={isAuth ? authorization : undefined}
              serviceRequestsOnAuth={isAuth ? printableServiceRequests : undefined}
              paperlessOptedIn={
                serviceRequest.paperlessOptedInWhenMailVendorTriggered && showPaperlessNotificationStatus
              }
              renderButton={(handleOpenViewer) => (
                <MenuItem
                  onClick={() => {
                    handleOpenViewer();
                    handleClosePrintMenu();
                  }}
                  className={authMenuStyles.menuItem}
                  data-testid={`auth-actions--print-${(authorization ?? serviceRequest)?.authNumber}`}
                >
                  <div className={authMenuStyles.printMenu}>
                    <div className={authMenuStyles.printView}>
                      <PrintIcon className={authMenuStyles.printIcon} />
                      <Body1>Print</Body1>
                    </div>
                    {showPaperlessNotificationStatus && serviceRequest.paperlessOptedInWhenMailVendorTriggered && (
                      <div className={authMenuStyles.paperlessSettingView}>
                        <img alt="" src={EcoFriendlyIcon} className={authMenuStyles.paperlessSettingIcon} />
                        <Caption>Paperless notifications ON</Caption>
                      </div>
                    )}
                  </div>
                </MenuItem>
              )}
            />
            {onShowCallLog &&
              canViewFinalDeterminationLetter &&
              generateCallLogPdf &&
              vatNotification &&
              vatNotification.find((vn) => vn.successful) && (
                <>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      if (vatNotification) {
                        onShowCallLog?.(
                          serviceRequest,
                          vatNotification.find((vn) => vn.successful),
                          generateCallLogPdf
                        );
                        handleClosePrintMenu();
                        // There is no submenu for the notification viewer.
                      }
                    }}
                    className={authMenuStyles.menuItem}
                    data-testid={`auth-actions--call-log-${(authorization ?? serviceRequest)?.authNumber}`}
                  >
                    Final Determination call log #{serviceRequest.cohereId}
                  </MenuItem>
                </>
              )}
          </div>
        ) : (
          <MenuItem onClick={handlePrintClick} className={authMenuStyles.menuItem}>
            <div className={authMenuStyles.printMenu}>
              <div className={authMenuStyles.printView}>
                <PrintIcon className={authMenuStyles.printIcon} />
                <Body1>Print</Body1>
                <KeyboardArrowRight className={authMenuStyles.keyboardArrowRight} />
              </div>
              {showPaperlessNotificationStatus && serviceRequest.paperlessOptedInWhenMailVendorTriggered && (
                <div className={authMenuStyles.paperlessSettingView}>
                  <img alt="" src={EcoFriendlyIcon} className={authMenuStyles.paperlessSettingIcon} />
                  <Caption>Paperless notifications ON</Caption>
                </div>
              )}
            </div>
          </MenuItem>
        )}

        <Divider />
        {authorizationTransferEnabled && Boolean(isTransferRequestUser) && (
          <Tooltip title={authTransferDisabledReason()} placement="left">
            <div>
              <MenuItem
                onClick={handleOpenSearchModal}
                disabled={disallowAllTerminalRequests}
                className={authMenuStyles.menuItem}
                data-testid={`auth-actions--transfer-authorization-${(authorization ?? serviceRequest)?.authNumber}`}
              >
                <SwapHorizIcon className={authMenuStyles.syncAltIcon} />
                <Body1>Transfer</Body1>
              </MenuItem>
            </div>
          </Tooltip>
        )}
        <Popover
          open={popoverOpen}
          anchorOrigin={{
            vertical: "top",
            horizontal: 0,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={submenuAnchorEl}
          onClose={handleCloseSubmenu}
        >
          <MenuItem className={authMenuStyles.menuItem} onClick={handleServiceSummaryClick}>
            <LibraryBooksIcon className={authMenuStyles.libraryBooksIcon} />
            <Body1>Service summary</Body1>
          </MenuItem>
          <Divider />
          {printableServiceRequests?.map((authorizationSR) => {
            return (
              <ServiceRequestPrintMenuItems
                key={authorizationSR.id}
                serviceRequest={authorizationSR}
                handleClosePrintMenu={handleClosePrintMenu}
                handleCloseSubmenu={handleCloseSubmenu}
                generateCallLogPdf={generateCallLogPdf}
                vatNotification={vatNotification}
                onShowCallLog={onShowCallLog}
              />
            );
          })}
        </Popover>
      </Menu>
    </>
  );
};
