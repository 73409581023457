import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Facility,
  PayerProcedureCode,
  AuthStatus,
  ProcedureCode,
  Provider,
  ServiceRequestResponse,
  UnitType,
  useGetReviewStatus,
  WithdrawnReasonUserSelection,
  WithdrawnReasonRR,
  ReviewOutcomeWithdrawOption,
  FinalDeterminationLetterAttachment,
  ServiceRequestCreatePayload,
  FormConfigurationFieldSpec,
  CarePathJourney,
  PalCheckProcedureCodeResponse,
  ServiceRequestSearchResponse,
  AuthBuilderWorkflowStep,
  InternalFinalDeterminationLetterAttachment,
  TatExtensionLetterAttachment,
  LetterAddressee,
  WithdrawRequestor,
  useWithdrawAuthDecisionGroup,
  useUpdateServiceRequest,
  AuthorizationResponse,
  ClinicalService,
  AuthCategoryResponse,
  CareParticipantType,
  AdditionalCareParticipant,
  AdditionalCareParticipantConfig,
  GetOutofNetworkCheckResponse,
  PatientStatus,
  NetworkType,
  Location,
  ServiceRequestUpdatePayload,
} from "@coherehealth/core-platform-api";
import { useAuthorized } from "../authorization";
import compact from "lodash/compact";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import {
  AdditionalCareParticipantFormContent,
  ServiceRequestFormContent,
  PartialServiceRequest,
  PatientStayDateRange,
} from "common/SharedServiceRequestFormComponents";
import {
  authBuilderPath,
  faxAuthBuilderPath,
  NonCohereCodes,
  smartOnFhirAuthBuilderPath,
} from "components/AuthBuilder/common";
import { FormConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import {
  IsExhaustiveArray,
  typeAssert,
  formatDateToISODate,
  parseDateFromISOString,
  parseDateFromISOStringWithoutFallback,
  HUMANA_HEALTH_PLAN_NAME,
  GEISINGER_NAME,
  statusCopy,
  formatDateStr,
  DropdownOption,
  IsNeverType,
  HEALTH_HELP_NAME,
  HEALTH_HELP_V2_NAME,
  findMaxDate,
  useFeature,
} from "@coherehealth/common";
import { getYear } from "date-fns";
import { TrackUserActivityProps, trackUserActivity } from "util/userActivityTracker";
import {
  InformativeModalConfigState,
  SRFormViewState,
} from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormSection";
import { useSnackbar } from "notistack";
import {
  getClinicalServicesText,
  getInitialServiceRequest,
  getLatestApprovedSR,
  getStartAndEndDate,
} from "./authorization";
import {
  aggregateStayDateByCommonFields,
  deAggregateStayDates,
} from "components/ServiceRequest/PatientStay/PatientStays";
import { ClinicalAssessmentProvider } from "components/AuthBuilder";
import elementIsNonNull from "./elementIsNonNull";
import { ServiceCase } from "@coherehealth/qm-api";
import { convertToProperCaseAndFormatAuthCategory } from "./textUtil";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { fieldIsValid } from "common/FormConfigUtils";

/**
 * Whether or not a service request can safely be deleted
 *
 * A service request should only be deleted if it has never been submitted to a payor,
 * otherwise it should be moved to authStatus WITHDRAWN
 */
export const canBeDeleted = (serviceRequest: ServiceRequestResponse) => serviceRequest.authStatus === "DRAFT";

export const isTerminalStatus = (serviceRequest: Pick<ServiceRequestResponse, "authStatus">) =>
  ["DENIED", "APPROVED", "PARTIALLY_APPROVED", "WITHDRAWN", "VOIDED", "DISMISSED"].includes(
    serviceRequest.authStatus || ""
  );

export const isTerminalNonNegationStatus = (serviceRequest: Pick<ServiceRequestResponse, "authStatus">) =>
  ["DENIED", "APPROVED", "PARTIALLY_APPROVED"].includes(serviceRequest.authStatus || "");

/**
 * Whether or not a service request can safely be withdrawn
 *
 * A request that has not been submitted should be deleted, rather than withdrawn
 */
export const canBeWithdrawn = (serviceRequest: ServiceRequestResponse): boolean => {
  if (isHumanaServiceRequest(serviceRequest) || isGeisingerServiceRequest(serviceRequest)) {
    return (
      !canBeDeleted(serviceRequest) &&
      serviceRequest.authStatus !== "WITHDRAWN" &&
      serviceRequest.authStatus !== "VOIDED" &&
      serviceRequest.authStatus !== "DENIED" &&
      serviceRequest.authStatus !== "PARTIALLY_APPROVED" &&
      serviceRequest.authStatus !== "DISMISSED"
    );
  }

  return true;
};

// All auth statuses that have a type of "Pending"
export function getPendingAuthStatuses() {
  return [
    "PENDING_FACILITY_VERIFICATION",
    "PENDING_EXTERNAL_DETERMINATION",
    "INTAKE",
    "PENDING",
    "PENDING_ASSESSMENT",
    "RECOMMENDED_PARTIAL_APPROVAL",
    "RECOMMENDED_DENIAL",
  ];
}

export function sameProviderCheckboxClick(
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>,
  setSameProviders: Dispatch<SetStateAction<boolean>>,
  checked: boolean
) {
  trackUserActivity(`sameProviderCheckboxChecked ${checked}`);

  setFormContent((currentFormContent) => {
    if (checked) {
      if (currentFormContent.orderingProvider?.tinList && currentFormContent.orderingProvider?.tinList.length < 2) {
        return {
          ...currentFormContent,
          performingProvider: currentFormContent.orderingProvider,
          performingProviderSelectedTin: currentFormContent.orderingProvider?.tinList[0],
          performingProviderSelectedAddress: currentFormContent.orderingProviderSelectedAddress,
          selectedPerformingProvider: currentFormContent.selectedOrderingProvider,
        };
      } else if (currentFormContent.orderingProvider?.tinList) {
        return {
          ...currentFormContent,
          performingProvider: currentFormContent.orderingProvider,
          performingProviderSelectedTin: currentFormContent.orderingProviderSelectedTin,
          performingProviderSelectedAddress: currentFormContent.orderingProviderSelectedAddress,
          selectedPerformingProvider: currentFormContent.selectedOrderingProvider,
        };
      } else {
        return {
          ...currentFormContent,
          performingProvider: currentFormContent.orderingProvider,
          performingProviderSelectedAddress: currentFormContent.orderingProviderSelectedAddress,
          selectedPerformingProvider: currentFormContent.selectedOrderingProvider,
        };
      }
    } else {
      return {
        ...currentFormContent,
        performingProvider: null,
        performingProviderSelectedTin: null,
        performingProviderSelectedAddress: null,
        selectedPerformingProvider: null,
      };
    }
  });
  setSameProviders(checked);
}

export const canBeEdited = (serviceRequest: ServiceRequestResponse) => !serviceRequest.isReadOnly;

export function canEditPractice(canEdit: boolean, practice: Provider | Facility | null) {
  if (canEdit && practice?.manuallyCreated) {
    return true;
  } else {
    if (practice?.createdByUser) {
      return true;
    }
  }
  return false;
}

/**
 * Backoffice users (or anyone w/o the EDIT_SERVICE_REQUEST_IN_REVIEW_STAGE permission) cannot edit
 * cannot edit service requests (278 form and clinical assessment) while it is actively under review
 * See https://docs.google.com/spreadsheets/d/11CnWs3xpagq98cNF0Uj3HoDt-48RbH_Uo_j_LCo6YlM/edit#gid=470440329
 */
export const useIsEditDisabledForReview = (
  serviceRequest: Pick<ServiceRequestResponse, "id" | "authStatus" | "healthPlanName">
) => {
  const { data: reviewStatus } = useGetReviewStatus({ id: serviceRequest.id });
  const canEditServiceRequestInReview = useAuthorized("EDIT_SERVICE_REQUEST_IN_REVIEW_STAGE");

  if (
    serviceRequest.healthPlanName === HUMANA_HEALTH_PLAN_NAME &&
    ["PENDING", "INTAKE"].includes(serviceRequest.authStatus || "") &&
    reviewStatus?.summary?.reviewPresent
  ) {
    // if sr is currently pending and there is a review, then you can't edit if you don't have authorization
    return !canEditServiceRequestInReview;
  }
  // if not in pending w/ a review then don't disable editing for this reason
  return false;
};

export const isFinallyDeterminedUtility = (authStatus?: AuthStatus) =>
  Boolean(["DENIED", "APPROVED", "PARTIALLY_APPROVED"].includes(authStatus || ""));

type IsEligibleForPostDenialP2POptions = {
  readonly currentAuthStatus?: AuthStatus;
  readonly initialDecisionDisposition?: AuthStatus;
  readonly revisedDecisionTimestamp?: string | null;
  readonly postDecisionP2PExpanded?: boolean;
};

export const determinePostDenialP2PEligibility = (options: IsEligibleForPostDenialP2POptions) => {
  const { currentAuthStatus, initialDecisionDisposition, revisedDecisionTimestamp, postDecisionP2PExpanded } = options;

  // only one post-denial P2P is allowed per service request
  if (revisedDecisionTimestamp) {
    return false;
  }

  // only DENIED or PARTIALLY_APPROVED service requests with a decisionTimeStamp can have a post-denial P2P
  return (
    !!currentAuthStatus &&
    !!initialDecisionDisposition &&
    ((postDecisionP2PExpanded && ["DENIED", "PARTIALLY_APPROVED", "APPROVED"].includes(currentAuthStatus)) ||
      ["DENIED", "PARTIALLY_APPROVED"].includes(currentAuthStatus))
  );
};

/**
 * The logic below is used in ReviewSummary/ServiceRequestSummaryCard/ServiceRequestReadOnly. The function
 * takes in both a list of procedure codes and a list of payer procedure codes (retrieved from the
 * payerProcedureCode/fromCPTCodes endpoint) and returns a PalCategoryProcedureCodeList array. The idea
 * is that on the service request summary, we group together procedure codes by their palCategory and this
 * structure mimics that framework.
 */

type PalCategoryProcedureCodeList = {
  palCategory: string | undefined;
  procedureCodes: ProcedureCode[];
};

export function getProcedureCodeListSortedByPalCategory(
  procedureCodes: ProcedureCode[] | undefined,
  payerProcedureCodes: PayerProcedureCode[] | null
): PalCategoryProcedureCodeList[] {
  const codes: PalCategoryProcedureCodeList[] = [];
  const palProcedureCodes: string[] = [];
  if (payerProcedureCodes && procedureCodes) {
    payerProcedureCodes.map((pc: PayerProcedureCode) => {
      //find the payer procedure codes' matching "normal" procedure code
      const procedureCodeWithDesc = procedureCodes.filter((pcWithDesc) => pcWithDesc.code === pc.procedureCode);
      //look for an entry in the "codes" list that already has the current palCategory
      const existingPalCategoryProcedureCodePair = codes.find((code) => code.palCategory === pc.palCategory);
      if (procedureCodeWithDesc.length) {
        procedureCodeWithDesc.forEach((pcWithDesc) => {
          palProcedureCodes.push(pcWithDesc.code);
        });
        if (existingPalCategoryProcedureCodePair) {
          //add to existing object in list
          procedureCodeWithDesc.forEach((pcWithDesc) =>
            existingPalCategoryProcedureCodePair.procedureCodes.push(pcWithDesc)
          );
        } else {
          //create a new PalCategoryProcedureCodeList object with a new palCategory
          codes.push({ palCategory: pc.palCategory, procedureCodes: procedureCodeWithDesc });
        }
      }
      return codes;
    });
    //for all of the codes that did not have a matching payer procedure code, add to "nonPal" category entry
    const nonPalProcedureCodes = procedureCodes.filter((pc) => !palProcedureCodes.includes(pc.code));
    if (nonPalProcedureCodes.length > 0) {
      codes.push({ palCategory: "nonPal", procedureCodes: nonPalProcedureCodes });
    }
  }
  return codes;
}

export const getCreatedByName = (serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse) => {
  if (serviceRequest.requestor?.firstName || serviceRequest.requestor?.lastName) {
    return `${serviceRequest.requestor.firstName} ${serviceRequest.requestor.lastName}`;
  } else if (serviceRequest.requestor?.user?.firstName || serviceRequest.requestor?.user?.lastName) {
    return `${serviceRequest.requestor.user.firstName} ${serviceRequest.requestor.user.lastName}`;
  } else {
    return serviceRequest.createdByName || "";
  }
};

const UNIT_TYPE_LABEL_MAP: Record<UnitType, string> = {
  PROCEDURE: "Procedure",
  NUMBER_OF_VISITS: "Number of visits",
  DAY: "Days",
  UNIT: "Units",
  RENTAL_DAY: "Days of rental",
  RENTAL_MONTH: "Months of rental",
  PURCHASE_ITEM: "Purchase item",
  MEDICATION_DOSE: "Doses of medication",
  HOUR: "Hours",
};

function isUnitType(ut: string): ut is UnitType {
  return UNIT_TYPE_LABEL_MAP.hasOwnProperty(ut);
}

export const unitTypeLabel = (unitType: string): string =>
  isUnitType(unitType) ? UNIT_TYPE_LABEL_MAP[unitType] : unitType.toString();

export function assertIsUnitType(ut: string): asserts ut is UnitType {
  if (!isUnitType(ut)) {
    throw new Error("Invalid unit type");
  }
}

export const blankModalMessageFiller =
  "This service request cannot be entered in the CohereNext portal.  Please contact Cohere for more information";

export function isAuthStatus(ipt: unknown): ipt is AuthStatus {
  return typeof ipt === "string" && statusCopy()[ipt as AuthStatus] !== undefined;
}

export function isNegationAuthStatus(status: AuthStatus | undefined): boolean {
  return !!status && ["WITHDRAWN", "VOIDED", "DISMISSED"].includes(status);
}

export function isRecommendedDenial(status: AuthStatus | undefined): boolean {
  return !!status && ["RECOMMENDED_DENIAL", "RECOMMENDED_PARTIAL_APPROVAL"].includes(status);
}

// FIXME: add tests
export function isPostLetterStatus(status: AuthStatus | undefined): boolean {
  return !!status && ["APPROVED", "DENIED", "PARTIALLY_APPROVED", "WITHDRAWN", "VOIDED", "DISMISSED"].includes(status);
}

//needs to be updated if user withdrawn reasons change
const userWithdrawnReasons = [
  "DUPLICATE_REQUEST",
  "INACCURATE_REQUEST_DETAILS",
  "SERVICE_NO_LONGER_REQUIRED",
  "SERVICE_EXCLUDED_FROM_BENEFIT_PLAN",
  "PLAN_CONTRACTUAL_GEOGRAPHIC_CONSTRICTION",
  "PLAN_CONTRACTUAL_GUIDELINES_NOT_FOLLOWED",
  "PRIOR_AUTH_NOT_REQUIRED",
  "REQUESTED_INFO_NOT_RECEIVED",
  "NON_COHERE_REVIEW_ORG_RESPONSIBILITY",
  "REQUEST_DETAILS_INCONSISTENCY",
  "DOES_NOT_MEET_CLINICAL_POLICY",
  "INAPPROPRIATE_LEVEL_OF_CARE",
  "NOT_MEDICALLY_NECESSARY",
  "ALTERNATIVE_SERVICES_RECOMMENDED_BY_REVIEWER",
  "DIAGNOSIS_AGE_OR_GENDER_INCONSISTENCY",
  "DISMISSAL_INVALID_REQUEST",
  "DISMISSAL_MEMBER_EXPIRED",
  "DISMISSAL_IMPROPER_PARTY",
  "OTHER_REVIEW_ORG_RESPONSIBILITY",
  "PATIENT_HAS_BEEN_DISCHARGED",
] as const;
//needs to be updated if record review claim withdrawn reasons change
const rrWithdrawnReasons = [
  "DUPLICATE_APPROVED_REQUEST",
  "DUPLICATE_DENIED_REQUEST",
  "OS_GEOGRAPHIC",
  "OS_CONCURRENT",
  "OS_OTHER",
  "EXCLUDED_FROM_AUTH_REQUEST",
] as const;

// These statements would be type errors if there were things in WithdrawnReasonUserSelection not in userWithdrawnReasons
typeAssert<IsExhaustiveArray<WithdrawnReasonUserSelection, typeof userWithdrawnReasons>>();
typeAssert<IsExhaustiveArray<WithdrawnReasonRR, typeof rrWithdrawnReasons>>();

const withdrawnReasonCopy: Record<string, string> = {
  DUPLICATE_REQUEST: "Duplicate Request",
  INACCURATE_REQUEST_DETAILS: "Request Details Inaccurate (e.g., units, dates, providers, facility, clinicals)",
  SERVICE_NO_LONGER_REQUIRED: "Service is No Longer Required",
  SERVICE_EXCLUDED_FROM_BENEFIT_PLAN: "Service is specifically excluded from the benefit plan",
  PLAN_CONTRACTUAL_GEOGRAPHIC_CONSTRICTION: "Plan/contractual geographic restriction",
  PLAN_CONTRACTUAL_GUIDELINES_NOT_FOLLOWED: "Plan/contractual guidelines not followed",
  PRIOR_AUTH_NOT_REQUIRED: "Prior Authorization Not Required for Service",
  REQUESTED_INFO_NOT_RECEIVED: "Requested Information Not Received",
  NON_COHERE_REVIEW_ORG_RESPONSIBILITY: "Responsibility of Non-Cohere Review Organization",
  REQUEST_DETAILS_INCONSISTENCY: "Service Inconsistent with Request Details",
  DOES_NOT_MEET_CLINICAL_POLICY: "Does Not Meet Plan, NCD/LCD, or other Clinical Policy",
  INAPPROPRIATE_LEVEL_OF_CARE: "Level of Care Not Appropriate",
  NOT_MEDICALLY_NECESSARY: "Not Medically Necessary",
  ALTERNATIVE_SERVICES_RECOMMENDED_BY_REVIEWER: "Alternative Service(s) Recommended By Reviewer",
  DIAGNOSIS_AGE_OR_GENDER_INCONSISTENCY: "Service Inconsistent with Patient Diagnosis, Age, or Gender",
};
const pharmWithDrawnReasonCopy: Record<string, string> = {
  DUPLICATE_REQUEST: "Duplicate Request",
  NOT_MEDICALLY_NECESSARY: "Not Medically Necessary",
  PRESCRIPTION_NO_LONGER_REQUIRED: "Prescription is no longer required",
  INACCURATE_REQUEST_DETAILS: "Request Details Inaccurate (e.g., units, dates, providers, facility, clinicals)",
};
const withdrawnReasonRR: Record<string, string> = {
  DUPLICATE_APPROVED_REQUEST: "Duplicate of a previously approved request",
  DUPLICATE_DENIED_REQUEST: "Duplicate of a previously denied request",
  OS_GEOGRAPHIC: "Plan/contractual geographic restriction",
  OS_CONCURRENT: "Service requires concurrent review",
  OS_OTHER: "Other reason service is out of Cohere’s Scope",
  EXCLUDED_FROM_AUTH_REQUEST: "Service is excluded from auth requirement",
};
const backOfficeViewableWithdrawnReasons: WithdrawnReasonUserSelection[] = [
  "DUPLICATE_REQUEST",
  "INACCURATE_REQUEST_DETAILS",
  "SERVICE_NO_LONGER_REQUIRED",
  "NOT_MEDICALLY_NECESSARY",
];
export function filterForBackOfficeWithdrawReasons(
  withdrawOptions?: { id: string; label?: string | undefined }[]
): ReviewOutcomeWithdrawOption[] | undefined {
  const validOptions = compact(
    withdrawOptions?.map((option) => {
      const found = backOfficeViewableWithdrawnReasons.find((r) => r === option.id);
      if (found) {
        return {
          id: found,
          label: option.label || "",
        };
      } else {
        return null;
      }
    })
  );

  return validOptions.length > 0 ? validOptions : undefined;
}

// fallback for serviceRequestWithdrawalConfiguration
export const withdrawnReasonOptions = (
  canViewAllReasons: boolean,
  serviceRequestId: string = "",
  serviceRequestIsRR: boolean = false,
  requestType: string = "Medical"
) => {
  let options: ReviewOutcomeWithdrawOption[] = [];
  if (requestType === "Pharmacy") {
    for (let id in pharmWithDrawnReasonCopy) {
      options.push({ id: id, label: pharmWithDrawnReasonCopy[id] });
    }
    return options;
  }
  if (!serviceRequestIsRR) {
    for (let id in withdrawnReasonCopy) {
      options.push({ id: id, label: withdrawnReasonCopy[id] });
    }
    if (!canViewAllReasons) {
      options = filterForBackOfficeWithdrawReasons(options) || [];
    }
  } else {
    for (let id in withdrawnReasonRR) {
      options.push({ id: id, label: withdrawnReasonRR[id] });
    }
  }
  return options;
};
export const removeDismissalReasons = (options?: { id: string; label?: string }[]) => {
  const nonDismissalReasons = options?.filter(
    (option: { id: string; label?: string }) => !option.id.toLowerCase().startsWith("dismissal") === true
  );
  return nonDismissalReasons;
};
export const getFinalDeterminationLetterByRecipientType =
  (recipientType: NonNullable<FinalDeterminationLetterAttachment["recipientType"]>) =>
  (
    finalDeterminationLetterAttachments: FinalDeterminationLetterAttachment[] | undefined
  ): FinalDeterminationLetterAttachment | undefined => {
    const sortedLetters = sortBy(
      finalDeterminationLetterAttachments || [],
      (a) => a.deliveryInfo?.processingStartDateTime || a.dateCreated
    ).reverse();
    return (
      // Prefer a provider letter if available, since providers are the users of the platform
      sortedLetters.find((attachment) => attachment.recipientType === recipientType) ||
      // But if we have no provider letter, prefer English-language letter ('eng' or no code, since it's the default)
      // (https://iso639-3.sil.org/code/eng)
      sortedLetters.find((attachment) => !attachment.languageCode || attachment.languageCode === "eng") ||
      // fallback: any old letter
      sortedLetters[0]
    );
  };

export const patientFinalDeterminationLetterToPrint = getFinalDeterminationLetterByRecipientType("PATIENT");
export const finalDeterminationLetterToPrint = getFinalDeterminationLetterByRecipientType("PROVIDER");

export const getInternalFinalDeterminationLetterByLetterAddressee =
  (letterAddressee: LetterAddressee) =>
  (
    internalFinalDeterminationLetterAttachments: InternalFinalDeterminationLetterAttachment[] | undefined
  ): InternalFinalDeterminationLetterAttachment | undefined => {
    const sortedLetters = sortBy(internalFinalDeterminationLetterAttachments || [], (a) => a.dateCreated).reverse();
    return (
      // Prefer a provider letter if available, since providers are the users of the platform
      sortedLetters.find((attachment) => attachment.letterAddressee === letterAddressee) ||
      // fallback: any old letter
      sortedLetters[0]
    );
  };

export const getTatExtensionLetterByLetterAddressee = (
  letterAddressee: LetterAddressee,
  tatExtensionLetterAttachments: TatExtensionLetterAttachment[] | undefined
): TatExtensionLetterAttachment | undefined => {
  const sortedLetters = sortBy(tatExtensionLetterAttachments || [], (a) => a.dateCreated).reverse();
  return sortedLetters.find((attachment) => attachment.letterAddressee === letterAddressee);
};

export function isTatExtended(serviceRequest: ServiceRequestResponse | undefined | null): boolean {
  return Boolean(serviceRequest?.turnAroundTimeExtension);
}

export const patientInternalFinalDeterminationLetterToPrint =
  getInternalFinalDeterminationLetterByLetterAddressee("PATIENT");
export const internalFinalDeterminationLetterToPrint = getInternalFinalDeterminationLetterByLetterAddressee("PROVIDER");
export const pcpInternalFinalDeterminationLetterToPrint = getInternalFinalDeterminationLetterByLetterAddressee("PCP");

export function filterToAuthBucket(
  palCodes: string[],
  nonPalCodes: string[],
  nonCohereCodes: Map<string, string[]>,
  authSubmissionVendorOnPalCheckEnabled: boolean,
  codes?: PalCheckProcedureCodeResponse[],
  nonCoherePxCodes?: string[]
) {
  let pxCodes = codes ? codes : [];
  for (const c of pxCodes) {
    if (!c.isAuthRequired && c.procedureCode) {
      nonPalCodes.push(c.procedureCode);
    } else if (
      c.isAuthRequired &&
      (c.authSubmissionVendor == null || c.authSubmissionVendor === "Cohere") &&
      c.procedureCode
    ) {
      palCodes.push(c.procedureCode);
    } else {
      if (authSubmissionVendorOnPalCheckEnabled) {
        nonCoherePxCodes?.push(c.procedureCode);
        if (
          c.authSubmissionVendor &&
          c.authSubmissionVendor !== "Cohere" &&
          nonCohereCodes.has(c.authSubmissionVendor) &&
          c.procedureCode
        ) {
          nonCohereCodes.get(c.authSubmissionVendor)?.push(c.procedureCode);
        } else {
          let code = [];
          code.push(c.procedureCode ? c.procedureCode : "");
          c.authSubmissionVendor
            ? nonCohereCodes.set(c.authSubmissionVendor, code)
            : nonCohereCodes.set("Could not determine", code);
        }
      } else {
        palCodes.push(c.procedureCode);
      }
    }
  }
}

export function filterToAuthBucketCrd(
  palCodes: string[],
  nonPalCodes: string[],
  nonCohereCodes: Map<string, string[]>,
  authSubmissionVendorOnPalCheckEnabled: boolean,
  codes?: any[],
  nonCoherePxCodes?: string[]
) {
  let pxCodes = codes ? codes : [];
  for (const c of pxCodes) {
    if (!c.isAuthRequired && c.code) {
      nonPalCodes.push(c.code);
    } else if (c.isAuthRequired && (c.authSubmissionVendor == null || c.authSubmissionVendor === "Cohere") && c.code) {
      palCodes.push(c.code);
    } else {
      if (authSubmissionVendorOnPalCheckEnabled) {
        nonCoherePxCodes?.push(c.code);
        if (
          c.authSubmissionVendor &&
          c.authSubmissionVendor !== "Cohere" &&
          nonCohereCodes.has(c.authSubmissionVendor) &&
          c.code
        ) {
          nonCohereCodes.get(c.authSubmissionVendor)?.push(c.code);
        } else {
          let code = [];
          code.push(c.code ? c.code : "");
          c.authSubmissionVendor
            ? nonCohereCodes.set(c.authSubmissionVendor, code)
            : nonCohereCodes.set("Could not determine", code);
        }
      } else {
        palCodes.push(c.code);
      }
    }
  }
}

export function filterToMatchingProcedures(pxs: ProcedureCode[], codes?: string[]): ProcedureCode[] {
  return (
    codes
      ?.map((matchCode) => pxs.find(({ code }) => code === matchCode))
      .filter((x: ProcedureCode | undefined): x is ProcedureCode => Boolean(x)) || []
  );
}

export function filterToCodesAndVendor(nonCohereCodes: Map<string, string[]>, procedureCodes: ProcedureCode[]) {
  let codesAndVendor = new Map<string, ProcedureCode[]>();
  let nonCoherePxCodes: NonCohereCodes[] = [];
  nonCohereCodes.forEach((value: string[], key: string) => {
    let codes = filterToMatchingProcedures(procedureCodes, value);
    codesAndVendor.set(key, codes ? codes : []);
  });
  codesAndVendor.forEach((value: ProcedureCode[], key: string) => {
    let c: NonCohereCodes = {
      authSubmissionVendor: key,
      procedureCode: value,
    };
    nonCoherePxCodes.push(c);
  });
  return nonCoherePxCodes;
}

/**
 * Converts ServiceRequestFormContent to ServiceRequestCreatePayload
 * @param formContent - the form content from a service request form
 * @param authStatus
 * @param workflowId - the rule run id for transient rule runs and varaible actions selection
 * @returns a payload that can be used for a patch or create for ServiceRequest
 */
export function payloadFromSRFormContent(
  formContent: ServiceRequestFormContent,
  simplifiedServiceFrequency: boolean,
  authStatus?: AuthStatus,
  workflowId?: string,
  savePxCodesWithUnits?: boolean
): ServiceRequestCreatePayload {
  const stayDates = formContent?.patientStayDateRanges
    ? deAggregateStayDates(formContent.patientStayDateRanges)
    : undefined;
  return {
    cohereId: formContent.cohereId,
    crdLogId: formContent?.crdLogId || "",
    palCategory: formContent.palCategory?.id || "",
    isPal: formContent.isPal,
    primarySemanticDiagnosisCode: formContent.primaryDiagnosisCode || undefined,
    secondarySemanticDiagnosisCodes: formContent.secondaryDiagnosisCodes,
    startDate: formatDateToISODate(formContent.startDate),
    expeditedTatUpdateTimestamp:
      !!formContent.expeditedTatUpdateTimestamp && formContent.isExpedited
        ? formContent.expeditedTatUpdateTimestamp.toISOString()
        : "",
    semanticProcedureCodes: savePxCodesWithUnits
      ? formContent.procedureCodes.filter((px) => px.units && px.units > 0)
      : formContent.procedureCodes,
    placeOfService: formContent.placeOfService?.id || "",
    encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
    facility: "", // facility can be cleared, setting to undefined won't update
    facilitySelectedTin: "",
    facilityLocation: undefined,
    performingProviderSelectedTin: "",
    performingProviderLocation: undefined,
    performingProvider: "", // performing provider can be cleared, setting to undefined won't update
    orderingProvider: "", // ordering provider can be cleared, setting to undefined won't update
    orderingProviderSelectedTin: "",
    orderingProviderLocation: undefined,
    urgency: {
      isExpedited: formContent.isExpedited,
      reasonNote: formContent.isExpedited ? formContent?.expeditedReason : "", // reason note should be erased when no longer expedited, setting to undefined won't update
    },
    participateCheck: {
      facilityParCheck: formContent.facilityParCheck,
      performingProviderParCheck: formContent.performingProviderParCheck,
    },
    recurrenceType: simplifiedServiceFrequency ? undefined : formContent.isRecurring ? "RECURRING" : "ONETIME",
    ...(formContent.isRecurring || simplifiedServiceFrequency
      ? {
          units: Number(formContent.units),
          unitType: formContent.unitType,
          endDate: formatDateToISODate(formContent?.endDate) || formatDateToISODate(formContent?.expectedDischargeDate),
        }
      : {
          units: undefined,
          unitType: undefined,
          endDate: "",
        }),
    carePathJourney: formContent?.carePathJourney?.id || "",
    clinicalService: formContent?.clinicalService?.id || "",
    nonPxClinicalServiceIds: formContent.nonPxClinicalServiceIds || [],
    authStatus: authStatus || "DRAFT",
    patientStatus: formContent?.patientStatus ?? undefined,
    patientStayDates: stayDates,
    admissionDate:
      formContent.patientStatus !== "NOT_YET_ADMITTED" && formContent?.admissionDate && formContent?.isInpatient
        ? formatDateToISODate(formContent.admissionDate)
        : "",
    admissionTime:
      formContent.patientStatus !== "NOT_YET_ADMITTED" && formContent?.admissionTime && formContent?.isInpatient
        ? formContent.admissionTime + ":00"
        : "",
    admissionSource:
      formContent.patientStatus !== "NOT_YET_ADMITTED" && formContent?.admissionSource?.enumName
        ? formContent?.admissionSource?.enumName
        : undefined,
    expectedAdmissionDate:
      formContent?.expectedAdmissionDate && formContent?.isInpatient
        ? formatDateToISODate(formContent?.expectedAdmissionDate)
        : "",
    expectedDischargeDate:
      formContent?.expectedDischargeDate && formContent?.isInpatient
        ? formatDateToISODate(formContent.expectedDischargeDate)
        : "",
    dischargeDate:
      formContent.patientStatus === "DISCHARGED" && formContent?.dischargeDate
        ? formatDateToISODate(formContent.dischargeDate)
        : "",
    dischargeTime:
      formContent.patientStatus === "DISCHARGED" && formContent?.dischargeTime ? formContent.dischargeTime + ":00" : "",
    dischargedTo:
      formContent.patientStatus === "DISCHARGED" && formContent?.dischargedTo?.enumName
        ? formContent?.dischargedTo?.enumName
        : undefined,
    behavioralHealthReviewType: formContent.bHReviewType,
    behavioralHealthAdmissionType: formContent.bHAdmissionType,
    serviceType: formContent.serviceType,
    followUpFaxNumber: formContent?.followUpFaxNumber,
    workflowId: workflowId,
    workflowStep: formContent?.workflowStep,
    authCategory: formContent?.authCategory?.enumName ? formContent?.authCategory?.enumName : null,
    authSubcategory: formContent?.authSubcategory,
    userSelectedOONException: formContent?.userSelectedOONException,
    userSelectedNonPalCode: formContent?.userSelectedNonPalCode,
    possibleAttachmentNudgeReasons: formContent?.possibleAttachmentNudgeReasons,
    selectedOrderingProvider: formContent.selectedOrderingProvider
      ? updateProviderDetailsPayload(formContent.selectedOrderingProvider)
      : undefined,
    selectedPerformingProvider: formContent.selectedPerformingProvider
      ? updateProviderDetailsPayload(formContent.selectedPerformingProvider)
      : formContent.selectedPerformingProvider,
    selectedFacility: formContent.selectedFacility
      ? updateProviderDetailsPayload(formContent.selectedFacility)
      : formContent.selectedFacility,
    selectedPerformingProviderPractice: formContent.selectedPerformingProviderPractice
      ? updateProviderDetailsPayload(formContent.selectedPerformingProviderPractice)
      : formContent.selectedPerformingProviderPractice,
    additionalCareParticipants: formContent.additionalCareParticipants
      ? updateAdditionalCareParticipantsPayload(formContent.additionalCareParticipants)
      : [],
    additionalInsurance: formContent.additionalInsurance,
    nonPalReason: formContent.nonPalReason,
    nonPalJustification: formContent.nonPalJustification,
  };
}

const updateProviderDetailsPayload = (
  selectedProvider: Provider | Facility | AdditionalCareParticipantFormContent
): Provider | Facility | AdditionalCareParticipantFormContent => {
  return {
    ...selectedProvider,
    providerFacilityId: selectedProvider?.id || "",
    npi: selectedProvider?.npi || "",
    selectedLocation: {
      ...selectedProvider.selectedLocation,
      tin: selectedProvider?.selectedLocation?.tin || "",
      isOutOfNetwork: selectedProvider?.selectedLocation?.isOutOfNetwork || false,
      networkType: selectedProvider?.selectedLocation?.networkType,
      outOfNetworkExceptionReason: selectedProvider?.selectedLocation?.outOfNetworkExceptionReason || "",
      outOfNetworkExceptionComment: selectedProvider?.selectedLocation?.outOfNetworkExceptionComment || "",
      address: {
        line1: selectedProvider?.selectedLocation?.address?.line1 || "",
        line2: selectedProvider?.selectedLocation?.address?.line2 || "",
        zipCode: selectedProvider?.selectedLocation?.address?.zipCode || "",
        state: selectedProvider?.selectedLocation?.address?.state || "",
        city: selectedProvider?.selectedLocation?.address?.city || "",
      },
    },
  };
};

const updateAdditionalCareParticipantsPayload = (
  additionalCareParticipants: AdditionalCareParticipantFormContent[]
): AdditionalCareParticipantFormContent[] => {
  const modifiedAdditionalCareParticipants: AdditionalCareParticipantFormContent[] = additionalCareParticipants.map(
    (additionalCareParticipant) => {
      return updateProviderDetailsPayload(additionalCareParticipant);
    }
  );
  return modifiedAdditionalCareParticipants;
};

export function formContentFromResponse(
  serviceRequest: ServiceRequestResponse,
  isEdit?: boolean,
  initialRenderForDraftServiceRequests?: boolean,
  isNewContinuationSR?: boolean,
  crdLogId?: string,
  authorizationData?: AuthorizationResponse | null
): ServiceRequestFormContent {
  const admissionTimeArray = serviceRequest?.admissionTime ? serviceRequest.admissionTime.split(":") : [];
  const dischargeTimeArray = serviceRequest?.dischargeTime ? serviceRequest.dischargeTime.split(":") : [];

  //do not set discharge date when the continuation flow is triggered from Start continuation button
  const dischargeDate =
    serviceRequest?.requestType === "CONTINUATION" &&
    initialRenderForDraftServiceRequests &&
    isNewContinuationSR &&
    (!serviceRequest.patientStayDates || serviceRequest.patientStayDates.length === 0) // only when no new dates are requested
      ? undefined
      : serviceRequest?.dischargeDate;

  //do not set discharge to when the continuation flow is triggered from Start continuation button
  const dischargeTo =
    serviceRequest?.requestType === "CONTINUATION" &&
    initialRenderForDraftServiceRequests &&
    isNewContinuationSR &&
    (!serviceRequest.patientStayDates || serviceRequest.patientStayDates.length === 0) // only when no new dates are requested
      ? undefined
      : serviceRequest?.dischargedTo;

  //do not set discharge time when the continuation flow is triggered from Start continuation button
  const dischargeTime =
    serviceRequest?.requestType === "CONTINUATION" &&
    initialRenderForDraftServiceRequests &&
    isNewContinuationSR &&
    (!serviceRequest.patientStayDates || serviceRequest.patientStayDates.length === 0) // only when no new dates are requested
      ? ""
      : dischargeTimeArray?.length === 3
      ? `${dischargeTimeArray[0]}:${dischargeTimeArray[1]}`
      : "";

  const latestApprovedSR = getLatestApprovedSR(authorizationData || undefined);

  // Checks if workflow step is FILL_FORMS_CONTINUATION or CLINICAL_ASSESSMENT_CONTINUATION
  const isDiagnosisAndProviderCardOnContinution =
    serviceRequest?.workflowStep === "FILL_FORMS_CONTINUATION" ||
    serviceRequest?.workflowStep === "CLINICAL_ASSESSMENT_CONTINUATION"
      ? true
      : false;

  // Added new fields for all provider address as when going from initial SR to continuation SR, continuation SR is not updating SR object with address fields
  const performingProviderAddressValueOnContinuation =
    isDiagnosisAndProviderCardOnContinution &&
    serviceRequest.selectedPerformingProvider?.selectedLocation?.address === undefined
      ? latestApprovedSR?.selectedPerformingProvider?.selectedLocation
      : serviceRequest.performingProviderLocation;

  const orderingProviderAddressValueOnContinuation =
    isDiagnosisAndProviderCardOnContinution &&
    serviceRequest.selectedOrderingProvider?.selectedLocation?.address === undefined
      ? latestApprovedSR?.selectedOrderingProvider?.selectedLocation
      : serviceRequest.orderingProviderLocation;

  const facilityAddressValueOnContinuation =
    isDiagnosisAndProviderCardOnContinution && serviceRequest.selectedFacility?.selectedLocation?.address === undefined
      ? latestApprovedSR?.selectedFacility?.selectedLocation
      : serviceRequest?.facilityLocation;

  const today = new Date();
  const parsedStartDate = parseDateFromISOString(serviceRequest?.startDate);

  const nonPalReason = serviceRequest.nonPalReason;
  const nonPalJustification = serviceRequest.nonPalJustification;

  return {
    id: serviceRequest?.id,
    cohereId: serviceRequest?.cohereId,
    clinicalService: serviceRequest?.clinicalService,
    clinicalServices: serviceRequest?.clinicalServices?.filter(elementIsNonNull) || [],
    nonPxClinicalServiceIds: serviceRequest?.nonPxClinicalServiceIds || [],
    palCategory: serviceRequest?.palCategory || null,
    isInpatient: serviceRequest?.encounterType === "INPATIENT",
    placeOfService: serviceRequest?.placeOfService || null,
    primaryDiagnosisCode: serviceRequest?.primarySemanticDiagnosisCode || null,
    secondaryDiagnosisCodes: serviceRequest?.secondarySemanticDiagnosisCodes || [],
    procedureCodes: serviceRequest?.procedureCodes || [],
    isRecurring:
      typeof serviceRequest?.recurrenceType === "undefined"
        ? undefined
        : serviceRequest?.recurrenceType === "RECURRING",
    startDate:
      serviceRequest.requestType === "CONTINUATION" && !isEdit
        ? today < parsedStartDate
          ? parsedStartDate
          : today
        : parsedStartDate,
    endDate: parseDateFromISOStringWithoutFallback(serviceRequest?.endDate),
    units: serviceRequest?.units ? `${serviceRequest?.units}` : "0",
    approvedUnits: serviceRequest?.approvedUnits ? `${serviceRequest?.approvedUnits}` : "0",
    unitType: serviceRequest?.unitType,
    orderingProvider: serviceRequest?.orderingProvider || null,
    orderingProviderSelectedTin: serviceRequest?.orderingProviderSelectedTin || null,
    orderingProviderSelectedAddress: orderingProviderAddressValueOnContinuation || null,
    performingProvider: serviceRequest?.performingProvider || null,
    performingProviderSelectedTin: serviceRequest?.performingProviderSelectedTin || null,
    performingProviderSelectedAddress: performingProviderAddressValueOnContinuation || null,
    performingProviderParCheck: serviceRequest?.participateCheck?.performingProviderParCheck,
    possibleAttachmentNudgeReasons: serviceRequest?.possibleAttachmentNudgeReasons,
    facility: serviceRequest?.facility || null,
    facilitySelectedTin: serviceRequest?.facilitySelectedTin || null,
    facilitySelectedAddress: facilityAddressValueOnContinuation || null,
    facilityParCheck: serviceRequest?.participateCheck?.facilityParCheck,
    isExpedited: serviceRequest?.urgency?.isExpedited || false,
    expeditedReason: serviceRequest?.urgency?.reasonNote || "",
    serviceType: serviceRequest?.serviceType,
    patientStatus: serviceRequest?.patientStatus ?? undefined,
    patientStayDateRanges: serviceRequest?.patientStayDates
      ? aggregateStayDateByCommonFields(serviceRequest.patientStayDates)
      : [],
    admissionDate: parseDateFromISOStringWithoutFallback(serviceRequest?.admissionDate),
    admissionTime: admissionTimeArray?.length === 3 ? `${admissionTimeArray[0]}:${admissionTimeArray[1]}` : "",
    admissionSource: serviceRequest?.admissionSource,
    expectedAdmissionDate: parseDateFromISOStringWithoutFallback(serviceRequest?.expectedAdmissionDate),
    expectedDischargeDate: parseDateFromISOStringWithoutFallback(serviceRequest?.expectedDischargeDate),
    dischargeDate: parseDateFromISOStringWithoutFallback(dischargeDate),
    dischargeTime: dischargeTime,
    dischargedTo: dischargeTo,
    bHAdmissionType: serviceRequest?.behavioralHealthAdmissionType,
    bHReviewType: serviceRequest?.behavioralHealthReviewType,
    followUpFaxNumber: serviceRequest?.followUpFaxNumber,
    userSelectedOONException: serviceRequest?.userSelectedOONException,
    userSelectedNonPalCode: serviceRequest?.userSelectedNonPalCode,
    selectedOrderingProvider: serviceRequest?.selectedOrderingProvider || null,
    selectedPerformingProvider: serviceRequest?.selectedPerformingProvider || null,
    selectedFacility: serviceRequest?.selectedFacility || null,
    selectedPerformingProviderPractice: serviceRequest?.selectedPerformingProviderPractice || null,
    authSubcategory: serviceRequest?.authSubcategory,
    authCategory: serviceRequest?.authCategory ?? undefined,
    crdLogId: crdLogId || null,
    additionalCareParticipants: serviceRequest?.additionalCareParticipants || [],
    additionalInsurance: serviceRequest.additionalInsurance ?? undefined,
    nonPalReason,
    nonPalJustification,
  };
}

/**
 * Auto-fills select fields in a formContent object using a ServiceRequestResponse. It will only auto-fill fields that
 * are currently empty
 *
 * @param formContent               The existing form content (note this object is not mutated, the result is returned)
 * @param autoFillData              A service request to merge into the form content
 */
export function autoFillFormContent(
  formContent: ServiceRequestFormContent,
  autoFillData: ServiceRequestResponse
): ServiceRequestFormContent {
  const autoFillFormContent = formContentFromResponse(autoFillData);

  let placeOfServiceValue = formContent.placeOfService;
  if (
    (!formContent.placeOfService ||
      formContent.placeOfService?.id === formContent.clinicalService?.defaultPlaceOfService?.id) &&
    autoFillFormContent.placeOfService
  ) {
    // Auto-fill place of service if it is currently empty or set to the default (i.e. not set explicitly by user)
    placeOfServiceValue = autoFillFormContent.placeOfService;
  }

  return {
    ...formContent,
    placeOfService: placeOfServiceValue,
    endDate: formContent.endDate || (Number.parseInt(formContent.units) > 1 ? autoFillFormContent.endDate : undefined),
    orderingProvider: formContent.orderingProvider || autoFillFormContent?.orderingProvider,
    performingProvider: formContent.performingProvider || autoFillFormContent?.performingProvider,
    performingProviderSelectedTin:
      formContent.performingProviderSelectedTin || autoFillFormContent?.performingProviderSelectedTin,
    facility: formContent.facility || autoFillFormContent?.facility,
    facilitySelectedTin: formContent.facilitySelectedTin || autoFillFormContent?.facilitySelectedTin,
  };
}

export function safelyGetFormConfig(ipt: {
  [key: string]: { fieldSpec: FormConfigurationFieldSpec } | undefined;
}): FormConfiguration {
  return {
    singleClinicalService: safeFieldSpec(ipt.singleClinicalService),
    encounterType: safeFieldSpec(ipt.encounterType),
    placeOfService: safeFieldSpec(ipt.placeOfService),
    primaryDiagnosis: safeFieldSpec(ipt.primaryDiagnosis),
    secondaryDiagnoses: safeFieldSpec(ipt.secondaryDiagnoses),
    procedureCodes: safeFieldSpec(ipt.procedureCodes),
    recurring: safeFieldSpec(ipt.recurring),
    startEndDate: safeFieldSpec(ipt.startEndDate),
    units: safeFieldSpec(ipt.units),
    orderingProvider: safeFieldSpec(ipt.orderingProvider),
    performingProvider: safeFieldSpec(ipt.performingProvider),
    facility: safeFieldSpec(ipt.facility),
    urgency: safeFieldSpec(ipt.urgency),
    behavioralHealthReviewType: safeFieldSpec(ipt.behavioralHealthReviewType),
    behavioralHealthAdmissionType: safeFieldSpec(ipt.behavioralHealthAdmissionType),
    admissionDischargeDate: safeFieldSpec(ipt.admissionDischargeDate),
    faxInputField: safeFieldSpec(ipt.faxInputField),
    procedureCodeWithUnits: safeFieldSpec(ipt.procedureCodeWithUnits),
    prescribedDrug: safeFieldSpec(ipt.prescribedDrug),
    facilityAddress: safeFieldSpec(ipt.facilityAddress),
    facilityNPI: safeFieldSpec(ipt.facilityNPI),
    facilityTIN: safeFieldSpec(ipt.facilityTIN),
    performingProviderAddress: safeFieldSpec(ipt.performingProviderAddress),
    performingProviderNPI: safeFieldSpec(ipt.performingProviderNPI),
    performingProviderTIN: safeFieldSpec(ipt.performingProviderTIN),
    orderingProviderAddress: safeFieldSpec(ipt.orderingProviderAddress),
    orderingProviderNPI: safeFieldSpec(ipt.orderingProviderNPI),
    orderingProviderTIN: safeFieldSpec(ipt.orderingProviderTIN),
    outOfNetworkCheck: safeFieldSpec(ipt.outOfNetworkCheck),
    facilityOutOfNetworkStatusDisplay: safeFieldSpec(ipt.facilityOutOfNetworkStatusDisplay),
    performingProviderOutOfNetworkStatusDisplay: safeFieldSpec(ipt.performingProviderOutOfNetworkStatusDisplay),
    performingProviderPracticeOutOfNetworkStatusDisplay: safeFieldSpec(
      ipt.performingProviderPracticeOutOfNetworkStatusDisplay
    ),
    orderingProviderOutOfNetworkStatusDisplay: safeFieldSpec(ipt.orderingProviderOutOfNetworkStatusDisplay),
    careParticipantOutOfNetworkStatusDisplay: safeFieldSpec(ipt.careParticipantOutOfNetworkStatusDisplay),
    outOfNetworkOrderingProvider: safeFieldSpec(ipt.outOfNetworkOrderingProvider),
    facilityOutOfNetworkExceptionReason: safeFieldSpec(ipt.facilityOutOfNetworkExceptionReason),
    orderingProviderOutOfNetworkExceptionReason: safeFieldSpec(ipt.orderingProviderOutOfNetworkExceptionReason),
    performingProviderOutOfNetworkExceptionReason: safeFieldSpec(ipt.performingProviderOutOfNetworkExceptionReason),
    performingProviderPracticeOutOfNetworkExceptionReason: safeFieldSpec(
      ipt.performingProviderPracticeOutOfNetworkExceptionReason
    ),
    performingProviderPracticeOutOfNetworkExceptionComment: safeFieldSpec(
      ipt.performingProviderPracticeOutOfNetworkExceptionComment
    ),
    userDeclaredOONException: safeFieldSpec(ipt.userDeclaredOONException),
    patientStayDateRanges: safeFieldSpec(ipt.patientStayDateRanges),
    authCategory: safeFieldSpec(ipt.authCategory),
    authSubCategory: safeFieldSpec(ipt.subCategory),
    admissionDate: safeFieldSpec(ipt.admissionDate),
    admissionTime: safeFieldSpec(ipt.admissionTime),
    dischargeDate: safeFieldSpec(ipt.dischargeDate),
    dischargeTime: safeFieldSpec(ipt.dischargeTime),
    dischargedTo: safeFieldSpec(ipt.dischargedTo),
    expectedAdmissionDate: safeFieldSpec(ipt.expectedAdmissionDate),
    expectedDischargeDate: safeFieldSpec(ipt.expectedDischargeDate),
    facilityOutOfNetworkExceptionComment: safeFieldSpec(ipt.facilityOutOfNetworkExceptionComment),
    orderingProviderOutOfNetworkExceptionComment: safeFieldSpec(ipt.orderingProviderOutOfNetworkExceptionComment),
    performingProviderOutOfNetworkExceptionComment: safeFieldSpec(ipt.performingProviderOutOfNetworkExceptionComment),
    additionalCareParticipants: safeFieldSpec(ipt.additionalCareParticipants),
    nonPalCheckbox: safeFieldSpec(ipt.nonPalCheckbox),
    careParticipantOutOfNetworkExceptionReason: safeFieldSpec(ipt.careParticipantOutOfNetworkExceptionReason),
    careParticipantOutOfNetworkExceptionComment: safeFieldSpec(ipt.careParticipantOutOfNetworkExceptionComment),
    blockUserIfExceedsRecommendedEndDate: safeFieldSpec(ipt.blockUserIfExceedsRecommendedEndDate),
    blockUserIfLessThanRecommendedEndDate: safeFieldSpec(ipt.blockUserIfLessThanRecommendedEndDate),
    userSelectedOONException: safeFieldSpec(ipt.userSelectedOONException),
    expeditedTatUpdateTimestamp: safeFieldSpec(ipt.expeditedTatUpdateTimestamp),
    performingProviderPractice: safeFieldSpec(ipt.performingProviderPractice),
    performingProviderPracticeSelectedAddress: safeFieldSpec(ipt.performingProviderPracticeSelectedAddress),
    performingProviderPracticeSelectedTIN: safeFieldSpec(ipt.performingProviderPracticeSelectedTIN),
    performingProviderPracticeSelectedNPI: safeFieldSpec(ipt.performingProviderPracticeSelectedNPI),
    patientStatus: safeFieldSpec(ipt.patientStatus),
    admissionSource: safeFieldSpec(ipt.admissionSource),
    selectedDetails: safeFieldSpec(ipt.selectedDetails),
  };
}

const safeFieldSpec = (ipt: { fieldSpec: FormConfigurationFieldSpec } | undefined) => ipt ?? { fieldSpec: "OMIT" };

/**
 * Hook to determine whether a delegatedVendor exists
 * @param serviceRequest
 * @param specifiedVendors optional to specify the vendors to check for ex: ["HealthHelp", "Humana"]
 */
export function useIsDelegatedToVendor(
  serviceRequest: ServiceRequestResponse | null,
  specifiedVendors?: string[]
): boolean {
  const hhIntegrationEnabled = useIsHealthHelpEnabled();
  let specifiedVendorCheck = false;
  let externalVendorCheck = false;
  if (serviceRequest) {
    const { delegatedVendor } = serviceRequest;
    specifiedVendorCheck =
      specifiedVendors && specifiedVendors.length > 0 ? specifiedVendors.includes(delegatedVendor || "") : true;
    switch (delegatedVendor) {
      case "HealthHelp":
        externalVendorCheck = hhIntegrationEnabled;
        break;
      case "Cohere":
        // "Delegated" to Cohere is not externally delegated
        externalVendorCheck = false;
        break;
      default:
        if (delegatedVendor) {
          externalVendorCheck = true;
        }
    }
  }
  return externalVendorCheck && specifiedVendorCheck;
}

export function useIsHealthHelpEnabled(): boolean {
  return true;
}

export function sortRecordInAscendingOrder(record: Record<string, string>): Record<string, string> {
  const entries = Object.entries(record);
  entries.sort((a, b) => a[1].localeCompare(b[1]));
  const sortedRecord = Object.fromEntries(entries);
  return sortedRecord;
}

export function splitServiceRequests(serviceRequests: ServiceRequestResponse[], byCarePathName = false) {
  const grouped = byCarePathName
    ? groupBy(serviceRequests, (sr) => sr.carePathName || "")
    : groupBy(serviceRequests, (sr) => sr.carePathJourney?.id || "");
  const journeys: (CarePathJourney & { serviceRequests: ServiceRequestResponse[] })[] = Object.keys(grouped)
    .filter(Boolean) // Get the keys that are non-empty, meaning have a journey ID
    .map((key) => {
      const journey: CarePathJourney | undefined = grouped[key][0].carePathJourney;
      // To satisfy TS: otherwise it doesn't understand we're already certain
      // that there is a journey on this entry
      if (!journey) {
        throw new Error("Not possible");
      }

      return {
        ...journey,
        serviceRequests: sortBy(grouped[key], sortCriteria),
      };
    });
  return {
    singleServiceRequests: sortBy(
      grouped[""],
      (serviceRequest) => serviceRequest.startDate || serviceRequest.endDate || serviceRequest.id
    ),
    journeys: sortBy(journeys, (journey) => sortCriteria(journey.serviceRequests[0])),
  };
}
const sortCriteria = (serviceRequest: ServiceRequestResponse) =>
  serviceRequest.startDate || serviceRequest.endDate || serviceRequest.id;

export const capitalizeString = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const determineArticleForWord = (word: string) => {
  return ["a", "e", "i", "o", "u"].includes(word[0].toLowerCase()) ? "an" : "a";
};

interface DraftWorkflowStepPathInputParams {
  workflowStep: AuthBuilderWorkflowStep;
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse;
  inSmartOnFhirWorkflow?: boolean;
  faxId?: string;
  isFaxAuthBuilderWorkflow?: boolean;
}

interface DraftWorkflowStepPathOutputParams {
  pathname: string;
  search: string;
}

const draftSRSearch = (serviceRequestId: string | undefined): string => {
  return `?draftServiceRequestId=${serviceRequestId}`;
};

// navigate to the page corresponding with "workflowStep" saved on the service request
export const lastWorkflowStepPath = ({
  workflowStep,
  serviceRequest,
  inSmartOnFhirWorkflow,
  faxId,
  isFaxAuthBuilderWorkflow,
}: DraftWorkflowStepPathInputParams): DraftWorkflowStepPathOutputParams => {
  return {
    pathname: inSmartOnFhirWorkflow
      ? smartOnFhirAuthBuilderPath(serviceRequest?.patient?.id)
      : isFaxAuthBuilderWorkflow
      ? faxAuthBuilderPath(faxId || "", serviceRequest?.patient?.id)
      : authBuilderPath(serviceRequest?.patient?.id),
    search: draftSRSearch(serviceRequest?.id) + `&authBuilderStep=${workflowStep}`,
  };
};

// navigate to the page corresponding with "workflowStep" saved on the service request
export const continuationWorkflowPath = (patientId: string, serviceRequestId: string) => {
  return {
    pathname: authBuilderPath(patientId),
    search: `${draftSRSearch(serviceRequestId)}&authBuilderStep=FILL_FORMS_CONTINUATION`,
  };
};

export const sideBySideContinuationWorkflowPath = (faxId: string, patientId: string, serviceRequestId: string) => {
  return {
    pathname: faxAuthBuilderPath(faxId, patientId),
    search: `${draftSRSearch(serviceRequestId)}&authBuilderStep=FILL_FORMS_CONTINUATION`,
  };
};

// navigate to first page in authbuilder (gather requirements)
export const startNewAuthWorkflowPath = (patientId: string) => {
  return {
    pathname: authBuilderPath(patientId),
    search: "&authBuilderStep=GATHER_REQUIREMENTS_MEDICAL",
  };
};

export const dateProperlyFormatted = (startDate: Date | undefined) =>
  startDate && !!formatDateToISODate(startDate) && getYear(startDate) > 1900 && getYear(startDate) < 2200;

const HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS_WITHOUT_SORTING: Record<string, string> = {
  LIMITED_SPECIALIST: "The provider is a limited specialist in the field",
  NO_IN_NETWORK_TRANSPORTATION: "Patient doesn't have access to transportation to get to a network provider",
  LISTED_IN_NETWORK: "This provider should be listed as in-network for this patient",
  REASONABLE_TIME: "Patient cannot be seen by a network provider within a reasonable timeframe",
  HOSPITAL_SETTING_FOLLOW_UP:
    "Patient was seen by an out-of-network provider in a hospital setting and requires follow-up",
};

export const HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS = {
  ...sortRecordInAscendingOrder(HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS_WITHOUT_SORTING),
  OTHER: "Other (comment is required)",
};

const HUMANA_OON_FACILITY_EXCEPTION_REASONS_WITHOUT_SORTING: Record<string, string> = {
  NO_IN_NETWORK_TRANSPORTATION: "Patient does not have access to transportation to get to a network facility",
  NO_NETWORK_FACILITY:
    "No network facility exists within the mandated time or distance from the patient's official place of residence, or patient resides at this facility",
  PERFORMING_PROVIDER: "The performing provider only practices at this out-of-network facility",
  LISTED_IN_NETWORK: "This facility should be listed as in-network for this patient",
  REASONABLE_TIME:
    "Patient's network facilities cannot see the patient within a reasonable timeframe or will not accept the patient",
  UNSTABLE_PATIENT: "Patient is unstable for transfer",
};

export const HUMANA_OON_FACILITY_EXCEPTION_REASONS = {
  ...sortRecordInAscendingOrder(HUMANA_OON_FACILITY_EXCEPTION_REASONS_WITHOUT_SORTING),
  OTHER: "Other (comment is required)",
};

export interface OONExceptionReasonOption {
  id: string;
  label: string;
}

export const OonExceptionReasonOptions = (reasons: Record<string, string>) => {
  let options: OONExceptionReasonOption[] = [];
  for (const key in reasons) {
    options.push({ id: reasons[key], label: reasons[key] });
  }
  return options;
};

export const PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP: Record<string, string> = {
  "Number of visits": "Visit",
  Units: "Unit",
  Days: "Day",
  Hours: "Hour",
};

export const useServiceRequestWithdrawn = (
  serviceRequest: ServiceRequestResponse,
  setViewState?: (newState: SRFormViewState) => void,
  onEdit?: () => void,
  setAndOpenInformativeModal?: (informativeModalConfigState: InformativeModalConfigState) => void
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    healthPlanName: serviceRequest?.healthPlanName,
    authStatus: serviceRequest.authStatus,
  });

  const canEditWithdrawalRequestor = useAuthorized("EDIT_WITHDRAWAL_REQUESTOR");
  const [withdrawnReason, setWithdrawnReason] = useState<string | undefined>(serviceRequest?.withdrawnReason);
  const [withdrawRequestor, setWithdrawRequestor] = useState<WithdrawRequestor | undefined>(
    serviceRequest?.withdrawRequestor
  );
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const limitVoidsAfterApprovalFF = useFeature("LimitVoidsAfterApproval");
  const srNoLongerNeededButton = srEditConfig?.srNoLongerNeededButton && limitVoidsAfterApprovalFF;

  const { mutate: withdrawAuthDecisionGroup, loading: loadingWithdrawAuthDecisionGroup } = useWithdrawAuthDecisionGroup(
    {
      authDecisionGroupId: serviceRequest?.authDecisionGroup?.id || "",
    }
  );

  const {
    mutate: patch,
    loading: submitting,
    error: submitError,
  } = useUpdateServiceRequest({
    id: serviceRequest.id,
  });

  const trackUserActivityInteraction = useTrackUserInteraction();

  useEffect(() => {
    if (submitError) {
      if (submitError.status === 422) {
        setAndOpenInformativeModal?.("ISSUE_SAVING");
      } else {
        enqueueSnackbar(`Error updating service request: ${submitError.message}`, { variant: "error" });
      }
    }
  }, [enqueueSnackbar, setAndOpenInformativeModal, submitError]);

  const handleWithdrawButtonClick = () => {
    setWithdrawModalOpen(true);
  };

  const onWithdraw = canBeWithdrawn(serviceRequest)
    ? async (authStatus?: AuthStatus) => {
        if (serviceRequest.authDecisionGroup) {
          await withdrawAuthDecisionGroup({
            withdrawnReason,
            withdrawRequestor:
              serviceRequest?.withdrawRequestorOption && authStatus?.includes("WITHDRAWN")
                ? !canEditWithdrawalRequestor
                  ? "PROVIDER"
                  : withdrawRequestor
                : undefined,
          });
        } else {
          let payload: ServiceRequestUpdatePayload = {
            authStatus: authStatus ? authStatus : "WITHDRAWN",
            withdrawnReason,
            withdrawRequestor:
              serviceRequest?.withdrawRequestorOption && authStatus?.includes("WITHDRAWN")
                ? !canEditWithdrawalRequestor
                  ? "PROVIDER"
                  : withdrawRequestor
                : undefined,
          };
          if (srNoLongerNeededButton) {
            payload = {
              isSrNoLongerNeeded: true,
              withdrawnReason,
            };
            await trackUserActivityInteraction({
              stage: "NO_LONGER_NEEDED",
              event: "NO_LONGER_NEEDED_ATTESTATION",
              activityContext: {
                noLongerNeededReason: withdrawnReason,
              },
            });
          }
          await patch(payload);
        }
        setViewState?.("READ_ONLY");
        onEdit?.();
      }
    : undefined;

  return {
    withdrawModalOpen,
    setWithdrawModalOpen,
    withdrawRequestor,
    setWithdrawRequestor,
    withdrawnReason,
    setWithdrawnReason,
    onWithdraw,
    withdrawAuthDecisionGroup,
    loadingWithdrawAuthDecisionGroup,
    patch,
    submitting,
    submitError,
    handleWithdrawButtonClick,
  };
};

export const isFacilityBasedSR = (serviceRequest: ServiceRequestResponse): boolean => {
  return (
    !!serviceRequest &&
    serviceRequest.encounterType === "INPATIENT" &&
    !!serviceRequest.requestTiming &&
    ["CONCURRENT", "POST_SERVICE"].includes(serviceRequest.requestTiming)
  );
};

export const getNumberOfServiceDatesString = (
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse
) => {
  return serviceRequest.authStatus === "PARTIALLY_APPROVED" && serviceRequest.approvedUnits
    ? `${serviceRequest.units} requested, ${serviceRequest.approvedUnits} approved`
    : serviceRequest.units
    ? serviceRequest.units.toString()
    : "1";
};

export const getDatesOfServiceString = (
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse,
  authorization?: AuthorizationResponse,
  isFaxEdit?: boolean
): string | undefined => {
  let startDate = formatDateStr(serviceRequest.startDate);
  let endDate = formatDateStr(serviceRequest.endDate);

  if (!!isFaxEdit || !authorization) {
    return `${startDate} - ${endDate}`;
  }

  const [initialSR, continuationSR] = getInitialServiceRequest(authorization);
  if (!initialSR) {
    return;
  }
  startDate = formatDateStr(getStartAndEndDate(initialSR).startDate);
  const computeEndDate = () => {
    const endDateString: string | undefined = initialSR?.endDate;
    const endDateStrings: string[] = endDateString ? [endDateString] : [];

    if (continuationSR.length === 0) {
      endDate = formatDateStr(getStartAndEndDate(initialSR).endDate);
    } else if (continuationSR && continuationSR.length > 0 && serviceRequest?.workflowStep === "REVIEW") {
      const filterResult = continuationSR.filter(
        (sr) => sr.authStatus === "APPROVED" || sr.authStatus === "PARTIALLY_APPROVED" || sr.authStatus === "PENDING"
      );
      if (filterResult && filterResult.length >= 1) {
        for (const sr of filterResult) {
          if (sr.endDate) {
            endDateStrings.push(sr.endDate);
          }
        }
        endDate = formatDateStr(findMaxDate(endDateStrings));
      } else {
        endDate = formatDateStr(getStartAndEndDate(initialSR).endDate);
      }
    }
    return endDate;
  };

  return `${startDate} - ${computeEndDate()}`;
};

export const mapAuthServiceRequestsToDropdownOptions = (
  authServiceRequests: (ServiceRequestResponse | ServiceRequestSearchResponse)[],
  includeTrackingNumber = true
): DropdownOption[] => {
  return authServiceRequests.map((sr) => {
    const { id, cohereId, procedureCodes, authStatus } = sr;
    let label = includeTrackingNumber ? `#${cohereId} • ` : "";
    if (sr.encounterType === "INPATIENT") {
      const inpatientDays = sr.patientStayDates?.length || 0;
      label += `${inpatientDays} ${inpatientDays === 1 ? "day" : "days"} • `;
    } else {
      const numberOfServiceDates = `${getNumberOfServiceDatesString(sr)} ${
        (sr.authStatus !== "PARTIALLY_APPROVED" && "visits") || ""
      }`;
      label += `${numberOfServiceDates} • `;
    }
    const datesOfService = getDatesOfServiceString(sr);
    label += `${datesOfService}`;
    if (procedureCodes) {
      label += ` • ${procedureCodes?.map((pc) => (pc.code ? pc.code : "")).join(", ")}`;
    }
    if (authStatus) {
      label += ` • ${convertToProperCaseAndFormatAuthCategory(authStatus)}`;
    }
    return {
      id,
      label,
    };
  });
};

export const addFlexibleCodeIntakeFieldsToProcedureCodes = (
  procedureCodes: ProcedureCode[],
  clinicalService: ClinicalService | undefined
): ProcedureCode[] => {
  const flexibleCodeIntakeProcedureCodes: ProcedureCode[] = procedureCodes.map((px) => {
    if (!px.groupBy || !px.groupId) {
      return {
        ...px,
        groupId: clinicalService?.id || "",
        groupBy: clinicalService?.id ? "ClinicalService" : undefined,
      };
    }
    return px;
  });
  return flexibleCodeIntakeProcedureCodes;
};

type ServiceRequestDateField = keyof (ServiceRequestResponse | ServiceRequestSearchResponse) &
  ("dateCreated" | "lastUpdated");
typeAssert<
  IsNeverType<Exclude<ServiceRequestDateField, keyof (ServiceRequestResponse | ServiceRequestSearchResponse)>>
>();

export const sortServiceRequestByDateField = (
  dateField: ServiceRequestDateField,
  serviceRequests: (ServiceRequestResponse | ServiceRequestSearchResponse)[],
  desc: boolean = false
) => {
  const copyOfServiceRequests = [...serviceRequests];
  return desc
    ? copyOfServiceRequests.sort((a, b) => b[dateField].localeCompare(a[dateField]))
    : copyOfServiceRequests.sort((a, b) => a[dateField].localeCompare(b[dateField]));
};

export const useCanEditUnits = ({ authStatus }: { authStatus: AuthStatus }) => {
  const canEditInReview = useAuthorized("EDIT_SERVICE_REQUEST_IN_REVIEW_STAGE");

  const allowedAuthStatuses: AuthStatus[] = ["DRAFT", "INTAKE"];
  if (canEditInReview) {
    allowedAuthStatuses.push("PENDING", "PENDING_EXTERNAL_DETERMINATION");
  }

  return allowedAuthStatuses.includes(authStatus);
};

function formatInpatientServiceName(
  clinicalServices?: ServiceRequestResponse["clinicalServices"],
  authCategory?: AuthCategoryResponse,
  authSubcategory?: string
): string {
  let serviceName;
  const clinicalServiceNames = getClinicalServicesText(clinicalServices);
  const authCategoryName = authSubcategory || authCategory?.name || "";
  if (authCategoryName && clinicalServiceNames) {
    serviceName = `${authCategoryName}, ${clinicalServiceNames}`;
  } else {
    serviceName = authCategoryName || clinicalServiceNames || "";
  }
  return serviceName ? `${authCategoryName ? "Inpatient" : ""} ${serviceName}` : "";
}
export const formatServiceName = ({
  encounterType,
  clinicalServices,
  authCategory,
  authSubcategory,
  palCategory,
  prescribedDrug,
}: Partial<ServiceRequestResponse> | Partial<ServiceRequestSearchResponse>): string => {
  const serviceName =
    encounterType === "INPATIENT"
      ? formatInpatientServiceName(clinicalServices, authCategory, authSubcategory)
      : getClinicalServicesText(clinicalServices) || palCategory?.name || prescribedDrug?.drugDescription;
  return serviceName || "";
};

export function getHealthCareManager(isDelegatedToHealthHelp: boolean): ClinicalAssessmentProvider {
  if (isDelegatedToHealthHelp) {
    return "HealthHelp";
  } else {
    return "Cohere";
  }
}

export const addNonPalTracking = async (
  serviceRequest: ServiceRequestResponse,
  nonPalProcedureCodes: ProcedureCode[],
  patientId: string,
  trackUserActivityInteraction: (props: TrackUserActivityProps) => Promise<void>,
  workflowId?: string
) => {
  if (serviceRequest?.id && nonPalProcedureCodes?.length > 0) {
    //add tracking when the service request has an id and has non pal px
    const userActivityInteractionPayload: TrackUserActivityProps = {
      event: "NON_PAL_CODES_ADDED",
      stage: "AUTH_CREATION",
      activityContext: {
        patientId: patientId,
        serviceRequestId: serviceRequest?.id,
        workflowId: workflowId,
        procedureCodes: nonPalProcedureCodes,
      },
    };
    await trackUserActivityInteraction({ ...userActivityInteractionPayload });
  }
};

export const getAdditionalCareParticipantName = (careParticipantType: CareParticipantType | undefined) => {
  switch (careParticipantType) {
    case "DME_VENDOR": {
      return "DME vendor";
    }
    case "DRUG_SUPPLIER": {
      return "Drug supplier";
    }
    case "CLINIC": {
      return "Clinic";
    }
    case "PROVIDER_GROUP": {
      return "Provider group";
    }
    default: {
      return "";
    }
  }
};

export const getFieldSpecForAdditionalCareParticipant = (
  careParticipant: AdditionalCareParticipant,
  fieldName: string
) => {
  return careParticipant.fieldConfigurations?.find((fieldConfiguration) => fieldConfiguration?.fieldName === fieldName)
    ?.fieldSpecification;
};

export const isCareParticipantNameInvalid = (additionalCareParticipant: AdditionalCareParticipantFormContent) => {
  return (
    !additionalCareParticipant.isDisabled &&
    getFieldSpecForAdditionalCareParticipant(additionalCareParticipant, "name") === "REQUIRED" &&
    additionalCareParticipant.name === undefined
  );
};

export const isCareParticipantNpiInvalid = (additionalCareParticipant: AdditionalCareParticipantFormContent) => {
  return (
    !additionalCareParticipant.isDisabled &&
    getFieldSpecForAdditionalCareParticipant(additionalCareParticipant, "npi") === "REQUIRED" &&
    additionalCareParticipant.npi === undefined
  );
};

export const isCareParticipantTinInvalid = (additionalCareParticipant: AdditionalCareParticipantFormContent) => {
  return (
    getFieldSpecForAdditionalCareParticipant(additionalCareParticipant, "tin") === "REQUIRED" &&
    !additionalCareParticipant.isDisabled &&
    (additionalCareParticipant.tinList === undefined ||
      additionalCareParticipant.tinList?.length === 0 ||
      additionalCareParticipant.selectedTin === undefined) &&
    additionalCareParticipant.selectedLocation?.tin === undefined &&
    additionalCareParticipant.source !== "CMS"
  );
};

export const isCareParticipantAddressInvalid = (additionalCareParticipant: AdditionalCareParticipantFormContent) => {
  return (
    getFieldSpecForAdditionalCareParticipant(additionalCareParticipant, "address") === "REQUIRED" &&
    !additionalCareParticipant.isDisabled &&
    (additionalCareParticipant.tinList === undefined ||
      additionalCareParticipant.tinList?.length === 0 ||
      additionalCareParticipant.selectedTin === undefined) &&
    additionalCareParticipant.selectedLocation?.tin === undefined &&
    (additionalCareParticipant.addresses === undefined ||
      additionalCareParticipant.addresses?.length === 0 ||
      additionalCareParticipant.selectedAddress === undefined) &&
    additionalCareParticipant.selectedLocation?.address === undefined
  );
};

export const validateEachFieldOfCareParticipant = (
  additionalCareParticipants: AdditionalCareParticipantFormContent[]
) => {
  for (let i = 0; i < additionalCareParticipants.length; i++) {
    if (
      isCareParticipantNameInvalid(additionalCareParticipants[i]) ||
      isCareParticipantNpiInvalid(additionalCareParticipants[i]) ||
      isCareParticipantTinInvalid(additionalCareParticipants[i]) ||
      isCareParticipantAddressInvalid(additionalCareParticipants[i])
    ) {
      return false;
    }
  }
  return true;
};

//only validate additional care participants if they are enabled and greater than zero
export const validateCareParticipants = (
  additionalParticipantsEnabled: boolean | undefined,
  additionalCareParticipants: AdditionalCareParticipantFormContent[] | undefined
) => {
  if (!additionalParticipantsEnabled || !additionalCareParticipants || additionalCareParticipants.length === 0) {
    return true; //Additional care participants not enabled, no need to show error so it is marked as valid
  }
  return validateEachFieldOfCareParticipant(additionalCareParticipants);
};

//Evaluates if additional care participant(s) need to be added/enabled/disabled after SR edit
export const updateCareParticipants = (
  careParticipantsConfigs: AdditionalCareParticipantConfig[],
  formContent: ServiceRequestFormContent
): AdditionalCareParticipantFormContent[] => {
  const updatedCareParticipants: AdditionalCareParticipantFormContent[] = [];
  const { additionalCareParticipants } = formContent;
  for (let careParticipantsConfig of careParticipantsConfigs) {
    const dependsOnValuesInForm: Array<string> = [];
    const dependsOnKey = careParticipantsConfig.dependency?.dependsOnKey
      ? careParticipantsConfig.dependency?.dependsOnKey
      : "";

    //get the dependsOnKey values from the SR form
    if (dependsOnKey.length > 0) {
      if (dependsOnKey.includes("ProcedureCodes")) {
        formContent.procedureCodes.forEach((pxCode) => {
          dependsOnValuesInForm.push(pxCode.code);
        });
      } else if (dependsOnKey.includes("encounterType")) {
        dependsOnValuesInForm.push(formContent.isInpatient ? "INPATIENT" : "OUTPATIENT");
      }
    }

    //eval if an additionalCareParticipant change is needed after SR edit
    if (
      !careParticipantsConfig?.dependency ||
      dependsOnValuesInForm.some((it) => {
        return (
          careParticipantsConfig.dependency?.dependsOnValue &&
          careParticipantsConfig.dependency?.dependsOnValue.includes(it)
        );
      })
    ) {
      const foundCareParticipant =
        additionalCareParticipants &&
        additionalCareParticipants.find(
          (additionalCareParticipant) =>
            careParticipantsConfig.type === additionalCareParticipant.careParticipantType &&
            careParticipantsConfig.entityType === additionalCareParticipant.configuredEntityType
        );

      if (foundCareParticipant) {
        //There was a previous DISABLED additional care participant but after editing we need to ENABLE it
        updatedCareParticipants.push({ ...foundCareParticipant, isDisabled: false });
      } else {
        //No previous additional care participant but after editing we need to add one
        updatedCareParticipants.push(createNewAdditionalCareParticipant(careParticipantsConfig));
      }
    } else {
      const foundCareParticipant =
        additionalCareParticipants &&
        additionalCareParticipants.find(
          (additionalCareParticipant) =>
            careParticipantsConfig.type === additionalCareParticipant.careParticipantType &&
            careParticipantsConfig.entityType === additionalCareParticipant.configuredEntityType
        );
      if (foundCareParticipant) {
        //There was a previous ENABLED additional care participant but after editing we need to DISABLE it
        updatedCareParticipants.push({ ...foundCareParticipant, isDisabled: true });
      }
    }
  }
  return updatedCareParticipants;
};

const createNewAdditionalCareParticipant = (
  careParticipantsConfig: AdditionalCareParticipantConfig
): AdditionalCareParticipantFormContent => {
  return {
    ...initialAdditionalCareParticipant,
    careParticipantType: careParticipantsConfig.type,
    configuredEntityType: careParticipantsConfig.entityType,
    fieldConfigurations: careParticipantsConfig.fieldConfigurations,
    isDisabled: false,
  };
};

const initialAdditionalCareParticipant: AdditionalCareParticipantFormContent = {
  id: "",
  dateCreated: "",
  lastUpdated: "",
};

export const isInpatient = (serviceRequest: PartialServiceRequest): boolean => {
  const defaultEncounterTypes = serviceRequest?.clinicalService?.defaultPlaceOfService?.encounterTypes || [];
  if (serviceRequest) {
    if (serviceRequest?.encounterType === "INPATIENT") {
      return true;
    } else if (defaultEncounterTypes.length === 1 && defaultEncounterTypes[0] === "INPATIENT") {
      return true;
    } else {
      return serviceRequest?.placeOfService?.name?.includes("Inpatient") || false;
    }
  }
  return false;
};

//remove with the removal of the simplifiedServiceFrequency flag
export function isRecurring(serviceRequest: PartialServiceRequest): boolean {
  const defaultAuthType = serviceRequest?.clinicalService?.defaultAuthType;
  if (serviceRequest?.recurrenceType) {
    return serviceRequest.recurrenceType === "RECURRING";
  } else if (defaultAuthType) {
    return defaultAuthType === "RECURRING";
  }
  return false;
}

/*Transforms a ServiceRequestFormContent into a ServiceRequestResponse
  after the form was updated. It gets some values from the previous
  ServiceRequestResponse if needed*/
export function formContentToResponse(
  formContent: ServiceRequestFormContent,
  simplifiedServiceFrequency: boolean,
  oldSr?: ServiceRequestResponse,
  workflowId?: string
): ServiceRequestResponse {
  const stayDates = formContent?.patientStayDateRanges
    ? deAggregateStayDates(formContent.patientStayDateRanges)
    : undefined;
  const response: ServiceRequestResponse = {
    id: formContent.id ?? "",
    authStatus: oldSr?.authStatus,
    userSelectedOONException: formContent.userSelectedOONException,
    dateCreated: oldSr?.dateCreated ?? "",
    tatStartTimestamp: formatDateToISODate(formContent.tatStartTimestamp),
    decisionTimestamp: oldSr?.decisionTimestamp,
    attachments: oldSr?.attachments,
    facility: formContent.facility ?? undefined,
    healthPlanName: oldSr?.healthPlanName,
    userUpdatedTat: oldSr?.userUpdatedTat,
    nonPxClinicalServiceIds: formContent.nonPxClinicalServiceIds ?? [],
    primarySemanticDiagnosisCode: formContent.primaryDiagnosisCode ?? undefined,
    secondarySemanticDiagnosisCodes: formContent.secondaryDiagnosisCodes,
    primaryDiagnosis: formContent.primaryDiagnosisCode ?? undefined,
    secondaryDiagnoses: formContent.secondaryDiagnosisCodes,
    type: oldSr?.type ?? formContent.type,
    procedureCodes: formContent.procedureCodes,
    semanticProcedureCodes: formContent.procedureCodes,
    lobType: oldSr?.lobType,
    lastUpdated: oldSr?.lastUpdated ?? "",
    requestor: oldSr?.requestor,
    initialDecisionDisposition: oldSr?.initialDecisionDisposition,
    placeOfService: formContent.placeOfService ?? undefined,
    intakeTimestamp: oldSr?.intakeTimestamp,
    createdBy: oldSr?.createdBy,
    clinicalServices: oldSr?.clinicalServices,
    autoApprovalSnapshot: oldSr?.autoApprovalSnapshot,
    patient: oldSr?.patient,
    createdByName: oldSr?.createdByName,
    cohereId: formContent.cohereId,
    crdLogId: formContent?.crdLogId ?? "",
    palCategory: formContent.palCategory ?? undefined,
    isPal: formContent.isPal,
    startDate: formatDateToISODate(formContent.startDate),
    expeditedTatUpdateTimestamp:
      !!formContent.expeditedTatUpdateTimestamp && formContent.isExpedited
        ? formContent.expeditedTatUpdateTimestamp.toISOString()
        : "",

    encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
    facilityLocation: formContent.selectedFacility?.selectedLocation,
    authNumber: oldSr?.authNumber,
    authorization: oldSr?.authorization,
    requestType: oldSr?.requestType,
    facilitySelectedTin: formContent.facilitySelectedTin ?? undefined,
    performingProviderSelectedTin: formContent.performingProviderSelectedTin ?? "",
    performingProviderLocation: formContent.performingProviderSelectedAddress,
    performingProvider: formContent.performingProvider ?? undefined,
    orderingProvider: formContent.orderingProvider ?? undefined,
    orderingProviderSelectedTin: formContent.orderingProviderSelectedTin ?? "",
    orderingProviderLocation: formContent.orderingProviderSelectedAddress,
    delegatedVendor: oldSr?.delegatedVendor,
    createdByGroups: oldSr?.createdByGroups,
    additionalInsurance: formContent.additionalInsurance ?? oldSr?.additionalInsurance,
    approvedUnits: oldSr?.approvedUnits,
    formConfiguration: oldSr?.formConfiguration,
    requestTiming: oldSr?.requestTiming,
    turnAroundTimeExtensionEligible: oldSr?.turnAroundTimeExtensionEligible,
    turnAroundTimeDueDate: oldSr?.turnAroundTimeDueDate,
    urgency: {
      isExpedited: formContent.isExpedited,
      reasonNote: formContent.isExpedited ? formContent?.expeditedReason : "", // reason note should be erased when no longer expedited, setting to undefined won't update
    },
    participateCheck: {
      facilityParCheck: formContent.facilityParCheck,
      performingProviderParCheck: formContent.performingProviderParCheck,
    },
    recurrenceType: simplifiedServiceFrequency ? undefined : formContent.isRecurring ? "RECURRING" : "ONETIME",
    ...(formContent.isRecurring || simplifiedServiceFrequency
      ? {
          units: Number(formContent.units),
          unitType: formContent.unitType,
          endDate: formatDateToISODate(formContent.endDate),
        }
      : {
          units: undefined,
          unitType: undefined,
          endDate: "",
        }),
    carePathJourney: formContent.carePathJourney ?? undefined,
    clinicalService: formContent.clinicalService ?? undefined,
    patientStatus: formContent.patientStatus ?? undefined,
    patientStayDates: stayDates,
    ...(formContent.patientStatus !== "NOT_YET_ADMITTED"
      ? {
          admissionDate:
            formContent.admissionDate && formContent.isInpatient ? formatDateToISODate(formContent.admissionDate) : "",
          admissionTime: formContent.admissionTime && formContent.isInpatient ? formContent.admissionTime + ":00" : "",
          admissionSource: formContent.admissionSource ?? undefined,
        }
      : {}),
    expectedAdmissionDate:
      formContent.expectedAdmissionDate && formContent.isInpatient
        ? formatDateToISODate(formContent.expectedAdmissionDate)
        : "",
    expectedDischargeDate:
      formContent.expectedDischargeDate && formContent.isInpatient
        ? formatDateToISODate(formContent.expectedDischargeDate)
        : "",
    ...(formContent.patientStatus === "DISCHARGED"
      ? {
          dischargeDate: formContent.dischargeDate ? formatDateToISODate(formContent.dischargeDate) : "",
          dischargeTime: formContent.dischargeTime ? formContent.dischargeTime + ":00" : "",
          dischargedTo: formContent.dischargedTo ?? undefined,
        }
      : {}),
    behavioralHealthReviewType: formContent.bHReviewType,
    behavioralHealthAdmissionType: formContent.bHAdmissionType,
    serviceType: formContent.serviceType,
    followUpFaxNumber: formContent?.followUpFaxNumber,
    workflowId: workflowId,
    workflowStep: formContent.workflowStep,
    authCategory: formContent.authCategory ?? undefined,
    authSubcategory: formContent.authSubcategory,
    userSelectedNonPalCode: formContent.userSelectedNonPalCode,
    selectedOrderingProvider: formContent.selectedOrderingProvider ?? undefined,
    selectedPerformingProvider: formContent.selectedPerformingProvider ?? undefined,
    selectedFacility: formContent.selectedFacility ?? undefined,
    selectedPerformingProviderPractice: formContent?.selectedPerformingProviderPractice ?? undefined,
    additionalCareParticipants: formContent.additionalCareParticipants ?? [],
    authValidityWindow: oldSr?.authValidityWindow,
    isRestricted: oldSr?.isRestricted,
    hasRestrictedCodes: oldSr?.hasRestrictedCodes,
    integration: oldSr?.integration,
    identifiers: oldSr?.identifiers,
    authorizationNote: oldSr?.authorizationNote,
    internalFinalDeterminationLetterAttachments: oldSr?.internalFinalDeterminationLetterAttachments,
    internalFinalDeterminationFaxAttachments: oldSr?.internalFinalDeterminationFaxAttachments,
    associatedStateOfUnion: oldSr?.associatedStateOfUnion,
    externalReferenceIds: oldSr?.externalReferenceIds,
  };
  return response;
}

export const isDraftServiceRequest = (
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse
): boolean => {
  return serviceRequest.authStatus === "DRAFT";
};

export const isHumanaServiceRequest = (
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse
): boolean => {
  return serviceRequest.healthPlanName === HUMANA_HEALTH_PLAN_NAME;
};

export const isGeisingerServiceRequest = (
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse
): boolean => {
  return serviceRequest.healthPlanName === GEISINGER_NAME;
};

export const isDelegatedToHealthHelp = (
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse
): boolean => {
  return serviceRequest.delegatedVendor === HEALTH_HELP_NAME || serviceRequest.delegatedVendor === HEALTH_HELP_V2_NAME;
};
/* Evaluates and recalculates unique list of externalReferenceIds from a new serviceCase */
export const updateExternalReferenceIdsFromServiceCase = (
  originalExternalReferenceIds: string[] | undefined,
  serviceCase: ServiceCase | undefined | null
) => {
  const newExternalReferenceId =
    serviceCase && serviceCase.externalSources && serviceCase.externalSources.length > 0
      ? serviceCase.externalSources[0].externalReferenceId
      : undefined;
  const externalReferenceIds = originalExternalReferenceIds ? [...originalExternalReferenceIds] : [];
  const updatedExternalReferenceIds = newExternalReferenceId
    ? [...externalReferenceIds, newExternalReferenceId]
    : externalReferenceIds;

  return Array.from(new Set(updatedExternalReferenceIds));
};

export const isAnyCareParticipantOONExceptionRequired = (careParticipants: AdditionalCareParticipantFormContent[]) => {
  for (let careParticipant of careParticipants) {
    if (careParticipant.careParticipantOONExceptionRequired) {
      return true;
    }
    return false;
  }
  return false;
};

export const isAnyCareParticipantOutOfNetwork = (
  careParticipants: AdditionalCareParticipantFormContent[],
  isContinuation: boolean,
  careParticipantExceptionRequest?: boolean
) => {
  for (let careParticipant of careParticipants) {
    if (!isContinuation) {
      if (careParticipant.selectedLocation?.isOutOfNetwork) {
        return true;
      }
    } else {
      if (careParticipant.selectedLocation?.isOutOfNetwork) {
        return careParticipantExceptionRequest;
      }
    }
  }
  return false;
};

export const validateOONExceptionReasonFieldForCareParticipants = (
  careParticipants: AdditionalCareParticipantFormContent[],
  isContinuation: boolean,
  careParticipantExceptionRequest?: boolean
) => {
  if (
    isAnyCareParticipantOONExceptionRequired(careParticipants) &&
    isAnyCareParticipantOutOfNetwork(careParticipants, isContinuation)
  ) {
    for (let careParticipant of careParticipants) {
      if (!fieldIsValid({ fieldSpec: "REQUIRED" }, !!careParticipant.selectedLocation?.outOfNetworkExceptionReason)) {
        return false;
      }
      return true;
    }
  } else {
    return true;
  }
  return true;
};

export const careParticipantRequiresOONExceptionComment = (careParticipant: AdditionalCareParticipantFormContent) => {
  return (
    careParticipant.selectedLocation?.isOutOfNetwork &&
    careParticipant.selectedLocation.outOfNetworkExceptionReason === "Other (comment is required)"
  );
};

export const validateOONExceptionCommentFieldForCareParticipants = (
  careParticipants: AdditionalCareParticipantFormContent[]
) => {
  for (let careParticipant of careParticipants) {
    if (
      !(
        (careParticipantRequiresOONExceptionComment(careParticipant) &&
          !!careParticipant.selectedLocation?.outOfNetworkExceptionComment) ||
        !careParticipantRequiresOONExceptionComment(careParticipant)
      )
    ) {
      return false;
    }
  }
  return true;
};

export const validateExceptionReasonOnSRforCareParticipants = (
  careParticipants: AdditionalCareParticipantFormContent[],
  canSkipOon: boolean,
  careParticipantOonCheckData: GetOutofNetworkCheckResponse | undefined
) => {
  for (let careParticipant of careParticipants) {
    if (
      getFieldSpecForAdditionalCareParticipant(careParticipant, "outOfNetworkExceptionReason") === "REQUIRED" &&
      !!careParticipantOonCheckData?.isExceptionRequired &&
      !canSkipOon &&
      !careParticipant.selectedLocation?.outOfNetworkExceptionReason
    ) {
      return false;
    }
  }
  return true;
};

export const validateExceptionReasonforEachCareParticipantsOnContinuation = (
  careParticipants: AdditionalCareParticipantFormContent[]
) => {
  for (let careParticipant of careParticipants) {
    if (
      getFieldSpecForAdditionalCareParticipant(careParticipant, "outOfNetworkExceptionReason") === "REQUIRED" &&
      !careParticipant.selectedLocation?.outOfNetworkExceptionReason
    ) {
      return false;
    }
  }
  return true;
};

export const validatePatientStayDates = (
  facilityBasedFeatureEnabled: boolean,
  isInpatient: boolean,
  isPatientStayDatesValid: boolean,
  includePatientStayDatesOnPlannedAdmission: boolean | undefined,
  patientStatus: PatientStatus | undefined
) => {
  if (patientStatus === "NOT_YET_ADMITTED" && !includePatientStayDatesOnPlannedAdmission) {
    return true;
  }

  if (facilityBasedFeatureEnabled && isInpatient) {
    return fieldIsValid({ fieldSpec: "REQUIRED" }, isPatientStayDatesValid);
  }

  return true;
};

export const patientNotYetAdmittedForInpatient = (sr: ServiceRequestResponse) => {
  return sr.encounterType === "INPATIENT" && sr.patientStatus === "NOT_YET_ADMITTED";
};

export const patientAdmittedOrDischarged = (patientStatus?: PatientStatus) => {
  return patientStatus && ["CURRENTLY_ADMITTED", "DISCHARGED"].includes(patientStatus);
};

export const isPlannedInpatientRequest = (sr: ServiceRequestResponse) => {
  return sr.encounterType === "INPATIENT" && sr.requestTiming === "PRE_SERVICE";
};

export const isUnplannedInpatientRequest = (sr: ServiceRequestResponse) => {
  return (
    sr.encounterType === "INPATIENT" && !!sr.requestTiming && ["CONCURRENT", "POST_SERVICE"].includes(sr.requestTiming)
  );
};

export const isSrCreatedByUser = (
  userId: string | null | undefined,
  sr: ServiceRequestResponse | ServiceRequestSearchResponse | undefined,
  userName: string | null | undefined
) => {
  if ((userId || userName) && sr) {
    return userId === sr?.createdBy || userId === sr?.requestor?.user?.id || userName === sr?.createdByName;
  }
  return false;
};

export const isSrCreatedByUserOrg = (
  userOrganization: string | null | undefined,
  sr: ServiceRequestResponse | ServiceRequestSearchResponse | undefined
) => {
  if (userOrganization && sr) {
    return sr?.createdByGroups?.includes(userOrganization) || userOrganization === sr?.requestor?.user?.organization;
  }
  return false;
};

export const isOonProviderOrFacility = (selectedAddress?: Location | null) => {
  return selectedAddress?.isOutOfNetwork || selectedAddress?.networkType === "OON";
};

export const isUnknownNetworkType = (networkType?: NetworkType) => {
  return networkType === "Unknown";
};

export const getStayDateRangeString = (patientStayDateRange: PatientStayDateRange[]) => {
  const stayDateRangeLength = patientStayDateRange.length;

  const rangeStartDate =
    patientStayDateRange !== undefined && stayDateRangeLength > 0 && patientStayDateRange[0].rangeStartDate !== null
      ? patientStayDateRange[0].rangeStartDate
      : undefined;
  const rangeEndDate =
    patientStayDateRange !== undefined &&
    stayDateRangeLength > 0 &&
    patientStayDateRange[stayDateRangeLength - 1].rangeEndDate !== null
      ? patientStayDateRange[stayDateRangeLength - 1].rangeEndDate
      : undefined;
  const dates =
    rangeStartDate !== rangeEndDate
      ? `${formatDateStr(rangeStartDate ?? undefined)} - ${formatDateStr(rangeEndDate ?? undefined)}`
      : `${formatDateStr(rangeStartDate ?? undefined)}`;

  return dates;
};

export const isVoidedContinuationWithoutStayDates = (serviceRequest?: ServiceRequestResponse) => {
  if (!serviceRequest) {
    return false;
  }
  return (
    serviceRequest.encounterType === "INPATIENT" &&
    serviceRequest.requestType === "CONTINUATION" &&
    serviceRequest.authStatus === "VOIDED" &&
    (serviceRequest.patientStayDates?.length === 0 ||
      serviceRequest.patientStayDates?.every((stayDate) => stayDate.reviewStatus === "VOID"))
  );
};
