import React from "react";

const ProhibitedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM34 26H14V22H34V26Z"
        fill="#E53935"
      />
    </svg>
  );
};

export default ProhibitedIcon;
