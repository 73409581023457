import React, { Dispatch } from "react";
import InformativeModal from "./InformativeModal";
import ClipboardErrorIcon from "../images/ClipboardErrorIcon";
import { Grid } from "@material-ui/core";
interface Props {
  open: boolean;
  setOpen: Dispatch<boolean>;
  reassign: () => void;
  from: string;
  to: string;
  loading?: boolean;
  showOnHoldWarning?: boolean;
}

export default function ReassignModal({ open, setOpen, reassign, from, to, loading, showOnHoldWarning }: Props) {
  return (
    <InformativeModal
      headerText={
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
          <span>This case is currently being worked by</span>
          <span>{from}.</span>
          <span>Are you sure you want to reassign it?</span>
        </Grid>
      }
      subHeaderText={
        showOnHoldWarning
          ? "Proceeding will remove the hold and reassign this case to " + to + "."
          : "Proceeding will reassign this case to " + to + "."
      }
      icon={<ClipboardErrorIcon />}
      primaryButtonText="Proceed to reassign"
      primaryButtonAction={() => {
        reassign();
      }}
      primaryButtonLoading={loading}
      primaryButtonDisabled={loading}
      tertiaryButtonText={"Go back"}
      tertiaryButtonAction={() => setOpen(false)}
      showDivider
      open={open}
      onClose={() => setOpen(false)}
    />
  );
}
