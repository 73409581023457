import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { DEFAULT_FORM_CONFIG } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { useGetCareParticipantsConfigByHealthPlan } from "hooks/useGetFeatureConfigurations";
import { createContext, ReactNode, useCallback, useMemo, useState } from "react";

type FaxAuthorizationProps = {
  children: ReactNode;
  healthPlanName?: string;
  initialAttachedServiceRequestIds?: Array<string | undefined>;
  initialAttachedReferralRequestIds?: Array<string | undefined>;
};

type FaxAuthorizationContextType = {
  healthPlanName: string;
  selectedReferralRequestId: string;
  handleServiceRequestSelection?: (serviceRequest: ServiceRequestResponse) => void;
  handleReferralRequestSelection?: (id: string) => void;
  handleNewlyAttachedServiceRequest?: (id: string) => void;
  handleNewlyAttachedReferralRequestIds?: (id: string) => void;
  handleInitialAttachedServiceRequests?: (id: string) => void;
  setSelectedReferralRequestId?: (id: string) => void;
  isReferralRequestSelected?: (id: string) => boolean;
  isRRAttached?: (id: string) => boolean;
  isCurrentlyServiceRequestSelected?: (id: string) => boolean;
  isServiceRequestSelected?: () => boolean;
  handleUploadState?: (val: boolean) => void;
  isSRAttached?: (id: string) => boolean;
  fileUploadComplete?: boolean;
  canAttachReferral: boolean;
  setAuthContext: React.Dispatch<React.SetStateAction<FaxAuthorizationStateType>>;
  authContext: FaxAuthorizationStateType;
  initialAttachedServiceRequestIds?: Array<string | undefined>;
  initialAttachedReferralRequestIds?: Array<string | undefined>;
  handleAuthDetailsSelected?: (auth: AuthorizationResponse | undefined) => void;
  shouldShowAdditionalCareParticipants: (serviceRequest: ServiceRequestResponse) => boolean;
  clearAuthContextAttachmentsAndRequests: () => void;
};

const defaultFaxAuthorizationContextType: FaxAuthorizationContextType = {
  healthPlanName: "",
  selectedReferralRequestId: "",
  isCurrentlyServiceRequestSelected: () => false,
  isServiceRequestSelected: () => false,
  isSRAttached: () => false,
  canAttachReferral: false,
  setAuthContext: () => {},
  handleAuthDetailsSelected: () => {},
  authContext: {
    selectedServiceRequestIds: [],
    selectedReferralRequestId: "default?",
    initialAttachedServiceRequestIds: [],
    initialAttachedReferralRequestIds: [],
    selectedServiceRequests: [],
    newlyAttachedServiceRequestIds: [],
    newlyAttachedReferralRequestIds: [],
    fileUploadComplete: false,
    flyoutOpen: false,
    selectedAuth: undefined,
  },
  shouldShowAdditionalCareParticipants: () => false,
  clearAuthContextAttachmentsAndRequests: () => {},
};

type FaxAuthorizationStateType = {
  selectedServiceRequestIds: string[];
  selectedReferralRequestId: string;
  selectedServiceRequests: Array<ServiceRequestResponse> | [];
  newlyAttachedServiceRequestIds: string[];
  newlyAttachedReferralRequestIds: string[];
  initialAttachedServiceRequestIds: string[];
  initialAttachedReferralRequestIds: string[];
  fileUploadComplete: boolean;
  flyoutOpen?: boolean;
  selectedAuth?: AuthorizationResponse | undefined;
};

export const FaxAuthorizationContext = createContext<FaxAuthorizationContextType>(defaultFaxAuthorizationContextType);

const FaxAuthorizationProvider = ({
  children,
  healthPlanName = "",
  initialAttachedReferralRequestIds = [],
  initialAttachedServiceRequestIds = [],
}: FaxAuthorizationProps) => {
  const [selectedReferralRequestId, setSelectedReferralRequestId] = useState<string>("");

  const [authContext, setAuthContext] = useState<FaxAuthorizationStateType>({
    selectedServiceRequestIds: [],
    selectedReferralRequestId: "",
    selectedServiceRequests: [],
    newlyAttachedServiceRequestIds: [],
    newlyAttachedReferralRequestIds: [],
    initialAttachedServiceRequestIds: [],
    initialAttachedReferralRequestIds: [],
    fileUploadComplete: false,
    flyoutOpen: false,
    selectedAuth: undefined,
  });

  const careParticipantConfiguration = useGetCareParticipantsConfigByHealthPlan({ healthPlanName });

  const isReferralRequestSelected = useCallback(
    (id: string) => authContext.selectedReferralRequestId === id,
    [authContext.selectedReferralRequestId]
  );

  const isServiceRequestSelected = useCallback(
    () => authContext.selectedServiceRequestIds.length > 0,
    [authContext.selectedServiceRequestIds]
  );
  const isCurrentlyServiceRequestSelected = useCallback(
    (id: string) => authContext.selectedServiceRequestIds.includes(id),
    [authContext.selectedServiceRequestIds]
  );

  const isSRAttached = useCallback(
    (id: string) =>
      authContext.newlyAttachedServiceRequestIds.includes(id) || initialAttachedServiceRequestIds?.includes(id),
    [initialAttachedServiceRequestIds, authContext]
  );

  const isRRAttached = useCallback(
    (id: string) =>
      authContext.newlyAttachedReferralRequestIds?.includes(id) ||
      authContext.initialAttachedReferralRequestIds?.includes(id) ||
      false,
    [authContext]
  );

  const canAttachReferral = selectedReferralRequestId === "" && !authContext.selectedServiceRequestIds;

  const handleServiceRequestSelection = useCallback((serviceRequest: ServiceRequestResponse) => {
    setAuthContext((prevState) => {
      const currentSelectedServices = [...prevState.selectedServiceRequests];
      // Find the index of the object with the same serviceRequestId
      const index = currentSelectedServices.findIndex((req) => req.id === serviceRequest.id);

      if (index !== -1) {
        currentSelectedServices.splice(index, 1);
      } else {
        currentSelectedServices.push(serviceRequest);
      }
      return {
        ...prevState,
        selectedServiceRequests: currentSelectedServices,
        selectedServiceRequestIds: currentSelectedServices.map((obj) => obj.id),
      };
    });
  }, []);

  const handleInitialAttachedServiceRequests = useCallback((id: string) => {
    setAuthContext((prevState) => {
      const currentSelectedServices = [...prevState.initialAttachedServiceRequestIds];
      // Find the index of the object with the same serviceRequestId
      const index = currentSelectedServices.findIndex((req) => req === id);

      // We should only remove items from this list
      if (index !== -1) {
        currentSelectedServices.splice(index, 1);
      }

      return {
        ...prevState,
        initialAttachedServiceRequestIds: currentSelectedServices,
      };
    });
  }, []);

  const handleNewlyAttachedServiceRequest = useCallback((id: string) => {
    setAuthContext((prevState) => {
      const currentSelectedServices = [...prevState.newlyAttachedServiceRequestIds];
      // Find the index of the object with the same serviceRequestId
      const index = currentSelectedServices.findIndex((req) => req === id);

      if (index !== -1) {
        // do nothing
      } else {
        currentSelectedServices.push(id);
      }
      return {
        ...prevState,
        fileUploadComplete: true,
        newlyAttachedServiceRequestIds: currentSelectedServices,
      };
    });
  }, []);

  const handleNewlyAttachedReferralRequestIds = useCallback((id: string) => {
    setAuthContext((prevState) => {
      const currentSelectedServices = [...prevState.newlyAttachedReferralRequestIds];
      // Find the index of the object with the same serviceRequestId
      const index = currentSelectedServices.findIndex((req) => req === id);

      if (index !== -1) {
        // do nothing
      } else {
        currentSelectedServices.push(id);
      }
      return {
        ...prevState,
        fileUploadComplete: true,
        newlyAttachedReferralRequestIds: currentSelectedServices,
      };
    });
  }, []);

  const handleReferralRequestSelection = useCallback((id: string) => {
    setAuthContext((prevState) => ({
      ...prevState,
      selectedReferralRequestId: prevState.selectedReferralRequestId === id ? "" : id,
    }));
  }, []);

  const handleAuthDetailsSelected = useCallback((auth: AuthorizationResponse | undefined) => {
    setAuthContext((prevState) => ({
      ...prevState,
      flyoutOpen: !prevState.flyoutOpen,
      selectedAuth: auth,
    }));
  }, []);

  const handleUploadState = useCallback((val: boolean) => {
    setAuthContext((prevState) => ({
      ...prevState,
      fileUploadComplete: val,
    }));
  }, []);

  const shouldShowAdditionalCareParticipants = useCallback(
    (serviceRequest: ServiceRequestResponse) => {
      const formConfiguration = serviceRequest.formConfiguration || DEFAULT_FORM_CONFIG;
      return (
        Boolean(careParticipantConfiguration?.careParticipantEnabled) &&
        Boolean(formConfiguration?.additionalCareParticipants) &&
        Boolean(formConfiguration?.additionalCareParticipants?.fieldSpec !== "OMIT")
      );
    },
    [careParticipantConfiguration]
  );

  const clearAuthContextAttachmentsAndRequests = useCallback(() => {
    setAuthContext((prev) => {
      return {
        ...prev,
        selectedAuth: undefined,
        selectedServiceRequestIds: [],
        selectedServiceRequests: [],
        selectedReferralRequestId: "",
        newlyAttachedReferralRequestIds: [],
        newlyAttachedServiceRequestIds: [],
        initialAttachedReferralRequestIds: [],
        initialAttachedServiceRequestIds: [],
      };
    });
  }, [setAuthContext]);

  const value = useMemo(() => {
    return {
      healthPlanName,
      initialAttachedServiceRequestIds,
      initialAttachedReferralRequestIds,
      handleServiceRequestSelection,
      handleReferralRequestSelection,
      isReferralRequestSelected,
      isServiceRequestSelected,
      isCurrentlyServiceRequestSelected,
      handleNewlyAttachedServiceRequest,
      handleNewlyAttachedReferralRequestIds,
      handleInitialAttachedServiceRequests,
      setSelectedReferralRequestId,
      isRRAttached,
      selectedReferralRequestId,
      canAttachReferral,
      authContext,
      setAuthContext,
      handleUploadState,
      handleAuthDetailsSelected,
      isSRAttached,
      shouldShowAdditionalCareParticipants,
      clearAuthContextAttachmentsAndRequests,
    };
  }, [
    healthPlanName,
    initialAttachedServiceRequestIds,
    initialAttachedReferralRequestIds,
    handleServiceRequestSelection,
    handleReferralRequestSelection,
    isReferralRequestSelected,
    isServiceRequestSelected,
    isCurrentlyServiceRequestSelected,
    handleNewlyAttachedServiceRequest,
    handleNewlyAttachedReferralRequestIds,
    handleInitialAttachedServiceRequests,
    isRRAttached,
    selectedReferralRequestId,
    canAttachReferral,
    authContext,
    handleUploadState,
    handleAuthDetailsSelected,
    isSRAttached,
    shouldShowAdditionalCareParticipants,
    clearAuthContextAttachmentsAndRequests,
  ]);

  return <FaxAuthorizationContext.Provider value={value}>{children}</FaxAuthorizationContext.Provider>;
};
export default FaxAuthorizationProvider;
