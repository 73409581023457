import React, { useCallback, useMemo } from "react";
import { HelmetProvider } from "react-helmet-async";
import { RestfulReactProviderProps } from "restful-react";
import { CommonProvider, FeatureConfigurationProvider, FeatureFlagsProvider } from "@coherehealth/common";
import config from "./api/config";
import { User, UserProvider } from "./UserContext";
import { AutocompleteChangeSubscriberContext, AutocompleteOnChangeFn } from "@coherehealth/common";
import { trackUserActivity } from "util/userActivityTracker";
import { error as logError, warn as logWarning, scrubUrl } from "./logger";
import MonitoringRestfulProvider from "./MonitoringRestfulProvider";
import { DesignSystemProvider } from "@coherehealth/design-system";
import HealthPlanDisplayNameContextProvider from "./util/context/HealthPlanDisplayNameContext";
import PatientContextProvider from "util/context/PatientContext";

import { ClinicalReviewManagerWrapper } from "./components/ClinicalReview/next/ClinicalReviewManager";

const onError: NonNullable<RestfulReactProviderProps["onError"]> = (error, retry, response) => {
  if (response && error) {
    // The fetch successfully executed, but the server returned us a failure
    const { message, data, status } = error;
    const { url } = response;

    // The reason for splitting these into multiple lines is that it causes
    // different stack traces, and different stack traces cause different errors
    // in sentry. This is sort of a sentry hack.
    if (status === 403) {
      // 403 should never happen for logged-in users: this suggests a bug
      logError(new Error(`HTTP status ${status} from ${scrubUrl(url)}: [${message}] [${JSON.stringify(data)}]`));
    } else if (status === 404) {
      // 404 _may_ happen but may indicate an application issue
      logWarning(`HTTP status ${status} from ${scrubUrl(url)}: [${message}] [${JSON.stringify(data)}]`);
    } else if (status === 500) {
      logError(new Error(`HTTP status ${status} from ${scrubUrl(url)}: [${message}] [${JSON.stringify(data)}]`));
    } else {
      // All other errors can group together
      logError(new Error(`HTTP status ${status} from ${scrubUrl(url)}: [${message}] [${JSON.stringify(data)}]`));
    }
  } else {
    // The fetch call itself failed: this will happen if the network is unavailable
    // or if the URL fails to resolve, etc.
    // Generally, these _wouldn't_ be coding errors, but instead
    // network unavailability or connection reset, etc
    logWarning(`error executing fetch: [${JSON.stringify(error)}]`);
  }
};

/**
 * These providers are used in the real app,
 * and also in individual component stories and tests.
 */
function AppProviders({
  children,
  getAccessToken,
  getOrg,
  getUser,
  skipFetchFeatureFlags,
  theme = "light",
}: {
  children: React.ReactNode;
  getAccessToken: () => Promise<string | undefined>;
  getOrg?: () => Promise<string | undefined>;
  getUser: () => Promise<User> | undefined;
  skipFetchFeatureFlags?: boolean;
  theme?: "light" | "dark";
}) {
  const userIdPromise = useMemo(async () => {
    const user = await getUser();
    return user?.sub;
  }, [getUser]);

  const fetchSimpleFlags = useCallback(async () => {
    if (!skipFetchFeatureFlags) {
      const accessToken = await getAccessToken();
      const org = getOrg ? await getOrg() : "";
      if (accessToken) {
        return fetch(`${config.SERVICE_API_URL}simpleFeatureFlag`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Org: `${org}`,
          },
        });
      }
    }
  }, [getAccessToken, getOrg, skipFetchFeatureFlags]);

  const requestOptions = useCallback(async () => {
    return { headers: { Authorization: `Bearer ${await getAccessToken()}`, Org: `${getOrg ? await getOrg() : ""}` } };
  }, [getAccessToken, getOrg]);

  const getUserValue = useMemo(() => ({ getUser }), [getUser]);
  const onChangeValueValue = useMemo(() => ({ onChange: autocompleteOnChange }), []);
  const logger = useMemo(() => ({ error: logError, warn: logWarning }), []);
  const userPromise = skipFetchFeatureFlags ? undefined : userIdPromise;

  return (
    <AutocompleteChangeSubscriberContext.Provider value={onChangeValueValue}>
      <MonitoringRestfulProvider requestOptions={requestOptions} onError={onError} base={config.SERVICE_API_URL}>
        <UserProvider value={getUserValue}>
          <FeatureConfigurationProvider getAccessToken={getAccessToken} logger={logger}>
            <FeatureFlagsProvider fetchSimpleFlags={fetchSimpleFlags} userIdPromise={userPromise}>
              <PatientContextProvider>
                <HealthPlanDisplayNameContextProvider>
                  <HelmetProvider>
                    <DesignSystemProvider theme={theme}>
                      <CommonProvider theme={theme}>
                        <ClinicalReviewManagerWrapper>{children}</ClinicalReviewManagerWrapper>
                      </CommonProvider>
                    </DesignSystemProvider>
                  </HelmetProvider>
                </HealthPlanDisplayNameContextProvider>
              </PatientContextProvider>
            </FeatureFlagsProvider>
          </FeatureConfigurationProvider>
        </UserProvider>
      </MonitoringRestfulProvider>
    </AutocompleteChangeSubscriberContext.Provider>
  );
}

const autocompleteOnChange: AutocompleteOnChangeFn = ({ fieldName, selectedValue }) => {
  // For now, don't send selectedValue in case
  // there is a PHI concern
  trackUserActivity(`select change - ${fieldName}`);
};

export default AppProviders;
