import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { H1, colorsLight, H2, SecondaryButton, Body1 } from "@coherehealth/common";
import { Organization, useGetOrganizationByTinEmailName } from "@coherehealth/core-platform-api";
import { isEmailValid, OnboardingSteps } from "./shared";
import OrganizationResultCard from "./OrganizationResultCard";
import EmptyOrganizationResultCard from "./EmptyOrganizationResultCard";
import TaxIdChipInput from "../ProviderOrganization/TaxIdChipInput";
import { CircularProgress } from "@material-ui/core";
import { isTinValid } from "util/providerUtils";
import CohereLogo from "../images/CohereLogo.svg";

const useStyles = makeStyles((theme) => ({
  containerPadding: {
    paddingBottom: theme.spacing(10),
  },
  drawer: {
    width: "560px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 40px",
  },
  logo: {
    padding: "40px",
    width: "30%",
    alignSelf: "center",
  },
  headerImageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(6.25),
  },
  textEntry: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  primaryButton: {
    margin: "12px 0px",
  },
  headerContainer: {
    textAlign: "center",
  },
  pageHeader: {
    paddingBottom: theme.spacing(1),
    width: "1024px",
    height: "35px",
  },

  cardHeader: {
    paddingBottom: theme.spacing(1),
    color: colorsLight.font.light,
  },
  wrappedCardHeader: {
    paddingBottom: theme.spacing(1),
    width: "50%",
    color: colorsLight.font.light,
  },
  wrappedHeader: {
    paddingBottom: theme.spacing(1),
    color: colorsLight.font.light,
    paddingTop: theme.spacing(0.5),
  },
  wrappedHeaderContainer: {
    paddingBottom: theme.spacing(0.5),
  },
  subHeader: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  emailAndTin: {
    color: theme.palette.text.primary,
  },
  cardContainer: {
    padding: theme.spacing(2),
  },
  cardNewContainer: {
    paddingTop: theme.spacing(3.75),
    display: "flex",
    justifyContent: "space-between",
  },
  orgCardContainer: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3.75),
  },
  createOrganizationButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  bodyContainer: {
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
  },
  cohereLogo: {
    height: "64px",
  },
  orgCards: {
    width: "100%",
    padding: theme.spacing(0, 0, 2),
  },
}));

interface Props {
  organizationTin: string;
  organizationName: string;
  userEmail: string;
  setCurrentStep: Dispatch<SetStateAction<OnboardingSteps>>;
  setSelectedProviderOrgId: Dispatch<SetStateAction<string>>;
  setSelectedProviderOrgName: Dispatch<SetStateAction<string>>;
  setSelectedProviderOrg: Dispatch<SetStateAction<Organization>>;
}

export default function SelectionPage({
  organizationTin,
  organizationName,
  userEmail,
  setCurrentStep,
  setSelectedProviderOrgId,
  setSelectedProviderOrgName,
  setSelectedProviderOrg,
}: Props) {
  const classes = useStyles();

  const [tinsToSearch, setTinsToSearch] = useState<string[]>([]);
  // Always initialize the search with the given organizationTin
  // Theoretically that prop value shouldn't change, but in case if it does (or react does a weird optimization), keep
  // it in a useEffect so that they stay in-sync
  useEffect(() => {
    if (isTinValid(organizationTin)) {
      setTinsToSearch([organizationTin]);
    }
  }, [organizationTin]);

  // The useGetOrganizationByTinEmailName should really be an HTTP GET method, but it's a POST
  // I'm going to treat it like a useGet however, because it helps keep the rest hook isolated here, rather than having
  // to hoist it up to the parent component. And also I think the mechanics are a little nicer, even if it does add a
  // useEffect.
  const [matchingOrgs, setMatchingOrgs] = useState<Organization[]>([]);
  const { mutate: orgSearch, loading: orgSearchLoading } = useGetOrganizationByTinEmailName({
    onMutate: (_, data) => {
      setMatchingOrgs(data?.matchingOrgs || []);
    },
  });

  // Re-do the search if a parameter changes
  useEffect(() => {
    if (isEmailValid(userEmail) && tinsToSearch.length > 0) {
      orgSearch({
        email: userEmail,
        organizationName,
        tinList: tinsToSearch,
      });
    } else {
      setMatchingOrgs([]);
    }
  }, [userEmail, organizationName, tinsToSearch, orgSearch]);

  return (
    <div className={classes.containerPadding}>
      <div>
        <div className={classes.headerImageContainer}>
          <img className={classes.cohereLogo} src={CohereLogo} alt="" />
        </div>
        <div className={classes.bodyContainer}>
          <PageHeadingHeader>
            <H1 className={classes.pageHeader}>Join an existing organization</H1>
          </PageHeadingHeader>
          <TinContainer>
            <TaxIdChipInput tinList={tinsToSearch} setTinList={setTinsToSearch} label={"TIN"} withIcon />
          </TinContainer>
          <div className={classes.orgCardContainer}>
            {orgSearchLoading ? (
              <CircularProgress size={40} />
            ) : matchingOrgs.length === 0 ? (
              <>
                <Body1 className={classes.cardHeader}>{matchingOrgs.length} results</Body1>
                <EmptyOrganizationResultCard />
              </>
            ) : (
              <div>
                <Body1 className={classes.cardHeader}>{matchingOrgs.length} results</Body1>

                {matchingOrgs.map((org) => (
                  <div className={classes.orgCards} key={org.id}>
                    <OrganizationResultCard
                      organization={org}
                      setCurrentStep={setCurrentStep}
                      setSelectedProviderOrgId={setSelectedProviderOrgId}
                      setSelectedProviderOrgName={setSelectedProviderOrgName}
                      setSelectedProviderOrg={setSelectedProviderOrg}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <CardNewContainer>
            <div className={classes.cardNewContainer}>
              <div>
                <H2 className={classes.wrappedHeaderContainer}>Don't see your organization listed?</H2>
                <Body1 className={classes.wrappedHeader}>
                  If you are an account administrator, you can create a new organization
                </Body1>
              </div>

              <SecondaryButton
                className={classes.createOrganizationButton}
                onClick={() => {
                  setSelectedProviderOrgId("");
                  setSelectedProviderOrgName("");
                  setCurrentStep("SCOPE_QUESTIONS");
                }}
                data-tracking-id="create-a-new-org-start"
              >
                Create new organization
              </SecondaryButton>
            </div>
          </CardNewContainer>
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CardNewContainer = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const PageHeadingHeader = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(2.5),
  paddingTop: theme.spacing(6.25),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const TinContainer = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));
