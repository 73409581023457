import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal, H2, colorsLight, Subtitle2, PrimaryButton, TertiaryButton, RadioGroup } from "@coherehealth/common";
import { useGetOrganizationById, useUpdateOrganization } from "@coherehealth/core-platform-api";
import { Divider, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import EcoFriendlyIcon from "common/EcoFriendlyIcon";

interface Props {
  orgId: string;
  open: boolean;
  onClose: () => void;
  selectedValue: string | undefined;
  setSelectedValue: Dispatch<SetStateAction<string | undefined>>;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: theme.spacing(5.5, 3, 7, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    padding: theme.spacing(0, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",
  },
  image: {
    height: theme.spacing(13),
    width: theme.spacing(13),
    paddingBottom: theme.spacing(4),
  },
  headerText: {
    paddingBottom: theme.spacing(2),
    textAlign: "center",
  },
  subtitleText: {
    color: colorsLight.font.light,
    textAlign: "center",
    paddingBottom: theme.spacing(3),
    alignSelf: "stretch",
  },
  divider: {
    color: colorsLight.gray.divider,
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  primaryButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2, 7, 1.5, 7),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  radioButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: theme.spacing(4),
  },
  tertiaryButton: {
    padding: 0,
    color: colorsLight.brand.blue,
  },
}));

export default function NewPaperlessSettingsModal({ orgId, open, onClose, selectedValue, setSelectedValue }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { data: organization } = useGetOrganizationById({ id: orgId });

  const { mutate: patchOrg, error: patchOrgError } = useUpdateOrganization({ id: orgId });

  const [optedIn, setOptedIn] = useState<boolean>();

  useEffect(() => {
    if (patchOrgError) {
      enqueueSnackbar(`Error saving organization information: ${patchOrgError.message}`, { variant: "error" });
    }
    if (organization) {
      setOptedIn(organization.electronicLettersOptedIn);
    }
  }, [enqueueSnackbar, patchOrgError, organization]);

  const classes = useStyles({ optedIn });

  const handleClose = () => {
    setSelectedValue(optedIn ? "ON" : "OFF");
    onClose();
  };

  const handleCancel = () => {
    setSelectedValue(optedIn ? "ON" : "OFF");
    onClose();
  };

  return (
    <Modal onClose={handleClose} open={open} contentClassName={classes.modal}>
      <div className={classes.container}>
        <EcoFriendlyIcon className={classes.image} />
        <H2 className={classes.headerText}>Paperless notifications</H2>

        <Subtitle2 className={classes.subtitleText}>
          By turning ON paperless notifications, you agree to receive all communications electronically. Denial letters
          will still be mailed to you. You can access all authorization information, including status updates, online at
          any time.
        </Subtitle2>
        <Subtitle2 className={classes.subtitleText}>
          By choosing paperless notifications, you are responsible for regularly checking back online for authorization
          updates.
        </Subtitle2>
        <div className={classes.radioButton}>
          <RadioGroup
            options={[
              { id: "ON", label: "On" },
              { id: "OFF", label: "Off" },
            ]}
            value={selectedValue}
            onChange={(value) => {
              setSelectedValue(value);
            }}
            row
          />
        </div>
        <Divider className={classes.divider} />
        <PrimaryButton
          className={classes.primaryButton}
          onClick={async () => {
            await patchOrg({ electronicLettersOptedIn: selectedValue === "ON" });
            setOptedIn(selectedValue === "ON");
            onClose();
          }}
        >
          Save
        </PrimaryButton>
        <TertiaryButton className={classes.tertiaryButton} onClick={handleCancel}>
          Cancel
        </TertiaryButton>
      </div>
    </Modal>
  );
}
