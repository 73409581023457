import { Body1, H2, InformativeModal } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import React from "react";
import ProhibitedIcon from "../../images/ProhibitedIcon";
import routes from "../../../routes";

interface Props {
  referralRequestIds: string[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

const useStyles = makeStyles((theme) => {
  return {
    primaryButton: {
      padding: theme.spacing(1.5, 3),
      marginTop: theme.spacing(2),
    },
    content: {
      minWidth: 600,
      padding: theme.spacing(5, 7),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      h2: {
        paddingTop: theme.spacing(1),
      },
    },
  };
});

export default function DuplicateReferralRequestRedirectModal({ referralRequestIds, open, setOpen }: Props) {
  const classes = useStyles();

  return (
    <InformativeModal
      hideX={true}
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") {
          return;
        }
      }}
      icon={<ProhibitedIcon />}
      iconSize="48px"
      headerText={
        <div>
          <H2>This request duplicates an existing one:</H2>
          <H2>{referralRequestIds.join(", ")}</H2>
        </div>
      }
      subHeaderElement={
        <div style={{ textAlign: "left" }}>
          <Body1 style={{ textAlign: "left" }}>Duplicate submissions are not accepted by this health plan.</Body1>
          <br />
          <Body1>
            The performing provider and specialty entered match an existing referral with overlapping dates.
          </Body1>
          <br />
          <Body1>
            You can cancel this referral request, change details to avoid duplication, or call Cohere for assistance at
            (833) 283-0033.
          </Body1>
        </div>
      }
      open={open}
      leftAlignText={true}
      primaryButtonText={"Exit request"}
      primaryButtonDisabled={false}
      primaryButtonRoute={routes.REFERRAL_DASHBOARD}
      customButtonStyle={classes.primaryButton}
      tertiaryButtonAction={() => setOpen(false)}
      tertiaryButtonDisabled={false}
      tertiaryButtonText={"Go back and make changes"}
      customContentStyle={classes.content}
    ></InformativeModal>
  );
}
