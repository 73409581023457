import { ReviewType, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { useReviewCorrection } from "./hooks/useReviewCorrection";
import { useReviewCorrectionActions } from "./hooks/useReviewCorrectionActions";
import { ReviewConfirmationModal } from "./ReviewConfirmationModal";
import { ReviewSelectionModal } from "./ReviewSelectionModal";
import { ReviewCorrectionModal } from "./ReviewCorrectionModal";
import { ReviewOutcomeModal } from "./ReviewOutcomeModal";
import { AddReviewCorrectionButton } from "./AddReviewCorrectionButton";
import { useFeature } from "@coherehealth/common";

type ReviewCorrectionProps = {
  completedReviews: ReviewType[];
  serviceRequest: ServiceRequestResponse;
};
export const ReviewCorrection = ({ completedReviews, serviceRequest }: ReviewCorrectionProps) => {
  const {
    isReviewCorrectionsButtonDisabled,
    getPendingReasonOptions,
    reviewCorrectionsResponse,
    getSubmissionModalStatus,
    getFailedSubmissionMessage,
    modalManager,
    setModalManager,
    reviewCorrectionPayload,
    setReviewCorrectionPayload,
    resetReviewCorrectionFields,
    setReviewCorrectionsResponse,
    sendLetters,
    updateAuthStatus,
    refetchPendingReasons,
    nextReviewDate,
    setNextReviewDate,
    nextReviewDateAcknowledged,
    setNextReviewDateAcknowledged,
  } = useReviewCorrection({
    completedReviews,
    serviceRequest,
  });
  const enableReviewCorrectionsFF = useFeature("enableReviewCorrections");
  const { canCorrectAuthStatus, canEditReviewSoftDelete, getCorrectionActionOptions } = useReviewCorrectionActions();

  return (canCorrectAuthStatus || canEditReviewSoftDelete) &&
    enableReviewCorrectionsFF &&
    completedReviews &&
    completedReviews?.length > 0 ? (
    <>
      <AddReviewCorrectionButton
        completedReviews={completedReviews}
        setModalManager={setModalManager}
        setReviewCorrectionPayload={setReviewCorrectionPayload}
      />
      <ReviewSelectionModal
        open={modalManager.openReviewSelectionModal}
        value={reviewCorrectionPayload.reviewId || ""}
        onChange={(reviewId: string) => setReviewCorrectionPayload((prev) => ({ ...prev, reviewId }))}
        completedReviews={completedReviews}
        primaryButtonDisabled={!reviewCorrectionPayload.reviewId}
        setModalManager={setModalManager}
        tertiaryButtonAction={resetReviewCorrectionFields}
      />
      <ReviewCorrectionModal
        open={modalManager.openReviewCorrectionModal}
        onClose={resetReviewCorrectionFields}
        primaryButtonDisabled={isReviewCorrectionsButtonDisabled()}
        correctionActionOptions={getCorrectionActionOptions()}
        pendingReasonOptions={getPendingReasonOptions()}
        refetchPendingReasons={refetchPendingReasons}
        reviewCorrectionPayload={reviewCorrectionPayload}
        setModalManager={setModalManager}
        setReviewCorrectionPayload={setReviewCorrectionPayload}
        serviceRequest={serviceRequest}
        nextReviewDate={nextReviewDate}
        setNextReviewDate={setNextReviewDate}
        nextReviewDateAckknowledged={nextReviewDateAcknowledged}
        setNextReviewDateAcknowledged={setNextReviewDateAcknowledged}
      />
      <ReviewConfirmationModal
        open={modalManager.openConfirmationModal}
        updateAuthStatus={updateAuthStatus}
        sendLetters={sendLetters}
        setReviewCorrectionsResponse={setReviewCorrectionsResponse}
        setModalManager={setModalManager}
        reviewCorrectionPayload={reviewCorrectionPayload}
        onClose={resetReviewCorrectionFields}
        cohereId={serviceRequest.cohereId || ""}
        nextReviewDate={nextReviewDate}
        nextReviewDateAcknowledged={nextReviewDateAcknowledged}
      />
      <ReviewOutcomeModal
        open={modalManager.openUpdateOutcomeModal}
        successfulSubmissionMessage={reviewCorrectionsResponse}
        failedSubmissionMessage={getFailedSubmissionMessage()}
        status={getSubmissionModalStatus()}
        getSubmissionModalStatus={getSubmissionModalStatus}
      />
    </>
  ) : (
    <></>
  );
};
