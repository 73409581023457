import { useEffect, useState } from "react";
import { useValidateEmailToken } from "@coherehealth/core-platform-api";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "routes";
import { useSnackbar } from "notistack";
import { ContentsContainer, VerificationType } from "./shared";
import OnboardingVerificationExpired from "./FullScreenDisplay/OnboardingVerificationExpired";
import { LinearProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fullScreen: {
    backgroundColor: "#f4f6f8",
    height: "100%",
    marginTop: 40,
    marginBottom: 40,
  },
}));

export default function VerifyUserPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: validateEmailToken } = useValidateEmailToken({});
  const [currentStep, setCurrentStep] = useState<VerificationType>("START");
  const classes = useStyles();

  useEffect(() => {
    const fetchVerification = async () => {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get("token");

      if (token) {
        try {
          const response = await validateEmailToken({ token: token });
          if (response.status) {
            // Redirect to dashboard if successful
            navigate(routes.DASHBOARD);
          } else {
            if (response.desc === "REGENERATED") {
              setCurrentStep("REGENERATED");
            } else {
              enqueueSnackbar(`Invalid token`, {
                variant: "error",
              });
            }
          }
        } catch (error) {
          enqueueSnackbar(`Failed to validate token`, {
            variant: "error",
          });
        } finally {
        }
      } else {
        enqueueSnackbar(`No token provided`, {
          variant: "error",
        });
      }
    };

    fetchVerification();
  }, [enqueueSnackbar, location.search, navigate, validateEmailToken]);

  return (
    <div className={classes.fullScreen}>
      <ContentsContainer>
        {currentStep === "START" && <LinearProgress />}
        {currentStep === "REGENERATED" && <OnboardingVerificationExpired />}
      </ContentsContainer>
    </div>
  );
}
