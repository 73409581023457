import { H2, H5, H6, PrimaryButton, useFeature } from "@coherehealth/common";
import {
  AuthorizationResponse,
  ServiceRequestResponse,
  useSendFinalDeterminationNotification,
} from "@coherehealth/core-platform-api";
import { Grid, CircularProgress, useTheme } from "@material-ui/core";
import { NotificationSummary } from "components/Notification/NotificationSummary";
import { useGetNotifications } from "components/NotificationViewer/hooks/useGetNotifications";
import { isLettersExpansionNotification } from "components/NotificationViewer/util/notificationUtil";
import {
  NOTIFICATION_PURPOSE_OPTIONS,
  isFinalDeterminationStatus,
} from "../AuthorizationPatientSummary/AuthorizationPatientSummaryUtils";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import SendNewAuthNotificationModal from "common/SendNewNotificationModal/SendNewAuthNotificationModal";

export interface NotificationsSummaryProps {
  authorization: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse;
  serviceRequestsOnAuth?: ServiceRequestResponse[];
}

export function NotificationsSummary({
  authorization,
  serviceRequest,
  serviceRequestsOnAuth,
}: NotificationsSummaryProps) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const showsCreateNewNotificationsButton = useFeature("showsCreateNewNotificationsButton");

  const isAuthInFinalDetermination = isFinalDeterminationStatus(serviceRequest);
  const [isOpenCreateNewNotificationsModal, setIsOpenCreateNewNotificationsModal] = useState<boolean>(false);
  const [isSendingNewNotifications, setIsSendingNewNotifications] = useState<boolean>(false);
  const [selectedNotificationPurpose, setSelectedNotificationPurpose] = useState<string>(
    NOTIFICATION_PURPOSE_OPTIONS[0].id
  );
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const { mutate: sendFinalDeterminationNotification, loading: sendFinalDeterminationNotificationLoading } =
    useSendFinalDeterminationNotification({});

  const { fetchNotifications, notifications, notificationsLoading } = useGetNotifications({
    serviceRequest,
    authorization,
    serviceRequestsOnAuth,
  });

  const handleSendNewNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsSendingNewNotifications(true);
    event.preventDefault();
    event.stopPropagation();
    sendFinalDeterminationNotification({
      serviceRequestId: serviceRequest.id,
      recipients: selectedRecipients,
    })
      .then((response) => {
        if (response) {
          setIsOpenCreateNewNotificationsModal(false);
          setIsSendingNewNotifications(false);
          enqueueSnackbar(`Notification sent successfuly to recipients`, {
            variant: "success",
          });
          setSelectedRecipients([]);
          fetchNotifications();
        }
      })
      .catch((error) => {
        setIsOpenCreateNewNotificationsModal(false);
        setIsSendingNewNotifications(false);
        enqueueSnackbar(`Error creating notifications, please try again`, { variant: "error" });
        setSelectedRecipients([]);
      });
  };

  useEffect(() => {
    if (authorization && serviceRequest && serviceRequestsOnAuth) {
      fetchNotifications();
    }
  }, [authorization, fetchNotifications, serviceRequest, serviceRequestsOnAuth]);
  const supportedNotifications = notifications.filter((notification) => isLettersExpansionNotification(notification));
  if (notificationsLoading) {
    return (
      <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <H2 data-public>Notification data loading...</H2>
        </Grid>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <H5 data-public>{"Notifications (" + supportedNotifications.length.toString() + ")"}</H5>
      </Grid>
      {showsCreateNewNotificationsButton && serviceRequest && (
        <Grid item style={{ marginLeft: "auto" }}>
          <PrimaryButton
            onClick={() => setIsOpenCreateNewNotificationsModal(true)}
            disabled={!isAuthInFinalDetermination}
            data-testid={"authorization-patient-summary-notification-button"}
            size="small"
          >
            Create new notifications
          </PrimaryButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <>
          {supportedNotifications.length === 0 ? (
            <Grid item xs={12} style={{ padding: 0 }}>
              <H6 data-public>No notifications</H6>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {supportedNotifications
                .slice()
                .sort(
                  (a, b) => new Date(b.data.dateCreated || "").valueOf() - new Date(a.data.dateCreated || "").valueOf()
                )
                .map((notification, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <NotificationSummary
                        notification={notification}
                        authorization={authorization}
                        serviceRequest={serviceRequest}
                        serviceRequestsOnAuth={serviceRequestsOnAuth}
                      />
                    </React.Fragment>
                  );
                })}
            </Grid>
          )}
        </>
      </Grid>
      {showsCreateNewNotificationsButton && (
        <SendNewAuthNotificationModal
          title="Create new notifications"
          subTitle="This will create a new set of notifications from the current snapshot of the request."
          theme={theme}
          isOpen={isOpenCreateNewNotificationsModal}
          onClose={() => setIsOpenCreateNewNotificationsModal(false)}
          onSubmit={handleSendNewNotification}
          isLoading={isSendingNewNotifications && sendFinalDeterminationNotificationLoading}
          selectedNotificationPurpose={selectedNotificationPurpose}
          setSelectedNotificationPurpose={setSelectedNotificationPurpose}
          selectedRecipients={selectedRecipients}
          setSelectedRecipients={setSelectedRecipients}
        />
      )}
    </Grid>
  );
}
