import { PendingReason, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { CaseType, useGetServiceRequestCases } from "@coherehealth/qm-api";
import config from "api/config";
import { useEffect, useMemo, useState } from "react";

export const useServiceCaseForReview = (serviceRequest: ServiceRequestResponse | null, caseType?: CaseType) => {
  const [caseId, setCaseId] = useState<string | undefined>();
  const mappedCaseType = mapPendingReasonToCaseType(serviceRequest?.pendingReason);
  const { data: serviceCases, loading: serviceCasesLoading } = useGetServiceRequestCases({
    id: serviceRequest?.id || "",
    queryParams: {
      caseType: caseType ?? mappedCaseType,
    },
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const currentCase = useMemo(() => {
    const openCases = serviceCases?.filter((caseItem) => caseItem.caseStatus === "OPEN");

    if (openCases?.length === 1) {
      return openCases[0];
    } else {
      return serviceCases?.reduce((latest, current) => {
        const latestDate = latest?.lastUpdated ? new Date(latest.lastUpdated) : new Date(0);
        const currentDate = current?.lastUpdated ? new Date(current.lastUpdated) : new Date(0);

        return currentDate > latestDate ? current : latest;
      }, serviceCases[0]);
    }
  }, [serviceCases]);
  useEffect(() => {
    if (currentCase && currentCase?.id !== caseId && !serviceCasesLoading) {
      setCaseId(currentCase?.id);
    }
  }, [currentCase, caseId, serviceCasesLoading]);
  return {
    currentCase,
  };
};
export const mapPendingReasonToCaseType = (pendingReason: PendingReason | undefined) => {
  switch (pendingReason) {
    case "SCHEDULING_OUTREACH":
      return "SCHEDULING_OUTREACH";
    case "NUDGE_OUTREACH":
      return "NUDGE_OUTREACH";
    case "MISSING_CLINICAL_INFO":
      return "MISSING_INFORMATION";
    case "MISSING_NON_CLINICAL_INFO":
      return "MISSING_INFORMATION";
    case "RN_REVIEW":
      return "RN_REVIEW";
    case "MD_REVIEW":
      return "MD_REVIEW";
    case "PEER_TO_PEER_REVIEW":
      return "P2P_REVIEW";
    case "OUT_OF_NETWORK_REVIEW":
      return "OUT_OF_NETWORK_REVIEW";
    case "LETTER_WRITING":
      return "LETTER_WRITING";
  }
};
