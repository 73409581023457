import { Body1, colorsLight, H2, H5, SelectOptionsHook, SingleSelect, DropdownOption } from "@coherehealth/common";
import { useGetHealthPlanHierarchyFeatureConfigurations } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { CircularProgress, Grid, styled, useTheme } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { CONFIGURATION_MANAGEMENT_DRAWER_WIDTH } from "./SelectConfigurationDrawer";
import safeRegex from "safe-regex";
import { startCase } from "lodash";

interface Props {
  selectedFeatureConfigurationName: string;
  setSelectedFeatureConfigurationName: (name: string) => void;
}

export const CONFIGURATION_MANAGEMENT_HEADER_HEIGHT = 75;

export default function ConfigurationManagementHeader({
  selectedFeatureConfigurationName,
  setSelectedFeatureConfigurationName,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { spacing } = useTheme();
  const {
    data: featureConfigurations,
    error: featureConfigurationsError,
    loading: featureConfigurationsLoading,
  } = useGetHealthPlanHierarchyFeatureConfigurations({});

  const useConfigurationSearch = makeUseConfigurationSearch({ featureConfigurations, featureConfigurationsLoading });

  useEffect(() => {
    if (featureConfigurationsError) {
      enqueueSnackbar(
        `Failed to load feature configurations: ${featureConfigurationsError.message}. Please reload the page.`,
        { variant: "error" }
      );
    }
  }, [enqueueSnackbar, featureConfigurationsError]);

  return (
    <HeaderContainer>
      <DrawerTitleContainer>
        <DrawerTitleText>Configuration</DrawerTitleText>
      </DrawerTitleContainer>
      <ContentTitleContainer container>
        {featureConfigurations && !featureConfigurationsLoading && (
          <FeatureSelectionContainer item xs={3}>
            <SingleSelect<DropdownOption>
              label="Feature"
              fullWidth
              useOptions={useConfigurationSearch}
              selectedValue={configNameToDropDownOption(selectedFeatureConfigurationName)}
              setSelectedValue={(configOption) => {
                configOption?.id && setSelectedFeatureConfigurationName(configOption?.id);
              }}
              getOptionLabel={(option) => {
                return formatConfigName(option.id);
              }}
              data-testid="feature-config-select"
            />
          </FeatureSelectionContainer>
        )}
        {featureConfigurationsLoading && (
          <FeatureSelectionContainer item xs={3}>
            <CircularProgress style={{ marginTop: spacing(1) }} />
          </FeatureSelectionContainer>
        )}
        {featureConfigurationsError && (
          <FeatureSelectionContainer item xs={3}>
            <ErrorText>Failed to load configurations.</ErrorText>
          </FeatureSelectionContainer>
        )}
        <TitleContainer item xs={9}>
          <H2>Health plan configuration management</H2>
        </TitleContainer>
      </ContentTitleContainer>
    </HeaderContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  borderBottom: `3px solid ${theme.palette.primary.dark}`,
  height: CONFIGURATION_MANAGEMENT_HEADER_HEIGHT,
  position: "fixed",
  zIndex: 20,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TitleContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DrawerTitleContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: colorsLight.gray.inactive,
  alignItems: "center",
  display: "flex",
  height: "100%",
  width: CONFIGURATION_MANAGEMENT_DRAWER_WIDTH,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const FeatureSelectionContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  marginRight: theme.spacing(2),
  height: CONFIGURATION_MANAGEMENT_HEADER_HEIGHT,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ContentTitleContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: "white",
  display: "flex",
  width: `calc(100% - ${CONFIGURATION_MANAGEMENT_DRAWER_WIDTH}px)`,
  padding: theme.spacing(1, 2),
  flexWrap: "nowrap",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DrawerTitleText = styled(H5)(({ theme }) => ({
  margin: "auto",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorText = styled(Body1)(({ theme }) => ({
  color: theme.palette.error.dark,
  marginTop: theme.spacing(2.5),
}));

type MakeUseConfigurationSearchArgs = {
  featureConfigurations: string[] | null;
  featureConfigurationsLoading: boolean;
};

const makeUseConfigurationSearch = ({
  featureConfigurations,
  featureConfigurationsLoading,
}: MakeUseConfigurationSearchArgs): SelectOptionsHook<DropdownOption> => {
  const useConfigurationSearch: SelectOptionsHook<DropdownOption> = ({ query }) => {
    const formattedOptions: DropdownOption[] = featureConfigurations
      ? featureConfigurationOptionsFromData(featureConfigurations)
      : [];

    const filterOptions = (options: DropdownOption[]) => {
      let searchRegex: RegExp;
      if (query) {
        const isRegexSafe = safeRegex(query);
        if (isRegexSafe) {
          searchRegex = new RegExp(query, "i");
        } else {
          searchRegex = new RegExp("", "i");
        }
      } else {
        searchRegex = new RegExp("", "i");
      }

      return options.filter((configOption) => {
        return (
          (isString(configOption?.label) && searchRegex.test(configOption?.label || "")) ||
          searchRegex.test(configOption?.id || "")
        );
      });
    };

    return {
      options: formattedOptions,
      optionsLoading: featureConfigurationsLoading,
      filterOptions,
    };
  };
  return useConfigurationSearch;
};

const isString = (field: any): field is string => {
  return typeof field === "string";
};

const featureConfigurationOptionsFromData = (options?: string[]) => {
  return (
    options?.map((option) => {
      return configNameToDropDownOption(option);
    }) || []
  );
};

const configNameToDropDownOption = (configName: string) => {
  return { id: configName, label: formatConfigName(configName) };
};

const formatConfigName = (configName: string) => {
  return FEATURE_CONFIGURATION_DISPLAY_NAME_MAP[configName] || startCase(configName);
};

const FEATURE_CONFIGURATION_DISPLAY_NAME_MAP: Record<string, string> = {
  faxIntakeConfiguration: "Fax intake",
  finalDeterminationLetterIntegrationConfiguration: "Final determination letter integration",
  palCheckConfiguration: "PA Check",
  contactInfoConfiguration: "Contact information on PDFs",
  missingInformationOutreachConfiguration: "Missing Information Outreach Configuration",
  submissionConfiguration: "Submission configuration",
  admissionCategoryMappingConfiguration: "Admission Category Mapping Configuration",
  levelOfCareMappingConfiguration: "Level of Care Mapping Configuration",
  facilityBasedRequestConfiguration: "Facility based request configuration",
  postDecisionConfiguration: "Post-Decision Configuration",
  automatedDenialLetterConfiguration: "Automated Denial Letter Configuration",
  authStatusAttachmentsTransitionConfiguration: "Auth Status Attachments Transition Configuration",
  lettersExpansionConfiguration: "Letters Expansion Configuration",
  outOfNetworkReviewConfiguration: "Out of Network Review Configuration",
};
