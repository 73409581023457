import { Pill } from "@coherehealth/common";
import { Location } from "@coherehealth/core-platform-api";

interface NetworkStatusPillConfig {
  outOfNetworkStatusDisplayOff?: boolean;
  locationIsOutOfNetwork?: boolean;
  outOfNetworkStatusOverrideExists?: boolean;
  outOfNetworkStatusOverrideValue?: boolean;
  outOfNetworkStatusOverrideEnabled?: boolean;
  style?: React.CSSProperties;
}

interface FacilityNetworkStatusPillProps {
  location: Location | undefined;
  config: NetworkStatusPillConfig;
}

export const LocationNetworkStatusPill = (props: FacilityNetworkStatusPillProps): JSX.Element => {
  const { location } = props;
  const {
    outOfNetworkStatusDisplayOff,
    locationIsOutOfNetwork,
    outOfNetworkStatusOverrideExists,
    outOfNetworkStatusOverrideValue,
    outOfNetworkStatusOverrideEnabled,
    style,
  } = props.config;

  // if override value exist show pill using override values only
  const outOfNetworkPill = <Pill label="Out-of-network" variant="warning" style={style} />;
  if (outOfNetworkStatusOverrideExists) {
    if (!outOfNetworkStatusDisplayOff && outOfNetworkStatusOverrideValue && outOfNetworkStatusOverrideEnabled) {
      return outOfNetworkPill;
    }
  } else if (!outOfNetworkStatusDisplayOff && (locationIsOutOfNetwork || location?.networkType === "OON")) {
    return outOfNetworkPill;
  }

  if (!outOfNetworkStatusDisplayOff && location?.networkType === "OOA") {
    return <Pill label="Out-of-area" variant="warning" style={style} />;
  }
  const unkonwNetworkPill = <Pill label="Network unknown" variant="info" style={style} />;

  // if override value exist show pill using override values only
  if (outOfNetworkStatusOverrideExists) {
    if (
      !outOfNetworkStatusDisplayOff &&
      outOfNetworkStatusOverrideValue === undefined &&
      outOfNetworkStatusOverrideEnabled
    ) {
      return unkonwNetworkPill;
    }
  } else if (!outOfNetworkStatusDisplayOff && location?.networkType === "Unknown") {
    return unkonwNetworkPill;
  }
  return <></>;
};
