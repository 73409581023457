import { Grid } from "@material-ui/core";
import { Caption, CardTextField, InlineButton, Tooltip } from "@coherehealth/common";
import { AuthorizationResponse, Provider, ServiceRequestResponse, Location } from "@coherehealth/core-platform-api";
import { useCallback, useContext, useState } from "react";
import { ClassNameMap } from "@material-ui/styles";
import ContactInfoModal, { ContactInfoModalProps, ContactType } from "components/ContactInfoModal";
import { getUnitsAggregationsFaxAuthorizationCard } from "util/authorization";
import { strToPhoneNumber } from "util/phoneUtils";
import uniqueBy from "lodash/uniqBy";
import { FaxAuthorizationContext } from "util/context/FaxAuthorizationContext";
import { CareParticipantModal } from "./CareParticipantModal";

export interface CommonSelectionCardProps {
  classes: ClassNameMap;
  authorization: AuthorizationResponse | null;
  latestServiceRequest: ServiceRequestResponse | null;
}

interface SpecialistModalComponentProps {
  label: string;
  name: string;
  classes: ClassNameMap;
  onClick: () => void;
}

const modalStateOnClickHandler = (
  provider: Provider | undefined,
  providerLocation: Location | null | undefined,
  setModalState: React.Dispatch<React.SetStateAction<ContactInfoModalProps>>,
  modalType: ContactType
) => {
  setModalState({
    handleClose: () => setModalState((prev: ContactInfoModalProps) => ({ ...prev, open: false })),
    open: true,
    name: provider?.name || "None",
    phones: provider?.phoneNumbers,
    type: modalType,
    tinList: provider?.tinList || undefined,
    npi: provider?.npi,
    addresses: [providerLocation?.address],
    npiLabel: provider?.npi || "N/A",
    fax: providerLocation?.faxNumbers?.map(strToPhoneNumber),
  });
};

export const PracticeModalComponent: React.FC<SpecialistModalComponentProps> = ({ label, name, classes, onClick }) => {
  return (
    <div className={classes.practiceContainer}>
      <Caption className={`${classes.practiceLabelWrapper}`} color={"textSecondary"}>
        {label}
      </Caption>
      <div className={classes.viewButtonContainer}>
        {!name || name.trim() === "" ? (
          "--"
        ) : (
          <>
            <Tooltip interactive title={name ?? ""} disableHoverListener={name ? name.length <= 30 : true}>
              <div className={`${classes.textWrap} ${classes.practiceTypography}`}>{name ?? ""}</div>
            </Tooltip>
            <InlineButton className={classes.viewInfoLink} onClick={onClick}>
              View info
            </InlineButton>
          </>
        )}
      </div>
    </div>
  );
};

export function FaxAuthorizationCommonSection(props: CommonSelectionCardProps): JSX.Element {
  const [modalState, setModalState] = useState<ContactInfoModalProps>({
    open: false,
    handleClose: () => {
      setModalState((prev) => ({ ...prev, open: false }));
    },
    type: "FACILITY",
  });
  const { latestServiceRequest, classes } = props;

  const {
    placeOfService,
    orderingProvider,
    orderingProviderLocation,
    performingProvider,
    performingProviderLocation,
    facility,
    facilityLocation,
    procedureCodes,
  } = latestServiceRequest ?? {};

  const aggUnits = latestServiceRequest
    ? getUnitsAggregationsFaxAuthorizationCard(latestServiceRequest, latestServiceRequest?.procedureCodes)
    : undefined;

  const unitsLabel = !!aggUnits ? aggUnits.unitsType : "units";
  const unitsString = aggUnits ? aggUnits.unitsVal : "--";

  const uniqueProcedures = procedureCodes
    ? uniqueBy(procedureCodes, (c) => c.code)
        .map((px) => px.code)
        ?.join(", ")
    : "";

  const orderingProviderOnClickHandler = useCallback(
    () => modalStateOnClickHandler(orderingProvider, orderingProviderLocation, setModalState, "ORDERING_PROVIDER"),
    [orderingProvider, orderingProviderLocation]
  );

  const performingProviderOnClickHandler = useCallback(
    () =>
      modalStateOnClickHandler(performingProvider, performingProviderLocation, setModalState, "PERFORMING_PROVIDER"),
    [performingProvider, performingProviderLocation]
  );

  const facilityOnClickHandler = useCallback(
    () => modalStateOnClickHandler(facility, facilityLocation, setModalState, "FACILITY"),
    [facility, facilityLocation]
  );

  const { shouldShowAdditionalCareParticipants } = useContext(FaxAuthorizationContext);

  return (
    <>
      <Grid item xs={6}>
        <PracticeModalComponent
          classes={classes}
          label={"Ordering provider"}
          name={orderingProvider?.name ?? ""}
          onClick={orderingProviderOnClickHandler}
        />
      </Grid>
      <Grid item xs={6}>
        <PracticeModalComponent
          classes={classes}
          label={"Performing provider"}
          name={performingProvider?.name ?? ""}
          onClick={performingProviderOnClickHandler}
        />
      </Grid>
      <Grid item xs={6}>
        <PracticeModalComponent
          classes={classes}
          label={"Facility"}
          name={facility?.name ?? ""}
          onClick={facilityOnClickHandler}
        />
      </Grid>
      <Grid item xs={6} data-public data-testid="placeOfService">
        <CardTextField label="Place of service" content={`${placeOfService?.name}`} data-public />
      </Grid>
      {latestServiceRequest &&
        shouldShowAdditionalCareParticipants(latestServiceRequest) &&
        latestServiceRequest?.additionalCareParticipants?.map((careParticipant) => (
          <CareParticipantModal careParticipant={careParticipant} classes={classes} setModalState={setModalState} />
        ))}
      <Grid item xs={3}>
        <CardTextField label={`Number of ${unitsLabel}`} content={`${unitsString}`} />
      </Grid>
      <Grid item xs={9} data-public data-testid="px_codes">
        <CardTextField label="Procedure codes" content={uniqueProcedures} data-public />
      </Grid>
      <ContactInfoModal
        open={modalState.open}
        handleClose={modalState.handleClose}
        type={modalState.type}
        name={modalState.name}
        phones={modalState.phones}
        fax={modalState.fax}
        email={modalState.email}
        npi={modalState.npi}
        tinList={modalState.tinList}
        addresses={modalState.addresses}
        npiLabel={modalState.npiLabel}
      />
    </>
  );
}
