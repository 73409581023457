import { useContext, useMemo } from "react";

import {
  TableWithPanels,
  TableWithPanelsColumn,
  useFeature,
  Tooltip,
  Body3,
  Body2,
  canHaveCoverageLevelDetails,
} from "@coherehealth/common";
import {
  AuthorizationResponse,
  AuthStatus,
  ClinicalService,
  Coverage,
  ProcedureCode,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useAuthorized } from "authorization";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { useShowPalStatusToReviewerConfigurationByPayer } from "hooks/useGetFeatureConfigurations";

interface Props {
  authorization?: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse;
  procedureCodes: ProcedureCode[];
  clinicalService?: ClinicalService;
}

export const procedureCodeListStyles = makeStyles((theme) => ({
  greenText: {
    color: theme.palette.success.dark,
    paddingTop: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
  },
  successIcon: {
    color: theme.palette.success.dark,
  },
  rowContainer: {
    display: "flex",
  },
}));

export default function GeneralAuthProcedureCodeList({ procedureCodes, serviceRequest, clinicalService }: Props) {
  const theme = useTheme();

  const showUnitsOnPxStatus = clinicalService ? clinicalService?.isUnitsOnPx : true;
  const showUnitsOnServiceStatus = clinicalService ? !clinicalService?.isUnitsOnPx : false;

  const isReviewerOrAdmin = useAuthorized("USE_CLINICAL_REVIEW_REDESIGN_GENERAL");
  const multiCoverageReviewsEnabled = useFeature("multiCoverageReviews");

  const { currentReview } = useContext(ClinicalReviewContext);
  const srCoverageDetails = serviceRequest.outcomeCoverageLevelDetails?.map((coverageLevelOutcome) => {
    const coverage = coverageLevelOutcome.coverage;
    const status = coverageLevelOutcome.authStatus;
    return { coverage: coverage, status: status };
  });

  const reviewLobValues: ReviewLobValues[] = useMemo(() => {
    if (currentReview && canHaveCoverageLevelDetails(currentReview)) {
      const values =
        currentReview.coverageLevelDetails?.map((detail) => {
          const lob = detail.coverage?.lineOfBusinessType || "";
          const srMatch = srCoverageDetails?.find((srDetail) =>
            coverageMatchHelper(detail.coverage, srDetail.coverage)
          );
          const label = srMatch?.status === "APPROVED" ? "Recommended approval" : "Needs review";

          return { lob: lob, label: label };
        }) || [];
      return values;
    } else {
      return [];
    }
  }, [currentReview, srCoverageDetails]);

  const multiCoverageDerivedColumns = multiCoverageReviewColumnGenerator(reviewLobValues);

  const { isMultiCoverageReviewRequired } = serviceRequest;

  const useMultiCoverageContent = multiCoverageReviewsEnabled && isMultiCoverageReviewRequired;

  const { data: showPalStatusToReviewerConfig } = useShowPalStatusToReviewerConfigurationByPayer(
    serviceRequest?.healthPlanName ?? ""
  );

  const showPAL = showPalStatusToReviewerConfig?.enabled;

  const columns =
    isReviewerOrAdmin &&
    ["RECOMMENDED_PARTIAL_APPROVAL", "RECOMMENDED_DENIAL", "PENDING"].includes(
      serviceRequest.authStatus ? serviceRequest.authStatus : ""
    )
      ? [...pxTableColumnsContinuationsReview, ...multiCoverageDerivedColumns].filter(
          ({ filterFunction }) =>
            !filterFunction ||
            filterFunction({
              procedureCodes: procedureCodes || [],
              showUnitsOnPxStatus: showUnitsOnPxStatus,
              showUnitsOnServiceStatus: showUnitsOnServiceStatus,
              authStatus: serviceRequest.authStatus,
              useMultiCoverageContent: useMultiCoverageContent,
              showPAL: showPAL,
            })
        )
      : [...pxTableColumnsContinuations, ...multiCoverageDerivedColumns].filter(
          ({ filterFunction }) =>
            !filterFunction ||
            filterFunction({
              procedureCodes: procedureCodes || [],
              showUnitsOnPxStatus: showUnitsOnPxStatus,
              showUnitsOnServiceStatus: showUnitsOnServiceStatus,
              authStatus: serviceRequest.authStatus,
              useMultiCoverageContent: useMultiCoverageContent,
            })
        );

  const pxCodesWithStatus =
    procedureCodes && serviceRequest.semanticProcedureCodes
      ? procedureCodes.map((code) => {
          const srCodesWithStatus = serviceRequest.semanticProcedureCodes?.find((pxCode) => {
            const clinicalServiceGroupIdFilter =
              pxCode.groupBy === "ClinicalService" && pxCode.groupId === code.groupId;
            const unCategorizedGroupFilter = pxCode.groupBy !== "ClinicalService";
            return pxCode.code === code.code && (unCategorizedGroupFilter || clinicalServiceGroupIdFilter);
          });
          return { ...code, status: srCodesWithStatus?.status };
        })
      : procedureCodes;

  return (
    <>
      {procedureCodes && (
        <>
          <Grid item xs={12} style={{ padding: theme.spacing(0, 0, 1, 0) }}>
            <TableWithPanels<ProcedureCode> columns={columns} panels={[pxCodesWithStatus]} />
          </Grid>
        </>
      )}
    </>
  );
}

interface PxTableColumnFilterParams {
  procedureCodes: ProcedureCode[];
  showRequestedUnitsOnPxTable?: boolean;
  showApprovedUnitsOnPxTable?: boolean;
  showUnitsOnPxStatus?: boolean;
  showUnitsOnServiceStatus?: boolean;
  authStatus?: AuthStatus;
  useMultiCoverageContent?: boolean;
  showPAL?: boolean;
}

interface PxTableColumnConfig extends TableWithPanelsColumn<ProcedureCode> {
  filterFunction?: (args: PxTableColumnFilterParams) => boolean;
}
// A new table without PAL category, with a new column Status
const pxTableColumnsContinuations: PxTableColumnConfig[] = [
  {
    columnName: "Code",
    value: (palCatProcCode) => palCatProcCode.code,
    width: "10%",
    filterFunction: ({ useMultiCoverageContent }) => !useMultiCoverageContent,
  },
  {
    columnName: "Code",
    value: (palCatProcCode) => (
      <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
        <Body3>{palCatProcCode.code}</Body3>
        <Tooltip placement="top" title={<Body2>{palCatProcCode.description}</Body2>} style={{ marginLeft: 4 }}>
          <InfoIcon fontSize="small" style={{ color: "#000000DE" }} />
        </Tooltip>
      </div>
    ),
    filterFunction: ({ useMultiCoverageContent }) => !!useMultiCoverageContent,
    width: "10%",
  },
  {
    /* 
    Units on PX Status column
    e.g. "1 unit approved"
    e.g. "2 units requested, 1 unit approved"
    */
    columnName: "Status",
    value: (palCatProcCode) => procedureCodeStatus(palCatProcCode, true),
    filterFunction: ({ showUnitsOnPxStatus }) => !!showUnitsOnPxStatus,
    width: "17%",
  },

  {
    /* 
    Units on Service Status column
    e.g. "Approved/Denied/Requested"
    * If partially approved, display as if this were units on PX
    */
    columnName: "Status",
    value: (palCatProcCode) => procedureCodeStatus(palCatProcCode, false),
    filterFunction: ({ showUnitsOnServiceStatus }) => !!showUnitsOnServiceStatus,
    width: "17%",
  },
  {
    columnName: "Details",
    value: (palCatProcCode) => selectedDetailsCell(palCatProcCode),
    filterFunction: ({ showUnitsOnPxStatus, showUnitsOnServiceStatus, procedureCodes }) => {
      const hasShowUnits = !!(showUnitsOnPxStatus || showUnitsOnServiceStatus);
      const allSelectedDetailsPresent = procedureCodes.some((pxCode) => pxCode?.selectedDetails);
      return hasShowUnits && allSelectedDetailsPresent;
    },
    customWidth: (codes: ProcedureCode[][] | ProcedureCode[]) => {
      const firstRowCodes = Array.isArray(codes[0]) ? codes[0] : (codes as ProcedureCode[]);
      const hasFreeText = firstRowCodes.some((code) => code.attributeType?.includes("Free text"));
      const hasMultiSelect = firstRowCodes.some((code) => code.attributeType?.includes("Multi-select"));
      return hasFreeText ? "30%" : hasMultiSelect ? "15%" : "0";
    },
  },
  {
    columnName: "Description",
    value: (palCatProcCode) => palCatProcCode.description,
    filterFunction: ({ useMultiCoverageContent }) => !useMultiCoverageContent,
    width: "75%",
  },
];

export const selectedDetailsCell = (code: ProcedureCode, maxLengthBeforeTooltip: number = 80) => {
  const selectedDetails = code?.selectedDetails?.join(", ") ?? "--";
  const showToolTip = selectedDetails.length > maxLengthBeforeTooltip;
  return (
    <>
      {showToolTip ? (
        <Tooltip title={selectedDetails} arrow placement="top">
          <span
            style={{
              maxWidth: "100%",
              overflow: "hidden",
              lineHeight: "18px",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {selectedDetails}
          </span>
        </Tooltip>
      ) : (
        <span
          style={{
            maxWidth: "100%",
            overflow: "hidden",
            lineHeight: "18px",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {selectedDetails}
        </span>
      )}
    </>
  );
};
// A new table without PAL category, with a new column Status
const pxTableColumnsContinuationsReview: PxTableColumnConfig[] = [
  {
    columnName: "Code",
    value: (palCatProcCode) =>
      palCatProcCode.status === "RECOMMENDED_APPROVAL" ? (
        <ReviewPxCode code={palCatProcCode.code} />
      ) : (
        palCatProcCode.code
      ),
    filterFunction: ({ useMultiCoverageContent }) => !useMultiCoverageContent,
    width: "10%",
  },
  {
    columnName: "Code",
    value: (palCatProcCode) => (
      <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
        <Body3>{palCatProcCode.code}</Body3>
        <Tooltip placement="top" title={<Body2>{palCatProcCode.description}</Body2>} style={{ marginLeft: 4 }}>
          <InfoIcon fontSize="small" style={{ color: "#000000DE" }} />
        </Tooltip>
      </div>
    ),
    filterFunction: ({ useMultiCoverageContent }) => !!useMultiCoverageContent,
    width: "10%",
  },
  {
    columnName: "PAL",
    value: (palCatProcCode) => {
      const palListStatus = palCatProcCode.palListStatus ? palCatProcCode.palListStatus : "--";
      const showToolTip =
        palCatProcCode.userAddedNonPalComment && palCatProcCode.userAddedNonPalComment !== "" ? true : false;
      const parentDivStyle = { display: "flex", alignItems: "center" };
      const valueStyle = { flex: "1 0 0" };
      const tooltipStyle = { marginLeft: "8px", width: "16px", height: "16px" };
      return (
        <div style={parentDivStyle}>
          <div style={valueStyle}>{palListStatus}</div>
          {showToolTip && (
            <Tooltip style={tooltipStyle} title={palCatProcCode.userAddedNonPalComment} placement="top">
              <InfoOutlinedIcon />
            </Tooltip>
          )}
        </div>
      );
    },
    width: "10%",
    filterFunction: ({ useMultiCoverageContent, showPAL }) => !useMultiCoverageContent && !!showPAL,
  },
  {
    /* 
    Units on PX Status column
    e.g. "1 unit approved"
    e.g. "2 units requested, 1 unit approved"
    */
    columnName: "Status",
    value: (palCatProcCode) => procedureCodeStatus(palCatProcCode, true),
    filterFunction: ({ showUnitsOnPxStatus }) => !!showUnitsOnPxStatus,
    width: "17%",
  },
  {
    /* 
    Units on Service Status column
    e.g. "Approved/Denied/Requested"
    * If partially approved, display as if this were units on PX
    */
    columnName: "Status",
    value: (palCatProcCode) => procedureCodeStatus(palCatProcCode, false),
    filterFunction: ({ showUnitsOnServiceStatus }) => !!showUnitsOnServiceStatus,
    width: "17%",
  },
  {
    columnName: "Details",
    value: (palCatProcCode) => selectedDetailsCell(palCatProcCode),
    filterFunction: ({ showUnitsOnPxStatus, showUnitsOnServiceStatus, procedureCodes }) => {
      const hasShowUnits = !!(showUnitsOnPxStatus || showUnitsOnServiceStatus);
      const allSelectedDetailsPresent = procedureCodes.some((pxCode) => pxCode?.selectedDetails);
      return hasShowUnits && allSelectedDetailsPresent;
    },
    customWidth: (codes: ProcedureCode[][] | ProcedureCode[]) => {
      const firstRowCodes = Array.isArray(codes[0]) ? codes[0] : (codes as ProcedureCode[]);
      const hasFreeText = firstRowCodes.some((code) => code.attributeType?.includes("Free text"));
      const hasMultiSelect = firstRowCodes.some((code) => code.attributeType?.includes("Multi-select"));
      return hasFreeText ? "30%" : hasMultiSelect ? "15%" : "0";
    },
  },
  {
    columnName: "Description",
    value: (palCatProcCode) => palCatProcCode.description,
    filterFunction: ({ useMultiCoverageContent }) => !useMultiCoverageContent,
    width: "37%",
  },
];

interface ReviewPxCodeProps {
  code: string;
}

const ReviewPxCode = ({ code }: ReviewPxCodeProps) => {
  const classes = procedureCodeListStyles();
  return (
    <div className={classes.rowContainer}>
      <div className={classes.greenText}>{code}</div>
      <Tooltip title={"Meets auto-approval criteria"} placement="top">
        <CheckCircleIcon className={classes.successIcon} fontSize="small" />
      </Tooltip>
    </div>
  );
};

const multiCoverageReviewColumnGenerator = (reviewLobValues: ReviewLobValues[]): PxTableColumnConfig[] => {
  return reviewLobValues.map((item) => ({
    columnName: item.lob,
    value: () => item.label,
    filterFunction: ({ useMultiCoverageContent }) => !!useMultiCoverageContent,
    width: "10%",
  }));
};

/* ProcedureCodeStatus: Display the status for a procedure code */
const procedureCodeStatus = (procedureCode: ProcedureCode, isUnitsOnPx: boolean): string => {
  if (isUnitsOnPx) {
    if (
      (procedureCode.approvedUnits || procedureCode.approvedUnits === 0) &&
      (procedureCode.units || procedureCode.units === 0)
    ) {
      const approvedText = `${procedureCode.approvedUnits} ${
        procedureCode.unitType === "Number of visits"
          ? pluralizedVisits(procedureCode.approvedUnits)
          : pluralizedUnits(procedureCode.approvedUnits)
      } approved`;
      const requestedText = `${procedureCode.units} ${
        procedureCode.unitType === "Number of visits"
          ? pluralizedVisits(procedureCode.units)
          : pluralizedUnits(procedureCode.units)
      } requested`;
      const deniedText = `${procedureCode.units} ${
        procedureCode.unitType === "Number of visits"
          ? pluralizedVisits(procedureCode.units)
          : pluralizedUnits(procedureCode.units)
      } denied`;
      if (procedureCode.approvedUnits === procedureCode.units) {
        // Approval
        return approvedText;
      } else if (procedureCode.approvedUnits !== procedureCode.units && procedureCode.approvedUnits > 0) {
        // Partial Approval
        return `${requestedText}, ${approvedText}`;
      } else if (procedureCode.approvedUnits !== procedureCode.units && procedureCode.approvedUnits === 0) {
        // Denial
        return `${deniedText}`;
      }
    } else if (!procedureCode.approvedUnits && procedureCode.units) {
      // No Final Determination yet
      const requestedText = `${procedureCode.units} ${
        procedureCode.unitType === "Number of visits"
          ? pluralizedVisits(procedureCode.units)
          : pluralizedUnits(procedureCode.units)
      } requested`;
      return requestedText;
    }
    // Should not reach here, but bad data *can* occur
    return "--";
  } else {
    const approvedText = `Approved`;
    const requestedText = `Requested`;
    const deniedText = `Denied`;
    if (
      (procedureCode.approvedUnits || procedureCode.approvedUnits === 0) &&
      (procedureCode.units || procedureCode.units === 0)
    ) {
      if (procedureCode.approvedUnits === procedureCode.units) {
        // Approval
        return approvedText;
      } else if (procedureCode.approvedUnits !== procedureCode.units && procedureCode.approvedUnits > 0) {
        // Partial Approval - Delegate to units on PX result
        return procedureCodeStatus(procedureCode, true);
      } else if (procedureCode.approvedUnits !== procedureCode.units && procedureCode.approvedUnits === 0) {
        // Denial
        return deniedText;
      }
    } else if (!procedureCode.approvedUnits && procedureCode.units) {
      // No Final Determination yet

      return requestedText;
    }
    // Should not reach here, but bad data *can* occur
    return "--";
  }
};

const pluralizedUnits = (units: number) => (units && units === 1 ? `unit` : `units`);
const pluralizedVisits = (visits: number) => (visits && visits === 1 ? `visit` : `visits`);

type CoverageKeys = keyof Coverage;
const COVERAGE_MATCH_FIELDS: CoverageKeys[] = [
  "planStart",
  "productId",
  "planType",
  "groupId",
  "coverageLineOfBusinessType",
];

const coverageMatchHelper = (coverageA?: Coverage, coverageB?: Coverage) => {
  return COVERAGE_MATCH_FIELDS.every((item) => coverageA?.[item] === coverageB?.[item]);
};

type ReviewLobValues = {
  lob: string;
  label: string;
};
