import { dateAndTimeParsedFromDateStr, InformativeModal, SingleSelectDropdown } from "@coherehealth/common";
import { reviewHeaderConfig } from "../ReviewHeader/utils";
import { CommonReviewOutcome, reviewOutcomeLabel } from "components/ClinicalReview/reviewUtils/utils";
import { ReviewType } from "@coherehealth/core-platform-api";
import { Box } from "@material-ui/core";
import { ModalManagerProps } from "./hooks/useReviewCorrection";

const buildCompletedReviewDescription = (review: ReviewType) => {
  const { date: completedDate, time: completedTime } = review.dateCompleted
    ? dateAndTimeParsedFromDateStr(review.dateCompleted)
    : { date: null, time: null };
  const completedBy = review.completedByName || review.createdByName;
  return `${reviewHeaderConfig[review.reviewType].title} ${reviewOutcomeLabel(
    ("reviewOutcome" in review ? review.reviewOutcome : "") as CommonReviewOutcome
  )} ${completedDate ? `on ${completedDate}` : ""} ${completedTime ? `at ${completedTime} by ${completedBy}` : ""} `;
};

const getReviewSelectionOptions = (completedReviews: ReviewType[]) => {
  return completedReviews.map((review) => ({
    id: review.id,
    label: buildCompletedReviewDescription(review),
  }));
};

type ReviewSelectionModalProps = {
  open: boolean;
  value: string;
  onChange: ((arg0: string) => any) | undefined;
  primaryButtonDisabled: boolean;
  tertiaryButtonAction:
    | ((event?: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void)
    | undefined;
  completedReviews: ReviewType[];
  setModalManager: React.Dispatch<React.SetStateAction<ModalManagerProps>>;
};

export const ReviewSelectionModal = ({
  open,
  value,
  onChange,
  completedReviews,
  setModalManager,
  primaryButtonDisabled,
  tertiaryButtonAction,
}: ReviewSelectionModalProps) => {
  return (
    <InformativeModal
      open={open}
      onClose={() => {
        if (tertiaryButtonAction) {
          tertiaryButtonAction();
        }
      }}
      headerText="Select a review to proceed"
      primaryButtonAction={() =>
        setModalManager((prev) => ({ ...prev, openReviewSelectionModal: false, openReviewCorrectionModal: true }))
      }
      primaryButtonDisabled={primaryButtonDisabled}
      tertiaryButtonAction={tertiaryButtonAction}
      additionalInfoElement={
        <Box style={{ paddingTop: 32, paddingBottom: 16, width: "100%" }}>
          <SingleSelectDropdown
            fullWidth
            label="Select a review"
            value={value}
            options={getReviewSelectionOptions(completedReviews)}
            onChange={onChange}
          />
        </Box>
      }
      showDivider={false}
    />
  );
};
