import React, { useState, useContext, useEffect } from "react";
import { Body1, useFeature } from "@coherehealth/common";
import MenuItem from "@material-ui/core/MenuItem";
import PaperlessSettingsModal from "components/PaperlessSettingsModal";
import { UserContext } from "UserContext";
import { useGetOrganizationById, useGetUserById } from "@coherehealth/core-platform-api";
import NewPaperlessSettingsModal from "components/NewPaperlessSettingsModal";

interface Props {
  handleClose(): void;
}
export default function PaperlessSettingsMenuItem({ handleClose }: Props) {
  const [orgId, setOrgId] = useState<string>();
  const { getUser } = useContext(UserContext);

  const { data: organization } = useGetOrganizationById({ id: orgId || "" });
  const { data: user, refetch: fetchUserDetails } = useGetUserById({
    id: "",
    lazy: true,
  });

  useEffect(() => {
    getUser()?.then((currentUser) => {
      if (currentUser?.sub && currentUser.groups && currentUser.groups.filter((x) => x !== "Everyone").length > 0) {
        // Only bother fetching user details if the user belongs to groups; otherwise we won't
        // find one anyway
        fetchUserDetails({ pathParams: { id: currentUser.sub } });
      }
    });
  }, [getUser, fetchUserDetails]);

  useEffect(() => {
    if (user && user?.organization) {
      setOrgId(user.organization);
    }
  }, [user]);

  const [selectedValue, setSelectedValue] = useState<string>();
  useEffect(() => {
    if (orgId && organization) {
      setSelectedValue(organization.electronicLettersOptedIn ? "ON" : "OFF");
    }
  }, [orgId, organization]);
  const [paperlessSettingsModalOpen, setPaperlessSettingsModalOpen] = useState(false);

  // Since the modal does a query, only mount it once it has been opened once
  const [modalEverOpened, setModalEverOpened] = useState(paperlessSettingsModalOpen);
  useEffect(() => {
    if (paperlessSettingsModalOpen) {
      setModalEverOpened(true);
    }
  }, [paperlessSettingsModalOpen]);
  const useNewPaperlessModal = useFeature("updatePaperlessNotificationChanges");
  if (orgId) {
    return (
      <>
        <MenuItem
          style={{ padding: 8 }}
          onClick={() => {
            handleClose();
            setPaperlessSettingsModalOpen(true);
          }}
        >
          <Body1>Paperless notifications settings</Body1>
        </MenuItem>
        {modalEverOpened && (
          <>
            {!useNewPaperlessModal ? (
              <PaperlessSettingsModal
                open={paperlessSettingsModalOpen}
                onClose={() => setPaperlessSettingsModalOpen(false)}
                orgId={orgId}
              />
            ) : (
              <NewPaperlessSettingsModal
                open={paperlessSettingsModalOpen}
                onClose={() => setPaperlessSettingsModalOpen(false)}
                orgId={orgId}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            )}
          </>
        )}
      </>
    );
  }

  return null;
}
