import { useCallback, useEffect, useState, useContext, ReactNode, createContext } from "react";
import {
  PeerToPeerOutreach,
  ReviewType,
  ServiceRequestResponse,
  useGetPatient,
  useGetServiceRequestOutreachOpportunities,
  useGetServiceRequests,
  AttachmentGuidelineTextHighlightsSnapshot,
  MdReview,
  NurseReview,
  PeerToPeerReview,
  ClinicalReviewChangeRequest,
  useGetLatestReviewLeadingToDenial,
  UseGetLatestClinicalReviewChangeRequestProps,
  useGetLatestClinicalReviewChangeRequest,
  Coverage,
  useGetAllCoverages,
  Patient,
  useGetIsCoverageActive,
} from "@coherehealth/core-platform-api";
import {
  InformativeModal,
  useFeature,
  UserClickInfoClinicalReviewPage,
  getEmptyUserClickTracking,
  HighlightStateContext,
  NotFoundPage,
  FullPageLayout,
  useConfiguration,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import { withStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import ScrubTinsContextProvider from "../../ScrubTinsContext";
import {
  CREATE_MD_REVIEW_PARAM,
  CREATE_NURSE_REVIEW_PARAM,
  CREATE_OUT_OF_NETWORK_REVIEW_PARAM,
  CREATE_P2P_REVIEW_PARAM,
  CREATE_AUDIT_REVIEW_PARAM,
} from "util/queryParams";
import { useAuthorized } from "authorization";
import LoadingDialog from "../LoadingDialog";
import Tabs from "@material-ui/core/Tabs";
import CurrentClinicalReview from "./CurrentClinicalReview";
import SlimHeader from "../SlimHeader";
import ClinicalReviewShell from "common/ClinicalReviewShell";
import { GetDataError } from "restful-react";
import ReviewLeftPanel from "../reviewUtils/ReviewLeftPanel";
import CurrentNewOutreach from "components/NewOutreach/CurrentNewOutreach";
import {
  CaseType,
  Queue,
  ServiceCase,
  useGetQueueById,
  useGetServiceRequestCases,
  useUpdateServiceCase,
} from "@coherehealth/qm-api";
import config from "api/config";
import {
  DoctorReviews,
  LeftPanelTabs,
  ReviewPageVersion,
  isFetchedDataReady,
  useAuthorizationFetch,
} from "components/ClinicalReview/reviewUtils/utils";
import { useAttachments } from "../reviewUtils/useAttachment";
import { useGetServiceCase } from "@coherehealth/qm-api";
import UnsolvableCaseModal from "../../DocumentViewer/FaxAttachment/UnworkableFax/UnsolvableCaseModal";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";
import LinkingModeBorder from "../reviewUtils/LinkingModeBorder";
import LogOutreachPage from "components/NewOutreach/LogOutreachPage";
import { useInitReview } from "../reviewUtils/useInitCurrentReview";
import { useGetContinuationConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import NewNote from "components/Notes/NewNote";
import { UseClaimHistoryProps } from "components/ClaimHistory/useClaimHistory";
import useFetchAttachmentHighlights from "./hooks/useFetchAttachmentHighlights";
import { UseOtherAuthorizationProps } from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import { useSearchParams } from "react-router-dom";
import { LeftReviewTabs } from "../reviewUtils/LeftReviewTabs";
import { useServiceRequestRuleResultsForClinicalReview } from "../reviewUtils/useServiceRequestRuleResultsForClinicalReview";
import useFetchReviewOutcomes from "components/ServiceRequest/ReviewSection/common/hooks/useFetchReviewOutcomes";
import { ReviewOutcomeOption } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";
import { AppealNote } from "components/AppealNote/AppealNote";
import { isTerminalStatus } from "util/serviceRequest";
import { ClinicalAssessmentContextProvider } from "components/ClinicalAssessment";

export interface PeerToPeerOutreachReviewType extends PeerToPeerOutreach {
  reviewType: "P2POutreach";
  reviewStatus: "";
  dateCompleted?: string;
}

export type ReviewListTypes = (ReviewType | PeerToPeerOutreachReviewType)[];

type ContextQueueProperties = Pick<Queue, "id" | "holdsEnabled" | "name" | "holdReasons">;

export type ClinicalReviewContextType = {
  latestReviewLeadingToDenial: MdReview | NurseReview | PeerToPeerReview | null;
  latestChangeRequest: ClinicalReviewChangeRequest | null;
  latestChangeRequestReady?: boolean;
  clinicalReviewChangeRequestReady?: boolean;
  patientPrimaryCoverage?: Coverage | null;
  additionalActiveCoverages?: Coverage[] | null;
  isMultiCoverageReviewRequired: boolean;
  patient?: Patient | null;
  allowedReviewOutcomes: ReviewOutcomeOption[];
  currentReview?: ReviewType | null;
  healthPlanName?: string;
  delegatedVendorName?: string;
  queue?: ContextQueueProperties;
};

export const ClinicalReviewContext = createContext<ClinicalReviewContextType>({
  latestReviewLeadingToDenial: null,
  latestChangeRequest: null,
  latestChangeRequestReady: false,
  clinicalReviewChangeRequestReady: false,
  patientPrimaryCoverage: null,
  patient: null,
  isMultiCoverageReviewRequired: false,
  allowedReviewOutcomes: [],
  currentReview: null,
});

interface ClinicalPageProps extends UseOtherAuthorizationProps, UseClaimHistoryProps {
  reviewPageVersion?: ReviewPageVersion;
  existingReviews: ReviewType[] | null;
  existingReviewsError: GetDataError<unknown> | null;
  loadingExistingReviews: boolean;
  serviceRequestId: string;
  caseId?: string;
  trackingNumberSearch?: boolean;
}

export default function ClinicalReviewPage({
  reviewPageVersion,
  existingReviews,
  existingReviewsError,
  loadingExistingReviews,
  serviceRequestId,
  caseId,
  trackingNumberSearch,
  otherAuthsProps,
  claimHistoryProps,
}: ClinicalPageProps) {
  const { currentReview, loadingCurrentReview, setOpenWarningModal, openWarningModal, isReviewEditMode } =
    useInitReview({
      serviceRequestId,
      existingReviews,
    });
  const [leftColumnTab, setLeftColumnTab] = useState<LeftPanelTabs>("REQUEST_INFORMATION");
  const [openMemberCoverageExpiresModal, setOpenMemberCoverageExpiresModal] = useState<boolean>(false);
  const [userClickInfoTracking, setUserClickInfoTracking] = useState<UserClickInfoClinicalReviewPage>(
    getEmptyUserClickTracking()
  );
  const [search] = useSearchParams();
  const [currentCase, setCurrentCase] = useState<ServiceCase>();
  const [assignmentErrorModalOpen, setAssignmentErrorModalOpen] = useState<boolean>(false);
  const showServiceCaseUserHolds = useFeature("showServiceCaseUserHolds");
  const claimHistoryFeature = useFeature("claimHistoryFeature");
  const canUseClinicalReviewRedesign = useAuthorized("USE_CLINICAL_REVIEW_REDESIGN_GENERAL");
  const canUseOutreachPage = useAuthorized("ACCESS_LOG_OUTREACH_PAGE");
  const viewReview = useAuthorized("VIEW_REVIEW");
  const canUseMissingInfoPage = useAuthorized("MISSING_INFO");
  const canEditMdReview = useAuthorized("EDIT_MD_REVIEW");
  const canEditPeerToPeerReview = useAuthorized("EDIT_P2P_REVIEW");
  const canEditFinalizedMDReview = useAuthorized("EDIT_FINALIZED_MD_REVIEW");
  const canSubmitDenial = useAuthorized("SUBMIT_DENIAL");
  const canUseNewDenialsWorkflowBeta = useAuthorized("USE_NEW_DENIALS_WORKFLOW");
  const canUseNewDenialWorkflowGeneralAvailability =
    canEditMdReview || canEditPeerToPeerReview || canSubmitDenial || canEditFinalizedMDReview;
  const newDenialsWorkflow = canUseNewDenialsWorkflowBeta || canUseNewDenialWorkflowGeneralAvailability;
  const genericNotesEnabled = useFeature("genericNotes");
  const addNoteAuthorized = useAuthorized("EDIT_NOTES");
  const viewNoteAuthorized = useAuthorized("VIEW_NOTES");
  const canAddGenericNote = genericNotesEnabled && addNoteAuthorized;
  const canViewGenericNotes = genericNotesEnabled && viewNoteAuthorized;
  const attemptNumber = search.get("outreach_attempts");
  const urlQueueId = search.get("queueId") || "";

  const paramToCaseType: Record<string, CaseType> = {
    [CREATE_NURSE_REVIEW_PARAM]: "RN_REVIEW",
    [CREATE_OUT_OF_NETWORK_REVIEW_PARAM]: "OUT_OF_NETWORK_REVIEW",
    [CREATE_MD_REVIEW_PARAM]: "MD_REVIEW",
    [CREATE_P2P_REVIEW_PARAM]: "P2P_REVIEW",
  };

  let caseType: CaseType | undefined = undefined;
  for (const [param, type] of Object.entries(paramToCaseType)) {
    if (search.has(param)) {
      caseType = type;
      break;
    }
  }

  const [attachmentGuidelineTextHighlightsSnapshot, setAttachmentGuidelineTextHighlightsSnapshot] =
    useState<AttachmentGuidelineTextHighlightsSnapshot>({ nonOcrAttachmentIds: [] });

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: serviceRequest,
    error: serviceRequestFetchError,
    loading: serviceRequestLoading,
    refetch: serviceRequestRefetch,
  } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId,
  });

  const {
    data: patient,
    error: patientFetchError,
    loading: patientLoading,
    refetch: patientRefetch,
  } = useGetPatient({
    id: serviceRequest?.patient?.id || "fallback-no-patient",
    lazy: true,
  });

  const {
    data: isCoverageActive,
    error: isCoverageActiveFetchError,
    refetch: isCoverageActiveRefetch,
  } = useGetIsCoverageActive({
    queryParams: {
      serviceRequestId: serviceRequest?.id || "",
    },
    lazy: true,
  });

  const { data: originQueue, refetch: fetchCurrentQueue } = useGetQueueById({
    queueId: urlQueueId,
    lazy: true,
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const { data: allCoverages, error: patientCoverageError } = useGetAllCoverages({
    id: serviceRequestId,
  });
  const patientPrimaryCoverage = allCoverages?.primaryActiveCoverage;

  const facilityBasedRequestConfiguration = useConfiguration(
    "facilityBasedRequestConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );

  const { data: continuationConfiguration } = useGetContinuationConfigurationByPayer(
    serviceRequest?.healthPlanName ?? ""
  );

  useEffect(() => {
    if (patient && serviceRequest) {
      isCoverageActiveRefetch();
    }
  }, [patient, serviceRequest, isCoverageActiveRefetch]);

  useEffect(() => {
    if (isCoverageActive !== null) {
      setOpenMemberCoverageExpiresModal(!isCoverageActive.active);
    }
  }, [isCoverageActive]);

  const { data: outreachOpportunities } = useGetServiceRequestOutreachOpportunities({
    id: serviceRequestId,
  });

  const {
    data: otherServiceRequests,
    error: otherServiceRequestsError,
    loading: otherServiceRequestsLoading,
    refetch: fetchOtherServiceRequests,
  } = useGetServiceRequests({
    queryParams: { patient: `eq:${serviceRequest?.patient?.id || ""}` },
    lazy: true,
    resolve: (patientServiceRequests: ServiceRequestResponse[]) =>
      patientServiceRequests.filter((patientServiceRequest) => patientServiceRequest.id !== serviceRequest?.id),
  });

  const { refetch: refetchQmCase, loading: serviceCaseLoading } = useGetServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  const { mutate: updateServiceCase, error: updateCaseError } = useUpdateServiceCase({
    id: currentCase?.id || "",
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const { data: serviceCases, refetch: getServiceCases } = useGetServiceRequestCases({
    id: serviceRequest?.id || "none",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  const hasCorrectCurrentCaseType =
    currentCase && serviceRequest ? doesCaseMatchSrStatus(currentCase, serviceRequest) : true;
  const caseIsCancelled = currentCase && currentCase.caseStatus === "CANCELLED";
  const shouldCancelCase = caseId && !hasCorrectCurrentCaseType && !caseIsCancelled;

  useEffect(() => {
    if (serviceRequest && config.HAS_QM_ENVIRONMENT) {
      getServiceCases();
    }
  }, [getServiceCases, serviceRequest]);

  useEffect(() => {
    const fetchCaseInformation = async () => {
      const activeCase = await refetchQmCase();
      if (activeCase) {
        setCurrentCase(activeCase);
      }
    };
    if (serviceRequest && caseType && config.HAS_QM_ENVIRONMENT) {
      if (caseId) {
        fetchCaseInformation();
      }
    }
  }, [caseId, caseType, refetchQmCase, serviceRequest, serviceRequestId]);

  const cancelCase = useCallback(async () => {
    const cancelledCase = await updateServiceCase({
      assignee: {
        userName: "",
        name: "",
      },
      caseStatus: "CANCELLED",
    });
    setAssignmentErrorModalOpen(true);
    return cancelledCase;
  }, [updateServiceCase]);

  useEffect(() => {
    if (shouldCancelCase) {
      cancelCase();
    } else if (caseIsCancelled) {
      setAssignmentErrorModalOpen(true);
    }
  }, [cancelCase, caseIsCancelled, shouldCancelCase]);

  useEffect(() => {
    if (Boolean(urlQueueId && showServiceCaseUserHolds)) {
      fetchCurrentQueue();
    }
  }, [fetchCurrentQueue, urlQueueId, showServiceCaseUserHolds]);

  useEffect(() => {
    if (existingReviewsError) {
      enqueueSnackbar(`Failed to load reviews: ${existingReviewsError.message}`, { variant: "error" });
    }
    if (serviceRequestFetchError) {
      enqueueSnackbar(`Failed to load service request: ${serviceRequestFetchError.message}`, { variant: "error" });
    }
    if (patientFetchError) {
      enqueueSnackbar(`Failed to load patient information: ${patientFetchError.message}`, { variant: "error" });
    }
    if (isCoverageActiveFetchError) {
      enqueueSnackbar(`Failed to load active coverage information: ${isCoverageActiveFetchError.message}`, {
        variant: "error",
      });
    }
    if (otherServiceRequestsError) {
      enqueueSnackbar(`Failed to load patient's other service requests: ${otherServiceRequestsError.message}`, {
        variant: "error",
      });
    }
    if (updateCaseError && shouldCancelCase) {
      enqueueSnackbar(`Failed to update service case: ${updateCaseError.message}`, {
        variant: "error",
      });
    }
  }, [
    enqueueSnackbar,
    existingReviewsError,
    serviceRequestFetchError,
    patientFetchError,
    isCoverageActiveFetchError,
    otherServiceRequestsError,
    updateCaseError,
    shouldCancelCase,
  ]);

  useEffect(() => {
    if (serviceRequest) {
      patientRefetch();
      fetchOtherServiceRequests();
    }
  }, [patientRefetch, fetchOtherServiceRequests, serviceRequest]);

  const { authorization, fetchAuthorization, authorizationFetchLoading } = useAuthorizationFetch(serviceRequest);
  const {
    attachments,
    attachmentListFetchLoading,
    appealsForAuth,
    onSwitchAttachmentTab,
    onViewAttachment,
    copiedAttachmentText,
    setCopiedAttachmentText,
    pastedAttachmentTexts,
    setPastedAttachmentTexts,
    searchInfos,
    setSearchInfos,
    previousAttachmentsExpanded,
    setPreviousAttachmentsExpanded,
    currentPage,
    zoomLevel,
    currentRotate,
    attachmentIndexOpen,
    openAttachment,
    openAttachmentWarningModal,
    setOpenAttachmentWarningModal,
    newAttachmentsNumber,
    attachmentsInfo,
    setAttachmentsInfo,
  } = useAttachments({
    serviceRequest,
    authorization,
    existingReviews: existingReviews || [],
    currentCase,
    isCaseLoading: serviceCaseLoading,
  });

  const isAuditReview = search.has(CREATE_AUDIT_REVIEW_PARAM);
  const { linking, rightColumnTab } = useContext(HighlightStateContext);
  useFetchAttachmentHighlights(currentReview, attachments, isAuditReview);

  const useUpdatedClinicalReviewNudges = useFeature("updatedClinicalReviewNudges");

  const {
    hasReviewRedirectRule,
    reviewRedirectRule,
    reviewNudges,
    showAutoApprovalModal,
    showAutoApprovalInfoBanner,
    fetchingSrRules,
    setAutoApprovalModalDismissed,
  } = useServiceRequestRuleResultsForClinicalReview({
    serviceRequestId,
    isReviewEditMode,
    reviewType: currentReview?.reviewType,
  });

  // Retrieve the latest review leading to denial for this Service Request
  const {
    error: latestReviewLeadingToDenialError,
    loading: loadingLatestReviewLeadingToDenial,
    data: latestReviewLeadingToDenial,
  } = useGetLatestReviewLeadingToDenial({
    id: serviceRequestId,
  });

  const getLatestChangeRequestPayload: UseGetLatestClinicalReviewChangeRequestProps = {
    reviewId: latestReviewLeadingToDenial?.id ?? "",
    queryParams: {
      unresolvedOnly: true,
      returnRequestTo:
        latestReviewLeadingToDenial?.reviewType && DoctorReviews.includes(latestReviewLeadingToDenial?.reviewType)
          ? "MD_REVIEW"
          : "NURSE_REVIEW",
    },
    lazy: true,
  };

  const {
    data: latestChangeRequest,
    loading: loadingLatestChangeRequest,
    error: latestChangeRequestError,
    refetch: fetchLatestChangeRequest,
  } = useGetLatestClinicalReviewChangeRequest(getLatestChangeRequestPayload);

  useEffect(() => {
    if (patientCoverageError) {
      enqueueSnackbar("Error fetching Patient's Primary Coverage", { variant: "error" });
    }
    // We're not displaying an error message if we get a 404 for the Clinical Review Chnage Request
    // because it only means that there were not changes requested for Service Request
    if ((latestChangeRequestError && latestChangeRequestError?.status !== 404) || latestReviewLeadingToDenialError) {
      enqueueSnackbar("Error fetching Clinical Review Change Request", { variant: "error" });
    }
  }, [patientCoverageError, latestChangeRequestError, latestReviewLeadingToDenialError, enqueueSnackbar]);

  useEffect(() => {
    if (latestReviewLeadingToDenial?.id) {
      fetchLatestChangeRequest();
    }
  }, [latestReviewLeadingToDenial?.id, fetchLatestChangeRequest]);

  // Validate that the latest Clinical Review that led this Service Request to a denial was successfully loaded
  const latestChangeRequestReady = isFetchedDataReady({
    error: latestReviewLeadingToDenialError,
    data: latestReviewLeadingToDenial,
    loading: loadingLatestReviewLeadingToDenial,
  });

  // Validate that the latest Clinical Review Change Request that returned this case to the reviewer was successfully loaded
  const clinicalReviewChangeRequestReady = isFetchedDataReady({
    error: latestChangeRequestError,
    data: latestChangeRequest,
    loading: loadingLatestChangeRequest,
  });

  const { allowedReviewOutcomes } = useFetchReviewOutcomes({
    serviceRequestId: serviceRequest?.id,
    reviewId: currentReview?.id,
    reviewPageVersion,
  });

  const clinicalReviewContext: ClinicalReviewContextType = {
    latestReviewLeadingToDenial,
    latestChangeRequest,
    latestChangeRequestReady,
    clinicalReviewChangeRequestReady,
    patientPrimaryCoverage,
    additionalActiveCoverages: allCoverages?.additionalActiveCoverages,
    isMultiCoverageReviewRequired: serviceRequest?.isMultiCoverageReviewRequired || false,
    patient,
    allowedReviewOutcomes,
    currentReview,
    healthPlanName: serviceRequest?.healthPlanName,
    delegatedVendorName: serviceRequest?.delegatedVendor,
    queue: {
      id: originQueue?.id || "",
      name: originQueue?.name || "",
      holdsEnabled: Boolean(originQueue?.holdsEnabled),
      holdReasons: originQueue?.holdReasons || [],
    },
  };

  const isInpatient = serviceRequest?.encounterType === "INPATIENT";
  const decisionAppealsConfiguration = useConfiguration(
    "decisionAppealsConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );
  const appealNoteAuthorized = useAuthorized("EDIT_APPEAL");
  const isTerminalAuthStatus = isTerminalStatus(serviceRequest ?? {});
  const canLogAppealNote = appealNoteAuthorized;

  const getRightColumn = (): ReactNode => {
    if (reviewPageVersion === "NewOutReachPage" && serviceRequest) {
      return <CurrentNewOutreach serviceRequest={serviceRequest} outreachAttempts={attemptNumber} />;
    } else if (reviewPageVersion === "LogOutreachPage" && serviceRequest) {
      return <LogOutreachPage serviceRequest={serviceRequest} outreachAttempts={attemptNumber} />;
    } else if (reviewPageVersion === "NewNotePage" && canAddGenericNote && serviceRequest) {
      return <NewNote serviceRequest={serviceRequest} />;
    } else if (
      reviewPageVersion === "AppealNotePage" &&
      decisionAppealsConfiguration?.enabled &&
      canLogAppealNote &&
      isTerminalAuthStatus &&
      serviceRequest
    ) {
      return <AppealNote serviceRequest={serviceRequest} />;
    } else if (currentReview && serviceRequest) {
      return (
        <CurrentClinicalReview
          review={currentReview}
          existingReviews={existingReviews}
          serviceRequest={serviceRequest}
          userClickInfoTracking={userClickInfoTracking}
          setUserClickInfoTracking={setUserClickInfoTracking}
          searchInfos={searchInfos}
          setSearchInfos={setSearchInfos}
          newDenialsWorkflow={newDenialsWorkflow}
          copiedAttachmentText={copiedAttachmentText}
          setPastedAttachmentTexts={setPastedAttachmentTexts}
          pastedAttachmentTexts={pastedAttachmentTexts}
          openAttachment={openAttachment}
          attachmentGuidelineTextHighlightsSnapshot={attachmentGuidelineTextHighlightsSnapshot}
          facilityBasedFeatureEnabled={facilityBasedRequestConfiguration?.enabled && isInpatient}
          continuationConfiguration={continuationConfiguration}
          authorization={authorization}
          checkEmptyRequestedLoc={facilityBasedRequestConfiguration?.levelOfCareIsMandatoryOnReviews ?? false}
          hasReviewRedirectRule={hasReviewRedirectRule}
          reviewRedirectRule={reviewRedirectRule}
          showAutoApprovalModal={showAutoApprovalModal}
          setAutoApprovalModalDismissed={setAutoApprovalModalDismissed}
          showAutoApprovalInfoBanner={showAutoApprovalInfoBanner}
        />
      );
    } else {
      return null;
    }
  };

  const aModalIsOpen =
    openWarningModal ||
    openAttachmentWarningModal ||
    openMemberCoverageExpiresModal ||
    assignmentErrorModalOpen ||
    showAutoApprovalModal;

  const hasPermissionForPage = () => {
    switch (reviewPageVersion) {
      case "NewOutReachPage":
        return canUseMissingInfoPage;
      case "LogOutreachPage":
        return canUseOutreachPage;
      case "NewNotePage":
        return canAddGenericNote;
      case "AppealNotePage":
        return canLogAppealNote;
      default:
        if (viewReview || canUseClinicalReviewRedesign) {
          return true;
        }
    }
    return false;
  };

  if (!hasPermissionForPage()) {
    return (
      <FullPageLayout>
        <NotFoundPage />
      </FullPageLayout>
    );
  }

  return (
    <ScrubTinsContextProvider>
      <ClinicalAssessmentContextProvider>
        <ClinicalReviewContext.Provider value={clinicalReviewContext}>
          <ClinicalReviewShell
            attachmentView={leftColumnTab === "ATTACHMENTS"}
            header={
              <SlimHeader
                serviceRequest={serviceRequest}
                facilityBasedFeatureEnabled={facilityBasedRequestConfiguration?.enabled && isInpatient}
              />
            }
            leftColumnTabs={
              <LeftReviewTabs
                leftColumnTab={leftColumnTab}
                setLeftColumnTab={setLeftColumnTab}
                onSwitchAttachmentTab={onSwitchAttachmentTab}
                newAttachmentsNumber={newAttachmentsNumber}
                showClaimsHistoryTab={claimHistoryFeature}
                showInsightsButton={useUpdatedClinicalReviewNudges && isReviewEditMode}
                reviewNudges={reviewNudges}
                aModalIsOpen={aModalIsOpen}
                fetchingSrRules={fetchingSrRules}
                rightColumnTab={rightColumnTab}
                linking={!!linking}
                serviceRequest={serviceRequest}
              />
            }
            leftColumn={
              <ReviewLeftPanel
                claimHistoryProps={claimHistoryProps}
                otherAuthsProps={otherAuthsProps}
                authorization={authorization}
                refreshAuthorization={fetchAuthorization}
                authorizationFetchLoading={authorizationFetchLoading}
                attachments={attachments}
                attachmentsLoading={attachmentListFetchLoading}
                appealsForAuth={appealsForAuth}
                patient={patient}
                patientLoading={patientLoading}
                serviceRequest={serviceRequest}
                serviceRequestLoading={serviceRequestLoading}
                existingReviews={existingReviews}
                outreachOpportunities={outreachOpportunities}
                otherServiceRequests={otherServiceRequests}
                otherServiceRequestsLoading={otherServiceRequestsLoading}
                userClickInfoTracking={userClickInfoTracking}
                setUserClickInfoTracking={setUserClickInfoTracking}
                setSearchInfos={setSearchInfos}
                serviceRequestRefetch={serviceRequestRefetch}
                tabOpen={leftColumnTab}
                setTabOpen={setLeftColumnTab}
                attachmentIndexOpen={attachmentIndexOpen}
                previousAttachmentsExpanded={previousAttachmentsExpanded}
                setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
                currentPage={currentPage}
                zoomLevel={zoomLevel}
                currentRotate={currentRotate}
                newDenialsWorkflow={newDenialsWorkflow}
                setCopiedAttachmentText={setCopiedAttachmentText}
                serviceCases={serviceCases}
                trackingNumberSearch={trackingNumberSearch}
                caseId={caseId}
                serviceRequestId={serviceRequestId}
                setAttachmentGuidelineTextHighlightsSnapshot={setAttachmentGuidelineTextHighlightsSnapshot}
                canViewGenericNotes={canViewGenericNotes}
                onViewAttachment={onViewAttachment}
                clinicalReview={currentReview}
                attachmentsInfo={attachmentsInfo}
                setAttachmentsInfo={setAttachmentsInfo}
                canUseClaimHistoryFeature={claimHistoryFeature}
                attachmentSeachAcrossAttachmentsAllowed
              />
            }
            rightColumn={getRightColumn()}
          />
          <LoadingDialog
            open={loadingExistingReviews || serviceRequestLoading || loadingCurrentReview}
            headingText={`Loading your ${
              reviewPageVersion === "NewOutReachPage" || reviewPageVersion === "LogOutreachPage"
                ? "outreach page"
                : reviewPageVersion === "NewNotePage"
                ? "new note"
                : reviewPageVersion === "AppealNotePage"
                ? "post appeal page"
                : "clinical review"
            }`}
            subHeaderText={"This may take a few seconds"}
          />
          <InformativeModal
            open={openWarningModal}
            onClose={() => {
              setOpenWarningModal(false);
            }}
            headerText={"Review in progress"}
            additionalInfoText={`There is a review in progress for this SR started by ${
              currentReview?.createdByName || "unknown"
            }`}
            primaryButtonText={"Continue to review"}
            primaryButtonAction={() => {
              setOpenWarningModal(false);
            }}
          />
          <InformativeModal
            open={openAttachmentWarningModal && !openWarningModal}
            onClose={() => {
              setOpenAttachmentWarningModal(false);
            }}
            headerText={"New attachments uploaded"}
            additionalInfoText={`The submitter has uploaded ${newAttachmentsNumber} new attachments`}
            primaryButtonText={"View new attachments"}
            primaryButtonAction={() => {
              setLeftColumnTab("ATTACHMENTS");
              setOpenAttachmentWarningModal(false);
            }}
          />
          <InformativeModal
            icon={<ClipboardMissingInfo />}
            open={openMemberCoverageExpiresModal && !openWarningModal && !assignmentErrorModalOpen}
            onClose={() => {
              setOpenMemberCoverageExpiresModal(false);
            }}
            headerText={"This member’s coverage expires before the Date of Service"}
            additionalInfoText={`You may not be able to complete this ${
              reviewPageVersion === "NewOutReachPage" ? "outreach attempt" : "review"
            }`}
            primaryButtonText={`Continue to ${reviewPageVersion === "NewOutReachPage" ? "outreach attempt" : "review"}`}
            primaryButtonAction={() => {
              setOpenMemberCoverageExpiresModal(false);
            }}
          />
          <UnsolvableCaseModal
            open={assignmentErrorModalOpen}
            setOpen={() => setAssignmentErrorModalOpen(true)}
            assignmentErrorVariant
            caseType={caseType}
          />
          {linking && <LinkingModeBorder />}
        </ClinicalReviewContext.Provider>
      </ClinicalAssessmentContextProvider>
    </ScrubTinsContextProvider>
  );
}

export const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  indicator: {
    backgroundColor: theme.palette.text.primary,
  },
}))(Tabs);

export const doesCaseMatchSrStatus = (serviceCase: ServiceCase, serviceRequest: ServiceRequestResponse) => {
  if (serviceRequest.authStatus === "PENDING") {
    if (serviceRequest.pendingReason === "RN_REVIEW" && serviceCase.caseType === "RN_REVIEW") {
      return true;
    } else if (
      serviceRequest.pendingReason === "OUT_OF_NETWORK_REVIEW" &&
      serviceCase.caseType === "OUT_OF_NETWORK_REVIEW"
    ) {
      return true;
    } else if (serviceRequest.pendingReason === "MD_REVIEW" && serviceCase.caseType === "MD_REVIEW") {
      return true;
    } else if (serviceRequest.pendingReason === "PEER_TO_PEER_REVIEW" && serviceCase.caseType === "P2P_REVIEW") {
      return true;
    }
  } else if (
    ["RECOMMENDED_DENIAL", "RECOMMENDED_PARTIAL_APPROVAL"].includes(serviceRequest.authStatus || "") &&
    serviceCase.caseType === "LETTER_WRITING"
  ) {
    return true;
  } else if (
    serviceRequest.authStatus === "POST_DENIAL_PEER_TO_PEER" &&
    serviceCase.caseType === "P2P_REVIEW" &&
    serviceCase.isPostDenialP2P
  ) {
    return true;
  }
  return false;
};
