import { environmentAbbreviation, shouldDisplayEnvironmentIndicator } from "./envUtils";

/*
 * A List of some Cohere-App constants for padding/spacing/etc.
 */

// If there is an environment indicator, we need to add height to the header
export const appHeaderHeight = (isDesktopScreen?: boolean, isVerySmallScreen?: boolean) => {
  const env = environmentAbbreviation();
  if (shouldDisplayEnvironmentIndicator(env)) {
    if (isVerySmallScreen && !isDesktopScreen) {
      return 119;
    } else {
      return 109;
    }
  } else {
    if (isVerySmallScreen && !isDesktopScreen) {
      return 84;
    } else {
      return 74;
    }
  }
};
export const authGutterWidth = 24;
export const gutterWidth = 100;
export const headerHeight = 96;
export const tallHeaderHeight = 120;
export const leftRailWidth = 248;
export const rightRailWidth = 362;
export const authContentMaxWidth = 1288;
export const containerSmallBreakpoint = 608;
export const authContentMinWidth = 320;
export const contentMaxWidth = 1440;
export const responsiveFooterMaxWidthMdValue = 321;
export const extendedHeaderHeight = 154;
