import { FunctionComponent, createContext, useContext, useEffect, useState } from "react";
import isAfter from "date-fns/isAfter";
import { store } from "../util";
import pick from "lodash/pick";

type FeatureFlagStorageType = {
  [userId: string]: {
    dateCreated: Date;
    flags: FeatureFlagSettings;
  };
};

export type FeatureFlagStatus = "ON" | "OFF" | "LIMITED";

export type FeatureFlagSettings = {
  [featureName: string]: {
    booleanSettingValue: boolean;
    setting?: FeatureFlagStatus;
    description?: string;
    dateCreated?: string;
    lastUpdated?: string;
    limitedOrgsCount?: number;
  };
};

interface FeatureFlagContextType {
  flags: FeatureFlagSettings;
  loadingFlags: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagContextType>({ flags: {}, loadingFlags: false });

export interface FeatureFlagAdminView {
  id?: string;
  setting?: FeatureFlagStatus;
  description?: string;
  dateCreated?: string;
  lastUpdated?: string;
  booleanSettingValue?: boolean;
  limitedOrgsCount?: number;
}

export interface LocalFeatureFlagProps {
  [flagName: string]: {
    booleanSettingValue: boolean;
  };
}

export interface CsvFile {
  fileName: string;
  organizations: CsvOrganizationList[];
}
export interface CsvOrganizationList {
  id: string;
  name: string;
}

// The extra spaces are to try to avoid merge conflicts. Sorry they look silly.
// prettier-ignore
const authFeatureToggleDefaults = {
  
  suppressGhpCocApprovalLetters : {setting: false, description: "Suppress Approval letters for GHP Continuity of Care Service Requests"},

  denyWithoutPendToMdForPartialApproval: false,

  adHocBehavioralHealthNotifications: false,

  expandedBehavioralHealthNotifications: false,

  reviewTimelineCard: false,

  readmissionIdentification: false,

  attachmentPDFViewerDefaultScale: {setting: false, description:"When this feature flag is on, the default scale for viewing PDF attachments gets set to “PageFit” (“PageWidth” vice versa). "},

   claimHistoryFeature: {setting: false, description:"Turn on Claim History tab on Clinical Review Page"},

  enablePxCodeDeletion: false,

  enableMMOPayerLookUp: false,

  mmoSOW2CohereDelegatedNewFeatures: false,

  EnableMMOEditRevisionLogic : false,

  crdReturnApplicationJson: false,


  humanaPrioritySubTypes: false,


    humanaCRD: false,

    loadClinicalPartialApproval: false,


    canMarkReviewAudited: false,


    multiCoverageReviews: false,


    missingInformationFF: false,


    highmarkFhirBundle: false,


    patientManagementPage: process.env.NODE_ENV === "development" ? true : false,


    autoApprovalModalInCrr: true,


    filterByPatientHealthPlan: false,


    preferOcrEnhanced: process.env.NODE_ENV === 'development' ? true : false,


    showPatientCoverageCoordationOfBenefitsSection: false,


    dynamicIndicationRuleIcons: false,


    partialApproval: process.env.NODE_ENV === "development" ? true : false,


    preventUserCreatedProviders: process.env.NODE_ENV === "development" ? true : false,


    preventUserCreatedFacilities: process.env.NODE_ENV === "development" ? true : false,

    preventUserCreatedPerformingProviderPractice: process.env.NODE_ENV === "development" ? true : false,

    triggerMaintenancePage: true,


    viewParCheckStatus: process.env.NODE_ENV === "development" ? true : false,


    authBuilderOtherProcedureSelect: true,


    mobileDashboardViewable: process.env.NODE_ENV === "development" ? true : false,


    displayBackOfficeAdminEmailAutoVerifiedModal: true,


    healthHelpSRIntegrationEnabledAdminOnly: false,


    prePopulateDenialsTemplate: false,


    showDenialsInTheSameWindow: false,


    availityOnboarding: false,


    showLicensePopup: false,


    hideCareWebQIOnPatientSummary: false,


    simplifiedServiceFrequency: false,


    showDenialTemplatesRelatedToHealthPlan: false,


    authSubmissionVendorOnPalCheckEnabled: process.env.NODE_ENV === "development" ? true : false,


    showContentBeingNudged: false,


    pharmacyAuthBuilderFlow: process.env.NODE_ENV === "development",


    simplifiedN2S: false,


    episodicAuth: process.env.NODE_ENV === "development" ? true : false,


    expandedDedicatedNudgePage: false,


    guidelineSelectionPersists: false,


    ehrViewEnabled: false,


    integrateAuthDecisionGroups: process.env.NODE_ENV === "development" ? true : false,


    clearerDraftWorkflow:process.env.NODE_ENV === "development" ? true : false,


    docTypeandOrderingBeta: false,


    docTypeandOrderingGeneralAvailabilty: false,


    createAuthDecisionGroupServiceRequestRelations: false,

    auditReviews: false,


    generateProviderAndPatientDeterminationLetters: false,


    autoApprovalSkipCAQ: false,


    onboardingRedesign: false,


    MLPredictionHighlights: false,


    auditServiceHistory: false,


    textHighlightJobEnabled: false,

    procedureCodeSystemSearch: false,


    dualWriteProviders: false,


    sendCommunicationResourceInHumanaFHIR: false,


    useCohereHealthFDasVendorNameInFHIR: false,


    useCohereHealthFDAsVendorNameInAttachmentFhir: true,


    updatedFaxWorkflowAllUsers: false,


    onePendsAllPendsEnabledSurgicalServices: false,


    onePendsAllPendsEnabledForAll: false,


    postDenialWorkflow: false,


    slimServiceSelect: false,


    palCategoryFacilityPerformingProvider: false,


    unmetIndications: false,


    cohereSuggestedValueFeatureEnabled: false,


    geisingerSuggestedValueFeatureEnabled: false,


    highmarkSuggestedValueFeatureEnabled: false,


    highmarkPatientSearchSuggestedValueFeatureEnabled: false,

    faxAuthCardRedesign: false,

    /* enables a more all-purpose workflow that will support facility based auths as well as inpatient and outpatient procedures and get rid of split-auths */
    generalAuthSubmissionWorkflow: process.env.NODE_ENV === "development" ? true : false,


    generalAuthSubmissionWorkflowFull: false,


    diagnosisOutOfScope: false,


    removeRevenueCodes: false,


    textract: false,


    humanaLetterUpdates: false,


    missingInfoJob: false,


    missingInfoUpdates: false,


    alwaysSendGHPProviderLetters: false,


    restrictBehavioralAuths: false,


    useFaxCloseOptionConfiguration: false,


    showPreviouslyApprovedRequest: false,


    mapDenormalizedProviderToOriginalProviderObjects: false,


    manualProviderInfo: false,


    hideManuallyCreatedFacilitiesBeforeDate: false,


    cohereAuthStatusOverride: false,


    updatedProviderLabelForZZProviders: false,


    newDocSegmentationTypes: false,


    clinicalAuthSubmissionReviewType: false,


    manualProviderInfoPhase3 : false,


    genericNotes: false,


    humanaPartiallyApproved: false,



    translatedMdNote: false,


    multipleUnitsHomeHealth: false,


    returnLetterCaseToReviewer: {setting: false, description: "Sendback from denial letter writing queue to MD review"},


    replacePalCheckWithCrdCheck: false,


    includeContactInfo: false,


    usePostDecisionConfiguration: false,


    medicaidUnder21ClinicalConversations: {setting: false, description:"Enhancements for Cohere MDs to review GHP auths"},

    useAttachmentChangeStream: true,

    usePatientChangeStream: true,

    useOrganizationChangeStream: true,

    useUserChangeStream: true,

    useAssociatedIdentifierGroupChangeStream: true,

    useClaimChangeStream: true,

    useServiceRequestChangeStream: true,

    useReferralRequestChangeStream: true,

    skipManualNpiTin: false,

    enableAuthorizationIdIntakeRequests: false,

    fhirFeatureConfigurationEnabled: false,


    recentProviderDetails: {setting: false, description:"Allow users to select their recent providers / facilities when submitting a request so that they can submit faster"},

    useAutomatedDenialLetterConfiguration: false,


    caseBuildOONCheck: false,

    useExpeditedTatUpdateTimestamp: {setting: false, description:"Allows standard auths to move to expedited"},

    duplicateAuths: {setting: false, description:"Tell users about potential duplicate requests"},

    displayAuthStatusHistory: {setting: false, description:"Display all the history of Status and Pending Reason transitions for a Service Request"},

    faxEditServiceRequest : {setting: false, description: "A new offering in our fax intake workflow- allowing users to edit an existing auth, while the fax gets auto-attached to the request."},

    viewNotificationsOnAuthSummary: {setting: false, description:"Use a new Notification Viewer component on the Patient Summary, to display the Service Summary and all types of Letters. Will replace the Print button."},

    useSingleAttachmentViewerOnAuthSummary: {setting: false, description:"Use a new Single Attachment Viewer component on the Patient Summary, to display an attachment. Will replace the “eyeball” icon next to each attachment."},

    useAuthStatusAttachmentsTransitionConfiguration: {setting: false, description: "Auto-routes cases back to RN/MD review after attachments are added"},

    authGroupingForOtherSrs: false,

    postDecisionP2PExpanded: false,

    shouldUseConfigurableApprovedServiceRequestStateLogic: false,

    srEditsPayerConfigurable: {setting: false, description:"Allow editing for attachments and request details to be payer configurable"},


    allowPartialNurseReviewAdminDenials: false,


    faxAuditQueue: false,

    avoidDuplicatedWesfaxCases: {setting: false, description: "Block duplicated fax attachments using the same faxId"},


    faxIntakeShowOnlyLatestEditableServiceRequest: false,


    surfaceCrdCheckMessages: {setting: false, description: "Show users CRD messages based on a configuration for MMO"},


    expandedReviewStayDatesEdits: false,

    reviewStayDatesEdits: false,

    enableUserToUpdateOwnContactInfo: {setting: false, description: "Users will soon be able to update their contact info!! via portal. From the dashboard, user will be able to click on their profile and see existing details + make edits (Except email id and admin status). They can also add a fax # and phone number for outreach!"},


    missingAttachmentInfoReasons: {setting: false, description:"Enables the user to choose to provide a text response to clarify why some info in their attachments/etc. may be missing."},


   // COH-5341 - when on, the ability to start a continuation is determined by the "continuationEnabled" field in an auth's clinical services
    clinicalServicesContinuationEligibility: {setting: false, description: "Restrict most services from being able to be continuable"},


    restrictContinuationsForOtherOrganizations: {setting: false, description:"Don’t allow users from other organizations to continue requests"},


    includePhysicianSpecialtyAndLicenseOnDeterminationLetters: false,


    deDuplicateManualProvidersInSearch: false,


    highmarkClientAuthSubmissionV2: false,


    showResendActivationLinkButton: {setting: false, description:"BOAdmins will be able to re-trigger activation emails directly from org management page, making our provider more self sufficient and helping reduce call volumes!"},


    updatedClinicalReviewNudges: {setting: false, description:"This FF controls showing nudges in reviews in an updated insights panel that can be referenced later instead of on an ephemeral modal"},


    deprecateSalesforceServiceRequestIntegration: {setting: false, description: "Disables the ServiceRequest workflow in the Salesforce Integration Job in core-platform"},


    textHighlightImprovements: false,


    evidenceBasedNudgesDisplay: false,


    deprecateSalesforceFaxAttachmentIntegration: {setting: false, description:"on: disables FaxAttachment Salesforce Integration from SalesforceIntegrationJob off: enables FaxAttachment Salesforce Integration from SalesforceIntegrationJob"},


    deprecateSalesforceOrganizationIntegration: {setting: false, description: "on: disables Organization Salesforce Integration from SalesforceIntegrationJob off: enables Organization Salesforce Integration from SalesforceIntegrationJob"},


    deprecateSalesforceUserIntegration: {setting: false, description:"on: disables User Salesforce Integration from SalesforceIntegrationJob off: enables User Salesforce Integration from SalesforceIntegrationJob"},


    deprecateSalesforceAssociatedIdentifierGroupIntegration: {setting: false, description : "on: disables AssociatedIdentiferGroup Salesforce Integration from SalesforceIntegrationJob off: enables AssociatedIdentiferGroup Salesforce Integration from SalesforceIntegrationJob"},


    deprecateSalesforceClaimIntegration: {setting: false, description: "on: disables Claim Salesforce Integration from SalesforceIntegrationJob off: enables Claim Salesforce Integration from SalesforceIntegrationJob"},


    allowBackOfficeToUpdateExpeditedStatus: {setting: process.env.NODE_ENV === "development", description: "This FF allows back office users to update the expedited status of a submitted service request with a pending status, within Patient Summary."},


    allowAllUsersToEditFacilityInpatientRequests: {setting: process.env.NODE_ENV === 'development', description: "Allows all users to edit facility-based inpatient requests"},


    missingInformationOutreachIsConfigurable: {setting: false, description:"Allows configurability of MI outreaches, instead of hard coded setup. Safe rollout + future scaling."},


    newAuditReviewOutcomes: {setting: false, description: "newAuditReviewOutcomes is being added so that additional auditReview outcomes can be used in certain cases instead of (PASS/FAIL)"},


    syncIngestHumanaAuthStatus: false,

    cwqiReviewGuidelinesDemoView: false,

    addAttachmentDedicatedNudge: false,

    faxBehavioralHealthLetters: {
      setting: false,
      description: "(GHP-only) Enables automatic faxes to requestor when fax number is present for IP behavioral auths that are not continuations",
    },

    bodyAreaGuidelineFilters: false,

    relaxAuthVisibility: {
      setting: false,
      description: "Enables relaxing auth visibility logic",
    },

    homeHealthandShiftCareTATRules: {
      setting: false,
      description: "Enables TAT logic changes for home health and shift care requests",
    },

    ghpProviderPenaltyApprovalLetters: {
      setting: false,
      description: "Enable sending approval letter batches for partially approved auths that are provider penalties (GHP + Inpatient)",
    },

    deprecateAuthorizationServiceRequestIds: {
      setting: false,
      description: "Deprecates the authorization.serviceRequestIds field on the backend and instead fetches ids with a database query on the SR collection.",
    },

    LimitVoidsAfterApproval: {
      setting: false,
      description: "Allows users to mark a request as no longer needed after a final decision (approved) ",
    },


    pauseNonBHFinalDeterminationFaxes: {
      setting: false,
      description: "Prevent sending final determination faxes, except for GHP + BH requests"
    },

    bodyAreaUoTFiltering: {
      setting: false,
      description: "Adds body area to utilization over time filter options"
    },

    fastTrackProvider: {
      setting: false,
      description: "Enables providers that are fast tracked to skip CAQs."
    },

    sideBySideEditSrFaxIntakeWorkflow : {
      setting: false,
      description: "Enable Edit SR from Fax in Side by Side view",
    },
    
    unifyPatientSearch: {
      setting: false,
      description: "Unifies the patient search modal for both prior authorizations and referrals",
    },

    getByIdWithFallbacks: {
      setting: false,
      description: "Falls back to a default non-empty string when fetching /authorization/id or /serviceRequest/id",
    },

    cobrandingPayerLogos: {
      setting: false,
      description: "Include payer logos throughout the product to ensure strategic brand representation for our payer partners.",
    },

    skipPHIPageIfUserIsExistingUser: {
      setting: false,
      description: "Determines whether the PHI page is skipped for users who are recognized as existing users in the system.",
    },

    coverLetterConfiguration: {
      setting: false,
      description: "Enables the cover letter configurations for faxed enabled authorizations",
    },

    showPerformingProviderPracticeField: {
      setting: false,
      description: "Show Performing provider practice field when enabled.",
    },

    facilityBasedFlowForOakStreetHealthPlan: {
      setting: false,
      description: "Enabling IsDelegatedVendorOakStreetHealth enables facility based workflow for Oak Strert healthPlan .",
    },

    propagateServiceRequestFieldUpdatesToServiceCases: {
      setting: false,
      description: "Enables the QM Service Request consumer to propagate some SR updates to Service Cases",
    },

    allowFilteringOnNewServiceCaseFields: {
      setting: false,
      description: "Allows filtering of all columns in QM",
    },
    clinicalReviewNext: {
      setting: false,
      description: "Enable future version of clinical review pages post-refactor. Works with frontend FF overrides. Please do not turn this on in a deployed environment yet."
    },

    mdNoteTranslationNewModel: {
      setting: false,
      description: "Supports the use of the new MD note translation model",
    },

    useDelegatedVendorInQmQueries: {
      setting: false,
      description: "Makes queries in QM tenancy filtered on delegated vendor",
    },

    newAttachmentChipEnhancements: {
      setting: false,
      description: "Ability to clear the new attachment chip",

    },
    
    configurableCwqiParams: {
      setting: false,
      description: "Fetch CWQI params from a new config object in Parameter Store",
    },

    formValidationRefactor: {
      setting: false,
      description: "Refactor form validation for required fields"
    },

    allowContinuationDelegatedVendorsToChange: {
      setting: false,
      description: "Re-run the delegated vendor check when a continuation is created. Originally made for Inpatient OSH requests",
    },

    reviewHistoryAboveCurrentReviewNote: {
      setting: false,
      description: "Shows previous reviews above the current review note in the review's right panel",
    },

    logrocketUnmasking: {setting: false, description: "Feature flag for unmasking fields in Logrocket"},

    searchableBeyondOrganization: {
      setting: false,
      description: "Enable search beyond organization if user knows patient details",
    },
    
    denialLetterReadingLevel: {
      setting: false,
      description:
        "Add Reading Level as Subtext below Denial Letters Text",
    },

    runningReviewNotePull: {
      setting: false,
      description:
        "Enable Review Note inheritance from previous completed reviews for current running review",
    },
    
    automatedReviewNote: {
      setting: false, 
      description: "Populates ML generated clinical review notes when a review is initialized"
    },

    servicesEditability: {
      setting: false,
      description:
        "Allow editability of clinical services",
    },
    
    useCrdCheckOnContinuations: {
      settings: false,
      description:
        "Uses the CRD check on the continuation form when adding new procedure codes instead of the pal check"
    },
    showClaimsEvidence: {
      settings: false,
      description:
        "Enables claims evidence view in RRD conditions"
    },
    documentSegmentation: {
      setting: true,
      description: "Enable Document Segmentation feature, which parses a larger document and attempts to separate out the document info sub documents based on date of service, etc.",
    },

    delegatedVendorValidationForConfigs: {
      setting: false,
      description: "Requires users to create delegated vendor configurations only for supported delegated vendors for the given healthplan"
    },

    overturnToPartialApproval: {
      setting: false,
      description: "Allows the reviewer to overturn a denial to partial approval or update the details of the initial partial approval"
    },

    enableDenialTemplateGuidelineLobCheck: {
      setting: false,
      description: "Enable the guidelines ids check along with the line of business check when retrieving denial templates"
    },

    useLobCoveragesCatalogueForTempPatients: {
      setting: false,
      description: "Read the LOB options from the coverageFieldDistinctValues mongo collection instead of reading them from the hardcoded enum",
    },

    validateServiceRequestWithdrawalEvents: {
      setting: false,
      description: "Validates that the Service Request withdrawal events are valid within the context of the ServiceRequestEditsConfig definition",
    },

    allowBOUserToViewOwnOrg: {
      setting: false,
      description: "Give Back Office users read only access to view their own organization information",
    },

    addLanguagePreferenceToGeisingerPDFName: {
      setting: false,
      description: "Include Patient's Language preference to GHP PDF Naming Convention",
    },
    
    ssoUserOnboarding: {
      setting: false,
      description: "SSO improvements to gather additional data about new SSO users by improving the onboarding workflow and making it required for SSO users",
    },

    configDrivenAppealsForm: {
      setting: false,
      description: "Controls whether the appeals form should use the config to control field visibility",
    },

    enableReviewCorrections: {
      setting: false,
      description: "Controls whether review correction button is displayed or not",
    },
    shouldUseAppendedRoute: {
      setting: false,
      description: "Will include caseId if it exists and route to correct site based on referrer qm/auth"
    },

    showPatientStayTimeline: {
      setting: false,
      description: "Show the new patient stay timeline in place of the patient stay modal"
    },

    showsCreateNewNotificationsButton: {
      setting: false,
      description: "Shows and enables create new notifications button on auths"
    },

    bcbsScProviderPracticeLogic: {
      setting: false,
      description: "Uses special business logic for BCBS South Carolina Provider Practice fields. See COH-6414"
    },

    getGroupedAllHistoricPatientStayDates: {
      setting: false,
      description: "Get grouped all historic patient stay dates"
    },

    automatedReviewNoteTextHighlightJob: {
      setting: false,
      description: "Enables the job that translates the automated review note attribution info to readable highlights for the FE"
    },
    
    redirectFlyoutUxImprovments: {
      setting: false,
      description: "Rdirects flyout to optimize for redirect-specific functionality "
    },
  
    enableClinicalAssessmentOptimizations: {
      setting: false, 
      description: "Enable code optimizations for CLinical Assessment Controllers and for querying ruleRunDescription"
    },
    
    updatePaperlessNotificationChanges: {
      setting: false,
      description: "Display in the portal if org has opted into electronic notifications",
    },

    noClinicalInformationProvidedCheckboxEnabled: {
      setting: false,
      description : "Enable the checkbox for 'No Clinical Information Provided' in the clinical review page"
    },

  /**
   * DO NOT REMOVE THIS COMMENT - PLACE NEW FEATURE FLAGS ABOVE
   *
   * To add a feature flag with a description, add:
   * featureFlagName : {setting: boolean, description: string}
   *
   * KEEP DESCRIPTIONS CONCISE TO MINIMIZE PAYLOAD SIZE
   *
   * examples:
   * featureFlagName: {setting: process.env.NODE_ENV === "development", description: "A short description"}
   * featureFlagName: {setting: false, description: "A short description"}
   */
}

// prettier-ignore
const adminFeatureToggleDefaults = {

    humanaClaimCreatedDateTime: false,


    ssfeConfigurationWorkflow: false,


    ssfeLabelAndTrain: false,


    diagnosticImagingUpdates: false,


    allowNurseReviewAdminDenials: false,


    useFindingStoreEvaluators: false,


    newEvalCodesDrawer: false,


    allowOptionalRedirectRules: false,


    useSemanticLayerToQueryProcedureCodes: false,


    useSemanticLayerToQueryDiagnosisCodes: false,


    allowSetAuthStatusOutreachAttempt: process.env.NODE_ENV === "development",


    useSemanticLayerForPatientAttributes: process.env.NODE_ENV === "development",


    evaluatorSchemaTransformation: process.env.NODE_ENV === "development",


    semanticLayerInClinicalAssessment: process.env.NODE_ENV === "development",


    displayEditPolicyTextWarning: false,

    mitchellTestFeatureFlag: false,

    // Ability to submit multiple single services in AuthBuilder (not under a care path)
    multiSingleService: false,


    // This FF is specifically for outpatient request
    outPatientSpecificFF : {setting: false, description: "Allowing users to make edits to provider / facility details on continuations"},

    highmarkCaseBuild: {setting: false, description:"With FF being OFF, the auth request starting from QM will always be navigated to the “FILL_FORMS_FAX” page. With FF being ON, the auth request starting from QM will always be navigated to the “FILL_FORMS_FAX” page if specialty is triage or else will be navigated to the “GATHER_REQUIREMTS_MEDICAL” page if specialty is casebuild "},

    enhanceDXCodeSearch: false,

    evidenceBasedNudgesBuild: false,

    dataPropagationEnabled: {setting: false, description: "Used to enable data propagation between environments"},

    dataPromotionEnabled: {setting: false, description: "Allows enabling of data promotion across environments"},

    llmPolicyIngestion: {setting: false, description: "Allows users to upload a PDF and generate a Policy via a LLM"},

    addAttachmentDedicatedNudge: {setting: false, description: "Enables Add Attachments nudge on the DNS page"},

    disableContinueOON : {setting: false, description: "Allows disabling the continue button while OON checks are being performed"},

    postSubmissionIntelligencePDFUpdates: {setting: false, description: "Add post submission intelligence to Humana SS PDF."},

    postSubmissionIntelligenceRuleDataModelUpdates: {setting: false, description: "Updates to rule engine data model."},

    hideIcd10PxCodes: {setting: false, description: "hides icd10 px codes from auth portal"},

    ruleSimAllRulesUpdate: {setting: false, description: "Updates to the rule simulation UI."},

    enableAuditingByTemplate: {setting: false, description: "Enables auditing by template for sequential cutover to Notifications pipeline"},

    letterAudit: {setting: false, description: "Temporary Feature Flag for Letter Audit"},

    analyticsPageUpdate: {setting: false, description: "FF to determine whether to display new designs when viewing a SR in the analytics page"},

    averaGenericStatementCounty: {setting: false, description: "Adds the selectedFacilityCounty generic statement for Avera Health Plans rules"},

    showsLogoManagementSection: {setting: false, description: "Used to enable logo management page for Health plans"},

    reviewerWithdrawalModalEnhanced: {setting: false, description: "Hide or display the enhanced 'withdrawal reason modal' to internal users."},

    sendNewSRFaxNotice: {setting: false, description: "Send a fax notice to users after a new SR or a new continuation SR is created"},

    mcgGuidelineIngestion: {setting: false, description: "Enables ingesting MCG policies into the Cohere system"},

    simplifiedGHPTAT: {setting: false, description: "We have a complicated GHP tat logic where we automatically extend TAT after first nurse review. This feature flag remove the complicated logic when turned on."},

    enableSalesForceBotForOnboarding: {setting: false, description: "Enables salesforce chatbot for the onboarding workflow"},

    attachmentSeachAcrossAttachments: {setting: false, description: "Enables searching across all the attachments"},

    enableEnhancedPDFNotifications: {setting: false, description: "Enables document template management for finalDeterminationTemplates"},

    nudgeDismissalImprovements: {setting: false, description: "Nudge dismissal and decline reason changes"},

    conditionBank: {setting: false, description: "Condition bank work that will allow rule builders create a bank of conditions"},

    nonPalRequiredReasonFeature: { setting: false, description: "Enables and requires user to inform a reason for submiting a non-pal code"},

    optimizeVatCleanupJob: {setting: false, description: "Use indexed query in VatCleanupJob which sorts on TAT desc"}

    /**
   * DO NOT REMOVE THIS COMMENT - PLACE NEW FEATURE FLAGS ABOVE
   *
   * To add a feature flag with a description, add:
   * featureFlagName : {setting: boolean, description: string}
   *
   * KEEP DESCRIPTIONS CONCISE TO MINIMIZE PAYLOAD SIZE
   *
   * examples:
   * featureFlagName: {setting: process.env.NODE_ENV === "development", description: "A short description"}
   * featureFlagName: {setting: false, description: "A short description"}
   */
};

// prettier-ignore
const qmFeatureToggleDefaults = {

    changeToDiscardButtonForP2PReview: false,


    saveReviews: false,

    allowPostDecisionAuthStatusUpdates: {setting: false, description:"Allows users to edit status of an auth post-decision with pencil icon"},

    qmServiceCaseConsumer: {setting: false, description: "Feature flag that will handle the message processing from the 2 different consumers that will be listening to the same topic."},

    allowFiltersPersistanceOnQueueSection: {
      setting: false,
      description: "Allows filters persistance on Queues section (adds local storage management while filtering fields)"
    },

    uploadCasesForAuditQueue: false,

    massActionsOnQueues: {
      setting: false,
      description: "Allows mass delete and unassign actions on the queues"
    },

    showSearchBarAllMyWorkPages: {
      setting: false,
      description: "Allows the search bar to be displayed in all 'My Work' pages without taking into account the queue/user"
    },

    showServiceCaseUserHolds: {
      setting: false,
      description: "Shows the service cases on hold by the logged user in 'My Work'"
    },

  /**
   * DO NOT REMOVE THIS COMMENT - PLACE NEW FEATURE FLAGS ABOVE
   *
   * To add a feature flag with a description, add:
   * featureFlagName : {setting: boolean, description: string}
   *
   * KEEP DESCRIPTIONS CONCISE TO MINIMIZE PAYLOAD SIZE
   *
   * examples:
   * featureFlagName: {setting: process.env.NODE_ENV === "development", description: "A short description"}
   * featureFlagName: {setting: false, description: "A short description"}
   */
};

// prettier-ignore
const sharedFeatureToggleDefaults =  {

  multiTenancyFilteringRequireHealthPlanValue: false,

  showPendIndicator: false,

  showPendIndicatorV2: false,

  episodicAuthGenerationTrigger: false,

  allMMOpostSubmissionDecisioning: false,

  allPostSubmissionDecisioning: {setting: false, description: "Feature flag that will handle the use of feature configuration to run rules post submission async"},

  /**
   * DO NOT REMOVE THIS COMMENT - PLACE NEW FEATURE FLAGS ABOVE
   *
   * To add a feature flag with a description, add:
   * featureFlagName : {setting: boolean, description: string}
   *
   * KEEP DESCRIPTIONS CONCISE TO MINIMIZE PAYLOAD SIZE
   *
   * examples:
   * featureFlagName: {setting: process.env.NODE_ENV === "development", description: "A short description"}
   * featureFlagName: {setting: false, description: "A short description"}
   */

}

const featureToggleDefaults = {
  ...authFeatureToggleDefaults,
  ...adminFeatureToggleDefaults,
  ...qmFeatureToggleDefaults,
  ...sharedFeatureToggleDefaults,
};

type DefaultsType = typeof featureToggleDefaults;
type DefaultsKey = keyof typeof featureToggleDefaults;
type Packages = "AUTH" | "ADMIN" | "QM" | "SHARED";

export type FeatureToggle = keyof typeof featureToggleDefaults;

const FLAG_STORAGE_KEY = `cohere-feature-toggles`;
const MAX_FLAG_CACHE_SIZE = 5;

interface Props {
  fetchSimpleFlags: () => Promise<Response | undefined> | undefined;
  userIdPromise?: Promise<string | undefined>;
}

export const FeatureFlagsProvider: FunctionComponent<Props> = ({ fetchSimpleFlags, children, userIdPromise }) => {
  const [serverOverrides, setServerOverrides] = useState<FeatureFlagSettings>({});

  const [userId, setUserId] = useState<string>("anonymous");
  useEffect(() => {
    userIdPromise?.then(
      (id) => setUserId(id || "anonymous"),
      () => setUserId("anonymous")
    );
  }, [userId, userIdPromise]);
  const [cachedOverrides, setCachedOverrides] = useState<FeatureFlagSettings>(
    () => store.get(FLAG_STORAGE_KEY)?.[userId]?.flags || {}
  );

  if (process.env.REACT_APP_ALLOW_LOCAL_FF_OVERRIDES === "true") {
    const hasLocalFeatureFlags = Boolean(store.get("localFeatureFlags"));
    if (!hasLocalFeatureFlags) {
      store.set("localFeatureFlags", {});
    }
  }

  // Fetches flags from the cache by userid and stores in the cachedOverrides state.
  // If the authenticated user is different from the last cached userid, cachedOverrides is empty.
  // In this case, default flag values are used, and those are overridden by server values.
  useEffect(() => {
    const stashed: FeatureFlagStorageType = store.get(FLAG_STORAGE_KEY) || {};
    if (stashed[userId]) {
      setCachedOverrides(stashed[userId].flags);
    }
  }, [userId]);

  // Fetches flags from the server and stores the flags in browser storage (and in the serverOverrides state).
  // The results are keyed by userid.
  // On unauthenticated pages, userid becomes "" and flags are re-fetched.
  const [loadingFlags, setLoadingFlags] = useState(true); // initiate to true, because we're going to kick off the loading first regardless
  useEffect(() => {
    setLoadingFlags(true);
    let active = true;
    const getFeatureFlags = async () => {
      const simpleFlagsResponse = await fetchSimpleFlags();
      if (simpleFlagsResponse) {
        if (simpleFlagsResponse.ok && active) {
          const simpleFlagsBody = await simpleFlagsResponse.json();
          const flagsFromServer = arrayToObjectById(simpleFlagsBody);
          let cachedFlags: FeatureFlagStorageType = store.get(FLAG_STORAGE_KEY);

          // prune cache to only 5 entries (so it won't get too big if lots of distinct users keep logging on in the same browser)
          if (!!cachedFlags && Object.keys(cachedFlags).length > MAX_FLAG_CACHE_SIZE - 1) {
            // evict LRU entries from cache
            const truncatedCachedFlags: FeatureFlagStorageType = {};
            cachedFlags = Object.keys(cachedFlags)
              .sort((user1, user2) =>
                isAfter(cachedFlags[user1].dateCreated, cachedFlags[user2].dateCreated) ? 1 : -1
              )
              .slice(0, MAX_FLAG_CACHE_SIZE - 1)
              .reduce((newCache, _userId) => {
                newCache[_userId] = cachedFlags[_userId];
                return newCache;
              }, truncatedCachedFlags);
          }

          const newCache: FeatureFlagStorageType = {
            ...cachedFlags,
            [userId]: { flags: flagsFromServer, dateCreated: new Date() },
          };
          store.set(FLAG_STORAGE_KEY, newCache);
          setServerOverrides(flagsFromServer);
          setLoadingFlags(false);
        }
      }
    };
    getFeatureFlags();
    return () => {
      active = false;
    };
  }, [fetchSimpleFlags, userId]);

  const currentFlags = overrideDefaultFlags(serverOverrides, cachedOverrides);

  return (
    <FeatureFlagsContext.Provider value={{ flags: currentFlags, loadingFlags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

/**
 * Retrieves the initial feature flag values and overrides them with the values from the server/cache
 */
const overrideDefaultFlags = (
  serverOverrides: FeatureFlagSettings,
  cachedOverrides: FeatureFlagSettings
): FeatureFlagSettings => {
  const currentFlags = defaultsToActual(featureToggleDefaults);
  const overridingFlags = Object.keys(serverOverrides).length ? serverOverrides : cachedOverrides;

  for (const flag in overridingFlags) {
    if (flag in currentFlags) {
      currentFlags[flag] = { ...currentFlags[flag] };
      currentFlags[flag].booleanSettingValue = overridingFlags[flag].booleanSettingValue;
      currentFlags[flag].setting = overridingFlags[flag].setting;
      if (overridingFlags[flag].description) {
        currentFlags[flag].description = overridingFlags[flag].description;
      }
      currentFlags[flag].dateCreated = overridingFlags[flag].dateCreated;
      currentFlags[flag].lastUpdated = overridingFlags[flag].lastUpdated;
      currentFlags[flag].limitedOrgsCount = overridingFlags[flag].limitedOrgsCount;
    } else {
      currentFlags[flag] = overridingFlags[flag];
    }
  }

  return currentFlags;
};
/**
 * Disposition of a feature flag
 */
export interface FeatureFlagCurrentValue {
  flagName: string;
  booleanSettingValue: boolean;
  setting?: FeatureFlagStatus;
  description?: string;
  dateCreated?: string;
  lastUpdated?: string;
  limitedOrgsCount?: number;

  /**
   * Gives info re which package the non-orphan flags belong to.
   */
  belongingPackage: Packages | undefined;
}

/**
 * React hook to get feature flag information
 */
export function useFeatureFlagCurrentValues(): FeatureFlagCurrentValue[] {
  const { flags: currentFlags } = useContext(FeatureFlagsContext);

  const authFeatureToggleKeys = Object.keys(authFeatureToggleDefaults);
  const adminFeatureToggleKeys = Object.keys(adminFeatureToggleDefaults);
  const qmFeatureToggleKeys = Object.keys(qmFeatureToggleDefaults);
  const sharedFeatureToggleKeys = Object.keys(sharedFeatureToggleDefaults);

  const localFeatureFlags = store.get("localFeatureFlags");

  const flagSummaries: FeatureFlagCurrentValue[] = Object.keys(currentFlags).map((flag) => {
    const currentFlagValues = { ...currentFlags?.[flag] };
    if (process.env.REACT_APP_ALLOW_LOCAL_FF_OVERRIDES === "true") {
      const isPresentInLocalStorage = localFeatureFlags?.hasOwnProperty(flag);
      if (isPresentInLocalStorage) {
        currentFlagValues.booleanSettingValue = localFeatureFlags[flag].booleanSettingValue;
      }
    }

    return {
      flagName: flag,
      ...currentFlagValues,
      belongingPackage: adminFeatureToggleKeys.includes(flag)
        ? "ADMIN"
        : authFeatureToggleKeys.includes(flag)
        ? "AUTH"
        : qmFeatureToggleKeys.includes(flag)
        ? "QM"
        : sharedFeatureToggleKeys.includes(flag)
        ? "SHARED"
        : undefined,
    };
  });
  return flagSummaries;
}

/**
 * Converts the feature flag JSON response into a FeatureFlagSettings object
 */
const arrayToObjectById = (body: any[]): FeatureFlagSettings => {
  const addFF = (
    acc: FeatureFlagSettings,
    ffVal: {
      id: string;
      booleanSettingValue: boolean;
      setting: FeatureFlagStatus;
      description?: string;
      dateCreated: string;
      lastUpdated: string;
      limitedOrgsCount: number;
    }
  ) => ({
    ...acc,
    [ffVal.id]: pick(ffVal, [
      "booleanSettingValue",
      "setting",
      "description",
      "dateCreated",
      "lastUpdated",
      "limitedOrgsCount",
    ]),
  });
  return body.reduce(addFF, {});
};

/**
 * Returns the initial feature flag settings before server/local storage overrides
 */
function defaultsToActual(defaults: DefaultsType): FeatureFlagSettings {
  return Object.keys(defaults).reduce<FeatureFlagSettings>((acc: any, key: string) => {
    const values = defaults[key as DefaultsKey];

    if (typeof values === "object" && "description" in values && "setting" in values) {
      acc[key] = {
        setting: values.setting ? "ON" : "OFF",
        booleanSettingValue: values.setting,
        description: values.description,
      };
    } else {
      acc[key] = {
        setting: values ? "ON" : "OFF",
        booleanSettingValue: values,
      };
    }
    return acc;
  }, {});
}

type LocalFlagAccumulator = Record<
  string,
  { featureName: string; booleanSettingValue: boolean; belongingPackage?: Packages }
>;

// Type hack: do not allow bare `useFeature("generalAuthSubmissionWorkflow");
export function useFeature(
  name: Exclude<FeatureToggle, "generalAuthSubmissionWorkflow" | "generalAuthSubmissionWorkflowFull">
): boolean {
  const currentFlagsArray = useFeatureFlagCurrentValues();

  const initialAccumulator: LocalFlagAccumulator = {};
  const resultObject = currentFlagsArray.reduce((acc, obj) => {
    const { flagName, booleanSettingValue, belongingPackage } = obj;
    acc[flagName] = { featureName: flagName, booleanSettingValue, belongingPackage };
    return acc;
  }, initialAccumulator);

  return resultObject[name]?.booleanSettingValue;
}

/**
 * Returns if features are currently being fetched from the server
 */
export function useFeaturesLoading(): boolean {
  const { loadingFlags } = useContext(FeatureFlagsContext);
  return loadingFlags;
}
