import { Box, Grid } from "@material-ui/core";
import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { format } from "date-fns";
import { DetailsField } from "util/referralRequest";
import { formatDateStr } from "@coherehealth/common";

interface PatientStayTimelineHeaderProps {
  authorization: AuthorizationResponse;
  serviceRequest?: ServiceRequestResponse;
}

const formatPatientStatus = (status: string | undefined): string => {
  if (!status) {
    return "";
  }
  return status
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const PatientStayTimelineHeader = ({ authorization, serviceRequest }: PatientStayTimelineHeaderProps) => {
  return (
    <Grid container spacing={2} style={{ paddingTop: "16px" }}>
      <Grid item xs={6}>
        <DetailsField
          label="Status"
          value={formatPatientStatus(authorization?.patientStatus)}
          style={{ paddingTop: 0, paddingBottom: 4 }}
        />
        {authorization?.patientStatus === "NOT_YET_ADMITTED" ? (
          <DetailsField
            label="Expected admission date"
            value={serviceRequest?.expectedAdmissionDate ? formatDateStr(serviceRequest?.expectedAdmissionDate) : "--"}
          />
        ) : (
          <>
            <Box display="flex" alignItems="center" position="relative">
              <DetailsField
                label="Admission"
                value={
                  serviceRequest?.admissionDate && serviceRequest?.admissionTime
                    ? format(
                        new Date(`${serviceRequest.admissionDate} ${serviceRequest.admissionTime}`),
                        "MM/dd/yyyy HH:mm"
                      )
                    : "--"
                }
                style={{ paddingTop: 0, paddingBottom: 4 }}
              />
            </Box>
            <DetailsField
              label="Admission source"
              value={serviceRequest?.admissionSource?.name || "--"}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={6}>
        <DetailsField
          label="Discharge"
          value={serviceRequest?.dischargeDate ? formatDateStr(serviceRequest?.dischargeDate) || "--" : "--"}
          style={{ paddingTop: 0, paddingBottom: 4 }}
        />
        <DetailsField
          label="Discharged to"
          value={authorization?.dischargedTo?.name || "--"}
          style={{ paddingTop: 0, paddingBottom: 4 }}
        />
        <DetailsField
          label="Length of stay"
          value={`${authorization?.patientStayDates?.length || 0} days`}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        />
      </Grid>
    </Grid>
  );
};

export default PatientStayTimelineHeader;
