import { NotFoundPage, useFeature, useFeaturesLoading } from "@coherehealth/common";
import {
  OnboardingPatientInfo,
  Organization,
  useCreateOnboardingUserActivity,
  useOnboardingCheckEmailCanAutoVerify,
  useOnboardingOrganizationNameUniquenessCheck,
  useValidateIfEmailAlreadyExists,
  useVerifyPatientInfoAndCreateOrg,
  VerifyPatientInfoAndCreateOrgResponse,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "UserContext";
import { useChatWidget } from "util/chatWidget";
import { isPhoneNumberValid } from "util/phoneUtils";
import { isNpiValid, isTinValid, isZipcodeValid } from "util/providerUtils";
import { isBackOfficeWithoutOrganization } from "util/user";
import ExistingOrgUserInfoPage from "./ExistingOrgUserInfoPage";
import AdminAssistance from "./FullScreenDisplay/AdminAssistance";
import NonAdminWarning from "./FullScreenDisplay/NonAdminWarning";
import OnboardingAutoverificationSuccess from "./FullScreenDisplay/OnboardingAutoverificationSuccess";
import OnboardingVerificationLoading from "./FullScreenDisplay/OnboardingVerificationLoading";
import OnboardingVerificationSuccess from "./FullScreenDisplay/OnboardingVerificationSuccess";
import OnboardingVerificationUnderReview from "./FullScreenDisplay/OnboardingVerificationUnderReview";
import OutOfScopeWarning from "./FullScreenDisplay/OutOfScopeWarning";
import OnboardingFooter from "./OnboardingFooter";
import OnboardingVerificationHome from "./OnboardingVerificationHome";
import OnboardingVerificationPatient from "./OnboardingVerificationPatient";
import ScopeQuestionsPage from "./ScopeQuestionsPage";
import SearchPage from "./SearchPage";
import SelectionPage from "./SelectionPage";
import {
  AvailityInfo,
  ContentsContainer,
  INITIAL_PROVIDER_ORG_INFO,
  INITIAL_USER_INFO,
  isMemberDobValid,
  isMemberIdLengthValid,
  OnboardingSteps,
  ProviderOrgInfo,
  UserInfoState,
} from "./shared";
import usePostOnboardingRedirectUrl from "./usePostOnboardingUrl";
import UserOrgInfoPage from "./UserOrgInfoPage";
import { useDebouncedEffect } from "@react-hookz/web";
import OnboardingVerificationEmailSent from "./FullScreenDisplay/OnboardingVerificationEmailSent";

export default function OrganizationOnboardingPageContents({ isAuthenticatedUser }: { isAuthenticatedUser?: boolean }) {
  const patientInfoLength = 5;
  const { canSkipSplash } = usePostOnboardingRedirectUrl();
  const [currentStep, setCurrentStep] = useState<OnboardingSteps>("START");
  const [userInfo, setUserInfo] = useState<UserInfoState>(INITIAL_USER_INFO);
  const [autoVerificationEnabled, setAutoVerificationEnabled] = useState<boolean>();
  const [patientInfo, setPatientInfo] = useState<OnboardingPatientInfo[]>();
  const [patientInfoEntryValidation, setPatientInfoEntryValidation] = useState<VerifyPatientInfoAndCreateOrgResponse>({
    validated: true,
    validationResult: [],
  });
  const [initialUserEmail, setInitialUserEmail] = useState("");
  // State for adding a provider organization
  const [providerOrgInfo, setProviderOrgInfo] = useState<ProviderOrgInfo>(INITIAL_PROVIDER_ORG_INFO);
  // The selected provider organization when joining as a new user
  const [selectedProviderOrgId, setSelectedProviderOrgId] = useState<string>("");
  const [selectedProviderOrgName, setSelectedProviderOrgName] = useState<string>("");
  const [selectedProviderOrg, setSelectedProviderOrg] = useState<Organization>({});
  const onboardingRedesign = useFeature("onboardingRedesign");

  const setOrganizationTin = (tin: string) => {
    setProviderOrgInfo({ ...providerOrgInfo, tin });
  };
  const setUserEmail = (email: string) => setUserInfo({ ...userInfo, email });
  const onContinue = async () => {
    await checkEmailAutoVerificationEligibility();
    setCurrentStep("SELECTION");
  };

  // Get userEmail and organizationTin from the URL once on load
  // (passed from redirect modal that is supposed to drop you on the 2nd page of the onboarding flow)
  // (skipping the first page with email and TIN inputs)
  // If the inputs are valid, then continue to the selection page
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const suggestedOrganizationTin = urlSearchParams.get("organizationTin") || "";
    const suggestedUserEmail = urlSearchParams.get("userEmail") || "";
    if (suggestedOrganizationTin && suggestedUserEmail) {
      setOrganizationTin(suggestedOrganizationTin);
      setUserEmail(suggestedUserEmail);
      onContinue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    mutate: verifyInfoAndCreateOrg,
    loading: verifyInfoAndCreateOrgLoading,
    error: verifyInfoAndCreateOrgError,
  } = useVerifyPatientInfoAndCreateOrg({});
  // TODO consume this state for availity info
  // This is mostly for convenience, since it would be annoying to have to deal with handling the getUser promise
  // every time we want to use it. If you want to check if the user is from availity then use availityInfo.isAvailityUser
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [availityInfo, setAvailityInfo] = useState<AvailityInfo>({ isAvailityUser: false });
  // NOTE: the name AvailityInfo is a little bit misleading: should be "NewUserInfo"
  const { getUser } = useContext(UserContext);
  useEffect(() => {
    getUser()?.then(
      (user) => {
        if (isBackOfficeWithoutOrganization(user)) {
          if (user.groups && user.groups.length !== 1) {
            setCurrentStep("NOT_NEEDED");
            return;
          }
          // availity users will always at least have a role
          setAvailityInfo({ ...user, isAvailityUser: true });
          setUserInfo((prev) => ({
            ...prev,
            email: user.email || "",
            preferredUsername: user.preferred_username,
            firstName: user.AvailityUserFirstName || user.given_name || "",
            lastName: user.AvailityUserLastName || user.family_name || "",
            phoneNumber: {
              number: isPhoneNumberValid(user.AvailityUserPhone)
                ? user.AvailityUserPhone
                : isPhoneNumberValid(user.phoneNumber)
                ? user.phoneNumber
                : "",
            },
            faxNumber: { number: isPhoneNumberValid(user.AvailityCustomerFax) ? user.AvailityCustomerFax : "" },
          }));
          setInitialUserEmail(user.email || "");
          setProviderOrgInfo((prev) => ({
            ...prev,
            tin: prev.tin || user.AvailityCustomerTINs?.split(",")?.[0] || "",
            npi: user.AvailityCustomerNPIs || "",
          }));
        } else {
          setAvailityInfo({ isAvailityUser: false });
        }
      },
      () => setAvailityInfo({ isAvailityUser: false })
    );
  }, [getUser]);

  const {
    data: emailAutoVerificationEligibilityCheckResponse,
    loading: loadingEmailAutoVerificationCheck,
    refetch: checkEmailAutoVerificationEligibility,
  } = useOnboardingCheckEmailCanAutoVerify({
    queryParams: {
      email: userInfo.email,
    },
    lazy: true,
  });

  useEffect(() => {
    if (canSkipSplash && availityInfo.isAvailityUser && !!userInfo.email) {
      checkEmailAutoVerificationEligibility();
      setCurrentStep("SELECTION");
    } else {
      setCurrentStep("SEARCH");
    }
  }, [userInfo.email, canSkipSplash, checkEmailAutoVerificationEligibility, availityInfo.isAvailityUser]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentStep]);

  const { mutate: trackUserInteraction } = useCreateOnboardingUserActivity({});
  useEffect(() => {
    if (!!emailAutoVerificationEligibilityCheckResponse) {
      trackUserInteraction({
        event: "ORGANIZATION_EMAIL_AUTO_VERIFICATION_ELIGIBILITY_CHECK",
        stage: "ORGANIZATION_ONBOARDING_FLOW",
        type: "INTERACTION",
        activityContext: {
          onboardingAutoVerificationStatus: {
            eligible: emailAutoVerificationEligibilityCheckResponse.canAutoVerify,
            userEmail: userInfo.email,
            organizationTin: providerOrgInfo.tin,
          },
        },
      });
      if (emailAutoVerificationEligibilityCheckResponse.canAutoVerify && autoVerificationEnabled === undefined) {
        // default auto-verification enabled to true if user is eligible
        setAutoVerificationEnabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAutoVerificationEligibilityCheckResponse, autoVerificationEnabled, trackUserInteraction]);

  const {
    data: orgNameDuplicateCheck,
    refetch: checkOrgNameForDuplicates,
    error: orgNameDuplicateCheckError,
  } = useOnboardingOrganizationNameUniquenessCheck({
    lazy: true,
  });
  useDebouncedEffect(
    async () => {
      if (!!providerOrgInfo.name) {
        await checkOrgNameForDuplicates({
          queryParams: {
            organizationName: providerOrgInfo.name.replaceAll(/[^A-Za-z0-9]+/g, " "),
          },
        });
      }
    },
    [providerOrgInfo.name],
    500
  );
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (orgNameDuplicateCheckError) {
      enqueueSnackbar("Error occurred while validating the organization name", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, orgNameDuplicateCheckError]);

  useEffect(() => {
    if (verifyInfoAndCreateOrgError) {
      if (verifyInfoAndCreateOrgError?.status === 422) {
        setPatientInfoEntryValidation({ validated: false, validationResult: [] });
      } else {
        enqueueSnackbar(
          "An error occurred while processing your request. Please try again or select the option to verify manually.",
          {
            variant: "error",
          }
        );
        trackUserInteraction({
          event: "ORGANIZATION_PATIENT_INFO_AUTO_VERIFICATION",
          stage: "ORGANIZATION_ONBOARDING_FLOW",
          type: "INTERACTION",
          activityContext: {
            onboardingAutoVerificationStatus: {
              userEmail: userInfo.email,
              organizationTin: providerOrgInfo.tin,
              organizationName: providerOrgInfo.name,
              successful: false,
            },
          },
        });
      }
    }
  }, [
    verifyInfoAndCreateOrgError,
    setPatientInfoEntryValidation,
    patientInfoLength,
    userInfo.email,
    providerOrgInfo.tin,
    providerOrgInfo.name,
    enqueueSnackbar,
    trackUserInteraction,
  ]);
  useEffect(() => {
    setPatientInfo(new Array(patientInfoLength).fill({ memberId: "", memberDateOfBirth: "" }));
    setPatientInfoEntryValidation((prevState) => ({
      ...prevState,
      validationResult: new Array(patientInfoLength).fill(true),
    }));
  }, [patientInfoLength, setPatientInfo]);

  const onContinueFromCallback = useCallback(
    async (step: OnboardingSteps) => {
      switch (step) {
        case "USER_ORG_INFO":
          await trackUserInteraction({
            event: "ORGANIZATION_INFO_SUBMISSION",
            stage: "ORGANIZATION_ONBOARDING_FLOW",
            type: "INTERACTION",
            activityContext: {
              onboardingAutoVerificationStatus: {
                eligible: emailAutoVerificationEligibilityCheckResponse?.canAutoVerify,
                enabled: autoVerificationEnabled,
                userEmail: userInfo.email,
                organizationTin: providerOrgInfo.tin,
                organizationName: providerOrgInfo.name,
              },
            },
          });
          break;
        case "VERIFICATION_UNDER_REVIEW":
          await trackUserInteraction({
            event: "ORGANIZATION_MANUAL_VERIFICATION",
            stage: "ORGANIZATION_ONBOARDING_FLOW",
            type: "INTERACTION",
            activityContext: {
              onboardingAutoVerificationStatus: {
                eligible: emailAutoVerificationEligibilityCheckResponse?.canAutoVerify,
                enabled: false,
                userEmail: userInfo.email,
                organizationTin: providerOrgInfo.tin,
                organizationName: providerOrgInfo.name,
              },
            },
          });
          break;
      }
    },
    [
      autoVerificationEnabled,
      emailAutoVerificationEligibilityCheckResponse?.canAutoVerify,
      providerOrgInfo.tin,
      trackUserInteraction,
      userInfo.email,
      providerOrgInfo.name,
    ]
  );

  const canProceedToNextStep = () => {
    switch (currentStep) {
      case "NOT_NEEDED":
      case "START":
      case "SEARCH":
      case "SELECTION":
      case "NON_ADMIN_WARNING":
      case "OUT_OF_SCOPE_WARNING":
        return true;
      case "SCOPE_QUESTIONS":
        if (!onboardingRedesign) {
          const scopeQuestionsAnswered =
            providerOrgInfo.healthPlans.length > 0 &&
            providerOrgInfo.organizationSpecialty !== "" &&
            userInfo?.isAdmin !== undefined;
          return scopeQuestionsAnswered;
        } else {
          return (
            providerOrgInfo.healthPlans.length > 0 &&
            providerOrgInfo.organizationSpecialties.length > 0 &&
            userInfo?.isAdmin !== undefined
          );
        }
      case "USER_ORG_INFO":
        // destructure required fields and validate existence
        const userInfoValid =
          !!userInfo.firstName &&
          !!userInfo.lastName &&
          !!userInfo.title &&
          isPhoneNumberValid(userInfo.phoneNumber.number);
        const providerOrgInfoValid =
          !!providerOrgInfo.name &&
          isPhoneNumberValid(providerOrgInfo.phoneNumber.number) &&
          isNpiValid(providerOrgInfo.npi) &&
          !!providerOrgInfo.primaryAddress.line1 &&
          !!providerOrgInfo.primaryAddress.city &&
          !!providerOrgInfo.primaryAddress.state &&
          isZipcodeValid(providerOrgInfo.primaryAddress.zipCode) &&
          !!providerOrgInfo.organizationStructure &&
          !!orgNameDuplicateCheck?.success &&
          providerOrgInfo.otherTins.every(({ name, tin }) => !!name && isTinValid(tin));
        const hasSpecialties = !onboardingRedesign || userInfo.userSpecialties.length > 0;
        return userInfoValid && providerOrgInfoValid && hasSpecialties;
      case "VERIFICATION_START":
        return true;
      case "VERIFICATION_BY_PATIENT_INFO_CREATE_ORG":
      case "VERIFICATION_BY_PATIENT_INFO_JOIN_ORG":
        return (
          patientInfo?.every(
            ({ memberId, memberDateOfBirth }): boolean =>
              isMemberIdLengthValid(memberId) && isMemberDobValid(memberDateOfBirth)
          ) || false
        );
      case "VERIFICATION_LOADING":
        return true;
      case "VERIFICATION_SUCCESS":
        return true;
      case "EXISTING_ORG_USER_INFO":
        const existingOrgUserInfoValid =
          !!userInfo.firstName &&
          !!userInfo.lastName &&
          !!userInfo.title &&
          !!userInfo.phoneNumber.number &&
          isPhoneNumberValid(userInfo.phoneNumber.number);
        const hasUserSpecialties = !onboardingRedesign || userInfo.userSpecialties.length > 0;
        return existingOrgUserInfoValid && hasUserSpecialties;
      case "VERIFICATION_UNDER_REVIEW":
        return true;
      case "ADMIN_ASSISTANCE":
        return true;
      case "AUTO_VERIFICATION_SUCCESS":
        return true;
      case "VERIFICATION_SENT":
        return true;
    }
  };
  const [highlightInfoFormErrors, setHighlightInfoFormErrors] = useState(false);

  const hasValidHealthPlan = !providerOrgInfo.healthPlans.includes("none");

  const nullifyInvalidFields = () => {
    if (!hasValidHealthPlan) {
      setProviderOrgInfo({ ...providerOrgInfo, healthPlans: [] });
    }
    if (providerOrgInfo.organizationSpecialty === "Chiropathy") {
      setProviderOrgInfo({
        ...providerOrgInfo,
        organizationSpecialty: INITIAL_PROVIDER_ORG_INFO.organizationSpecialty,
      });
    }
    if (!userInfo?.isAdmin) {
      setUserInfo({ ...userInfo, isAdmin: undefined });
    }
  };

  const FOOTER_ITEMS: OnboardingSteps[] = [
    "SCOPE_QUESTIONS",
    "USER_ORG_INFO",
    "EXISTING_ORG_USER_INFO",
    "VERIFICATION_START",
    "VERIFICATION_BY_PATIENT_INFO_CREATE_ORG",
    "VERIFICATION_BY_PATIENT_INFO_JOIN_ORG",
    "OUT_OF_SCOPE_WARNING",
    "NON_ADMIN_WARNING",
    "SELECTION",
  ];

  const shouldShowFooter = FOOTER_ITEMS.includes(currentStep);
  const isOutOfScope = useMemo(
    () => !hasValidHealthPlan || providerOrgInfo.organizationSpecialty === "Chiropathy",
    [hasValidHealthPlan, providerOrgInfo.organizationSpecialty]
  );

  useChatWidget();

  // email duplicate validation
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const {
    data: verifyIfEmailAlreadyExists,
    refetch: checkVerifyIfEmailAlreadyExists,
    loading: verifyIfEmailAlreadyExistsLoading,
  } = useValidateIfEmailAlreadyExists({
    queryParams: {
      email: userInfo.email,
    },
    lazy: true,
  });
  const doesEmailAlreadyExist =
    verifyIfEmailAlreadyExists?.userAlreadyExists && !availityInfo.isAvailityUser && attemptedSubmit;
  const onContinueWithEmailValidation = async () => {
    setAttemptedSubmit(true);
    checkVerifyIfEmailAlreadyExists().then((data) => {
      if (!data?.userAlreadyExists) {
        onContinue();
      }
    });
  };

  // @ts-ignore
  return (
    <PageWrapper>
      <ContentsContainer>
        {currentStep === "NOT_NEEDED" && <NotFoundPage />}
        {currentStep === "SEARCH" && (
          <SearchPage
            onContinue={onContinue}
            onContinueWithEmailValidation={onContinueWithEmailValidation}
            continueLoading={loadingEmailAutoVerificationCheck}
            userEmail={userInfo.email}
            setUserEmail={setUserEmail}
            organizationTin={providerOrgInfo.tin}
            setOrganizationTin={setOrganizationTin}
            isSsoUser={availityInfo.isAvailityUser}
            initialUserEmail={initialUserEmail}
            verifyIfEmailAlreadyExistsLoading={verifyIfEmailAlreadyExistsLoading}
            doesEmailAlreadyExist={doesEmailAlreadyExist}
            setAttemptedSubmit={setAttemptedSubmit}
          />
        )}
        {currentStep === "SELECTION" && (
          <SelectionPage
            organizationTin={providerOrgInfo.tin}
            organizationName={providerOrgInfo.name}
            userEmail={userInfo.email}
            setCurrentStep={setCurrentStep}
            setSelectedProviderOrgId={setSelectedProviderOrgId}
            setSelectedProviderOrgName={setSelectedProviderOrgName}
            setSelectedProviderOrg={setSelectedProviderOrg}
          />
        )}
        {currentStep === "EXISTING_ORG_USER_INFO" && (
          <ExistingOrgUserInfoPage
            availityInfo={availityInfo}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            autoVerificationEnabled={autoVerificationEnabled}
            setAutoVerificationEnabled={setAutoVerificationEnabled}
            canEnableAutoVerification={false}
            isAvailityUser={availityInfo.isAvailityUser}
            highlightInfoFormErrors={highlightInfoFormErrors}
          />
        )}
        {currentStep === "SCOPE_QUESTIONS" && (
          <ScopeQuestionsPage
            providerOrgInfo={providerOrgInfo}
            setProviderOrgInfo={setProviderOrgInfo}
            isAdmin={userInfo?.isAdmin}
            setAdminStatus={(isAdmin) => setUserInfo({ ...userInfo, isAdmin })}
            isAvailityUser={availityInfo.isAvailityUser}
          />
        )}
        {currentStep === "USER_ORG_INFO" && (
          <UserOrgInfoPage
            availityInfo={availityInfo}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            autoVerificationEnabled={autoVerificationEnabled}
            setAutoVerificationEnabled={setAutoVerificationEnabled}
            canEnableAutoVerification={emailAutoVerificationEligibilityCheckResponse?.canAutoVerify || false}
            organizationInfo={providerOrgInfo}
            setOrganizationInfo={setProviderOrgInfo}
            isAvailityUser={availityInfo.isAvailityUser}
            highlightInfoFormErrors={highlightInfoFormErrors}
            orgNameIsDuplicate={orgNameDuplicateCheck != null && !orgNameDuplicateCheck.success}
          />
        )}
        {currentStep === "VERIFICATION_START" && (
          <OnboardingVerificationHome
            setCurrentStep={setCurrentStep}
            onContinueFrom={onContinueFromCallback}
            providerOrgInfo={providerOrgInfo}
            userInfo={userInfo}
          />
        )}
        {currentStep === "VERIFICATION_UNDER_REVIEW" && <OnboardingVerificationUnderReview />}
        {(currentStep === "VERIFICATION_BY_PATIENT_INFO_CREATE_ORG" ||
          currentStep === "VERIFICATION_BY_PATIENT_INFO_JOIN_ORG") &&
          (verifyInfoAndCreateOrgLoading ? (
            <OnboardingVerificationLoading />
          ) : (
            <OnboardingVerificationPatient
              patientInfo={patientInfo}
              setPatientInfo={setPatientInfo}
              patientInfoEntryValidation={patientInfoEntryValidation}
              setCurrentStep={setCurrentStep}
              onContinueFrom={onContinueFromCallback}
              providerOrgInfo={providerOrgInfo}
              userInfo={userInfo}
              currentStep={currentStep}
              availityInfo={availityInfo}
              selectedProviderOrgId={selectedProviderOrgId}
              selectedProviderOrgName={selectedProviderOrgName}
              autoVerificationEnabled={autoVerificationEnabled || false}
            />
          ))}
        {currentStep === "VERIFICATION_SUCCESS" && <OnboardingVerificationSuccess />} {/* old component */}
        {currentStep === "VERIFICATION_SENT" && <OnboardingVerificationEmailSent />}
        {currentStep === "OUT_OF_SCOPE_WARNING" && <OutOfScopeWarning />}
        {currentStep === "NON_ADMIN_WARNING" && <NonAdminWarning />}
        {currentStep === "ADMIN_ASSISTANCE" && <AdminAssistance />}
        {currentStep === "AUTO_VERIFICATION_SUCCESS" && <OnboardingAutoverificationSuccess />} {/* old component */}
      </ContentsContainer>
      {shouldShowFooter && (
        <OnboardingFooter
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          canProceedToNextStep={canProceedToNextStep()}
          highlightInfoFormErrors={highlightInfoFormErrors}
          setHighlightInfoFormErrors={setHighlightInfoFormErrors}
          onContinueFrom={onContinueFromCallback}
          providerOrgInfo={providerOrgInfo}
          patientInfo={patientInfo}
          autoVerificationEnabled={autoVerificationEnabled}
          userInfo={userInfo}
          verifyInfoAndCreateOrg={verifyInfoAndCreateOrg}
          setPatientInfoEntryValidation={setPatientInfoEntryValidation}
          isOutOfScope={isOutOfScope}
          userIsAdmin={userInfo?.isAdmin}
          nullifyInvalidFields={nullifyInvalidFields}
          selectedProviderOrgId={selectedProviderOrgId}
          selectedProviderOrgName={selectedProviderOrgName}
          availityInfo={availityInfo}
          selectedProviderOrg={selectedProviderOrg}
          isAuthenticatedUser={isAuthenticatedUser}
        />
      )}
    </PageWrapper>
  );
}

export const FeatureGatedOrganizationOnboardingPageContents = () => {
  const availityOnboardingFeatureOn = useFeature("availityOnboarding");
  const featuresLoading = useFeaturesLoading();

  if (!availityOnboardingFeatureOn && featuresLoading) {
    return null; // this should happen fairly quickly so we really don't need a loading state
  } else if (availityOnboardingFeatureOn) {
    return <OrganizationOnboardingPageContents isAuthenticatedUser />;
  } else {
    return <NotFoundPage />;
  }
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PageWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
}));
