import React from "react";

import { Body1, Caption, H6 } from "../Typography";
import { formatDateStr } from "../../util/DateUtils";
import { Patient } from "@coherehealth/core-platform-api";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { patientDisplayName } from "../../util/demographicUtils";
import { generatePath } from "react-router";
import { Launch } from "@material-ui/icons";

interface Props {
  patient: Patient;
}

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  infoLine: {
    display: "flex",
    alignItems: "center",
  },
  infoLabel: {
    marginRight: theme.spacing(1),
  },
  infoValue: {
    marginRight: theme.spacing(4),
  },
  cancelButton: {
    marginTop: "auto",
    marginBottom: theme.spacing(3),
  },
  patientDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  patientSummaryLink: {
    verticalAlign: "bottom",
    marginRight: "5px",
    width: "18px",
    height: "18px",
  },
}));

export default function SelectedPatientInfo({ patient }: Props) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.patientDiv}>
        <H6 className={classes.header}>{patientDisplayName(patient)}</H6>
        <MuiLink
          variant="body2"
          color="primary"
          underline="none"
          href={generatePath("/patient_summary/:patientId", { patientId: patient.id })}
          target="_blank"
          data-public
        >
          <Launch className={classes.patientSummaryLink} />
          View patient summary
        </MuiLink>
      </div>
      <div className={classes.infoLine}>
        <Caption className={classes.infoLabel} color="textSecondary">
          DOB
        </Caption>
        <Body1 className={classes.infoValue}>{formatDateStr(patient.dateOfBirth)}</Body1>
        <Caption className={classes.infoLabel} color="textSecondary">
          Member ID
        </Caption>
        <Body1>{patient.memberId}</Body1>
      </div>
    </div>
  );
}
