import { Body1, Caption } from "@coherehealth/common";
import {
  FinalDeterminationLetterAttachment,
  InternalFinalDeterminationFaxAttachment,
  InternalFinalDeterminationLetterAttachment,
  ServiceRequestResponse,
  TatExtensionLetterAttachment,
  VatNotification,
  WelltokVATNotification,
  useGetServiceRequestNotifications,
} from "@coherehealth/core-platform-api";
import { Divider, Grid, MenuItem } from "@material-ui/core";
import { useAuthorized } from "authorization";
import PrintableStatusAlert from "components/ServiceRequestStatusDisplay/PrintableStatusAlert";
import React from "react";
import {
  finalDeterminationLetterToPrint,
  getTatExtensionLetterByLetterAddressee,
  internalFinalDeterminationLetterToPrint,
  patientFinalDeterminationLetterToPrint,
  patientInternalFinalDeterminationLetterToPrint,
  pcpInternalFinalDeterminationLetterToPrint,
} from "util/serviceRequest";
import sortBy from "lodash/sortBy";
import {
  isWelltokVATNotification,
  NotificationResponse,
  wrapNotificationResponse,
  getRecipientOfNotification,
  createDisplayName,
  isLettersExpansionNotification,
} from "components/NotificationViewer/util/notificationUtil";

type Props = {
  serviceRequest: ServiceRequestResponse;
  onShowCallLog?: (
    arg0: ServiceRequestResponse,
    arg1: VatNotification | null | undefined,
    arg2: string | undefined
  ) => void;
  handleClosePrintMenu: () => void;
  handleCloseSubmenu?: (e: React.MouseEvent<HTMLLIElement>) => void;
  generateCallLogPdf: string | undefined;
  vatNotification: VatNotification[] | null | undefined;
};
interface DefaultLettersData {
  letterId: string | undefined;
  showingInternalLetter?: boolean;
  canViewLetter: boolean;
  internalLetter?: InternalFinalDeterminationLetterAttachment;
  externalLetter?: FinalDeterminationLetterAttachment;
  shouldShow: boolean;
  isTat: boolean;
  tatAttachment?: TatExtensionLetterAttachment;
  isFax?: boolean;
  faxAttachment?: InternalFinalDeterminationFaxAttachment;
}

const ServiceRequestPrintMenuItems = ({
  serviceRequest,
  onShowCallLog,
  handleClosePrintMenu,
  handleCloseSubmenu,
  generateCallLogPdf,
  vatNotification,
}: Props) => {
  const canViewFinalDeterminationLetter = useAuthorized("FINAL_DETERMINATION_LETTER_DISPLAY");
  const canViewPatientFinalDeterminationLetter = useAuthorized("PATIENT_FINAL_DETERMINATION_LETTER_DISPLAY");
  const patientTatExtensionLetter = getTatExtensionLetterByLetterAddressee(
    "PATIENT",
    serviceRequest.tatExtensionLetterAttachments
  );
  const providerTatExtensionLetter = getTatExtensionLetterByLetterAddressee(
    "PROVIDER",
    serviceRequest.tatExtensionLetterAttachments
  );
  const pcpTatExtensionLetter = getTatExtensionLetterByLetterAddressee(
    "PCP",
    serviceRequest.tatExtensionLetterAttachments
  );

  const hasInternalLetters =
    !!serviceRequest.internalFinalDeterminationLetterAttachments?.length ||
    !!serviceRequest.internalFinalDeterminationFaxAttachments?.length;
  const surfaceInternalLetters = hasInternalLetters;

  const defaultFinalDeterminationLetterAttachment = finalDeterminationLetterToPrint(
    serviceRequest.finalDeterminationLetterAttachments
  );
  const defaultFinalDeterminationLetterAttachmentId = defaultFinalDeterminationLetterAttachment?.id;

  const secondaryFinalDeterminationLetterAttachment = patientFinalDeterminationLetterToPrint(
    serviceRequest.finalDeterminationLetterAttachments
  );
  const secondaryFinalDeterminationLetterAttachmentId = secondaryFinalDeterminationLetterAttachment?.id;

  const defaultInternalFinalDeterminationLetterAttachment = surfaceInternalLetters
    ? internalFinalDeterminationLetterToPrint(serviceRequest.internalFinalDeterminationLetterAttachments)
    : undefined;
  const defaultInternalFinalDeterminationLetterAttachmentId = defaultInternalFinalDeterminationLetterAttachment?.id;

  const secondaryInternalFinalDeterminationLetterAttachment = surfaceInternalLetters
    ? patientInternalFinalDeterminationLetterToPrint(serviceRequest.internalFinalDeterminationLetterAttachments)
    : undefined;
  const secondaryInternalFinalDeterminationLetterAttachmentId = secondaryInternalFinalDeterminationLetterAttachment?.id;

  const tertiaryInternalFinalDeterminationLetterAttachment = surfaceInternalLetters
    ? pcpInternalFinalDeterminationLetterToPrint(serviceRequest.internalFinalDeterminationLetterAttachments)
    : undefined;
  const tertiaryInternalFinalDeterminationLetterAttachmentId = tertiaryInternalFinalDeterminationLetterAttachment?.id;

  const internalFinalDeterminationFaxAttachment =
    surfaceInternalLetters && serviceRequest.internalFinalDeterminationFaxAttachments?.length
      ? sortBy(serviceRequest.internalFinalDeterminationFaxAttachments, (a) => a.dateCreated).reverse()[0]
      : undefined;

  const internalFinalDeterminationFaxAttachmentId = internalFinalDeterminationFaxAttachment?.id;

  const defaultDeterminationLetterId =
    surfaceInternalLetters && defaultInternalFinalDeterminationLetterAttachmentId
      ? defaultInternalFinalDeterminationLetterAttachmentId
      : defaultFinalDeterminationLetterAttachmentId;

  const secondaryDeterminationLetterId =
    surfaceInternalLetters && secondaryInternalFinalDeterminationLetterAttachmentId
      ? secondaryInternalFinalDeterminationLetterAttachmentId
      : secondaryFinalDeterminationLetterAttachmentId;

  const tertiaryDeterminationLetterId =
    surfaceInternalLetters && tertiaryInternalFinalDeterminationLetterAttachmentId
      ? tertiaryInternalFinalDeterminationLetterAttachmentId
      : undefined;

  const showingInternalDefaultLetter =
    defaultDeterminationLetterId === defaultInternalFinalDeterminationLetterAttachmentId;
  const showingInternalSecondaryLetter =
    secondaryDeterminationLetterId === secondaryInternalFinalDeterminationLetterAttachmentId;
  const showingInternalTertiaryLetter =
    tertiaryInternalFinalDeterminationLetterAttachmentId === tertiaryDeterminationLetterId;

  const defaultLettersData: Array<DefaultLettersData> = [
    {
      letterId: internalFinalDeterminationFaxAttachmentId,
      showingInternalLetter: true,
      canViewLetter: true,
      shouldShow: Boolean(internalFinalDeterminationFaxAttachmentId),
      faxAttachment: internalFinalDeterminationFaxAttachment,
      isFax: true,
      isTat: false,
    },
    {
      letterId: defaultDeterminationLetterId,
      showingInternalLetter: showingInternalDefaultLetter,
      canViewLetter: canViewPatientFinalDeterminationLetter,
      internalLetter: defaultInternalFinalDeterminationLetterAttachment,
      externalLetter: defaultFinalDeterminationLetterAttachment,
      shouldShow: Boolean(canViewFinalDeterminationLetter && defaultDeterminationLetterId),
      isTat: false,
    },
    {
      letterId: secondaryDeterminationLetterId,
      showingInternalLetter: showingInternalSecondaryLetter,
      canViewLetter: canViewPatientFinalDeterminationLetter,
      internalLetter: secondaryInternalFinalDeterminationLetterAttachment,
      externalLetter: secondaryFinalDeterminationLetterAttachment,
      shouldShow: Boolean(
        canViewPatientFinalDeterminationLetter &&
          secondaryDeterminationLetterId &&
          secondaryDeterminationLetterId !== defaultDeterminationLetterId
      ),
      isTat: false,
    },
    {
      letterId: tertiaryDeterminationLetterId,
      showingInternalLetter: showingInternalTertiaryLetter,
      canViewLetter: canViewPatientFinalDeterminationLetter,
      internalLetter: tertiaryInternalFinalDeterminationLetterAttachment,
      externalLetter: undefined,
      shouldShow: Boolean(
        canViewFinalDeterminationLetter &&
          tertiaryDeterminationLetterId &&
          tertiaryDeterminationLetterId !== defaultDeterminationLetterId &&
          tertiaryDeterminationLetterId !== secondaryDeterminationLetterId
      ),
      isTat: false,
    },
    {
      letterId: patientTatExtensionLetter?.id,
      canViewLetter: false,
      shouldShow: Boolean(patientTatExtensionLetter?.id),
      tatAttachment: patientTatExtensionLetter,
      isTat: true,
    },
    {
      letterId: providerTatExtensionLetter?.id,
      canViewLetter: false,
      shouldShow: Boolean(providerTatExtensionLetter?.id),
      tatAttachment: providerTatExtensionLetter,
      isTat: true,
    },
    {
      letterId: pcpTatExtensionLetter?.id,
      canViewLetter: false,
      shouldShow: Boolean(pcpTatExtensionLetter?.id),
      tatAttachment: pcpTatExtensionLetter,
      isTat: true,
    },
  ];

  const DefaultLettersLogsDisplay = ({ defaultLettersData }: { defaultLettersData: DefaultLettersData[] }) => {
    const filteredData = defaultLettersData.filter((item) => item.shouldShow === true)?.slice(0, 12);
    return (
      <>
        {filteredData.map((item, idx) => {
          return (
            <PrintableStatusAlert
              key={`DefaultLettersLogsDisplay_${idx}`}
              serviceRequestId={serviceRequest.id}
              attachmentId={item.letterId}
              showingInternalLetter={item.showingInternalLetter}
              showingTatExtensionLetter={item.isTat}
              showingInternalFax={item.isFax}
              ButtonComponent={({ onClick }) => (
                <>
                  <MenuItem
                    onClick={(e) => {
                      onClick?.();
                      e.stopPropagation();
                    }}
                  >
                    <Grid>
                      {item.isTat && (
                        <>
                          <Body1 display="block">Due Date Extension Letter #{serviceRequest.cohereId}</Body1>
                          <Caption display="block">
                            {capitalizeFirstLetter(item.tatAttachment?.letterAddressee)} Facing
                          </Caption>
                        </>
                      )}

                      {!item.isTat && (
                        <>
                          <Body1 display="block">Decision letter #{serviceRequest.cohereId}</Body1>
                          <Caption display="block">
                            {letterFacing(item.canViewLetter, item.internalLetter, item.externalLetter, item.isFax)}
                          </Caption>
                        </>
                      )}
                    </Grid>
                  </MenuItem>
                  <Divider />
                </>
              )}
            />
          );
        })}
      </>
    );
  };

  const PreviousDeterminationLetterDisplay = ({
    internalFinalDeterminationLetterAttachments,
  }: {
    internalFinalDeterminationLetterAttachments?: InternalFinalDeterminationLetterAttachment[];
  }) => {
    const previousLetters = internalFinalDeterminationLetterAttachments
      ?.filter(
        (letter) =>
          letter.id !== defaultDeterminationLetterId &&
          letter.id !== secondaryDeterminationLetterId &&
          letter.id !== tertiaryDeterminationLetterId
      )
      .slice(0, 12);
    return (
      <>
        {previousLetters?.map((previousLetter, idx) => (
          <PrintableStatusAlert
            key={`PreviousDeterminationLetterDisplay_${idx}`}
            serviceRequestId={serviceRequest.id}
            attachmentId={previousLetter.id}
            showingInternalLetter={Boolean(showingInternalDefaultLetter)}
            ButtonComponent={({ onClick }) => (
              <>
                <MenuItem
                  onClick={(e) => {
                    onClick?.();
                    e.stopPropagation();
                  }}
                >
                  <Grid>
                    <Body1 display="block">Decision letter #{serviceRequest.cohereId}</Body1>
                    <Caption display="block">
                      {letterFacing(canViewPatientFinalDeterminationLetter, previousLetter)}
                    </Caption>
                  </Grid>
                </MenuItem>
                <Divider />
              </>
            )}
          />
        ))}
      </>
    );
  };

  const { data: serviceRequestNotifications } = useGetServiceRequestNotifications({
    serviceRequestId: serviceRequest.id,
  });

  const NotificationsDisplay = ({ notifications }: { notifications: NotificationResponse[] }) => {
    if (!notifications?.length) {
      return null;
    }
    const notificationsToDisplay = notifications.filter(
      // Welltok VAT Notifications haven't been set up yet.
      (notification): notification is Exclude<NotificationResponse, WelltokVATNotification> => {
        if (isWelltokVATNotification(notification)) {
          return false;
        }
        // Unlike Notification Viewer, we must have a file because this component directly prints it.
        return !!notification.pdfUrl;
      }
    );

    return (
      <>
        {notificationsToDisplay?.map((notification, idx) => {
          // These utility functions were originallly written for the Notification
          // Viewer, so the API responses need to be wrapped first to use them.
          const wrappedNotification = wrapNotificationResponse(notification, serviceRequest);
          if (!wrappedNotification) {
            return null;
          }
          let displayName = createDisplayName(wrappedNotification);
          if (isLettersExpansionNotification(wrappedNotification) && wrappedNotification.data?.dryRun === true) {
            displayName += " (dry run)";
          }
          const recipientName = getRecipientOfNotification(wrappedNotification);

          return (
            <PrintableStatusAlert
              key={`Notification_${idx}`}
              serviceRequestId={serviceRequest.id}
              attachmentId={notification.id}
              showingNotification={true}
              ButtonComponent={({ onClick }) => (
                <>
                  <MenuItem
                    onClick={(e) => {
                      onClick?.();
                      e.stopPropagation();
                    }}
                  >
                    <Grid>
                      <Body1 display="block">{displayName}</Body1>
                      <Caption display="block">{`${recipientName} Facing`}</Caption>
                    </Grid>
                  </MenuItem>
                  <Divider />
                </>
              )}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <DefaultLettersLogsDisplay defaultLettersData={defaultLettersData} />
      <PreviousDeterminationLetterDisplay
        internalFinalDeterminationLetterAttachments={serviceRequest?.internalFinalDeterminationLetterAttachments}
      />
      <NotificationsDisplay notifications={serviceRequestNotifications || []} />
      {onShowCallLog &&
        canViewFinalDeterminationLetter &&
        generateCallLogPdf &&
        vatNotification &&
        vatNotification.find((vn) => vn.successful) && (
          <>
            <MenuItem
              onClick={(e) => {
                if (vatNotification) {
                  onShowCallLog?.(
                    serviceRequest,
                    vatNotification.find((vn) => vn.successful),
                    generateCallLogPdf
                  );
                  handleClosePrintMenu();
                  handleCloseSubmenu?.(e);
                }
              }}
            >
              Final Determination call log #{serviceRequest.cohereId}
            </MenuItem>
            <Divider />
          </>
        )}
    </>
  );
};

const capitalizeFirstLetter = (x?: string | null) => {
  if (!!x?.length) {
    x = x.toLowerCase();
    return x[0].toUpperCase() + x.substring(1);
  }
  return null;
};

const letterFacing = (
  canViewLetter: boolean,
  internalLetter?: InternalFinalDeterminationLetterAttachment,
  externalLetter?: FinalDeterminationLetterAttachment,
  isFax?: boolean
) => {
  if (isFax) {
    return canViewLetter && "Requestor Facing";
  }
  if (internalLetter) {
    return canViewLetter && `${capitalizeFirstLetter(internalLetter.letterAddressee)} Facing`;
  } else if (externalLetter) {
    return canViewLetter && `${capitalizeFirstLetter(externalLetter.recipientType)} Facing`;
  } else {
    return "";
  }
};

export default ServiceRequestPrintMenuItems;
