import React, { useEffect, useContext, Dispatch, SetStateAction } from "react";
import { useConfiguration, useFeature } from "@coherehealth/common";
import {
  useCreateCarePathJourney,
  CarePathJourney,
  ServiceDeterminationProcedureCodeBucket,
  Patient,
  ServiceRequestResponse,
  AuthBuilderWorkflowStep,
} from "@coherehealth/core-platform-api";
import { PriorAuthRequirements, ServiceRequestFormStateSetters, formContentFromAuthBuilderContext } from "../common";
import { useSnackbar } from "notistack";
import { constructProcedureCodes } from "util/clinicalAssessment";
import Footer from "../Footer";
import { ServiceRequestFormContent } from "../../ServiceRequest";
import { SuggestionContext } from "../SuggestionContext";
import { getPatientHealthPlanName } from "../../../util/patientUtils";

interface Props extends ServiceRequestFormStateSetters {
  priorAuthRequirements: PriorAuthRequirements;
  buckets: ServiceDeterminationProcedureCodeBucket[];
  mostRecentlyUsedFaxNumber?: string;
  primaryCarePathId?: string;
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  carePathJourney?: CarePathJourney;
  setCarePathJourney: (journey: CarePathJourney | undefined) => void;
  patient?: Patient;
  importedEhrOrder?: ServiceRequestResponse;
  serviceRequestFormContents: ServiceRequestFormContent[];
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

export default function ServiceSelectionFooter({
  priorAuthRequirements,
  buckets,
  mostRecentlyUsedFaxNumber,
  primaryCarePathId,
  setWorkflowStep,
  carePathJourney,
  setCarePathJourney,
  patient,
  setServiceRequestFormContents,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  importedEhrOrder,
  serviceRequestFormContents,
  setFooterHeight,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");

  const {
    mutate: createCarePathJourney,
    loading: createCarePathJourneyLoading,
    error: createCarePathJourneyError,
  } = useCreateCarePathJourney({});

  useEffect(() => {
    if (createCarePathJourneyError) {
      enqueueSnackbar("Failed to create service requests, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [createCarePathJourneyError, enqueueSnackbar]);

  //continue button logic
  const numberOfBuckets = buckets?.length || 0;
  const canContinueToFillForms = numberOfBuckets > 0;

  const footerContinueText =
    "Continue" +
    (canContinueToFillForms ? " with " + numberOfBuckets + " procedure" + (numberOfBuckets !== 1 ? "s" : "") : "");

  const { suggestedFormContent } = useContext(SuggestionContext);
  const { blockUserIfLessThanRecommendedEndDate } =
    useConfiguration("patientStayRangesConfiguration", getPatientHealthPlanName(patient) || undefined) ?? {};
  const onContinueToFillForms = async () => {
    if (patient?.id) {
      let journey = carePathJourney;
      if (!journey) {
        journey = await createCarePathJourney({
          patient: patient.id || "",
          carePath: primaryCarePathId || "",
          primarySemanticDiagnosisCode: priorAuthRequirements?.primaryDiagnosis,
        });
        setCarePathJourney(journey);
      }
      const isInpatient = priorAuthRequirements.encounterType === "INPATIENT" ? true : false;
      setServiceRequestFormContents(
        buckets.map((bucket, index) => {
          const procedureCodes = constructProcedureCodes({
            procedureCodes: bucket.procedureCodes || [],
            isInpatient: isInpatient,
            serviceLevelUnits: bucket.clinicalService?.defaultUnits ? `${bucket.clinicalService?.defaultUnits}` : "1",
            clinicalServiceId: bucket?.clinicalService?.id || "",
            isUnitsOnPx: true,
          });
          return {
            ...serviceRequestFormContents[index],
            ...formContentFromAuthBuilderContext({
              priorAuthRequirements,
              patient,
              carePathJourney: journey,
              mostRecentlyUsedFaxNumber,
              simplifiedServiceFrequency,
              ...bucket,
              procedureCodes,
              importedEhrOrder,
              suggestedFormContent,
              useDefaultDurationAsMinimumEndDate: !!blockUserIfLessThanRecommendedEndDate,
            }),
          };
        })
      );
      setServiceRequestFormsCanBeSubmitted(Array(buckets.length).fill(false));
      setServiceRequestFormsHaveNewEdits(Array(buckets.length).fill(true));
      setWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
    } else {
      enqueueSnackbar("Cannot find patient information", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  return (
    <Footer
      onPrimaryButtonClick={onContinueToFillForms}
      primaryButtonText={footerContinueText}
      primaryButtonDisabled={!canContinueToFillForms || createCarePathJourneyLoading}
      primaryButtonLoading={createCarePathJourneyLoading}
      showSaveAndExitButton={false}
      setFooterHeight={setFooterHeight}
    />
  );
}
