import React, { useContext, useEffect, useCallback } from "react";
import {
  GuidelineType,
  ReviewType,
  Guideline,
  FilterMatchMap,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import {
  H5,
  OrderedPolicyTypes,
  Body3,
  Body2,
  Body1,
  Checkbox,
  MultiSelectDropdown,
  HighlightStateContext,
  useConfiguration,
  useFeature,
  canHaveCoverageLevelDetails,
} from "@coherehealth/common";
import CareWebQILink from "./CareWebQILink";
import { GuidelineMatchDisplay, GuidelineMatchDisplayReadonly } from "./GuidelineReview";
import { ClinicalAssessmentContext } from "components/ClinicalAssessment";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Divider, styled, useTheme, useMediaQuery } from "@material-ui/core";
import { RIGHT_HAND_PANEL_SIZE } from "components/ClinicalReview/reviewUtils/utils";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { error as logError } from "logger";
import { useReviewsDispatch } from "components/ClinicalReview/reviewUtils/useReviews";

interface GuidelineProps {
  orderedPolicyTypes: GuidelineType[];
  serviceRequestId?: string;
  healthPlanName?: string;
  review: ReviewType;
  noClinicalInformationProvided?: boolean;
  setNoClinicalInformationProvided?: (value: boolean) => void;
  selectedGuidelines: string[];
  setSelectedGuidelines: (guidelineIds: string[]) => void;
  openAttachment?: (attachmentId: string) => void;
  serviceRequest?: ServiceRequestResponse | null;
}
interface GuidelineMatch {
  guideline: Guideline;
  matchList: FilterMatchMap;
  policyTitle: string;
  guidelineTitle: string;
}
export default function SelectableGuidelines({
  orderedPolicyTypes,
  serviceRequestId,
  healthPlanName,
  review,
  noClinicalInformationProvided,
  setNoClinicalInformationProvided,
  selectedGuidelines,
  setSelectedGuidelines,
  openAttachment,
  serviceRequest,
}: GuidelineProps) {
  const theme = useTheme();
  const noClinicalInformationProvidedCheckboxEnabledFF = useFeature("noClinicalInformationProvidedCheckboxEnabled");
  const { noClinicalAssessmentAnswered } = useContext(ClinicalAssessmentContext);

  const reviewConfig = useConfiguration(
    "reviewConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );

  const noClinicalInformationProvidedCheckboxEnabled =
    noClinicalInformationProvidedCheckboxEnabledFF &&
    reviewConfig?.noClinicalInformationProvidedCheckbox?.enabled === true;

  const showNoClinicalInformationProvidedCheckbox =
    noClinicalInformationProvidedCheckboxEnabled &&
    !serviceRequest?.attachments?.length &&
    noClinicalAssessmentAnswered;

  const cwqiReviewGuidelinesDemoView = useFeature("cwqiReviewGuidelinesDemoView");
  const multiCoverageReviews = useFeature("multiCoverageReviews");
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const { linking, setSelectedGuidelineIds } = useContext(HighlightStateContext);
  const { isMultiCoverageReviewRequired } = useContext(ClinicalReviewContext);
  const guidelineMatches = orderedPolicyTypes.reduce((gl, policyType) => {
    const guidelinePolicyMap = review.guidelines?.find(
      (guidelinePolicyMap) => guidelinePolicyMap.policyType === policyType
    );
    return gl.concat(
      guidelinePolicyMap?.guidelines?.map((guideline) => {
        const filterMatchMap =
          guidelinePolicyMap?.guidelineMatchMap?.find((map) => map.guidelineId === guideline.id) || {};
        const policyType =
          filterMatchMap?.policyType === "PAYOR"
            ? healthPlanName
              ? `${healthPlanName} Guidelines`
              : "Humana Guidelines"
            : OrderedPolicyTypes[filterMatchMap!.policyType!];
        return {
          guideline: guideline,
          matchList: filterMatchMap,
          //regex used to enfore snake case
          policyTitle:
            policyType +
              " " +
              filterMatchMap?.policyTitle?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()) || "",
          guidelineTitle:
            policyType +
              " " +
              guideline.guidelineTitle?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()) || "",
        };
      }) || []
    );
  }, [] as GuidelineMatch[]);
  const options = guidelineMatches.map((guidelineMatch) => {
    return {
      id: guidelineMatch.guideline.id!,
      label: (
        <div style={{ maxWidth: matchesRight ? 600 : "calc(100vw - 840px)" }}>
          <Body2>{guidelineMatch.guidelineTitle}</Body2>
          {guidelineMatch.matchList?.procedureCodesMatch && guidelineMatch.matchList.procedureCodesMatch.length > 0 && (
            <CPTMatches>
              Matches CPT {guidelineMatch.matchList.procedureCodesMatch.map((cpt) => cpt.code).join(", ")}
            </CPTMatches>
          )}
        </div>
      ),
    };
  });

  useEffect(() => {
    if (selectedGuidelines && setSelectedGuidelineIds) {
      setSelectedGuidelineIds(selectedGuidelines);
    }
  }, [selectedGuidelines, setSelectedGuidelineIds]);

  return (
    <div key={noClinicalInformationProvided ? "readonly" : "editable"} style={linking ? { marginBottom: "8px" } : {}}>
      {!linking && (
        <>
          <SelectableRow style={{ marginBottom: theme.spacing(1) }}>
            <H5 style={{ paddingTop: "2px" }}>Available guidelines</H5>
            {serviceRequestId && !cwqiReviewGuidelinesDemoView && (
              <CareWebQILink hasInAppGuidelines={true} serviceRequestId={serviceRequestId} review={review} />
            )}
          </SelectableRow>
          {multiCoverageReviews && isMultiCoverageReviewRequired ? (
            <MultiCoverageSelectableGuidelineComponent
              options={options}
              selectedGuidelines={selectedGuidelines}
              setSelectedGuidelines={setSelectedGuidelines}
              review={review}
            />
          ) : (
            <SingleCoverageSelectableGuidelineComponent
              options={options}
              selectedGuidelines={selectedGuidelines}
              setSelectedGuidelines={setSelectedGuidelines}
            />
          )}
          {showNoClinicalInformationProvidedCheckbox && (
            <Checkbox
              label={"No clinical information provided"}
              data-test-id="no-clinical-information-provided-checkbox"
              key="noClinicalInformationProvided"
              checked={noClinicalInformationProvided}
              onChange={(checked) => {
                setNoClinicalInformationProvided?.(checked);
              }}
            />
          )}
          <SelectableRow>
            <Selected>Selected guidelines</Selected>
          </SelectableRow>
        </>
      )}
      {selectedGuidelines.length === 0 ? (
        <SelectableRow>
          <NoGuidelines>There are no guidelines selected.</NoGuidelines>
        </SelectableRow>
      ) : (
        <>
          {guidelineMatches
            .filter((guidelineMatch) => selectedGuidelines.includes(guidelineMatch.guideline.id || ""))
            .map((guidelineMatch, idx) => {
              const guidelinePolicyMap = review.guidelines?.find(
                (guidelinePolicyMap) => guidelinePolicyMap.policyType === guidelineMatch.matchList?.policyType
              );
              return (
                <React.Fragment key={guidelineMatch.guideline.id}>
                  {idx !== 0 && <GuidelineDivider />}
                  {noClinicalInformationProvided ? (
                    <GuidelineMatchDisplayReadonly
                      guideline={guidelineMatch.guideline}
                      matchList={guidelinePolicyMap?.guidelineMatchMap || []}
                      review={review}
                      checkedIndications={guidelinePolicyMap?.checkedIndications || []}
                      unmetIndications={guidelinePolicyMap?.unmetIndications || []}
                      expandable
                      serviceRequest={serviceRequest}
                    />
                  ) : (
                    <GuidelineMatchDisplay
                      guideline={guidelineMatch.guideline}
                      matchList={guidelinePolicyMap?.guidelineMatchMap || []}
                      checkedIndications={guidelinePolicyMap?.checkedIndications || []}
                      unmetIndications={guidelinePolicyMap?.unmetIndications || []}
                      review={review}
                      expandable
                      policyTitle={guidelineMatch.policyTitle}
                      openAttachment={openAttachment}
                      selectableGuidelines
                      multiCoverageReviewsEnabled={multiCoverageReviews}
                      serviceRequest={serviceRequest}
                    />
                  )}
                </React.Fragment>
              );
            })}
        </>
      )}
    </div>
  );
}

interface SelectableGuidelineOption {
  id: string;
  label: JSX.Element;
}

interface SingleCoverageSelectableGuidelineComponentProps {
  options: SelectableGuidelineOption[];
  selectedGuidelines: string[];
  setSelectedGuidelines: (guidelineIds: string[]) => void;
}

const SingleCoverageSelectableGuidelineComponent = ({
  options,
  selectedGuidelines,
  setSelectedGuidelines,
}: SingleCoverageSelectableGuidelineComponentProps) => {
  return (
    <SelectableRow>
      <MultiSelectDropdown
        style={{ marginTop: "2px" }}
        fullWidth
        omitNumberSelectedInLabel
        label="Select guidelines to use"
        options={options}
        selectedValues={selectedGuidelines}
        onChange={setSelectedGuidelines}
        renderSingleSelection={
          <Body1>{`${selectedGuidelines.length} guideline${selectedGuidelines.length > 1 ? "s" : ""} selected`}</Body1>
        }
        data-testid={"guideline-select-dropdown"}
        maxMenuHeight="calc(100vh - 245px)"
      />
    </SelectableRow>
  );
};

interface MultiCoverageSelectableGuidelineComponentProps {
  options: SelectableGuidelineOption[];
  selectedGuidelines: string[];
  setSelectedGuidelines: (guidelineIds: string[]) => void;
  review: ReviewType;
}

const MultiCoverageSelectableGuidelineComponent = ({
  options,
  selectedGuidelines,
  setSelectedGuidelines,
  review,
}: MultiCoverageSelectableGuidelineComponentProps) => {
  const reviewsDispatch = useReviewsDispatch();

  const onToggleGuidelineForCoverage = useCallback(
    (guidelineIds: string[], coverageIndex: number) => {
      reviewsDispatch?.({
        type: "TOGGLE_GUIDELINE_FOR_COVERAGE",
        reviewId: review.id,
        payload: {
          guidelineIds,
          coverageIndex,
        },
      });
    },
    [review.id, reviewsDispatch]
  );

  if (!canHaveCoverageLevelDetails(review)) {
    logError(`Unexpected review type ${review.reviewType} in toggleGuidelineForCoverage`);
    return null;
  }

  if (!review.coverageLevelDetails) {
    logError(`Unexpected unfilled coverageLevelDetails in toggleGuidelineForCoverage`);
    return null;
  }

  return (
    <>
      {review.coverageLevelDetails.map((coverageLevelDetail, index) => (
        <SelectableRow key={`${coverageLevelDetail?.coverage?.productId} - ${index}`} style={{ paddingBottom: 8 }}>
          <MultiSelectDropdown
            style={{ marginTop: "2px" }}
            fullWidth
            omitNumberSelectedInLabel
            label={`Select guidelines to use for ${review.coverageLevelDetails?.[index].coverage?.lineOfBusinessType}`}
            options={options}
            selectedValues={review.coverageLevelDetails?.[index].selectedGuidelineIds}
            onChange={(selections) => onToggleGuidelineForCoverage(selections, index)}
            renderSingleSelection={
              <Body1>{`${review.coverageLevelDetails?.[index].selectedGuidelineIds?.length} guideline${
                selectedGuidelines.length > 1 ? "s" : ""
              } selected`}</Body1>
            }
            data-testid={"guideline-select-dropdown"}
            maxMenuHeight="calc(100vh - 245px)"
          />
        </SelectableRow>
      ))}
    </>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SelectableRow = styled("div")(({ theme }) => ({
  justifyContent: "space-between",
  display: "flex",
  alignItems: "center",
  marginLeft: -theme.spacing(1),
  marginRight: theme.spacing(1),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const Selected = styled(H5)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const GuidelineDivider = styled(Divider)(({ theme }) => ({
  marginLeft: -theme.spacing(1),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const CPTMatches = styled(Body3)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.text.secondary,
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const NoGuidelines = styled(Body1)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  color: theme.palette.text.secondary,
}));
