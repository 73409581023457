import {
  CopyToClipboard,
  H2,
  Modal,
  ModalHeader,
  PrimaryButton,
  queueMgmtBaseUrl,
  SecondaryButton,
  useFeature,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import { AuthStatus, ContactType, OutreachAttempt, ServiceRequestResponse } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { useTheme, Tab, Grid, styled, useMediaQuery, Theme, makeStyles } from "@material-ui/core";
import { StyledTabs } from "components/ClinicalReview/Review/ClinicalReviewPage";
import ChangeStatusForm from "components/ServiceRequestStatusDisplay/ChangeStatusForm";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import routes from "routes";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import { determinePostDenialP2PEligibility, isFinallyDeterminedUtility } from "util/serviceRequest";
import OutreachForm from "./OutreachForm";
import useOutreachAttempt from "components/ServiceRequest/ReviewSection/useOutreachAttempt";
import { getReviewOutcomeForOutreachCase, getPhoneNumberForOutreachCase } from "./outreachUtil";
import { RIGHT_HAND_PANEL_SIZE, tabProps } from "components/ClinicalReview/reviewUtils/utils";
import { useSnackbar } from "notistack";
import { redirectToPatientSummaryFromReview, routeBackToOriginFromReview } from "util/routeUtils/routeUtils";
import { useServiceCaseForReview } from "components/ServiceRequest/ReviewSection/util/useReviewRouting";

interface Props {
  serviceRequest: ServiceRequestResponse;
  outreachAttempts: string | null;
}

const LogOutreachPage = ({ serviceRequest, outreachAttempts }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const isFinallyDetermined = isFinallyDeterminedUtility(serviceRequest.authStatus);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [useEnhancedWithdrawalModal, setUseEnhancedWithdrawalModal] = useState<boolean>(false);
  const [reviewSubmissionLoading, setReviewSubmissionLoading] = useState<boolean>(false);
  const [search] = useSearchParams();
  const type = search.get("type");
  const queueId = search.get("queueId");
  const initialContactType: ContactType = !type || type === "outbound" ? "OUTBOUND" : "INBOUND";
  const initialOutreachAtempts = outreachAttempts ? parseInt(outreachAttempts) : undefined;
  const defaultOutreachOverride = { contactType: initialContactType, attemptNumber: initialOutreachAtempts };
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    outreachAttempt,
    setOutreachAttempt,
    saveOutreachAttempt,
    outreachAttemptLoading,
    outreachFormConfig,
    outreachFormErrors,
    setOutreachFormErrors,
    validateOutreachForm,
  } = useOutreachAttempt({
    serviceRequest,
    defaultOutreachOverride,
  });
  const { currentCase } = useServiceCaseForReview(serviceRequest);
  const shouldUseAppendedRoute = useFeature("shouldUseAppendedRoute");
  const caseId = shouldUseAppendedRoute && currentCase?.id ? currentCase?.id : search.get("caseId") || "";
  const referredByQM = caseId && document.referrer.includes(caseId);

  const {
    mutate: completeServiceCase,
    loading: completServiceCaseLoading,
    error: completeServiceCaseError,
  } = useCompleteServiceCase({
    id: caseId,
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () =>
      shouldUseAppendedRoute && currentCase?.id
        ? routeBackToOriginFromReview(Boolean(referredByQM), currentCase?.id, null, serviceRequest, navigate)
        : window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`),
  });
  const postDenialWorkflowEnabled = useFeature("postDenialWorkflow");
  const postDecisionP2PExpanded = useFeature("postDecisionP2PExpanded");
  const isEligibleForPostDenialP2P = postDenialWorkflowEnabled
    ? determinePostDenialP2PEligibility({
        currentAuthStatus: serviceRequest.authStatus,
        initialDecisionDisposition: serviceRequest.initialDecisionDisposition,
        revisedDecisionTimestamp: serviceRequest.revisedDecisionTimestamp,
        postDecisionP2PExpanded,
      })
    : false;

  const { refetch: serviceRequestRefetch } = useGetServiceRequestByIdWithFallback({
    id: serviceRequest?.id || "none",
    lazy: true,
  });
  useEffect(() => {
    if (completeServiceCaseError) {
      enqueueSnackbar(`Failed to complete Service Case: ${caseId}`, { variant: "error" });
    }
  }, [caseId, completeServiceCaseError, enqueueSnackbar]);

  const onSubmit = useCallback(
    async (postedOutreachAttempt: OutreachAttempt | undefined) => {
      setReviewSubmissionLoading(true);
      const updatedServiceRequest = await serviceRequestRefetch();

      if (caseId || (currentCase?.id && shouldUseAppendedRoute)) {
        await completeServiceCase({
          reviewOutcome: getReviewOutcomeForOutreachCase(
            updatedServiceRequest?.authStatus,
            updatedServiceRequest?.pendingReason
          ),
          dateCompleted: new Date().toISOString(),
          outreachCaseOutcome: {
            outreachAttemptId: postedOutreachAttempt?.id || "",
            outreachNote: outreachAttempt.outreachNotes,
            contactType: outreachAttempt.contactType,
            outreachType: outreachAttempt.outreachType,
            outreachChannel: outreachAttempt.channel,
            phoneNumber: getPhoneNumberForOutreachCase(
              outreachAttempt.providerPhoneNumber,
              outreachAttempt.memberPhoneNumber
            ),
            outreachOutcome: outreachAttempt.outreachOutcome,
          },
        });
        setReviewSubmissionLoading(false);
      } else {
        setReviewSubmissionLoading(false);
        redirectToPatientSummaryFromReview({ serviceRequest, navigate });
      }
    },
    [
      serviceRequestRefetch,
      caseId,
      currentCase?.id,
      shouldUseAppendedRoute,
      completeServiceCase,
      outreachAttempt.outreachNotes,
      outreachAttempt.contactType,
      outreachAttempt.outreachType,
      outreachAttempt.channel,
      outreachAttempt.providerPhoneNumber,
      outreachAttempt.memberPhoneNumber,
      outreachAttempt.outreachOutcome,
      serviceRequest,
      navigate,
    ]
  );

  const validateAndSubmit = async () => {
    const formIsValid = validateOutreachForm();
    if (formIsValid) {
      if (!isFinallyDetermined || isEligibleForPostDenialP2P) {
        setOpenModal(true);
      } else {
        const postedOutreachAttempt = await saveOutreachAttempt();
        await onSubmit(postedOutreachAttempt);
      }
    }
  };

  const getMaxWidth = (): "lg" | "md" => {
    if (useEnhancedWithdrawalModal) {
      return "lg";
    }
    return "md";
  };

  const finishOutreachAttemptLoading = reviewSubmissionLoading || outreachAttemptLoading || completServiceCaseLoading;

  return (
    <>
      <StyledTabs
        value={"LOG_NEW_CONTACT"}
        aria-label="guideline tabs"
        style={{ margin: "0px -24px", position: "sticky", top: 0, backgroundColor: "white", zIndex: 2 }}
      >
        <Tab
          style={{ width: "250px" }}
          label={"Log New Contact"}
          {...tabProps("LOG_NEW_CONTACT")}
          id="log-new-contact-tab"
          className={classes.panelTab}
        />
      </StyledTabs>
      <OutreachForm
        serviceRequest={serviceRequest}
        outreachAttempt={outreachAttempt}
        setOutreachAttempt={setOutreachAttempt}
        displayFieldConfig={outreachFormConfig}
        errorStates={outreachFormErrors}
        setErrorStates={setOutreachFormErrors}
      />
      <CopyToClipboard
        text={getP2PReviewLink({
          serviceRequestId: serviceRequest.id,
          authStatus: serviceRequest.authStatus,
          caseId: caseId,
          queueId: queueId,
        })}
        message={"Copy P2P review link"}
        style={{
          paddingLeft: "24px",
          marginBottom: "30px",
        }}
        onClick={() => {
          enqueueSnackbar("Copied to clipboard", { variant: "success" });
        }}
      />
      <NavClear />
      <NavigationControl item xs={12} container alignItems="center" justifyContent="center" matchesRight={matchesRight}>
        <SecondaryButton
          warning
          style={{ width: theme.spacing(32.25), marginRight: theme.spacing(2), height: theme.spacing(6) }}
          onClick={() => {
            redirectToPatientSummaryFromReview({
              serviceRequest,
              caseId,
              navigate,
            });
          }}
          disabled={finishOutreachAttemptLoading}
        >
          Discard
        </SecondaryButton>
        <PrimaryButton
          onClick={validateAndSubmit}
          style={{ width: theme.spacing(32.25), height: theme.spacing(6) }}
          loading={finishOutreachAttemptLoading}
          disabled={finishOutreachAttemptLoading}
        >
          Save
        </PrimaryButton>
      </NavigationControl>
      <Modal
        open={openModal}
        scroll="body"
        fullWidth
        maxWidth={getMaxWidth()}
        onClose={() => setOpenModal(false)}
        className={classes.root}
      >
        <ModalHeader>
          <H2>Change service status</H2>
        </ModalHeader>
        <ChangeStatusForm
          onEdit={(postedOutreachAttempt: OutreachAttempt | undefined) => {
            setOpenModal(false);
            onSubmit(postedOutreachAttempt);
          }}
          saveOutreachAttempt={saveOutreachAttempt}
          onCancel={() => setOpenModal(false)}
          authServiceRequests={serviceRequest.authorization?.serviceRequestsOnAuth || [serviceRequest]}
          outreachAttemptLoading={finishOutreachAttemptLoading}
          setUseEnhancedWithdrawalModal={setUseEnhancedWithdrawalModal}
        />
      </Modal>
    </>
  );
};

// Takeup space behind footer so content is not hidden
// eslint-disable-next-line cohere-react/no-mui-styled-import
const NavClear = styled((props) => <div {...props} />)(({ theme }) => ({
  clear: "both",
  height: theme.spacing(9),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NavigationControl = styled(({ matchesRight, ...other }) => <Grid {...other} />)<
  Theme,
  { matchesRight?: boolean }
>(({ theme, matchesRight }) => ({
  position: "fixed",
  height: theme.spacing(9),
  bottom: "0px",
  right: "0px",
  boxShadow: "0px -5px 10px -5px rgba(0, 0, 0, 0.2)",
  backgroundColor: theme.palette.background.paper,
  width: matchesRight ? 712 : "calc(100vw - 728px)",
  zIndex: 10,
}));

interface GetP2PReviewLinkProps {
  serviceRequestId: string;
  authStatus?: AuthStatus;
  caseId?: string | null;
  queueId?: string | null;
}

const useStyles = makeStyles(() => ({
  panelTab: {
    "&:hover span": {
      opacity: 0.7,
    },
  },
  root: {
    "& .MuiDialog-paperWidthLg": {
      maxWidth: 944,
      minWidth: 944,
    },
  },
}));

export const getP2PReviewLink = ({ serviceRequestId, authStatus, caseId, queueId }: GetP2PReviewLinkProps) => {
  let originalP2PReviewLink =
    window.location.origin + generatePath(routes.CREATE_P2P_REIVEW_PAGE, { serviceRequestId });

  if (caseId) {
    originalP2PReviewLink = originalP2PReviewLink.concat(`&caseId=${caseId}`);
  }
  if (queueId) {
    originalP2PReviewLink = originalP2PReviewLink.concat(`&queueId=${queueId}`);
  }

  switch (authStatus) {
    case "DENIED":
    case "POST_DENIAL_PEER_TO_PEER":
      return originalP2PReviewLink.concat(`&isPostDenialP2P`);
    default:
      return originalP2PReviewLink;
  }
};

export default LogOutreachPage;
