import { H2, InformativeModal, InlineButton, Subtitle2 } from "@coherehealth/common";
import { NonPalReasons } from "@coherehealth/core-platform-api";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { Error, OpenInNew } from "@material-ui/icons";
import { Dispatch, SetStateAction, useCallback } from "react";

export interface NonPalReasonModalProps {
  open: boolean;
  onClose?: () => void;
  availableReasons?: NonPalReasons[];
  showLearnMoreButton?: boolean;
  selectedNonPalReason?: string;
  setSelectedNonPalReason?: Dispatch<SetStateAction<string | undefined>>;
  handleSubmitNonPalReason: (reason: string) => void;
  setOpenNonPalConfirmationModal?: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.warning.dark,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
  },
  learnMoreButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  radioContainer: {
    textAlign: "start",
    "& p": {
      color: theme.palette.text.secondary,
    },
  },
  boldText: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

export function NonPalReasonModal({
  showLearnMoreButton,
  availableReasons = [],
  selectedNonPalReason,
  setSelectedNonPalReason,
  handleSubmitNonPalReason,
  setOpenNonPalConfirmationModal,
  ...props
}: NonPalReasonModalProps) {
  const classes = useStyles();

  const handleSubmit = useCallback(() => {
    if (setOpenNonPalConfirmationModal) {
      setOpenNonPalConfirmationModal(false);
    }

    handleSubmitNonPalReason(selectedNonPalReason ?? "");
  }, [selectedNonPalReason, setOpenNonPalConfirmationModal, handleSubmitNonPalReason]);

  const handleModalClose = useCallback(() => {
    if (setOpenNonPalConfirmationModal) {
      setOpenNonPalConfirmationModal(false);
    }

    setSelectedNonPalReason?.("");
  }, [setOpenNonPalConfirmationModal, setSelectedNonPalReason]);

  return (
    <InformativeModal
      {...props}
      headerText={
        <div className={classes.header}>
          <H2>Please read the following before submitting this care:</H2>
          <Subtitle2 color="textSecondary">This code must meet one of the following criteria.</Subtitle2>
        </div>
      }
      subHeaderElement={
        <div className={classes.subHeader}>
          <RadioGroup
            value={selectedNonPalReason}
            onChange={(event) => {
              setSelectedNonPalReason?.(event.target.value);
            }}
          >
            {availableReasons.map((availableReason) => (
              <div className={classes.radioContainer}>
                <FormControlLabel
                  value={availableReason.title}
                  control={<Radio color="primary" />}
                  label={
                    <>
                      {!availableReason.description && <p className={classes.boldText}>{availableReason.title}</p>}
                      {availableReason.description && (
                        <p>
                          <span className={classes.boldText}>{availableReason.title}: </span>
                          {availableReason.description}
                        </p>
                      )}
                    </>
                  }
                />
              </div>
            ))}
          </RadioGroup>
          {showLearnMoreButton && (
            <InlineButton
              startIcon={<OpenInNew />}
              onClick={() => {
                window.open("https://coherehealth.zendesk.com/hc/en-us/articles/14198266768279");
              }}
              className={classes.learnMoreButton}
            >
              Learn more
            </InlineButton>
          )}
        </div>
      }
      showDivider={false}
      primaryButtonText={`Include it (request will pend for review)`}
      primaryButtonDisabled={!selectedNonPalReason}
      tertiaryButtonText={`Cancel`}
      primaryButtonAction={handleSubmit}
      tertiaryButtonAction={handleModalClose}
      icon={<Error className={classes.errorIcon} />}
      iconSize="48px"
    />
  );
}
