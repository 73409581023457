import { useAuthorized } from "authorization";

export const useReviewCorrectionActions = () => {
  const canCorrectAuthStatus = useAuthorized("CORRECT_AUTH_STATUS");
  const canEditReviewSoftDelete = useAuthorized("SEND_LETTER_REVIEW_CORRECTION");

  const getCorrectionActionOptions = () => {
    return [
      ...(canCorrectAuthStatus
        ? [
            { id: "MOVE_FORWARD", label: "Move forward" },
            { id: "MOVE_BACKWARD", label: "Move backward" },
          ]
        : []),
      ...(canEditReviewSoftDelete ? [{ id: "SEND_LETTERS", label: "Send letters" }] : []),
    ];
  };
  return {
    canCorrectAuthStatus,
    canEditReviewSoftDelete,
    getCorrectionActionOptions,
  };
};
