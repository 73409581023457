import { Body1, Caption, colorsLight, parseDateFromISOString } from "@coherehealth/common";
import { Patient, ReferralRequestResponse, useGetPatient } from "@coherehealth/core-platform-api";
import { Grid, GridSize, makeStyles } from "@material-ui/core";
import { fieldIsValid } from "common/FormConfigUtils";
import { ReferralFormConfiguration } from "components/ReferralManagement/FormContentSpecification/ReferralRequestFormContentSpecifications";
import { ReferralRequestFormContent } from "components/ReferralManagement/RequestBuilder/ReferralRequestForm";
import { useSnackbar } from "notistack";
import { PropsWithChildren, useEffect } from "react";

export type ReferralRequestSummaryField =
  | "DATES_VALID"
  | "PRIMARY_DIAGNOSIS"
  | "SECONDARY_DIAGNOSIS"
  | "REFERRING_PROVIDER"
  | "SPECIALTY"
  | "SPECIALIST_PRACTICE"
  | "SPECIALIST";

export const getDetailFields = (): ReferralRequestSummaryField[] => {
  return [
    "DATES_VALID",
    "PRIMARY_DIAGNOSIS",
    "SECONDARY_DIAGNOSIS",
    "REFERRING_PROVIDER",
    "SPECIALTY",
    "SPECIALIST_PRACTICE",
    "SPECIALIST",
  ];
};

export function rrFormContentFromResponse(referralRequest: ReferralRequestResponse): ReferralRequestFormContent {
  return {
    id: referralRequest?.id,
    startDate: referralRequest?.startDate ? parseDateFromISOString(referralRequest.startDate) : undefined,
    cohereId: referralRequest?.cohereId,
    primarySemanticDiagnosisCode: referralRequest?.primarySemanticDiagnosisCode || null,
    secondarySemanticDiagnosisCodes: referralRequest?.secondarySemanticDiagnosisCodes || [],
    selectedReferringProvider: referralRequest?.selectedReferringProvider || null,
    referringProviderSelectedAddress:
      referralRequest?.referringProviderSelectedLocation ||
      referralRequest?.selectedReferringProvider?.selectedLocation,
    referringProviderSelectedTin:
      referralRequest?.referringProviderSelectedTin ||
      referralRequest?.selectedReferringProvider?.selectedLocation?.tin,
    selectedFacility: referralRequest?.selectedFacility || null,
    facilitySelectedAddress:
      referralRequest?.facilitySelectedLocation || referralRequest?.selectedFacility?.selectedLocation,
    facilitySelectedTin:
      referralRequest?.facilitySelectedTin || referralRequest?.selectedFacility?.selectedLocation?.tin,
    selectedPerformingSpecialist: referralRequest?.selectedPerformingSpecialist || null,
    performingSpecialistSelectedAddress:
      referralRequest?.performingSpecialistSelectedLocation ||
      referralRequest?.selectedPerformingSpecialist?.selectedLocation,
    performingSpecialistSelectedTin:
      referralRequest?.performingSpecialistSelectedTin ||
      referralRequest?.selectedPerformingSpecialist?.selectedLocation?.tin,
    specialty: referralRequest?.specialty,
    referralStatus: referralRequest?.referralStatus,
  };
}

export const defaultFormContent: ReferralRequestFormContent = {
  id: undefined,
  startDate: undefined,
  cohereId: undefined,
  primarySemanticDiagnosisCode: null,
  secondarySemanticDiagnosisCodes: [],
  selectedReferringProvider: null,
  referringProviderSelectedAddress: null,
  referringProviderSelectedTin: null,
  selectedFacility: null,
  facilitySelectedAddress: null,
  facilitySelectedTin: null,
  specialty: undefined,
  selectedPerformingSpecialist: null,
  performingSpecialistSelectedAddress: null,
  performingSpecialistSelectedTin: null,
  referralStatus: "DRAFT",
};

export const validatePerformingSpecialistAddress = (
  rrFormConfiguration: ReferralFormConfiguration,
  rrFormContent: ReferralRequestFormContent,
  hasPerformingSpecialistAddress: boolean
) => {
  if (validatePerformingSpecialistSharedConditions(rrFormConfiguration, rrFormContent)) {
    return true;
  }
  if (rrFormConfiguration.performingSpecialistAddress.fieldSpec === "OMIT") {
    return true;
  }
  if (rrFormContent.selectedPerformingSpecialist) {
    if (
      rrFormContent.performingSpecialistSelectedAddress?.address ||
      rrFormContent.selectedPerformingSpecialist.selectedLocation?.address
    ) {
      return fieldIsValid(rrFormConfiguration.performingSpecialistAddress, hasPerformingSpecialistAddress);
    }
    return false;
  }
  return fieldIsValid(rrFormConfiguration.performingSpecialistAddress, hasPerformingSpecialistAddress);
};

export const validatePerformingSpecialistTin = (
  rrFormConfiguration: ReferralFormConfiguration,
  rrFormContent: ReferralRequestFormContent,
  performingSpecialistTinLength: number | undefined,
  hasPerformingSpecialistTin: boolean
) => {
  if (validatePerformingSpecialistSharedConditions(rrFormConfiguration, rrFormContent)) {
    return true;
  }
  if (!performingSpecialistTinLength) {
    return true;
  }
  if (!!rrFormConfiguration.selectedPerformingSpecialist && performingSpecialistTinLength > 0) {
    return !!rrFormConfiguration.performingSpecialistTIN;
  }
  return fieldIsValid(rrFormConfiguration.performingSpecialistTIN, hasPerformingSpecialistTin);
};

export const validatePerformingSpecialistNPI = (
  rrFormConfiguration: ReferralFormConfiguration,
  rrFormContent: ReferralRequestFormContent,
  hasPerformingSpecialistNpi: boolean
) => {
  if (validatePerformingSpecialistSharedConditions(rrFormConfiguration, rrFormContent)) {
    return true;
  }
  return fieldIsValid(rrFormConfiguration?.performingSpecialistNPI, hasPerformingSpecialistNpi);
};

const validatePerformingSpecialistSharedConditions = (
  rrFormConfiguration: ReferralFormConfiguration,
  rrFormContent: ReferralRequestFormContent
) => {
  if (rrFormConfiguration.selectedPerformingSpecialist.fieldSpec === "OMIT") {
    return true;
  }
  if (
    rrFormConfiguration.selectedPerformingSpecialist.fieldSpec === "OPTIONAL" &&
    !!rrFormContent.selectedPerformingSpecialist
  ) {
    return true;
  }
  return false;
};

/*
  Created a custom hook which returns referralEligibility field for a patient today
  Return of this hook will be same as that of useGetPatient()
*/
export function useGetPatientWithReferralEligibility(patientId: string): Patient | null {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: patient,
    loading: loadingPatient,
    error,
  } = useGetPatient({
    id: patientId || "",
    queryParams: { includeReferralEligibility: true },
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Failed to fetch the patient with id: ${patientId}`, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, error, loadingPatient, patientId]);

  return patientId === "" ? null : patient;
}

export const useDetailsFieldStyles = makeStyles((theme) => ({
  grid: {
    wordWrap: "break-word",
    "&.btnLayout": {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    "&.specialistContainerLayout": {
      paddingBottom: theme.spacing(0),
    },
    display: "flex",
  },
  initialContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flex: "1 0 230px",
    flexWrap: "wrap",
  },
  subContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: theme.spacing(93),
    gap: theme.spacing(1.5),
    flexWrap: "wrap",
  },
  exceptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.75),
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  labelContainer: {
    color: theme.palette.text.secondary,
    display: "inline-block",
  },
  captionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    maxWidth: "200px",
    minWidth: "65px",
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  cardHeader: {
    paddingBottom: theme.spacing(3),
  },
  viewInfoButton: {
    marginTop: theme.spacing(0.125),
    height: theme.spacing(2),
  },
  footerText: {
    paddingTop: theme.spacing(0.125),
    color: colorsLight.font.light,
  },
  outOfNetworkExceptionReasonStyle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary,
    display: "inline-block",
  },
  bodyChildrenContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  viewContactInfo: {
    padding: 0,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  LocationNetworkStatusPill: {
    padding: "0 !important",
  },
}));

export const DetailsField = ({
  label,
  value,
  xs = 12,
  children,
  subTextChildren,
  bodyChildren,
  style,
}: PropsWithChildren<{
  label: string;
  value?: string;
  xs?: GridSize;
  subTextChildren?: JSX.Element;
  bodyChildren?: JSX.Element;
  style?: React.CSSProperties;
}>) => {
  const classes = useDetailsFieldStyles();

  return (
    <Grid
      item
      xs={xs}
      className={`${classes.grid}${" btnLayout "}${label === "Specialist" ? "specialistContainerLayout" : ""}`}
      style={{ paddingLeft: 0, ...style }}
    >
      <Grid item xs={12} className={classes.initialContainer}>
        <Grid item xs={4} className={classes.captionContainer}>
          <Caption className={classes.labelContainer} data-public>
            {label}
          </Caption>
        </Grid>
        <Grid item xs={8} className={classes.container}>
          <div className={classes.subContainer}>
            {value && <Body1 data-public>{value || "--"}</Body1>}
            {bodyChildren}
            {children}
          </div>
          <div className={classes.exceptionContainer}>{subTextChildren}</div>
        </Grid>
      </Grid>
    </Grid>
  );
};
