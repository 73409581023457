import { useEffect, useState, Dispatch } from "react";
import { Divider, Box, CircularProgress } from "@material-ui/core";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import {
  PrimaryButton,
  SecondaryButton,
  queueMgmtBaseUrl,
  ReassignModal,
  UnassignModal,
  useLazyLoadingQueryOptionsHook,
  SelectOptionsHook,
  getCaseRedirectUrl,
} from "@coherehealth/common";
import FaxMetaDataDisplay from "./FaxMetaDataDisplay";
import { CommonFaxSidebarViewProps } from "./common";
import {
  useUpdateServiceCaseAssignee,
  User as QMAgent,
  UseSearchUserProps,
  useSearchUser,
  ServiceCase,
  useGetQueueById,
} from "@coherehealth/qm-api";
import config from "api/config";
import { useSnackbar } from "notistack";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { useGetUser } from "components/ClinicalReview/reviewUtils/utils";
import SupervisorQueueView from "components/ClinicalReview/ViewOnlyReview/SupervisorQueueView";
import { useSearchParams } from "react-router-dom";

export const useQueueSidebarStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(4, 0),
  },
  actionHeader: {
    paddingBottom: theme.spacing(2),
  },
  doubleControlBtns: {
    width: "268px",
    height: "48px",
  },
  singleControlBtn: {
    width: "100%",
    height: "48px",
  },
}));

interface QueueSupervisorProps extends Partial<CommonFaxSidebarViewProps> {
  setServiceCase: Dispatch<ServiceCase>;
  serviceCaseLoading: boolean;
  serviceRequest?: ServiceRequestResponse | null;
  isReviewQueue?: boolean;
  queueId?: string;
}

export default function QueueSupervisorSidebar({
  serviceRequest,
  fileData,
  url,
  serviceCase,
  setServiceCase,
  serviceCaseLoading,
  isReviewQueue,
  queueId,
}: QueueSupervisorProps) {
  const classes = useQueueSidebarStyles();
  const { enqueueSnackbar } = useSnackbar();
  const user = useGetUser();
  const [selectedAgent, setSelectedAgent] = useState<QMAgent | null>(null);
  const [reRoute, setReRoute] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [openUnassignModal, setOpenUnassignModal] = useState(false);
  const [isQueueView, setIsQueueView] = useState(!!isReviewQueue);
  const disableCaseChanges = serviceCase?.caseStatus === "COMPLETE" || serviceCase?.caseStatus === "CANCELLED";
  const [searchParams] = useSearchParams();
  const urlQueueId = searchParams.get("queueId");
  const queueIdWithFallBack = urlQueueId || queueId || "";

  const { data: currentQueue, refetch: fetchCurrentQueue } = useGetQueueById({
    queueId: queueIdWithFallBack,
    lazy: true,
    base: `${config.QM_SERVICE_API_URL}`,
  });

  useEffect(() => {
    setIsQueueView(!!isReviewQueue);
  }, [isReviewQueue]);

  const {
    mutate: updateServiceCaseAssignee,
    loading: updatingServiceCase,
    error: updateServiceCaseError,
  } = useUpdateServiceCaseAssignee({ base: `${config.QM_SERVICE_API_URL}` });

  useEffect(() => {
    if (updateServiceCaseError) {
      enqueueSnackbar(`Error updating service case: ${updateServiceCaseError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, updateServiceCaseError]);

  useEffect(() => {
    if (queueIdWithFallBack) {
      fetchCurrentQueue();
    }
  }, [queueIdWithFallBack, fetchCurrentQueue]);

  const reRouteToCasePage = () => {
    const caseUrl = getCaseRedirectUrl({
      caseId: serviceCase?.id,
      caseType: serviceCase?.caseType,
      serviceRequestId: serviceRequest?.id,
      faxId: serviceCase?.faxDetail?.attachmentId,
      isPostDenialP2P: serviceCase?.isPostDenialP2P,
      queueId: queueIdWithFallBack,
      routeConfigByCaseType: currentQueue?.routeConfigByCaseType,
    });
    caseUrl && window.location.assign(caseUrl);
  };

  const handleOnStartWork = async () => {
    if (user?.preferred_username?.toLowerCase() === serviceCase?.assignee?.userName?.toLowerCase()) {
      if (
        serviceCase?.caseStatus !== "IN_PROGRESS" ||
        user?.preferred_username === serviceCase?.activeCaseHold?.heldBy
      ) {
        const updatedCase = await updateServiceCaseAssignee({
          serviceCaseId: serviceCase?.id || "",
          assignee: {
            name: user?.name,
            userName: user?.preferred_username,
          },
          assignmentMethodDetails: {
            assignmentMethod: "VIEW_ONLY_PAGE",
          },
        });
        setServiceCase?.(updatedCase);
      }
      reRouteToCasePage();
    } else if (serviceCase?.assignee?.userName) {
      setSelectedAgent({ name: user?.name, username: user?.preferred_username });
      setReRoute(true);
      setOpenReassignModal(true);
    } else if (serviceCase?.assignee?.userName === undefined) {
      const updatedCase = await updateServiceCaseAssignee({
        serviceCaseId: serviceCase?.id || "",
        assignee: {
          name: user?.name,
          userName: user?.preferred_username,
        },
        assignmentMethodDetails: {
          assignmentMethod: "VIEW_ONLY_PAGE",
        },
      });
      setServiceCase?.(updatedCase);
      reRouteToCasePage();
    }
  };

  const handleOnSelect = (option: QMAgent | null) => {
    if (option === null) {
      setSelectedAgent?.({ name: "" });
      return;
    }
    setSelectedAgent?.(option);
    if (serviceCase?.assignee?.name === option?.name) {
      return;
    }
    if (serviceCase?.assignee?.name !== undefined) {
      option?.name !== "Unassign" ? setOpenReassignModal?.(true) : setOpenUnassignModal?.(true);
    } else {
      assignAgent(option);
    }
  };

  const assignAgent = async (agent: QMAgent | null) => {
    const updatedCase = await updateServiceCaseAssignee({
      serviceCaseId: serviceCase?.id || "",
      assignee: {
        name: agent?.name,
        userName: agent?.username,
      },
      assignmentMethodDetails: {
        assignmentMethod: "VIEW_ONLY_PAGE",
      },
    });
    setServiceCase?.(updatedCase);
    setSelectedAgent?.(null);
  };

  const handleChipClick = () => {
    setShowDropdown(true);
    setSelectedAgent?.({ name: serviceCase?.assignee?.name || "" });
  };

  const reAssign = async () => {
    selectedAgent && (await assignAgent(selectedAgent));
    setOpenReassignModal?.(false);
    if (reRoute) {
      reRouteToCasePage();
      setReRoute(false);
    }
  };

  const unAssign = async () => {
    await assignAgent(null);
    setOpenUnassignModal(false);
  };

  const useQmSearchUser = (props: UseSearchUserProps) =>
    useSearchUser({ ...props, base: `${config.QM_SERVICE_API_URL}` });
  const useUsers: SelectOptionsHook<QMAgent> = (selectOptionsParams) =>
    useLazyLoadingQueryOptionsHook({
      ...selectOptionsParams,
      useGetHook: useQmSearchUser,
      additionalQueryParams: {
        name: selectOptionsParams.query,
      },
      optionsDecorator: (opts) => {
        const query = selectOptionsParams.query;
        if (serviceCase?.assignee?.name) {
          const options = opts && opts.length > 0 ? (query === "" ? [{ name: "Unassign" }, ...opts] : opts) : [];
          return options;
        } else {
          return opts;
        }
      },
    });

  const [queueIdParam] = useSearchParams();
  const origin = queueIdParam.get("origin");
  const goBackUrl = origin === "dashboard" ? "dashboard" : "supervisor_dashboard";
  const goBackBtnText = origin === "dashboard" && serviceCase?.activeCaseHold ? "Go back" : "Back to queue";

  return (
    <>
      {serviceCaseLoading ? (
        <CircularProgress />
      ) : isQueueView ? (
        <SupervisorQueueView
          serviceRequest={serviceRequest}
          serviceCase={serviceCase}
          isChipVisible={true}
          selectedAgent={selectedAgent}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          handleChipClick={handleChipClick}
          useUsers={useUsers}
          handleOnSelect={handleOnSelect}
          disableCaseChanges={disableCaseChanges}
          updatingServiceCase={updatingServiceCase}
        />
      ) : fileData !== undefined ? (
        <FaxMetaDataDisplay
          fileData={fileData}
          serviceCase={serviceCase}
          url={url}
          isChipVisible={true}
          selectedAgent={selectedAgent}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          handleChipClick={handleChipClick}
          useUsers={useUsers}
          handleOnSelect={handleOnSelect}
        />
      ) : null}
      <Divider className={classes.divider} />
      <ControlPanelContainer>
        <Box display="flex" alignItems="center" height="100%" mx="24px" justifyContent="space-around">
          <SecondaryButton
            style={
              disableCaseChanges ? { marginLeft: 38, width: "85%", marginRight: 6 } : { marginLeft: 38, marginRight: 6 }
            }
            className={classes.doubleControlBtns}
            href={`${queueMgmtBaseUrl()}/${goBackUrl}`}
          >
            {goBackBtnText}
          </SecondaryButton>
          {!disableCaseChanges && (
            <PrimaryButton
              style={{ marginRight: 38, marginLeft: 6 }}
              className={classes.doubleControlBtns}
              onClick={handleOnStartWork}
            >
              Start working case
            </PrimaryButton>
          )}
        </Box>
      </ControlPanelContainer>
      <ReassignModal
        from={serviceCase?.assignee?.name || ""}
        to={selectedAgent?.name || ""}
        open={openReassignModal || false}
        setOpen={(value) => {
          setOpenReassignModal?.(value);
          setReRoute?.(false);
        }}
        reassign={reAssign}
        loading={updatingServiceCase}
        showOnHoldWarning={!!serviceCase?.activeCaseHold}
      />
      <UnassignModal
        open={openUnassignModal}
        setOpen={setOpenUnassignModal}
        unassign={unAssign}
        from={serviceCase?.assignee?.name || ""}
        loading={updatingServiceCase}
        showOnHoldWarning={!!serviceCase?.activeCaseHold}
      />
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const ControlPanelContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  boxShadow: "0px -5px 10px -3px rgba(0, 0, 0, 0.2)",
  backgroundColor: theme.palette.background.paper,
  zIndex: 100,
  width: "100%",
  height: "96px",
}));
