/*
    To ensure consistency, these Flesch Kincaid utility functions were copied from
    https://github.com/CohereHealth/ml-jobs/blob/develop/ml_denial_letter_inference_reporting/evaluate_code.py
*/

function getTokens(text: string): string[] {
  const punctuation = `!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~`;

  const rawTokens = text.split(/\s+/);
  const tokens: string[] = [];

  rawTokens.forEach((tok) => {
    if (punctuation.includes(tok.charAt(tok.length - 1))) {
      tok = tok.slice(0, -1);
    }

    if (tok) {
      tokens.push(tok);
    }
  });

  return tokens;
}

function getWordsPerSentence(text: string, tokens: string[]): number {
  const numWords = tokens.length;

  const numSentences = text.split(/:[\s\r\n]+|\.[\s\r\n]+/).length;

  const wordsPerSentence = parseFloat((numWords / numSentences).toFixed(1));

  return wordsPerSentence;
}

function syllableCount(word: string): number {
  word = word.toLowerCase();
  let count = 0;
  const vowels = "aeiouy";

  if (vowels.includes(word[0])) {
    count += 1;
  }

  for (let i = 1; i < word.length; i++) {
    if (vowels.includes(word[i]) && !vowels.includes(word[i - 1])) {
      count += 1;
    }
  }

  if (word.endsWith("e") && !word.endsWith("le")) {
    count -= 1;
  }

  if (count === 0) {
    count += 1;
  }

  return count;
}

function getSyllablesPerWord(tokens: string[]): number {
  const syllableCounts = tokens.map((tok) => syllableCount(tok));
  const averageSyllables = syllableCounts.reduce((sum, count) => sum + count, 0) / tokens.length;

  return parseFloat(averageSyllables.toFixed(1));
}

export function calculateFleshKincaidScore(text: string): number {
  const trimmedText = text.trim();
  const tokens = getTokens(trimmedText);
  const wordsPerSentence = getWordsPerSentence(trimmedText, tokens);
  const syllablesPerWord = getSyllablesPerWord(tokens);

  const easeScore = parseFloat((206.835 - 1.015 * wordsPerSentence - 84.6 * syllablesPerWord).toFixed(1));

  return easeScore;
}

export const calculateFleschKincaidGradeLevel = (text: string) => {
  const easeScore = calculateFleshKincaidScore(text);

  if (easeScore <= 10) {
    return "Professional reading level";
  } else if (easeScore <= 30) {
    return "College graduate reading level";
  } else if (easeScore <= 50) {
    return "College reading level";
  } else if (easeScore <= 53.3) {
    return "12th grade reading level";
  } else if (easeScore <= 56.6) {
    return "11th grade reading level";
  } else if (easeScore <= 60) {
    return "10th grade reading level";
  } else if (easeScore <= 65) {
    return "9th grade reading level";
  } else if (easeScore <= 70) {
    return "8th grade reading level";
  } else if (easeScore <= 80) {
    return "7th grade reading level";
  } else if (easeScore <= 90) {
    return "6th grade reading level";
  } else {
    return "5th grade reading level";
  }
};
