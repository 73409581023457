// Eula utils

import { User } from "UserContext";
import addYears from "date-fns/addYears";

/**
 * Determine the EULA status of a user.
 *
 * @param user - the Okta user
 * @returns "NEEDED", "UNKNOWN", or "COMPLETE"
 *
 * "NEEDED" means that the user needs to sign the agreement;
 * "UNKNOWN" means that the user is unknown;
 * "COMPLETE" means that the user has already signed the agreement, and the signature
 * hasn't expired (i.e. it's been less than a year since the user signed the agreement).
 */
export const getEulaStatus = (user: User): "NEEDED" | "UNKNOWN" | "COMPLETE" => {
  if (user) {
    if (user.agreementSignedAt && addYears(new Date(user.agreementSignedAt), 1) > new Date()) {
      return "COMPLETE";
    } else {
      return "NEEDED";
    }
  }
  return "UNKNOWN";
};
