import { useState, useEffect } from "react";
import { useFeature } from "@coherehealth/common";

import { SemanticProcedureCode } from "@coherehealth/core-platform-api";

export const getFormattedProcedureCodes = (codes?: SemanticProcedureCode[]): string => {
  return (
    codes
      ?.map((c) => c.code)
      .sort()
      .join(",") || ""
  );
};

export const haveProcedureCodesChanged = (
  oldCodes?: SemanticProcedureCode[],
  newCodes?: SemanticProcedureCode[]
): boolean => {
  const formattedOldCodes = getFormattedProcedureCodes(oldCodes);
  const formattedNewCodes = getFormattedProcedureCodes(newCodes);
  return formattedOldCodes !== formattedNewCodes;
};

export function useProcedureCodeChangeBanner(
  currentProcedureCodes: SemanticProcedureCode[],
  newProcedureCodes?: SemanticProcedureCode[]
) {
  const [showProcedureCodeChangeBanner, setShowProcedureCodeChangeBanner] = useState(false);
  const isServicesEditabilityEnabled = useFeature("servicesEditability");

  useEffect(() => {
    if (!isServicesEditabilityEnabled) {
      return;
    }

    setShowProcedureCodeChangeBanner(haveProcedureCodesChanged(currentProcedureCodes, newProcedureCodes));
  }, [currentProcedureCodes, newProcedureCodes, isServicesEditabilityEnabled]);

  return showProcedureCodeChangeBanner;
}
