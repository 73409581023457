import { useEffect, useState, useContext, useRef, SetStateAction, Dispatch } from "react";
import { TargetFieldLabel, useFeature } from "@coherehealth/common";
import { clearNotShownClinicalQuestions, incompleteRequiredQuestions } from "util/clinicalAssessment";
import { ClinicalAssessmentContext } from "components/ClinicalAssessment";
import {
  CarePathJourney,
  usePostClinicalAssessment,
  useGetBulkServiceRequestRuleActions,
  ServiceRequestResponse,
  AuthBuilderWorkflowStep,
  useUpdateServiceRequest,
  useGetServiceRequestRuleActions,
} from "@coherehealth/core-platform-api";
import { convertStringToAuthBuilderWorkflowStep, fetchRuleActions, navigateToPS, navigateToSRSummary } from "../common";
import { useSnackbar } from "notistack";
import {
  deduplicatedRuleActions,
  isRecommendChangeAction,
  isRedirectAction,
  ValidDisplayMessageAction,
} from "util/rule";
import ServiceRequestFormRedirectModal from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { useAuthorized } from "authorization";
import ClinicalSkipOrIncompleteModal from "components/ClinicalAssessment/ClinicalSkipOrIncompleteModal";
import { ClinicalAssessmentProvider } from "components/AuthBuilder/index";
import { error as logError } from "logger";
import Footer from "../Footer";
import { useNavigate } from "react-router";
import { DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION, useHasAcceptedAllNudges } from "util/NudgeUtils";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";

interface Props {
  serviceRequests: ServiceRequestResponse[];
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  carePathJourney?: CarePathJourney;
  singleService?: ServiceRequestResponse;
  clinicalAssessmentProviders: ClinicalAssessmentProvider[];
  workflowId?: string;
  refetchActions: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"];
  dedicatedNudgeIds: string[];
  setDedicatedNudgeIds: Dispatch<SetStateAction<string[]>>;
  setCurrentDedicatedNudgeTargetForAction: Dispatch<SetStateAction<TargetFieldLabel | undefined>>;
  setDedicatedNudgeServiceRequestId: (s: string) => void;
  loadingActions: boolean;
  workflowStep: AuthBuilderWorkflowStep;
  patientId: string;
  authFlowType: string;
  displayClearerDraftComponents: boolean;
  isContinuationWorkflow?: boolean;
  dedicatedNudgeResponses: boolean[];
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

export default function ClinicalAssessmentFooter({
  serviceRequests,
  setWorkflowStep,
  carePathJourney,
  singleService,
  clinicalAssessmentProviders,
  workflowId,
  refetchActions,
  dedicatedNudgeIds,
  setDedicatedNudgeIds,
  setCurrentDedicatedNudgeTargetForAction,
  setDedicatedNudgeServiceRequestId,
  loadingActions,
  workflowStep,
  patientId,
  authFlowType,
  displayClearerDraftComponents,
  isContinuationWorkflow,
  dedicatedNudgeResponses,
  setFooterHeight,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    clinicalAssessment,
    subQuestions: subQuestionsVisibility,
    updateClinicalAssessment,
  } = useContext(ClinicalAssessmentContext);

  const [assessmentModalOpen, setAssessmentModalOpen] = useState<boolean>(false);
  const [assessmentModalAction, setAssessmentModalAction] = useState<ValidDisplayMessageAction>();
  const [modalState, setModalState] = useState<"INCOMPLETE_CHECK">();
  const [skipModalOpen, setSkipModalOpen] = useState<boolean>(false);
  const { faxDbId: faxId } = useContext(FaxAttachmentContext);
  const isFaxIntakeFlow = Boolean(faxId);

  const { loading: clinicalAssessmentLoading, error, mutate } = usePostClinicalAssessment({});
  const userCanSkip = useAuthorized("SKIP_CLINICAL_ASSESSMENT");
  const isClearerDraftWorkflow = useFeature("clearerDraftWorkflow");
  const navigate = useNavigate();
  const goToDedicatedNudgeStepRef = useRef(false);

  const setGoToDedicatedNudgeStep = (value: boolean): void => {
    goToDedicatedNudgeStepRef.current = value;
  };

  const {
    mutate: updateServiceRequest,
    error: updateServiceRequestError,
    loading: updateServiceRequestLoading,
  } = useUpdateServiceRequest({ id: "" });

  const hasAcceptedAllNudges = useHasAcceptedAllNudges(dedicatedNudgeResponses);

  const targetsForAction = DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION;

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Failed to post assessment: ${error.message}`, { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (updateServiceRequestError) {
      enqueueSnackbar("Failed to update service request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  const handleAssessmentModalClose = async (success: boolean) => {
    if (success) {
      setAssessmentModalAction(undefined);
    } else {
      setWorkflowStep(
        goToDedicatedNudgeStepRef.current ? "REVIEW_NUDGES" : isContinuationWorkflow ? "REVIEW_CONTINUATION" : "REVIEW"
      );
    }
    setAssessmentModalOpen(false);
  };

  const onClose = () => setSkipModalOpen(false);
  const openIncompleteModal = () => {
    setModalState("INCOMPLETE_CHECK");
    setSkipModalOpen(true);
  };

  const { mutate: getBulkRuleActions, loading: loadingBulkActions } = useGetBulkServiceRequestRuleActions({});

  const updateServiceRequestWorkflowStep = async () => {
    for (const sr of serviceRequests) {
      await updateServiceRequest({ workflowStep: workflowStep }, { pathParams: { id: sr?.id } });
    }
  };

  // Get whether there are skipped questions from backend on component load; after that, keep track of it locally
  // because we don't refresh data from backend after every change in this component.
  const [persistedSrsHaveSkippedQuestions, setPersistedSrsHaveSkippedQuestions] = useState<boolean>(() =>
    serviceRequests.some((sr) => sr.areClinicalQuestionsSkipped)
  );
  const updateServiceRequestSkippedClinicalQuestions = async (areClinicalQuestionsSkipped: boolean) => {
    if (persistedSrsHaveSkippedQuestions !== areClinicalQuestionsSkipped) {
      for (const sr of serviceRequests) {
        await updateServiceRequest({ areClinicalQuestionsSkipped }, { pathParams: { id: sr?.id } });
      }
    }
    setPersistedSrsHaveSkippedQuestions(areClinicalQuestionsSkipped);
  };

  const continueToNextStep = async () => {
    let continueFlow = true;
    const ruleActions = await getBulkRuleActions({
      serviceRequestIds: serviceRequests.map((sr) => sr.id),
      displayTarget: "CLINICAL_ASSESSMENT",
      authStage: convertStringToAuthBuilderWorkflowStep("CLINICAL_ASSESSMENT"),
    });

    /*we are fetching the rule actions for each service request to check if 
    any of the rules match the rule we are testing for COH-2048,
    a proof of concept test*/
    if (!hasAcceptedAllNudges) {
      await fetchRuleActions(
        serviceRequests,
        refetchActions,
        targetsForAction,
        dedicatedNudgeIds,
        setDedicatedNudgeIds,
        setCurrentDedicatedNudgeTargetForAction,
        setDedicatedNudgeServiceRequestId,
        setGoToDedicatedNudgeStep
      );
    }
    /* Todo - align usage so we can replace all of these dns state usages w/ fired nudge context */
    const redirect = deduplicatedRuleActions(ruleActions, dedicatedNudgeIds)?.find(isRedirectAction);
    const recommendChange = deduplicatedRuleActions(ruleActions, dedicatedNudgeIds)?.find(isRecommendChangeAction);
    if (redirect || recommendChange) {
      setAssessmentModalOpen(true);
      setAssessmentModalAction(redirect || recommendChange);
      continueFlow = false;
    }
    if (continueFlow) {
      if (clinicalAssessmentProviders.includes("HealthHelp")) {
        setWorkflowStep("VENDOR_CLINICAL_ASSESSMENT");
      } else {
        setWorkflowStep(
          goToDedicatedNudgeStepRef.current
            ? "REVIEW_NUDGES"
            : isContinuationWorkflow
            ? "REVIEW_CONTINUATION"
            : "REVIEW"
        );
      }
    }
  };

  const continueToNextStepWithoutNudges = async () => {
    if (clinicalAssessmentProviders.includes("HealthHelp")) {
      setWorkflowStep("VENDOR_CLINICAL_ASSESSMENT");
    } else {
      setWorkflowStep(isContinuationWorkflow ? "REVIEW_CONTINUATION" : "REVIEW");
    }
  };

  const submitClinicalAssessmentAndContinue = async ({
    skipClinicalQuestions = false,
  }: {
    skipClinicalQuestions?: boolean;
  }) => {
    try {
      await updateServiceRequestSkippedClinicalQuestions(skipClinicalQuestions);
      if (clinicalAssessment && clinicalAssessment.questions) {
        const answeredClinicalAssessmentQuestions = clearNotShownClinicalQuestions(clinicalAssessment.questions);
        const updatedCAQs = await mutate({
          ...clinicalAssessment,
          questions: answeredClinicalAssessmentQuestions,
          isDraft: false,
        });
        updateClinicalAssessment({ type: "populate", clinicalAssessment: updatedCAQs });
        if (isClearerDraftWorkflow && authFlowType !== "PHARMACY") {
          await updateServiceRequestWorkflowStep();
        }
        if (Boolean(isFaxIntakeFlow)) {
          //nudges are only going to be evaluated on pre-submission when on fax intake workflow
          await continueToNextStepWithoutNudges();
        } else {
          await continueToNextStep();
        }
      }
    } catch (e) {
      logError(e);
    }
  };
  const onTryClinicalAssessmentSubmit = async () => {
    if (incompleteRequiredQuestions(clinicalAssessment, subQuestionsVisibility).length === 0) {
      // OK, you can submit
      await submitClinicalAssessmentAndContinue({ skipClinicalQuestions: false });
    } else {
      openIncompleteModal();
    }
  };

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  const loading = clinicalAssessmentLoading || loadingBulkActions || loadingActions;
  const onSaveAndExitClickButton = async () => {
    try {
      if (clinicalAssessment && clinicalAssessment.questions) {
        const answeredClinicalAssessmentQuestions = clearNotShownClinicalQuestions(clinicalAssessment.questions);
        await mutate({ ...clinicalAssessment, questions: answeredClinicalAssessmentQuestions, isDraft: true });
        await updateServiceRequestWorkflowStep();
        if (inSmartOnFhirWorkflow) {
          navigateToSRSummary(singleService?.id || serviceRequests[0].id, navigate, true);
        } else {
          navigateToPS(patientId, navigate, singleService?.id || serviceRequests[0].id);
        }
      }
    } catch (e) {
      logError(e);
    }
  };

  return (
    <>
      <Footer
        onPrimaryButtonClick={onTryClinicalAssessmentSubmit}
        primaryButtonLoading={loading || updateServiceRequestLoading}
        primaryButtonDisabled={loading || assessmentModalOpen || skipModalOpen || updateServiceRequestLoading}
        primaryButtonText={"Continue"}
        showSaveAndExitButton={displayClearerDraftComponents}
        onSaveAndExitClick={onSaveAndExitClickButton}
        saveAndExitButtonDisabled={loading || assessmentModalOpen || skipModalOpen || updateServiceRequestLoading}
        setFooterHeight={setFooterHeight}
      />
      <ServiceRequestFormRedirectModal
        open={assessmentModalOpen}
        onClose={handleAssessmentModalClose}
        redirectRuleAction={assessmentModalAction}
        objectType={"assessment"}
        context={{
          ruleId: assessmentModalAction?.ruleId,
          actionId: assessmentModalAction?.actionId,
          patientId: carePathJourney?.patient?.id,
          clinicalServiceId:
            singleService?.clinicalService?.id || serviceRequests.length > 0
              ? serviceRequests[0]?.clinicalService?.id
              : "",
          serviceRequestId: singleService?.id || serviceRequests.length > 0 ? serviceRequests[0]?.id : "",
          careJourneyId: carePathJourney?.id,
          workflowId: workflowId,
        }}
        isHardStop={assessmentModalAction && isRedirectAction(assessmentModalAction)}
      />
      <ClinicalSkipOrIncompleteModal
        canSkip={userCanSkip}
        modalOpen={skipModalOpen}
        onClose={onClose}
        modalState={modalState}
        loading={loading}
        submitAndContinue={async () => {
          await submitClinicalAssessmentAndContinue({ skipClinicalQuestions: true });
        }}
      />
    </>
  );
}
