import { Body1, Sanitized, Caption } from "@coherehealth/common";
import { MdReview, PeerToPeerReview, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { Label } from "components/ServiceRequest/ReadonlyDetail/FormElements";
import DenialLetterSummaryCard from "./DenialLetterSummaryCard";

type GenericMdReview = MdReview | PeerToPeerReview;

interface Props {
  mdReview: GenericMdReview;
  serviceRequest: ServiceRequestResponse;
  prePopulateDenialsTemplate?: boolean;
  hideReviewNotes?: boolean;
}

export default function MDReviewReadonly({
  mdReview,
  serviceRequest,
  prePopulateDenialsTemplate,
  hideReviewNotes,
}: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>Outcome</Label>
        <Body1>
          {mdReview.reviewStatus === "DISCARDED" ? "Discarded" : ReviewOutcomes[mdReview.reviewOutcome || ""]}
        </Body1>
      </Grid>
      {prePopulateDenialsTemplate && (
        <Grid item xs={12}>
          <Label>{`Guidelines used (${
            (mdReview.usedGuidelines?.length || 0) + (mdReview.wasUnlistedGuidelineUsed ? 1 : 0)
          })`}</Label>
          {mdReview.usedGuidelines && mdReview.usedGuidelines.length > 0 ? (
            mdReview.usedGuidelines.map((guideline) => {
              let policyTitle: string | undefined;
              const hasMatch = mdReview?.guidelines?.find(({ guidelineMatchMap }) =>
                guidelineMatchMap?.find((matchedGuideline) => {
                  if (matchedGuideline.guidelineId === guideline.id) {
                    policyTitle = matchedGuideline?.policyTitle;
                    return true;
                  }
                  return false;
                })
              );
              return (
                <div key={guideline.id}>
                  <Body1>{guideline.guidelineTitle}</Body1>
                  <LightCaption>{hasMatch ? policyTitle : ""}</LightCaption>
                </div>
              );
            })
          ) : mdReview.wasUnlistedGuidelineUsed ? (
            ""
          ) : (
            <Body1>- -</Body1>
          )}
          {mdReview.wasUnlistedGuidelineUsed && <Body1>Unlisted guideline used</Body1>}
        </Grid>
      )}
      {!hideReviewNotes && (
        <StyledNotesContainer item xs={12}>
          <Label>Reviewer notes</Label>
          <StyledReviewNotes variantMapping={{ body1: "div" }}>
            <Sanitized __html={mdReview.determinationNote || "<p>- -</p>"} />
          </StyledReviewNotes>
        </StyledNotesContainer>
      )}
      {mdReview.reviewStatus === "COMPLETE" &&
        (mdReview.reviewOutcome === "RECOMMENDED_DENIAL" ||
          mdReview.reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL" ||
          mdReview.reviewOutcome === "PARTIALLY_APPROVED" ||
          mdReview.reviewOutcome === "DENIED") && (
          <Grid item xs={12}>
            <OutcomeDivider />
            <DenialLetterSummaryCard review={mdReview} serviceRequest={serviceRequest} />
          </Grid>
        )}
    </Grid>
  );
}

const ReviewOutcomes: Record<string, string> = {
  APPROVED: "Approved",
  WITHDRAWN: "Withdrawn",
  PARTIALLY_APPROVED: "Partially Approved",
  PENDING_MISSING_CLINICAL_INFO: "Pending for missing clinical information",
  RECOMMENDED_PARTIAL_APPROVAL: "Recommended Partial Approval",
  RECOMMENDED_DENIAL: "Recommended Denial",
  DENIED: "Denied",
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OutcomeDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 0, 2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledNotesContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledReviewNotes = styled(Body1)(({ theme }) => ({
  margin: theme.spacing(-1.5, 0, -2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const LightCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
