import React, { useState, useContext, useEffect, useMemo } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Theme } from "@material-ui/core/styles";
import { Body1, H5, Subtitle1, H6 } from "../Typography";
import { Pill } from "../Pill";
import { IndicationsReviewProps } from "../IndicationsReview";
import { colorsLight } from "../../themes";
import {
  codeMatch,
  facilityCategoryMatch,
  getURLInfo,
  stateMatch,
  formatDateStr,
  HighlightStateContext,
} from "../../util";
import {
  AttachmentGuidelineTextHighlight,
  FilterMatchMap,
  ReviewType,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InlineButton } from "../InlineButton";
import IndicationsOperatorReview from "../IndicationsReview/IndicationsOperatorReview";
import { Chip } from "../../components/Chip";
import { canHaveCoverageLevelDetails } from "../../util/reviewsUtil";

interface GuidelineIndicationsReviewProps extends IndicationsReviewProps {
  filterMatchMap?: FilterMatchMap;
  hideMatchSection?: boolean;
  additionalSummaryContent?: React.ReactNode;
  expandable?: boolean;
  policyTitle?: string;
  selected?: boolean;
  viewOnlyHighlights?: AttachmentGuidelineTextHighlight[] | null;
  review?: ReviewType | null;
  serviceRequest?: ServiceRequestResponse | null;
  multiCoverageReviewsEnabled?: boolean;
}

export default function GuidelineIndicationsReview({
  guideline,
  checkedIndications,
  unmetIndications,
  filterMatchMap,
  onCheckIndication,
  readonly,
  hideMatchSection,
  hideHeadlessIndications,
  additionalSummaryContent,
  expandable,
  policyTitle,
  selected,
  viewOnlyHighlights,
  openAttachment,
  isCPP,
  review,
  serviceRequest,
  multiCoverageReviewsEnabled,
}: GuidelineIndicationsReviewProps) {
  const isMultiCoverageReviewRequired = serviceRequest?.isMultiCoverageReviewRequired;
  const useMultiCoverageContent = multiCoverageReviewsEnabled && isMultiCoverageReviewRequired;

  const lobsWithGuideline = useMemo(() => {
    if (review && canHaveCoverageLevelDetails(review)) {
      const detailsWithGuideline =
        review.coverageLevelDetails?.filter((detail) => detail.selectedGuidelineIds?.includes(guideline?.id || "")) ||
        [];
      const lobs = detailsWithGuideline?.map((detail) => detail.coverage?.lineOfBusinessType || "");
      const uniqueLobs = Array.from(new Set(lobs));

      return uniqueLobs;
    } else {
      return [];
    }
  }, [guideline?.id, review]);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const classes = useStyles({ hideMatchSection, isExpanded, expandable });
  const { selectedGuideline, setSelectedGuideline, readOnlySelectedGuideline, setReadOnlySelectedGuideline } =
    useContext(HighlightStateContext);
  useEffect(() => {
    readonly
      ? setIsExpanded(readOnlySelectedGuideline === guideline?.id)
      : setIsExpanded(selectedGuideline === guideline?.id);
  }, [guideline?.id, readOnlySelectedGuideline, readonly, selectedGuideline]);
  return (
    <SingleGuidelineContainer expandable={expandable} container spacing={1}>
      <Accordion
        expanded={isExpanded}
        style={{ boxShadow: "none", width: "100%" }}
        TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
      >
        <AccordionSummary
          classes={{ root: classes.summaryRoot, content: classes.summaryContent }}
          onClick={() => {
            //Don't process click if text was selected
            if (!((window.getSelection()?.toString()?.length || 0) > 0)) {
              if (expandable) {
                if (setSelectedGuideline || setReadOnlySelectedGuideline) {
                  if (isExpanded) {
                    setReadOnlySelectedGuideline?.(undefined);
                    setSelectedGuideline?.(undefined);
                  } else {
                    setReadOnlySelectedGuideline?.(guideline?.id);
                    setSelectedGuideline?.(guideline?.id);
                  }
                } else {
                  setIsExpanded(!isExpanded);
                }
              }
            }
          }}
        >
          <Grid container direction="row" spacing={1} style={{ margin: 0, maxWidth: "100%" }}>
            <Grid item xs={expandable ? 11 : 12}>
              {expandable ? <H6>{policyTitle || guideline?.policyTitle}</H6> : <H5>{guideline?.guidelineTitle}</H5>}
            </Grid>
            {expandable && (
              <Grid item xs={1}>
                <CarrotContainer>
                  <CarrotIcon>
                    <ExpandMoreIcon className={classes.rotatingCarrot} />
                  </CarrotIcon>
                </CarrotContainer>
              </Grid>
            )}
            <Grid item xs={12}>
              <PolicyTitle>{expandable ? guideline?.guidelineTitle : guideline?.policyTitle}</PolicyTitle>
            </Grid>
            {selected && (
              <Grid item xs={12}>
                <Pill label="Selected by reviewer" variant="success" />
              </Grid>
            )}
            {additionalSummaryContent && (
              <Grid item xs={12}>
                {additionalSummaryContent}
              </Grid>
            )}
            {useMultiCoverageContent && (
              <Grid container item xs={12}>
                {lobsWithGuideline.map((lob) => (
                  <Grid item>
                    <Chip label={`Selected for ${lob} review`} style={{ marginRight: 8 }} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detailRoot }}>
          <Grid container direction="row" spacing={1} style={{ margin: 0, maxWidth: "100%" }}>
            {!hideMatchSection && (
              <>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start" }}>
                  <InfoPill className={classes.singlePill}>
                    <Body3>{`LOB: ${filterMatchMap?.linesOfBusinessMatch ?? "--"}`}</Body3>
                  </InfoPill>
                  <InfoPill className={classes.singlePill}>
                    <Body3>{`Effective ${
                      filterMatchMap?.effectiveDate ? formatDateStr(filterMatchMap?.effectiveDate) : "--"
                    }`}</Body3>
                  </InfoPill>
                  <InfoPill className={classes.singlePill}>
                    <Body3>{`Expires ${
                      filterMatchMap?.expirationDate ? formatDateStr(filterMatchMap?.expirationDate) : "--"
                    }`}</Body3>
                  </InfoPill>
                  <InfoPill>{getURLInfo(filterMatchMap?.policyUrl || "")}</InfoPill>
                </Grid>
                {!expandable && (
                  <Grid item xs={12} className={classes.filterMatchDivider}>
                    <Divider />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Body1>This guideline is being shown because the service request meets the following criteria:</Body1>
                </Grid>
                {stateMatch(filterMatchMap?.stateMatch ?? "")}
                {facilityCategoryMatch(filterMatchMap?.facilityCategoriesMatch ?? "")}
                {codeMatch(filterMatchMap?.procedureCodesMatch, filterMatchMap?.diagnosisCodesMatch)}
              </>
            )}
            <Grid item xs={12} className={classes.indicationsDivider}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {
                <IndicationsOperatorReview
                  guideline={guideline}
                  checkedIndications={checkedIndications}
                  unmetIndications={unmetIndications}
                  onCheckIndication={onCheckIndication}
                  readonly={readonly}
                  hideHeadlessIndications={hideHeadlessIndications}
                  viewOnlyHighlights={viewOnlyHighlights}
                  openAttachment={openAttachment}
                  isCPP={isCPP}
                />
              }
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </SingleGuidelineContainer>
  );
}
interface StyleProps {
  hideMatchSection?: boolean;
  isExpanded?: boolean;
  expandable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  singlePill: {
    marginRight: theme.spacing(1),
  },
  codesContainer: {
    marginBottom: theme.spacing(2),
  },
  filterMatchDivider: {
    margin: theme.spacing(2.5, 0),
  },
  indicationsDivider: {
    margin: ({ hideMatchSection }: StyleProps) => (hideMatchSection ? theme.spacing(2, 0) : theme.spacing(0.5, 0)),
  },
  summaryRoot: {
    display: "block",
    userSelect: "text",
    padding: 0,
    width: "100%",
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 0,
    },
  },
  summaryContent: {
    "&.MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
    padding: 0,
    margin: 0,
    cursor: ({ expandable }: StyleProps) => (expandable ? "pointer" : "default"),
  },
  detailRoot: {
    display: "block",
    padding: 0,
  },
  rotatingCarrot: ({ isExpanded }: StyleProps) => ({
    transition: theme.transitions.create("transform"),
    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: 0,
  }),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SingleGuidelineContainer = styled(({ expandable, ...other }) => <Grid {...other} />)<
  Theme,
  { expandable?: boolean }
>(({ theme, expandable }) => ({
  margin: theme.spacing(expandable ? 1.5 : 2, 0, expandable ? 1.5 : 2, -1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body3 = styled("div")({
  color: colorsLight.font.main,
  fontFamily: "Gilroy-Medium",
  fontSize: "13px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PolicyTitle = styled(Subtitle1)({
  color: colorsLight.font.secondary,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InfoPill = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  padding: theme.spacing(1),
  boxShadow: "0px",
  borderRadius: theme.spacing(0.5),
  "&:before": {
    content: "none",
  },
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const CarrotIcon = styled(InlineButton)({
  position: "absolute",
  right: 0,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CarrotContainer = styled("div")({
  position: "relative",
  right: 12,
  top: -14,
  marginLeft: 60,
});
