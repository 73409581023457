import SubmissionModal, { SubmissionStatus } from "components/ServiceRequest/Submission/SubmissionModal";
import { useNavigate } from "react-router";

type ReviewOutcomeModalProps = {
  open: boolean;
  successfulSubmissionMessage: string;
  status: SubmissionStatus;
  failedSubmissionMessage: string;
  getSubmissionModalStatus: () => number;
};

export const ReviewOutcomeModal = ({
  open,
  successfulSubmissionMessage,
  status,
  failedSubmissionMessage,
}: ReviewOutcomeModalProps) => {
  const navigate = useNavigate();
  const refreshPage = () => {
    navigate(0);
  };

  const handleClick = () => {
    refreshPage();
  };

  return (
    <SubmissionModal
      successfulSubmissionMessage={successfulSubmissionMessage}
      successfulSubmissionMessageDetails={""}
      failedSubmissionMessageDetails=""
      failedSubmissionButtonText="Close"
      successfulSubmissionButtonText="Close"
      failedSubmissionMessage={failedSubmissionMessage}
      open={open}
      handleClose={handleClick}
      onSuccessAction={handleClick}
      onFailureAction={handleClick}
      status={status}
    />
  );
};
