import { Alert } from "@coherehealth/common";
import { useLocation } from "react-router-dom";

const BANNER_MESSAGES = {
  review:
    "Updating the procedure codes will create a new review with updated clinical guidelines. Your current review, including all notes and indication selections, will be discarded.",
  default:
    "Updating these procedure codes will create a new review with the appropriate clinical guidelines. Any draft reviews will be deleted, including your current notes and indication selections.",
} as const;

interface Props {
  show: boolean;
}

export default function ProcedureCodeChangeBanner({ show }: Props) {
  const location = useLocation();

  if (!show) {
    return null;
  }

  const bannerMessage = location.pathname.includes("/review") ? BANNER_MESSAGES.review : BANNER_MESSAGES.default;

  return (
    <Alert
      severity="info"
      style={{
        paddingTop: "4px",
        paddingBottom: "4px",
      }}
      data-testid="procedure-code-change-banner"
    >
      {bannerMessage}
    </Alert>
  );
}
