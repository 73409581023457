import { ReviewType } from "@coherehealth/core-platform-api";

interface ReviewHeaderConfig {
  title: string;
}

export const reviewHeaderConfig: Record<ReviewType["reviewType"], ReviewHeaderConfig> = {
  NurseReview: {
    title: "Clinical Review",
  },
  OutOfNetworkReview: {
    title: "OON Exception Review",
  },
  AuditReview: {
    title: "Audit Review",
  },
  MdReview: {
    title: "MD Review",
  },
  PeerToPeerReview: {
    title: "P2P Review",
  },
  NewHireAuditReview: {
    title: "New Hire Audit Review",
  },
  OutOfScopeAuditReview: {
    title: "OOS Audit Review",
  },
  FaxAuditReview: {
    title: "Fax Audit Review",
  },
};
