import { Grid } from "@material-ui/core";
import { CardTextField, formatDateStr } from "@coherehealth/common";
import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { compact } from "lodash";

const M_DASH = "\u2014";

export interface CommonSelectionCardProps {
  authorization: AuthorizationResponse | null;
  latestServiceRequest: ServiceRequestResponse | null;
}

export function AuthorizationOutpatientSection(props: CommonSelectionCardProps): JSX.Element {
  const { latestServiceRequest } = props;
  const { primaryDiagnosis, secondaryDiagnoses, startDate, endDate } = latestServiceRequest ?? {};
  const serviceDatesTitle = compact([startDate, endDate]).length <= 1 ? "Date of service" : "Dates of service";
  const formattedServiceDates = compact([startDate, endDate]).map(formatDateStr).join(` ${M_DASH} `) || "none";
  const secondaryDiagnosisString = secondaryDiagnoses
    ? secondaryDiagnoses.map((diagnosis) => diagnosis.code).join(",")
    : "--";

  return (
    <>
      <Grid item xs={3} data-public data-testid="primary_dx">
        <CardTextField label="Primary diagnosis" content={`${primaryDiagnosis?.code}`} data-public />
      </Grid>

      <Grid item xs={4} data-public data-testid="secondary_dx">
        <CardTextField label="Secondary diagnosis" content={secondaryDiagnosisString} data-public />
      </Grid>

      <Grid item xs={5}>
        <CardTextField label={serviceDatesTitle} content={formattedServiceDates} />
      </Grid>
    </>
  );
}
